import { Patient } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import { DateTime } from "luxon";
import { useMemo } from "react";

export function useAge<T extends Patient | undefined>(
  patient: T
): T extends Patient ? number : undefined;
export function useAge(patient: Patient | undefined): number | undefined {
  return useMemo(() => {
    if (!patient) {
      return undefined;
    }

    const now = DateTime.now();

    return patientAge(patient, now);
  }, [patient]);
}
