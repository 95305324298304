import { Typography } from "render/ui/presentation/Typography";
import ArrowIcon from "./assets/arrow.svg?react";
import styles from "./styles.module.sass";

interface MainUSPItemProps {
  value: React.ReactNode;
  unit?: React.ReactNode;
}

export default function MainUSPItem({ value, unit }: MainUSPItemProps) {
  return (
    <span className={styles.MainUSPItem}>
      <ArrowIcon />
      <Typography as="span" variant="numeral-m" className={styles.amount}>
        {value}
      </Typography>
      {unit && (
        <Typography as="span" variant="eyebrow-m" className={styles.unit}>
          {unit}
        </Typography>
      )}
    </span>
  );
}
