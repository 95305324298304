import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useEntries } from "render/hooks/store/refs";
import { useReporting } from "../useReporting";

export function usePatientVisitSummaryQuery(
  options: APITypesV1.VisitSummaryListByPatientParams,
  namespace: string
) {
  const { logError } = useReporting();

  const api = useAPIClient();

  const visitStore = useStore().visit;

  const { getEntries, setEntries } = useEntries(visitStore, namespace);

  const fetch = useCallback(() => {
    const patientId = options.patientId;

    return api.visitSummary
      .querySummary({ patientId }, options)
      .result.then((result) => {
        const visits = result.items.map(fromAPI.toVisitSummary);
        setEntries(visits);
      })
      .catch(logError);
  }, [api, setEntries, options, logError]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return useMemo(() => {
    return {
      entries: getEntries(),
      fetch,
    };
  }, [getEntries, fetch]);
}
