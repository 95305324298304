import SquircleArrow from "render/assets/svg/squircle-arrow";
import styles from "./styles.module.sass";

interface ChartValueChangeProps {
  value: number;
  previousValue: number;
}

export default function ChartValueChange({
  value,
  previousValue,
}: ChartValueChangeProps) {
  const change = value - previousValue;

  return (
    <div className={styles.ChartValueChange}>
      {change !== 0 && (
        <SquircleArrow direction={change > 0 ? "upwards" : "downwards"} />
      )}
      <span className={styles.value}>{Math.abs(change)}</span>
      <hr />
    </div>
  );
}
