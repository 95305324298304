import { APITypesV1 } from "@cur8/api-client";

export enum COLORS {
  RUBBER_BLACK = "#263032",
  DARK_STEEL_GREY = "#3a4c50",
  DARK_STEEL_GREYER = "#4c6a70",
  ALUMINUM_GREY = "#e3e5e5",
  PORCELAIN_WHITE = "#fbfbfa",
  INTELLIGENT_BLUE = "#019dc9",
  OPTIMISTIC_RED = "#f96452",
  LIGHT_INTELLIGENT_BLUE = "#e2eff3",
  DARK_INTELLIGENT_BLUE = "#0274a2",
  STEEL_GREY = "#9ebcc3",
  LIGHT_STEEL_GREY = "#c5d9dd",
  LIGHT_OPTIMISTIC_RED = "#faedeb",
  DARK_OPTIMISTIC_RED = "#c02c1a",
  LIGHT_PLAIN_ORANGE = "#fff5e5",
  DARK_PLAIN_ORANGE = "#986416",
  YELLOW = "#F5E06D",
}

export const ArtefactStateColor: Record<
  APITypesV1.PhysicalArtefactState,
  string
> = {
  closedAsHarmless: COLORS.YELLOW,
  closedAsTreated: COLORS.YELLOW,
  needsFollowUp: COLORS.YELLOW,
  needsTreatment: COLORS.YELLOW,
  waitingForTestResults: COLORS.YELLOW,
  none: COLORS.STEEL_GREY,
  selfMonitor: COLORS.YELLOW,
  needsDermoscope: COLORS.YELLOW,
  followUp4Months: COLORS.YELLOW,
  needsExcision: COLORS.OPTIMISTIC_RED,
  needsDermatologistReferral: COLORS.STEEL_GREY,
  dermoscoped: COLORS.YELLOW,
  referralSent: COLORS.STEEL_GREY,
  excised: COLORS.STEEL_GREY,
  referralSentDerma: COLORS.STEEL_GREY,
  referralSentExcision: COLORS.STEEL_GREY,
};
