import styles from "./styles.module.sass";

interface TabProps {
  label: string;
  isActive: boolean;
  onClick?: () => void;
}

export default function Tab({ label, isActive, onClick }: TabProps) {
  return (
    <div className={styles.Tab} data-active={isActive}>
      <div className={styles.label} title={label} onClick={onClick}>
        {label}
      </div>
    </div>
  );
}
