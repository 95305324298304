import { fromAPI, Patient } from "@cur8/rich-entity";
import { toUpdateRequest } from "lib/api/adapter/patient";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function usePatientStore() {
  const { logError } = useReporting();

  const patientAPI = useAPIClient().patient;

  const fetchPatient = useCallback(
    (patientId: string) => {
      return patientAPI
        .fetchPatient({ patientId })
        .result.then(fromAPI.toPatientDetails)
        .catch(logError);
    },
    [patientAPI, logError]
  );

  const updatePatient = useCallback(
    async (patient: Patient) => {
      const source = await fetchPatient(patient.patientId);
      const payload = toUpdateRequest(patient, source);

      return patientAPI
        .updatePatient(patient.patientId, payload)
        .result.then(fromAPI.toPatientDetails)
        .catch(logError);
    },
    [patientAPI, fetchPatient, logError]
  );

  return { fetchPatient, updatePatient };
}
