/**
 * Tissue data exporter
 */
import { Box, ImmutableScan } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import hash from "object-hash";
import { ToISummary, toiSummaryCalc } from "./calculations";
import {
  BloodVesselsMask,
  Property,
  PropertyLabel,
  RegionTimeseries,
  TissueAnnotation,
} from "./types";
import {
  fetchChromophoreTimeseries,
  filterRegions,
  filterTimes,
} from "./utils";

type SummaryData = {
  scanId: string;
  scanVersion: string;
  property: Property;
  roi: string;
  roiPos: RoiBox;
  toi: string;
  toi_from: number;
  toi_to: number;
} & ToISummary;

type RoiBox = {
  x: number;
  y: number;
  w: number | string;
  h: number | string;
};

export function exportSummary(
  annos: TissueAnnotation[],
  api: APIClient,
  bloodVesselMask: BloodVesselsMask | undefined,
  emitDialog: (headline: string, msg: string) => void,
  pixelsPerCm: number | undefined,
  properties: PropertyLabel[],
  scan: ImmutableScan,
  timestamps: number[]
) {
  const proms = [] as Promise<SummaryData[]>[];

  const tois = filterTimes(annos);
  const regularRois = filterRegions(annos, Property.epidermisVolume);
  const thermalRois = filterRegions(annos, Property.thermal);

  if (
    (regularRois.length <= 0 && thermalRois.length <= 0) ||
    tois.length <= 0
  ) {
    emitDialog(
      "Can't export",
      "Data export not possible until both a Region- and Time of Interest has been created."
    );
    return;
  }

  properties.forEach(([prop]) => {
    let rois = regularRois;
    if (prop === Property.thermal) {
      // Use Thermal RoI´s only
      rois = thermalRois;
    }

    for (let i = 0; i < rois.length; i++) {
      let region = rois[i];
      let prom = fetchChromophoreTimeseries(
        api,
        prop,
        region.annotation.data.rect,
        scan,
        bloodVesselMask
      ).then((roiData: number[]) => {
        // Split RoI-data by ToI's
        const roisByTois = [] as SummaryData[];
        tois.forEach((toi) => {
          const r = toi.annotation.data.range;
          const toiSum = toiSummaryCalc(roiData.slice(r.from, r.to + 1)); // Need to add +1 to include end-pos as well
          const box = rectToRoiBox(region.annotation.data.rect, pixelsPerCm);

          roisByTois.push({
            scanId: scan.id,
            scanVersion: scan.version,
            property: prop,
            roi: region.label,
            roiPos: box,
            toi: toi.label,
            toi_from: timestamps[r.from],
            toi_to: timestamps[r.to],
            ...toiSum,
          });
        });
        return roisByTois;
      });
      proms.push(prom);
    }
  });

  Promise.all(proms).then((res) => {
    let ret: SummaryData[] = [];
    res.forEach((r) => {
      if (r) {
        ret.push(...r);
      }
    });
    const sum = toSummaryCSV(ret, pixelsPerCm);
    saveExportForCRF(api, scan, `export_summary_${checksum(annos)}`, sum).then(
      (res: boolean) => {
        if (res) {
          csvDownload(`neko-health_tissue_summary_${scan.id}.csv`, sum);
        } else {
          emitDialog(
            "Error - export blocked",
            "Failed to save the export for eCRF, export blocked!"
          );
        }
      }
    );
  });
}

type TimeseriesData = {
  roi: string;
  roiPos: RoiBox;
  samples: number[];
};

export function exportTimeseries(
  annos: TissueAnnotation[],
  api: APIClient,
  bloodVesselMask: BloodVesselsMask | undefined,
  createdAt: DateTime | undefined,
  emitDialog: (headline: string, msg: string) => void,
  pixelsPerCm: number | undefined,
  property: Property,
  scan: ImmutableScan,
  timestamps: number[]
) {
  const proms = [] as Promise<TimeseriesData>[];
  const rois = filterRegions(annos, property);
  if (rois.length <= 0) {
    emitDialog(
      "Can't export timeseries",
      "Data export not possible until at least on Region of Interest has been created."
    );
    return;
  }

  for (let i = 0; i < rois.length; i++) {
    let region = rois[i];
    let prom = fetchChromophoreTimeseries(
      api,
      property,
      region.annotation.data.rect,
      scan,
      bloodVesselMask
    ).then((roiData: number[]) => {
      const anno = region.annotation;
      return {
        roi: region.label,
        roiPos: rectToRoiBox(anno.data.rect, pixelsPerCm),
        samples: roiData,
      } as TimeseriesData;
    });
    proms.push(prom);
  }

  return Promise.all(proms).then((res) => {
    let series: TimeseriesData[] = [];
    res.forEach((r) => {
      if (r) {
        series.push(r);
      }
    });
    let csv = "";
    // Metadata
    csv = `"Scan:","${scan.id}",\n"Version:","${
      scan.version
    }"\n"Property:","${property}"\n"Created:","${createdAt?.toUTC()}"\n\n`;
    // Timeseries header
    const csvTsHead: string[] = [];
    csvTsHead[0] = '"RoI:"';
    csvTsHead[1] = '"Pos (x:y):"';
    csvTsHead[2] = '"Size (' + (pixelsPerCm ? "cm" : "px") + '):"';
    for (let i = 0; i < series.length; i++) {
      const p = series[i].roiPos;
      csvTsHead[0] += `,"${series[i].roi}"`;
      csvTsHead[1] += `,"${p.x} : ${p.y}"`;
      csvTsHead[2] += `,"${p.w} x ${p.h}"`;
    }
    csv += csvTsHead.join("\n") + "\n";
    // Timeseries
    for (let t = 0; t < timestamps.length; t++) {
      csv += timestamps[t];
      for (let i = 0; i < series.length; i++) {
        csv += `,${series[i].samples[t]}`;
      }
      csv += `\n`;
    }

    saveExportForCRF(
      api,
      scan,
      `timeseries_${property}_${checksum(annos)}`,
      csv
    ).then((res: boolean) => {
      if (res) {
        csvDownload(
          `neko-health_tissue_timeseries_${property}_${scan.id}.csv`,
          csv
        );
      } else {
        emitDialog(
          "Error - export blocked",
          "Failed to save the export for eCRF, export blocked!"
        );
      }
    });
  });
}

export function exportThermalLines(
  api: APIClient,
  emitDialog: (headline: string, msg: string) => void,
  index: number,
  lines: RegionTimeseries[],
  property: Property,
  scan: ImmutableScan
) {
  // Metadata
  let csv = `"Scan","${scan.id}",\n"Version","${scan.version}"\n"Property","${property}"\n"Index (time)", ${index}\n\n`;

  csv += '"Label", "Id", "Temperatures"\n';
  lines.forEach((line) => {
    csv += `"${line.label}","${line.id}"`;
    line.series.forEach((p) => {
      csv += `,${p.y}`;
    });
    csv += "\n";
  });

  saveExportForCRF(api, scan, `lines_${property}_${checksum(lines)}`, csv).then(
    (res: boolean) => {
      if (res) {
        csvDownload(`neko-health_tissue_lines_${scan.id}-${property}.csv`, csv);
      } else {
        emitDialog(
          "Error - export blocked",
          "Failed to save the export for eCRF, export blocked!"
        );
      }
    }
  );
}

function csvDownload(filename: string, data: string) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.download = filename ?? "nekohealth-docui-download.csv";
  anchor.click();
  window.URL.revokeObjectURL(url);
  anchor.remove();
}

function toSummaryCSV(
  data: SummaryData[],
  pixelsPerCm: number | undefined
): string {
  let csv = "";
  // Add heading
  csv = '"Scan","Version","Property","RoI","X","Y",';
  csv += pixelsPerCm ? '"W (cm)","H (cm)",' : '"W","H",';
  csv +=
    '"ToI","From","To","Mean","Std","Min","Max","p2","p25","p50","p75","p98"\n';

  for (let p of data) {
    csv +=
      `"${p.scanId}","${p.scanVersion}","${p.property}",` +
      `"${p.roi}",${p.roiPos.x},${p.roiPos.y},${p.roiPos.w},${p.roiPos.h},` +
      `"${p.toi}",${p.toi_from},${p.toi_to},` +
      `${p.mean},${p.std},${p.min},${p.max},` +
      `${p.p2},${p.p25},${p.p50},${p.p75},${p.p98}\n`;
  }
  return csv;
}

function rectToRoiBox(rect: Box, pixelsPerCm: number | undefined) {
  const box = {} as RoiBox;
  box.x = rect.x;
  box.y = rect.y;
  if (pixelsPerCm) {
    box.w = (rect.w / pixelsPerCm).toFixed(2);
    box.h = (rect.h / pixelsPerCm).toFixed(2);
  } else {
    box.w = rect.w;
    box.h = rect.h;
  }
  return box;
}

function saveExportForCRF(
  api: APIClient,
  scan: ImmutableScan,
  name: string,
  data: string
): Promise<boolean> {
  return api.blob
    .putPatientBlob({
      patientId: scan.patientId,
      path: cleanName(`tissue_crf_export_scan_${scan.id}_${name}.csv`),
      file: new Blob([data]),
    })
    .result.then((res: Response) => {
      if (res.status === 201) {
        return true;
      }
      return false;
    })
    .catch((err: any) => {
      console.error(err);
      return false;
    });
}

function checksum(obj: any): string {
  return hash(obj).substring(0, 9);
}

function cleanName(str: string): string {
  return str.replaceAll("-", "_");
}
