import { classNames } from "@pomle/classnames";
import { useQueryParams } from "@pomle/react-router-paths";
import { DateTime, Duration, Interval } from "luxon";
import { useEffect, useMemo } from "react";
import { query } from "render/routes/querys";
import { CalendarEvent } from "../../reducer/slotManagementUtils";
import AppointmentsLayer from "./components/AppointmentsLayer";
import CurrentTimeIndicator from "./components/CurrentTimeIndicator";
import Header from "./components/Header";
import Hours from "./components/Hours";
import PossibleSlotsCreationLayer from "./components/PossibleSlotCreationLayer";
import useCalendar from "./hooks/useCalendar";
import useCalendarState from "./hooks/useCalendarState";
import useFetchSlots from "./hooks/useFetchSlots";
import styles from "./styles.module.sass";
import { useSiteRooms } from "./hooks/useSiteRooms";

export default function Calendar() {
  const { siteId, hours, slots, closingHour, openingHour } = useCalendar();
  const rooms = useSiteRooms(siteId);
  const { refetchCurrentSlots } = useFetchSlots();
  const { areAppointmentsCensored, selectedPeriod } = useCalendarState();

  useEffect(() => {
    refetchCurrentSlots();
  }, [refetchCurrentSlots]);

  const days = useMemo(
    () => selectedPeriod.splitBy(Duration.fromDurationLike({ days: 1 })),
    [selectedPeriod]
  );

  const [queryParams] = useQueryParams(query.schedule);

  const roomId = queryParams.roomId.at(0);
  const siteRooms = rooms.data;
  const { room, roomSlots, site } = useMemo(() => {
    const room = roomId
      ? siteRooms?.find((room) => room.id === roomId)
      : siteRooms?.at(0);
    return {
      room,
      site: room?.site,
      roomSlots: slots.data?.find(({ id }) => id === room?.id)?.slots ?? [],
    };
  }, [roomId, siteRooms, slots.data]);

  const possibleSlots = useMemo(() => {
    if (!room) {
      return [];
    }

    return days.map<[Interval, CalendarEvent[]]>((day) => [
      day,
      hours.map<CalendarEvent>(({ hour, minute }) => {
        const start = day.start.set({ hour, minute });
        const end = start.plus({ hour: 1 });

        return {
          room,
          interval: Interval.fromDateTimes(start, end),
        };
      }),
    ]);
  }, [room, days, hours]);

  return (
    <div className={styles.Calendar}>
      <Header selectedPeriod={selectedPeriod} />
      <div className={styles.calendarBody}>
        <Hours />
        <div className={classNames(styles.grid, styles.calendarSlots)}>
          <PossibleSlotsCreationLayer
            slots={roomSlots}
            possibleSlots={possibleSlots}
            closingHour={closingHour}
            openingHour={openingHour}
          />
          {roomSlots && room && site && (
            <AppointmentsLayer
              site={site}
              room={room}
              slots={roomSlots}
              isCensored={areAppointmentsCensored}
              closingHour={closingHour}
              openingHour={openingHour}
              selectedPeriod={selectedPeriod}
            />
          )}
        </div>
        {site && (
          <CurrentTimeIndicator
            site={site}
            totalDayCount={days.length}
            weekday={DateTime.now().weekday}
          />
        )}
      </div>
    </div>
  );
}
