import { BoundingBoxAnnotation, Recording } from "@cur8/rich-entity";
import { Marking } from "../../types";
import { filterPanoramaAnnotations } from "../ScanExplorer/filterPanoramaAnnotations";

export function createMarkings(
  recording: Recording,
  annotations: BoundingBoxAnnotation[],
  movedAnnotations: BoundingBoxAnnotation[]
): Marking[] {
  // Suggested annotations are annotations from the previous recording, which are not yet linked to a primary annotation
  const { primaryAnnotations, nonPrimaryAnnotations } =
    filterPanoramaAnnotations(annotations, recording.id);

  const nonLinkedsuggestedAnnotations = nonPrimaryAnnotations.map(
    (annotation) => {
      const indx = movedAnnotations.findIndex((ma) => ma.id === annotation.id);
      return indx > -1 ? movedAnnotations[indx] : annotation;
    }
  );

  const confirmedMarkings = primaryAnnotations.map((annotation): Marking => {
    return {
      type: "confirmed",
      annotation,
    };
  });

  const suggestedMarkings = nonLinkedsuggestedAnnotations.map(
    (annotation): Marking => {
      return {
        type: "assumed",
        annotation,
      };
    }
  );

  return confirmedMarkings.concat(suggestedMarkings);
}
