import { APITypesV2 } from "@cur8/api-client";
import { ExerciseFrequency } from "render/fragments/metric/patient-details/ExerciseFrequency";
import styles from "./style.module.sass";

type QuestionnaireProps = {
  data: APITypesV2.QuestionAnswer[] | undefined;
};

export function Questionnaire({ data }: QuestionnaireProps) {
  if (!data) {
    return <>No data available</>;
  }

  return (
    <dl className={styles.questionnaire}>
      {data.map((d) => (
        <>
          <dt>{v1Label(d) ?? d.englishLabel ?? d.label}</dt>
          <dd>{v1Value(d) ?? displayValue(d.answer?.value)}</dd>
        </>
      ))}
    </dl>
  );
}

const noAnswer = "(no answer)";

function displayValue(value: string | number | boolean | string[] | undefined) {
  if (Array.isArray(value)) {
    return value.filter(Boolean).join(", ") || noAnswer;
  }
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }
  if (value == null) {
    return noAnswer;
  }
  return String(value);
}

/** TODO: remove after Questionnaire V1 is no longer supported */
function v1Label(question: APITypesV2.QuestionAnswer) {
  if (!question.englishLabel && !question.label) {
    return question.questionId;
  }
  if (v1AlcoholQuestions.has(question.questionId)) {
    const drink = question.questionId.slice(8).replace("liqour", "liquor");
    return (question.englishLabel ?? question.label).replace("alcohol", drink);
  }
}

/** TODO: remove after Questionnaire V1 is no longer supported */
const v1AlcoholQuestions = new Set([
  "alcohol.beer",
  // typo is on purpose, we need to support both
  "alcohol.liqour",
  "alcohol.liquor",
  "alcohol.wine",
]);

/** TODO: remove after Questionnaire V1 is no longer supported */
function v1Value(question: APITypesV2.QuestionAnswer) {
  const value = question.answer?.value;
  if (question.questionId === "weeklyExerciseHours") {
    return <ExerciseFrequency value={(value as number) ?? 0} />;
  }
  // Date timestamps
  if (Number(value) > 1e5) {
    return new Date(Number(value)).toLocaleDateString();
  }
  const label = (question.answer as APITypesV2.EnumAnswerPayload)?.label;
  if (label) {
    return label;
  }
}
