import { Side } from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { isRecordingURI } from "lib/uri/guard";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import {
  PodiumView,
  getSide,
} from "render/pages/DashboardPage/context/DashboardContext/hooks/usePodium";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import Layout, { LayoutProps } from "../Layout";
import SidePanel from "../SidePanel";
import FlipIcon from "./assets/flip.svg?react";
import PanoramaOverlay from "./components/PanoramaOverlay";
import SkinSection from "./components/SkinSection";
import styles from "./styles.module.sass";

interface SkinLayoutProps extends LayoutProps {
  active: boolean;
}

export default function SkinLayout({ active }: SkinLayoutProps) {
  const { scans } = usePatientData();

  const recordingURI = useMemo(() => {
    return scans?.skin?.at(0)?.sourceUris.filter(isRecordingURI).at(0);
  }, [scans?.skin]);

  const { ui, podium } = useDashboardContext();

  const [, setSide] = useState<Side>("front");

  useEffect(() => {
    if (active) {
      podium.moveTo(PodiumView.Front);
    }
  }, [podium, active]);

  const toggleSide = useCallback(() => {
    const side = getSide(podium);
    if (side === "back") {
      setSide("front");
      podium.moveTo(PodiumView.Front);
    } else {
      setSide("back");
      podium.moveTo(PodiumView.Back);
    }
  }, [podium]);

  // const panoramaURIFront = useMemo(() => {
  //   if (!recordingURI) {
  //     return undefined;
  //   }

  //   return new PanoramaImageURI(
  //     recordingURI.deviceId,
  //     recordingURI.recordingId,
  //     "front",
  //     "b3"
  //   );
  // }, [recordingURI]);

  const panoramaURIBack = useMemo(() => {
    if (!recordingURI) {
      return undefined;
    }

    return new PanoramaImageURI(
      recordingURI.deviceId,
      recordingURI.recordingId,
      "back",
      "b3"
    );
  }, [recordingURI]);

  return (
    <Layout active={active}>
      <div
        className={styles.SkinLayout}
        data-active={active}
        data-layer={ui.layer}
      >
        <div className={styles.avatar}>
          {/* <PanoramaOverlay
            active={active}
            side="front"
            panoramaURI={panoramaURIFront}
          /> */}
          <PanoramaOverlay
            active={active}
            side="back"
            panoramaURI={panoramaURIBack}
          />

          <button className={styles.flip} onClick={toggleSide}>
            <FlipIcon />
            <Typography variant="label-m">Flip</Typography>
          </button>
        </div>

        <SidePanel>
          <SkinSection active={active} />
        </SidePanel>
      </div>
    </Layout>
  );
}
