import { Sex } from "@cur8/rich-entity";
import { clamp } from "lib/math";
import { getAgeBinForAge } from "render/pages/DashboardPage/lib/projection/age";
import { getBenchmarkCpvFor } from "../../../../lib/projection";
import { concatPercent } from "../../../../lib/util";

const SexToGenderBin: Record<Sex, string> = {
  [Sex.Female]: "female",
  [Sex.Male]: "male",
  [Sex.Unknown]: "other",
};

function roundToNearestMultipleOfFive(number: number): number {
  return Math.round(number / 5) * 5;
}
const cpvToPercentage = (cpv: number) => cpv * 100;

const normalizeCpv = (cpv: number) => {
  if (cpv <= 0.5) {
    return 1 - cpv;
  }

  return cpv;
};

interface BenchmarkResultDescriptionProps {
  currentValue: number;
  sex: Sex;
  age: number;
}

export default function BenchmarkResultDescription({
  currentValue,
  sex,
  age,
}: BenchmarkResultDescriptionProps) {
  const cpv = getBenchmarkCpvFor(currentValue, age, sex);
  const ageBin = getAgeBinForAge(age);
  const isAboveTheAverage = cpv > 0.5;
  const titleTrend = isAboveTheAverage ? "Higher" : "Better";

  const benchmarkPercentage = roundToNearestMultipleOfFive(
    clamp(cpvToPercentage(normalizeCpv(cpv)), 10, 90)
  );

  if (cpv >= 0.5 && cpv < 0.6) {
    return (
      <>
        Same as the typical Neko member [{SexToGenderBin[sex]} {ageBin} years]
      </>
    );
  }

  return (
    <>
      {titleTrend} than {concatPercent(benchmarkPercentage)} of Neko members [
      {SexToGenderBin[sex]} {ageBin} years]
    </>
  );
}
