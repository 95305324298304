import { PulseWaveAssessment } from "@cur8/rich-entity";
import { createContext } from "react";
import { CardioSignals } from "../../hooks/useCardioSignals";
import { useRange } from "./lib";

export type RangeContextValue = ReturnType<typeof useRange>;
export const Context = createContext<RangeContextValue | null>(null);

interface RangeContextProps {
  assessment: PulseWaveAssessment;
  signals: CardioSignals;
  children: React.ReactNode;
}

export default function RangeContext({
  assessment,
  signals,
  children,
}: RangeContextProps) {
  const value = useRange({ assessment, signals });

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
