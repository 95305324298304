import { Sex } from "@cur8/rich-entity";

export const SexBins = {
  MALE: "male",
  FEMALE: "female",
} as const;

export type SexBinsType = (typeof SexBins)[keyof typeof SexBins];

export function mapSexToModel(sex: Sex) {
  if (sex === Sex.Female) {
    return SexBins.FEMALE;
  }

  if (sex === Sex.Male) {
    return SexBins.MALE;
  }

  throw new Error("Not valid sex for model");
}
