import { Pressure } from "@cur8/measurements";

interface BodyMetricPressureProps {
  pressure?: Pressure;
  precision?: number;
}

export default function BodyMetricPressure({
  pressure,
  precision = 0,
}: BodyMetricPressureProps) {
  return (
    <span>
      {pressure ? pressure.mmHg.toFixed(precision) : "--"}
      &nbsp; mmHg
    </span>
  );
}
