import { useNav } from "@pomle/react-router-paths";
import { DateTime } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import PatientName from "render/fragments/patient/PatientName";
import PreviousVisitSummary from "render/fragments/visit/PreviousVisitSummary/PreviousVisitSummary";
import { getPreviousSummaryFor } from "render/fragments/visit/PreviousVisitSummary/summary";
import VisitForm from "render/fragments/visit/VisitForm";
import { usePatient } from "render/hooks/api/usePatient";
import { useFormHandle } from "render/hooks/useFormHandle";
import { paths } from "render/routes/paths";
import Form from "render/ui/form/Form";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import BackButtonLink from "render/ui/trigger/BackButtonLink";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";

import { VisitSummary } from "@cur8/rich-entity";
import { toList } from "@pomle/react-flat-store";
import { useVisitStore } from "render/hooks/api/useVisitStore";
import { usePatientVisitSummaries } from "../PatientDetailPage/hooks/usePatientVisitSummaries";
import styles from "./styles.module.sass";

const INITIAL: VisitSummary = {
  id: "",
  patientId: "",
  summaryText: "",
  visitDate: DateTime.invalid("Unknown"),
  audit: {},
};

interface PatientVisitSummaryEditPageProps {
  patientId: string;
  summaryId: string;
}

export default function PatientVisitSummaryEditPage({
  patientId,
  summaryId,
}: PatientVisitSummaryEditPageProps) {
  const { entries: visitSummaries } = usePatientVisitSummaries(patientId);
  const { updateVisit, fetchVisit } = useVisitStore();
  const patient = usePatient(patientId);
  const [visit, setVisit] = useState(INITIAL);

  useEffect(() => {
    fetchVisit(patientId, summaryId).then(setVisit);
  }, [fetchVisit, patientId, summaryId]);

  const isFormDisabled = useMemo(() => {
    return visit === INITIAL;
  }, [visit]);

  const previousSummary = useMemo(() => {
    if (visitSummaries == null || visit.id === INITIAL.id) {
      return {
        loaded: false,
        data: undefined,
      };
    }

    return {
      loaded: true,
      data: getPreviousSummaryFor(toList(visitSummaries), visit.id),
    };
  }, [visit.id, visitSummaries]);

  const handleSave = useCallback(() => {
    return updateVisit(patientId, summaryId, visit).then(setVisit);
  }, [updateVisit, patientId, summaryId, visit]);

  const submitHandle = useFormHandle(handleSave);

  const nav = {
    patient: useNav(paths.patient.detail),
  };
  return (
    <FramedPage>
      <div className={styles.PatientVisitSummaryEditPage}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>

        <div>
          <PageHeader caption="Visit Summary" />
          <div className={styles.date}>
            {visit.visitDate.isValid
              ? visit.visitDate.toFormat("dd LLLL yyyy")
              : "-"}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.item}>
            <Form handle={submitHandle}>
              <VisitForm
                disabled={isFormDisabled}
                visit={visit}
                onChange={setVisit}
              />
              <ButtonSet>
                <SubmitButton disabled={isFormDisabled} handle={submitHandle}>
                  Save Summary
                </SubmitButton>
              </ButtonSet>
            </Form>
          </div>
          <div className={styles.item}>
            {previousSummary.loaded && (
              <PreviousVisitSummary visit={previousSummary.data} />
            )}
          </div>
        </div>
      </div>
    </FramedPage>
  );
}
