import { APITypesV1 } from "@cur8/api-client";
import { SkinBookTracking } from "@cur8/rich-entity";
import { useMemo } from "react";

const STATUS_MAP: Record<APITypesV1.SkinAppointmentStatus, string> = {
  [APITypesV1.SkinAppointmentStatus.Completed]: "Booked",
  [APITypesV1.SkinAppointmentStatus.CouldNotBook]: "Could Not Book",
  [APITypesV1.SkinAppointmentStatus.NoShow]: "No Show",
  [APITypesV1.SkinAppointmentStatus.Rebooked]: "Rebooked",
};

interface SkinBookStatusProps {
  item: SkinBookTracking;
}

export default function SkinBookAppointmentStatus({
  item,
}: SkinBookStatusProps) {
  const mappedStatus = useMemo(
    () =>
      item.skinAppointmentStatus
        ? STATUS_MAP[item.skinAppointmentStatus]
        : "Unknown",
    [item.skinAppointmentStatus]
  );

  return <span>{mappedStatus}</span>;
}
