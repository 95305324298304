import { useCallback, useEffect } from "react";
import { useAPIClient } from "render/context/APIContext";
import { APITypesV1 } from "@cur8/api-client";
import { useReporting } from "render/hooks/useReporting";
import { useStore } from "render/context/StoreContext";

interface Params {
  patientId: string;
  physicalArtefactId: string;
}

export function useLatestPhysicalArtefact({
  patientId,
  physicalArtefactId,
}: Params) {
  const { handleError } = useReporting();
  const api = useAPIClient().physicalArtefact;

  const {
    entries: { set, get },
  } = useStore().physicalArtefact;

  const fetch = useCallback(async () => {
    const physicalArtefact = await api
      .getPhysicalArtefactLatestVersion({ patientId, physicalArtefactId })
      .result.catch((error) => {
        console.warn(
          `Error fetching physicalArtefact ${physicalArtefactId} error`,
          error
        );
      });

    if (physicalArtefact) {
      set(physicalArtefactId, physicalArtefact);
    }

    return physicalArtefact;
  }, [api, set, patientId, physicalArtefactId]);

  const createVersion = useCallback(
    async (physicalArtefact: APITypesV1.CreatePhysicalArtefactRequest) => {
      await api
        .createVersion({ patientId, physicalArtefactId }, physicalArtefact)
        .result.catch(handleError);

      fetch();
    },
    [api, patientId, physicalArtefactId, handleError, fetch]
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    latestPhysicalArtefact: get(physicalArtefactId),
    fetch,
    createVersion,
  };
}
