import {
  Assignment,
  AktiiaMonitorBloodPressureAssignmentData,
} from "@cur8/rich-entity";
import { UserURI } from "@cur8/uri";
import { useUser } from "render/hooks/api/useUser";
import styles from "./styles.module.sass";

interface DoctorsNoteProps {
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>;
}

export default function DoctorsNote({ assignment }: DoctorsNoteProps) {
  const { data, audit } = assignment;
  const userUri = audit.lastModified?.userUri;

  if (!data.doctorsNote) {
    return null;
  }

  return (
    <div className={styles.DoctorsNote}>
      <div className={styles.doctor}>
        {userUri && <DoctorDetail userUri={userUri} />}
      </div>
      <div className={styles.doctorsNote}>{data.doctorsNote}</div>
    </div>
  );
}

function DoctorDetail({ userUri }: { userUri: UserURI }) {
  const user = useUser(userUri);

  return (
    <div>
      <div className={styles.note}>Doctor's note</div>
      {user.data?.displayName && (
        <div className={styles.writtenBy}>
          Written by {user.data.displayName}{" "}
        </div>
      )}
    </div>
  );
}
