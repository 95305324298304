import { clamp } from "lib/math";
import { createTimer } from "lib/timer";
import { useEffect, useState } from "react";

function linear(t: number) {
  return t;
}

interface RampUpNumberProps {
  value: number;
  duration: number;
  delay?: number;
  precision?: number;
  ease?: (t: number) => number;
}

export default function RampUpNumber({
  value,
  duration,
  delay = 0,
  precision = 1,
  ease = linear,
}: RampUpNumberProps) {
  const [output, setOutput] = useState(0);

  const [range, setRange] = useState(() => ({ start: 0, end: value }));

  useEffect(() => {
    setOutput((output) => {
      setRange(({ end }) => ({
        start: output,
        end: value,
      }));

      return output;
    });
  }, [value]);

  useEffect(() => {
    let acc = -delay;

    const offset = range.end - range.start;

    const stop = createTimer((delta) => {
      acc += delta / 1000;
      const progress = ease(clamp(acc / duration, 0, 1));

      setOutput(range.start + offset * progress);
      if (progress >= 1) {
        stop();
      }
    });

    return stop;
  }, [range, duration, delay, ease]);

  return <>{output.toFixed(precision)}</>;
}
