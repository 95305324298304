export const inRange = (
  val: number,
  min: number,
  max: number,
  { includeMax = false }: { includeMax: boolean }
) => {
  if (includeMax) {
    return val >= min && val <= max;
  }

  return val >= min && val < max;
};
