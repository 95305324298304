import React from "react";
import IconButton from "./IconButton";
import classes from "./styles.module.sass";

interface IconButtonProps extends React.ButtonHTMLAttributes<any> {
  variant?: "" | "danger";
  label?: string;
}

export default function TrashIconButton({
  variant = "",
  label,
  ...props
}: IconButtonProps) {
  return (
    <IconButton data-variant="danger" label={label} {...props}>
      <svg
        className={classes.trashIcon}
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m15 4-1.2 11.47c-.08.68-.65 1.2-1.33 1.2H5.53c-.68 0-1.25-.52-1.32-1.2L3 4M1.33 4h15.34M6 4V2c0-.37.3-.67.67-.67h4.66c.37 0 .67.3.67.67v2M9 7v7m3-7-.33 7M6 7l.33 7" />
      </svg>
    </IconButton>
  );
}
