import { useEffect, useRef } from "react";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import classes from "./styles.module.sass";

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onClear: () => void;
}

export default function SearchInput({
  value,
  onChange,
  onClear,
}: SearchInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const element = inputRef.current;
    if (element) {
      element.focus();
    }
  }, []);
  return (
    <div className={classes.SearchInput}>
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        placeholder="Search"
        spellCheck={false}
      />

      <HoverTextButton onClick={onClear}>Clear</HoverTextButton>
    </div>
  );
}
