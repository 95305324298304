import { Path, codecs } from "@pomle/paths";

export function createPaths<T extends {}>(origin: Path<T>) {
  const create = origin.append("/assignment-create", {});

  const list = origin.append("/assignment-list", {});

  const detail = origin.append("/assignment/:assignmentId", {
    assignmentId: codecs.string,
  });

  const bloodpressure = detail.append("/blood-pressure", {});

  return { create, list, detail, bloodpressure };
}
