import { APITypesV1 } from "@cur8/api-client";
import { Patient, Sex } from "@cur8/rich-entity";
import { PhysicalIdType, PhysicalIdURI } from "@cur8/uri";
import { isPhysicalIdURI } from "lib/uri/guard";
import { DateTime } from "luxon";

function filterBankId(id: string) {
  return id.replace(/[^\d]/g, "");
}

function sanitizePhysicalId(uri: PhysicalIdURI) {
  if (uri.idType === PhysicalIdType.BankId) {
    const id = filterBankId(uri.id);
    return new PhysicalIdURI(PhysicalIdType.BankId, id);
  }
  return uri;
}

export function createAutoLegacyId(date: DateTime) {
  const id = "mt" + date.toFormat("LLdd-u");
  return new PhysicalIdURI(PhysicalIdType.Legacy, id);
}

function toName({ name }: Patient): APITypesV1.CreatePatientRequest["name"] {
  return {
    firstName: name?.firstName?.trim(),
    lastName: name?.lastName?.trim(),
    displayName: name?.displayName?.trim() ?? null,
  };
}

function toContactDetails({
  contactDetails: source,
}: Patient): APITypesV1.CreatePatientRequest["contactDetails"] {
  return {
    addressLines: source.addressLines.map((s) => s.trim()),
    phoneNumbers: source.phoneNumbers.map((s) => s.trim()),
    email: source.email.trim(),
    zipCode: source.zipCode.trim(),
    countryOrRegion: source.countryOrRegion.trim(),
  };
}

function toDateOfBirth({ dateOfBirth }: Patient) {
  if (dateOfBirth) {
    return dateOfBirth.toFormat("yyyy-LL-dd");
  }
  return null;
}

const SEX_MAP: Record<Sex, APITypesV1.Sex | undefined> = {
  [Sex.Female]: APITypesV1.Sex.Female,
  [Sex.Male]: APITypesV1.Sex.Male,
  [Sex.Unknown]: undefined,
};

function toSex(patient: Patient) {
  return SEX_MAP[patient.sex];
}

export function toUpdateRequest(
  patient: Patient,
  origin: Patient
): APITypesV1.UpdatePatientDetailsRequest {
  const newPhysicalIds = patient.physicalIds
    .filter(isPhysicalIdURI)
    .map(sanitizePhysicalId)
    .map((uri) => uri.toString());

  const existingPhysicalIds = origin.physicalIds
    .filter(isPhysicalIdURI)
    .map(sanitizePhysicalId)
    .map((uri) => uri.toString());

  const physicalIdsToAdd = newPhysicalIds.filter((id) => {
    return !existingPhysicalIds.includes(id);
  });

  const physicalIdsToRemove = existingPhysicalIds.filter((id) => {
    return !newPhysicalIds.includes(id);
  });

  return {
    name: toName(patient),
    dateOfBirth: toDateOfBirth(patient),
    sex: toSex(patient),
    contactDetails: toContactDetails(patient),
    preferredLanguage: patient.preferredLanguage,
    physicalIdsToAdd,
    physicalIdsToRemove,
    groupIds: patient.groupIds,
  };
}
