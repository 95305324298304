import { APITypesV1 } from "@cur8/api-client";
import { Visit, fromAPI } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useVisits(patientId: string | undefined) {
  const { logError } = useReporting();
  const [visits, setVisits] = useState<Visit[]>();

  const api = useAPIClient();

  useEffect(() => {
    if (!patientId) {
      setVisits(undefined);
    } else {
      const req = api.visit.getPatientVisits(
        { patientId },
        {
          order: APITypesV1.SortOrder.Desc,
          pageSize: 100,
        }
      );

      req.result
        .then((result) => result.items.map(fromAPI.toVisit))
        .then(setVisits)
        .catch(logError)
        .catch(silenceAbort);

      return () => {
        req.abandon();
        setVisits(undefined);
      };
    }
  }, [api, patientId, logError]);

  return visits;
}
