import { ImmutableScan } from "@cur8/rich-entity";
import {
  propertiesPAD,
  usePADRatio,
} from "render/pages/TissuePages/hooks/usePADRatio";
import RegionTracker from "../../../components/RegionTracker";
import { Property } from "../../../lib/types";
import styles from "../../styles.module.sass";

interface SideboxProps {
  indexRemap: number[];
  onSelectProperty: (property: Property) => void;
  pixelsPerCm?: number;
  scan: ImmutableScan;
  selected: Property;
  timestamps: number[];
}

export default function Sidebox({
  indexRemap,
  onSelectProperty,
  pixelsPerCm,
  scan,
  selected,
  timestamps,
}: SideboxProps) {
  const { ratioBlood, ratioOxygen } = usePADRatio(indexRemap, scan, timestamps);

  return (
    <div className={styles.Sidebox}>
      <div className={styles.PropertyRatio}>
        <h3>Ratios</h3>
        <div data-section="properties">
          {ratioBlood ? (
            <button
              onClick={() => onSelectProperty(Property.t1BloodVolume)}
              data-selected={selected === Property.t1BloodVolume}
            >
              <label>{propertiesPAD.get(Property.t1BloodVolume)}</label>
              <div>{ratioBlood.toFixed(2)}</div>
            </button>
          ) : (
            <h3 className={styles.loading}>Loading blood concentration</h3>
          )}
          {ratioOxygen ? (
            <button
              onClick={() => onSelectProperty(Property.t1Oxygenation)}
              data-selected={selected === Property.t1Oxygenation}
            >
              <label>{propertiesPAD.get(Property.t1Oxygenation)}</label>
              <div>{ratioOxygen.toFixed(2)}</div>
            </button>
          ) : (
            <h3 className={styles.loading}>Loading oxygenation</h3>
          )}
        </div>
      </div>

      <RegionTracker allowEdit={false} pixelsPerCm={pixelsPerCm} />
    </div>
  );
}
