import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useEffect, useState } from "react";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { useAssessmentContext } from "../../context/AssessmentContext";
import styles from "./styles.module.sass";

export default function MakeAssessment() {
  const {
    createAssessment,
    isSavable,
    loadLatest,
    save,
    selected,
    setComment,
    setState,
    isModified,
  } = useAssessmentContext();
  const [text, setText] = useState<string>(createAssessment.comment ?? "");
  const [showForm, setShowForm] = useState<boolean>(
    selected.assessmentState === APITypesV1.AssessmentState.New &&
      selected.isLatestVersion
  );

  const stateCleared = useCallback(() => {
    setState(APITypesV1.AssessmentState.Cleared);
  }, [setState]);
  const stateFollowUp = useCallback(() => {
    setState(APITypesV1.AssessmentState.FollowUpRequested);
  }, [setState]);
  const stateReview = useCallback(() => {
    setState(APITypesV1.AssessmentState.ReviewRequested);
  }, [setState]);

  const textChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setText(event.target.value);
    },
    []
  );

  useEffect(() => {
    if (isModified) {
      console.debug("isModified = true");
      setShowForm(true);
    }
  }, [isModified]);

  useEffect(() => {
    const saveText = async () => {
      if (text.length > 0) {
        setComment(text);
      }
    };
    saveText();
  }, [setComment, text]);

  const reAssess = useCallback(() => {
    setShowForm(true);
  }, []);

  const saveWrapper = useCallback(() => {
    save();
    setShowForm(false);
  }, [save]);

  if (!selected.isLatestVersion) {
    return (
      <div className={styles.notLatest}>
        <p>
          <b>Viewing an old version!</b>
          Please load the latest version to make an assessments.
        </p>
        <HoverTextButton onClick={loadLatest}>Load latest</HoverTextButton>
      </div>
    );
  }

  return (
    <div className={styles.MakeAssessment}>
      {showForm ? (
        <>
          <h3>Make assessment</h3>
          <div className={styles.buttonGroup}>
            <HoverTextButton
              onClick={stateCleared}
              active={
                createAssessment.assessmentState ===
                APITypesV1.AssessmentState.Cleared
              }
            >
              Cleared
            </HoverTextButton>
            <HoverTextButton
              onClick={stateFollowUp}
              active={
                createAssessment.assessmentState ===
                APITypesV1.AssessmentState.FollowUpRequested
              }
            >
              Follow up
            </HoverTextButton>
            <HoverTextButton
              onClick={stateReview}
              active={
                createAssessment.assessmentState ===
                APITypesV1.AssessmentState.ReviewRequested
              }
            >
              Request Review
            </HoverTextButton>
          </div>
          <label>Comment</label>
          <textarea
            placeholder="Please add your comment here"
            value={text}
            onChange={textChange}
            rows={4}
          />
          <HoverTextButton onClick={saveWrapper} disabled={!isSavable()}>
            Save
          </HoverTextButton>
        </>
      ) : (
        <HoverTextButton onClick={reAssess} style={{ marginTop: "16px" }}>
          Reassess
        </HoverTextButton>
      )}
    </div>
  );
}
