import { Slot } from "@cur8/rich-entity";

interface Props {
  slot: Slot | null;
}

export default function SlotDate({ slot }: Props) {
  if (!slot) {
    return null;
  }

  return (
    <>
      {slot.startTime.toRelativeCalendar()},{" "}
      {slot.startTime.toFormat("dd LLLL, yyyy")}
    </>
  );
}
