import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import Layout, { LayoutProps } from "../Layout";
import SidePanel from "../SidePanel";
import CardioSection from "./components/CardioSection";
import styles from "./styles.module.sass";

export default function CardioLayout({ active }: LayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.CardioLayout}>
        <div className={styles.cardioPanel} data-active={active}>
          <SidePanel>
            {patient && <CardioSection patient={patient} />}
          </SidePanel>
        </div>
      </div>
    </Layout>
  );
}
