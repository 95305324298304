import shader from "./colorizer.frag.glsl?raw";
import { WebGLImageProcessor } from "./gl-image-processor";

export enum ColorMap {
  None = 0,
  Inferno = 1,
  Magma = 2,
  Viridis = 3,
  Plasma = 4,
  Parula = 5,
}

export const Colormaps = [
  ColorMap.Magma,
  ColorMap.Viridis,
  ColorMap.Inferno,
  ColorMap.Plasma,
  ColorMap.Parula,
  ColorMap.None,
];

export function createColorizer() {
  const processor = new WebGLImageProcessor(shader);

  return {
    canvas: processor.canvas,
    draw(image: HTMLImageElement, map: ColorMap, transparencyCutoff = 0.0) {
      processor.attribute("u_type", map);
      processor.attribute("u_alpha", transparencyCutoff);
      processor.draw(image);
    },
  };
}
