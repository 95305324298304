import { APITypesV1 } from "@cur8/api-client";
import {
  BoundingBoxAnnotation,
  BoundingBoxDataType,
  LineAnnotation,
  LineDataType,
  RangeAnnotation,
  RangeDataType,
  hasBoundingBox,
  hasLine,
} from "@cur8/rich-entity";
import { ScanURI } from "@cur8/uri";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useAnnotationCRUD } from "render/hooks/api/useAnnotationCRUD";
import { useAnnotation } from "../../hooks/useAnnotation";
import { useQueries } from "../../hooks/useQueries";
import { TissueURI } from "../../lib/TissueURI";
import {
  CreateTissueAnnotation,
  TissueAnnotation,
  TissueAnnotationDataType,
  TissueAnnotationDataTypes,
  TissueContextMode,
} from "../../lib/types";
import {
  compareTimeAnnotations,
  filterAnnotations,
  filterLines,
  filterRegions,
  filterTimes,
  getRoIColor,
  property2TargetProperty,
  toTissueAnnotation,
} from "../../lib/utils";

type FilteredAnnos = {
  lines: TissueAnnotation<LineAnnotation>[];
  roi: TissueAnnotation<BoundingBoxAnnotation>[];
  toi: TissueAnnotation<RangeAnnotation>[];
};

export function useValue(
  mode: TissueContextMode,
  patientId: string,
  scanId: string,
  scanVersion: string
) {
  const { annotations, fetch, setAnnotations } = useAnnotation(
    patientId,
    scanId,
    scanVersion
  );
  const {
    create: crudCreate,
    replace: crudReplace,
    remove: crudRemove,
  } = useAnnotationCRUD();

  const { property, selectedAnnoId, setSelectedAnno } = useQueries();

  const select = useCallback(
    (id: string | undefined) => {
      setSelectedAnno(id);
    },
    [setSelectedAnno]
  );

  const [filtered, setFiltered] = useState<FilteredAnnos>({
    lines: [],
    roi: [],
    toi: [],
  } as FilteredAnnos);
  const [newAnno, setNewAnno] = useState<CreateTissueAnnotation>();

  const getAnnoURI = useCallback(
    (label: string = "") => {
      const tprop = property2TargetProperty(property);
      return new TissueURI(tprop, label);
    },
    [property]
  );

  const create = useCallback(
    (
      type: TissueAnnotationDataType,
      data?: LineDataType | BoundingBoxDataType | RangeDataType,
      label: string = ""
    ) => {
      if (!data) {
        switch (type) {
          case TissueAnnotationDataType.Line:
            data = {} as LineDataType;
            break;
          case TissueAnnotationDataType.Region:
            data = {} as BoundingBoxDataType;
            break;
          case TissueAnnotationDataType.Time:
            data = {} as RangeDataType;
            break;
          default:
            throw new Error("Invalid type");
        }
      }
      // Deselect anno
      select(undefined);

      const uri = getAnnoURI(label);
      const anno: CreateTissueAnnotation = {
        annotation: {
          applicationSpecificTarget: uri.toString(),
          data: data,
        },
        color: getRoIColor(
          filterAnnotations(annotations, property, type).length
        ),
        label: uri.label,
        property: uri.property,
        type: type,
      };
      setNewAnno(anno);
      return anno;
    },
    [annotations, getAnnoURI, property, select]
  );

  const setLabel = useCallback(
    (label: string) => {
      if (!newAnno) {
        throw new Error("No annotation created");
      }
      const uri = new TissueURI(newAnno.property, label);
      const na = { ...newAnno, label: label };
      na.annotation.applicationSpecificTarget = uri.toString();
      setNewAnno(na);
    },
    [newAnno, setNewAnno]
  );

  const setData = useCallback(
    (data: TissueAnnotationDataTypes) => {
      if (!newAnno) {
        throw new Error("No annotation created");
      }
      const na = { ...newAnno.annotation, data: data };
      setNewAnno({ ...newAnno, annotation: na });
    },
    [newAnno, setNewAnno]
  );

  const cancelCreate = useCallback(() => {
    setNewAnno(undefined);
  }, []);

  const crudCreateHandler = useCallback(
    (appTarget: string, data: TissueAnnotationDataTypes) => {
      return crudCreate(patientId, {
        acceptState: APITypesV1.AcceptState.Accepted,
        applicationSpecificTarget: appTarget,
        data,
        targetURI: new ScanURI(patientId, scanId, scanVersion),
      });
    },
    [crudCreate, patientId, scanId, scanVersion]
  );

  const save = useCallback(() => {
    if (!newAnno) {
      throw new Error("No annotation created");
    }
    return crudCreateHandler(
      newAnno.annotation.applicationSpecificTarget,
      newAnno.annotation.data
    ).then((anno) => {
      setNewAnno(undefined);
      const ta = toTissueAnnotation(anno, annotations.length);
      setAnnotations([...annotations, ta]); // Add the newly created annotation to the list
      select(anno.id);
      return true;
    });
  }, [
    annotations,
    crudCreateHandler,
    newAnno,
    select,
    setAnnotations,
    setNewAnno,
  ]);

  const createAndSave = useCallback(
    (
      type: TissueAnnotationDataType,
      data?: LineDataType | BoundingBoxDataType | RangeDataType,
      label: string = ""
    ) => {
      const anno = create(type, data, label);
      return crudCreateHandler(
        anno.annotation.applicationSpecificTarget,
        anno.annotation.data
      ).then((anno) => {
        setNewAnno(undefined);
        const ta = toTissueAnnotation(anno, annotations.length);
        setAnnotations([...annotations, ta]); // Add the newly created annotation to the list
        select(anno.id);
        return true;
      });
    },
    [annotations, create, crudCreateHandler, select, setAnnotations]
  );

  const replace = useCallback(
    async (anno: TissueAnnotation) => {
      if (anno.annotation.acceptState === APITypesV1.AcceptState.Proposed) {
        anno.annotation.acceptState = APITypesV1.AcceptState.Accepted;
      }
      anno.annotation.applicationSpecificTarget = new TissueURI(
        anno.property,
        anno.label
      ).toString();
      const editAnno = await crudReplace(
        patientId,
        anno.annotation.id,
        anno.annotation
      );
      fetch();
      select(editAnno.id);
      return true;
    },
    [crudReplace, fetch, patientId, select]
  );

  const move = useCallback(
    (id: string, data: BoundingBoxDataType | LineDataType) => {
      const updated = annotations.find((anno) => anno.annotation.id === id);
      if (!updated) {
        return;
      }
      if (hasBoundingBox(updated.annotation)) {
        updated.annotation.data = data as BoundingBoxDataType;
      } else if (hasLine(updated.annotation)) {
        updated.annotation.data = data as LineDataType;
      } else {
        return;
      }
      return replace(updated);
    },
    [annotations, replace]
  );

  const remove = useCallback(
    (id: string) => {
      return crudRemove(patientId, id).then((res: any) => {
        if (res.ok) {
          if (selectedAnnoId === id) {
            select(undefined);
          }
          fetch();
          return true;
        }
      });
    },
    [crudRemove, fetch, patientId, select, selectedAnnoId]
  );

  useEffect(() => {
    let rois = filterRegions(annotations, property);
    if (mode === TissueContextMode.PAD) {
      // Filter out the pipeline created annotation labled "thenar"
      rois = rois.filter((r) => r.label !== "thenar");
    }
    setFiltered({
      lines: filterLines(annotations, property),
      roi: rois,
      toi: filterTimes(annotations).sort(compareTimeAnnotations),
    });
  }, [annotations, mode, property]);

  return {
    annotations,
    cancelCreate,
    create,
    createAndSave,
    crudCreateHandler,
    fetch,
    filtered,
    getAnnoURI,
    mode,
    move,
    newAnno,
    remove,
    replace,
    save,
    select,
    selected: selectedAnnoId,
    setData,
    setLabel,
  };
}

type TissueAnnotationContextValue = ReturnType<typeof useValue>;

export const Context = createContext<TissueAnnotationContextValue | null>(null);

export function useTissueAnnotationContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAnnotationContext without AnnotationContext");
  }
  return context;
}
