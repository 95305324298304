import { APIFetchError } from "@cur8/api-client";
import styles from "./styles.module.sass";

interface RequestErrorNotificationProps {
  comment: React.ReactNode;
  error: APIFetchError;
  body: React.ReactNode;
}

export default function RequestErrorNotification({
  comment,
  error,
  body,
}: RequestErrorNotificationProps) {
  return (
    <div className={styles.RequestErrorNotification}>
      <div>{comment}</div>
      <div>
        <b>Status: {error.response.status}</b>
      </div>

      <div>{error.response.statusText}</div>

      <div className={styles.body}>{body}</div>
    </div>
  );
}
