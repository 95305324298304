import React, { useCallback } from "react";
import { useAsyncHandle } from "./useAsyncHandle";

export type FormHandle = ReturnType<typeof useFormHandle>;

export function useFormHandle<T, A extends unknown[], P extends Promise<T>>(
  resolver: (...args: A) => P
) {
  const { busy, promise, callback } = useAsyncHandle(resolver);

  const onSubmit = useCallback(
    (event: React.FormEvent, ...args: A) => {
      event.preventDefault();
      callback(...args);
    },
    [callback]
  );

  return {
    busy,
    promise,
    onSubmit,
  };
}
