import { Risk } from "@cur8/health-risks-calc";
import { Unit } from "@cur8/measurements";
import {
  AggregateEntry,
  CombinedPressure,
  MergedEntry,
  MergedRecord,
} from "lib/doctor-scribe/types";
import { Deviation } from "lib/health-risk";
import { removeEntries } from "./resultHelpers";

const HIGH_PRESSURE_LIMIT = 24;
const LOW_PRESSURE_LIMIT = 8;
const DIFFERENCE_LIMIT = 4;

export function addCombinedEyePressure(record: MergedRecord): MergedRecord {
  const leftRecords = record["risk_assessment.eye_pressure.left"];
  const rightRecords = record["risk_assessment.eye_pressure.right"];

  if (
    !leftRecords ||
    !rightRecords ||
    Object.keys(leftRecords).length !== Object.keys(rightRecords).length
  ) {
    //console.warn("unequal number of left- and right grip records!");
    return record;
  }

  const newRecords: AggregateEntry[] = [];

  for (let i = 0; i < Object.keys(leftRecords).length; i++) {
    const leftPressure = (
      leftRecords[i].value as Unit<"risk_assessment.eye_pressure.left">
    ).mmHg;
    const rightPressure = (
      rightRecords[i].value as Unit<"risk_assessment.eye_pressure.right">
    ).mmHg;

    const maxPressure = Math.max(rightPressure, leftPressure);
    const minPressure = Math.min(rightPressure, leftPressure);
    const diffPressure = Math.abs(rightPressure - leftPressure);

    let risk = Risk.Normal;
    let deviation = Deviation.None;
    const largeDiff = diffPressure > DIFFERENCE_LIMIT;

    if (maxPressure > HIGH_PRESSURE_LIMIT) {
      deviation = Deviation.Above;
      risk = Risk.Risk;
    } else if (minPressure < LOW_PRESSURE_LIMIT) {
      deviation = Deviation.Below;
      risk = Risk.Risk;
    } else if (largeDiff) {
      risk = Risk.Risk;
    }

    newRecords.push({
      timestamp: leftRecords[i].timestamp,
      value: {
        left: { mmHg: leftPressure },
        right: { mmHg: rightPressure },
      },
      risk,
      deviation,
    });
  }

  record["risk_assessment.eye_pressure"] = newRecords as MergedEntry[];

  return removeEntries(record, [
    "risk_assessment.eye_pressure.left",
    "risk_assessment.eye_pressure.right",
  ]);
}

export function eyePressureReferral(record: MergedRecord) {
  const eyePressure = record?.["risk_assessment.eye_pressure"];

  if (
    eyePressure &&
    eyePressure.length > 0 &&
    eyePressure[0].risk !== Risk.Normal
  ) {
    const pressure = eyePressure[0].value as CombinedPressure;
    const diff = Math.abs(pressure.left.mmHg - pressure.right.mmHg);
    const max = Math.max(pressure.left.mmHg, pressure.right.mmHg);
    const min = Math.min(pressure.left.mmHg, pressure.right.mmHg);

    if (diff > DIFFERENCE_LIMIT) {
      return `Your eye pressure needs further investigation at a specialized eye clinic. The investigation will conclude if there is a risk of developing glaucoma ("grön starr in Swedish"). Contact any region-associated clinic through www.1177.se/hittavard (then type "ögonmottagningar"). Tell them that your eye pressure after control with iCare 200 is ${pressure.left.mmHg} mmHg on the left side and ${pressure.right.mmHg} mmHg on the right side, with a side difference of ${diff} mmHg.`;
    } else if (min < LOW_PRESSURE_LIMIT || max > HIGH_PRESSURE_LIMIT) {
      return `Your eye pressure needs further investigation at a specialized eye clinic. The investigation will conclude if there is a risk of developing glaucoma ("grön starr in Swedish"). Contact any region-associated clinic through www.1177.se/hittavard (then type "ögonmottagningar"). Tell them that your eye pressure after control with iCare 200 is ${pressure.left.mmHg} mmHg on the left side and ${pressure.right.mmHg} mmHg on the right side.`;
    } else {
      return undefined;
    }
  }
  return undefined;
}
