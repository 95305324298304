import { APITypesV1 } from "@cur8/api-client";
import { Patient, Sex } from "@cur8/rich-entity";
import { PhysicalIdURI } from "@cur8/uri";
import { isPhysicalIdURI } from "lib/uri/guard";
import { useCallback, useEffect, useMemo, useState } from "react";
import SexRadioInput from "render/fragments/form/input/SexRadioInput";
import { usePatientStore } from "render/hooks/api/usePatientStore";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useUserInput } from "render/hooks/useUserInput";
import Field from "render/ui/form/Field";
import Fieldset from "render/ui/form/Fieldset";
import Input from "render/ui/form/Input";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";
import PhysicalIdsEditor from "./components/PhysicalIdsEditor";
import { Entries, toEntries, toPatient } from "./conversion";
import styles from "./styles.module.sass";

const INITIAL: Patient = {
  patientId: "",
  name: {
    firstName: "",
    lastName: "",
  },
  physicalIds: [],
  contactDetails: {
    phoneNumbers: [],
    addressLines: [],
    countryOrRegion: "",
    zipCode: "",
    email: "",
  },
  sex: Sex.Unknown,
  consent: {
    createdAt: undefined,
    expiresAt: undefined,
  },
  primaryConsentRuleset: undefined,
  preferredLanguage: undefined,
  preferredCountry: "",
  preferredUnitSystem: APITypesV1.UnitSystem.Metric,
  allergies: [],
  ethnicities: [],
  groupIds: [],
  flags: [],
  hasPasskey: false,
  hasVerifiedIdentity: false,
};

interface AccountSectionProps {
  patientId: string;
}

export default function AccountSection({ patientId }: AccountSectionProps) {
  const { fetchPatient, updatePatient } = usePatientStore();

  const [patient, setPatient] = useState<Patient>(INITIAL);

  useEffect(() => {
    fetchPatient(patientId).then(setPatient);

    return () => {
      setPatient(INITIAL);
    };
  }, [fetchPatient, patientId]);

  const entries = useMemo(() => {
    return toEntries(patient);
  }, [patient]);

  const handleEntries = useCallback((entries: Entries) => {
    setPatient((patient) => toPatient(entries, patient));
  }, []);

  const physicalIds = patient.physicalIds.filter(isPhysicalIdURI);

  const handlePhysicalIds = useCallback((physicalIds: PhysicalIdURI[]) => {
    setPatient((patient) => ({
      ...patient,
      physicalIds,
    }));
  }, []);

  const handleSex = useCallback(
    (sex: Sex) => {
      setPatient({
        ...patient,
        sex,
      });
    },
    [patient]
  );

  const userInput = useUserInput(entries, handleEntries);

  const handleSave = useCallback(() => {
    /*
     ** We are setting groupIds to undefined here because there is an issue with
     ** Doctors not having access to receive all groupIds this resetting it for patients
     ** and corrupting their data.
     ** We can not solve this on the API level, so this is a temp fix
     ** If not fixed before 2023-05-01, remind!
     */
    return updatePatient({ ...patient, groupIds: undefined as any }).then(
      setPatient
    );
  }, [updatePatient, patient]);

  const handle = useFormHandle(handleSave);
  return (
    <div className={styles.AccountSection}>
      <form onSubmit={handle.onSubmit} autoComplete="off">
        <Fieldset legend="Identity">
          <Field label="Preferred Name">
            <Input>
              <input type="text" {...userInput.displayName} />
            </Input>
          </Field>

          <Field label="First Name">
            <Input>
              <input type="text" {...userInput.firstName} />
            </Input>
          </Field>

          <Field label="Last Name">
            <Input>
              <input type="text" {...userInput.lastName} />
            </Input>
          </Field>

          <Field label="Date of Birth">
            <Input>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                {...userInput.dateOfBirth}
              />
            </Input>
          </Field>

          <Field label="Sex">
            <SexRadioInput sex={patient.sex} onChange={handleSex} />
          </Field>
        </Fieldset>

        <Fieldset legend="Contact">
          <Field label="Phone number">
            <Input>
              <input type="text" placeholder="+46" {...userInput.phoneNo} />
            </Input>
            <small>
              Phone number should start with a country code, e.g. +46
            </small>
          </Field>

          <Field label="Email">
            <Input>
              <input type="text" {...userInput.email} />
            </Input>
          </Field>
        </Fieldset>

        <Fieldset legend="Database">
          <Field label="Physical Identification">
            <PhysicalIdsEditor
              physicalIdURIs={physicalIds}
              onChange={handlePhysicalIds}
            />
          </Field>

          <Field label="Groups">
            Reach out to @oncall for modifying groups
          </Field>
        </Fieldset>

        <ButtonSet>
          <SubmitButton handle={handle}>Save Member Info</SubmitButton>
        </ButtonSet>
      </form>
    </div>
  );
}
