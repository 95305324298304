import { ScanSequence } from "@cur8/api-client";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

interface useScanSequenceProps {
  deviceId: string;
  recordingId: string;
}

export function useScanSequence({
  recordingId,
  deviceId,
}: useScanSequenceProps) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<ScanSequence>>();

  useEffect(() => {
    const request = api.recording.fetchScanSequence({ deviceId, recordingId });

    request.result
      .then((data) => {
        setResult({ data });
      })
      .catch(silenceAbort);

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [api, recordingId, deviceId]);

  return result;
}
