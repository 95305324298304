import { Box } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { useCallback, useEffect, useState } from "react";
import { useMessagePopup } from "render/pages/TissuePages/hooks/useMessagePopup";
import { ImageSize } from "render/pages/TissuePages/lib/types";
import {
  getColorName,
  getRoIColor,
  roiWithinBoundry,
} from "render/pages/TissuePages/lib/utils";
import { useAssessmentContext } from "../../../../context/AssessmentContext";
import { useAssessmentNav } from "../../../../hooks/useAssessmentNav";
import colors from "../../colors.module.sass";
import styles from "./styles.module.sass";

interface SvgRectProps {
  box: Box;
  canvasSize: ImageSize;
  isSelected: boolean;
  roiName: string;
  scale: number;
}

export default function SvgRect({
  box,
  canvasSize,
  isSelected,
  roiName,
  scale,
}: SvgRectProps) {
  const { emitDialog } = useMessagePopup();
  const { setSelectedRoi } = useAssessmentNav();
  const { moveRoI } = useAssessmentContext();

  const [action, setAction] = useState<null | "resize" | "move">(null);
  const [movieBox, setMovieBox] = useState<Box>(box);
  useEffect(() => {
    setMovieBox(box);
  }, [box]);

  const [strokeWidth, setStrokeWidth] = useState<number>(2 / scale);
  useEffect(() => {
    if (isSelected) {
      setStrokeWidth(scale > 1 ? 4 : 3 / scale);
    } else {
      setStrokeWidth(2 / scale);
    }
  }, [isSelected, scale]);

  const movePointerDown = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1 || !isSelected || action) {
        return;
      }
      event.preventDefault();
      event.currentTarget.setPointerCapture(event.pointerId);
      setAction("move");
    },
    [action, isSelected]
  );

  const movePointerMove = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1 || !isSelected || action !== "move") {
        return;
      }
      event.preventDefault();
      let bx = Math.round(movieBox.x + event.movementX / scale);
      let by = Math.round(movieBox.y + event.movementY / scale);
      const b = new Box(bx, by, movieBox.w, movieBox.h);
      if (roiWithinBoundry(b, canvasSize)) {
        setMovieBox(b);
      } else {
        emitDialog(
          "Can't move RoI",
          "The RoI seams to be out of bounds. It needs to be completely within the image."
        );
        setAction(null);
        setMovieBox(box);
      }
    },
    [
      action,
      box,
      canvasSize,
      emitDialog,
      isSelected,
      movieBox.h,
      movieBox.w,
      movieBox.x,
      movieBox.y,
      scale,
    ]
  );

  const movePointerUp = useCallback(
    (event: React.PointerEvent) => {
      event.currentTarget.releasePointerCapture(event.pointerId);
      if (!isSelected || !action) {
        return;
      }
      event.preventDefault();
      setAction(null);
      moveRoI(roiName, movieBox);
    },
    [isSelected, action, moveRoI, roiName, movieBox]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (!isSelected) {
        event.preventDefault();
        setSelectedRoi(roiName);
      }
    },
    [isSelected, roiName, setSelectedRoi]
  );

  return (
    <g className={classNames(styles.roi, isSelected ? styles.selected : "")}>
      <rect
        className={classNames(colors.fill, colors.stroke)}
        data-color={getColorName(getRoIColor(roiName === "forearm" ? 0 : 1))}
        onClick={handleClick}
        onPointerDown={movePointerDown}
        onPointerMove={movePointerMove}
        onPointerUp={movePointerUp}
        x={movieBox.x}
        y={movieBox.y}
        width={movieBox.w}
        height={movieBox.h}
        strokeWidth={strokeWidth}
      />
    </g>
  );
}
