export function createTimer(onRender: (deltaTime: number) => void) {
  let stopped = false;
  let lastTime = NaN;

  const step = 1000 / 60;
  const bail = 1000;

  let acc = 0;

  function update(time: number) {
    if (stopped) {
      return;
    }

    if (lastTime) {
      const deltaTime = time - lastTime;

      if (deltaTime < bail) {
        acc += deltaTime;

        while (acc > step) {
          onRender(step);
          acc -= step;
        }
      }
    }

    lastTime = time;

    requestAnimationFrame(update);
  }

  requestAnimationFrame(update);

  return () => {
    stopped = true;
  };
}
