/**
 * Finds the key of a given value in an object.
 *
 * @param object - The object to search in.
 * @param value - The value to find the key for.
 * @returns The key of the value if found, otherwise undefined.
 *
 * @example
 * const object = { a: 1, b: 2, c: 3 };
 * const key = getKeyByValue(object, 2); // Returns 'b'
 */
export function getKeyByValue<T>(object: Record<string, T>, value: T) {
  return Object.keys(object).find((key) => object[key] === value);
}
