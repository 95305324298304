import { ImmutableScan } from "@cur8/rich-entity";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { ValidScanTypes, parseScanType } from "lib/scanType";
import { isRecordingURI } from "lib/uri/guard";
import { paths } from "render/routes/paths";

interface ScanLinksProps {
  scan: ImmutableScan;
}

export default function ScanLinks({ scan }: ScanLinksProps) {
  const patientId = scan.patientId;
  const scanId = scan.id;
  const scanVersion = scan.version;

  const nav = {
    cardio: useNav(paths.patient.scans.cardio),
    tissue: {
      regular: useNav(paths.patient.scans.tissue.regular),
      mind: useNav(paths.patient.scans.tissue.mind),
      pad: useNav(paths.patient.scans.tissue.pad),
    },
    atlas: useNav(paths.patient.recording.atlas),
  };

  const scanType = parseScanType(scan.scanType);
  if (scanType?.type === ValidScanTypes.Cardio) {
    return (
      <>
        <PathLink to={nav.cardio.to({ patientId, scanId, scanVersion })}>
          <b>Cardio</b>
        </PathLink>
      </>
    );
  }

  if (scanType?.type === ValidScanTypes.Tissue) {
    let label = "Tissue";
    let tissueNav = nav.tissue.regular;
    if (scanType.subtype === "mind") {
      tissueNav = nav.tissue.mind;
      label = "Tissue/MIND";
    } else if (scanType.subtype === "pad") {
      tissueNav = nav.tissue.pad;
      label = "Tissue/PAD";
    }
    return (
      <>
        <PathLink to={tissueNav.to({ patientId, scanId, scanVersion })}>
          <b>{label}</b>
        </PathLink>
      </>
    );
  }

  if (scanType?.type === ValidScanTypes.Skin) {
    const recordingURI = scan.sourceUris.filter(isRecordingURI).at(0);

    if (recordingURI) {
      const { recordingId, deviceId } = recordingURI;

      return (
        <>
          <PathLink to={nav.atlas.to({ patientId, recordingId, deviceId })}>
            <b>Atlas</b>
          </PathLink>
        </>
      );
    }
  }

  return null;
}
