import { APITypesV1 } from "@cur8/api-client";
import { MetricName } from "@cur8/measurements";
import { fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { toMetric } from "lib/metric";

type Query<M extends MetricName> = APITypesV1.MeasurementQueryParams & {
  metricName: M;
};

export function queryMetrics<M extends MetricName>(
  api: APIClient["measurement"],
  query: Query<M>
) {
  return api.queryMeasurements(query).result.then((response) => {
    return response.items.map(fromAPI.toMeasurement).map((measurement) => {
      return toMetric<M>(query.metricName, measurement);
    });
  });
}
