import { clamp } from "lib/math";
import { useCallback, useState } from "react";
import { MINIMUM_SELECTION } from "../constants";
import styles from "./styles.module.sass";

export type Range = [number, number];

enum Cursor {
  crosshair = "crosshair",
  grabbing = "grabbing",
  grab = "grab",
  resizeLeft = "resizeLeft",
  resizeRight = "resizeRight",
}

interface DataWindowProps {
  children: React.ReactNode;
  range: Range;
  onRange: React.Dispatch<React.SetStateAction<Range>>;
}

export default function DataWindow({
  children,
  range,
  onRange,
}: DataWindowProps) {
  const [dragPoint, setDragPoint] = useState<number>();
  const [cursor, setCursor] = useState<Cursor>(Cursor.crosshair);

  const isInSelection = useCallback(
    (pos: number) => {
      return pos > Math.min(...range) && pos < Math.max(...range);
    },
    [range]
  );

  const EDGE_MARGIN = 0.01;

  const isAtLeftEdge = useCallback(
    (pos: number) => {
      const left = Math.min(...range);
      return Math.abs(pos - left) < EDGE_MARGIN;
    },
    [range]
  );

  const isAtRightEdge = useCallback(
    (pos: number) => {
      const right = Math.max(...range);
      return Math.abs(pos - right) < EDGE_MARGIN;
    },
    [range]
  );

  const handlePointer = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const bounds = event.currentTarget.getBoundingClientRect();
      const pos = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);

      if (event.type === "pointerdown") {
        setDragPoint(undefined);
        if (isAtLeftEdge(pos)) {
          setCursor(Cursor.resizeLeft);
        } else if (isAtRightEdge(pos)) {
          setCursor(Cursor.resizeRight);
        } else if (isInSelection(pos)) {
          setDragPoint(pos - Math.min(...range));
          setCursor(Cursor.grabbing);
        } else {
          setCursor(Cursor.crosshair);
          onRange([pos, pos]);
        }
      } else if (event.type === "pointermove") {
        if (!!event.buttons) {
          if (cursor === Cursor.resizeLeft) {
            onRange((prev) => {
              const end = Math.max(...prev);
              return [pos, end];
            });
          } else if (cursor === Cursor.resizeRight) {
            onRange((prev) => {
              const start = Math.min(...prev);
              return [start, pos];
            });
          } else if (dragPoint === undefined) {
            onRange((prev) => {
              return [prev[0], pos];
            });
          } else {
            // just moving, ensure we don't move out
            onRange((prev) => {
              const left = pos - dragPoint;
              const right = pos + Math.abs(prev[1] - prev[0]) - dragPoint;
              return left > 0 && right < 1
                ? [
                    pos - dragPoint,
                    pos + Math.abs(prev[1] - prev[0]) - dragPoint,
                  ]
                : prev;
            });
          }
        } else {
          if (isAtLeftEdge(pos) || isAtRightEdge(pos)) {
            setCursor(Cursor.resizeLeft);
          } else if (isInSelection(pos)) {
            setCursor(Cursor.grab);
          } else {
            setCursor(Cursor.crosshair);
          }
        }
      } else if (event.type === "pointerup") {
        setDragPoint(undefined);
        setCursor(isInSelection(pos) ? Cursor.grab : Cursor.crosshair);
        onRange((prev) => {
          const delta = Math.abs(prev[1] - prev[0]);
          const minDelta = MINIMUM_SELECTION;
          if (delta < minDelta) {
            return [
              clamp(pos - minDelta, 0, 1 - 2 * minDelta),
              clamp(pos + minDelta, 2 * minDelta, 1),
            ];
          } else {
            return prev;
          }
        });
      }
    },
    [
      range,
      onRange,
      dragPoint,
      setDragPoint,
      isInSelection,
      isAtLeftEdge,
      isAtRightEdge,
      cursor,
    ]
  );

  const start = range ? Math.min(...range) : 0;
  const end = range ? Math.max(...range) : 0;

  return (
    <div className={styles.DataWindow} data-cursor={cursor}>
      {range && (
        <div
          className={styles.window}
          style={{
            left: `${start * 100}%`,
            right: `${(1 - end) * 100}%`,
          }}
        />
      )}
      <div
        className={styles.content}
        onPointerDown={handlePointer}
        onPointerMove={handlePointer}
        onPointerUp={handlePointer}
      >
        {children}
      </div>
    </div>
  );
}
