import { useCallback } from "react";
import { useMetricAPI } from "../useMetricAPI";
import { Bloodwork } from "lib/types/metrics/bloodwork";
import { MetricName } from "@cur8/measurements";
import { toMetricList } from "lib/metric";

export function useBloodworkStore() {
  const { fetchMetrics, storeMetrics } = useMetricAPI();

  const fetchBloodwork = useCallback(
    async (patientId: string) => {
      async function fetchMetric<M extends MetricName>(name: M) {
        return (await fetchMetrics(patientId, name, 1))[0];
      }

      const [
        cholesterol,
        cholesterolHDL,
        CRP,
        HbA1c,
        HDL,
        LDL,
        nonHDL,
        triglycerides,
        haemoglobin,
        glucose,
        wbcTotal,
        neutrophils,
        basophils,
        monocytes,
        eosinophils,
        lymphocytes,
      ] = await Promise.all([
        fetchMetric("bloodwork.cholesterol"),
        fetchMetric("bloodwork.cholesterol_hdl"),
        fetchMetric("bloodwork.crp"),
        fetchMetric("bloodwork.hba1c"),
        fetchMetric("bloodwork.hdl"),
        fetchMetric("bloodwork.ldl"),
        fetchMetric("bloodwork.non_hdl"),
        fetchMetric("bloodwork.triglycerides"),
        fetchMetric("bloodwork.haemoglobin"),
        fetchMetric("bloodwork.glucose"),
        fetchMetric("bloodwork.white_blood_cells.total"),
        fetchMetric("bloodwork.white_blood_cells.neutrophils"),
        fetchMetric("bloodwork.white_blood_cells.basophils"),
        fetchMetric("bloodwork.white_blood_cells.monocytes"),
        fetchMetric("bloodwork.white_blood_cells.eosinophils"),
        fetchMetric("bloodwork.white_blood_cells.lymphocytes"),
      ]);

      return {
        cholesterol,
        cholesterolHDL,
        CRP,
        HbA1c,
        HDL,
        LDL,
        nonHDL,
        triglycerides,
        haemoglobin,
        glucose,
        wbcTotal,
        neutrophils,
        basophils,
        monocytes,
        eosinophils,
        lymphocytes,
      } as Bloodwork;
    },
    [fetchMetrics]
  );

  const storeBloodwork = useCallback(
    (patientId: string, metrics: Bloodwork) => {
      return storeMetrics(patientId, toMetricList(metrics));
    },
    [storeMetrics]
  );

  return { fetchBloodwork, storeBloodwork };
}
