import {
  OutputImageSize,
  SourceImageCrop,
  TranscoderImageResponse,
} from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { loadImage } from "lib/loader";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

export function usePanoramaFetch(panoramaURI: PanoramaImageURI) {
  const api = useAPIClient().transcode;

  const fetchImage = useCallback(
    async (
      size: OutputImageSize,
      crop?: SourceImageCrop
    ): Promise<TranscoderImageResponse> => {
      const { meta, blob } = await api.fetchPanorama(panoramaURI, crop, size)
        .result;
      const url = URL.createObjectURL(blob);
      return {
        meta,
        image: await loadImage(url),
      };
    },
    [api, panoramaURI]
  );

  return fetchImage;
}
