export function clamp(value: number, min: number, max: number) {
  if (value > max) {
    return max;
  }
  if (value < min) {
    return min;
  }
  return value;
}

export function mapLinear(
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number,
  { clamp: clampOutput = false } = {}
) {
  const mappedValue =
    ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;

  if (clampOutput) {
    return clamp(mappedValue, outMin, outMax);
  }

  return mappedValue;
}

/**
 * Returns evenly spaced numbers over a specified interval.
 * Based on numpy.linspace (https://numpy.org/doc/stable/reference/generated/numpy.linspace.html)
 * * linspace(0, 1, 5) => [0, 0.25, 0.5, 0.75, 1]
 */
export function linspace(
  start: number,
  stop: number,
  num: number = 50,
  endpoint: boolean = true
): number[] {
  if (num <= 0) {
    return [];
  }

  const result: number[] = [];

  const step = endpoint ? (stop - start) / (num - 1) : (stop - start) / num;

  for (let i = 0; i < num; i++) {
    result.push(start + i * step);
  }

  // If endpoint is true, manually push the stop value to ensure precision
  if (endpoint) {
    result[num - 1] = stop;
  }

  return result;
}

/**
 * Snaps a value to the nearest increment based on a rounding function.
 * * snap(5.4, 1) => 5
 * * snap(5.4, 1, Math.ceil) => 6
 * * snap(5.6, 1, Math.floor) => 5
 */
export function snap(
  value: number,
  increment: number,
  roundingFunction: (x: number) => number = Math.round
): number {
  if (increment === 0) {
    return value;
  }

  const sign = Math.sign(value);
  const magnitude = Math.abs(value);
  const snappedValue = roundingFunction(magnitude / increment) * increment;

  return snappedValue * sign;
}

export function findClosest(target: number, values: number[]) {
  return values.reduce((prev, curr) =>
    Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
  );
}

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  add(point: Point) {
    return new Point(this.x + point.x, this.y + point.y);
  }

  subtract(point: Point) {
    return new Point(this.x - point.x, this.y - point.y);
  }

  divide(point: Point) {
    return new Point(this.x / point.x, this.y / point.y);
  }

  multiply(point: Point) {
    return new Point(this.x * point.x, this.y * point.y);
  }

  distanceTo(point: Point) {
    return Math.sqrt((this.x - point.x) ** 2 + (this.y - point.y) ** 2);
  }

  multiplyScalar(scalar: number) {
    return new Point(this.x * scalar, this.y * scalar);
  }
}

export class Bounds {
  min: Point;
  max: Point;

  constructor(min: Point, max: Point) {
    this.min = min;
    this.max = max;
  }

  clamp(point: Point) {
    return new Point(
      clamp(point.x, this.min.x, this.max.x),
      clamp(point.y, this.min.y, this.max.y)
    );
  }
}

export class Box {
  x: number;
  y: number;
  w: number;
  h: number;

  constructor(x: number, y: number, w: number, h: number) {
    this.x = x;
    this.y = y;
    this.w = w;
    this.h = h;
  }

  get left() {
    return this.x;
  }

  get right() {
    return this.x + this.w;
  }

  get top() {
    return this.y;
  }

  get bottom() {
    return this.y + this.h;
  }

  intersects(box: Box) {
    return (
      this.left < box.right &&
      this.right > box.left &&
      this.top < box.bottom &&
      this.bottom > box.top
    );
  }
}

export class Line {
  a: Point;
  b: Point;

  constructor(a: Point, b: Point) {
    this.a = a;
    this.b = b;
  }

  get angle() {
    return Math.atan2(this.b.y - this.a.y, this.b.x - this.a.x);
  }

  divide(size: Point) {
    return new Line(this.a.divide(size), this.b.divide(size));
  }

  length(): number {
    return this.a.distanceTo(this.b);
  }

  multiply(size: Point) {
    return new Line(this.a.multiply(size), this.b.multiply(size));
  }

  midPoint(): Point {
    return new Point(
      Math.round((this.a.x + this.b.x) / 2),
      Math.round((this.a.y + this.b.y) / 2)
    );
  }

  // This copied from src/render/ui/presentation/BoxAreas/math.ts
  toBox(): Box {
    const maxX = Math.max(this.a.x, this.b.x);
    const maxY = Math.max(this.a.y, this.b.y);
    const minX = Math.min(this.a.x, this.b.x);
    const minY = Math.min(this.a.y, this.b.y);

    const x = minX;
    const y = minY;
    const w = maxX - minX;
    const h = maxY - minY;

    return new Box(x, y, w, h);
  }
}

export function toRelativeBox(box: Box, width: number, height: number) {
  return new Box(box.x / width, box.y / height, box.w / width, box.h / height);
}

export function toAbsoluteBox(box: Box, width: number, height: number) {
  return new Box(box.x * width, box.y * height, box.w * width, box.h * width);
}

export function roundLeast(value: number, ...sources: number[]) {
  const decimals = sources.map((num) => {
    return num.toString().split(".").at(1)?.length ?? 0;
  });

  const len = Math.min(...decimals);

  if (!isFinite(len)) {
    return value;
  }

  const pow = 10 ** len;

  return Math.round(value * pow) / pow;
}

export function distanceSq(
  a: { x: number; y: number },
  b: { x: number; y: number }
) {
  const dx = a.x - b.x,
    dy = a.y - b.y;
  return dx * dx + dy * dy;
}
