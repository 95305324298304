import { Interval } from "luxon";

interface Props {
  timeWindow: Interval;
}

export default function SlotTime({ timeWindow }: Props) {
  return (
    <>
      <time dateTime={timeWindow.start.toISO()}>
        {timeWindow.start.toFormat("HH:mm")}
      </time>
      -
      <time dateTime={timeWindow.end.toISO()}>
        {timeWindow.end.toFormat("HH:mm")}
      </time>
    </>
  );
}
