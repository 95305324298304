import { MetricName } from "@cur8/measurements";
import { useMetrics } from "render/hooks/api/useMetric";

export type PatientMetrics = ReturnType<typeof usePatientMetrics>;

function usePatientMetricHistory(patientId: string) {
  return function usePatientMetric<M extends MetricName>(metricName: M) {
    return useMetrics<M>(metricName, patientId, 3);
  };
}

export function usePatientMetrics(patientId: string) {
  const useMetric = usePatientMetricHistory(patientId);

  const body = {
    height: useMetric("body.height"),
    weight: useMetric("body.weight"),
    waist: useMetric("body.waist"),
    eyePressure: {
      left: useMetric("risk_assessment.eye_pressure.left"),
      right: useMetric("risk_assessment.eye_pressure.right"),
      wearsContactLenses: useMetric(
        "risk_assessment.eye_pressure.wears_contact_lenses"
      ),
    },
    gripStrength: {
      left: useMetric("body.grip_strength.left"),
      right: useMetric("body.grip_strength.right"),
    },
  };

  const bloodwork = {
    cholesterol: useMetric("bloodwork.cholesterol"),
    cholesterolHDL: useMetric("bloodwork.cholesterol_hdl"),

    crp: useMetric("bloodwork.crp"),
    hba1c: useMetric("bloodwork.hba1c"),
    hdl: useMetric("bloodwork.hdl"),
    ldl: useMetric("bloodwork.ldl"),
    nonHDL: useMetric("bloodwork.non_hdl"),
    triglycerides: useMetric("bloodwork.triglycerides"),
    haemoglobin: useMetric("bloodwork.haemoglobin"),
    glucose: useMetric("bloodwork.glucose"),
    whiteBloodCells: useMetric("bloodwork.white_blood_cells.total"),
    neutrophils: useMetric("bloodwork.white_blood_cells.neutrophils"),
    basophils: useMetric("bloodwork.white_blood_cells.basophils"),
    monocytes: useMetric("bloodwork.white_blood_cells.monocytes"),
    eosinophils: useMetric("bloodwork.white_blood_cells.eosinophils"),
    lymphocytes: useMetric("bloodwork.white_blood_cells.lymphocytes"),
  };

  const cardio = {
    bloodOxygen: useMetric("cardio.oxygen_saturation"),
    heartRate: useMetric("cardio.heart_rate"),
    respiratoryRate: useMetric("cardio.respiratory_rate"),
    anklePressure: {
      left: useMetric("cardio.ankle_pressure.left"),
      right: useMetric("cardio.ankle_pressure.right"),
    },
    brachialPressure: {
      left: useMetric("cardio.brachial_pressure.left"),
      right: useMetric("cardio.brachial_pressure.right"),
    },
    toePressure: {
      left: useMetric("cardio.toe_pressure.left"),
      right: useMetric("cardio.toe_pressure.right"),
    },
  };

  const risk = {
    frax: useMetric("risk_assessment.frax_score"),
    congestiveHeartFailureHistory: useMetric(
      "risk_assessment.congestive_heart_failure_history"
    ),
    ecgDiscrepancy: useMetric("risk_assessment.ecg_discrepancy"),
    diabetesHistory: useMetric("risk_assessment.diabetes_history"),
    drugInducedBleeding: useMetric("risk_assessment.drug-induced_bleeding"),
    hypertensionHistory: useMetric("risk_assessment.hypertension_history"),
    kidneyDisease: useMetric("risk_assessment.kidney_disease"),
    labileINR: useMetric("risk_assessment.labile_inr"),
    liverDisease: useMetric("risk_assessment.liver_disease"),
    majorBleedingHistory: useMetric("risk_assessment.major_bleeding_history"),
    strokeHistory: useMetric("risk_assessment.stroke_history"),
    thromboembolismHistory: useMetric(
      "risk_assessment.thromboembolism_history"
    ),
    TIAHistory: useMetric("risk_assessment.tia_history"),
    vascularDiseaseHistory: useMetric(
      "risk_assessment.vascular_disease_history"
    ),
  };

  return {
    bloodwork,
    body,
    cardio,
    risk,
  };
}
