import { SearchResult } from "render/hooks/useSearch";
import LineThrobber from "render/ui/throbber/LineThrobber";
import Results from "./components/Results";
import styles from "./styles.module.sass";

interface SearchResultsProps<T> {
  result: SearchResult<T>;
  children: (items: T[]) => React.ReactElement;
}

export default function SearchResults<T>({
  result,
  children,
}: SearchResultsProps<T>) {
  return (
    <div className={styles.SearchResults}>
      <div className={styles.busy}>{result.busy && <LineThrobber />}</div>
      <Results result={result}>{children}</Results>
    </div>
  );
}
