import { useCallback, useRef } from "react";
import BackgroundStripes from "./components/BackgroundStripes";
import DataLayer from "./components/DataLayer";
import styles from "./styles.module.sass";
import { DateTime } from "luxon";
import { classNames } from "@pomle/classnames";
import { Patient, AggregatedBloodPressureData } from "@cur8/rich-entity";

interface BloodPressureTableBodyProps {
  patient: Patient;
  days: DateTime[];
  measurements: AggregatedBloodPressureData[];
}

export default function BloodPressureTableBody({
  days,
  measurements,
  patient,
}: BloodPressureTableBodyProps) {
  const dataLayerRef = useRef<HTMLDivElement>(null);
  const top = 200;
  const bottom = 20;
  const diff = top - bottom;

  const normalize = useCallback(
    (v: number) => ((v - bottom) * 100) / diff,
    [bottom, diff]
  );
  const invert = useCallback((n: number) => 100 - n, []);
  const toY = useCallback(
    (v: number) => invert(normalize(v)),
    [invert, normalize]
  );

  const labels = [40, 60, 80, 100, 120, 140, 160, 180];

  return (
    <div className={styles.BloodPressureTableBody}>
      <div className={classNames(styles.layer, styles.backgroundLayer)}>
        <BackgroundStripes labels={labels} toY={toY} />
      </div>
      <div ref={dataLayerRef} className={styles.layer}>
        <DataLayer
          patient={patient}
          labels={labels}
          toY={toY}
          days={days}
          measurements={measurements}
        />
      </div>
    </div>
  );
}
