import React from "react";
import styles from "./styles.module.sass";

interface PopupDialogContentProps {
  head: React.ReactNode;
  body: React.ReactNode;
  buttons: React.ReactNode;
}

export default function PopupDialogContent({
  body,
  head,
  buttons,
}: PopupDialogContentProps) {
  return (
    <div className={styles.PopupDialogContent}>
      <div className={styles.content}>
        <header>{head}</header>

        <section className={styles.body}>{body}</section>

        <footer className={styles.footer}>{buttons}</footer>
      </div>
    </div>
  );
}
