import { animate, useMotionValue } from "framer-motion";
import { mapLinear } from "lib/math";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useDOMElement } from "render/hooks/useDOMElement";
import { Highlight } from "../ChartMarker/Marker";
import { Typography } from "../Typography";
import {
  ProjectionGraphContext,
  type IProjectionGraphContext,
} from "./ProjectionGraph.context";
import { ProjectionLabel } from "./components/ProjectionLabel";
import { ProjectionLine } from "./components/ProjectionLine";
import { ProjectionMarker } from "./components/ProjectionMarker";
import { ProjectionRiskThresholdMarker } from "./components/ProjectionRiskThresholdMarker";
import { ProjectionSvgPortal } from "./components/ProjectionSvgPortal";
import { ProjectionTimelineRange } from "./components/ProjectionTimelineRange";
import styles from "./styles.module.sass";

const onlyOnLocalhost = (fn: () => void) => {
  return window.location.hostname === "localhost" ? fn : () => {};
};

/**
 *  (Recommended) size for the projection chart area. Used to calculate the aspect ratio of the chart, as well as projection range values.
 */
const CHART_SIZE = { WIDTH: 650, HEIGHT: 300 };

/**
 * Duration range for the timeline animation (in seconds)
 * Actual duration will be calculated based on the seek value.
 */
const TIMELINE_DURATION = { MIN: 1.5, MAX: 2.8 };

export type AxisLabel = {
  value: number;
  label: string;
};

export interface ProjectionGraphRange {
  from: number;
  to: number;
  labelLeft?: string;
  labelRight?: string;
  highlight?: Highlight;
}

export interface ProjectionGraphProps extends React.ComponentProps<"div"> {
  xDomain: [number, number];
  yDomain: [number, number];
  xLabels: AxisLabel[];
  yRanges: ProjectionGraphRange[];
  timelineSeek?: number;
  debug?: boolean;
}

export default function ProjectionGraph({
  xLabels,
  yRanges,
  yDomain: [yMin, yMax],
  xDomain: [xMin, xMax],
  timelineSeek = 0,
  debug = false,
  children,
  className = "",
  style = {},
  ...rest
}: ProjectionGraphProps) {
  const domain = useMemo(
    () => ({
      x: [xMin, xMax],
      y: [yMin, yMax],
    }),
    [xMin, xMax, yMin, yMax]
  ) as { x: [number, number]; y: [number, number] };

  const range = useMemo(
    () => ({
      x: [0, CHART_SIZE.WIDTH],
      y: [0, CHART_SIZE.HEIGHT],
    }),
    []
  ) as { x: [number, number]; y: [number, number] };

  const X = useCallback(
    (value: number) =>
      mapLinear(value, domain.x[0], domain.x[1], range.x[0], range.x[1]),
    [domain, range]
  );

  const Y = useCallback(
    (value: number) =>
      mapLinear(value, domain.y[0], domain.y[1], range.y[0], range.y[1]),
    [domain, range]
  );

  // Invert the Y axis, so we can display SVG assets correctly (SVG has the origin at the top left corner)
  const Yflip = useCallback(
    (value: number) =>
      mapLinear(value, domain.y[0], domain.y[1], range.y[1], range.y[0]),
    [domain, range]
  );

  const Xnormalize = useCallback(
    (value: number) => mapLinear(value, domain.x[0], domain.x[1], 0, 1),
    [domain]
  );

  const Ynormalize = useCallback(
    (value: number) => mapLinear(value, domain.y[0], domain.y[1], 0, 1),
    [domain]
  );

  const Xpercent = useCallback(
    (value: number) => Xnormalize(value) * 100,
    [Xnormalize]
  );

  const Ypercent = useCallback(
    (value: number) => Ynormalize(value) * 100,
    [Ynormalize]
  );

  const [timelineState, setTimelineState] =
    React.useState<IProjectionGraphContext["timelineState"]>("idle");
  const timelineProgress = useMotionValue(timelineSeek);

  useEffect(
    () => timelineProgress.set(timelineSeek),
    [timelineProgress, timelineSeek]
  );

  const svgRef = useRef<SVGSVGElement>(null);
  const svgContainer = useDOMElement<SVGSVGElement>(svgRef);
  const timelineProgressLineRef = useRef<HTMLDivElement>(null);
  const playButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const onTimelineProgressUpdate = (value: number) => {
      if (!timelineProgressLineRef.current) {
        return;
      }
      timelineProgressLineRef.current.style.setProperty(
        "--progress",
        `${value}`
      );

      if (!playButtonRef.current) {
        return;
      }
      playButtonRef.current.style.left = `${value * 100}%`;
    };

    onTimelineProgressUpdate(timelineProgress.get());

    return timelineProgress.on("change", onTimelineProgressUpdate);
  }, [timelineProgress]);

  const timelineDuration = useMemo(
    () =>
      mapLinear(
        1 - timelineSeek,
        0,
        1,
        TIMELINE_DURATION.MIN,
        TIMELINE_DURATION.MAX,
        { clamp: true }
      ),
    [timelineSeek]
  );

  const handleTimelinePlay = () => {
    animate(timelineProgress, 1, {
      duration: timelineDuration,
      ease: [0.75, 0, 0.55, 1], // neko 75-55 curve
      onPlay: () => setTimelineState("play"),
      onComplete: () => setTimelineState("complete"),
    });
  };

  const handleTimelineReset = () => {
    timelineProgress.stop();
    timelineProgress.set(timelineSeek);
    setTimelineState("idle");
  };

  const context = useMemo(
    () => ({
      X,
      Y,
      Yflip,
      Xnormalize,
      Ynormalize,
      Xpercent,
      Ypercent,
      timelineSeek,
      domain,
      range,
      svgContainer,
      debug,
      timelineState,
      timelineProgress,
    }),
    [
      X,
      Y,
      Yflip,
      Xnormalize,
      Ynormalize,
      Xpercent,
      Ypercent,
      timelineSeek,
      timelineProgress,
      domain,
      range,
      svgContainer,
      debug,
      timelineState,
    ]
  );

  return (
    <ProjectionGraphContext.Provider value={context}>
      <div
        className={`${styles.ProjectionGraph} ${className}`}
        data-timeline-state={timelineState}
        style={{ ...style, "--timeline-duration": `${timelineDuration}s` }}
        onDoubleClick={handleTimelinePlay}
        {...rest}
      >
        <div className={styles.ranges}>
          {yRanges.map((range, index) => (
            <div
              key={index}
              className={styles.range}
              data-highlight={range.highlight}
              style={{
                bottom: `${Ypercent(range.from)}%`,
                height: `${mapLinear(
                  range.to - range.from,
                  0,
                  domain.y[1] - domain.y[0],
                  0,
                  100
                )}%`,
              }}
            >
              <div className={styles.rangeLabel} data-side="left">
                <Typography variant="body-s">{range.labelLeft}</Typography>
              </div>
              <div className={styles.rangeLabel} data-side="right">
                <Typography variant="label-m">{range.labelRight}</Typography>
              </div>
            </div>
          ))}
        </div>

        <div
          className={styles.graph}
          style={{ aspectRatio: `${CHART_SIZE.WIDTH}/${CHART_SIZE.HEIGHT}` }}
        >
          <ProjectionTimelineRange from={0.9}>
            {(isActive) => (
              <>
                {xLabels.map((label, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.labelContainer}
                      style={{ left: `${Xpercent(label.value)}%` }}
                    >
                      <div
                        className={styles.label}
                        data-strong={index === xLabels.length - 1 && isActive}
                      >
                        <Typography variant="label-m">{label.label}</Typography>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </ProjectionTimelineRange>

          <svg
            ref={svgRef}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className={styles.SvgCanvas}
            viewBox={[0, 0, CHART_SIZE.WIDTH, CHART_SIZE.HEIGHT].join(" ")}
          >
            <defs>
              <clipPath id="projection-timeline-seek-mask">
                <rect
                  x="0"
                  y="0"
                  width={timelineSeek * CHART_SIZE.WIDTH}
                  height={CHART_SIZE.HEIGHT}
                />
              </clipPath>
            </defs>
          </svg>

          {children}

          <div className={styles.timeline}>
            {timelineSeek > 0 && timelineSeek < 1 && (
              <div
                className={styles.timelineSeekLine}
                style={{ left: `${timelineSeek * 100}%` }}
              />
            )}

            {timelineSeek < 1 && (
              <button
                ref={playButtonRef}
                className={styles.timelinePlayButton}
                onClick={handleTimelinePlay}
              >
                <svg
                  width="56"
                  height="24"
                  viewBox="0 0 56 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    data-role="base"
                    width="56"
                    height="24"
                    fill="#F0F0F0"
                  />
                  <line
                    data-role="dotted-line-mask"
                    x1="10"
                    y1="12"
                    x2="48"
                    y2="12"
                    stroke="#F0F0F0"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />

                  <rect
                    data-role="pill"
                    x="5"
                    y="5"
                    width="22"
                    height="14"
                    rx="7"
                    stroke="#567E87"
                    strokeWidth="2"
                    fill="#F0F0F0"
                  />
                  <line
                    data-role="pill-animated-line"
                    x1="12"
                    y1="12"
                    x2="20"
                    y2="12"
                    stroke="#567E87"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                  <path
                    data-role="arrow"
                    d="M40 8L44 12L40 16"
                    stroke="#567E87"
                    strokeWidth="2"
                    strokeLinecap="square"
                  />
                </svg>
              </button>
            )}

            <svg className={styles.timelineBaseline} width="100%" height="2">
              <line
                x1="0"
                y1="1"
                x2="100%"
                y2="1"
                stroke="#9EBCC3"
                strokeWidth="2"
                strokeDasharray="0, 5"
                strokeLinecap="round"
              />
            </svg>

            <div
              ref={timelineProgressLineRef}
              className={styles.timelineProgressLine}
              style={{ "--progress": timelineSeek }}
            />

            <ProjectionTimelineRange from={0.9}>
              {(isActive) => (
                <svg
                  className={styles.timelineEndMarker}
                  data-strong={isActive}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <circle
                    data-role="base"
                    cx="10"
                    cy="10"
                    r="8"
                    fill="#F0F0F0"
                    stroke="#F0F0F0"
                    strokeWidth="4"
                  />

                  <circle
                    data-role="border"
                    cx="10"
                    cy="10"
                    r="4"
                    fill="#F0F0F0"
                    stroke="#9EBCC3"
                    strokeWidth="2"
                  />
                </svg>
              )}
            </ProjectionTimelineRange>
          </div>
        </div>

        <div
          className={styles.sidebarRight}
          onClick={onlyOnLocalhost(handleTimelineReset)}
        />
      </div>
    </ProjectionGraphContext.Provider>
  );
}

ProjectionGraph.Line = ProjectionLine;
ProjectionGraph.Label = ProjectionLabel;
ProjectionGraph.Marker = ProjectionMarker;
ProjectionGraph.TimelineRange = ProjectionTimelineRange;
ProjectionGraph.SvgPortal = ProjectionSvgPortal;
ProjectionGraph.RiskThresholdMarker = ProjectionRiskThresholdMarker;
