import { APITypesV1 } from "@cur8/api-client";

export function allValuesDefinedOrNull<T extends object>(obj: T): boolean {
  for (let key in obj) {
    if (obj[key] === undefined) {
      return false;
    }
  }
  return true;
}

export function toContent(response: APITypesV1.OpenAIResponse | null) {
  return response ? response.choices[0].message.content || "" : "";
}
