import { Score2 } from "@cur8/formulas";
import { Sex } from "@cur8/rich-entity";
import { clamp } from "lib/math";
import { Metric } from "lib/metric";

export const SCORE2_MAX_VALUE = 25;

export function isScore2HighRisk(age: number, score2Value: number) {
  if (age < 50) {
    return score2Value >= 7.5;
  }

  if (age < 69) {
    return score2Value >= 10;
  }

  if (age < 90) {
    return score2Value >= 15;
  }

  return false;
}

export function patientScore2({
  age,
  sex,
  metric: { nonHDL, isSmoker, brachialPressure },
}: {
  age: number;
  sex: Sex;
  metric: {
    nonHDL: Metric<"bloodwork.non_hdl">;
    isSmoker: boolean;
    brachialPressure:
      | Metric<"cardio.brachial_pressure.left">
      | Metric<"cardio.brachial_pressure.right">;
  };
}) {
  const sys = brachialPressure.unit.systolic.mmHg;
  const non = clamp(nonHDL.unit["mmol/L"], 3, 6);

  if (Number.isNaN(age)) {
    return null;
  }

  return Score2.calculate({
    sex: sex === Sex.Male ? "male" : "female",
    age,
    bloodPressure: {
      systolic: {
        mmHg: sys,
      },
    },
    nonHDLCholesterol: {
      mmolL: non,
    },
    isSmoker,
  }).score;
}
