import { APITypesV1, Side } from "@cur8/api-client";
import { SIDES } from "./rigg";

const SORT_ORDERS: APITypesV1.SortOrder[] = [
  APITypesV1.SortOrder.Asc,
  APITypesV1.SortOrder.Desc,
];

export function isSide(value: unknown): value is Side {
  return SIDES.includes(value as Side);
}

export function isSortOrder(value: unknown): value is APITypesV1.SortOrder {
  return SORT_ORDERS.includes(value as APITypesV1.SortOrder);
}
