import { ImmutableScan, fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";

export function useScanJournalNoteQuery(scan: ImmutableScan | undefined) {
  const api = useAPIClient();
  const { get, set } = useStore().journal.entries;
  const storeKey = useMemo(
    () => scan?.journalNoteId ?? `${Date.now()}`,
    [scan?.journalNoteId]
  );

  const fetchJournal = useCallback(async () => {
    if (!scan || !scan.journalNoteId) {
      return;
    }

    return api.patient
      .fetchJournalNote({
        journalItemId: scan.journalNoteId,
        patientId: scan.patientId,
      })
      .result.then(fromAPI.toJournalNoteItem)
      .then((journalItem) => {
        set(storeKey, journalItem);
      });
  }, [api.patient, scan, set, storeKey]);

  useEffect(() => {
    fetchJournal();
  }, [fetchJournal]);

  return { fetchJournal, data: get(storeKey).data ?? undefined };
}
