import { lerp, norm, sawtooth } from "lib/anim";
import { Transform } from "lib/avatar";
import { toCenter } from "lib/three";
import { createTimer } from "lib/timer";
import { createTween, createVectorTween } from "lib/tween";
import { useEffect, useMemo } from "react";
import { ANTICIPATED_POINT_COUNT } from "render/hooks/api/meshuga/usePointCloud";
import { useDelay } from "render/hooks/useDelay";
import { useAvatarContext } from "render/pages/DashboardPage/context/AvatarContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import { AvatarGeometry } from "../../geometry/AvatarGeometry";
import { AvatarPointCloudMaterial } from "../../materials/AvatarPointCloudMaterial";
import { normalizePCDBuffers } from "./point";

const POINT_SPRING_SLOW = {
  stiffness: 15,
  mass: 1000,
  friction: 35,
  precision: 0.000001,
};

const POINT_SPRING_HEART = {
  friction: 25,
  mass: 250,
  stiffness: 40,
  precision: 0.000001,
};

const POINT_SPRING_FAST = {
  stiffness: 40,
  mass: 100,
  friction: 25,
  precision: 0.000001,
};

const POINT_SIZE_NORMAL = 10;
const POINT_SIZE_HEART = 1.6;

const FALLOFF_HIDE = new THREE.Vector3(0, 0, -2000);
const FALLOFF_SHOW = new THREE.Vector3(0, 0, 1400);

type Mix = {
  avatar: number;
  scatter: number;
  heart: number;
};

function createTweens() {
  return {
    pointSize: createTween(
      { value: 0 },
      { stiffness: 15, mass: 150, friction: 35 }
    ),

    falloff: createVectorTween(FALLOFF_HIDE.clone(), {
      stiffness: 15,
      mass: 20,
      friction: 10,
    }),

    mix: createTween<Mix>(
      { avatar: 0, scatter: 1, heart: 0 },
      POINT_SPRING_SLOW
    ),

    side: { front: 1, back: 0 },
  };
}

interface AvatarPointCloudProps {
  layer: Layer;
  scene: THREE.Object3D;
  podium: THREE.Object3D;
  pointTexture: THREE.Texture;
}

export default function AvatarPointCloud({
  layer,
  scene,
  podium,
  pointTexture,
}: AvatarPointCloudProps) {
  const spatial = useAvatarContext();

  const frontPCD = spatial.front.world;
  const backPCD = spatial.back.world;
  const heartPCD = spatial.heart;
  const areas = spatial.front.smpl.areas;

  const positions = useMemo(() => {
    if (!areas) {
      return;
    }

    const heart = toCenter(areas.heart);
    heart.y = 0;
    return {
      heart,
    };
  }, [areas]);

  const tweens = useMemo(createTweens, []);

  const avatarPointCloud = useMemo(() => {
    const geometry = new AvatarGeometry(ANTICIPATED_POINT_COUNT);
    const material = new AvatarPointCloudMaterial({ pointTexture });
    const points = new THREE.Points(geometry, material);

    material.color.setStyle("#008BB7", THREE.LinearSRGBColorSpace);
    material.points.mix.position = 0;
    material.points.size = 0;
    material.wave.direction.y = 1;
    material.falloff.center.copy(FALLOFF_HIDE);
    material.falloff.near = 8000;
    material.falloff.far = 12000;

    return points;
  }, [pointTexture]);

  const pointBuffers = useMemo(() => {
    if (!frontPCD || !backPCD || !heartPCD || !positions) {
      return;
    }

    const { x, y, z } = positions.heart;
    const heartGeo = heartPCD.geometry.clone();
    heartGeo.translate(x, y, z);

    const frontGeo = frontPCD.geometry.clone();
    frontGeo.applyMatrix4(Transform.front);

    const backGeo = backPCD.geometry.clone();
    backGeo.applyMatrix4(Transform.back);

    const buffers = normalizePCDBuffers(
      ANTICIPATED_POINT_COUNT,
      frontGeo,
      backGeo,
      heartGeo
    );

    return buffers;
  }, [frontPCD, backPCD, heartPCD, positions]);

  const fadeReady = useDelay({ active: !!pointTexture, delay: 500 });

  const constructReady = useDelay({
    active: !!pointTexture && !!pointBuffers,
    delay: 5000,
  });

  useEffect(() => {
    if (fadeReady) {
      tweens.pointSize.to({ value: POINT_SIZE_NORMAL });
      tweens.falloff.to(FALLOFF_SHOW);
    }
  }, [tweens, fadeReady]);

  useEffect(() => {
    if (!pointBuffers) {
      return;
    }

    avatarPointCloud.geometry.setPointPositions("front", pointBuffers.front);
    avatarPointCloud.geometry.setPointPositions("back", pointBuffers.back);
    avatarPointCloud.geometry.setPointPositions("heart", pointBuffers.heart);
  }, [avatarPointCloud, pointBuffers]);

  useEffect(() => {
    if (!constructReady) {
      tweens.mix.to({ avatar: 0, scatter: 1, heart: 0 });
      return;
    }

    if (layer === Layer.Heart) {
      tweens.pointSize.reconfigure(POINT_SPRING_HEART);
      tweens.pointSize.to({ value: POINT_SIZE_HEART });

      const timer = setTimeout(() => {
        tweens.mix.reconfigure(POINT_SPRING_HEART);
        tweens.mix.to({ avatar: 0, scatter: 0, heart: 1 });
      }, 150);

      return () => {
        clearTimeout(timer);
      };
    } else {
      tweens.pointSize.reconfigure(POINT_SPRING_SLOW);
      tweens.mix.reconfigure(POINT_SPRING_FAST);

      tweens.pointSize.to({ value: POINT_SIZE_NORMAL });
      tweens.mix.to({
        avatar: 1,
        scatter: 0,
        heart: 0,
      });
    }
  }, [constructReady, layer, tweens]);

  useEffect(() => {
    tweens.mix.reconfigure(POINT_SPRING_SLOW);
  }, [constructReady, tweens]);

  useEffect(() => {
    const lap = Math.PI * 2;

    function toSide(rotation: number) {
      const absRotation = (rotation % lap) / lap;
      const range = [0.25, 0.75] as const;
      const margin = 0.05;

      if (absRotation < range[0] - margin) {
        return 1;
      }

      if (absRotation < range[0] + margin) {
        const n = norm(range[0] - margin, range[0] + margin, absRotation);
        return lerp(1, 0, n);
      }

      if (absRotation < range[1] - margin) {
        return 0;
      }

      if (absRotation < range[1] + margin) {
        const n = norm(range[1] - margin, range[1] + margin, absRotation);
        return lerp(0, 1, n);
      }

      return 1;
    }

    return createTimer(() => {
      const side = toSide(podium.rotation.z);

      tweens.side.front = side;
      tweens.side.back = 1 - side;
    });
  }, [tweens, podium]);

  useEffect(() => {
    podium.add(avatarPointCloud);

    return () => {
      podium.remove(avatarPointCloud);
      avatarPointCloud.geometry.dispose();
      avatarPointCloud.material.dispose();
    };
  }, [podium, avatarPointCloud]);

  useEffect(() => {
    let acc = 0;

    const avatarMaterial = avatarPointCloud.material;

    const materials = [avatarMaterial];

    return createTimer((time) => {
      acc += time;

      const s = acc / 1000;

      tweens.mix.update(time / 1000);
      tweens.falloff.update(time / 1000);
      tweens.pointSize.update(time / 1000);

      const mix = tweens.mix.value;
      const side = tweens.side;
      const falloff = tweens.falloff.value;
      const pointSize = tweens.pointSize.value;

      avatarMaterial.points.mix.scatter = mix.scatter;
      avatarMaterial.points.mix.front = mix.avatar * side.front;
      avatarMaterial.points.mix.back = mix.avatar * side.back;
      avatarMaterial.points.mix.heart = mix.heart;

      for (const material of materials) {
        material.points.size = pointSize.value * window.devicePixelRatio;

        material.falloff.center.copy(falloff);

        material.shimmer.origin = lerp(2500, -500, sawtooth(s * 0.5, 3));
        material.shimmer.width = 800; //lerp(200, 1000, (Math.sin(s * 1.4) + 1) / 2);

        material.scan.origin = lerp(9500, -500, sawtooth(s, 10));
        material.scan.width = lerp(200, 600, (Math.sin(s * 1.4) + 1) / 2);

        material.wave.progression = s * 2;
        material.time.value = s;
      }
    });
  }, [tweens, avatarPointCloud]);

  return null;
}
