import * as Risks from "@cur8/health-risks-calc";
import { Patient, Sex } from "@cur8/rich-entity";
import { isScore2HighRisk } from "lib/scores/score2";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientHealthData } from "render/hooks/patient/usePatientHealthData";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import Table from "render/ui/layouts/Table";
import ResultRow from "../ResultRow";

interface LipidPanelTableProps {
  patient?: Patient;
  metrics: PatientMetrics;
  healthData: ReturnType<typeof usePatientHealthData>;
}

export default function LipidPanelTable({
  patient,
  metrics,
  healthData,
}: LipidPanelTableProps) {
  const bloodwork = metrics.bloodwork;
  const age = useAge(patient);
  const agg = healthData.aggregates;

  const score2 = agg.score2;

  const score2Risk = useMemo(() => {
    if (age == null || score2 == null) {
      return;
    }

    return isScore2HighRisk(age, score2.score);
  }, [age, score2]);

  return (
    <Table>
      <thead>
        <tr>
          <th>Lipid panel</th>
          <th>Result</th>
          <th>Risk</th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="Total Chol"
          value={bloodwork.cholesterol?.at(0)?.unit}
          format={(unit) => unit["mmol/L"].toFixed(2)}
          unit="mmol/L"
          summarize={(unit) => {
            return {
              risk: Risks.Cholesterol.rangesFor({ age: age ?? NaN }).findRisk(
                unit
              ),
            };
          }}
        />
        <ResultRow
          caption="LDL"
          value={bloodwork.ldl?.at(0)?.unit}
          format={(value) => value["mmol/L"].toFixed(2)}
          unit="mmol/L"
          summarize={(unit) => {
            return {
              risk: Risks.LDL.rangesFor({
                age: age ?? NaN,
                isScore2HighRisk: score2Risk ?? false,
              }).findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="HDL"
          value={bloodwork.hdl?.at(0)?.unit}
          format={(value) => value["mmol/L"].toFixed(2)}
          summarize={(unit) => {
            return {
              risk: Risks.HDL.rangesFor({
                sex: patient?.sex ?? Sex.Unknown,
              }).findRisk(unit),
            };
          }}
          unit="mmol/L"
        />
        <ResultRow
          caption="Triglycerides"
          value={bloodwork.triglycerides?.at(0)?.unit}
          format={(value) => value["mmol/L"].toFixed(2)}
          summarize={(unit) => {
            return {
              risk: Risks.Triglycerides.rangesFor().findRisk(unit),
            };
          }}
          unit="mmol/L"
        />
        <ResultRow
          caption="Non-HDL"
          value={bloodwork.nonHDL?.at(0)?.unit}
          format={(value) => value["mmol/L"].toFixed(2)}
          summarize={(unit) => {
            return {
              risk: Risks.NonHDL.rangesFor({
                sex: patient?.sex ?? Sex.Unknown,
                age: age ?? NaN,
                isScore2HighRisk: score2Risk ?? false,
              }).findRisk(unit),
            };
          }}
          unit="mmol/L"
        />
        <ResultRow
          caption="Chol/HDL"
          value={bloodwork.cholesterolHDL?.at(0)?.unit}
          format={(unit) => unit.fraction.toFixed(2)}
          summarize={(unit) => {
            return {
              risk: Risks.CholesterolHDL.rangesFor().findRisk({
                ratio: unit.fraction,
              }),
            };
          }}
        />
      </tbody>
    </Table>
  );
}
