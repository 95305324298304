import { ImmutableScan } from "@cur8/rich-entity";
import { useECGPlot } from "render/hooks/api/scan/cardio/useECGPlot";
import { useFemoralPlot } from "render/hooks/api/scan/cardio/useFemoralPlot";
import { useSSNPlot } from "render/hooks/api/scan/cardio/useSSNPlot";
import StudySymbol from "render/ui/symbol/StudySymbol";
import ECGGraph from "./components/ECGGraph";
import HipAortaGraph from "./components/Hip+AortaGraph";
import styles from "./styles.module.sass";

interface CardioGraphsProps {
  scan: ImmutableScan;
}

export default function CardioGraphs({ scan }: CardioGraphsProps) {
  const ecg = useECGPlot(scan);
  const femoral = useFemoralPlot(scan);
  const ssn = useSSNPlot(scan);

  return (
    <div className={styles.CardioGraphs}>
      <div className={styles.graph}>
        <label>
          ECG <StudySymbol />
        </label>
        {ecg && <ECGGraph plot={ecg} />}
      </div>

      <div className={styles.graph}>
        <label htmlFor="hip">
          Hip <StudySymbol />
        </label>

        <label htmlFor="aorta">
          Aorta <StudySymbol />
        </label>

        {femoral && ssn && <HipAortaGraph femoral={femoral} ssn={ssn} />}
      </div>
    </div>
  );
}
