import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useLatestScans(patientId: string) {
  const api = useAPIClient();

  const [latestScans, setLatestScans] =
    useState<AsyncResult<ImmutableScan[]>>();

  const reloadLatestScans = useCallback(() => {
    const request = api.scan.queryScans({
      patientId,
      order: APITypesV1.SortOrder.Desc,
      pageSize: 1,
    });

    request.result
      .then((result) => result.items.map(fromAPI.toImmutableScan))
      .then((scans) => {
        setLatestScans({
          data: scans,
        });
      })
      .catch(silenceAbort);

    return () => {
      request.abandon();
      setLatestScans(undefined);
    };
  }, [api, patientId]);

  useEffect(() => {
    reloadLatestScans();
  }, [reloadLatestScans]);

  return { latestScans, reloadLatestScans };
}
