import { useState, useEffect, useCallback } from "react";
import { DateTime, DateTimeUnit } from "luxon";

export function useLiveTime(
  unit: DateTimeUnit,
  { zone }: { zone?: string } = {}
): DateTime {
  const getNow = useCallback(() => {
    return DateTime.now().setZone(zone);
  }, [zone]);

  const [time, setTime] = useState<DateTime>(getNow());

  useEffect(() => {
    const delay = time
      .startOf(unit)
      .plus({ [unit]: 1 })
      .diff(time, "milliseconds");

    const timer = setTimeout(() => {
      const nextTime = getNow()
        .startOf(unit)
        .plus({ [unit]: 1 });
      setTime(nextTime);
    }, delay.milliseconds);

    return () => clearTimeout(timer);
  }, [unit, time, getNow]);

  return time;
}
