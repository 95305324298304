import { Patient, Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback, useMemo } from "react";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import DropdownItem from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import CalendarAdd from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/calendar-add.svg?react";
import { useDoubleBookingConfirmation } from "../../hooks/useDoubleBookingConfirmation";
import { usePatientBookingToken } from "../../hooks/usePatientBookingToken";
import { useCreateBookingTokenPopup } from "./hooks/useCreateBookingTokenPopup";

interface Props {
  patient: Patient;
  upcomingAppointment?: Slot;
}

export default function BookPatientButton({
  patient,
  upcomingAppointment,
}: Props) {
  const { emitDialog: emitCreateBookingTokenDialog } =
    useCreateBookingTokenPopup();

  const { emitDoubleBookingConfirmation } = useDoubleBookingConfirmation();

  const bookingToken = usePatientBookingToken(patient.patientId);

  const nav = {
    schedule: useNav(paths.schedule, query.schedule),
    siteSelect: useNav(paths.scheduleSiteSelect, query.schedule),
  };

  const hasActiveBookingToken = useMemo(() => {
    if (!bookingToken.entries) {
      return false;
    }

    const openTokens = bookingToken.entries.filter(
      (token) => !token.data?.slotItemId
    );

    return openTokens.length > 0 || false;
  }, [bookingToken.entries]);

  const navigateToCalendar = useCallback(() => {
    const params = { assignPatientId: [patient.patientId] };

    nav.siteSelect.go({}, params);
  }, [nav.siteSelect, patient.patientId]);

  const checkForDoubleBooking = useCallback(() => {
    if (upcomingAppointment) {
      emitDoubleBookingConfirmation(
        patient,
        upcomingAppointment,
        navigateToCalendar
      );
      return;
    }

    navigateToCalendar();
  }, [
    upcomingAppointment,
    patient,
    emitDoubleBookingConfirmation,
    navigateToCalendar,
  ]);

  const onClickHandler = useCallback(() => {
    if (!hasActiveBookingToken) {
      emitCreateBookingTokenDialog(patient.patientId, checkForDoubleBooking);
      return;
    }

    checkForDoubleBooking();
  }, [
    checkForDoubleBooking,
    emitCreateBookingTokenDialog,
    hasActiveBookingToken,
    patient,
  ]);

  return (
    <DropdownItem onClick={onClickHandler}>
      <CalendarAdd />
      Book Premium Appointment
    </DropdownItem>
  );
}
