import { DateTime, Interval } from "luxon";
import { useCallback } from "react";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import ChevronIcon from "./assets/chevron.svg?react";
import styles from "./styles.module.sass";

interface TableNavProps {
  canNavForward: boolean;
  canNavBack: boolean;
  interval?: Interval;
  onNav: (date: DateTime) => void;
}

export default function TableNav({
  interval,
  canNavBack,
  canNavForward,
  onNav,
}: TableNavProps) {
  const navForward = useCallback(() => {
    if (!interval) {
      return;
    }
    onNav(interval.end.plus({ day: 1 }));
  }, [interval, onNav]);

  const navBack = useCallback(() => {
    if (!interval) {
      return;
    }
    onNav(interval.end.minus({ day: 1 }));
  }, [interval, onNav]);

  return (
    <div className={styles.TableNav}>
      <HoverTextButton disabled={!canNavBack} onClick={navBack}>
        <ChevronIcon className={styles.rotate} />
      </HoverTextButton>
      <HoverTextButton disabled={!canNavForward} onClick={navForward}>
        <ChevronIcon />
      </HoverTextButton>
    </div>
  );
}
