import { useState } from "react";
import { useKeyboardContext } from "render/context/KeyboardContext";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface CommentInputProps {
  onSave: (comment: string) => void;
  onCancel?: () => void;
}

export default function CommentInput({ onSave, onCancel }: CommentInputProps) {
  const { block, unblock } = useKeyboardContext();

  const [text, setText] = useState<string>("");
  return (
    <div className={styles.CommentInput}>
      <textarea
        placeholder="Add a doctor’s note..."
        value={text}
        onChange={(event) => setText(event.target.value)}
        rows={3}
        onFocus={block}
        onBlur={unblock}
      />

      <div className={styles.control}>
        <HoverTextButton onClick={() => onSave(text)}>Save</HoverTextButton>
        {onCancel && (
          <button className={styles.cancel} onClick={onCancel}>
            Cancel
          </button>
        )}
      </div>
    </div>
  );
}
