import { useEffect, useState } from "react";
import { Typography } from "../../Typography";
import { useProjectionGraphContext } from "../ProjectionGraph.context";
import styles from "../styles.module.sass";
import { ProjectionMarker } from "./ProjectionMarker";

interface ProjectionRiskThresholdMarkerProps {
  age: number;
  threshold: number;
}

export function ProjectionRiskThresholdMarker({
  age,
  threshold,
}: ProjectionRiskThresholdMarkerProps) {
  const [showMarker, setShowMarker] = useState(false);

  const { Xpercent, Ypercent, Xnormalize, timelineProgress, timelineSeek } =
    useProjectionGraphContext();

  const pointRevealThreshold = Xnormalize(age);

  useEffect(() => {
    const onTimelineProgressUpdate = (p: number) => {
      if (!showMarker && p >= pointRevealThreshold) {
        setShowMarker(true);
      }
      if (showMarker && p < pointRevealThreshold) {
        setShowMarker(false);
      }
    };

    onTimelineProgressUpdate(timelineProgress.get());

    return timelineProgress.on("change", onTimelineProgressUpdate);
  }, [timelineProgress, pointRevealThreshold, showMarker]);

  const highlight: "warning" | "none" =
    timelineSeek === 1 || pointRevealThreshold >= timelineSeek
      ? "warning"
      : "none";

  return (
    <>
      <ProjectionMarker
        point={{ x: age, y: threshold }}
        data-active={showMarker}
        highlight={highlight}
        variant="secondary"
      />

      <div
        className={styles.RiskThresholdMarkerLabel}
        data-active={showMarker}
        data-strong={highlight !== "none"}
        style={{
          left: `${Xpercent(age)}%`,
          bottom: `${Ypercent(threshold)}%`,
        }}
      >
        <Typography variant="label-m">{String(Math.round(age))}</Typography>
      </div>
    </>
  );
}
