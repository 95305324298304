import { debounce, derace } from "@pomle/throb";
import { useReporting } from "./useReporting";
import { useEffect, useMemo, useState } from "react";

type QueryResult<T> = {
  query: string;
  matches?: T[];
};

export type SearchResult<T> = {
  busy: boolean;
  query?: string;
  matches?: T[];
};

/*
Higher order hook that implements good practices for search-as-you-type.
*/
export function useSearch<Match>({
  search,
  query,
  delay,
}: {
  search: (query: string) => Promise<Match[]>;
  query: string;
  delay: number;
}): SearchResult<Match> {
  const { handleError } = useReporting();

  const [result, setResult] = useState<QueryResult<Match>>();

  const performSearch = useMemo(() => {
    const deracedSearch = derace(search);

    return debounce((query: string) => {
      deracedSearch(query)
        .then(([matches, sync]) => {
          if (!sync) {
            console.info("Out of sync result", matches);
            return;
          }

          setResult({ query, matches });
        })
        .catch(handleError);
    }, delay);
  }, [delay, search, handleError]);

  useEffect(() => {
    if (query.length > 0) {
      performSearch(query);
    }
  }, [query, performSearch]);

  if (query.length > 0) {
    return {
      busy: query !== result?.query,
      query: result?.query,
      matches: result?.matches,
    };
  }

  return {
    busy: false,
  };
}
