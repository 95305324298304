export function getTitlesForValues(
  value: number,
  prevValue?: number
): { title: string; subtitle?: string; aux?: string } {
  if (!prevValue) {
    return {
      title: "Long-term blood sugar",
      aux: "[HbA1c]",
    };
  }

  const areValuesEqual = value === prevValue;

  if (areValuesEqual) {
    return {
      title: "Maintained",
      subtitle: "long-term blood sugar",
      aux: "[HbA1c]",
    };
  }

  if (value > prevValue) {
    return {
      title: "Increased",
      subtitle: "long-term blood sugar",
      aux: "[HbA1c]",
    };
  }

  if (value < prevValue) {
    return {
      title: "Improved",
      subtitle: "long-term blood sugar",
      aux: "[HbA1c]",
    };
  }

  return {
    title: "long-term blood sugar",
    aux: "[HbA1c]",
  };
}
