import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useInitiateRefundMutation() {
  const { logError } = useReporting();
  const apiClient = useAPIClient();
  const mutate = useCallback(
    (options: { patientId: string; bookingTokenId: string; eTag: string }) => {
      return apiClient.billingV2
        .initiateRefund(options.patientId, options.bookingTokenId, options.eTag)
        .result.catch(logError);
    },
    [apiClient.billingV2, logError]
  );

  return {
    mutate,
  };
}
