import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { useQueryParams } from "@pomle/react-router-paths";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { query } from "render/routes/querys";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import AtriumTable from "./components/AtriumTable";
import ClearedTable from "./components/ClearedTable";
import DermaTable from "./components/DermaTable/DermaTable";
import OperatorTable from "./components/OperatorTable";
import OpsTable from "./components/OpsTable";
import ReferredIRLDermaBookedTable from "./components/ReferredIRLDermaBookedTable";
import Tabs, { Tab } from "./components/Tabs";
import { mergePaginatedResponseWith } from "./mergePaginatedResponseWith";
import styles from "./styles.module.sass";

export default function SkinCRMPage() {
  const [dermaResponseData, setDermaResponseData] =
    useState<APITypesV1.SkinBookQueryResultPaginatedResponse | null>(null);
  const [dermaFetchStatus, setDermaFetchStatus] = useState<"loading" | "idle">(
    "loading"
  );

  const [opsResponseData, setOpsResponseData] =
    useState<APITypesV1.SkinBookQueryResultPaginatedResponse | null>(null);
  const [opsFetchStatus, setOpsFetchStatus] = useState<"loading" | "idle">(
    "loading"
  );

  const [atriumResponseData, setAtriumResponseData] =
    useState<APITypesV1.SkinBookQueryResultPaginatedResponse | null>(null);
  const [atriumFetchStatus, setAtriumFetchStatus] = useState<
    "loading" | "idle"
  >("loading");

  const [
    referredIRLDermaBookedResponseData,
    setReferredIRLDermaBookedResponseData,
  ] = useState<APITypesV1.SkinBookQueryResultPaginatedResponse | null>(null);
  const [
    referredIRLDermaBookedFetchStatus,
    setReferredIRLDermaBookedFetchStatus,
  ] = useState<"loading" | "idle">("loading");

  const [operatorResponseData, setOperatorResponseData] =
    useState<APITypesV1.SkinBookQueryResultPaginatedResponse | null>(null);
  const [operatorFetchStatus, setOperatorFetchStatus] = useState<
    "loading" | "idle"
  >("loading");

  const [clearedResponseData, setClearedResponseData] =
    useState<APITypesV1.SkinBookQueryResultPaginatedResponse | null>(null);
  const [clearedFetchStatus, setClearedFetchStatus] = useState<
    "loading" | "idle"
  >("loading");

  const [uriParams, setURIParams] = useQueryParams(query.skinCRM);
  const activeTab = uriParams?.tab[0];

  const api = useAPIClient();

  const fetchData = useCallback(
    (params: APITypesV1.SkinBookParams) => {
      return api.skinBook.fetchSkinBook({
        pageSize: 300,
        ...params,
      }).result;
    },
    [api.skinBook]
  );

  const refreshDataForTaskGroup = useCallback(
    (taskGroup: APITypesV1.TaskGroup) => {
      switch (taskGroup) {
        case APITypesV1.TaskGroup.Derma:
          setDermaFetchStatus("loading");
          fetchData({
            taskGroup: APITypesV1.TaskGroup.Derma,
          })
            .then(setDermaResponseData)
            .finally(() => {
              setDermaFetchStatus("idle");
            });
          break;
        case APITypesV1.TaskGroup.Ops:
          setOpsFetchStatus("loading");
          fetchData({
            taskGroup: APITypesV1.TaskGroup.Ops,
          })
            .then(setOpsResponseData)
            .finally(() => {
              setOpsFetchStatus("idle");
            });
          break;
        case APITypesV1.TaskGroup.Atrium:
          setAtriumFetchStatus("loading");
          fetchData({
            taskGroup: APITypesV1.TaskGroup.Atrium,
          })
            .then(setAtriumResponseData)
            .finally(() => {
              setAtriumFetchStatus("idle");
            });
          break;
        case APITypesV1.TaskGroup.ReferredIRLDermaBooked:
          debugger;
          setReferredIRLDermaBookedFetchStatus("loading");
          fetchData({
            taskGroup: APITypesV1.TaskGroup.ReferredIRLDermaBooked,
          })
            .then(setReferredIRLDermaBookedResponseData)
            .finally(() => {
              setReferredIRLDermaBookedFetchStatus("idle");
            });
          break;
        case APITypesV1.TaskGroup.Operator:
          setOperatorFetchStatus("loading");
          fetchData({
            taskGroup: APITypesV1.TaskGroup.Operator,
          })
            .then(setOperatorResponseData)
            .finally(() => {
              setOperatorFetchStatus("idle");
            });
          break;
        case APITypesV1.TaskGroup.Cleared:
          setClearedFetchStatus("loading");
          fetchData({
            taskGroup: APITypesV1.TaskGroup.Cleared,
          })
            .then(setClearedResponseData)
            .finally(() => {
              setClearedFetchStatus("idle");
            });
          break;
      }
    },
    [fetchData]
  );

  useEffect(() => {
    if (!activeTab) {
      setURIParams({ tab: [APITypesV1.TaskGroup.Derma] });
    }
  }, [activeTab, api.skinBook, fetchData, setURIParams]);

  useEffect(() => {
    if (activeTab) {
      refreshDataForTaskGroup(activeTab as APITypesV1.TaskGroup);
    }
  }, [activeTab, refreshDataForTaskGroup]);

  useEffect(() => {
    setDermaFetchStatus("loading");
    const dermaRequest = api.skinBook.fetchSkinBook({
      pageSize: 300,
      taskGroup: APITypesV1.TaskGroup.Derma,
    });
    dermaRequest.result.then(setDermaResponseData).finally(() => {
      setDermaFetchStatus("idle");
    });

    setOpsFetchStatus("loading");
    const opsRequest = api.skinBook.fetchSkinBook({
      pageSize: 100,
      taskGroup: APITypesV1.TaskGroup.Ops,
    });
    opsRequest.result.then(setOpsResponseData).finally(() => {
      setOpsFetchStatus("idle");
    });

    setAtriumFetchStatus("loading");
    const atriumRequest = api.skinBook.fetchSkinBook({
      pageSize: 100,
      taskGroup: APITypesV1.TaskGroup.Atrium,
    });
    atriumRequest.result.then(setAtriumResponseData).finally(() => {
      setAtriumFetchStatus("idle");
    });

    setReferredIRLDermaBookedFetchStatus("loading");
    const referredIRLDermaBookedRequest = api.skinBook.fetchSkinBook({
      pageSize: 100,
      taskGroup: APITypesV1.TaskGroup.ReferredIRLDermaBooked,
    });
    referredIRLDermaBookedRequest.result
      .then(setReferredIRLDermaBookedResponseData)
      .finally(() => {
        setReferredIRLDermaBookedFetchStatus("idle");
      });

    setOperatorFetchStatus("loading");
    const operatorRequest = api.skinBook.fetchSkinBook({
      pageSize: 100,
      taskGroup: APITypesV1.TaskGroup.Operator,
    });
    operatorRequest.result.then(setOperatorResponseData).finally(() => {
      setOperatorFetchStatus("idle");
    });

    setClearedFetchStatus("loading");
    const clearedRequest = api.skinBook.fetchSkinBook({
      pageSize: 100,
      taskGroup: APITypesV1.TaskGroup.Cleared,
    });
    clearedRequest.result.then(setClearedResponseData).finally(() => {
      setClearedFetchStatus("idle");
    });

    return () => {
      dermaRequest.abandon();
      opsRequest.abandon();
      atriumRequest.abandon();
      referredIRLDermaBookedRequest.abandon();
      operatorRequest.abandon();
      clearedRequest.abandon();
    };
  }, [api.skinBook, fetchData]);

  const changeActiveTabTo = useCallback(
    (tab: APITypesV1.TaskGroup) => {
      setURIParams({ tab: [tab] });
    },
    [setURIParams]
  );

  const dermaData = useMemo(() => {
    const item = dermaResponseData?.items.find(
      (item) => item.taskGroup === APITypesV1.TaskGroup.Derma
    );
    return item && item.skinBookTrackings
      ? item.skinBookTrackings.map(fromAPI.toSkinBookTracking)
      : [];
  }, [dermaResponseData]);

  const removeItemFromDermaData = (patientId: string, scanId: string) => {
    if (dermaResponseData) {
      setDermaResponseData({
        ...dermaResponseData,
        items: dermaResponseData.items.map((item) =>
          item.taskGroup === APITypesV1.TaskGroup.Derma
            ? {
                ...item,
                skinBookTrackings: item.skinBookTrackings.filter(
                  (tracking) =>
                    tracking.patientId !== patientId ||
                    tracking.scanId !== scanId
                ),
              }
            : item
        ),
      });
    }
  };

  const opsData = useMemo(() => {
    const item = opsResponseData?.items.find(
      (item) => item.taskGroup === APITypesV1.TaskGroup.Ops
    );
    return item && item.skinBookTrackings
      ? item.skinBookTrackings.map(fromAPI.toSkinBookTracking)
      : [];
  }, [opsResponseData]);

  const atriumData = useMemo(() => {
    const item = atriumResponseData?.items.find(
      (item) => item.taskGroup === APITypesV1.TaskGroup.Atrium
    );
    return item && item.skinBookTrackings
      ? item.skinBookTrackings.map(fromAPI.toSkinBookTracking)
      : [];
  }, [atriumResponseData]);

  const referredIRLDermaBookedData = useMemo(() => {
    const item = referredIRLDermaBookedResponseData?.items.find(
      (item) => item.taskGroup === APITypesV1.TaskGroup.ReferredIRLDermaBooked
    );
    return item && item.skinBookTrackings
      ? item.skinBookTrackings.map(fromAPI.toSkinBookTracking)
      : [];
  }, [referredIRLDermaBookedResponseData]);

  const operatorData = useMemo(() => {
    const item = operatorResponseData?.items.find(
      (item) => item.taskGroup === APITypesV1.TaskGroup.Operator
    );
    return item && item.skinBookTrackings
      ? item.skinBookTrackings.map(fromAPI.toSkinBookTracking)
      : [];
  }, [operatorResponseData]);

  const clearedData = useMemo(() => {
    const item = clearedResponseData?.items.find(
      (item) => item.taskGroup === APITypesV1.TaskGroup.Cleared
    );
    return item && item.skinBookTrackings
      ? item.skinBookTrackings.map(fromAPI.toSkinBookTracking)
      : [];
  }, [clearedResponseData]);

  if (!activeTab) {
    return (
      <FramedPage>
        <PageHeader caption="Skin Book" />
        <div className={styles.SkinCRMPage}>Loading</div>
      </FramedPage>
    );
  }

  return (
    <FramedPage>
      <PageHeader caption="Skin Book" />

      <div className={styles.SkinCRMPage}>
        <Tabs>
          <Tab
            isActive={activeTab === APITypesV1.TaskGroup.Derma}
            onClick={() => changeActiveTabTo(APITypesV1.TaskGroup.Derma)}
            label={`Derma ${dermaResponseData ? `(${dermaData.length})` : ""}`}
          />
          <Tab
            isActive={activeTab === APITypesV1.TaskGroup.Ops}
            onClick={() => changeActiveTabTo(APITypesV1.TaskGroup.Ops)}
            label={`Ops ${opsResponseData ? `(${opsData.length})` : ""}`}
          />
          <Tab
            isActive={activeTab === APITypesV1.TaskGroup.Atrium}
            onClick={() => changeActiveTabTo(APITypesV1.TaskGroup.Atrium)}
            label={`Excision/IRL Derma ${
              atriumResponseData ? `(${atriumData.length})` : ""
            }`}
          />
          <Tab
            isActive={activeTab === APITypesV1.TaskGroup.Operator}
            onClick={() => changeActiveTabTo(APITypesV1.TaskGroup.Operator)}
            label={`Operator ${
              operatorResponseData ? `(${operatorData.length})` : ""
            }`}
          />
          <Tab
            isActive={activeTab === APITypesV1.TaskGroup.Cleared}
            onClick={() => changeActiveTabTo(APITypesV1.TaskGroup.Cleared)}
            label={`Cleared ${
              clearedResponseData ? `(${clearedData.length})` : ""
            }`}
          />
          <Tab
            isActive={activeTab === APITypesV1.TaskGroup.ReferredIRLDermaBooked}
            onClick={() =>
              changeActiveTabTo(APITypesV1.TaskGroup.ReferredIRLDermaBooked)
            }
            label={`Referred/IRL Derma Booked ${
              referredIRLDermaBookedResponseData
                ? `(${referredIRLDermaBookedData.length})`
                : ""
            }`}
          />
        </Tabs>

        <div>
          {activeTab === APITypesV1.TaskGroup.Derma && (
            <DermaTable
              data={dermaData}
              fetchStatus={dermaFetchStatus}
              hasMoreData={Boolean(dermaResponseData?.nextPage)}
              onLoadMore={() => {
                if (!dermaResponseData?.nextPage) {
                  return;
                }

                setDermaFetchStatus("loading");
                fetchData({
                  taskGroup: APITypesV1.TaskGroup.Derma,
                  continuationToken: dermaResponseData?.nextPage,
                })
                  .then(mergePaginatedResponseWith(dermaResponseData))
                  .then(setDermaResponseData)
                  .finally(() => {
                    setDermaFetchStatus("idle");
                  });
              }}
              onRemoveItem={removeItemFromDermaData}
            />
          )}
          {activeTab === APITypesV1.TaskGroup.Ops && (
            <OpsTable
              data={opsData}
              fetchStatus={opsFetchStatus}
              hasMoreData={Boolean(opsResponseData?.nextPage)}
              onDataRefresh={() =>
                refreshDataForTaskGroup(APITypesV1.TaskGroup.Ops)
              }
              onLoadMore={() => {
                if (!opsResponseData?.nextPage) {
                  return;
                }

                setOpsFetchStatus("loading");
                fetchData({
                  taskGroup: APITypesV1.TaskGroup.Ops,
                  continuationToken: opsResponseData?.nextPage,
                })
                  .then(mergePaginatedResponseWith(opsResponseData))
                  .then(setOpsResponseData)
                  .finally(() => {
                    setOpsFetchStatus("idle");
                  });
              }}
            />
          )}
          {activeTab === APITypesV1.TaskGroup.Atrium && (
            <AtriumTable
              data={atriumData}
              fetchStatus={atriumFetchStatus}
              hasMoreData={Boolean(atriumResponseData?.nextPage)}
              onDataRefresh={() =>
                refreshDataForTaskGroup(APITypesV1.TaskGroup.Atrium)
              }
              onLoadMore={() => {
                if (!atriumResponseData?.nextPage) {
                  return;
                }

                setAtriumFetchStatus("loading");
                fetchData({
                  taskGroup: APITypesV1.TaskGroup.Atrium,
                  continuationToken: atriumResponseData?.nextPage,
                })
                  .then(mergePaginatedResponseWith(atriumResponseData))
                  .then(setAtriumResponseData)
                  .finally(() => {
                    setAtriumFetchStatus("idle");
                  });
              }}
            />
          )}
          {activeTab === APITypesV1.TaskGroup.Operator && (
            <OperatorTable
              data={operatorData}
              fetchStatus={operatorFetchStatus}
              hasMoreData={Boolean(operatorResponseData?.nextPage)}
              onDataRefresh={() =>
                refreshDataForTaskGroup(APITypesV1.TaskGroup.Operator)
              }
              onLoadMore={() => {
                if (!operatorResponseData?.nextPage) {
                  return;
                }

                setOperatorFetchStatus("loading");
                fetchData({
                  taskGroup: APITypesV1.TaskGroup.Operator,
                  continuationToken: operatorResponseData?.nextPage,
                })
                  .then(mergePaginatedResponseWith(operatorResponseData))
                  .then(setOperatorResponseData)
                  .finally(() => {
                    setOperatorFetchStatus("idle");
                  });
              }}
            />
          )}
          {activeTab === APITypesV1.TaskGroup.Cleared && (
            <ClearedTable
              data={clearedData}
              fetchStatus={clearedFetchStatus}
              hasMoreData={Boolean(clearedResponseData?.nextPage)}
              onDataRefresh={() =>
                refreshDataForTaskGroup(APITypesV1.TaskGroup.Cleared)
              }
              onLoadMore={() => {
                if (!clearedResponseData?.nextPage) {
                  return;
                }

                setClearedFetchStatus("loading");
                fetchData({
                  taskGroup: APITypesV1.TaskGroup.Cleared,
                  continuationToken: clearedResponseData?.nextPage,
                })
                  .then(mergePaginatedResponseWith(clearedResponseData))
                  .then(setClearedResponseData)
                  .finally(() => {
                    setClearedFetchStatus("idle");
                  });
              }}
            />
          )}
          {activeTab === APITypesV1.TaskGroup.ReferredIRLDermaBooked && (
            <ReferredIRLDermaBookedTable
              data={referredIRLDermaBookedData}
              fetchStatus={referredIRLDermaBookedFetchStatus}
              hasMoreData={Boolean(
                referredIRLDermaBookedResponseData?.nextPage
              )}
              onDataRefresh={() =>
                refreshDataForTaskGroup(
                  APITypesV1.TaskGroup.ReferredIRLDermaBooked
                )
              }
              onLoadMore={() => {
                if (!referredIRLDermaBookedResponseData?.nextPage) {
                  return;
                }

                setReferredIRLDermaBookedFetchStatus("loading");
                fetchData({
                  taskGroup: APITypesV1.TaskGroup.ReferredIRLDermaBooked,
                  continuationToken:
                    referredIRLDermaBookedResponseData?.nextPage,
                })
                  .then(
                    mergePaginatedResponseWith(
                      referredIRLDermaBookedResponseData
                    )
                  )
                  .then(setReferredIRLDermaBookedResponseData)
                  .finally(() => {
                    setReferredIRLDermaBookedFetchStatus("idle");
                  });
              }}
            />
          )}
        </div>
      </div>
    </FramedPage>
  );
}
