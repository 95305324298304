import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useCreateScanJournalNote() {
  const api = useAPIClient();

  return useCallback(
    async (scan: ImmutableScan, payload: { note: string }) => {
      return api.scan.createScanJournalNote(
        {
          patientId: scan.patientId,
          scanId: scan.id,
          scanVersion: scan.version,
        },
        {
          journalNoteType: APITypesV1.JournalNoteType.Derma,
          note: payload.note,
          visitId: scan.visitId,
        }
      ).result;
    },
    [api.scan]
  );
}
