import styles from "./fragments.module.sass";

export function JSXResult(main: string, detail?: string, subDetail?: string) {
  return (
    <div className={styles.results}>
      <div className={styles.mainResult}>{main}</div>
      <div className={styles.detail}>{detail}</div>
      <div className={styles.detail}>{subDetail}</div>
    </div>
  );
}
