import { BMI } from "@cur8/formulas";
import { Metric } from "lib/metric";

export type BodyMetrics = {
  biceps?: Metric<"body.biceps">;
  calf?: Metric<"body.calf">;
  calf_left?: Metric<"body.calf.left">;
  calf_right?: Metric<"body.calf.right">;
  height?: Metric<"body.height">;
  hip?: Metric<"body.hip">;
  neck?: Metric<"body.neck">;
  waist?: Metric<"body.waist">;
  weight?: Metric<"body.weight">;

  femoral_instep_left?: Metric<"cardio.distance.femoralis-left_instep.left">;
  femoral_instep_right?: Metric<"cardio.distance.femoralis-left_instep.right">;
  ssn_carotis_left?: Metric<"cardio.distance.ssn_carotis.left">;
  ssn_femoralis_left?: Metric<"cardio.distance.ssn_femoralis.left">;
  ssn_symphysis?: Metric<"cardio.distance.ssn_symphysis">;
  ssn_piezo_finger_left?: Metric<"cardio.distance.ssn_piezo-finger.left">;
  ssn_piezo_finger_right?: Metric<"cardio.distance.ssn_piezo-finger.right">;
};

export function toBMI(
  weight: Metric<"body.weight">,
  height: Metric<"body.height">
) {
  return BMI.calculate({
    height: {
      meters: height.unit.meters,
    },
    weight: {
      kilograms: weight.unit.kilograms,
    },
  }).bmi;
}
