import { Sex } from "@cur8/rich-entity";
import { clamp } from "lib/math";
import { concatPercent } from "lib/number";
import { getAgeBinForAge } from "render/pages/DashboardPage/lib/projection/age";
import {
  cpvToPercentage,
  getBenchmarkCpvFor,
  normalizeCpv,
  roundToNearestMultipleOfFive,
} from "../../lib/benchmark";
import { BloodPressureType } from "../../lib/projection";

const SexToGenderBin: Record<Sex, string> = {
  [Sex.Female]: "female",
  [Sex.Male]: "male",
  [Sex.Unknown]: "other",
};

interface BenchmarkResultDescriptionProps {
  currentValue: number;
  bloodPressureType: BloodPressureType;
  sex: Sex;
  age: number;
}

export default function BenchmarkResultDescription({
  currentValue,
  bloodPressureType,
  sex,
  age,
}: BenchmarkResultDescriptionProps) {
  const cpv = getBenchmarkCpvFor(currentValue, bloodPressureType, age, sex);
  const ageBin = getAgeBinForAge(age);
  const isAboveTheAverage = cpv > 0.5;
  const titleTrend = isAboveTheAverage ? "Higher" : "Better";

  const benchmarkPercentage = roundToNearestMultipleOfFive(
    clamp(cpvToPercentage(normalizeCpv(cpv)), 10, 90)
  );

  if (cpv >= 0.5 && cpv < 0.6) {
    return (
      <>
        Same as the typical Neko member [{SexToGenderBin[sex]} {ageBin} years]
      </>
    );
  }

  return (
    <>
      {titleTrend} than {concatPercent(benchmarkPercentage)} of Neko members [
      {SexToGenderBin[sex]} {ageBin} years]
    </>
  );
}
