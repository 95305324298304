import { Fragment } from "react";
import { useToggle } from "render/hooks/useToggle";
import Marker from "../ChartMarker/Marker";
import ChartValueChange from "../ChartValueChange";
import { Bounds, RangeLineEntry, RangePointEntry } from "./lib";
import styles from "./styles.module.sass";

type Orientation = "horizontal" | "vertical";

const Offset: Record<Orientation, string> = {
  horizontal: "left",
  vertical: "bottom",
};

const Length: Record<Orientation, string> = {
  horizontal: "width",
  vertical: "height",
};

const Transtorm: Record<Orientation, string> = {
  vertical: "translateY(50%)",
  horizontal: "translateY(-50%)",
};

interface RangeLineProps {
  ranges: RangeLineEntry[];
  bounds: Bounds;
  points?: RangePointEntry[];
  labels?: boolean;
  orientation?: "horizontal" | "vertical";
}

export default function RangeLine({
  ranges,
  bounds,
  points,

  labels = false,
  orientation = "horizontal",
}: RangeLineProps) {
  const [isValueChangeActive, toggleIsValueChangeActive] = useToggle(false);

  const offset = Offset[orientation];
  const length = Length[orientation];

  return (
    <div className={styles.RangeLine}>
      <div className={styles.segments}>
        {ranges.map((range, index) => {
          const start = bounds.lerp(range.from.value);
          const end = bounds.lerp(range.to.value);

          return (
            <div
              key={index}
              className={styles.segment}
              style={{
                [offset]: `calc(${start * 100}% + 0.5px)`,
                [length]: `calc(${(end - start) * 100}% - 1px)`,
              }}
            >
              <div
                className={styles.range}
                style={{
                  background: range.color,
                }}
              />
            </div>
          );
        })}

        {labels &&
          ranges.map((range, index) => {
            const start = bounds.lerp(range.from.value);
            const end = bounds.lerp(range.to.value);

            return (
              <Fragment key={index}>
                <div
                  className={styles.label}
                  style={{
                    [offset]: `${start * 100}%`,
                  }}
                >
                  {range.from.label}
                </div>

                {ranges.length === 1 && (
                  <div
                    className={styles.label}
                    style={{
                      [offset]: `${end * 100}%`,
                      transform: "translateX(-100%)",
                    }}
                  >
                    {range.to.label}
                  </div>
                )}
              </Fragment>
            );
          })}
      </div>

      <div className={styles.points}>
        {points &&
          points.map((point, index) => {
            const pos = bounds.lerp(point.value);
            const hasChangeToggler = orientation === "vertical";

            return (
              <div
                key={index}
                className={styles.point}
                title={point.title}
                style={{
                  [offset]: `${pos * 100}%`,
                  transform: Transtorm[orientation],
                  left: "-9px",
                }}
                onClick={toggleIsValueChangeActive}
              >
                <Marker
                  variant={point.variant ?? "outlined"}
                  highlight={point.highlight ?? "normal"}
                />
                {hasChangeToggler && point.previousValue !== undefined ? (
                  <div
                    className={styles.changeContainer}
                    data-active={isValueChangeActive}
                  >
                    <ChartValueChange
                      value={point.value}
                      previousValue={point.previousValue}
                    />
                  </div>
                ) : null}
              </div>
            );
          })}
      </div>
    </div>
  );
}
