import { Annotation } from "@cur8/rich-entity";
import { sortBy } from "lib/sort";
import { useMemo } from "react";
import CommentAuthor from "../CommentAuthor";
import styles from "./styles.module.sass";

const byCreated = sortBy<Annotation>((annotation) =>
  annotation.createdAt.valueOf()
);

interface CommentListProps {
  annotations: Annotation[];
}

export default function CommentList({ annotations }: CommentListProps) {
  const comments = useMemo(() => {
    return [...annotations].sort(byCreated("asc"));
  }, [annotations]);
  return (
    <div className={styles.CommentList}>
      {comments.map((annotation) => {
        return (
          <div className={styles.comment} key={annotation.id}>
            {annotation.audit.created && (
              <CommentAuthor auditRecord={annotation.audit.created} />
            )}
            <div className={styles.content}>{annotation.comment}</div>
          </div>
        );
      })}
    </div>
  );
}
