import { useMemo } from "react";
import styles from "./styles.module.sass";

let counter = 0;

type Entry<T> = {
  value: T;
  content: React.ReactNode;
};

interface RadioButtonsProps<T> {
  disabled?: boolean;
  required?: boolean;
  value: T;
  entries: Entry<T>[];
  onChange: (value: T) => void;
  stacked?: boolean;
}

export default function RadioButtons<T>({
  disabled = false,
  required = false,
  value,
  entries,
  onChange,
  stacked = false,
}: RadioButtonsProps<T>) {
  const name = useMemo(() => {
    return `cur8-radio-unique-group-${++counter}`;
  }, []);
  return (
    <div className={styles.RadioButtons} data-stacked={stacked}>
      {entries.map((entry, index) => {
        const selected = entry.value === value;
        return (
          <button
            key={index}
            disabled={disabled}
            type="button"
            value={selected ? "on" : "off"}
            onClick={() => onChange(entry.value)}
          >
            {entry.content}

            <input
              tabIndex={-1}
              type="radio"
              name={name}
              checked={selected}
              onChange={() => void 0}
              required={required}
            />
          </button>
        );
      })}
    </div>
  );
}
