import { BodyAreas } from "lib/smpl";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import BodyLayer from "./components/BodyLayer";
import DiabetesLayer from "./components/DiabetesLayer";

interface RenderLayersProps {
  layer: Layer;
  podium: THREE.Object3D;
  areas: BodyAreas;
}

export default function RenderLayers({
  layer,
  podium,
  areas,
}: RenderLayersProps) {
  return (
    <>
      <BodyLayer active={layer === Layer.Body} areas={areas} podium={podium} />

      <DiabetesLayer
        active={layer === Layer.Diabetes}
        areas={areas}
        podium={podium}
      />
    </>
  );
}
