import React from "react";
import { clamp } from "lib/math";
import classes from "./styles.module.sass";

function brightness(offset: number) {
  if (offset < 0) {
    return 1;
  }

  return 1 + offset * -0.12;
}

function opacity(offset: number, depth: number) {
  if (offset < 0 || offset > depth) {
    return 0;
  }
  return 1;
}

interface FlipBookCardsProps {
  activeIndex: number;
  children: React.ReactNode;
  depth?: number;
}

export default function FlipBookCards({
  activeIndex,
  children,
  depth = Infinity,
}: FlipBookCardsProps) {
  return (
    <div className={classes.FlipBookCards}>
      {React.Children.map(children, (child, index) => {
        const offset = clamp(index - activeIndex, -1, depth);

        const style: React.CSSProperties = {
          filter: `
            brightness(${brightness(offset)})
          `,
          opacity: opacity(offset, depth),
          transform: `
            translateZ(${-100 * offset}px)
            translateY(${32 * offset}px)
          `,
        };

        return (
          <div
            className={classes.item}
            data-active={offset === 0 ? "visible" : "hidden"}
            style={style}
          >
            {child}
          </div>
        );
      })}
    </div>
  );
}
