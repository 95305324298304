import { classNames } from "@pomle/classnames";
import { DateTime, Duration, Interval } from "luxon";
import { Fragment, useCallback, useMemo } from "react";
import { useLiveTime } from "render/hooks/useLiveTime";
import styles from "./styles.module.sass";

interface Props {
  selectedPeriod: Interval;
}

const isWeekend = (date: DateTime) => date.weekday > 5;

export default function Header({ selectedPeriod }: Props) {
  const now = useLiveTime("minute");

  const days = useMemo(
    () =>
      selectedPeriod
        .splitBy(Duration.fromDurationLike({ days: 1 }))
        .map((interval) => interval.start),
    [selectedPeriod]
  );
  const isCurrentDay = useCallback(
    (day: DateTime) => {
      return (
        now.year === day.year && now.month === day.month && now.day === day.day
      );
    },
    [now]
  );
  const dayCount = useMemo(
    () => selectedPeriod.count("days"),
    [selectedPeriod]
  );

  return (
    <div
      className={classNames(styles.CalendarHeader)}
      style={{
        gridTemplateColumns: `60px repeat(${dayCount}, minmax(100px, 1fr))`,
      }}
    >
      <div className={styles.weekNumber}>
        W{selectedPeriod.start.weekNumber}
      </div>
      {days.map((day) => (
        <Fragment key={day.toUnixInteger()}>
          <div
            className={classNames({
              [styles.workday]: !isWeekend(day),
              [styles.current]: isCurrentDay(day),
            })}
          >
            <div className={styles.day}>
              <span className={styles.number}>{day.day}</span>
              <span className={styles.weekday}>{day.weekdayShort}</span>
            </div>
          </div>
        </Fragment>
      ))}
    </div>
  );
}
