import { cutSignal, Plot } from "lib/api/cardio";
import { normalize } from "lib/normalize";
import { useMemo } from "react";
import CardioGraph from "render/ui/graph/CardioGraph";
import CardioGrid from "render/ui/graph/CardioGrid";
import styles from "./styles.module.sass";

const DURATION = 5;

interface ECGGraphProps {
  plot: Plot;
}

export default function ECGGraph({ plot }: ECGGraphProps) {
  const scaledPoints = useMemo(() => {
    const slice = cutSignal(plot, DURATION);

    return slice.map(normalize(0, 1)).map((v, index) => {
      return {
        x: (index / slice.length) * 100,
        y: 5 + (1 + -v) * 90,
      };
    });
  }, [plot]);

  const points = useMemo(() => {
    return [...scaledPoints, { x: 200, y: 101 }, { x: 0, y: 101 }];
  }, [scaledPoints]);

  return (
    <div className={styles.ECGGraph}>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <CardioGrid vertical={[20, 40, 60, 80]} horizontal={[]} />

        <CardioGraph points={points} duration={DURATION} />
      </svg>
    </div>
  );
}
