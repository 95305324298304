import { Metric } from "lib/metric";
import { useCallback } from "react";
import { useMetricAPI } from "render/hooks/api/useMetricAPI";
import { toMetricList } from "lib/metric";

export type EyePressureMetrics = Partial<{
  eyePressureLeft: Metric<"risk_assessment.eye_pressure.left">;
  eyePressureRight: Metric<"risk_assessment.eye_pressure.right">;
  wearsContactLenses: Metric<"risk_assessment.eye_pressure.wears_contact_lenses">;
}>;

export function useEyePressureMetrics() {
  const { fetchMetrics, storeMetrics } = useMetricAPI();

  const fetchEyePressureMetrics = useCallback(
    async (patientId: string): Promise<EyePressureMetrics> => {
      const [eyePressureLeft, eyePressureRight, wearsContactLenses] =
        await Promise.all([
          fetchMetrics(patientId, "risk_assessment.eye_pressure.left"),
          fetchMetrics(patientId, "risk_assessment.eye_pressure.right"),
          fetchMetrics(
            patientId,
            "risk_assessment.eye_pressure.wears_contact_lenses"
          ),
        ]);

      return {
        eyePressureLeft: eyePressureLeft.at(0),
        eyePressureRight: eyePressureRight.at(0),
        wearsContactLenses: wearsContactLenses.at(0),
      };
    },
    [fetchMetrics]
  );

  const updateEyePressureMetrics = useCallback(
    async (patientId: string, metrics: EyePressureMetrics) => {
      return storeMetrics(patientId, toMetricList(metrics));
    },

    [storeMetrics]
  );

  return {
    fetchEyePressureMetrics,
    updateEyePressureMetrics,
  };
}
