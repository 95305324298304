import { Cholesterol } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import MetricRange from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface TotalCholesterolRangeProps {
  patient: Patient;
  metrics: Metric<"bloodwork.cholesterol">[] | undefined;
}

export default function TotalCholesterolRange({
  patient,
  metrics,
}: TotalCholesterolRangeProps) {
  const age = useAge(patient);

  const riskRange = useMemo(() => {
    return Cholesterol.rangesFor({ age });
  }, [age]);

  const ranges = useMemo(() => {
    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [metrics, riskRange]);

  return (
    <MetricRange
      title="Total cholesterol"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(2)}
    />
  );
}
