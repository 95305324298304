import { useState, useCallback } from "react";
import { PhysicalIdType, PhysicalIdURI } from "@cur8/uri";
import PatientIdTypeSelect from "render/fragments/form/input/PatientIdTypeSelect";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import PhysicalIdName from "render/fragments/patient/PhysicalIdName";
import Input from "render/ui/form/Input";
import PhysicalIdInput from "render/fragments/form/input/PhysicalIdInput";
import styles from "./styles.module.sass";

interface PhysicalIdsEditorProps {
  physicalIdURIs: PhysicalIdURI[];
  onChange: (physicalIdURIs: PhysicalIdURI[]) => void;
}

export default function PhysicalIdsEditor({
  physicalIdURIs,
  onChange,
}: PhysicalIdsEditorProps) {
  const [type, setType] = useState<PhysicalIdType>(PhysicalIdType.Legacy);
  const [id, setId] = useState<string>("");

  const handleAdd = useCallback(() => {
    const next = [...physicalIdURIs, new PhysicalIdURI(type, id.trim())];
    const unique = new Set(next.map((uri) => uri.toString()));
    const output = [...unique].map(PhysicalIdURI.fromString);
    onChange(output);
    setId("");
  }, [onChange, physicalIdURIs, type, id]);

  const handleRemove = useCallback(
    (uri: PhysicalIdURI) => {
      const unique = new Set(physicalIdURIs.map((uri) => uri.toString()));
      unique.delete(uri.toString());
      const output = [...unique].map(PhysicalIdURI.fromString);
      onChange(output);
    },
    [onChange, physicalIdURIs]
  );

  const isAddDisabled = id.length === 0;
  return (
    <div className={styles.PhysicalIdsEditor}>
      <div className={styles.addInput}>
        <Input>
          <PhysicalIdInput
            idType={type}
            value={id}
            onChange={(event) => setId(event.target.value)}
          />
        </Input>

        <PatientIdTypeSelect value={type} onChange={setType} />

        <HoverTextButton disabled={isAddDisabled} onClick={handleAdd}>
          Add
        </HoverTextButton>
      </div>

      <div>
        <table className={styles.idTable}>
          <tbody>
            {physicalIdURIs.map((uri) => (
              <tr className={styles.idItem} key={uri.toString()}>
                <td>
                  <b>{uri.id}</b>
                </td>

                <td>
                  <PhysicalIdName type={uri.idType} />
                </td>

                <td>
                  <HoverTextButton onClick={() => handleRemove(uri)}>
                    Delete
                  </HoverTextButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
