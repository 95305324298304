import { UnitSystem } from "lib/conversion/UnitConverter";
import { createContext, useContext } from "react";

export type MetricsUnits = {
  length: UnitSystem;
};

const Context = createContext<MetricsUnits>({
  length: "metric",
});

interface MetricUnitContextProps {
  units: MetricsUnits;
  children: React.ReactNode;
}

function MetricUnitContext({ units, children }: MetricUnitContextProps) {
  return <Context.Provider value={units}>{children}</Context.Provider>;
}

export function useMetricsUnits() {
  return useContext(Context);
}

export default MetricUnitContext;
