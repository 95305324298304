import { APITypesV1 } from "@cur8/api-client";
import {
  Assignment,
  fromAPI,
  isAktiiaMonitorBloodPressureAssignment,
} from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { createQuery } from "@pomle/paths";
import { useNav, useQueryState } from "@pomle/react-router-paths";
import { DateTime, Interval } from "luxon";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import PatientName from "render/fragments/patient/PatientName/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { codecs } from "render/routes/codec";
import { paths } from "render/routes/paths";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import BackButtonLink from "render/ui/trigger/BackButtonLink";
import DropdownMenuButton from "render/ui/trigger/DropdownMenuButton";
import DropdownItem from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import HoverTextButton from "render/ui/trigger/HoverTextButton/HoverTextButton";
import { useAssignmentCompletePopup } from "../hooks/useAssignmentCompletePopup";
import { useAssignmentReturnDevicePopup } from "../hooks/useAssignmentReturnDevicePopup";
import { useAssignmentTerminatePopup } from "../hooks/useAssignmentTerminatePopup";
import PhoneIcon from "./assets/phone.svg?react";
import BloodPressureTable from "./components/BloodPressureTable";
import DataMeasurementDeviceInfo from "./components/DataMeasurementDeviceInfo";
import DateRangeDisplay from "./components/DateRangeDisplay/DateRangeDisplay";
import DoctorsNote from "./components/DoctorsNote/DoctorsNote";
import ReturnDeviceMessage from "./components/ReturnDeviceMessage/ReturnDeviceMessage";
import TableNav from "./components/TableNav";
import styles from "./styles.module.sass";

interface BloodPressureMonitorPageProps {
  patientId: string;
  assignmentId: string;
}

const queryParams = createQuery({
  end: codecs.date,
});

export default function BloodPressureMonitorPage({
  patientId,
  assignmentId,
}: BloodPressureMonitorPageProps) {
  const nav = {
    patient: useNav(paths.patient.detail),
  };
  const [query, setQuery] = useQueryState(queryParams);
  const api = useAPIClient();
  const [assignment, setAssignment] = useState<Assignment>();
  const assignmentCompletePopup = useAssignmentCompletePopup();
  const assignmentReturnDevicePopup = useAssignmentReturnDevicePopup();
  const assignmentTerminatePopup = useAssignmentTerminatePopup();
  const patient = usePatient(patientId);
  const isActionAllowed = useCallback(
    (targetAction: APITypesV1.AssignmentAction) => {
      return !!assignment?.allowedActions?.some(
        (action) => action === targetAction
      );
    },
    [assignment]
  );

  useEffect(() => {
    const request = api.assignment.getAssignmentFor(patientId, assignmentId);

    request.result.then(fromAPI.toAssignment).then(setAssignment);

    return () => {
      request.abandon();
      setAssignment(undefined);
    };
  }, [api, patientId, assignmentId]);

  const handleShowComplete = useCallback(() => {
    if (!assignment) {
      return;
    }
    assignmentCompletePopup.emitDialog(assignment, {
      onSuccess: setAssignment,
    });
  }, [assignmentCompletePopup, assignment]);

  const handleReturnDevice = useCallback(() => {
    if (!assignment) {
      return;
    }
    if (!isAktiiaMonitorBloodPressureAssignment(assignment)) {
      return;
    }
    assignmentReturnDevicePopup.emitDialog(assignment, {
      onSuccess: setAssignment,
    });
  }, [assignmentReturnDevicePopup, assignment]);

  const handleTerminateAssignment = useCallback(() => {
    if (!assignment) {
      return;
    }
    if (!isAktiiaMonitorBloodPressureAssignment(assignment)) {
      return;
    }

    assignmentTerminatePopup.emitDialog(assignment, {
      onSuccess: setAssignment,
    });
  }, [assignmentTerminatePopup, assignment]);

  const clampRange = useMemo(() => {
    if (!assignment) {
      return null;
    }
    if (!isAktiiaMonitorBloodPressureAssignment(assignment)) {
      return null;
    }
    const isCompleted =
      assignment.data.state === APITypesV1.AssignmentState.Submitted ||
      assignment.data.state === APITypesV1.AssignmentState.Completed ||
      assignment.data.state === APITypesV1.AssignmentState.Closed;

    const start = assignment.startAt;
    const lastMeasurementDate = assignment.data.measurements.reduce<DateTime[]>(
      (acc, { measuredAt }) => {
        if (measuredAt) {
          acc.push(measuredAt);
        }
        return acc;
      },
      []
    );
    const end = !isCompleted
      ? DateTime.now()
      : DateTime.max(...lastMeasurementDate) ?? DateTime.now();

    return {
      start: start.startOf("day"),
      end: end.endOf("day"),
    };
  }, [assignment]);

  const interval = useMemo(() => {
    const from = query.end.at(0);

    if (from) {
      return Interval.fromDateTimes(
        from.minus({ day: 6 }).startOf("day"),
        from.endOf("day")
      );
    }

    if (assignment && clampRange) {
      const end = DateTime.max(
        clampRange.start.plus({ day: 6 }),
        DateTime.min(clampRange.end, DateTime.now())
      );

      return Interval.fromDateTimes(
        end.minus({ day: 6 }).startOf("day"),
        end.endOf("day")
      );
    }
    return undefined;
  }, [query, assignment, clampRange]);

  const shouldDeviceBeReturned = useMemo(() => {
    if (assignment && isAktiiaMonitorBloodPressureAssignment(assignment)) {
      return (
        !assignment.data.returnedAt &&
        (assignment.data.state === APITypesV1.AssignmentState.Completed ||
          assignment.data.state === APITypesV1.AssignmentState.Terminated)
      );
    }

    return false;
  }, [assignment]);

  const canNavForward = useMemo(() => {
    return !!(
      interval &&
      clampRange &&
      interval.end.plus({ day: 1 }).startOf("day") <= clampRange.end
    );
  }, [interval, clampRange]);
  const canNavBack = useMemo(() => {
    return !!(
      interval &&
      clampRange &&
      interval.start.minus({ day: 1 }).startOf("day") >= clampRange.start
    );
  }, [interval, clampRange]);

  return (
    <FramedPage>
      <div className={styles.BloodPressureMonitorPage}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>
        <div className={styles.header}>
          <PageHeader caption="Monitor blood pressure">
            <div className={styles.headerActions}>
              {isActionAllowed(APITypesV1.AssignmentAction.Complete) && (
                <HoverTextButton onClick={handleShowComplete}>
                  Mark as complete
                </HoverTextButton>
              )}
              <DropdownMenuButton header="Manage assignment">
                {isActionAllowed(APITypesV1.AssignmentAction.Terminate) && (
                  <DropdownItem onClick={handleTerminateAssignment}>
                    Abandon assignment
                  </DropdownItem>
                )}
              </DropdownMenuButton>
            </div>
          </PageHeader>
          <div className={classNames(styles.fadeIn, styles.details)}>
            <div className={styles.info}>
              <DateRangeDisplay interval={interval} />
              {patient && (
                <div className={styles.phone}>
                  <PhoneIcon />
                  <div>
                    Member no:&nbsp;{patient.contactDetails.phoneNumbers[0]}
                  </div>
                </div>
              )}
            </div>
            <TableNav
              canNavBack={canNavBack}
              canNavForward={canNavForward}
              onNav={(target) => setQuery({ end: [target] })}
              interval={interval}
            />
          </div>
        </div>

        {shouldDeviceBeReturned && (
          <ReturnDeviceMessage onOpen={handleReturnDevice} />
        )}

        {assignment && isAktiiaMonitorBloodPressureAssignment(assignment) && (
          <>
            {patient && interval && (
              <div className={styles.table}>
                <div className={styles.fadeIn}>
                  <BloodPressureTable
                    patient={patient}
                    measurements={assignment.data.measurements}
                    interval={interval}
                  />
                </div>
                <div className={styles.fadeIn}>
                  <DataMeasurementDeviceInfo assignment={assignment} />
                </div>
              </div>
            )}

            {assignment &&
              isAktiiaMonitorBloodPressureAssignment(assignment) && (
                <DoctorsNote assignment={assignment} />
              )}
          </>
        )}
      </div>
    </FramedPage>
  );
}
