import { BodyAreas } from "lib/smpl";
import { createTimer } from "lib/timer";
import { createOpacityTween, createPositionTween } from "lib/tween";
import { useEffect, useMemo } from "react";
import * as THREE from "three";
import { createCircumference } from "../../models";

const OPACITY_OFF = 0;
const OPACITY_ON = 0.5;

const TWEEN_CONFIG = {
  stiffness: 3,
  mass: 20,
  friction: 2,
};

interface BodyLayerProps {
  active: boolean;
  podium: THREE.Object3D;
  areas: BodyAreas;
}

export default function BodyLayer({ active, podium, areas }: BodyLayerProps) {
  const models = useMemo(() => {
    const { waist, thorax, neck, calf, hip } = areas;

    return [waist, thorax, neck, calf, hip].map((box) => {
      const mesh = createCircumference(box);

      const origin = mesh.position.clone();

      mesh.material.opacity = OPACITY_OFF;
      mesh.position.z += 3000;

      return {
        mesh,
        origin,
        position: createPositionTween(mesh, TWEEN_CONFIG),
        opacity: createOpacityTween(mesh.material, TWEEN_CONFIG),
      };
    });
  }, [areas]);

  useEffect(() => {
    return createTimer((deltaTime) => {
      models.forEach((model) => {
        model.position.update(deltaTime / 1000);
        model.opacity.update(deltaTime / 1000);
        model.mesh.rotation.z += deltaTime * 0.001;
      });
    });
  }, [models]);

  useEffect(() => {
    models.forEach((model) => {
      podium.add(model.mesh);
    });

    return () => {
      models.forEach((model) => {
        podium.remove(model.mesh);
        model.mesh.geometry.dispose();
      });
    };
  }, [podium, models, areas]);

  useEffect(() => {
    if (active) {
      models.forEach((model) => {
        model.position.to(model.origin);
        model.opacity.to({ opacity: OPACITY_ON });
      });
    }

    return () => {
      models.forEach((model) => {
        model.opacity.to({ opacity: OPACITY_OFF });
      });
    };
  }, [models, active]);

  return null;
}
