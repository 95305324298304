import { RegionsOfInterestBox } from "@cur8/rich-entity";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTissueImageContext } from "../../context/TissueImageContext";
import { useQueries } from "../../hooks/useQueries";
import { ImageSize, ImageType, Property, PropertyRange } from "../../lib/types";
import Regions from "../Regions";
import GradientImage from "./GradientImage";
import RangeLegend from "./RangeLegend";
import styles from "./styles.module.sass";
import WaitingForImage from "./WaitingForImage";

interface ImageViewerProps {
  children?: React.ReactNode;
  rois: RegionsOfInterestBox;
  range?: PropertyRange;
  selectedRoi?: string;
}

export default function ImageViewer({
  children,
  rois,
  range,
  selectedRoi,
}: ImageViewerProps) {
  const { colormap, imageType, property, setImageType } = useQueries();
  const { viewer: viewerImage } = useTissueImageContext();

  const [scale, setScale] = useState<number>(1);
  const [imageRange, setImageRange] = useState<PropertyRange | undefined>(
    range
  );
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 0,
    height: 0,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setFullscreen] = useState<boolean>(false);

  const updateSize = useCallback(() => {
    if (!wrapperRef.current || !viewerImage || !viewerImage.image) {
      return;
    }
    const rect = wrapperRef.current.getBoundingClientRect();
    const ws = rect.width / viewerImage.image.naturalWidth;
    const hs = rect.height / viewerImage.image.naturalHeight;
    const s = Math.min(ws, hs);
    const is = {
      width: viewerImage.image.naturalWidth,
      height: viewerImage.image.naturalHeight,
    };
    setImageSize(is);
    setScale(s);
  }, [wrapperRef, viewerImage]);

  useEffect(() => {
    if (property === Property.thermal && imageType === ImageType.rgb) {
      setImageType(ImageType.normal);
    }
  }, [imageType, property, setImageType]);

  useEffect(() => {
    updateSize();
  }, [isFullscreen, property, updateSize, wrapperRef]);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize]);

  useEffect(() => {
    if (
      !viewerImage ||
      viewerImage.imin === undefined ||
      viewerImage.imax === undefined ||
      !range
    ) {
      return;
    }
    setImageRange({
      low: viewerImage.imin * (range.factor ?? 1),
      high: viewerImage.imax * (range.factor ?? 1),
      unit: range.displayUnit ?? range.unit,
    });
  }, [viewerImage, range]);

  useEffect(() => {
    function keyDownHandler(ev: KeyboardEvent) {
      if (ev.ctrlKey || ev.shiftKey || ev.altKey) {
        // Ignore if any modifier key is active
        return;
      }
      switch (ev.key) {
        case "Escape":
          if (isFullscreen) {
            setFullscreen(false);
          }
          break;
        default:
          return;
      }
    }

    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [isFullscreen]);

  return (
    <div
      className={styles.ImageViewer}
      data-fullscreen={isFullscreen}
      data-controls={false}
    >
      <div className={styles.imageColumn} ref={wrapperRef}>
        <svg
          width={imageSize.width * scale}
          height={imageSize.height * scale}
          viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
          className={styles.svg}
        >
          {viewerImage ? (
            <GradientImage
              colormap={colormap}
              tissueImage={viewerImage}
              type={imageType}
              transparencyCutoff={1 / 255}
            />
          ) : (
            <WaitingForImage />
          )}
          {viewerImage?.image && (
            <Regions
              canvasSize={imageSize}
              rois={rois}
              scale={scale}
              selectedRoi={selectedRoi}
            />
          )}
        </svg>
        {imageRange && imageType !== ImageType.rgb && (
          <RangeLegend colormap={colormap} range={imageRange} />
        )}
        {children}
      </div>
    </div>
  );
}
