interface SquircleArrowProps {
  direction?: "upwards" | "downwards";
}

export default function SquircleArrow({
  direction = "upwards",
}: SquircleArrowProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
      style={{ transform: direction === "downwards" ? "rotate(180deg)" : "" }}
    >
      <path
        fill="#E3E5E5"
        d="M0 7.425C0 1.722 1.222.5 6.925.5h2.15C14.778.5 16 1.722 16 7.425v2.15c0 5.703-1.222 6.925-6.925 6.925h-2.15C1.222 16.5 0 15.278 0 9.575v-2.15z"
      ></path>
      <path
        stroke="#262C2E"
        d="M8 4.257v8.486m0-8.486l3.818 3.819M8 4.257L4.182 8.076"
      ></path>
    </svg>
  );
}
