import { Patient, PulseWaveAssessment } from "@cur8/rich-entity";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import AssessmentSidebox from "./AssessmentSidebox";
import ButtonControl from "./components/shared/ButtonControl";
import RangeContext from "./context/RangeContext";
import { useCardioSignals } from "./hooks/useCardioSignals";
import PlotSelector from "./PlotSelector";
import styles from "./styles.module.sass";

interface PulseWavePageProps {
  patient: Patient;
  assessment: PulseWaveAssessment;
}

export default function PulseWavePage({
  patient,
  assessment,
}: PulseWavePageProps) {
  const scan = useImmutableScan(
    patient.patientId,
    assessment.scanId,
    assessment.scanVersion
  );
  const signals = useCardioSignals(scan);

  return (
    <div className={styles.PulseWavePage}>
      <RangeContext assessment={assessment} signals={signals}>
        <AssessmentSidebox />
        <div className={styles.plotArea}>
          <ButtonControl />
          <PlotSelector patient={patient} signals={signals} />
        </div>
      </RangeContext>
    </div>
  );
}
