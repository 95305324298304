export enum Layout {
  Fit,
  Max,
}

export type Crop = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export type Size = {
  w: number;
  h: number;
};

export type ViewBox = {
  crop: Crop;
  size: Size;
};
