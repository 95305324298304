import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import NoActiveBookingTokenPopup from "../NoActiveBookingTokenPopup";

export function useNoActiveBookingTokenPopup() {
  const { create } = usePopupDialog();

  const emitNoActiveBookingTokenDialog = useCallback(
    (patientId: string, onSuccess: () => void) => {
      const { emit, close } = create();
      emit(
        <NoActiveBookingTokenPopup
          patientId={patientId}
          onClose={close}
          onSuccess={onSuccess}
        />
      );
    },
    [create]
  );

  return {
    emitNoActiveBookingTokenDialog,
  };
}
