import { WhiteBloodCells } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import MetricRange from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface WhiteBloodCellsRangeProps {
  metrics: Metric<"bloodwork.white_blood_cells.total">[];
}

export default function WhiteBloodCellsRange({
  metrics,
}: WhiteBloodCellsRangeProps) {
  const riskRange = useMemo(() => {
    return WhiteBloodCells.rangesFor();
  }, []);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["x10⁹/L"],
      };
    });
  }, [riskRange, metrics]);

  const ranges = useMemo(() => {
    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  return (
    <MetricRange
      title="White blood cells"
      unit="x10⁹/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(1)}
    />
  );
}
