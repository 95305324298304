import { APITypesV1, Side } from "@cur8/api-client";

function parseTarget(anno: APITypesV1.Annotation) {
  const parts = anno.applicationSpecificTarget?.split(":");
  if (parts && parts[0] === "panorama" && parts.length === 5) {
    return {
      device: parts[1],
      id: parts[2],
      side: parts[3] as Side,
      camera: parts[4],
    };
  } else {
    return {
      type: null,
      device: null,
      id: null,
      side: null,
      camera: null,
    };
  }
}

export function atlasUrlFromAnnotation(anno: APITypesV1.Annotation) {
  const { device, id, side, camera } = parseTarget(anno);
  return (
    ["recording", device, id, "atlas"].join("/") +
    `?camera=${camera}&side=${side}`
  );
}
