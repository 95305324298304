import { APITypesV1 } from "@cur8/api-client";
import {
  BoundingBoxAnnotation,
  Recording,
  fromAPI,
  hasBoundingBox,
} from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { findPreviousRecording } from "lib/api/resolvers/recording";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

type RecordingEntry = {
  recording: Recording;
  annotations: BoundingBoxAnnotation[];
};

async function findRelevantRecordings(
  api: APIClient,
  deviceId: string,
  recordingId: string
) {
  const sourceRecording = await api.recording
    .fetchRecording({ deviceId, recordingId })
    .result.then(fromAPI.toRecording);

  const recordings = [sourceRecording];

  const previousRecording = await findPreviousRecording(api, sourceRecording);
  if (previousRecording) {
    recordings.push(previousRecording);
  }

  return recordings;
}

function queryAnnotations(api: APIClient, recordings: Recording[]) {
  const tasks = recordings.map(async (recording) => {
    const patientId = recording.patientId;
    if (!patientId) {
      throw new Error(`Recording ${recording.id} missing patient id`);
    }

    const { items } = await api.annotation.queryAnnotations({
      patientId,
      targetUri: recording.uri.toString(),
      acceptState: APITypesV1.AcceptState.Accepted,
      pageSize: 100,
    }).result;

    return {
      recording,
      annotations: items.map(fromAPI.toAnnotation).filter(hasBoundingBox),
    };
  });

  return Promise.all(tasks);
}

async function findRelevantAnnotations(
  api: APIClient,
  deviceId: string,
  recordingId: string
) {
  const recordings = await findRelevantRecordings(api, deviceId, recordingId);
  const annotations = await queryAnnotations(api, recordings);
  return annotations;
}

export function useRelevantRecordingAnnotations(
  deviceId: string,
  recordingId: string
) {
  const api = useAPIClient();

  const [entries, setEntries] = useState<RecordingEntry[]>([]);

  useEffect(() => {
    return () => {
      setEntries([]);
    };
  }, [deviceId, recordingId]);

  const fetch = useCallback(() => {
    return findRelevantAnnotations(api, deviceId, recordingId).then(setEntries);
  }, [api, deviceId, recordingId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    entries,
    fetch,
  };
}
