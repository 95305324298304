import { Sex } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { Typography } from "render/ui/presentation/Typography";
import BenchmarkResultDescription from "../BenchmarkResultDescription";
import styles from "./styles.module.sass";

interface HeadeProps {
  sex: Sex;
  age: number;
  metrics: Metric<"bloodwork.hba1c">[];
}
export default function Header({ metrics, sex, age }: HeadeProps) {
  const currentScan = metrics[0];
  const currentValue = currentScan?.unit["mmol/mol"];

  return (
    <div className={styles.Header}>
      <div className={styles.titles}>
        <Typography variant="title-s">Benchmark</Typography>
        <Typography variant="label-m" className={styles.aux}>
          [HbA1c]
        </Typography>
      </div>
      <Typography variant="body-m" className={styles.subtitle}>
        <BenchmarkResultDescription
          sex={sex}
          age={age}
          currentValue={currentValue}
        />
      </Typography>
    </div>
  );
}
