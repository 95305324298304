import { APITypesV1 } from "@cur8/api-client";
import { MetricName, toDimensions } from "@cur8/measurements";
import { UserURI } from "@cur8/uri";
import { queryMetrics } from "lib/api/measurement";
import { Metric } from "lib/metric";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useMSAL } from "render/context/MSALContext";

function useUserURI() {
  const { auth } = useMSAL();

  return useMemo(() => {
    if (!auth) {
      return;
    }

    return new UserURI(auth.tenantId, auth.uniqueId);
  }, [auth]);
}

const GENERATOR = [
  "cur8",
  "app",
  "doctor",
  process.env.REACT_APP_GIT_COMMIT ?? "-",
]
  .map(encodeURIComponent)
  .join(":");

export function useMetricAPI() {
  const api = useAPIClient().measurement;

  const userURI = useUserURI();

  const fetchMetrics = useCallback(
    function <M extends MetricName>(
      patientId: string,
      metricName: M,
      pageSize: number = 1
    ) {
      return queryMetrics(api, { patientId, metricName, pageSize });
    },
    [api]
  );

  const storeMeasurement = useCallback(
    (patientId: string, measurement: APITypesV1.CreateMeasurementRequest) => {
      return api.storeMeasurement({
        patientId,
        measurement,
      });
    },
    [api]
  );

  const storeMetrics = useCallback(
    (patientId: string, metrics: Metric[]) => {
      if (!userURI) {
        throw new Error("No UserURI available");
      }

      const sourceUri = userURI.toString();
      const timestampStart = DateTime.now().toISO();

      function toMeasurement<M extends MetricName>(
        metric: Metric<M>
      ): APITypesV1.CreateMeasurementRequest {
        const source = metric.measurement;

        const measurement: APITypesV1.CreateMeasurementRequest = {
          ...source,
          metricName: metric.name,
          sourceUri,
          generator: GENERATOR,
          timestampStart,
          timestampEnd: null,
          type: null,
          dimensions: toDimensions(metric.name, metric.unit),
        };

        return measurement;
      }

      const actions = metrics.map(toMeasurement).map((measurement) => {
        return storeMeasurement(patientId, measurement).result;
      });

      return Promise.all(actions);
    },
    [storeMeasurement, userURI]
  );

  return {
    fetchMetrics,
    storeMetrics,
  };
}
