import React from "react";
import { Highlight, Variant } from "../ChartMarker/Marker";

type RangeLineEdge = {
  value: number;
  label: React.ReactNode;
};

export type RangeLineEntry = {
  from: RangeLineEdge;
  to: RangeLineEdge;
  color: string;
};

export type RangePointEntry = {
  color: string;
  value: number;
  previousValue?: number;
  title: string;
  highlight?: Highlight;
  variant?: Variant;
};

export function lerp(value: number, min: number, max: number) {
  const len = max - min;
  return (value - min) / len;
}

export class Bounds {
  min: number;
  max: number;

  constructor(min: number, max: number) {
    this.min = min;
    this.max = max;
  }

  lerp(value: number) {
    const len = this.max - this.min;
    return (value - this.min) / len;
  }
}

export function getBounds(
  ranges: RangeLineEntry[],
  points?: RangePointEntry[]
): Bounds {
  const values: number[] = [];

  for (const range of ranges) {
    values.push(range.from.value);
    values.push(range.to.value);
  }

  if (points) {
    for (const point of points) {
      values.push(point.value);
    }
  }

  const sanitized = values.filter(isFinite);

  return new Bounds(Math.min(...sanitized), Math.max(...sanitized));
}
