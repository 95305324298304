import { MemberSummary } from "@cur8/api-client/dist/api/cur8/generated-types/v1/Api";
import { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useAppInsights } from "render/context/AppInsightsContext";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface SummaryDetailsProps {
  patientId: string;
  memberSummary: MemberSummary | undefined;
}

enum State {
  transcription,
  data,
}

export default function SummaryDetails({
  patientId,
  memberSummary,
}: SummaryDetailsProps) {
  const [state, setState] = useState(State.transcription);

  const appInsights = useAppInsights();

  const transcriptionRaw = memberSummary?.transcription?.text
    ? memberSummary.transcription.text
    : "No transcription available";
  const transcriptionSummary = memberSummary?.transcription?.summary
    ? memberSummary.transcription.summary
    : "No transcription summary available";

  useEffect(() => {
    appInsights.trackEvent({
      name: "scribe-show-details",
      properties: { patientId },
    });
  }, [appInsights, patientId]);

  return memberSummary ? (
    <div className={styles.SummaryDetails}>
      <div className={styles.topRow}>
        <div className={styles.buttons}>
          <HoverTextButton
            active={state === State.transcription}
            onClick={() => setState(State.transcription)}
          >
            Transcription
          </HoverTextButton>
          <HoverTextButton
            active={state === State.data}
            onClick={() => setState(State.data)}
          >
            Data record
          </HoverTextButton>
        </div>
      </div>

      <div className={styles.sections}>
        {state === State.transcription && (
          <div className={styles.transcriptionSection}>
            <div className={styles.title}>
              <h3>Transcription summary</h3>
            </div>
            <div className={styles.transcriptionSummary}>
              {transcriptionSummary}
            </div>
            <div className={styles.title}>
              <h3>Raw Transcription</h3>
            </div>
            <div className={styles.transcription}>{transcriptionRaw}</div>
          </div>
        )}
        {state === State.data && (
          <div className={styles.dataSection}>
            <ReactJson
              src={JSON.parse(memberSummary.results)}
              collapsed={2}
              displayDataTypes={false}
            />
          </div>
        )}
      </div>
    </div>
  ) : null;
}
