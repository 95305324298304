import { APITypesV1 } from "@cur8/api-client";
import { MemberSummary } from "@cur8/api-client/dist/api/cur8/generated-types/v1/Api";
import { Patient } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";
import { TransSumPair } from "./types";

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function formatDate(dateStr: string) {
  const date = new Date(dateStr);
  return date.toLocaleString();
}

export function transcriptionsInSync(
  t: APITypesV1.Annotation[],
  a: APITypesV1.Annotation[]
) {
  return t.length && a.length
    ? t[0].targetUri.replace("_transcription.json", ".webm") === a[0].targetUri
    : false;
}

/**
 * Strips out patient display name which is injected on the first line of input
 */
export function stripName(input: string): string {
  const firstLineBreakIndex = input.indexOf("\n\n");
  if (firstLineBreakIndex >= 0) {
    return input.slice(firstLineBreakIndex + 2);
  }
  return input;
}

export async function fetchSpeechAnnotations(
  api: APIClient,
  patientId: string,
  target: string
) {
  const result = await api.annotation.queryAnnotations({
    patientId: patientId,
    applicationSpecificTarget: target,
  }).result;
  return result.items;
}

/** Uploads an empty transcription blob, triggering member summary generation without a transcription */
export async function triggerSummaryGeneration(
  api: APIClient,
  patientId: string
) {
  const timestamp = new Date().getTime().toString();
  const path = `speech/${timestamp}_transcription.json`;
  await api.blob.putPatientBlob({
    patientId,
    file: new Blob([]),
    path,
  }).result;
  return timestamp;
}

export function daysOld(dateString: string): number {
  const inputDate = new Date(dateString);
  const currentDate = new Date();
  const differenceMs = currentDate.getTime() - inputDate.getTime();
  const msPerDay = 24 * 60 * 60 * 1000;
  const daysOld = differenceMs / msPerDay;
  return daysOld;
}

export function MSToTSPair(s: MemberSummary) {
  return {
    language: s.transcription?.language || "",
    transcriptionSummary: s.transcription?.summary || "",
    results: s.results,
    generatedSummary: s.summary,
    savedSummary: s.summary,
    temp: -1,
    readTime: DateTime.now().toMillis(),
    isLiked: undefined,
  };
}

export function generatedSummaryWithGreeting(
  s: TransSumPair,
  patient: Patient | undefined
) {
  const patientName = patient?.name?.displayName || "";
  const preferredLanguage = patient?.preferredLanguage
    ? patient.preferredLanguage
    : "en"; // default to English if no preferred language
  const language = s.language ? s.language : preferredLanguage; // use transcription language if available
  const prefix =
    language === "sv" ? `Hej ${patientName},\n\n` : `Hello ${patientName},\n\n`;

  return prefix + s.generatedSummary;
}
