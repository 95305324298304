import styles from "./styles.module.sass";

interface LabelsLayerProps {
  toY: (n: number) => number;
  labels: number[];
}

export default function LabelsLayer({ toY, labels }: LabelsLayerProps) {
  return (
    <div className={styles.LabelsLayer}>
      {labels.map((label) => (
        <div
          className={styles.label}
          key={label}
          style={{ top: `${toY(label)}%` }}
        >
          {label}
        </div>
      ))}
    </div>
  );
}
