import { useMemo } from "react";
import { Metric } from "lib/metric";
import { bySystolic } from "lib/metric/sort";

type BrachialMetric = {
  left: Metric<"cardio.brachial_pressure.left">[] | undefined;
  right: Metric<"cardio.brachial_pressure.right">[] | undefined;
};

export function useHighestBrachialPressure(metric?: BrachialMetric) {
  const left = metric?.left;
  const right = metric?.right;

  return useMemo(() => {
    if (!left || !right) {
      return;
    }

    return [...left.slice(0, 1), ...right.slice(0, 1)]
      .sort(bySystolic("desc"))
      .at(0);
  }, [left, right]);
}
