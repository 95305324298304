import { useCallback } from "react";
import { useManageSelectedPatient } from "render/pages/SchedulePage/hooks/useManageSelectedPatient";
import { useSelectedPatient } from "render/pages/SchedulePage/hooks/useSelectedPatient";
import useCalendarInteraction from "../../../Calendar/hooks/useCalendarInteraction";
import useCalendarState from "../../../Calendar/hooks/useCalendarState";
import AssigningPatient from "../AssigningPatient";
import EditingSlots from "../EditingSlots";

export default function CalendarStateDescription() {
  const selectedPatient = useSelectedPatient();
  const { clearPatient } = useManageSelectedPatient();
  const { enterCalendarViewMode } = useCalendarInteraction();
  const { calendarInteractionMode } = useCalendarState();

  const onCloseEditing = useCallback(enterCalendarViewMode, [
    enterCalendarViewMode,
  ]);

  return (
    <>
      {calendarInteractionMode === "booking" && (
        <AssigningPatient patient={selectedPatient} onClose={clearPatient} />
      )}

      {calendarInteractionMode === "editing" && (
        <EditingSlots onClose={onCloseEditing} />
      )}
    </>
  );
}
