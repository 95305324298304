import { generatePsuedoRandomString } from "lib/id";
import { useEffect, useMemo, useState } from "react";

function useUniq(prefix: string) {
  const id = useMemo(() => {
    return generatePsuedoRandomString(12, prefix + "-");
  }, [prefix]);

  const ref = useMemo(() => {
    return `url(#${id})`;
  }, [id]);

  return { id, ref };
}

const stopStyle = { transition: "stop-color 1s ease-out" };

export function useGradient(from: string, to: string) {
  const { id, ref } = useUniq("gradient");

  const def = (
    <linearGradient id={id}>
      <stop offset="0%" stopColor={from} style={stopStyle} />
      <stop offset="100%" stopColor={to} style={stopStyle} />
    </linearGradient>
  );

  return { def, ref, id };
}

enum Intensity {
  Cold,
  Hot,
}

type CardioGraphProps = {
  duration?: number;
  progress?: number;
};

export function useCardioGraphPattern({
  progress = NaN,
  duration = NaN,
}: CardioGraphProps) {
  const [intensity, setIntensity] = useState(Intensity.Cold);

  useEffect(() => {
    setIntensity(Intensity.Hot);
  }, [progress, duration]);

  useEffect(() => {
    if (isFinite(duration)) {
      return;
    }

    const timer = setTimeout(() => {
      setIntensity(Intensity.Cold);
    }, 200);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, progress]);

  const pulse = useGradient("#9ebcc4", intensity ? "#019dc9" : "#9ebcc4");
  const scan = useGradient("#c5d9dd00", intensity ? "#c5d9dd" : "#c5d9dd00");

  const line = useUniq("stroke");
  const fill = useUniq("fill");
  const beam = useUniq("beam");

  const progressX = isFinite(progress) ? progress * 100 - 25 : undefined;

  const anim = isFinite(duration) ? (
    <animate
      attributeName="x"
      dur={duration}
      values="0;100"
      repeatCount="indefinite"
    />
  ) : null;

  const def = (
    <>
      {pulse.def}
      {scan.def}

      <pattern
        id={line.id}
        patternUnits="userSpaceOnUse"
        x={progressX}
        width="100"
        height="100"
      >
        {anim}
        <rect x="0" width="25" height="100" fill={pulse.ref} />
        <rect x="25" width="75" height="100" fill="#9ebcc4" />
      </pattern>

      <pattern
        id={fill.id}
        patternUnits="userSpaceOnUse"
        x={progressX}
        width="100"
        height="100"
      >
        {anim}
        <rect width="25" height="100" fill={scan.ref} />
      </pattern>

      <pattern
        id={beam.id}
        patternUnits="userSpaceOnUse"
        x={progressX}
        width="100"
        height="100"
      >
        {anim}
        <line
          x1="25"
          x2="25"
          y1="0"
          y2="100"
          stroke="#019dc9"
          strokeWidth="1"
          vectorEffect="non-scaling-stroke"
        />
      </pattern>
    </>
  );

  return { def, fill, line, beam };
}
