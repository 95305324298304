/**
 * Line specific functions
 */
import { APITypesV1 } from "@cur8/api-client";
import { LCJSPoint, PropertyRange } from "./types";

export type PointData = {
  red: number;
} & APITypesV1.Point2D;

/**
 * Get all points that given line passes
 * WARNING, All points needs to be integers to avoid infinite loops
 */
export function linePoints(line: APITypesV1.Line2D): APITypesV1.Point2D[] {
  const points: APITypesV1.Point2D[] = [];
  const dx = Math.abs(line.b.x - line.a.x);
  const dy = Math.abs(line.b.y - line.a.y);
  const sx = line.a.x < line.b.x ? 1 : -1;
  const sy = line.a.y < line.b.y ? 1 : -1;
  let x = line.a.x;
  let y = line.a.y;
  let err = dx - dy;

  while (true) {
    points.push({ x, y });

    if (x === line.b.x && y === line.b.y) {
      break;
    }

    const e2 = 2 * err;
    if (e2 > -dy) {
      err -= dy;
      x += sx;
    }
    if (e2 < dx) {
      err += dx;
      y += sy;
    }
  }

  return points;
}

export function readPixelsFromImage(
  image: HTMLImageElement,
  points: APITypesV1.Point2D[],
  range: PropertyRange
): LCJSPoint[] {
  const canvas = document.createElement("canvas");
  canvas.width = image.naturalWidth;
  canvas.height = image.naturalHeight;
  const context = canvas.getContext("2d", { willReadFrequently: true });
  if (!context) {
    throw new Error("No context");
  }
  context.drawImage(image, 0, 0);
  const data = [] as LCJSPoint[];

  const rDiff = range.high - range.low;

  for (let i = 0; i < points.length; i++) {
    const p = points[i];
    if (
      p.x < 0 ||
      p.y < 0 ||
      p.x > image.naturalWidth ||
      p.y > image.naturalHeight
    ) {
      console.warn("Point OB", p);
      continue;
    }
    const id = context.getImageData(p.x, p.y, 1, 1);
    if (id.data[0] > 0 || id.data[1] > 0 || id.data[2] > 0) {
      // We ignore zero-values and only return red
      data.push({
        x: i,
        y: range.low + (rDiff / 255) * id.data[0],
      });
    }
  }
  canvas.remove();

  return data;
}
