import { IconProps } from ".";
import styles from "./styles.module.sass";

export default function TimeIcon({ active, color }: IconProps) {
  return (
    <svg
      className={styles.Common}
      data-active={active ? "yes" : "no"}
      data-color={color}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19" />
      <path strokeWidth="2" d="m20 20 0 -15" />
      <path strokeWidth="2" d="m20 20 10 0" />
    </svg>
  );
}
