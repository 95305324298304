import { HbA1c, Risk, Value } from "@cur8/health-risks-calc";
import { IFCCtoNGSP, NGSPtoIFCC } from "./hba1c-unit-conversion";

// see: Projections.specs.md#hba1c-projection for more info

/**
 * !IMPORTANT
 * Our HbA1c measurements are in IFCC (International Federation of Clinical Chemistry) units (= mmol/mol) ...
 * ...but the underlying hba1c study data and corresponding hba1c increase per year factor are in NGSP (National Glycohemoglobin Standardization Programme) units (= %)
 * We need to convert between the two units to make the projection work
 * reference: https://www.hba1cnet.com/hba1c-calculator/
 */

export const MAX_PROJECTION_AGE = 100;
export const HBA1C_UNIT_INCREASE_PER_YEAR: Value<"%"> = { "%": 0.012 } as const;
export const HIGH_HBA1C_THRESHOLD: Value<"mmol/mol"> = {
  "mmol/mol": 42,
} as const;

export interface HbA1cProjectionParams {
  age: number;
  hba1c: Value<"mmol/mol">;
}

export interface HbA1cProjectionResult {
  /**
   * Projected risk
   */
  risk: Risk;
  /**
   * Projected HbA1c value
   */
  hba1c: Value<"mmol/mol">;
  /**
   * Projection end age
   */
  age: number;
  /**
   * Projected age and value at which the risk threshold is reached
   */
  riskThreshold: {
    value: Value<"mmol/mol">;
    age: number;
  } | null;
}

function project({
  age: startAge,
  hba1c,
}: HbA1cProjectionParams): HbA1cProjectionResult {
  const endAge = Math.min(startAge + 30, MAX_PROJECTION_AGE);
  const projectionLengthInYears = endAge - startAge;

  const currentHba1cValue: Value<"mmol/mol" | "%"> = {
    "mmol/mol": hba1c["mmol/mol"],
    "%": IFCCtoNGSP(hba1c)["%"],
  };

  const projectedHba1cInPercentageUnits =
    currentHba1cValue["%"] +
    projectionLengthInYears * HBA1C_UNIT_INCREASE_PER_YEAR["%"];

  const projectedHba1cValue: Value<"mmol/mol" | "%"> = {
    "%": projectedHba1cInPercentageUnits,
    "mmol/mol": NGSPtoIFCC({ "%": projectedHba1cInPercentageUnits })[
      "mmol/mol"
    ],
  };

  const hba1cRanges = HbA1c.rangesFor({ age: startAge });
  const sortedRanges = hba1cRanges.entries.toSorted((a, b) => a.to - b.to);

  const projectedHba1cRiskRange = sortedRanges.find((range, index) => {
    const isFirstRange = index === 0;
    const isLastRange = index === sortedRanges.length - 1;

    if (isFirstRange) {
      return projectedHba1cValue["mmol/mol"] < range.to;
    }
    if (isLastRange) {
      return projectedHba1cValue["mmol/mol"] >= range.from;
    }

    return (
      projectedHba1cValue["mmol/mol"] >= range.from &&
      projectedHba1cValue["mmol/mol"] < range.to
    );
  });

  const riskThresholdInMmolUnits = projectedHba1cRiskRange?.from;

  // very low hba1c values are also marked as high risk
  // but they are not relevant for the projection
  // so execute an early return and don't calculate age at which risk threshold is reached if the projected risk threshold value is below the high risk threshold
  if (
    !riskThresholdInMmolUnits ||
    riskThresholdInMmolUnits < HIGH_HBA1C_THRESHOLD["mmol/mol"]
  ) {
    return {
      risk: projectedHba1cRiskRange?.risk ?? Risk.Unknown,
      hba1c: projectedHba1cValue,
      age: endAge,
      riskThreshold: null,
    };
  }

  const riskThresholdHba1cValue: Value<"mmol/mol" | "%"> = {
    "mmol/mol": riskThresholdInMmolUnits,
    "%": IFCCtoNGSP({ "mmol/mol": riskThresholdInMmolUnits })["%"],
  };

  let ageAtWhichRiskThresholdIsReached =
    (riskThresholdHba1cValue["%"] - currentHba1cValue["%"]) /
      HBA1C_UNIT_INCREASE_PER_YEAR["%"] +
    startAge;

  // discard projected risk threshold if the risk threshold age is out of projection bounds
  const isRiskThresholdAgeOutOfRange =
    ageAtWhichRiskThresholdIsReached > endAge ||
    ageAtWhichRiskThresholdIsReached < startAge;

  const riskThreshold: HbA1cProjectionResult["riskThreshold"] =
    isRiskThresholdAgeOutOfRange
      ? null
      : {
          value: riskThresholdHba1cValue,
          age: ageAtWhichRiskThresholdIsReached,
        };

  return {
    risk: projectedHba1cRiskRange?.risk ?? Risk.Unknown,
    hba1c: projectedHba1cValue,
    age: endAge,
    riskThreshold,
  };
}

export const HbA1cProjection = {
  project,
};
