import { useMemo } from "react";
import { Box, Coordinates } from "./positions";
import styles from "./styles.module.sass";

interface DistributionDiagramProps {
  count: number;
}

const strokeWidth = 1.5;
const width = 402;
const height = 242;

function BoxSVG(position: Box) {
  const durationBaseLine = 0.166;
  const delay = ((position.x + position.y) / 80) * durationBaseLine;
  const duration = ((position.height + position.width) / 80) * durationBaseLine;

  return (
    <g
      className={styles.group}
      style={{ animationDelay: `${delay}s`, animationDuration: `${duration}s` }}
    >
      <rect
        x={position.x}
        y={position.y}
        width={position.width}
        height={position.height}
        stroke="#B4E6EF"
        strokeWidth={strokeWidth}
      />
      <rect
        style={{
          animationDelay: `${delay + duration / 2}s`,
          animationDuration: `${duration / 2}s`,
        }}
        className={styles.center}
        x={position.x + (position.width - strokeWidth * 2) / 2}
        y={position.y + (position.height - strokeWidth * 2) / 2}
        width="4"
        height="4"
        rx="2"
        fill="#019DC9"
      />
    </g>
  );
}

export default function DistributionDiagram({
  count,
}: DistributionDiagramProps) {
  const variant = useMemo(() => {
    if (count === 0) {
      return [];
    }
    if (count < 500) {
      return Coordinates.extraSmall;
    }
    if (count < 750) {
      return Coordinates.small;
    }
    if (count < 1000) {
      return Coordinates.medium;
    }
    if (count < 1250) {
      return Coordinates.large;
    }
    if (count < 1500) {
      return Coordinates.larger;
    }
    if (count < 2000) {
      return Coordinates.dense;
    }
    return Coordinates.extraLarge;
  }, [count]);

  return (
    <svg
      className={styles.DistributionDiagram}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {variant.map((box) => (
        <BoxSVG
          key={`${box.x}${box.y}`}
          height={box.height}
          width={box.width}
          x={box.x}
          y={box.y}
        />
      ))}
    </svg>
  );
}
