import React, { useEffect, useState } from "react";
import { AsyncHandle } from "render/hooks/useAsyncHandle";
import { useReporting } from "render/hooks/useReporting";
import CheckIcon from "./assets/check.svg?react";
import LineThrobber from "./components/LineThrobber";
import classes from "./styles.module.sass";

interface AsyncButtonProps extends React.ButtonHTMLAttributes<any> {
  handle: Pick<AsyncHandle, "busy" | "promise">;
}

export default function AsyncButton({
  children,
  handle: { busy, promise },
  ...props
}: AsyncButtonProps) {
  const { handleError } = useReporting();

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!success) {
      return;
    }

    const timer = setTimeout(setSuccess, 3000, false);

    return () => {
      clearTimeout(timer);
    };
  }, [success]);

  useEffect(() => {
    if (!promise) {
      return;
    }

    promise
      .then(() => {
        setSuccess(true);
      })
      .catch(handleError);
  }, [promise, handleError]);

  return (
    <button
      className={classes.AsyncButton}
      data-busy={busy}
      data-success={success}
      type="submit"
      {...props}
    >
      <div className={classes.check}>
        <CheckIcon width={16} />
      </div>

      <div className={classes.content}>{children}</div>
      {busy && (
        <div className={classes.busyIndicator}>
          <LineThrobber />
        </div>
      )}
    </button>
  );
}
