import PatientName from "render/fragments/patient/PatientName/PatientName";
import { usePatient } from "render/hooks/api/usePatient";

interface AssignedToPatientProps {
  patientId: string;
}

export default function AssignedToPatient({
  patientId,
}: AssignedToPatientProps) {
  const patient = usePatient(patientId);
  if (!patient) {
    return null;
  }

  return <PatientName patient={patient} />;
}
