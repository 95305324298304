import * as Risks from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientHealthData } from "render/hooks/patient/usePatientHealthData";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import BrachialPressure from "render/ui/format/BrachialPressure";
import Table from "render/ui/layouts/Table";
import ResultRow from "../ResultRow";

interface CardioTableProps {
  patient?: Patient;
  metrics: PatientMetrics;
  healthData: ReturnType<typeof usePatientHealthData>;
}

export default function CardioTable({
  healthData,
  patient,
  metrics,
}: CardioTableProps) {
  const cardio = metrics.cardio;

  const age = useAge(patient);
  const agg = healthData.aggregates;

  return (
    <Table>
      <thead>
        <tr>
          <th>Cardio</th>
          <th>Result</th>
          <th>Risk</th>
          <th>Units</th>
        </tr>
      </thead>
      <tbody>
        <ResultRow
          caption="Brachial Pressure (L)"
          unit="mmHg"
          value={cardio?.brachialPressure?.left?.at(0)?.unit}
          format={(unit) => <BrachialPressure brachial={unit} />}
          summarize={(unit) => {
            return {
              risk: Risks.BrachialPressure.riskFor({
                systolic: unit.systolic,
                diastolic: unit.diastolic,
                age: age ?? NaN,
              }),
            };
          }}
        />

        <ResultRow
          caption="Brachial Pressure (R)"
          unit="mmHg"
          value={cardio?.brachialPressure?.right?.at(0)?.unit}
          format={(unit) => <BrachialPressure brachial={unit} />}
          summarize={(unit) => {
            return {
              risk: Risks.BrachialPressure.riskFor({
                systolic: unit.systolic,
                diastolic: unit.diastolic,
                age: age ?? NaN,
              }),
            };
          }}
        />

        <ResultRow
          caption="Ankle Pressure (L)"
          unit="mmHg"
          value={cardio?.anklePressure?.left?.at(0)?.unit}
          format={(unit) => unit.mmHg.toFixed(0)}
          summarize={(unit) => {
            return {
              risk: Risks.AnklePressure.rangesFor().findRisk(unit),
            };
          }}
        />
        <ResultRow
          caption="Ankle Pressure (R)"
          unit="mmHg"
          value={cardio?.anklePressure?.right?.at(0)?.unit}
          format={(unit) => unit.mmHg.toFixed(0)}
          summarize={(unit) => {
            return {
              risk: Risks.AnklePressure.rangesFor().findRisk(unit),
            };
          }}
        />

        <ResultRow
          caption="ABI (L)"
          value={agg.abi?.left}
          format={(unit) => unit.score.toFixed(2)}
          summarize={(unit) => {
            return {
              risk: Risks.ABI.rangesFor().findRisk({ ratio: unit.score }),
            };
          }}
        />
        <ResultRow
          caption="ABI (R)"
          value={agg.abi?.right}
          format={(unit) => unit.score.toFixed(2)}
          summarize={(unit) => {
            return {
              risk: Risks.ABI.rangesFor().findRisk({ ratio: unit.score }),
            };
          }}
        />

        <ResultRow
          caption="Saturation"
          unit="%"
          value={cardio.bloodOxygen?.at(0)?.unit.percent}
          format={(percent) => percent.toFixed(1)}
          summarize={(percent) => {
            return {
              risk: Risks.BloodOxygen.rangesFor().findRisk({ "%": percent }),
            };
          }}
        />
        <ResultRow
          caption="Heart rate"
          unit="bpm"
          value={cardio.heartRate?.at(0)?.unit.bpm}
          format={(bpm) => bpm.toFixed(0)}
          summarize={(bpm) => {
            return {
              risk: Risks.HeartRate.rangesFor().findRisk({ bpm }),
            };
          }}
        />
        <ResultRow
          caption="Heart score"
          value={agg.score2}
          format={(score2) => score2.score.toFixed(0)}
        />
      </tbody>
    </Table>
  );
}
