import { useEffect, useState } from "react";
import { loadAudio } from "lib/loader";

export function useBlobAudio(blob?: Blob) {
  const [audio, setAudio] = useState<HTMLAudioElement>();

  useEffect(() => {
    if (!blob) {
      return;
    }

    const url = URL.createObjectURL(blob);
    loadAudio(url).then(setAudio);

    return () => {
      setAudio(undefined);
      URL.revokeObjectURL(url);
    };
  }, [blob]);

  return audio;
}
