import { fromAPI } from "@cur8/rich-entity";
import { useEffect } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useReporting } from "../useReporting";

export function usePatient(patientId: string) {
  const { handleError } = useReporting();

  const api = useAPIClient().patient;

  const { get, set } = useStore().patient.entries;

  useEffect(() => {
    const request = api.fetchPatient({ patientId });

    request.result
      .then(fromAPI.toPatientDetails)
      .then((patient) => {
        set(patient.patientId, patient);
      })
      .catch(handleError);
  }, [set, api, patientId, handleError]);

  return get(patientId).data ?? undefined;
}
