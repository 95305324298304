import { Sex } from "@cur8/rich-entity";
import { getAgeBinForAge } from "render/pages/DashboardPage/lib/projection/age";
import distributionModel from "render/pages/DashboardPage/lib/projection/assets/hba1c_blood_pressure_grip_strength_distribution_model.json";
import { mapSexToModel } from "render/pages/DashboardPage/lib/projection/sex";
import { BloodPressureType, bloodPressureValueToCpv } from "./projection";

export function getBenchmarkValueClampLimitsForResultDiff(resultDiff: number) {
  if (resultDiff <= 0) {
    return {
      from: 20,
      to: 100,
    };
  }

  return {
    from: 25,
    to: 70,
  };
}

export function getMemberValueClampLimitsForResultDiff(resultDiff: number) {
  if (resultDiff >= 0) {
    return {
      from: 25,
      to: 100,
    };
  }

  return {
    from: 40,
    to: 70,
  };
}

export function toFixedIfNecessary(number: number) {
  return +number.toFixed(1);
}

export function normalizePercentageDifference(value: number) {
  if (value <= 20) {
    return 20;
  }

  if (value <= 80) {
    return 50;
  }

  if (value <= 90) {
    return 70;
  }

  return 100;
}

export function roundToNearestMultipleOfFive(number: number): number {
  return Math.round(number / 10) * 10;
}

export function cpvToPercentage(cpv: number) {
  return cpv * 100;
}

export function normalizeCpv(cpv: number) {
  if (cpv <= 0.5) {
    return 1 - cpv;
  }

  return cpv;
}

export function getBenchmarkCpvFor(
  currentHba1cValue: number,
  type: BloodPressureType,
  age: number,
  sex: Sex
) {
  return bloodPressureValueToCpv(
    currentHba1cValue,
    type,
    mapSexToModel(sex),
    age
  );
}

export function getBenchmarkMedianFor(
  bloodPressureType: BloodPressureType,
  age: number,
  sex: Sex
) {
  const ageGroup = getAgeBinForAge(age);
  const sexGroup = mapSexToModel(sex);
  // Find distribution arrays from data model
  const distributionArrays =
    distributionModel[sexGroup][ageGroup][bloodPressureType];

  // Extract distribution arrays
  const binCenters: number[] = distributionArrays["bin_centers"];
  const cdf: number[] = distributionArrays["cdf_values"];

  // Find bin center value for cpv=0.5
  const closestIndex = cdf.reduce((prevIndex, value, currentIndex) => {
    const prevValue = cdf[prevIndex];
    return Math.abs(value - 0.5) < Math.abs(prevValue - 0.5)
      ? currentIndex
      : prevIndex;
  }, 0);

  const medianHba1c: number = binCenters[closestIndex];

  return Math.round(medianHba1c);
}
