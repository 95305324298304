import {
  OutputImageSize,
  SourceImageCrop,
  TranscoderImageResponse,
} from "lib/api/types";
import { derace, debounce } from "@pomle/throb";
import { Point } from "lib/math";
import { useEffect, useMemo, useRef, useState } from "react";
import { useReporting } from "render/hooks/useReporting";
import styles from "./styles.module.sass";

type Crop = {
  x: number;
  y: number;
  w: number;
  h: number;
};

type Size = {
  w: number;
  h: number;
};
const IMAGE_UPDATE_DEBOUNCE_MS = 300;

interface TiledImageProps {
  source: TranscoderImageResponse;
  viewCrop: Crop;
  viewSize: Size;
  fetchTile(
    size: OutputImageSize,
    crop?: SourceImageCrop
  ): Promise<TranscoderImageResponse>;
  scaleFactor: number;
}

export default function TiledImage({
  fetchTile,
  viewCrop,
  viewSize,
  source,
  scaleFactor,
}: TiledImageProps) {
  const { handleError } = useReporting();

  const frameRef = useRef<HTMLDivElement>(null);

  const imageSize = useMemo((): Size => {
    return source.meta.original.size;
  }, [source]);

  const sourceRes = useMemo(() => {
    const { image, meta } = source;
    const { w, h } = meta.original.size;
    return new Point(image.naturalWidth / w, image.naturalHeight / h);
  }, [source]);

  const crop = useMemo(() => {
    return {
      x: viewCrop.x | 0,
      y: viewCrop.y | 0,
      w: Math.min(viewCrop.w, imageSize.w - viewCrop.x) | 0,
      h: Math.min(viewCrop.h, imageSize.h - viewCrop.y) | 0,
    };
  }, [viewCrop, imageSize]);

  const [slice, setSlice] = useState<{
    image: HTMLImageElement;
    offset: Crop;
  }>();

  const fetchSlice = useMemo(() => {
    const fetch = derace(fetchTile);

    function fetchSlice(size: Size, crop: Crop) {
      fetch(size, crop)
        .then(([response, sync]) => {
          if (!sync) {
            console.info("Out of sync result", response);
            return;
          }
          setSlice({ image: response.image, offset: crop });
        })
        .catch(handleError);
    }

    return debounce(fetchSlice, IMAGE_UPDATE_DEBOUNCE_MS);
  }, [fetchTile, handleError]);

  useEffect(() => {
    return () => {
      setSlice(undefined);
    };
  }, [fetchSlice]);

  useEffect(() => {
    const sliceRes = new Point(viewSize.w / crop.w, viewSize.h / crop.h);

    if (sliceRes.x > sourceRes.x || sliceRes.y > sourceRes.y) {
      fetchSlice(viewSize, crop);
    }
  }, [fetchSlice, sourceRes, viewSize, crop]);

  return (
    <div
      className={styles.TiledImage}
      ref={frameRef}
      style={{
        width: imageSize.w / scaleFactor,
        height: imageSize.h / scaleFactor,
      }}
    >
      {source && (
        <img
          className={styles.source}
          src={source.image.src}
          alt=""
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      )}

      {slice && (
        <img
          src={slice.image.src}
          alt=""
          style={{
            height: slice.offset.h,
            width: slice.offset.w,
            transform: `
              scale(${1 / scaleFactor})
              translate(${slice.offset.x}px, ${slice.offset.y}px)
            `,
          }}
        />
      )}
    </div>
  );
}
