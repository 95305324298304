import { createMetric } from "lib/metric";
import { Bloodwork } from "lib/types/metrics/bloodwork";

export type Entries = Record<keyof Bloodwork, string>;

function toText(value: number | undefined) {
  if (value === undefined || Number.isNaN(value)) {
    return "";
  }
  return value.toString();
}

export function toEntries(bloodwork: Bloodwork): Entries {
  return {
    cholesterol: toText(bloodwork.cholesterol?.unit["mmol/L"]),
    cholesterolHDL: toText(bloodwork.cholesterolHDL?.unit.fraction),
    CRP: toText(bloodwork.CRP?.unit["mg/L"]),
    HbA1c: toText(bloodwork.HbA1c?.unit["mmol/mol"]),
    HDL: toText(bloodwork.HDL?.unit["mmol/L"]),
    LDL: toText(bloodwork.LDL?.unit["mmol/L"]),
    nonHDL: toText(bloodwork.nonHDL?.unit["mmol/L"]),
    triglycerides: toText(bloodwork.triglycerides?.unit["mmol/L"]),

    haemoglobin: toText(bloodwork.haemoglobin?.unit["g/L"]),
    glucose: toText(bloodwork.glucose?.unit["mmol/L"]),
    wbcTotal: toText(bloodwork.wbcTotal?.unit["x10⁹/L"]),
    neutrophils: toText(bloodwork.neutrophils?.unit["x10⁹/L"]),
    basophils: toText(bloodwork.basophils?.unit["x10⁹/L"]),
    monocytes: toText(bloodwork.monocytes?.unit["x10⁹/L"]),
    eosinophils: toText(bloodwork.eosinophils?.unit["x10⁹/L"]),
    lymphocytes: toText(bloodwork.lymphocytes?.unit["x10⁹/L"]),
  };
}

export function toBloodwork(entries: Entries): Bloodwork {
  const bloodwork: Bloodwork = {};

  const cholesterol = parseFloat(entries.cholesterol);
  if (isFinite(cholesterol)) {
    bloodwork.cholesterol = createMetric("bloodwork.cholesterol", {
      "mmol/L": cholesterol,
    });
  }

  const LDL = parseFloat(entries.LDL);
  if (isFinite(LDL)) {
    bloodwork.LDL = createMetric("bloodwork.ldl", { "mmol/L": LDL });
  }

  const triglycerides = parseFloat(entries.triglycerides);
  if (isFinite(triglycerides)) {
    bloodwork.triglycerides = createMetric("bloodwork.triglycerides", {
      "mmol/L": triglycerides,
    });
  }

  const HDL = parseFloat(entries.HDL);
  if (isFinite(HDL)) {
    bloodwork.HDL = createMetric("bloodwork.hdl", { "mmol/L": HDL });
  }

  const nonHDL = parseFloat(entries.nonHDL);
  if (isFinite(nonHDL)) {
    bloodwork.nonHDL = createMetric("bloodwork.non_hdl", {
      "mmol/L": nonHDL,
    });
  }

  const cholesterolHDL = parseFloat(entries.cholesterolHDL);
  if (isFinite(cholesterolHDL)) {
    bloodwork.cholesterolHDL = createMetric("bloodwork.cholesterol_hdl", {
      fraction: cholesterolHDL,
    });
  }

  const HbA1c = parseFloat(entries.HbA1c);
  if (isFinite(HbA1c)) {
    bloodwork.HbA1c = createMetric("bloodwork.hba1c", {
      "mmol/mol": HbA1c,
    });
  }

  const CRP = parseFloat(entries.CRP);
  if (isFinite(CRP)) {
    bloodwork.CRP = createMetric("bloodwork.crp", { "mg/L": CRP });
  }

  const haemoglobin = parseFloat(entries.haemoglobin);
  if (isFinite(haemoglobin)) {
    bloodwork.haemoglobin = createMetric("bloodwork.haemoglobin", {
      "g/L": haemoglobin,
    });
  }
  const glucose = parseFloat(entries.glucose);
  if (isFinite(glucose)) {
    bloodwork.glucose = createMetric("bloodwork.glucose", {
      "mmol/L": glucose,
    });
  }
  const wbcTotal = parseFloat(entries.wbcTotal);
  if (isFinite(wbcTotal)) {
    bloodwork.wbcTotal = createMetric("bloodwork.white_blood_cells.total", {
      "x10⁹/L": wbcTotal,
    });
  }
  const neutrophils = parseFloat(entries.neutrophils);
  if (isFinite(neutrophils)) {
    bloodwork.neutrophils = createMetric(
      "bloodwork.white_blood_cells.neutrophils",
      {
        "x10⁹/L": neutrophils,
      }
    );
  }
  const basophils = parseFloat(entries.basophils);
  if (isFinite(basophils)) {
    bloodwork.basophils = createMetric(
      "bloodwork.white_blood_cells.basophils",
      {
        "x10⁹/L": basophils,
      }
    );
  }
  const monocytes = parseFloat(entries.monocytes);
  if (isFinite(monocytes)) {
    bloodwork.monocytes = createMetric(
      "bloodwork.white_blood_cells.monocytes",
      {
        "x10⁹/L": monocytes,
      }
    );
  }
  const eosinophils = parseFloat(entries.eosinophils);
  if (isFinite(eosinophils)) {
    bloodwork.eosinophils = createMetric(
      "bloodwork.white_blood_cells.eosinophils",
      {
        "x10⁹/L": eosinophils,
      }
    );
  }
  const lymphocytes = parseFloat(entries.lymphocytes);
  if (isFinite(lymphocytes)) {
    bloodwork.lymphocytes = createMetric(
      "bloodwork.white_blood_cells.lymphocytes",
      {
        "x10⁹/L": lymphocytes,
      }
    );
  }

  return bloodwork;
}
