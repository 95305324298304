type Aggregator = (values: number[]) => number;

export function average(values: number[]) {
  return values.reduce((sum, val) => sum + val, 0) / values.length;
}

export function downsample(size: number, aggregator: Aggregator = average) {
  const buffer: number[] = [];

  return function sample(samples: number[], sample: number) {
    buffer.push(sample);
    if (buffer.length >= size) {
      samples.push(aggregator(buffer));
      buffer.length = 0;
    }
    return samples;
  };
}
