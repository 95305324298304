interface CircleProps {
  val: number;
  fill: string;
  toY: (n: number) => number;
}

export default function Circle({ val, fill, toY }: CircleProps) {
  const valNormalized = toY(val);

  return <circle cx="50%" cy={`${valNormalized}%`} r="2.5px" fill={fill} />;
}
