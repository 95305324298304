import { BoundingBoxAnnotation } from "@cur8/rich-entity";
import { fetchLesionImage, overscan } from "lib/api/resolvers/annotation";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "render/hooks/useBlobImage";
import styles from "./styles.module.sass";

interface LesionEvolutionImageProps {
  annotation: BoundingBoxAnnotation;
}

export default function LesionEvolutionImage({
  annotation,
}: LesionEvolutionImageProps) {
  const [blob, setBlob] = useState<Blob>();
  const [ready, setReady] = useState(false);

  const api = useAPIClient();

  useEffect(() => {
    const crop = overscan(annotation.data.rect, 3);
    const res = { w: Math.min(240, crop.w), h: Math.min(240, crop.h) };
    const request = fetchLesionImage(api, annotation, crop, res);
    request?.result
      .then((image) => {
        setBlob(image.blob);
      })
      .catch(silenceAbort);

    return () => {
      request?.abandon();
      setBlob(undefined);
    };
  }, [api, annotation]);

  const image = useBlobImage(blob);

  useEffect(() => {
    if (!image) {
      return;
    }

    image.decode().then(() => {
      setReady(true);
    });

    return () => {
      setReady(false);
    };
  }, [image]);

  return (
    <div className={styles.LesionEvolutionImage}>
      <div className={styles.image}>
        {ready && image && (
          <img
            src={image.src}
            alt="Lesion"
            data-annotation-id={annotation.id}
          />
        )}
      </div>
    </div>
  );
}
