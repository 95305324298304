import { useCallback, useEffect, useState } from "react";
import { useTissueAnnotationContext } from "../../context/TissueAnnotationContext";
import { TissueAnnotation, TissueAnnotationDataType } from "../../lib/types";
import LabelEditor from "../LabelEditor";
import DisplayLabel from "./DisplayLabel";

interface RegionLabelProps {
  allowEdit?: boolean;
  anno: TissueAnnotation;
  pixelsPerCm: number | undefined;
}

export default function RegionLabel({
  allowEdit = true,
  anno,
  pixelsPerCm,
}: RegionLabelProps) {
  const { select, selected, remove, replace } = useTissueAnnotationContext();
  const [toggleEdit, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (selected !== anno.annotation.id && toggleEdit) {
      setEditMode(false);
    }
  }, [anno.annotation.id, selected, toggleEdit]);

  const onSave = useCallback(
    (label: string) => {
      const na = { ...anno, label: label };
      replace(na).then((res) => {
        if (res) {
          setEditMode(false);
        }
      });
    },
    [anno, replace, setEditMode]
  );

  const edit = useCallback(() => {
    setEditMode(true);
  }, [setEditMode]);

  const onRemove = useCallback(() => {
    remove(anno.annotation.id);
  }, [anno.annotation.id, remove]);

  const onSelect = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      select(anno.annotation.id);
    },
    [anno.annotation.id, select]
  );

  const cancel = useCallback(() => {
    setEditMode(false);
  }, [setEditMode]);

  return (
    <div onClick={onSelect}>
      {toggleEdit ? (
        <LabelEditor
          addOrEdit="edit"
          type={TissueAnnotationDataType.Region}
          orgLabel={anno.label}
          onSave={onSave}
          onCancel={cancel}
          onRemove={onRemove}
        />
      ) : (
        <DisplayLabel
          allowEdit={allowEdit}
          anno={anno}
          edit={edit}
          isSelected={selected === anno.annotation.id}
          pixelsPerCm={pixelsPerCm}
        />
      )}
    </div>
  );
}
