import { createMetric } from "lib/metric";
import { useCallback, useEffect, useState } from "react";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useUserInput } from "render/hooks/useUserInput";
import Field from "render/ui/form/Field";
import Fieldset from "render/ui/form/Fieldset";
import Input from "render/ui/form/Input";
import RadioButtons from "render/ui/form/RadioButtons";
import BodyMetricPressure from "render/ui/format/BodyMetricPressure";
import BrachialPressure from "render/ui/format/BrachialPressure";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";
import ReferenceValue from "../../fragments/ReferenceValue";
import { Entries, toMetrics } from "./conversion";
import { CardioMetrics, useCardioMetrics } from "./hooks/useCardioMetrics";
import styles from "./styles.module.sass";

const ECGInterpretationStates = [
  { value: false, content: "Normal" },
  { value: true, content: "Review" },
];

const BinaryResponse = [
  { value: false, content: "No" },
  { value: true, content: "Yes" },
];

const INITIAL: Entries = {
  ankleLeft: "",
  ankleRight: "",

  brachialLeft: "",
  brachialRight: "",

  toeLeft: "",
  toeRight: "",

  bloodOxygen: "",

  manualBrachial: "",
  manualFemoral: "",
};

interface CardioSectionProps {
  patientId: string;
}

function validatePressureInput(e: React.FormEvent<HTMLInputElement>) {
  const input = (e.target as HTMLInputElement).value.split("/");
  const systolic = parseInt(input[0]);
  const diastolic = parseInt(input[1]);

  if (systolic < 50 || systolic > 280 || diastolic < 20 || diastolic > 160) {
    (e.target as HTMLInputElement).setCustomValidity(
      "Value must be within 50-280 mm Hg (sys) and 20-160 mm Hg (dia)"
    );
  } else {
    (e.target as HTMLInputElement).setCustomValidity("");
  }
}

export default function CardioSection({ patientId }: CardioSectionProps) {
  const { fetchCardioMetrics, storeCardioMetrics } = useCardioMetrics();

  const [latest, setLatest] = useState<CardioMetrics>();

  const [ecgDisc, setECGDisc] = useState<{
    value: boolean | undefined;
    touched: boolean;
  }>();
  const [diastolicMurmur, setDiastolicMurmur] = useState<{
    value: boolean | undefined;
    touched: boolean;
  }>();
  const [systolicMurmur, setSystolicMurmur] = useState<{
    value: boolean | undefined;
    touched: boolean;
  }>();

  const updateLatest = useCallback(() => {
    fetchCardioMetrics(patientId).then((metrics) => {
      setLatest(metrics);
      setECGDisc({ value: metrics.ecgDiscrepancy?.unit, touched: false });
      setDiastolicMurmur({
        value: metrics.murmur.diastolic?.unit,
        touched: false,
      });
      setSystolicMurmur({
        value: metrics.murmur.systolic?.unit,
        touched: false,
      });
    });
  }, [fetchCardioMetrics, patientId]);

  useEffect(() => {
    updateLatest();

    return () => {
      setLatest(undefined);
    };
  }, [updateLatest]);

  const [entries, setEntries] = useState<Entries>(INITIAL);

  const handleEntries = useCallback((entries: Entries) => {
    entries.brachialLeft = entries.brachialLeft.replace(/[^0-9/]/g, "");
    entries.brachialRight = entries.brachialRight.replace(/[^0-9/]/g, "");

    setEntries(entries);
  }, []);

  const userInput = useUserInput(entries, handleEntries);

  const handleSave = useCallback(async () => {
    const metrics = toMetrics(entries);

    if (ecgDisc?.touched && ecgDisc.value != null) {
      metrics.ecgDiscrepancy = createMetric(
        "risk_assessment.ecg_discrepancy",
        ecgDisc.value
      );
    }
    if (diastolicMurmur?.touched && diastolicMurmur.value != null) {
      metrics.murmur.diastolic = createMetric(
        "cardio.murmur.diastolic",
        diastolicMurmur.value
      );
    }
    if (systolicMurmur?.touched && systolicMurmur.value != null) {
      metrics.murmur.systolic = createMetric(
        "cardio.murmur.systolic",
        systolicMurmur.value
      );
    }

    await storeCardioMetrics(patientId, metrics);
    setEntries(INITIAL);

    setTimeout(updateLatest, 1000);
  }, [
    entries,
    ecgDisc?.touched,
    ecgDisc?.value,
    diastolicMurmur?.touched,
    diastolicMurmur?.value,
    systolicMurmur?.touched,
    systolicMurmur?.value,
    storeCardioMetrics,
    patientId,
    updateLatest,
  ]);

  const formHandle = useFormHandle(handleSave);

  return (
    <form className={styles.CardioSection} onSubmit={formHandle.onSubmit}>
      <Fieldset legend="Heart">
        <Field label="ECG Interpretation">
          <RadioButtons
            entries={ECGInterpretationStates}
            value={ecgDisc?.value}
            onChange={(value) => {
              setECGDisc({ value, touched: true });
            }}
          />
        </Field>

        <Field label="Diastolic Murmur">
          <RadioButtons
            entries={BinaryResponse}
            value={diastolicMurmur?.value}
            onChange={(value) => {
              setDiastolicMurmur({ value, touched: true });
            }}
          />
        </Field>
        <Field label="Systolic Murmur">
          <RadioButtons
            entries={BinaryResponse}
            value={systolicMurmur?.value}
            onChange={(value) => {
              setSystolicMurmur({ value, touched: true });
            }}
          />
        </Field>
      </Fieldset>

      <Fieldset legend="Blood Oxygen">
        <Field label="Saturation">
          <Input hint="%">
            <input
              type="number"
              inputMode="numeric"
              min={80}
              max={99}
              placeholder=""
              autoComplete="off"
              style={{ minWidth: "8em" }}
              {...userInput.bloodOxygen}
            />
          </Input>

          <ReferenceValue
            value={latest?.bloodOxygen ? latest.bloodOxygen.unit.percent : "--"}
          />
        </Field>
      </Fieldset>

      <Fieldset direction="row" legend="Brachial Pressure">
        <Field label="Left Arm">
          <Input hint="mm Hg">
            <input
              type="text"
              placeholder="-/-"
              pattern="\d+/\d+"
              autoComplete="off"
              {...userInput.brachialLeft}
              onInput={(e) => {
                validatePressureInput(e);
              }}
            />
          </Input>

          <ReferenceValue
            value={<BrachialPressure brachial={latest?.brachial?.left?.unit} />}
          />
        </Field>

        <Field label="Right Arm">
          <Input hint="mm Hg">
            <input
              type="text"
              placeholder="-/-"
              pattern="\d+/\d+"
              autoComplete="off"
              {...userInput.brachialRight}
              onInput={(e) => {
                validatePressureInput(e);
              }}
            />
          </Input>

          <ReferenceValue
            value={
              <BrachialPressure brachial={latest?.brachial?.right?.unit} />
            }
          />
        </Field>
      </Fieldset>

      <Fieldset direction="row" legend="Ankle Pressure">
        <Field label="Left Ankle">
          <Input hint="mm Hg">
            <input
              type="number"
              min={15}
              max={320}
              step="any"
              autoComplete="off"
              {...userInput.ankleLeft}
            />
          </Input>

          <ReferenceValue
            value={<BodyMetricPressure pressure={latest?.ankle?.left?.unit} />}
          />
        </Field>

        <Field label="Right Ankle">
          <Input hint="mm Hg">
            <input
              type="number"
              min={15}
              max={320}
              step="any"
              autoComplete="off"
              {...userInput.ankleRight}
            />
          </Input>

          <ReferenceValue
            value={<BodyMetricPressure pressure={latest?.ankle?.right?.unit} />}
          />
        </Field>
      </Fieldset>

      <Fieldset direction="row" legend="Toe Pressure">
        <Field label="Left Toe">
          <Input hint="mm Hg">
            <input
              type="number"
              min={15}
              max={320}
              step="any"
              autoComplete="off"
              {...userInput.toeLeft}
            />
          </Input>

          <ReferenceValue
            value={<BodyMetricPressure pressure={latest?.toe?.left?.unit} />}
          />
        </Field>

        <Field label="Right Toe">
          <Input hint="mm Hg">
            <input
              type="number"
              min={15}
              max={320}
              step="any"
              autoComplete="off"
              {...userInput.toeRight}
            />
          </Input>

          <ReferenceValue
            value={<BodyMetricPressure pressure={latest?.toe?.right?.unit} />}
          />
        </Field>
      </Fieldset>

      <Fieldset direction="row" legend="Manual Pressure">
        <Field label="Brachial">
          <Input hint="mm Hg">
            <input
              type="text"
              placeholder="-/-"
              pattern="\d+/\d+"
              autoComplete="off"
              {...userInput.manualBrachial}
              onInput={(e) => {
                validatePressureInput(e);
              }}
            />
          </Input>

          <ReferenceValue
            value={
              <BrachialPressure brachial={latest?.manual?.brachial?.unit} />
            }
          />
        </Field>

        <Field label="Femoral">
          <Input hint="mm Hg">
            <input
              type="text"
              placeholder="-/-"
              pattern="\d+/\d+"
              autoComplete="off"
              {...userInput.manualFemoral}
              onInput={(e) => {
                validatePressureInput(e);
              }}
            />
          </Input>

          <ReferenceValue
            value={
              <BrachialPressure brachial={latest?.manual?.femoral?.unit} />
            }
          />
        </Field>
      </Fieldset>

      <ButtonSet>
        <SubmitButton handle={formHandle}>Save</SubmitButton>
      </ButtonSet>
    </form>
  );
}
