export function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}

/*
 ** https://easings.net/#easeOutQuint
 */
export function easeOutQuint(x: number) {
  return 1 - Math.pow(1 - x, 5);
}
