import { SkinBookTracking } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";
import DropdownMenuButton from "render/ui/trigger/DropdownMenuButton";
import DropdownItem from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";

interface RemoveFromDermaMenuProps {
  item: SkinBookTracking;
  onRemove: () => void;
}

export default function RemoveFromDermaMenu({
  item,
  onRemove,
}: RemoveFromDermaMenuProps) {
  const api = useAPIClient();
  const { handleError } = useReporting();

  const handleForceClearFromDerma = useCallback(async () => {
    await api.skinBook
      .forceClearFromDerma(item.patientId, item.scanId)
      .result.then(() => {
        onRemove();
      })
      .catch(handleError);
  }, [api.skinBook, handleError, item.patientId, item.scanId, onRemove]);

  return (
    <DropdownMenuButton header="Manage">
      <DropdownItem onClick={handleForceClearFromDerma}>
        Remove from Derma
      </DropdownItem>
    </DropdownMenuButton>
  );
}
