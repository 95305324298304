import { Patient } from "@cur8/rich-entity";
import { DateTime } from "luxon";

export type Entries = {
  firstName: string;
  lastName: string;
  displayName: string;
  dateOfBirth: string;
  phoneNo: string;
  email: string;
};

const DATE_ONLY_FORMAT = "yyyy-LL-dd";

function formatPhoneNumber(phone: string) {
  // For Swedish numbers / local numbers we will disallow leading zeroes
  // For other countries this logic will be skipped
  const isInternational = phone.startsWith("+46");
  const trimmed = phone.trim().replace(/^\+46/, "").trim();

  // If the string starts with zeroes - delete them
  return `${isInternational ? "+46" : ""}${trimmed.replace(
    /^0{1,}([^0\n]*)/,
    "$1"
  )}`;
}

export function toEntries(patient: Patient): Entries {
  return {
    firstName: patient.name?.firstName ?? "",
    lastName: patient.name?.lastName ?? "",
    displayName: patient.name?.displayName ?? "",
    phoneNo: patient.contactDetails.phoneNumbers[0] ?? "",
    dateOfBirth: patient.dateOfBirth
      ? patient.dateOfBirth.toFormat(DATE_ONLY_FORMAT)
      : "",
    email: patient.contactDetails.email ?? "",
  };
}

function toDateOfBirth({ dateOfBirth }: Entries) {
  if (dateOfBirth.length > 0) {
    return DateTime.fromFormat(dateOfBirth, DATE_ONLY_FORMAT);
  }
}

function toPhoneNumbers(entries: Entries, source: Patient) {
  const phoneNo =
    entries.phoneNo.length > 0 ? formatPhoneNumber(entries.phoneNo) : "";
  return [phoneNo, ...source.contactDetails.phoneNumbers.slice(1)];
}

export function toPatient(entries: Entries, source: Patient): Patient {
  return {
    ...source,
    name: {
      firstName: entries.firstName.trimLeft(),
      lastName: entries.lastName.trimLeft(),
      displayName: entries.displayName.trimLeft(),
    },
    dateOfBirth: toDateOfBirth(entries),
    contactDetails: {
      ...source.contactDetails,
      email: entries.email.trim(),
      phoneNumbers: toPhoneNumbers(entries, source),
    },
  };
}
