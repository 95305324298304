import { Converters as HeightConverter } from "lib/conversion/height";
import { useMemo } from "react";
import { useMetricsUnits } from "render/context/MetricsUnitContext/MetricsUnitContext";

export function useUnitConverter() {
  const system = useMetricsUnits();

  return useMemo(() => {
    return {
      height: HeightConverter[system.length],
    };
  }, [system]);
}
