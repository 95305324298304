import { APITypesV1 } from "@cur8/api-client";
import { BodyMetrics } from "lib/types/metrics/body";
import { useCallback, useEffect, useState } from "react";
import { useBodyMetricStore } from "render/hooks/api/metrics/useBodyMetricsStore";
import { useRangeContext } from "../../context/RangeContext";
import { CardioSignals } from "../../hooks/useCardioSignals";
import GraphWithRuler from "../shared/GraphWithRuler";
import { ECG_COLOR, FEMORAL_COLOR, SSN_COLOR } from "../shared/constants";
import { JSXResult } from "../shared/fragments";
import { rangeToDistance } from "../shared/utils";
import SiradSelector from "./components/SiradSelector/SiradSelector";

interface PWVProps {
  patientId: string;
  signals: CardioSignals | undefined;
}

export default function PWV({ patientId, signals }: PWVProps) {
  const { selectionRange, setMeasurement, windowRange } = useRangeContext();
  const [femDistance, setFemDistance] = useState<number>();
  const [siradChannel, setSiradChannel] = useState(0);
  const [qualityMap, setQualityMap] =
    useState<Record<number, APITypesV1.PulseScore>>();

  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure PWV")
  );

  const { fetchBodyMetrics } = useBodyMetricStore();

  useEffect(() => {
    fetchBodyMetrics(patientId).then((metrics: BodyMetrics) => {
      const fd = metrics.ssn_femoralis_left?.measurement;
      if (fd) {
        setFemDistance(Math.round(fd.dimensions[0].value));
      }
    });
  }, [fetchBodyMetrics, patientId]);

  useEffect(() => {
    if (!signals || !selectionRange || selectionRange[0] >= selectionRange[1]) {
      setResultElement(
        JSXResult("", "Make a selection in the plot to measure PWV")
      );
      return;
    }
    const distanceMs = rangeToDistance(
      windowRange,
      selectionRange,
      signals.ecg.lengthMs
    );
    if (!femDistance) {
      setResultElement(
        JSXResult(
          "Cannot compute PWV",
          "no SSN-Femoral distance has been entered",
          `PTT: ${distanceMs} ms`
        )
      );
      return;
    }

    const pwv = Math.round((femDistance / 100 / distanceMs) * 1000 * 10) / 10;
    setMeasurement(pwv);

    setResultElement(
      JSXResult(
        `PWV: ${pwv} m/s`,
        `Using SSN-Femoral distance: ${femDistance} cm, PTT: ${distanceMs} ms`
      )
    );
  }, [femDistance, selectionRange, setMeasurement, signals, windowRange]);

  const handleChannelChange = useCallback((increase: boolean) => {
    setSiradChannel((prev) => {
      if (increase) {
        return prev < 9 ? prev + 1 : prev;
      } else {
        return prev > 0 ? prev - 1 : prev;
      }
    });
  }, []);

  useEffect(() => {
    if (signals?.sirad.bestChannel !== undefined) {
      setSiradChannel(signals.sirad.bestChannel);
    }

    if (signals?.sirad.quality?.siradQuality) {
      const dataMap: Record<number, APITypesV1.PulseScore> =
        signals.sirad.quality.siradQuality.reduce((acc, item) => {
          if (item.channel !== undefined) {
            acc[item.channel] = item;
          }
          return acc;
        }, {} as Record<number, APITypesV1.PulseScore>);
      setQualityMap(dataMap);
    }
  }, [signals?.sirad.bestChannel, signals?.sirad.quality?.siradQuality]);

  return signals ? (
    <div>
      <GraphWithRuler
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.ecg,
            color: ECG_COLOR,
          },
          { label: "SSN", signal: signals.ssn, color: SSN_COLOR },
          {
            label: "Femoral",
            signal: signals.sirad.signals[siradChannel].signal,
            color: FEMORAL_COLOR,
          },
        ]}
        diffData={[
          {
            label: "SSN Acceleration",
            signal: signals.ssn_acc,
            color: SSN_COLOR,
          },
          {
            label: "Femoral Acceleration",
            signal: signals.sirad.signals[siradChannel].acc,
            color: FEMORAL_COLOR,
          },
        ]}
        plugin={
          <SiradSelector
            channel={siradChannel}
            quality={qualityMap ? qualityMap[siradChannel].score : undefined}
            onChange={handleChannelChange}
          />
        }
      />
      {resultElement}
    </div>
  ) : null;
}
