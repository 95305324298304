import { hasBoundingBox } from "@cur8/rich-entity";
import { ArtefactHistoryEntry } from "../../../../history";
import AnnotationImage from "./components/AnnotationImage";
import RetakeLayer from "./components/RetakeLayer";
import styles from "./styles.module.sass";

const MAX_IMAGE_SIZE = { w: 1024, h: 1024 };

interface ArtefactHistoryEventProps {
  entry: ArtefactHistoryEntry;
  onUpdate: () => void;
}

export default function ArtefactHistoryEvent({
  entry,
  onUpdate,
}: ArtefactHistoryEventProps) {
  const annotation = entry.image;

  const crop = hasBoundingBox(annotation) ? annotation.data.rect : undefined;

  return (
    <div
      className={styles.ArtefactHistoryEvent}
      data-annotation={entry.image.id}
    >
      <div
        className={styles.image}
        data-target={entry.image.applicationSpecificTarget}
      >
        <AnnotationImage
          annotation={annotation}
          crop={crop}
          size={MAX_IMAGE_SIZE}
        />
      </div>

      <div className={styles.overlay}>
        <RetakeLayer annotation={annotation} onDone={onUpdate} />
      </div>
    </div>
  );
}
