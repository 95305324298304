import { urls } from "lib/derma";
import { useMemo } from "react";
import QRCode from "react-qr-code";
import PopupDialog from "render/ui/layouts/PopupDialog";
import PopupDialogContent from "render/ui/layouts/PopupDialogContent/PopupDialogContent";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface DermaLinkDialogProps {
  patientId: string;
  onClose(): void;
}

export default function DermaLinkDialog({
  patientId,
  onClose,
}: DermaLinkDialogProps) {
  const url = useMemo(() => {
    return urls.addImages(patientId);
  }, [patientId]);

  return (
    <PopupDialog>
      <PopupDialogContent
        head={<h2>Scan Code</h2>}
        body={
          <div className={styles.DermaLinkDialog}>
            <QRCode value={url} />
            Scan Code to Take Pictures using Derma App
          </div>
        }
        buttons={
          <>
            <HoverTextButton type="button" onClick={onClose}>
              Done
            </HoverTextButton>
          </>
        }
      />
    </PopupDialog>
  );
}
