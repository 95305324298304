import { APITypesV1 } from "@cur8/api-client";
import { fromAPI, Patient } from "@cur8/rich-entity";
import { useLocation } from "@pomle/react-router-paths";
import { silenceAbort } from "lib/error";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNotifications } from "render/context/NotificationContext";
import { useLLMConsent } from "render/fragments/visit/DoctorScribe/hooks/useLLMConsent";
import { paths } from "render/routes/paths";
import { useAPIClient } from "../../../APIContext";
import { useAppInsights } from "../../../AppInsightsContext";

import { useLLMScan } from "render/fragments/visit/DoctorScribe/hooks/useLLMScan";
import {
  RecordingSession,
  useAudioRecording,
} from "../../../AudioRecordingContext";
import { useMSAL } from "../../../MSALContext";

const MAX_TOTAL_DURATION_MINUTES = 30; // Cut recording after 30 minutes

async function getAudioInputDevices() {
  return new Promise<MediaDeviceInfo[]>((resolve, reject) => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const audioDevices = devices.filter(
          (device) => device.kind === "audioinput"
        );
        resolve(audioDevices);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

function useAudioDevice() {
  async function getSelectedAudioInputDeviceLabel(): Promise<string> {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      throw new Error("Enumerating devices is not supported in this browser.");
    }

    const devices = await navigator.mediaDevices.enumerateDevices();
    const audioInputDevices = devices.filter(
      (device) => device.kind === "audioinput"
    );
    const defaultAudioInputDevice = audioInputDevices.find(
      (device) => device.deviceId === "default"
    );

    return defaultAudioInputDevice
      ? defaultAudioInputDevice.label
      : "No audio input device";
  }

  const [audioDeviceId, setAudioDeviceId] = useState<string>();
  const { emit } = useNotifications();

  const handleDeviceChange = useCallback(
    (devices: MediaDeviceInfo[]) => {
      const jabraDevices = devices.filter((d) =>
        d.label.toLowerCase().includes("jabra")
      );
      // Filter for the preferred DVS devices. The first devices deployed have a name with two spaces in it...
      const dvsDevices = devices.filter(
        (d) =>
          d.label.includes("DVS Receive  1-2") ||
          d.label.includes("DVS Receive 1-2")
      );

      if (jabraDevices.length > 0) {
        setAudioDeviceId(jabraDevices[0].deviceId);
        emit(`Using Jabra device: ${jabraDevices[0].label}`, "success"); // prefer Jabra device (if connected..)
      } else if (dvsDevices.length > 0) {
        setAudioDeviceId(dvsDevices[0].deviceId);
        emit(`Using roof device: ${dvsDevices[0].label}`, "success"); // otherwise, use DVS for roof mic (if available)
      } else if (devices.length > 0) {
        setAudioDeviceId(""); // otherwise, do not specify => system default
      } else {
        emit("No audio input device connected.", "error");
      }
    },
    [emit]
  );

  useEffect(() => {
    getAudioInputDevices().then((devices) => {
      handleDeviceChange(devices);
    });
  }, [emit, handleDeviceChange]);

  useEffect(() => {
    const handleDeviceChangeWrapper = () => {
      getAudioInputDevices().then((devices) => {
        handleDeviceChange(devices);
      });
    };

    navigator.mediaDevices.addEventListener(
      "devicechange",
      handleDeviceChangeWrapper
    );

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        handleDeviceChangeWrapper
      );
    };
  }, [handleDeviceChange]);

  return { audioDeviceId, getSelectedAudioInputDeviceLabel };
}

function usePatientFromPath() {
  const path = useLocation().pathname;

  const api = useAPIClient().patient;

  const patientId = useMemo(() => {
    return paths.patient.detail.parse(path)?.patientId;
  }, [path]);

  const [patient, setPatient] = useState<Patient>();

  useEffect(() => {
    if (!patientId) {
      return;
    }

    const request = api.fetchPatient({ patientId });

    request.result
      .then(fromAPI.toPatientDetails)
      .then((patient) => {
        setPatient(patient);
      })
      .catch(silenceAbort);

    return () => {
      request.abandon();
      setPatient(undefined);
    };
  }, [api, patientId]);

  return useMemo(() => {
    // A little paranoia check to ensure we never return an unmatching patient
    // even if a patient object was set due to race condition in fetch
    if (patient?.patientId === patientId) {
      return patient;
    }
  }, [patient, patientId]);
}

export function usePatientRecorder() {
  const patient = usePatientFromPath();

  const recorder = useAudioRecording();
  const startRecording = recorder.start;

  const [session, setSession] = useState<RecordingSession>();

  const busyRef = useRef(false);

  const { canUseSummaries } = useLLMConsent(patient?.patientId);
  const { audioDeviceId, getSelectedAudioInputDeviceLabel } = useAudioDevice();
  const { emit } = useNotifications();

  const [timerStart, setTimerStart] = useState(false);
  const timerRef = useRef<NodeJS.Timeout>();

  const appInsights = useAppInsights();
  const { auth } = useMSAL();

  const { createLLMScan } = useLLMScan(patient?.patientId);

  const trackEvent = useCallback(
    (
      name: string,
      device: string | undefined = undefined,
      scan: APITypesV1.ImmutableScan | undefined = undefined
    ) => {
      if (patient && auth && auth.account) {
        const scanId = scan?.id;
        appInsights.trackEvent(
          { name },
          {
            userId: auth.account.homeAccountId,
            patient: patient.patientId,
            device,
            scanId,
          }
        );
      }
    },
    [appInsights, auth, patient]
  );

  const engage = useCallback(async () => {
    if (!patient || audioDeviceId === undefined || busyRef.current) {
      return;
    }

    busyRef.current = true;

    if (audioDeviceId.length === 0) {
      const device = await getSelectedAudioInputDeviceLabel();
      trackEvent("recording_preferred_device_not_connected", device);
      emit(
        `No preferred audio input device connected, using system default: ${device}`,
        "error"
      );
    }

    const scan = await createLLMScan(patient.patientId);
    if (!scan) {
      trackEvent("recording_no_scan");
      emit(
        `Could not start recording. Ensure ${patient.patientId} is assigned to a room and checked in.`,
        "error"
      );
      busyRef.current = false;
      return;
    }

    try {
      const session = await startRecording(patient, audioDeviceId, scan);
      setSession(session);
      trackEvent("recording_engaged", audioDeviceId, scan);
      setTimerStart(true);
    } catch (e) {
      trackEvent("recording_could_not_start", audioDeviceId, scan);
      emit(`Could not start audio recording: ${e}`, "error");
    }
    busyRef.current = false;
  }, [
    patient,
    startRecording,
    audioDeviceId,
    getSelectedAudioInputDeviceLabel,
    emit,
    trackEvent,
    createLLMScan,
  ]);

  const disengage = useCallback(() => {
    setTimerStart(false);
    if (!session) {
      return;
    }
    recorder.stop(patient);
    session.stop();
    setSession(undefined);
  }, [session, recorder, patient]);

  const toggle = useCallback(() => {
    if (session) {
      disengage();
    } else {
      engage();
    }
  }, [session, engage, disengage]);

  const patientId = patient?.patientId;

  useEffect(() => {
    if (session) {
      return () => {
        console.debug("Stopping recording for", patientId);
        trackEvent("recording_disengaged");
        disengage();
      };
    }
  }, [patientId, session, disengage, trackEvent]);

  useEffect(() => {
    if (timerStart) {
      timerRef.current = setTimeout(() => {
        trackEvent("recording_auto_stopped");
        disengage();
        emit(
          `Auto stopping recording, max record time is ${MAX_TOTAL_DURATION_MINUTES} minutes`,
          "error"
        );
      }, MAX_TOTAL_DURATION_MINUTES * 60 * 1000);
      return () => clearTimeout(timerRef.current);
    }
  }, [disengage, timerStart, trackEvent, emit]);

  const ready = !!patient && canUseSummaries && audioDeviceId !== undefined;

  return { ready, toggle, session };
}
