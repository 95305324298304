import { RouterContext } from "@pomle/react-router-paths";
import APIContext from "render/context/APIContext";
import AppInsightsContext from "render/context/AppInsightsContext";
import AudioRecordingContext from "render/context/AudioRecordingContext";
import ConfigContext from "render/context/ConfigContext";
import KeyboardContext from "render/context/KeyboardContext";
import MSALContext from "render/context/MSALContext";
import NotificationContext from "render/context/NotificationContext";
import PopupDialogContext from "render/context/PopupDialogContext";
import StoreContext from "render/context/StoreContext";
import PatientAudioRecordContext from "render/context/patient/PatientAudioRecordContext";
import ApplicationLayout from "render/layouts/ApplicationLayout";
import Router from "render/routes/Router";
import "./App.css";

function App() {
  return (
    <ConfigContext>
      <AppInsightsContext>
        <KeyboardContext>
          <PopupDialogContext>
            <NotificationContext>
              <MSALContext>
                <StoreContext>
                  <APIContext>
                    <RouterContext history={window.history}>
                      <ApplicationLayout>
                        <AudioRecordingContext>
                          <PatientAudioRecordContext>
                            <Router />
                          </PatientAudioRecordContext>
                        </AudioRecordingContext>
                      </ApplicationLayout>
                    </RouterContext>
                  </APIContext>
                </StoreContext>
              </MSALContext>
            </NotificationContext>
          </PopupDialogContext>
        </KeyboardContext>
      </AppInsightsContext>
    </ConfigContext>
  );
}

export default App;
