import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import Layout, { LayoutProps } from "../Layout";
import SidePanel from "../SidePanel";
import ArterySection from "./components/ArterySection";
import BloodFatsLeftSection from "./components/BloodFatsLeftSection";
import BloodFatsRightSection from "./components/BloodFatsRightSection";
import styles from "./styles.module.sass";

export default function CholesterolLayout({ active }: LayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.BloodFatsPanel} data-active={active}>
        <SidePanel>
          {patient && <BloodFatsLeftSection patient={patient} />}
        </SidePanel>

        <div className={styles.ArteryPanel}>
          {patient && <ArterySection patient={patient} />}
        </div>

        <SidePanel>
          {patient && <BloodFatsRightSection patient={patient} />}
        </SidePanel>
      </div>
    </Layout>
  );
}
