import styles from "./styles.module.sass";

interface LengthValueProps {
  value: React.ReactNode;
  unit?: React.ReactNode;
}

export default function LengthValue({ value, unit }: LengthValueProps) {
  return (
    <span className={styles.LengthValue}>
      <span className={styles.amount}>{value}</span>&nbsp;
      {unit && <span className={styles.unit}>{unit}</span>}
    </span>
  );
}
