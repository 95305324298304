import { PhysicalIdType } from "@cur8/uri";
import { useCallback } from "react";
import { humanName } from "render/fragments/patient/PhysicalIdName";

const OPTIONS: PhysicalIdType[] = [
  PhysicalIdType.Legacy,
  PhysicalIdType.BankId,
  PhysicalIdType.USSocialSecurityNo,
];

interface PatientIdTypeSelectProps {
  value: PhysicalIdType;
  onChange: (value: PhysicalIdType) => void;
}

export default function PatientIdTypeSelect({
  value,
  onChange,
}: PatientIdTypeSelectProps) {
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const physicalIdType = OPTIONS.find((physicalIdType) => {
        return physicalIdType.toString() === event.target.value;
      });

      if (physicalIdType !== undefined) {
        onChange(physicalIdType);
      }
    },
    [onChange]
  );

  return (
    <select value={value} onChange={handleChange}>
      {OPTIONS.map((idType) => (
        <option key={idType} value={idType}>
          {humanName(idType)}
        </option>
      ))}
    </select>
  );
}
