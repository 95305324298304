import { createContext } from "react";
import { useUI } from "./hooks/useUI";
import { usePodium } from "./hooks/usePodium";
import { useCamera } from "./hooks/useCamera";

type DashboardContextValue = {
  camera: ReturnType<typeof useCamera>;
  podium: ReturnType<typeof usePodium>;
  ui: ReturnType<typeof useUI>;
};

export const Context = createContext<DashboardContextValue | null>(null);
