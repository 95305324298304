import { HbA1c } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { MAX_PROJECTION_AGE } from "lib/projections/hba1c/hba1c.projection";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import GlucoseSegment from "./components/GlucoseSegment";
import HbA1cBenchmark from "./components/HbA1cBenchmark";
import BenchmarkHeader from "./components/HbA1cBenchmark/components/Header";
import HbA1cProjectionGraph from "./components/HbA1cProjectionGraph";
import HbA1cSegment from "./components/HbA1cSegment";
import styles from "./styles.module.sass";

interface DiabetesSectionProps {
  patient: Patient;
}

export default function DiabetesSection({ patient }: DiabetesSectionProps) {
  const {
    metrics: { bloodwork },
  } = usePatientData();
  const age = useAge(patient);

  /**
   * If the latest HbA1c value is in the diabetes or low hba1c range, the hba1c projection graph is not relevant/should not be shown.
   */
  const isCurrentValueWithinHba1cProjectionBounds = useMemo(() => {
    const latestMetric = bloodwork.hba1c?.[0];

    if (!latestMetric) {
      return false;
    }

    const hba1cRiskRanges = HbA1c.rangesFor({ age: age });

    const hba1cDiabetesRange = hba1cRiskRanges.entries.find(
      (range) => range.label === "diabetes"
    )!;

    // values confirmed with MedOps
    const projectionBounds = {
      min: 25,
      max: hba1cDiabetesRange.from,
    };

    return (
      latestMetric.unit["mmol/mol"] >= projectionBounds.min &&
      latestMetric.unit["mmol/mol"] <= projectionBounds.max
    );
  }, [bloodwork, age]);

  return (
    <div className={styles.DiabetesSection}>
      <section>
        <Typography as="h2" variant="display-s">
          Diabetes factors
        </Typography>
        <hr />
      </section>
      {patient && !!bloodwork.hba1c?.length && !!bloodwork.glucose?.length && (
        <section className={styles.rangesSection}>
          <HbA1cSegment patient={patient} metrics={bloodwork.hba1c} />
          <GlucoseSegment patient={patient} metrics={bloodwork.glucose} />
        </section>
      )}

      {patient &&
        !!bloodwork.hba1c?.length &&
        isCurrentValueWithinHba1cProjectionBounds &&
        age < MAX_PROJECTION_AGE && (
          <section>
            <HbA1cProjectionGraph patient={patient} metrics={bloodwork.hba1c} />
          </section>
        )}

      {patient && !!bloodwork.hba1c?.length && (
        <section>
          <BenchmarkHeader
            metrics={bloodwork.hba1c}
            sex={patient.sex}
            age={age}
          />
          <HbA1cBenchmark patient={patient} metrics={bloodwork.hba1c} />
        </section>
      )}
    </div>
  );
}
