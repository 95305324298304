import { Box } from "@cur8/rich-entity";
import {
  clampBox,
  fetchLesionImage,
  overscan,
} from "lib/api/resolvers/annotation";
import { silenceAbort } from "lib/error";
import { Lesion, byLinkDate, isDermaLink, isPreferredLink } from "lib/lesion";
import { useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "render/hooks/useBlobImage";
import LesionId from "render/ui/format/LesionId";
import LocatorBox from "./components/LocatorBox";
import styles from "./styles.module.sass";

interface TrackedLesionImageProps {
  active: boolean;
  delay: number;
  lesion: Lesion;
}

export default function TrackedLesionImage({
  active,
  delay,
  lesion,
}: TrackedLesionImageProps) {
  const [motif, setMotif] = useState<{ blob: Blob; crop: Box }>();
  const [ready, setReady] = useState(false);

  const link = useMemo(() => {
    return lesion.links
      .filter(isPreferredLink)
      .toSorted(byLinkDate("desc"))
      .at(0);
  }, [lesion]);

  const annotation = link?.annotation;

  const derma = useMemo(() => {
    return lesion.links.find(isDermaLink);
  }, [lesion]);

  const api = useAPIClient();

  useEffect(() => {
    if (!annotation) {
      return;
    }

    const crop = overscan(annotation.data.rect, 4);
    const res = { w: Math.min(320, crop.w), h: Math.min(320, crop.h) };
    const request = fetchLesionImage(api, annotation, crop, res);
    request?.result
      .then((image) => {
        const { w, h } = image.meta.original.size;

        const bounds = new Box(0, 0, w, h);

        setMotif({ blob: image.blob, crop: clampBox(crop, bounds) });
      })
      .catch(silenceAbort);

    return () => {
      request?.abandon();
      setMotif(undefined);
    };
  }, [api, annotation]);

  const image = useBlobImage(motif?.blob);

  useEffect(() => {
    if (!image) {
      return;
    }

    image.decode().then(() => {
      setReady(true);
    });

    return () => {
      setReady(false);
    };
  }, [image]);

  const size = annotation?.classification.size;

  return (
    <div
      className={styles.TrackedLesionImage}
      data-visible={active}
      style={{ transitionDelay: `${delay}s` }}
    >
      <div className={styles.content}>
        <div className={styles.image} data-annotation-id={annotation?.id}>
          {ready && image && <img src={image.src} alt="Lesion" />}
        </div>

        <div className={styles.locator}>
          {annotation && motif?.crop && (
            <LocatorBox annotation={annotation} crop={motif.crop} />
          )}
        </div>

        {derma && <div className={styles.dermaTag}>Derma</div>}

        <div className={styles.name}>
          <LesionId lesion={lesion} />
        </div>

        <div className={styles.size}>{size ? size.toFixed(1) : "--"}mm</div>
      </div>
    </div>
  );
}
