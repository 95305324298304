import { APITypesV2 } from "@cur8/api-client";

type QuestionId =
  | "additionalWorries"
  | "diet"
  | "healthConditions"
  | "healthConditionsFamily"
  | "medications.type"
  | "ethnicities"
  | "allergies"
  | "allergies.list"
  | "alcohol"
  | "alcohol.weeklyDrinks"
  | "weeklyCardioExerciseHours"
  | "weeklyStrengthExerciseHours"
  | "smoking.style"
  | "smoking.dailyCigarettes"
  | "snus.style"
  | "snus.frequency"
  | "cardiovascular"
  | "skinCancer"
  | "diabetes"
  | "relevantConditions";

type v1QuestionId = "weeklyExerciseHours" | "snus.weeklyCans" | "medication";

export function createResolver(response: APITypesV2.QuestionnaireResponse) {
  return {
    findAnswer(questionId: QuestionId | v1QuestionId) {
      return findAnswer(response, questionId);
    },
    readValue(questionId: QuestionId | v1QuestionId) {
      const answer = findAnswer(response, questionId);
      return convertValue(answer);
    },
  };
}

function findAnswer(
  response: APITypesV2.QuestionnaireResponse,
  questionId: QuestionId | v1QuestionId
) {
  return response.questionsAndAnswers.find((q) => q.questionId === questionId)
    ?.answer?.value;
}

function convertValue(
  answer: NonNullable<APITypesV2.QuestionAnswer["answer"]>["value"] | undefined
) {
  if (answer == null) {
    return;
  }
  if (Array.isArray(answer)) {
    return answer.join(", ");
  }
  if (typeof answer === "boolean") {
    return answer ? "Yes" : "No";
  }
  return String(answer);
}
