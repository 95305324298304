import { Patient } from "@cur8/rich-entity";
import { Interval } from "luxon";
import { CalendarEvent } from "./slotManagementUtils";

export type EnterSlotEditingModeAction = { type: "ENTER_SLOT_EDITING_MODE" };
export const enterSlotEditingModeAction = (): EnterSlotEditingModeAction => ({
  type: "ENTER_SLOT_EDITING_MODE",
});

export type EnterCalendarViewModeAction = { type: "ENTER_CALENDAR_VIEW_MODE" };
export const enterCalendarViewModeAction = (): EnterCalendarViewModeAction => ({
  type: "ENTER_CALENDAR_VIEW_MODE",
});

export type EnterCalendarBookingModeAction = {
  type: "ENTER_CALENDAR_BOOKING_MODE";
  payload: Patient;
};
export const enterCalendarBookingModeAction = (
  payload: Patient
): EnterCalendarBookingModeAction => ({
  type: "ENTER_CALENDAR_BOOKING_MODE",
  payload,
});

export type AddSlotToSelectedAction = {
  type: "ADD_SELECTED_SLOT";
  // interval in case of new slot creation
  payload: CalendarEvent;
};
export const addSlotToSelectedAction = (
  payload: CalendarEvent
): AddSlotToSelectedAction => ({
  type: "ADD_SELECTED_SLOT",
  payload,
});

export type RemoveSlotFromSelectedAction = {
  type: "REMOVE_SELECTED_SLOT";
  // interval in case of new slot creation
  payload: CalendarEvent;
};
export const removeSlotFromSelectedAction = (
  payload: CalendarEvent
): RemoveSlotFromSelectedAction => ({
  type: "REMOVE_SELECTED_SLOT",
  payload,
});

export type ResetSelectedSlotsAction = {
  type: "RESET_SELECTED_SLOTS";
};
export const resetSelectedSlotsAction = (): ResetSelectedSlotsAction => ({
  type: "RESET_SELECTED_SLOTS",
});

export type SetAppointmentsCensoredAction = {
  type: "SET_APPOINTMENTS_CENSORED";
  payload: boolean;
};
export const setAppointmentsCensoredAction = (
  payload: boolean
): SetAppointmentsCensoredAction => ({
  type: "SET_APPOINTMENTS_CENSORED",
  payload,
});

export type SetSelectedPeriodAction = {
  type: "SET_SELECTED_PERIOD";
  payload: Interval;
};
export const setSelectedPeriodAction = (
  payload: Interval
): SetSelectedPeriodAction => ({
  type: "SET_SELECTED_PERIOD",
  payload,
});

export type Action =
  | EnterCalendarViewModeAction
  | EnterCalendarBookingModeAction
  | EnterSlotEditingModeAction
  | AddSlotToSelectedAction
  | RemoveSlotFromSelectedAction
  | ResetSelectedSlotsAction
  | SetAppointmentsCensoredAction
  | SetSelectedPeriodAction;
