import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import Layout, { LayoutProps } from "../Layout";
import SidePanel from "../SidePanel";
import HeartPanel from "./components/HeartPanel";
import styles from "./styles.module.sass";

export default function HeartLayout({ active }: LayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.HeartLayout}>
        <div className={styles.heartPanel} data-active={active}>
          <SidePanel>
            {patient && <HeartPanel active={active} patient={patient} />}
          </SidePanel>
        </div>
      </div>
    </Layout>
  );
}
