import { Point } from "lib/math";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTissueAnnotationContext } from "../../context/TissueAnnotationContext";
import { useTissueImageContext } from "../../context/TissueImageContext";
import { useQueries } from "../../hooks/useQueries";
import {
  BloodVesselsMode,
  ImageSize,
  ImageType,
  Property,
  PropertyRange,
} from "../../lib/types";
import LabelEditor from "../LabelEditor";
import BloodVesselsImage from "./BloodVesselsImage";
import GradientImage from "./GradientImage";
import RangeLegend from "./RangeLegend";
import WaitingForImage from "./WaitingForImage";
import Annotations from "./components/Annotations/Annotations";
import Controls from "./components/Controls";
import styles from "./styles.module.sass";

interface ImageViewerProps {
  allowAdd?: boolean;
  allowResize?: boolean;
  children?: React.ReactNode;
  showControls?: boolean;
  range?: PropertyRange;
}

export default function ImageViewer({
  allowAdd = true,
  allowResize = true,
  children,
  range,
  showControls = true,
}: ImageViewerProps) {
  const { bloodVesselsMode, colormap, imageType, property, setImageType } =
    useQueries();
  const { cancelCreate, newAnno, save, select, setLabel } =
    useTissueAnnotationContext();
  const { viewer: viewerImage, bloodVessels: bloodVesselsImage } =
    useTissueImageContext();

  const [scale, setScale] = useState<number>(1);
  const [imageRange, setImageRange] = useState<PropertyRange | undefined>(
    range
  );
  const [imageSize, setImageSize] = useState<ImageSize>({
    width: 0,
    height: 0,
  });
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isFullscreen, setFullscreen] = useState<boolean>(false);
  const [showBloodVessels, setShowBloodVessels] = useState<boolean>(true);

  const [showLabelEditor, setShowLabelEditor] = useState<boolean>(false);
  const [anchorPosition, setAnchorPosition] = useState<Point>(
    new Point(100, 100)
  );

  useEffect(() => {
    if (!newAnno) {
      setShowLabelEditor(false);
    }
  }, [newAnno]);

  const updateSize = useCallback(() => {
    if (!wrapperRef.current || !viewerImage || !viewerImage.image) {
      return;
    }
    const rect = wrapperRef.current.getBoundingClientRect();
    const ws = rect.width / viewerImage.image.naturalWidth;
    const hs = rect.height / viewerImage.image.naturalHeight;
    const s = Math.min(ws, hs);
    const is = {
      width: viewerImage.image.naturalWidth,
      height: viewerImage.image.naturalHeight,
    };
    setImageSize(is);
    setScale(s);
    setAnchorPosition(
      new Point((is.width / 2) * scale, (is.height / 2) * scale)
    );
  }, [wrapperRef, scale, viewerImage]);

  useEffect(() => {
    if (property === Property.thermal || !bloodVesselsImage) {
      setShowBloodVessels(false);
    } else {
      setShowBloodVessels(true);
    }
  }, [bloodVesselsImage, property]);

  useEffect(() => {
    if (property === Property.thermal && imageType === ImageType.rgb) {
      setImageType(ImageType.normal);
    }
  }, [imageType, property, setImageType]);

  useEffect(() => {
    updateSize();
  }, [isFullscreen, property, updateSize, wrapperRef]);

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, [updateSize]);

  useEffect(() => {
    if (
      !viewerImage ||
      viewerImage.imin === undefined ||
      viewerImage.imax === undefined ||
      !range
    ) {
      return;
    }
    setImageRange({
      low: viewerImage.imin * (range.factor ?? 1),
      high: viewerImage.imax * (range.factor ?? 1),
      unit: range.displayUnit ?? range.unit,
    });
  }, [viewerImage, range]);

  useEffect(() => {
    function keyDownHandler(ev: KeyboardEvent) {
      if (ev.ctrlKey || ev.shiftKey || ev.altKey) {
        // Ignore if any modifier key is active
        return;
      }
      switch (ev.key) {
        case "Escape":
          if (isFullscreen) {
            setFullscreen(false);
          }
          select(undefined);
          cancelCreate();
          break;
        default:
          return;
      }
    }

    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [cancelCreate, isFullscreen, select]);

  const onAnnoCreated = useCallback(() => {
    setShowLabelEditor(true);
  }, []);

  const onSaveLabel = useCallback(
    (label: string) => {
      setLabel(label);
      save();
    },
    [save, setLabel]
  );

  return (
    <div
      className={styles.ImageViewer}
      data-fullscreen={isFullscreen}
      data-controls={showControls}
    >
      {showControls && (
        <Controls
          allowAdd={allowAdd}
          isFullscreen={isFullscreen}
          setFullscreen={setFullscreen}
          showBloodVessels={showBloodVessels}
        />
      )}

      <div className={styles.imageColumn} ref={wrapperRef}>
        <svg
          width={imageSize.width * scale}
          height={imageSize.height * scale}
          viewBox={`0 0 ${imageSize.width} ${imageSize.height}`}
          className={styles.svg}
        >
          {viewerImage ? (
            <GradientImage
              colormap={colormap}
              tissueImage={viewerImage}
              type={imageType}
              transparencyCutoff={1 / 255}
            />
          ) : (
            <WaitingForImage />
          )}
          {showBloodVessels &&
            bloodVesselsImage &&
            bloodVesselsMode !== BloodVesselsMode.inactive && (
              <BloodVesselsImage
                bloodVesselsMode={bloodVesselsMode}
                tissueImage={bloodVesselsImage}
              />
            )}
          {viewerImage?.image && (
            <Annotations
              allowResize={allowResize}
              canvasSize={imageSize}
              onAnnoCreated={onAnnoCreated}
              scale={scale}
            />
          )}
        </svg>
        {imageRange && imageType !== ImageType.rgb && (
          <RangeLegend colormap={colormap} range={imageRange} />
        )}
        {showLabelEditor && newAnno && (
          <LabelEditor
            addOrEdit="add"
            type={newAnno.type}
            anchor={anchorPosition}
            onCancel={cancelCreate}
            onSave={onSaveLabel}
            orgLabel={newAnno.label}
          />
        )}
        {children}
      </div>
    </div>
  );
}
