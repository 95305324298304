import { clamp } from "lib/math";
import { useCallback, useState } from "react";
import { useRangeContext } from "../../../context/RangeContext";
import { MINIMUM_SELECTION } from "../constants";
import styles from "./styles.module.sass";

enum Cursor {
  crosshair = "crosshair",
  grabbing = "grabbing",
  grab = "grab",
  resizeLeft = "resizeLeft",
  resizeRight = "resizeRight",
}

interface DataWindowProps {
  children: React.ReactNode;
}

export default function DataWindow({ children }: DataWindowProps) {
  const { windowRange, setWindowRange } = useRangeContext();
  const [dragPoint, setDragPoint] = useState<number>();
  const [cursor, setCursor] = useState<Cursor>(Cursor.crosshair);

  const isInSelection = useCallback(
    (pos: number) => {
      return pos > Math.min(...windowRange) && pos < Math.max(...windowRange);
    },
    [windowRange]
  );

  const EDGE_MARGIN = 0.01;

  const isAtLeftEdge = useCallback(
    (pos: number) => {
      const left = Math.min(...windowRange);
      return Math.abs(pos - left) < EDGE_MARGIN;
    },
    [windowRange]
  );

  const isAtRightEdge = useCallback(
    (pos: number) => {
      const right = Math.max(...windowRange);
      return Math.abs(pos - right) < EDGE_MARGIN;
    },
    [windowRange]
  );

  const handlePointer = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const bounds = event.currentTarget.getBoundingClientRect();
      const pos = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);

      if (event.type === "pointerdown") {
        setDragPoint(undefined);
        if (isAtLeftEdge(pos)) {
          setCursor(Cursor.resizeLeft);
        } else if (isAtRightEdge(pos)) {
          setCursor(Cursor.resizeRight);
        } else if (isInSelection(pos)) {
          setDragPoint(pos - Math.min(...windowRange));
          setCursor(Cursor.grabbing);
        } else {
          setCursor(Cursor.crosshair);
          setWindowRange([pos, pos]);
        }
      } else if (event.type === "pointermove") {
        if (!!event.buttons) {
          const start = Math.min(...windowRange);
          const end = Math.max(...windowRange);
          if (cursor === Cursor.resizeLeft) {
            setWindowRange([pos, end]);
          } else if (cursor === Cursor.resizeRight) {
            setWindowRange([start, pos]);
          } else if (dragPoint === undefined) {
            setWindowRange([windowRange[0], pos]);
          } else {
            // just moving, ensure we don't move out
            const left = pos - dragPoint;
            const right =
              pos + Math.abs(windowRange[1] - windowRange[0]) - dragPoint;
            if (left > 0 && right < 1) {
              setWindowRange([
                pos - dragPoint,
                pos + Math.abs(windowRange[1] - windowRange[0]) - dragPoint,
              ]);
            }
          }
        } else {
          if (isAtLeftEdge(pos) || isAtRightEdge(pos)) {
            setCursor(Cursor.resizeLeft);
          } else if (isInSelection(pos)) {
            setCursor(Cursor.grab);
          } else {
            setCursor(Cursor.crosshair);
          }
        }
      } else if (event.type === "pointerup") {
        setDragPoint(undefined);
        setCursor(isInSelection(pos) ? Cursor.grab : Cursor.crosshair);
        const delta = Math.abs(windowRange[1] - windowRange[0]);
        const minDelta = MINIMUM_SELECTION;
        if (delta < minDelta) {
          setWindowRange([
            clamp(pos - minDelta, 0, 1 - 2 * minDelta),
            clamp(pos + minDelta, 2 * minDelta, 1),
          ]);
        }
      }
    },
    [
      isAtLeftEdge,
      isAtRightEdge,
      isInSelection,
      windowRange,
      setWindowRange,
      cursor,
      dragPoint,
    ]
  );

  const start = windowRange ? Math.min(...windowRange) : 0;
  const end = windowRange ? Math.max(...windowRange) : 0;

  return (
    <div className={styles.DataWindow} data-cursor={cursor}>
      {windowRange && (
        <div
          className={styles.window}
          style={{
            left: `${start * 100}%`,
            right: `${(1 - end) * 100}%`,
          }}
        />
      )}
      <div
        className={styles.content}
        onPointerDown={handlePointer}
        onPointerMove={handlePointer}
        onPointerUp={handlePointer}
      >
        {children}
      </div>
    </div>
  );
}
