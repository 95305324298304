import React from "react";
import classes from "./styles.module.sass";

export default function TextButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<any>) {
  return (
    <button className={classes.TextButton} type="button" {...props}>
      {children}
    </button>
  );
}
