import { clamp, mapLinear } from "lib/math";
import { useEffect, useMemo, useRef, useState } from "react";
import { createPathElement, createPathString, type Point } from "../lib/svg";
import { useProjectionGraphContext } from "../ProjectionGraph.context";
import styles from "../styles.module.sass";
import { ProjectionLabel } from "./ProjectionLabel";
import { ProjectionMarker } from "./ProjectionMarker";
import { ProjectionSvgPortal } from "./ProjectionSvgPortal";
import { ProjectionTimelineRange } from "./ProjectionTimelineRange";

export type Highlight = "none" | "normal" | "warning";
export type Variant = "solid" | "dashed";

interface ProjectionLineProps {
  points: Point[];
  highlight: Highlight;
  variant?: Variant;
  startLabel?: string;
  endLabel?: string;
}

export function ProjectionLine({
  points,
  highlight,
  startLabel,
  endLabel,
  variant = "solid",
}: ProjectionLineProps) {
  if (points.length < 2) {
    throw new Error("Line must have at least 2 points");
  }

  const pathRef = useRef<SVGPathElement | null>(null);
  const markerEndRef = useRef<HTMLDivElement | null>(null);

  const isHighlighted = highlight !== "none";

  const [showLineEndMarker, setShowLineEndMarker] = useState(false);

  const {
    X,
    Y,
    Yflip,
    Xnormalize,
    debug,
    timelineState,
    timelineProgress,
    timelineSeek,
    domain,
    range,
  } = useProjectionGraphContext();

  const firstPoint = points[0];
  const lastPoint = points[points.length - 1];

  /**
   * Calculate the point on the line at the initial timeline value (= timeline seel value)
   * This is used to display the line start label at the correct position
   *
   * Algorithm:
   * - Create a path element based on the input points mapped from domain to range units
   * - Get the point on the path at the timeline seek value
   * - Map the point back to domain units (used passing the point to the label component, which expects domain units)
   */
  const pointOnLineAtTimelineStart = useMemo(() => {
    const path = createPathElement(
      createPathString(
        points.map(({ x, y }) => ({
          x: X(x),
          y: Y(y),
        }))
      )
    );
    const seekedPointInRangeUnits = path.getPointAtLength(
      path.getTotalLength() * timelineSeek
    );

    const xScaleInvert = (value: number) =>
      mapLinear(value, range.x[0], range.x[1], domain.x[0], domain.x[1]);

    const yScaleInvert = (value: number) =>
      mapLinear(value, range.y[0], range.y[1], domain.y[0], domain.y[1]);

    const seekedPointInDomainUnits = {
      x: xScaleInvert(seekedPointInRangeUnits.x),
      y: yScaleInvert(seekedPointInRangeUnits.y),
    };

    return seekedPointInDomainUnits;
  }, [timelineSeek, points, range, domain, X, Y]);

  const d = useMemo(
    () =>
      createPathString(
        points.map(({ x, y }) => ({
          x: X(x),
          y: Yflip(y),
        }))
      ),
    [points, X, Yflip]
  );

  const pathLength = useMemo(() => {
    const path = createPathElement(d);
    return path.getTotalLength() ?? 0;
  }, [d]);

  useEffect(() => {
    const XrangeToPercent = (value: number) =>
      mapLinear(value, range.x[0], range.x[1], 0, 100);

    const YrangeToPercent = (value: number) =>
      mapLinear(value, range.y[0], range.y[1], 0, 100);

    const onTimelineProgressUpdate = (p: number) => {
      const lineStartX = Xnormalize(firstPoint.x);
      const lineEndX = Xnormalize(lastPoint.x);

      // Calculate the progress of the line relative to the overall chart
      const lineProgress = clamp(
        (p - lineStartX) / (lineEndX - lineStartX),
        0,
        1
      );

      if (!pathRef.current) {
        return;
      }

      // Animate the line drawing
      pathRef.current.style.strokeDashoffset = String(
        pathLength * (1 - lineProgress)
      );

      if (!markerEndRef.current) {
        return;
      }

      // Animate the line end marker
      try {
        const p = pathRef.current.getPointAtLength(pathLength * lineProgress);
        markerEndRef.current.style.left = `${XrangeToPercent(p.x)}%`;
        markerEndRef.current.style.top = `${YrangeToPercent(p.y)}%`;
      } catch (_) {}
    };

    onTimelineProgressUpdate(timelineProgress.get());

    return timelineProgress.on("change", onTimelineProgressUpdate);
  }, [
    timelineProgress,
    pathLength,
    firstPoint.x,
    lastPoint.x,
    showLineEndMarker,
    Xnormalize,
    isHighlighted,
    range,
  ]);

  // show line end markers once the timeline starts playing
  useEffect(() => {
    switch (timelineState) {
      case "play":
        setShowLineEndMarker(true);
        break;
      case "idle":
        setShowLineEndMarker(isHighlighted);
        break;
      default:
        break;
    }
  }, [timelineState, isHighlighted]);

  const strokeWidth = variant === "solid" ? 4 : 3;
  const strokeLinecap = variant === "dashed" ? "round" : undefined;
  const strokeDasharray = useMemo(() => {
    if (variant !== "dashed") {
      return pathLength;
    }

    // Animated dashed line algorithm based on https://www.visualcinnamon.com/2016/01/animating-dashed-line-d3/
    const dashing = "8,8";
    const dashLength = dashing
      .split(/[\s,]/)
      .map((a) => parseFloat(a) || 0)
      .reduce((a, b) => a + b);
    const dashCount = Math.ceil(pathLength / dashLength);
    const newDashes = new Array(dashCount).join(dashing + " ");

    return newDashes + " 0, " + pathLength;
  }, [variant, pathLength]);

  return (
    <>
      <ProjectionSvgPortal>
        {/* Line */}
        <path
          ref={pathRef}
          className={styles.ProjectionLine}
          data-highlight={highlight}
          d={d}
          strokeWidth={strokeWidth}
          strokeDasharray={strokeDasharray}
          strokeDashoffset={pathLength}
          strokeLinecap={strokeLinecap}
          fill="none"
        />

        {/* Line History */}
        {timelineSeek > 0 && timelineSeek < 1 && (
          <path
            className={styles.ProjectionLine}
            d={d}
            strokeWidth={strokeWidth}
            strokeDasharray={strokeDasharray}
            strokeLinecap={strokeLinecap}
            fill="none"
            clipPath="url(#projection-timeline-seek-mask)"
          />
        )}

        {debug &&
          points.map(({ x, y }, i) => (
            <circle key={i} cx={X(x)} cy={Yflip(y)} r="2" strokeWidth="4" />
          ))}
      </ProjectionSvgPortal>

      <ProjectionMarker
        markerRef={markerEndRef}
        point={pointOnLineAtTimelineStart}
        variant="secondary"
        highlight={isHighlighted ? "normal" : "none"}
        data-active={showLineEndMarker}
        style={{ zIndex: "var(--layer-lines)" }}
      />

      {startLabel && (
        <ProjectionTimelineRange to="timeline-start">
          {(isVisible) => (
            <ProjectionLabel
              point={pointOnLineAtTimelineStart}
              data-active={isVisible}
              data-strong={isHighlighted}
            >
              {startLabel}
            </ProjectionLabel>
          )}
        </ProjectionTimelineRange>
      )}

      {endLabel && (
        <ProjectionTimelineRange from={0.95}>
          {(isVisible) => (
            <ProjectionLabel
              point={lastPoint}
              data-active={isVisible && timelineState !== "idle"}
              data-strong={!!highlight && highlight !== "none"}
            >
              {endLabel}
            </ProjectionLabel>
          )}
        </ProjectionTimelineRange>
      )}
    </>
  );
}
