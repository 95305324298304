import { PulseWaveAssessmentData } from "@cur8/api-client/dist/api/cur8/generated-types/v1/Api";

export function getAverageValue(
  lveti: PulseWaveAssessmentData[] | undefined | null
) {
  if (!lveti) {
    return "";
  }
  const nonValues = lveti.find((item) => item.value === undefined);
  if (nonValues) {
    return "";
  }
  const total = lveti.reduce((sum, item) => sum + item.value!, 0);
  return (total / lveti.length).toFixed(0);
}

export function printFirstValue(
  data: PulseWaveAssessmentData[] | undefined | null
) {
  if (!data || !data[0].value) {
    return "";
  }
  return data[0].value;
}
