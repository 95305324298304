import { PropsWithChildren, ReactNode, useCallback } from "react";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import PopupDialog from "render/ui/layouts/PopupDialog";
import ActionButton from "render/ui/trigger/ActionButton";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

export interface ConfirmationModalProps {
  onClose: () => void;
  onConfirm: () => void;
  title?: string | ReactNode;
  body: string | ReactNode;
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
}

export default function ConfirmationModal({
  title,
  confirmButtonLabel,
  cancelButtonLabel,
  body,
  onClose,
  onConfirm,
}: PropsWithChildren<ConfirmationModalProps>) {
  const handleConfirm = useCallback(async () => {
    return await Promise.resolve().then(() => {
      onConfirm();
      setTimeout(onClose, 200); // close modal
    });
  }, [onConfirm, onClose]);

  const confirmHandler = useAsyncHandle(handleConfirm);

  return (
    <PopupDialog>
      <div className={styles.ConfirmationModal}>
        <div className={styles.content}>
          {title && (
            <header>
              {typeof title === "string" ? <h2>{title}</h2> : title}
            </header>
          )}
          {body && (
            <section className={styles.body}>
              {typeof body === "string" ? <p>{body}</p> : body}
            </section>
          )}
          <footer className={styles.footer}>
            <HoverTextButton type="button" onClick={onClose}>
              {cancelButtonLabel || "Cancel"}
            </HoverTextButton>

            <ActionButton
              disabled={confirmHandler.busy}
              handle={confirmHandler}
            >
              {confirmButtonLabel || "Confirm"}
            </ActionButton>
          </footer>
        </div>
      </div>
    </PopupDialog>
  );
}
