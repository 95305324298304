import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function usePatientProduct(patientId: string, productId: string) {
  const { logError } = useReporting();
  const apiClient = useAPIClient();
  const [product, setProduct] = useState<APITypesV1.Product>();

  const fetch = useCallback(async () => {
    return apiClient.billing
      .getProductForPatient(patientId, productId)
      .result.then((result) => {
        setProduct(result);
      })
      .catch(logError);
  }, [apiClient, patientId, productId, logError]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    product: useMemo(() => product, [product]),
    fetch,
  };
}
