import { Slot } from "@cur8/rich-entity";
import { toList } from "@pomle/react-flat-store";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { Metric } from "lib/metric";
import { byTimestamp } from "lib/metric/sort";
import { DateTime } from "luxon";
import { useMemo } from "react";
import AssignmentsTable from "render/fragments/assignments/AssignmentsTable/AssignmentsTable";
import PatientIdentifier from "render/fragments/patient/PatientIdentifier";
import PatientScanTable from "render/fragments/scan/PatientScanTable";
import PatientVisitTable from "render/fragments/visit/PatientVisitTable";
import { usePatient } from "render/hooks/api/usePatient";
import { usePatientImmutableScans } from "render/hooks/api/usePatientImmutableScans";
import { usePatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { useRoleBasedAccess } from "render/hooks/useRoleBasedAccess";
import { paths } from "render/routes/paths";
import PassedTime from "render/ui/format/PassedTime";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import Banner from "render/ui/presentation/Banner";
import ButtonSet from "render/ui/trigger/ButtonSet";
import DropdownMenuButton from "render/ui/trigger/DropdownMenuButton";
import DropdownItem from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import IcnCheckbox from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/icn-checkbox.svg?react";
import IdCard from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/id-card.svg?react";
import NekoLogo from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/neko-logo.svg?react";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import ScheduleFollowUpButton from "./components/ScheduleFollowUpButton";
import { usePatientAssignments } from "./hooks/usePatientAssignments";
import { usePatientUpcomingSlot } from "./hooks/usePatientUpcomingSlot";
import { usePatientVisitSummaries } from "./hooks/usePatientVisitSummaries";
import styles from "./styles.module.sass";

enum LastUpdateStatus {
  Unknown,
  Never,
}

type LastUpdate = LastUpdateStatus | DateTime;

interface UpcomingAppointmentProps {
  slot: Slot;
}

function UpcomingAppointment({ slot }: UpcomingAppointmentProps) {
  const timeZoneId = slot.room?.site?.timeZoneId;
  return (
    <div>
      <div>{slot.room?.site?.siteName}</div>
      <div>
        {slot.startTime.setZone(timeZoneId).toFormat("HH:mm")} •{" "}
        {slot.startTime.setZone(timeZoneId).toFormat("dd LLLL")}
      </div>
    </div>
  );
}

interface StatusSummaryProps {
  lastUpdate: LastUpdate;
  fallback: React.ReactNode;
}

function StatusSummary({ lastUpdate, fallback }: StatusSummaryProps) {
  if (lastUpdate === LastUpdateStatus.Unknown) {
    return null;
  }
  if (lastUpdate === LastUpdateStatus.Never) {
    return <>{fallback}</>;
  }

  return (
    <div>
      Updated <PassedTime date={lastUpdate} />
    </div>
  );
}

interface PatientDetailPageProps {
  patientId: string;
}

export default function PatientDetailPage({
  patientId,
}: PatientDetailPageProps) {
  const roleBasedAccess = useRoleBasedAccess();
  const patient = usePatient(patientId);
  const immutableScans = usePatientImmutableScans(patientId);
  const { bloodwork, risk } = usePatientMetrics(patientId);

  const { entries: visitEntries } = usePatientVisitSummaries(patientId);

  const upcomingAppointment = usePatientUpcomingSlot(patientId);
  const assignmentResult = usePatientAssignments(patientId);

  const visits = useMemo(() => toList(visitEntries), [visitEntries]);

  const lastDashboardUpdate = useMemo(() => {
    if (!immutableScans) {
      return LastUpdateStatus.Unknown;
    }

    if (immutableScans.history.length === 0) {
      return LastUpdateStatus.Never;
    }

    const immutableScanDate =
      immutableScans.history.at(0)?.at(0)?.timestamp ?? LastUpdateStatus.Never;

    return immutableScanDate;
  }, [immutableScans]);

  const lastBloodworkUpdate = useMemo(() => {
    const metrics = Object.values(bloodwork);

    if (metrics.some((metric) => metric === undefined)) {
      return LastUpdateStatus.Unknown;
    }

    if (metrics.every((metric) => metric?.length === 0)) {
      return LastUpdateStatus.Never;
    }

    return (
      (metrics as Metric[][])
        .flatMap((metric) => metric)
        .sort(byTimestamp("desc"))
        .at(0)?.measurement.timestampStart ?? LastUpdateStatus.Never
    );
  }, [bloodwork]);

  const lastRiskUpdate = useMemo(() => {
    const metrics = Object.values(risk);

    if (metrics.some((metric) => metric === undefined)) {
      return LastUpdateStatus.Unknown;
    }

    if (metrics.every((metric) => metric?.length === 0)) {
      return LastUpdateStatus.Never;
    }

    return (
      (metrics as Metric[][])
        .flatMap((metric) => metric)
        .sort(byTimestamp("desc"))
        .at(0)?.measurement.timestampStart ?? LastUpdateStatus.Never
    );
  }, [risk]);

  const nav = {
    dash: useNav(paths.patient.dashboard),
    edit: useNav(paths.patient.edit),
    createVisitSummary: useNav(paths.patient.visit.create),
    bloodwork: useNav(paths.patient.bloodworkEntry),
    riskAssessment: useNav(paths.patient.riskAssessmentEntry),
    appointment: useNav(paths.patient.appointment.view),
    createAssignment: useNav(paths.patient.assignment.create),
    encyclopedia: useNav(paths.patient.encyclopedia),
    trackedMarkings: useNav(paths.patient.trackedMarkings),
  };

  const scanHistory = useMemo(() => {
    if (immutableScans) {
      return immutableScans.history.flatMap((group) => group);
    }
  }, [immutableScans]);

  return (
    <FramedPage>
      <div className={styles.PatientDetailPage}>
        <section className={styles.summary}>
          <PageHeader
            caption={
              <PatientIdentifier patientId={patientId} patient={patient} />
            }
          >
            <ButtonSet>
              {patient && roleBasedAccess.feature.booking && (
                <ScheduleFollowUpButton
                  patient={patient}
                  upcomingAppointment={upcomingAppointment}
                />
              )}
              <DropdownMenuButton label="Manage..." header="Manage member">
                <DropdownItem onClick={nav.edit.on({ patientId })}>
                  <IdCard />
                  Edit Member
                </DropdownItem>

                <DropdownItem onClick={nav.createAssignment.on({ patientId })}>
                  <IcnCheckbox />
                  Create Assignment
                </DropdownItem>

                <DropdownItem onClick={nav.encyclopedia.on({ patientId })}>
                  <NekoLogo />
                  Data Table
                </DropdownItem>
              </DropdownMenuButton>
            </ButtonSet>
          </PageHeader>

          <div className={styles.items}>
            <PathLink
              className={styles.item}
              data-disabled={
                lastDashboardUpdate === LastUpdateStatus.Unknown ||
                lastDashboardUpdate === LastUpdateStatus.Never
              }
              to={nav.dash.to({ patientId })}
            >
              <div className={styles.primary}>Health Chart</div>
              <StatusSummary
                lastUpdate={lastDashboardUpdate}
                fallback="No examinations made"
              />
            </PathLink>

            <PathLink
              className={styles.item}
              to={nav.bloodwork.to({ patientId })}
            >
              <div className={styles.primary}>Bloodwork</div>
              <StatusSummary
                lastUpdate={lastBloodworkUpdate}
                fallback="No bloodwork added"
              />
            </PathLink>

            <PathLink
              className={styles.item}
              to={nav.riskAssessment.to({ patientId })}
            >
              <div className={styles.primary}>Risk</div>
              <StatusSummary
                lastUpdate={lastRiskUpdate}
                fallback="Not completed"
              />
            </PathLink>

            {upcomingAppointment?.slotId ? (
              <PathLink
                className={styles.item}
                to={nav.appointment.to({
                  patientId,
                  bookingSlotId: upcomingAppointment.slotId,
                })}
              >
                <div className={styles.primary}>Appointment</div>
                <UpcomingAppointment slot={upcomingAppointment} />
              </PathLink>
            ) : (
              <div className={styles.item} data-disabled={true}>
                <div className={styles.primary}>Appointment</div>
                <div>Nothing scheduled yet</div>
              </div>
            )}
          </div>
        </section>

        <section className={styles.visits}>
          <header>
            <div>
              <h2>Visits</h2>
            </div>

            <div>
              <ButtonSet>
                <HoverTextButton
                  onClick={nav.createVisitSummary.on({ patientId })}
                >
                  Create Visit Summary
                </HoverTextButton>
              </ButtonSet>
            </div>
          </header>

          {visitEntries ? (
            visitEntries.length ? (
              <PatientVisitTable visits={visits} />
            ) : (
              <Banner>
                <p>No visits</p>
              </Banner>
            )
          ) : (
            <Banner>
              <p>Looking for visits</p>
            </Banner>
          )}
        </section>

        <section className={styles.scans}>
          <header>
            <div>
              <h2>Examinations</h2>
            </div>

            <div>
              <ButtonSet>
                <HoverTextButton
                  onClick={nav.trackedMarkings.on({ patientId })}
                >
                  Tracked Markings
                </HoverTextButton>
              </ButtonSet>
            </div>
          </header>

          {scanHistory ? (
            scanHistory.length ? (
              <PatientScanTable scans={scanHistory} />
            ) : (
              <Banner>
                <p>No examinations</p>
              </Banner>
            )
          ) : (
            <Banner>
              <p>Looking for examinations</p>
            </Banner>
          )}
        </section>

        <section className={styles.assignments}>
          <header>
            <div>
              <h2>Assignments</h2>
            </div>
          </header>

          {assignmentResult?.data ? (
            !!assignmentResult.data.length ? (
              <AssignmentsTable assignments={assignmentResult.data} />
            ) : (
              <Banner>
                <p>No assignments</p>
              </Banner>
            )
          ) : (
            <Banner>
              <p>Looking for assignments</p>
            </Banner>
          )}
        </section>
      </div>
    </FramedPage>
  );
}
