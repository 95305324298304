import { Annotation } from "@cur8/rich-entity";
import { codecs, createPath } from "@pomle/paths";

const URL_MAP: Record<string, string> = {
  localhost: "derma.dev.cur8.co",
  "doctor.pr.dev.cur8.co": "derma.dev.cur8.co",
  "doctor.preprod.cur8.co": "derma.preprod.cur8.co",
  "doctor.dev.cur8.co": "derma.dev.cur8.co",
  "doctor.cur8.co": "derma.cur8.co",
};

function findDermaDomain() {
  const host = window.location.hostname;
  return URL_MAP[host] ?? URL_MAP.localhost;
}

const DERMA_APP_DOMAIN = findDermaDomain();

const addView = createPath("/add/:patientId", {
  patientId: codecs.string,
});

const lesionsView = createPath("/lesions/:patientId", {
  patientId: codecs.string,
});

function makeURL(path: string) {
  return new URL(`https://${DERMA_APP_DOMAIN}` + path);
}

export const urls = {
  addImages(patientId: string) {
    const path = lesionsView.url({ patientId });
    const url = makeURL(path);
    return url.toString();
  },

  appendImages(annotation: Annotation) {
    const path = addView.url({ patientId: annotation.patientId });
    const url = makeURL(path);
    url.searchParams.set("id", annotation.id);
    return url.toString();
  },
};
