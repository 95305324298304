import { HDL } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import MetricRange from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface HDLRangeProps {
  patient: Patient;
  metrics: Metric<"bloodwork.hdl">[] | undefined;
}

export default function HDLRange({ patient, metrics }: HDLRangeProps) {
  const riskRange = useMemo(() => {
    return HDL.rangesFor({ sex: patient.sex });
  }, [patient]);

  const ranges = useMemo(() => {
    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.map((metric) => {
      const unit = metric.unit;
      return {
        risk: riskRange.findRisk(unit),
        timestamp: metric.measurement.timestampStart,
        value: unit["mmol/L"],
      };
    });
  }, [metrics, riskRange]);

  return (
    <MetricRange
      title="HDL"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(2)}
    />
  );
}
