import { isSide } from "./guard";
import { Side } from "./types";

export class PanoramaImageURI {
  type = "panorama";
  deviceId: string;
  recordingId: string;
  side: Side;
  cameraName: string;

  static fromString(uri: string) {
    const [type, deviceId, recordingId, side, cameraName] = uri
      .split(":")
      .map(decodeURIComponent);

    if (type !== "panorama") {
      throw new Error("Not PanoramaImageURI");
    }

    if (!isSide(side)) {
      throw new Error(`Invalid value for side: ${side}`);
    }

    return new PanoramaImageURI(deviceId, recordingId, side, cameraName);
  }

  static parse(text: string) {
    const [type, deviceId, recordingId, side, cameraName] = text
      .split(":")
      .map(decodeURIComponent);

    if (type !== "panorama") {
      return null;
    }

    if (!isSide(side)) {
      return null;
    }

    return new PanoramaImageURI(deviceId, recordingId, side, cameraName);
  }

  constructor(
    deviceId: string,
    recordingId: string,
    side: Side,
    cameraName: string
  ) {
    this.type = "panorama";
    this.deviceId = deviceId;
    this.recordingId = recordingId;
    this.side = side;
    this.cameraName = cameraName;
  }

  toString(): string {
    return [
      "panorama",
      this.deviceId,
      this.recordingId,
      this.side,
      this.cameraName,
    ]
      .map(encodeURIComponent)
      .join(":");
  }
}

export class RGBInoImageURI {
  type = "rgbino";
  deviceId: string;
  recordingId: string;
  side: Side;
  module: string;
  pos: { x: number; y: number };

  static fromString(uri: string) {
    const inst = RGBInoImageURI.parse(uri);

    if (!inst) {
      throw new Error(`Invalid URI: ${uri}`);
    }

    return inst;
  }

  static parse(text: string) {
    const [type, deviceId, recordingId, side, module, x, y] = text
      .split(":")
      .map(decodeURIComponent);

    if (type !== "rgbino") {
      return null;
    }

    if (!isSide(side)) {
      return null;
    }

    return new RGBInoImageURI(deviceId, recordingId, side, module, {
      x: parseFloat(x),
      y: parseFloat(y),
    });
  }

  constructor(
    deviceId: string,
    recordingId: string,
    side: Side,
    module: string,
    pos: { x: number; y: number }
  ) {
    this.type = "rgbino";
    this.deviceId = deviceId;
    this.recordingId = recordingId;
    this.side = side;
    this.module = module;
    this.pos = pos;
  }

  toString(): string {
    return [
      "rgbino",
      this.deviceId,
      this.recordingId,
      this.side,
      this.module,
      this.pos.x,
      this.pos.y,
    ]
      .map(encodeURIComponent)
      .join(":");
  }
}
