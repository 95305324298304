import FilledDotIcon from "./assets/filled-dot.svg?react";

import styles from "./styles.module.sass";

export default function PositionBar({ value }: { value: number }) {
  return (
    <div className={styles.PositionBar}>
      <div
        data-value={value}
        className={styles.indicator}
        style={{ left: `${value * 100}%` }}
      >
        <FilledDotIcon className={styles.icon} />
      </div>
    </div>
  );
}
