import { useMemo } from "react";
import { APITypesV1 } from "@cur8/api-client";
import { Annotation } from "@cur8/rich-entity";
import { useAnnotationQuery } from "render/hooks/api/useAnnotationQuery";

export function useRelatedAnnotations(sourceAnnotation: Annotation) {
  const namespace = useMemo(() => {
    return [
      "related-annotations",
      "patient",
      sourceAnnotation.patientId,
      "annotation",
      sourceAnnotation.id,
    ].join("/");
  }, [sourceAnnotation]);

  const patientId = sourceAnnotation.patientId;
  const targetURI = sourceAnnotation.uri.toString();

  const options = useMemo((): APITypesV1.AnnotationListByPatientParams => {
    return {
      patientId,
      targetUri: targetURI,
      order: APITypesV1.SortOrder.Desc,
      pageSize: 5,
    };
  }, [patientId, targetURI]);

  return useAnnotationQuery(options, namespace);
}
