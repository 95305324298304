import { LDL, Risk } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { Score2 } from "render/hooks/api/metrics/useScore2";
import { useScore2Status } from "render/hooks/api/metrics/useScore2Status";
import { useAge } from "render/hooks/patient/useAge";
import MetricRange from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface LDLRangeProps {
  patient: Patient;
  metrics: Metric<"bloodwork.ldl">[] | undefined;
  score2: Score2;
}

export default function LDLRange({ patient, metrics, score2 }: LDLRangeProps) {
  const age = useAge(patient);

  const riskStatus = useScore2Status(patient, score2);

  const riskRange = useMemo(() => {
    if (!riskStatus) {
      return;
    }

    return LDL.rangesFor({
      age,
      isScore2HighRisk: riskStatus.status === Risk.HighRisk,
    });
  }, [age, riskStatus]);

  const ranges = useMemo(() => {
    if (!riskRange) {
      return [];
    }

    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  const entries = useMemo(() => {
    if (!metrics || !riskRange) {
      return [];
    }

    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [riskRange, metrics]);

  return (
    <MetricRange
      title="LDL"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(2)}
    />
  );
}
