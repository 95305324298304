import { Metric } from "lib/metric";
import { calculateTBI } from "lib/scores/tbi";
import { useMemo } from "react";

export type TBIScore = {
  score: number;
};

type TBIScoreMetrics = {
  brachialPressure: {
    left: Metric<"cardio.brachial_pressure.left">[] | undefined;
    right: Metric<"cardio.brachial_pressure.right">[] | undefined;
  };
  toePressure: {
    left: Metric<"cardio.toe_pressure.left">[] | undefined;
    right: Metric<"cardio.toe_pressure.right">[] | undefined;
  };
};

export function useTBIScore({
  brachialPressure: B,
  toePressure: T,
}: TBIScoreMetrics) {
  return useMemo(() => {
    if (!B.left || !B.right || !T.left || !T.right) {
      return;
    }

    const score = calculateTBI(
      { left: B.left, right: B.right },
      { left: T.left, right: T.right }
    );

    return {
      score: score ?? NaN,
    };
  }, [B, T]);
}
