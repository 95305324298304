import { ImmutableScan } from "@cur8/rich-entity";
import AssessmentHandler from "render/pages/AssessmentPage/components/AssessmentHandler";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { Property } from "render/pages/TissuePages/lib/types";
import {
  propertiesPAD,
  useChromophoreToRatio,
} from "../../hooks/useChromophoreToRatio";
import RegionTracker from "../RegionTracker";
import styles from "./styles.module.sass";

interface SideboxProps {
  indexRemap: number[];
  onPropertySelect: (property: Property) => void;
  onRoiSelect: (roi: string) => void;
  pixelsPerCm?: number;
  scan: ImmutableScan;
  selectedRoi?: string;
  property: Property;
  timestamps: number[];
}

export default function Sidebox({
  indexRemap,
  onPropertySelect,
  onRoiSelect,
  pixelsPerCm,
  scan,
  selectedRoi,
  property,
  timestamps,
}: SideboxProps) {
  const { createAssessment } = useAssessmentContext();
  const { ratioBlood, ratioOxygen } = useChromophoreToRatio(
    scan,
    createAssessment,
    indexRemap,
    timestamps
  );

  return (
    <div className={styles.Sidebox}>
      <div className={styles.PropertyRatio}>
        <h3>Ratios</h3>
        {ratioBlood ? (
          <button
            onClick={() => onPropertySelect(Property.t1BloodVolume)}
            data-selected={property === Property.t1BloodVolume}
          >
            <label>{propertiesPAD.get(Property.t1BloodVolume)}</label>
            <div className={styles.numerals}>{ratioBlood.toFixed(2)}</div>
          </button>
        ) : (
          <h3 className={styles.loading}>Loading blood concentration</h3>
        )}
        {ratioOxygen ? (
          <button
            onClick={() => onPropertySelect(Property.t1Oxygenation)}
            data-selected={property === Property.t1Oxygenation}
          >
            <label>{propertiesPAD.get(Property.t1Oxygenation)}</label>
            <div className={styles.numerals}>{ratioOxygen.toFixed(2)}</div>
          </button>
        ) : (
          <h3 className={styles.loading}>Loading oxygenation</h3>
        )}
      </div>

      <RegionTracker
        assessment={createAssessment}
        onRoiSelect={onRoiSelect}
        pixelsPerCm={pixelsPerCm}
        selectedRoi={selectedRoi}
      />
      <div className={styles.assessmentWrap}>
        <AssessmentHandler />
      </div>
    </div>
  );
}
