import React from "react";
import { PathLink } from "@pomle/react-router-paths";
import styles from "./styles.module.sass";

interface MenuButtonProps extends React.ButtonHTMLAttributes<any> {
  to: string;
}

export default function MenuButtonLink({
  children,
  to,
  ...props
}: MenuButtonProps) {
  return (
    <PathLink to={to} className={styles.MenuButton}>
      <div className={styles.icon}>{children}</div>
      <label>{props.title}</label>
    </PathLink>
  );
}
