import { clamp } from "lib/math";
import React, { useCallback, useMemo, useState } from "react";
import { useRangeContext } from "../../../context/RangeContext";
import VerticalLine from "../VerticalLine/VerticalLine";
import { MINIMUM_SELECTION } from "../constants";
import styles from "./style.module.sass";

interface RulerProps {
  children: React.ReactNode;
  signalLengthMs: number;
}

export default function Ruler({ children, signalLengthMs }: RulerProps) {
  const { selectionRange, setSelectionRange, windowRange, setWindowRange } =
    useRangeContext();
  const [indicatorPos, setIndicatorPos] = useState<number>();

  const viewport = useMemo(() => {
    return {
      start: Math.min(...windowRange),
      end: Math.max(...windowRange),
    };
  }, [windowRange]);

  const selection = useMemo(() => {
    const r = selectionRange ? selectionRange : [0, 0];
    return {
      start: Math.min(...r),
      end: Math.max(...r),
    };
  }, [selectionRange]);

  const handlePointer = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      if (event.button === 2) {
        event.preventDefault();
        if (event.type === "pointerdown") {
          // Right click to zoom in to selection
          const delta = viewport.end - viewport.start;
          const start = viewport.start + selection.start * delta;
          const end = viewport.start + selection.end * delta;
          if (end - start > MINIMUM_SELECTION) {
            setWindowRange([start, end]);
          }
        }
        return;
      }

      const bounds = event.currentTarget.getBoundingClientRect();
      const pos = clamp((event.clientX - bounds.left) / bounds.width, 0, 1);

      if (event.type === "pointerdown") {
        setSelectionRange([pos, pos]);
        setIndicatorPos(undefined);
      } else if (event.type === "pointermove" && !!event.buttons) {
        // dragging
        setSelectionRange((prev) => {
          return [prev ? prev[0] : pos, pos];
        });
      } else if (event.type === "pointermove") {
        // just moving around
        setIndicatorPos(pos);
      } else if (event.type === "pointerup") {
        setSelectionRange((prev) => {
          return [prev ? prev[0] : pos, pos];
        });
        setIndicatorPos(pos);
      }
    },
    [
      viewport.end,
      viewport.start,
      selection.start,
      selection.end,
      setWindowRange,
      setSelectionRange,
    ]
  );

  return (
    <div className={styles.Ruler}>
      <div
        className={styles.content}
        onPointerDown={handlePointer}
        onPointerMove={handlePointer}
        onPointerUp={handlePointer}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        onPointerLeave={() => setIndicatorPos(undefined)}
      >
        <div data-testid="rulerChildren">
          {children}
          <VerticalLine position={indicatorPos} />
        </div>
      </div>

      {selection.end - selection.start > 0 && (
        <div
          className={styles.selection}
          style={{
            left: `${selection.start * 100}%`,
            right: `${(1 - selection.end) * 100}%`,
          }}
        />
      )}
    </div>
  );
}
