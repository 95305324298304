export function lerp(from: number, to: number, p: number) {
  return (to - from) * p + from;
}

export function norm(min: number, max: number, p: number) {
  return (p - min) / (max - min);
}

export function sawtooth(value: number, len: number) {
  return (value % len) / len;
}
