import { Assignment, fromAPI } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

export function usePatientAssignments(patientId: string) {
  const api = useAPIClient();

  const [result, setResult] = useState<AsyncResult<Assignment[]>>();

  useEffect(() => {
    const request = api.assignment.queryAssignmentsFor({ patientId });

    request.result
      .then((data) => {
        setResult({ data: data.items.map(fromAPI.toAssignment) });
      })
      .catch(silenceAbort);

    return () => {
      request.abandon();
      setResult(undefined);
    };
  }, [api, patientId]);

  return result;
}
