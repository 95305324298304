import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import styles from "./styles.module.sass";

export default function StudySymbol({ forceShow }: { forceShow?: boolean }) {
  const { isCurrentVisitInSweden } = usePatientData();

  return isCurrentVisitInSweden || forceShow ? (
    <svg
      width="6"
      height="6"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.NekoSymbol}
    >
      <rect width="6" height="6" fill="#9EBCC4" />
      <path
        d="M1.10801 1.505L1.08301 1.529V3.00501H1.60001V2.293H1.65801L2.16201 3.00501V4.482L2.18601 4.505H2.65401L2.67801 4.482V3.00501L1.61701 1.505H1.10801ZM4.31701 3.717H4.25901L3.75501 3.00501V1.529L3.73101 1.505H3.26301L3.23901 1.529V3.00501L4.30001 4.505H4.80901L4.83301 4.482V3.00501H4.31701V3.717Z"
        fill="#FBFBFA"
      />
    </svg>
  ) : null;
}
