import { SkinBookTracking } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import DayDate from "render/ui/format/DayDate";
import ArrowIcon from "render/ui/symbol/ArrowIcon";
import PaginatedTable from "../PaginatedTable";
import { PaginatedTableProps } from "../PaginatedTable/PaginatedTable";
import SkinBookStatus from "../SkinBookStatus";
import styles from "./styles.module.sass";

type AtriumTableProps = Pick<
  PaginatedTableProps<SkinBookTracking>,
  "data" | "fetchStatus" | "hasMoreData" | "onLoadMore"
> & {
  onDataRefresh: () => void;
};

export default function AtriumTable({
  data,
  fetchStatus,
  hasMoreData,
  onLoadMore,
}: AtriumTableProps) {
  const nav = {
    markings: useNav(paths.patient.trackedMarkings),
  };

  return (
    <div className={styles.AtriumTable}>
      <PaginatedTable
        data={data}
        fetchStatus={fetchStatus}
        hasMoreData={hasMoreData}
        onLoadMore={onLoadMore}
        renderHeader={() => (
          <tr>
            <th>Member</th>
            <th>Member ID</th>
            <th>Phone no</th>
            <th>Scan</th>
            <th className={styles.statusCol}>Status</th>
            <th>Action</th>
          </tr>
        )}
        renderRow={(item) => (
          <tr
            key={`${item.scanId}-${item.patientId}-${item.audit.created?.timeStamp}`}
            data-status={item.skinBookStatus}
          >
            <td>{item.patientDisplayName}</td>
            <td>{item.patientId}</td>
            <td>{item.patientPhoneNumber}</td>
            <td>
              {item.scanCreatedAt ? (
                <DayDate date={item.scanCreatedAt} />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              <SkinBookStatus item={item} />
            </td>
            <td className={styles.actionCol}>
              <a
                href={nav.markings.to({ patientId: item.patientId })}
                target="_blank"
                rel="noreferrer"
              >
                Markings
                <ArrowIcon direction="right" />
              </a>
            </td>
          </tr>
        )}
      />
    </div>
  );
}
