import { ImmutableScan } from "@cur8/rich-entity";
import { useEffect, useState } from "react";
import { useTissueAnnotationContext } from "../context/TissueAnnotationContext";
import { LCJSPoint, Property } from "../lib/types";
import { useChromophoreTimeseriesMulti } from "./useChromophoreTimeseriesMulti";

/**
 * Function to calculate the mean y-value excluding points with y = 0
 */
function seriesMean(series: LCJSPoint[]): number {
  if (series.length <= 0) {
    throw new Error("No points in series");
  }
  const nonZeroPoints = series.filter((point) => point.y !== 0);
  if (nonZeroPoints.length === 0) {
    throw new Error("No Y-values");
  }

  const sumY = nonZeroPoints.reduce((acc, point) => acc + point.y, 0);
  return sumY / nonZeroPoints.length;
}

export const propertiesPAD = new Map<Property, string>([
  [Property.t1BloodVolume, "Shallow Blood Concentration"],
  [Property.t1Oxygenation, "Shallow Oxygenation"],
]);

export function usePADRatio(
  indexRemap: number[],
  scan: ImmutableScan,
  timestamps: number[]
) {
  const { filtered } = useTissueAnnotationContext();
  const seriesBlood = useChromophoreTimeseriesMulti(
    scan,
    filtered.roi,
    indexRemap,
    Property.t1BloodVolume,
    timestamps
  );
  const seriesOxygen = useChromophoreTimeseriesMulti(
    scan,
    filtered.roi,
    indexRemap,
    Property.t1Oxygenation,
    timestamps
  );
  const [ratioBlood, setRatioBlood] = useState<number>(0);
  const [ratioOxygen, setRatioOxygen] = useState<number>(0);

  useEffect(() => {
    if (!seriesBlood) {
      return;
    }
    const forearm = seriesBlood.find((ser) => ser.label === "forearm");
    const hypothenar = seriesBlood.find((ser) => ser.label === "hypothenar");
    if (forearm && hypothenar) {
      try {
        const meanForearm = seriesMean(forearm.series);
        const meanHypothenar = seriesMean(hypothenar.series);
        setRatioBlood(meanHypothenar / meanForearm);
      } catch (err) {
        setRatioBlood(0);
        console.error(err);
      }
    }
  }, [seriesBlood]);

  useEffect(() => {
    if (!seriesOxygen) {
      return;
    }
    const forearm = seriesOxygen.find((ser) => ser.label === "forearm");
    const hypothenar = seriesOxygen.find((ser) => ser.label === "hypothenar");
    if (forearm && hypothenar) {
      try {
        const meanForearm = seriesMean(forearm.series);
        const meanHypothenar = seriesMean(hypothenar.series);
        setRatioOxygen(meanHypothenar / meanForearm);
      } catch (err) {
        setRatioOxygen(0);
        console.error(err);
      }
    }
  }, [seriesOxygen]);

  return {
    ratioBlood,
    ratioOxygen,
  };
}
