import { ImmutableScan } from "@cur8/rich-entity";
import { normalize } from "lib/normalize";
import { useMemo } from "react";
import { usePlotBlob } from "render/hooks/api/scan/cardio/usePlotBlob";

export function useNormalizedSignal(
  scan: ImmutableScan | undefined,
  resultName: string
) {
  const result = usePlotBlob(scan, resultName);
  const lengthMs = result ? result.signal_length_sec * 1000 : NaN;

  const normalized = useMemo(() => {
    if (!result) {
      return [];
    }

    const signal = result.signal;

    return signal.map(normalize(0, 1)).map((v) => {
      return 1 + -v;
    });
  }, [result]);

  return {
    normalized,
    lengthMs,
  };
}

export type NormalizedSignal = ReturnType<typeof useNormalizedSignal>;
