import { createMetric } from "lib/metric";
import { FractureMetrics } from "./hooks/useFractureMetrics";

export type Entries = {
  fraxScore: string;
};

export function toEntries(metrics: FractureMetrics): Entries {
  return {
    fraxScore: metrics.fraxScore?.unit.percent.toString() ?? "",
  };
}

export function toMetrics(entries: Entries) {
  const metrics: FractureMetrics = {};

  {
    const value = parseFloat(entries.fraxScore);
    if (isFinite(value)) {
      metrics.fraxScore = createMetric("risk_assessment.frax_score", {
        percent: value,
      });
    }
  }

  return metrics;
}
