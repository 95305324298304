import { normalizeSize } from "lib/api/image";
import { OutputImageSize, SourceImageCrop } from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "./useBlobImage";
import { useReporting } from "./useReporting";

export function usePanorama(
  panoramaURI: PanoramaImageURI,
  size?: OutputImageSize,
  crop?: SourceImageCrop
) {
  const { handleError } = useReporting();

  const api = useAPIClient().transcode;

  const [blob, setBlob] = useState<Blob>();

  const uri = panoramaURI.toString();

  useEffect(() => {
    const effectiveSize = normalizeSize(size);

    const panoramaURI = PanoramaImageURI.fromString(uri);
    const request = api.fetchPanorama(panoramaURI, crop, effectiveSize);

    request.result
      .then((res) => {
        setBlob(res.blob);
      })
      .catch(silenceAbort)
      .catch((error) => {
        console.warn(`Panorama image ${uri} error`, { error });
      });

    return () => {
      setBlob(undefined);
      request.abandon();
    };
  }, [api, uri, crop, size, handleError]);

  return useBlobImage(blob);
}
