import { easeOutCubic } from "lib/ease";
import { hasLesionLink, hasScan } from "lib/lesion";
import { clamp } from "lib/math";
import { useCallback, useMemo, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import RampUpNumber from "render/ui/format/RampUpNumber";
import { Typography } from "render/ui/presentation/Typography";
import ArrowIcon from "./assets/arrow.svg?react";
import PaginatedImagePool from "./components/PaginatedImagePool";
import styles from "./styles.module.sass";

export default function DetectedSection({ active }: { active: boolean }) {
  const { ui } = useDashboardContext();

  const {
    skin: { lesions },
    scans,
  } = usePatientData();

  const [page, setPage] = useState({ index: 0, count: NaN, size: NaN });

  const stepPage = useCallback((step: number) => {
    setPage((page) => {
      return {
        ...page,
        index: clamp(page.index + step, 0, page.count - 1),
      };
    });
  }, []);

  const latestSkinScan = scans?.skin.at(0);

  const latestLesions = useMemo(() => {
    if (!lesions || !latestSkinScan) {
      return;
    }

    const isLatest = hasScan(latestSkinScan);

    return lesions.filter((lesion) => {
      return isLatest(lesion) && hasLesionLink(lesion);
    });
  }, [latestSkinScan, lesions]);

  const currentPosition = useMemo(() => {
    if (!Number.isFinite(page.size)) {
      return 0;
    }

    return Math.min((page.index + 1) * page.size, latestLesions?.length ?? NaN);
  }, [latestLesions, page]);

  const showNav = currentPosition > 0;

  return (
    <div className={styles.DetectedSection}>
      <nav className={styles.navigation}>
        <div className={styles.label}>
          <Typography variant="title-s">All markings</Typography>
          <span className={styles.position} data-active={showNav}>
            <Typography variant="label-m" as="span">
              <span>[</span>
              <RampUpNumber
                value={currentPosition}
                duration={2}
                precision={0}
                ease={easeOutCubic}
              />
              &nbsp;of&nbsp;
              {latestLesions?.length}
              <span>]</span>
            </Typography>
          </span>
        </div>

        <div className={styles.actions} data-active={showNav}>
          <button
            className={styles.button}
            disabled={page.index === 0}
            onClick={() => stepPage(-1)}
          >
            <ArrowIcon className={styles.arrowPrev} />
          </button>

          <div>
            <Typography variant="label-m">
              {(page.index + 1).toString().padStart(2, "0")} /{" "}
              {page.count.toString().padStart(2, "0")}
            </Typography>
          </div>

          <button
            className={styles.button}
            disabled={page.index === page.count - 1}
            onClick={() => stepPage(1)}
          >
            <ArrowIcon />
          </button>
        </div>
      </nav>

      <div className={styles.container}>
        {latestLesions && (
          <PaginatedImagePool
            page={page}
            onChange={setPage}
            active={active && ui.layer === Layer.LesionLibrary}
            lesions={latestLesions}
          />
        )}
      </div>
    </div>
  );
}
