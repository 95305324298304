/**
 * Given an age bin string, return the age bin object
 * @param ageBin (e.g. '30-34')
 * @param separator (e.g. '-') the character that separates the age bin values
 * @returns { from: number, to: number }
 */
export function getAgeBinFromString(
  ageBin: string,
  separator = "-"
): { from: number; to: number } {
  const [from, to] = ageBin.split(separator).map(parseFloat);
  return { from, to };
}
