import { useMemo } from "react";
import * as THREE from "three";

export function useScene() {
  const scene = useMemo(() => {
    const scene = new THREE.Scene();

    return scene;
  }, []);

  return scene;
}
