import { useMemo } from "react";
import { useSchedule } from "render/pages/SchedulePage/context/ScheduleContext";

export enum ViewRangeMode {
  Daily = "daily",
  Weekly = "weekly",
}
export default function useCalendarState() {
  const { state, siteId } = useSchedule();

  const calendarInteractionMode = useMemo(() => {
    return state.mode;
  }, [state.mode]);

  const bulkSelectedSlots = useMemo(() => {
    return state.selectedSlots;
  }, [state.selectedSlots]);

  const selectedPeriod = useMemo(() => {
    return state.selectedPeriod;
  }, [state.selectedPeriod]);

  const viewRange = useMemo(() => {
    const dayCount = selectedPeriod.count("day");
    return dayCount === 7 ? ViewRangeMode.Weekly : ViewRangeMode.Daily;
  }, [selectedPeriod]);

  const areAppointmentsCensored = useMemo(() => {
    return state.censorAppointmens;
  }, [state.censorAppointmens]);

  return {
    siteId,
    selectedPeriod,
    calendarInteractionMode,
    bulkSelectedSlots,
    viewRange,
    areAppointmentsCensored,
  };
}
