import { Typography } from "../Typography";
import TargetIcon from "./assets/target.svg?react";
import styles from "./styles.module.sass";

interface FloatingValueTargetProps {
  label: React.ReactNode;
  value: React.ReactNode;
  color?: string;
  align?: "left" | "right";
  width?: number;
  jut?: number;
}

export default function FloatingValueTarget({
  label,
  value,
  color = "#0274A2",
  align = "left",
  width = 170,
  jut = 75,
}: FloatingValueTargetProps) {
  return (
    <div
      className={styles.FloatingValueTarget}
      style={{ color }}
      data-align={align}
    >
      <div className={styles.target}>
        <TargetIcon fill={color} width={36} height={36} />

        <div className={styles.line} style={{ width }}>
          <svg
            viewBox="0 0 100 36"
            width="100%"
            height="36"
            preserveAspectRatio="none"
          >
            <g stroke={color} strokeWidth={1}>
              <line x1="0" y1="18" x2="100" y2="18" />
              <line x1={jut} x2={jut} y1="18" y2="22" />
            </g>
          </svg>

          <div className={styles.label}>
            <Typography variant="label-m">{label}</Typography>
          </div>

          <div className={styles.value}>{value}</div>
        </div>
      </div>
    </div>
  );
}
