import * as THREE from "three";

const top = [9003];

const leftEye = [9440];
const rightEye = [10054];

const leftIndexFinger = [4933];
const leftHand = [
  4595, 4596, 4597, 4598, 4599, 4600, 4601, 4602, 4603, 4604, 4605, 4606, 4607,
  4608, 4609, 4610, 4611, 4612, 4613, 4614, 4615, 4616, 4617, 4618, 4619, 4620,
  4621, 4622, 4623, 4624, 4625, 4626, 4629, 4630, 4631, 4632, 4633, 4634, 4635,
  4636, 4637, 4638, 4639, 4640, 4641, 4642, 4643, 4644, 4645, 4646, 4647, 4648,
  4649, 4650, 4651, 4652, 4653, 4654, 4655, 4656, 4657, 4658, 4659, 4661, 4662,
  4663, 4664, 4666, 4667, 4668, 4673, 4674, 4675, 4676, 4677, 4678, 4679, 4680,
  4681, 4682, 4683, 4684, 4685, 4686, 4687, 4688, 4689, 4690, 4691, 4692, 4693,
  4694, 4696, 4697, 4698, 4699, 4700, 4701, 4702, 4703, 4704, 4705, 4706, 4707,
  4708, 4709, 4710, 4711, 4712, 4713, 4715, 4716, 4717, 4719, 4720, 4721, 4722,
  4723, 4724, 4725, 4726, 4727, 4728, 4729, 4730, 4731, 4732, 4733, 4734, 4735,
  4736, 4738, 4739, 4740, 4743, 4744, 4747, 4748, 4749, 4750, 4751, 4752, 4755,
  4757, 4758, 4759, 4760, 4761, 4762, 4763, 4764, 4765, 4766, 4767, 4768, 4769,
  4770, 4771, 4772, 4773, 4774, 4776, 4778, 4779, 4780, 4781, 4782, 4783, 4784,
  4785, 4786, 4787, 4788, 4789, 4790, 4791, 4792, 4793, 4794, 4795, 4796, 4797,
  4798, 4799, 4800, 4801, 4806, 4807, 4809, 4810, 4811, 4812, 4813, 4814, 4815,
  4816, 4817, 4818, 4819, 4820, 4821, 4822, 4823, 4824, 4825, 4826, 4827, 4828,
  4829, 4830, 4831, 4832, 4833, 4834, 4835, 4836, 4837, 4838, 4839, 4840, 4841,
  4842, 4843, 4844, 4845, 4846, 4847, 4848, 4849, 4850, 4851, 4852, 4853, 4854,
  4855, 4859, 4860, 4861, 4862, 4863, 4864, 4865, 4866, 4867, 4868, 4869, 4870,
  4871, 4872, 4873, 4874, 4875, 4876, 4877, 4878, 4879, 4880, 4881, 4883, 4884,
  4886, 4887, 4888, 4889, 4890, 4891, 4893, 4894, 4895, 4896, 4897, 4898, 4899,
  4901, 4902, 4904, 4905, 4906, 4907, 4909, 4910, 4911, 4912, 4913, 4914, 4915,
  4916, 4917, 4919, 4920, 4921, 4922, 4923, 4924, 4925, 4926, 4928, 4931, 4932,
  4933, 4934, 4935, 4936, 4937, 4938, 4939, 4940, 4941, 4942, 4943, 4944, 4945,
  4946, 4947, 4948, 4951, 4955, 4956, 4957, 4959, 4960, 4961, 4962, 4964, 4966,
  4968, 4970, 4972, 4974, 4975, 4976, 4977, 4978, 4979, 4980, 4981, 4983, 4986,
  4987, 4988, 4990, 4991, 4993, 4994, 4995, 4996, 4997, 4998, 5000, 5001, 5002,
  5004, 5005, 5007, 5008, 5009, 5010, 5011, 5013, 5014, 5015, 5016, 5020, 5022,
  5023, 5024, 5025, 5027, 5030, 5031, 5032, 5033, 5034, 5035, 5036, 5037, 5038,
  5039, 5040, 5043, 5044, 5045, 5048, 5049, 5050, 5052, 5053, 5056, 5057, 5058,
  5059, 5060, 5062, 5065, 5067, 5075, 5079, 5080, 5082, 5083, 5084, 5085, 5087,
  5088, 5090, 5091, 5092, 5093, 5094, 5095, 5096, 5097, 5098, 5099, 5100, 5101,
  5102, 5104, 5105, 5106, 5108, 5109, 5110, 5111, 5112, 5113, 5114, 5116, 5117,
  5118, 5119, 5120, 5121, 5122, 5124, 5125, 5127, 5129, 5134, 5135, 5136, 5137,
  5138, 5140, 5145, 5146, 5148, 5151, 5152, 5154, 5159, 5160, 5161, 5163, 5164,
  5168, 5172, 5173, 5174, 5175, 5178, 5181, 5188, 5197, 5198, 5200, 5201, 5203,
  5205, 5206, 5207, 5208, 5210, 5211, 5213, 5224, 5225, 5228, 5231, 5232, 5238,
  5239, 5240, 5242, 5243, 5245, 5246, 5247, 5248, 5250, 5251, 5252, 5253, 5256,
  5276, 5279, 5280, 5281, 5282, 5283, 5284, 5285, 5295, 5296, 5297, 5298, 5299,
  5302, 5303, 5305, 5309, 5311, 5312, 5313, 5314, 5315, 5316, 5317, 5318, 5319,
  5320, 5321, 5322, 5323, 5324, 5325, 5326, 5327, 5328, 5329, 5331, 5332, 5333,
  5334, 5335, 5336, 5337, 5338, 5339, 5340, 5341, 5342, 5343, 5344, 5345, 5346,
  5347, 5348, 5351, 5352, 5353, 5354, 5356, 5357, 5358, 5359, 5360, 5361, 5362,
  5363, 5364, 5365, 5366, 5367, 5368, 5369, 5370, 5371, 5372, 5373, 5374, 5375,
  5376, 5377, 5378, 5379, 5381, 5382, 5384, 5385, 5389, 5390, 5391, 5392, 5393,
  5394,
];
const leftShoulder = [
  3233, 3234, 3264, 3266, 3267, 3338, 3339, 3341, 3413, 3414, 3875, 3877, 3878,
  3880, 3881, 3882, 3883, 3930, 3931, 3932, 3935, 3953, 3954, 3955, 3973, 3974,
  3987, 3989, 3990, 4030, 4047, 4073, 4074, 4076, 4077, 4078, 4079, 4141, 4142,
  4143, 4439, 4440, 4441, 4442, 4443, 4444, 4445, 4446, 4448, 4450, 4451, 4460,
  4461, 4462, 4467, 4468, 4469, 4470, 4471, 4472, 4473, 4474, 4475, 4478, 4479,
  4480, 4481, 4489, 4490, 4491, 4492, 4493, 4500, 4502, 4503, 4504, 4505, 4506,
  4507, 4508, 4509, 4511, 4512, 4514, 4515, 4516, 4517, 5465, 5466, 5467, 5468,
  5469, 5470, 5543, 5544, 5545, 5587, 5588, 5589, 5590, 5591, 5592, 5595, 5597,
  5602, 5606, 5607, 5608, 5609, 5610, 5624, 5626, 5627,
];

const leftUpperArm = [4139];

const leftToe = [5769, 5770, 5771, 5772, 5786, 5787, 5789, 5790, 5847, 5849];

const leftAnkle = [
  5752, 5753, 5754, 5755, 5756, 5757, 5758, 5760, 5761, 5762, 5763, 5764, 5878,
  5879, 5880, 5881, 5882, 5883, 5884, 5885, 5886, 5887, 5888, 5889, 5918, 5919,
  5922, 5926, 8839, 8840, 8841, 8850, 8851, 8852, 8853, 8854, 8859, 8860, 8861,
  8875, 8876, 8877, 8878, 8892, 8893, 8894, 8895, 8896, 8935,
];
const rightAnkle = [
  8446, 8447, 8448, 8449, 8450, 8451, 8452, 8454, 8455, 8456, 8457, 8458, 8572,
  8573, 8574, 8575, 8576, 8577, 8578, 8579, 8580, 8581, 8582, 8583, 8612, 8613,
  8614, 8618, 8627, 8628, 8629, 8638, 8639, 8640, 8641, 8642, 8647, 8648, 8649,
  8663, 8664, 8665, 8666, 8680, 8681, 8682, 8683, 8684, 8717,
];

const rightHand = [
  7331, 7332, 7333, 7334, 7335, 7336, 7337, 7338, 7339, 7340, 7341, 7342, 7343,
  7344, 7345, 7346, 7347, 7348, 7349, 7350, 7351, 7352, 7353, 7354, 7355, 7356,
  7357, 7358, 7359, 7360, 7361, 7362, 7363, 7365, 7367, 7368, 7369, 7370, 7371,
  7372, 7373, 7374, 7375, 7376, 7377, 7378, 7379, 7380, 7381, 7382, 7383, 7384,
  7385, 7386, 7387, 7388, 7389, 7390, 7391, 7392, 7393, 7394, 7396, 7399, 7400,
  7401, 7402, 7403, 7405, 7410, 7411, 7412, 7413, 7414, 7415, 7416, 7417, 7418,
  7419, 7420, 7421, 7422, 7423, 7425, 7426, 7427, 7428, 7429, 7430, 7433, 7434,
  7435, 7436, 7437, 7438, 7439, 7440, 7441, 7442, 7443, 7444, 7445, 7446, 7447,
  7448, 7449, 7450, 7459, 7463, 7464, 7465, 7466, 7467, 7468, 7469, 7470, 7471,
  7472, 7473, 7474, 7475, 7476, 7477, 7478, 7479, 7480, 7482, 7483, 7484, 7485,
  7486, 7488, 7489, 7490, 7491, 7492, 7493, 7494, 7495, 7496, 7499, 7500, 7501,
  7502, 7503, 7504, 7505, 7506, 7507, 7508, 7509, 7510, 7511, 7512, 7513, 7514,
  7515, 7516, 7517, 7518, 7519, 7520, 7521, 7522, 7523, 7524, 7525, 7526, 7527,
  7528, 7529, 7530, 7531, 7532, 7533, 7534, 7535, 7536, 7537, 7538, 7539, 7541,
  7542, 7543, 7544, 7545, 7546, 7547, 7548, 7549, 7550, 7551, 7552, 7553, 7554,
  7555, 7560, 7561, 7562, 7563, 7564, 7565, 7566, 7567, 7568, 7569, 7570, 7571,
  7572, 7573, 7574, 7575, 7576, 7577, 7578, 7579, 7581, 7582, 7583, 7585, 7586,
  7587, 7588, 7589, 7590, 7595, 7596, 7597, 7598, 7599, 7600, 7601, 7602, 7603,
  7604, 7605, 7606, 7607, 7608, 7609, 7610, 7611, 7612, 7613, 7614, 7615, 7616,
  7617, 7618, 7620, 7622, 7623, 7624, 7625, 7626, 7627, 7628, 7630, 7631, 7632,
  7633, 7634, 7635, 7637, 7638, 7640, 7641, 7643, 7644, 7645, 7646, 7647, 7648,
  7649, 7650, 7651, 7652, 7653, 7655, 7656, 7657, 7658, 7659, 7660, 7661, 7662,
  7664, 7667, 7668, 7669, 7670, 7671, 7672, 7673, 7674, 7675, 7676, 7677, 7678,
  7679, 7680, 7681, 7682, 7683, 7684, 7687, 7691, 7692, 7693, 7694, 7695, 7696,
  7698, 7701, 7706, 7709, 7710, 7711, 7712, 7713, 7714, 7715, 7716, 7717, 7718,
  7719, 7720, 7721, 7722, 7723, 7724, 7726, 7727, 7729, 7731, 7733, 7734, 7736,
  7737, 7738, 7739, 7740, 7741, 7742, 7743, 7744, 7745, 7747, 7749, 7750, 7751,
  7755, 7756, 7757, 7758, 7759, 7761, 7763, 7766, 7767, 7768, 7769, 7770, 7771,
  7772, 7773, 7774, 7775, 7776, 7779, 7780, 7781, 7782, 7784, 7785, 7786, 7789,
  7792, 7793, 7794, 7796, 7798, 7800, 7804, 7817, 7818, 7819, 7827, 7828, 7829,
  7830, 7831, 7832, 7834, 7835, 7836, 7837, 7838, 7839, 7840, 7841, 7844, 7845,
  7846, 7847, 7848, 7849, 7850, 7852, 7853, 7855, 7856, 7857, 7858, 7859, 7860,
  7861, 7865, 7869, 7870, 7873, 7877, 7883, 7884, 7887, 7888, 7893, 7896, 7897,
  7899, 7901, 7904, 7908, 7909, 7911, 7914, 7924, 7928, 7931, 7933, 7934, 7935,
  7936, 7939, 7941, 7942, 7943, 7944, 7948, 7959, 7960, 7961, 7962, 7963, 7964,
  7967, 7970, 7974, 7975, 7976, 7977, 7978, 7979, 7981, 7982, 7986, 7987, 7988,
  7989, 7992, 8011, 8012, 8013, 8014, 8015, 8016, 8017, 8018, 8019, 8020, 8021,
  8027, 8031, 8032, 8033, 8034, 8035, 8037, 8038, 8039, 8040, 8041, 8045, 8046,
  8047, 8048, 8049, 8050, 8051, 8052, 8053, 8054, 8055, 8056, 8057, 8058, 8059,
  8060, 8061, 8062, 8063, 8064, 8065, 8066, 8067, 8068, 8069, 8070, 8071, 8072,
  8073, 8074, 8075, 8076, 8077, 8078, 8079, 8080, 8081, 8082, 8083, 8084, 8085,
  8086, 8087, 8088, 8090, 8091, 8092, 8093, 8094, 8095, 8096, 8097, 8098, 8099,
  8100, 8101, 8102, 8103, 8104, 8105, 8106, 8108, 8109, 8110, 8111, 8112, 8113,
  8115, 8116, 8117, 8118, 8119, 8120, 8121, 8122, 8123, 8125, 8126, 8128,
];

const rightShoulder = [
  5999, 6000, 6027, 6029, 6030, 6103, 6104, 6137, 6174, 6176, 6626, 6627, 6628,
  6629, 6631, 6632, 6701, 6702, 6703, 6911, 7163, 7164, 7167, 7175, 7176, 7177,
  7178, 7183, 7184, 7185, 7186, 7203, 7204, 7205, 7206, 7207, 7214, 7216, 7218,
  7227, 7229, 7235, 7236, 7237, 7247, 8190, 8199, 8200, 8201, 8203, 8204, 8318,
  8320,
];

const rightCollarbone = [
  4280, 5349, 5350, 5921, 5981, 5982, 5983, 5984, 6090, 6091, 6092, 6093, 6095,
  6124, 6125, 6602, 6603, 6604, 6605, 6606, 6684, 6686, 6687, 7127, 7168, 7169,
  7174, 8129, 8184, 8188, 8189, 8191, 8192, 8194, 8195, 8196, 8350,
];

const rightUpperArm = [6821];

const rightToe = [8463, 8464, 8465, 8466, 8480, 8481, 8484, 8543];

const heart = [3382, 5652];

const forehead = [707];

const neck = [
  14, 221, 373, 374, 463, 564, 650, 1210, 1211, 1360, 1386, 1759, 1886, 1933,
  1934, 1940, 1941, 1948, 2150, 2151, 2964, 3012, 3185, 3192, 3193, 3194, 3195,
  3196, 3197, 3199, 3200, 3202, 3204, 3206, 3207, 3209, 3354, 3457, 3458, 5957,
  5959, 5963, 5965, 5966, 5969, 5972, 8813, 8814, 8938, 9165,
];

const thorax = [
  3242, 3243, 3315, 3334, 3371, 3381, 3382, 3383, 3384, 3389, 3390, 3392, 3393,
  3572, 3834, 3872, 3873, 3892, 3894, 3895, 3896, 3897, 3922, 3923, 3924, 3925,
  3927, 3979, 3980, 3982, 3985, 4049, 4069, 4168, 4395, 4396, 4452, 4454, 5439,
  5441, 5443, 5444, 5446, 5447, 5449, 5483, 5485, 5486, 5487, 5531, 5554, 5556,
  5561, 5634, 5635, 5638, 5639, 5642, 5643, 5644, 5645, 5646, 5647, 5648, 5652,
  5945, 6006, 6067, 6068, 6073, 6076, 6097, 6106, 6142, 6143, 6144, 6145, 6146,
  6147, 6148, 6149, 6152, 6333, 6589, 6590, 6623, 6624, 6642, 6643, 6644, 6645,
  6728, 6730, 6731, 6732, 6733, 6779, 6812, 6813, 6814, 6815, 6880, 6919, 7133,
  7189, 7190, 7193, 8173, 8174, 8175, 8180, 8181, 8183, 8214, 8215, 8269, 8273,
  8328, 8329, 8330, 8331, 8332, 8333, 8334, 8335, 8336, 8337, 8340, 8346,
];

const waist = [
  3292, 3293, 3308, 3309, 3335, 3460, 3461, 3474, 3475, 3476, 3485, 3488, 3489,
  3517, 3546, 3547, 3548, 3549, 3550, 3568, 3569, 3969, 3970, 4082, 4292, 4298,
  4401, 4402, 4403, 4404, 5491, 5492, 5493, 5503, 5504, 5505, 5506, 5507, 5517,
  5520, 5698, 5939, 6055, 6056, 6071, 6072, 6098, 6221, 6222, 6235, 6236, 6237,
  6246, 6249, 6250, 6278, 6307, 6308, 6309, 6310, 6311, 6329, 6330, 6717, 6718,
  6826, 7137, 7138, 7139, 7140, 8220, 8221, 8225, 8226, 8227, 8228, 8229, 8239,
  8240, 8392,
];

const hip = [
  3440, 3441, 3454, 3494, 3495, 3496, 3497, 3542, 3543, 3562, 3841, 3842, 3884,
  3885, 4080, 4081, 4084, 4088, 4144, 4145, 4146, 4320, 4321, 4422, 4424, 4425,
  5574, 5575, 5662, 5663, 5669, 5684, 5685, 5692, 5693, 5694, 5695, 5711, 5712,
  5713, 6201, 6202, 6215, 6255, 6256, 6257, 6258, 6304, 6323, 6596, 6597, 6634,
  6635, 6824, 6825, 6828, 6832, 6888, 6889, 6890, 7158, 7159, 7160, 7161, 8356,
  8357, 8363, 8378, 8379, 8386, 8387, 8388, 8389, 8405, 8406, 8407,
];

const calf = [
  3712, 3716, 3717, 3718, 3719, 3721, 3722, 3724, 3725, 3726, 3727, 3728, 3729,
  3730, 3731, 3732, 3733, 3741, 3742, 3784, 3785, 3788, 3810, 4001, 4098, 4099,
  4100, 4101, 4102, 4104, 4108, 4157, 4160, 4161,
];

const rightElbow = [
  6938, 6939, 6989, 6990, 6991, 6997, 6998, 7003, 7004, 7069, 7073, 7074, 7098,
  7117, 7118, 7126,
];

const leftElbow = [
  4194, 4195, 4245, 4246, 4247, 4253, 4254, 4259, 4260, 4333, 4337, 4338, 4362,
  4381, 4382, 4390,
];

const rightKnee = [
  6386, 6387, 6390, 6391, 6396, 6397, 6398, 6400, 6401, 6402, 6403, 6404, 6405,
  6408, 6409, 6410, 6411, 6434, 6435, 6436, 6437, 6440, 6441, 6442, 6443, 6444,
  6448, 6449, 6453, 6454, 6458, 6538, 6539, 6540, 6898, 6899,
];

const rightHip = [
  6223, 6227, 6228, 6230, 6253, 6254, 6255, 6273, 6274, 6275, 6276, 6303, 6304,
  6305, 6306, 6595, 6617, 6650, 6663, 6664, 6706, 6707, 6719, 6720, 6824, 6825,
  6832, 6855, 6856, 6857, 6874, 6889, 6890, 8352, 8353, 8354, 8355, 8357, 8359,
  8360, 8361, 8364, 8365, 8366, 8367, 8368, 8374, 8375, 8376, 8387, 8388, 8389,
  8397, 8398, 8399, 8402, 8405, 8406, 8407, 8408, 8409, 8410, 8411, 8412, 8413,
  8721, 8725,
];

const rightFoot = [
  8459, 8460, 8461, 8462, 8463, 8464, 8465, 8466, 8467, 8468, 8469, 8472, 8473,
  8474, 8475, 8476, 8477, 8479, 8480, 8481, 8482, 8483, 8484, 8485, 8486, 8487,
  8488, 8489, 8491, 8492, 8493, 8494, 8495, 8496, 8497, 8498, 8499, 8500, 8501,
  8502, 8503, 8504, 8505, 8506, 8507, 8508, 8510, 8511, 8514, 8515, 8516, 8518,
  8519, 8520, 8521, 8522, 8523, 8524, 8525, 8526, 8527, 8528, 8529, 8530, 8531,
  8532, 8533, 8534, 8535, 8536, 8537, 8538, 8539, 8540, 8541, 8543, 8544, 8545,
  8546, 8547, 8548, 8549, 8550, 8551, 8552, 8554, 8555, 8556, 8559, 8560, 8561,
  8562, 8563, 8564, 8565, 8566, 8584, 8585, 8586, 8587, 8588, 8589, 8590, 8591,
  8592, 8593, 8594, 8595, 8597, 8598, 8599, 8600, 8601, 8602, 8603, 8605, 8606,
  8607, 8608, 8609, 8610, 8611, 8612, 8613, 8645,
];

const leftKnee = [
  3642, 3649, 3650, 3675, 3676, 3677, 3678, 3679, 3680, 3681, 3682, 3683, 3684,
  3685, 3687, 3688, 3692, 3693, 3694, 3695, 3696, 3697, 3781, 3782, 3999, 4155,
];

const leftFoot = [
  5872, 5881, 5882, 5883, 5884, 5885, 5886, 5887, 5888, 5889, 5890, 5891, 5892,
  5893, 5894, 5895, 5896, 5897, 5898, 5899, 5900, 5901, 5902, 5918, 5919, 5922,
  5923, 5924, 5925, 5926, 5927, 5928, 5929, 5930, 5933, 8728, 8729, 8730, 8839,
  8840, 8841, 8842, 8843, 8844, 8845, 8846, 8847, 8848, 8849, 8850, 8851, 8852,
  8853, 8854, 8855, 8857, 8858, 8859, 8860, 8861, 8862, 8863, 8864, 8865, 8869,
  8870, 8871, 8872, 8873, 8874, 8875, 8876, 8877, 8878, 8892, 8893, 8894, 8895,
  8896, 8935,
];

const leftCollarbone = [
  3217, 3218, 3219, 3220, 3329, 3330, 3332, 3363, 3882, 3883, 3936, 3937, 3938,
  3954, 3955, 4167, 4426, 4430, 4431, 4432, 4437, 4438, 4512, 4517, 5457, 5458,
  5461, 5462, 5463,
];

const leftHip = [
  3462, 3463, 3469, 3513, 3514, 3544, 3545, 3866, 3902, 3915, 3916, 3958, 3959,
  4111, 4112, 4113, 5658, 5659, 5660, 5661, 5665, 5666, 5667, 5670, 5672, 5673,
  5674, 5680, 5681, 5682, 5703, 5704, 5705, 5708, 5714, 9021, 9025,
];

const POINT_SOURCES = {
  calf,
  forehead,
  heart,
  hip,
  leftAnkle,
  leftEye,
  leftHip,
  leftHand,
  leftElbow,
  leftCollarbone,
  leftIndexFinger,
  leftShoulder,
  leftToe,
  leftKnee,
  leftFoot,
  leftUpperArm,
  neck,
  rightAnkle,
  rightCollarbone,
  rightEye,
  rightHand,
  rightHip,
  rightElbow,
  rightKnee,
  rightShoulder,
  rightFoot,
  rightToe,
  rightUpperArm,
  thorax,
  top,
  waist,
};

export class BufferVector {
  buffer: THREE.BufferAttribute;

  constructor(buffer: THREE.BufferAttribute) {
    this.buffer = buffer;
  }

  getVec3(index: number) {
    return new THREE.Vector3(
      this.buffer.getX(index),
      this.buffer.getY(index),
      this.buffer.getZ(index)
    );
  }

  getBox3(indices: number[]) {
    const box = new THREE.Box3(
      new THREE.Vector3(Infinity, Infinity, Infinity),
      new THREE.Vector3(-Infinity, -Infinity, -Infinity)
    );

    const { min, max } = box;

    for (const index of indices) {
      const pos = this.getVec3(index);

      if (pos.x < box.min.x) {
        min.x = pos.x;
      }
      if (pos.x > max.x) {
        max.x = pos.x;
      }

      if (pos.y < min.y) {
        min.y = pos.y;
      }
      if (pos.y > max.y) {
        max.y = pos.y;
      }

      if (pos.z < min.z) {
        min.z = pos.z;
      }
      if (pos.z > max.z) {
        max.z = pos.z;
      }
    }

    return box;
  }
}

export type BodyArea = keyof typeof POINT_SOURCES;

export type BodyAreas = Record<BodyArea, THREE.Box3>;

export function findSMPLAreas(points: THREE.Points): BodyAreas {
  const lookup = new BufferVector(
    points.geometry.attributes.position as THREE.BufferAttribute
  );

  function createBounds(indices: number[]) {
    return lookup.getBox3(indices);
  }

  const areaEntries = Object.entries(POINT_SOURCES).map(([key, index]) => {
    return [key, createBounds(index)] as [BodyArea, THREE.Box3];
  });

  return Object.fromEntries(areaEntries) as BodyAreas;
}

export function createFakeSMPLPositions(): BodyAreas {
  function createBox(
    x: number,
    y: number,
    z: number,
    width: number = 0,
    height: number = 0,
    depth: number = 0
  ) {
    const w = width / 2;
    const h = height / 2;
    const d = depth / 2;

    const box = new THREE.Box3();
    box.min.set(x - w, y - d, z - h);
    box.max.set(x + w, y + d, z + h);
    return box;
  }

  return {
    top: createBox(0, 0, 1800),
    forehead: createBox(0, 0, 1750),
    leftEye: createBox(50, 0, 1750),
    rightEye: createBox(-50, 0, 1750),
    calf: createBox(200, 0, 350, 150),
    hip: createBox(0, 0, 950, 400),
    leftAnkle: createBox(200, 0, 100),
    leftHand: createBox(400, -160, 800),
    rightHand: createBox(-400, -160, 800),
    leftCollarbone: createBox(220, 0, 1500),
    leftIndexFinger: createBox(400, -160, 800),
    leftShoulder: createBox(320, 0, 1500),
    leftElbow: createBox(320, 0, 1200),
    leftHip: createBox(200, 0, 1000),
    leftFoot: createBox(200, 0, 0),
    leftKnee: createBox(200, 0, 600),
    leftToe: createBox(200, 0, 0),
    leftUpperArm: createBox(280, 0, 1300),
    heart: createBox(0, 0, 1400),
    neck: createBox(0, 0, 1600),
    rightAnkle: createBox(-200, 0, 100),
    rightCollarbone: createBox(-220, 0, 1500),
    rightShoulder: createBox(-320, 0, 1500),
    rightElbow: createBox(-320, 0, 1200),
    rightHip: createBox(-200, 0, 1000),
    rightKnee: createBox(-200, 0, 600),
    rightFoot: createBox(-200, 0, 0),
    rightToe: createBox(-200, 0, 0),
    rightUpperArm: createBox(-280, 0, 1300),
    thorax: createBox(0, 0, 1500, 400),
    waist: createBox(0, 0, 1100, 300),
  };
}
