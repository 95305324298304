import { Assessment, Patient } from "@cur8/rich-entity";
import { createContext } from "react";
import useAssessment from "./lib";

interface AssessmentContextProps {
  assessments: Assessment[];
  patient: Patient;
  selected: Assessment;
  children: React.ReactNode;
}
type AssessmentContextValue = ReturnType<typeof useAssessment>;

export const Context = createContext<AssessmentContextValue | null>(null);

export default function AssessmentContext({
  assessments,
  patient,
  selected,
  children,
}: AssessmentContextProps) {
  const value = useAssessment({ patient, assessments, selected });

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
