import * as THREE from "three";

export function createFloorGeometry() {
  const geometry = new THREE.BufferGeometry();

  const positions: number[] = [];

  const width = 400;
  const depth = 500;

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < depth; y++) {
      for (let z = 0; z < 1; z++) {
        positions.push((x - width / 2) * 25, y * 75 - 3000, z);
      }
    }
  }

  geometry.setAttribute(
    "position",
    new THREE.Float32BufferAttribute(positions, 3)
  );

  geometry.computeBoundingSphere();

  return geometry;
}
