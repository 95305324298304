import { APITypesV1 } from "@cur8/api-client";

const Cls = APITypesV1.SkinLesionClassification;

export const LabelMap: Record<APITypesV1.SkinLesionClassification, string> = {
  [Cls.ActinicKeratosis]: "Actinic keratosis",
  [Cls.BasalCellCarcinoma]: "Basal cell carcinoma",
  [Cls.BenignKeratosis]: "Benign keratosis",
  [Cls.Dermatofibroma]: "Dermatofibroma",
  [Cls.MelanocyticNevus]: "Melanocytic nevus",
  [Cls.Melanoma]: "Melanoma",
  [Cls.SquamousCellCarcinoma]: "Squamous cell carcinoma",
  [Cls.VascularLesion]: "Vascular lesion",
  [Cls.Other]: "Other",
};

export default function SkinLesionClassification({
  classification,
}: {
  classification: APITypesV1.SkinLesionClassification;
}) {
  return <>{LabelMap[classification] ?? "Unknown"}</>;
}
