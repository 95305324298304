import React from "react";
import styles from "./styles.module.sass";

interface TooltipProps {
  data: { label: string; value: React.ReactNode; color: string }[];
}

export default function Tooltip({ data }: TooltipProps) {
  return (
    <div className={styles.Tooltip}>
      <ul>
        {data.map(({ color, label, value }) => (
          <li style={{ "--color": color } as unknown as any}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>{value}</div>
          </li>
        ))}
      </ul>
    </div>
  );
}
