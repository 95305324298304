import { Interval } from "luxon";
import { useCallback } from "react";
import { useSchedule } from "render/pages/SchedulePage/context/ScheduleContext";
import {
  addSlotToSelectedAction,
  enterCalendarViewModeAction,
  enterSlotEditingModeAction,
  removeSlotFromSelectedAction,
  resetSelectedSlotsAction,
  setAppointmentsCensoredAction,
  setSelectedPeriodAction,
} from "render/pages/SchedulePage/reducer";
import { CalendarEvent } from "render/pages/SchedulePage/reducer/slotManagementUtils";

export default function useCalendarInteraction() {
  const { dispatch, setQueryParams } = useSchedule();

  const setSelectedPeriod = useCallback(
    (interval: Interval) => {
      dispatch(setSelectedPeriodAction(interval));
      setQueryParams({
        from: [interval.start],
        to: [interval.end],
      });
    },
    [dispatch, setQueryParams]
  );

  const toggleSlotBlur = useCallback(
    (toggle: boolean) => {
      setQueryParams({ censorAppointments: [toggle] });
      dispatch(setAppointmentsCensoredAction(toggle));
    },
    [setQueryParams, dispatch]
  );

  const removeSlotFromBulkSelection = useCallback(
    (slot: CalendarEvent) => dispatch(removeSlotFromSelectedAction(slot)),
    [dispatch]
  );

  const addSlotToBulkSelection = useCallback(
    (slot: CalendarEvent) => dispatch(addSlotToSelectedAction(slot)),
    [dispatch]
  );

  const resetSlotSelection = useCallback(
    () => dispatch(resetSelectedSlotsAction()),
    [dispatch]
  );

  const enterCalendarEditMode = useCallback(
    () => dispatch(enterSlotEditingModeAction()),
    [dispatch]
  );

  const enterCalendarViewMode = useCallback(
    () => dispatch(enterCalendarViewModeAction()),
    [dispatch]
  );

  return {
    dispatch,
    removeSlotFromBulkSelection,
    addSlotToBulkSelection,
    resetSlotSelection,

    enterCalendarViewMode,
    enterCalendarEditMode,

    setSelectedPeriod,
    toggleSlotBlur,
  };
}
