import { APIFetchError } from "@cur8/api-client";
import { createElement, useCallback } from "react";
import { useNotifications } from "render/context/NotificationContext";
import RequestErrorNotification from "render/fragments/notification/RequestErrorNotification";

let wasHandled: unknown;

export function useReporting() {
  const { emit } = useNotifications();

  const handleAPIFetchError = useCallback(
    async (error: APIFetchError) => {
      const body = await error.response.text();

      const content = createElement(RequestErrorNotification, {
        error,
        comment: "API Error",
        body,
      });

      emit(content, "error");
    },
    [emit]
  );

  const handleError = useCallback(
    (error: unknown) => {
      if (wasHandled === error) {
        return;
      }

      wasHandled = error;

      if (error instanceof APIFetchError) {
        handleAPIFetchError(error);
      } else if (error instanceof Error) {
        if (error.name === "AbortError") {
          console.debug("Fetch aborted", { error });
        } else {
          emit(`Error: ${error.message}`, "error");
        }
      } else {
        console.error("Unreported Error", error);
      }
    },
    [handleAPIFetchError, emit]
  );

  const logError = useCallback(
    (error: unknown) => {
      handleError(error);
      throw error;
    },
    [handleError]
  );

  return { logError, handleError };
}
