export type BodySide = "left" | "right";

export function resolveHighestSide(
  left: number | undefined,
  right: number | undefined
): BodySide | undefined;
export function resolveHighestSide(
  left: number | undefined,
  right: number | undefined,
  fallback: BodySide
): BodySide;
export function resolveHighestSide(
  left: number | undefined,
  right: number | undefined,
  fallback: BodySide | undefined = undefined
) {
  if (left === undefined && right === undefined) {
    return fallback;
  }

  if (left === undefined) {
    return "right";
  }

  if (right === undefined) {
    return "left";
  }

  return left > right ? "left" : "right";
}
