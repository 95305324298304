import { Sticky } from "@pomle/react-viewstack";
import { fetchDermaImage } from "lib/api/resolvers/annotation";
import { silenceAbort } from "lib/error";
import { DermoscopyAnnotation } from "lib/lesion";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "render/hooks/useBlobImage";
import styles from "./styles.module.sass";

interface ImageViewerProps {
  annotation: DermoscopyAnnotation | undefined;
}

export default function ImageViewer({ annotation }: ImageViewerProps) {
  const api = useAPIClient();

  const [blob, setBlob] = useState<Blob>();
  const image = useBlobImage(blob);

  useEffect(() => {
    if (!annotation) {
      return;
    }

    const request = fetchDermaImage(api, annotation);
    request?.result
      .then((response) => response.blob())
      .then(setBlob)
      .catch(silenceAbort);

    return () => {
      request?.abandon();
      setBlob(undefined);
    };
  }, [api, annotation]);

  return (
    <div className={styles.ImageViewer} data-active={!!annotation}>
      <Sticky>{image && <img src={image.src} alt="Dermascope" />}</Sticky>
    </div>
  );
}
