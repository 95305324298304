import { Risk, RiskRange } from "@cur8/health-risks-calc";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { toFormat } from "render/ui/format/FullDate";
import ValueTimeline from "../ValueTimeline";

const RISK_COLOR: Record<Risk, string> = {
  [Risk.Unknown]: "#fff",
  [Risk.Optimal]: "#019dc9",
  [Risk.Normal]: "#019dc9",
  [Risk.Risk]: "#ffaf38",
  [Risk.HighRisk]: "#f96452",
  [Risk.ImmediateRisk]: "#000",
};

function toColor(risk: Risk) {
  return RISK_COLOR[risk];
}

export type Entry = {
  value: number;
  repr?: string;
  risk: Risk;
  timestamp: DateTime;
};

interface MeasurementTimelineProps {
  ranges: readonly RiskRange[];
  entries?: readonly Entry[];
}

export default function MeasurementTimeline({
  ranges,
  entries,
}: MeasurementTimelineProps) {
  const r = useMemo(() => {
    const MARGIN_SIZE = 0.1;

    const points = ranges
      .flatMap((range) => [range.from, range.to])
      .filter(isFinite);

    const min = Math.min(...points);
    const max = Math.max(...points);
    const len = max - min;
    const margin = len * MARGIN_SIZE;

    return ranges
      .filter((range) => isFinite(range.from) || isFinite(range.to))
      .map((range) => {
        const from = isFinite(range.from) ? range.from : range.to - margin;
        const to = isFinite(range.to) ? range.to : range.from + margin;

        return {
          from: {
            value: from,
            label: from.toString(),
          },
          to: {
            value: to,
            label: to.toString(),
          },
          color: toColor(range.risk),
        };
      });
  }, [ranges]);

  const p = useMemo(() => {
    if (!entries) {
      return [];
    }

    return entries.map((entry) => {
      const value = entry.value;
      return {
        value,
        color: toColor(entry.risk),
        title: `${value} -- ${toFormat(entry.timestamp)}`,
      };
    });
  }, [entries]);

  return <ValueTimeline ranges={r} points={p} />;
}
