import { Side } from "@cur8/api-client";
import {
  Annotation,
  AnnotationClassificationType,
  BoundingBoxDataType,
  ImmutableScan,
  MoleClassification,
  hasBoundingBox,
} from "@cur8/rich-entity";
import { PatientBlobURI, URIType } from "@cur8/uri";
import { RGBInoImageURI } from "./api/uri";
import { Coord, ImageTraverse } from "./canvas";
import { distinct } from "./filter";
import { distanceSq } from "./math";
import { sortBy } from "./sort";

export type LesionAnnotation = Annotation<
  MoleClassification,
  BoundingBoxDataType
>;

export type DermoscopyAnnotation = Annotation & {
  targetURI: PatientBlobURI;
  applicationSpecificTarget: "derma:derma";
};

export type LesionLink = {
  scan?: ImmutableScan;
  annotation: Annotation;
};

export type Lesion = {
  fakeId: number;
  physicalId: string;
  links: LesionLink[];
};

export const DETECTED_PHYSICAL_ARTIFACT_MEDIAN = 1252;

export function isLesionAnnotation(
  annotation: Annotation
): annotation is LesionAnnotation {
  return (
    annotation.classification?.$type === AnnotationClassificationType.Mole &&
    hasBoundingBox(annotation)
  );
}

export function isDermoscopyAnnotation(
  annotation: Annotation
): annotation is DermoscopyAnnotation {
  return (
    annotation.targetURI.type === URIType.PatientBlob &&
    annotation.applicationSpecificTarget === "derma:derma"
  );
}

export const SELECTED_COUNT = 5;

export function getSelected(lesions: Lesion[]) {
  const lesionsSortedByScore = lesions
    .filter(hasLesionLink)
    .map((lesion) => {
      return {
        score: calcScore(lesion),
        lesion,
      };
    })
    .toSorted((a, b) => b.score - a.score);

  console.debug("Lesions sorted by score:", lesionsSortedByScore);

  return lesionsSortedByScore.slice(0, SELECTED_COUNT).map((t) => t.lesion);
}

export function calcScore(lesion: Lesion) {
  let score = 0;

  const history = lesion.links.filter(distinct((link) => link.scan));

  score += history.length * 100;

  for (const link of lesion.links) {
    const anno = link.annotation;

    if (isDermoscopyAnnotation(anno)) {
      score += 10000;
    }

    if (isLesionAnnotation(anno)) {
      const cls = anno.classification;
      if (cls.size && cls.score) {
        if (cls.size <= 50) {
          score += cls.size * cls.score * 100;
        }
      }
    }
  }

  return score;
}

export function isDermaLink(
  link: LesionLink
): link is LesionLink & { annotation: DermoscopyAnnotation } {
  return isDermoscopyAnnotation(link.annotation);
}

function isRGBinoTarget(anno: Annotation) {
  const type = anno.targetURI.type;
  return type === URIType.ImmutableScanBlob || type === URIType.FrameSequence;
}

export function isPreferredLink(
  link: LesionLink
): link is LesionLink & { annotation: LesionAnnotation } {
  const anno = link.annotation;

  return (
    isLesionAnnotation(anno) &&
    isRGBinoTarget(anno) &&
    !!anno.classification.preferredView
  );
}

export function hasLesionLink(lesion: Lesion) {
  return lesion.links.some((link) => {
    return isLesionAnnotation(link.annotation);
  });
}

export function hasScan(scan: ImmutableScan) {
  return function matchesRecordingURI(lesion: Lesion) {
    return lesion.links.some((link) => {
      return link.scan === scan;
    });
  };
}

export const byLinkDate = sortBy<LesionLink>((link) =>
  link.annotation.createdAt.valueOf()
);

export function extractSide(annotation: Annotation): Side | undefined {
  const appTargetURI = annotation.applicationSpecificTarget;
  if (appTargetURI) {
    const uri = RGBInoImageURI.parse(appTargetURI);
    if (uri) {
      return uri.side;
    }
  }
}

export function drawMask(lesion: LesionAnnotation) {
  const rle = lesion.classification.maskEncodedRLE ?? "";
  const mask = atob(rle).split("-").map(parseFloat);
  const rect = lesion.data.rect;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    throw new Error("Could not create context");
  }

  canvas.width = rect.w;
  canvas.height = rect.h;

  context.clearRect(0, 0, canvas.width, canvas.height);

  let chunkIndex = 0;
  let chunkLimit = 0;
  for (let i = 0; i < canvas.width * canvas.height; i++) {
    if (chunkLimit === i) {
      chunkLimit += mask[chunkIndex++];
    }

    const x = Math.floor(i / canvas.height); // why imageHeight and not imageWidth?
    const y = i % canvas.height;

    if (chunkIndex % 2 === 0) {
      context.fillRect(x, y, 1, 1);
    }
  }

  return canvas;
}

export function findOutline(canvas: HTMLCanvasElement) {
  const maskImage = new ImageTraverse(canvas);

  const outline = new Map<number, Coord>();

  outer: for (const { x, y } of maskImage) {
    const target = maskImage.getPixel(x, y);

    if (target[3] === 255) {
      for (const neighbour of maskImage.getNeighbors(x, y)) {
        if (neighbour[3] === 0) {
          const pos = maskImage.toPos(x, y);
          outline.set(pos, { x, y });
          continue outer;
        }
      }
    }
  }

  const waypoints: Coord[] = [];

  if (outline.size === 0) {
    return waypoints;
  }

  // Pick any starting point
  for (const [sourceIndex, sourceCoord] of outline) {
    outline.delete(sourceIndex);
    waypoints.push(sourceCoord);
    break;
  }

  while (outline.size > 0) {
    const candidate = {
      index: -1,
      coord: { x: 0, y: 0 },
      distance: Infinity,
    };

    const sourceCoord = waypoints[waypoints.length - 1];

    for (const [targetIndex, targetCoord] of outline) {
      const dist = distanceSq(sourceCoord, targetCoord);
      if (dist < candidate.distance) {
        candidate.index = targetIndex;
        candidate.distance = dist;
        candidate.coord = targetCoord;
      }
    }

    if (isFinite(candidate.distance) && candidate.distance > 3.5) {
      waypoints.push(candidate.coord);
    }

    outline.delete(candidate.index);
  }

  return waypoints;
}

export const SKIN_FAKE_COLORS = [
  "#7d625b",
  "#b7917a",
  "#775d56",
  "#a58672",
  "#b78f76",
];
