import { createContext, useContext } from "react";
import { usePatientRecorder } from "./hooks/usePatientRecorder";

const Context = createContext<ReturnType<typeof usePatientRecorder> | null>(
  null
);

interface PatientAudioRecordContextProps {
  children: React.ReactNode;
}

export default function PatientAudioRecordContext({
  children,
}: PatientAudioRecordContextProps) {
  const value = usePatientRecorder();

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

export function usePatientAudioRecordContext() {
  return useContext(Context);
}
