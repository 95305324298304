export type Coord = {
  x: number;
  y: number;
};

export class ImageTraverse {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  image: ImageData;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.context = canvas.getContext("2d")!;
    this.image = this.context.getImageData(0, 0, canvas.width, canvas.height, {
      colorSpace: "srgb",
    });
  }

  getNeighbors(x: number, y: number) {
    return [
      this.getPixel(x, y - 1),
      this.getPixel(x + 1, y - 1),
      this.getPixel(x + 1, y),
      this.getPixel(x + 1, y + 1),
      this.getPixel(x, y + 1),
      this.getPixel(x - 1, y + 1),
      this.getPixel(x - 1, y),
      this.getPixel(x - 1, y - 1),
    ];
  }

  toPos(x: number, y: number) {
    return y * this.canvas.width + x;
  }

  toIndex(x: number, y: number) {
    return this.toPos(x, y) * 4;
  }

  toCoord(pos: number): Coord {
    const y = Math.floor(pos / this.canvas.width);
    const x = pos - y * this.canvas.width;
    return {
      x,
      y,
    };
  }

  getPixel(x: number, y: number) {
    const index = this.toIndex(x, y);
    return this.image.data.slice(index, index + 4);
  }

  setPixel(x: number, y: number, data: Uint8ClampedArray) {
    const index = this.toIndex(x, y);
    this.image.data.set(data, index);
  }

  *[Symbol.iterator]() {
    for (let y = 0; y < this.canvas.height; y++) {
      for (let x = 0; x < this.canvas.width; x++) {
        yield {
          x,
          y,
        };
      }
    }
  }
}
