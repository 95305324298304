import { usePatientAudioRecordContext } from "render/context/patient/PatientAudioRecordContext";
import MenuButton from "../MenuButton";
import Recording from "./assets/recording.svg?react";
import styles from "./styles.module.sass";

export default function AudioRecorder() {
  const audioRecorder = usePatientAudioRecordContext();

  return (
    <div
      className={styles.AudioRecorder}
      data-recording={!!audioRecorder?.session}
    >
      <MenuButton
        onClick={() => {
          audioRecorder?.toggle();
        }}
        disabled={!audioRecorder?.ready}
        title="Record Audio"
      >
        <div className={styles.recordButton}>
          <Recording />
        </div>
      </MenuButton>
    </div>
  );
}
