import styles from "./styles.module.sass";

interface ButtonSetProps {
  stacked?: boolean;
  children: React.ReactNode;
}

export default function ButtonSet({
  children,
  stacked = false,
}: ButtonSetProps) {
  return (
    <div className={styles.ButtonSet} data-stacked={stacked}>
      {children}
    </div>
  );
}
