import { APITypesV1 } from "@cur8/api-client";
import { hasBoundingBox, hasLine } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { Box } from "lib/math";
import { useCallback, useEffect, useState } from "react";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import colors from "../../colors.module.sass";
import { useTissueAnnotationContext } from "../../context/TissueAnnotationContext";
import { TargetProperty, TissueAnnotation } from "../../lib/types";
import { getColorName, line2DtoLine } from "../../lib/utils";
import AuditInfo from "./AuditInfo";
import styles from "./styles.module.sass";

interface DisplayLabelProps {
  allowEdit?: boolean;
  anno: TissueAnnotation;
  edit: () => void;
  isSelected: boolean;
  pixelsPerCm: number | undefined;
}

export default function DisplayLabel({
  allowEdit = true,
  anno,
  edit,
  isSelected,
  pixelsPerCm,
}: DisplayLabelProps) {
  const { move } = useTissueAnnotationContext();

  const [xy, setXy] = useState<string>();
  const [toXy, seToXy] = useState<string>();
  const [wh, setWh] = useState<string>();
  const type = hasLine(anno.annotation) ? "loi" : "roi";
  const [showRealSize, setShowRealSize] = useState<boolean>(false);

  useEffect(() => {
    if (hasBoundingBox(anno.annotation)) {
      const b = anno.annotation.data.rect;
      setXy(`${b.x} : ${b.y}`);
      if (pixelsPerCm && anno.property === TargetProperty.nonthermal) {
        setWh(
          `${(b.w / pixelsPerCm).toFixed(2)} x ${(b.w / pixelsPerCm).toFixed(
            2
          )}`
        );
        setShowRealSize(true);
      } else {
        setWh(`${b.w} x ${b.h}`);
        setShowRealSize(false);
      }
    } else if (hasLine(anno.annotation)) {
      const l = anno.annotation.data.line;
      setXy(`${l.a.x} : ${l.a.y}`);
      seToXy(`${l.b.x} : ${l.b.y}`);
      setWh(line2DtoLine(l).length().toFixed(0));
    }
  }, [anno.annotation, anno.property, pixelsPerCm]);

  const fixedSize = useCallback(
    (size: number) => {
      if (!pixelsPerCm || anno.property === TargetProperty.thermal) {
        return;
      }
      if (!hasBoundingBox(anno.annotation)) {
        return;
      }
      const s = Math.round(size * pixelsPerCm);
      const b = anno.annotation.data.rect;
      move(anno.annotation.id, { rect: new Box(b.x, b.y, s, s) });
    },
    [anno.property, anno.annotation, move, pixelsPerCm]
  );

  const editProxy = useCallback(() => {
    if (isSelected) {
      edit();
    }
  }, [edit, isSelected]);

  return (
    <div className={styles.DisplayLabel} data-selected={isSelected}>
      <div className={styles.label}>
        <div
          className={classNames(
            type === "loi" ? styles.Line : styles.Box,
            colors.border
          )}
          data-color={getColorName(anno.color)}
        ></div>
        <div className={styles.txt}>
          {anno.label}
          {anno.annotation.acceptState === APITypesV1.AcceptState.Proposed && (
            <span title="RoI Candidate"> (c)</span>
          )}
        </div>
        {allowEdit && (
          <svg
            className={styles.edit}
            onClickCapture={editProxy}
            width="20"
            height="20"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M33.75 3.1a7.76 7.76 90 0 0-11 0L2.62 23.25a1 1 90 0 0-.29.58L.83 34.87a1.02 1.02 0 0 0 1 1.15h.14l6.66-.9a1.01 1.01 90 1 0-.28-2.02l-5.33.73 1.05-7.71 8.1 8.11a.99.99 90 0 0 1.43 0l20.15-20.14a7.72 7.72 0 0 0 0-10.98zm-10.6 2.48 3.38 3.38L8.15 27.34l-3.39-3.38L23.14 5.58zM12.9 32.08l-3.3-3.3 18.38-18.39 3.3 3.31-18.37 18.4zm19.8-19.82-8.1-8.1a5.73 5.73 90 0 1 7.72.37 5.68 5.68 90 0 1 1.69 4.06c0 1.36-.47 2.64-1.32 3.66z" />
          </svg>
        )}
      </div>
      {isSelected && (
        <div className={styles.infoBox}>
          <div className={styles.data}>
            {type === "roi" && (
              <>
                <label>
                  Position <small>(X, Y)</small>
                </label>
                <b>{xy}</b>
                <label>
                  Size <small>(W x H)</small>
                </label>
                <b>
                  {wh}
                  <span> {showRealSize ? "cm" : "px"}</span>
                </b>
              </>
            )}
            {type === "loi" && (
              <>
                <label>
                  From <small>(X, Y)</small>
                </label>
                <b>{xy}</b>
                <label>
                  To <small>(X, Y)</small>
                </label>
                <b>{toXy}</b>
                <label>Length</label>
                <b>
                  {wh}
                  <span> points</span>
                </b>
              </>
            )}
            <AuditInfo auditInfo={anno.annotation.audit} />
          </div>
          {allowEdit && showRealSize && type === "roi" && (
            <div className={styles.fixedSize}>
              <label>Set fixed size</label>
              <div className={styles.buttonSet}>
                <HoverTextButton onClick={() => fixedSize(0.5)}>
                  0.5 cm
                </HoverTextButton>
                <HoverTextButton onClick={() => fixedSize(1)}>
                  1 cm
                </HoverTextButton>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
