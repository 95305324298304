import { APITypesV1 } from "@cur8/api-client";
import { Patient } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { DateTime } from "luxon";

export class RecordingSession {
  date: DateTime;
  stream: MediaStream;

  constructor(stream: MediaStream) {
    this.date = DateTime.now();
    this.stream = stream;
  }

  stop() {
    this.stream.getTracks().forEach((track) => track.stop());
  }
}

export function audiorecordingFilename(timestamp: string) {
  return `speech/${timestamp}_audiorecording.json`;
}

export function webmFilename(timestamp: string, n: number) {
  return `speech/${timestamp}_${n}.webm`;
}

export async function uploadPatientAudioBlob(
  api: APIClient,
  patient: Patient,
  data: Blob,
  fileName: string,
  scan: APITypesV1.ImmutableScan
) {
  const patientId = patient.patientId;

  await api.scan.createScanResult({
    patientId,
    scanId: scan.id,
    scanVersion: scan.version,
    resultName: "audioFiles",
    state: APITypesV1.ResultState.Inprogress,
  }).result;

  const res = await api.blob.putScanBlob({
    patientId,
    scanId: scan.id,
    scanVersion: scan.version,
    resultName: "audioFiles",
    path: fileName,
    file: data,
  }).result;

  if (!res.ok) {
    console.error(
      "Could not upload recording, upload resulted in: ",
      res.status,
      res.statusText
    );
    return false;
  }

  return true;
}
