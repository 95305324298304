import { RequestEnvelope } from "@cur8/api-client/dist/api/types";
import { BoundingBoxAnnotation, ImmutableScan } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";
import { useTissueImageContext } from "../context/TissueImageContext";
import {
  Property,
  PropertyRange,
  RegionTimeseries,
  TargetProperty,
  TissueAnnotation,
} from "../lib/types";
import { cleanAndTrimSeries } from "../lib/utils";

export function useChromophoreTimeseriesMulti(
  scan: ImmutableScan,
  annos: TissueAnnotation<BoundingBoxAnnotation>[],
  indexRemap: number[],
  property: Property,
  timestamps: number[],
  range?: PropertyRange
) {
  const api = useAPIClient().transcode;
  const { handleError } = useReporting();
  const { bloodVesselsMask } = useTissueImageContext();

  const [series, setSeries] = useState<RegionTimeseries[]>([]);

  const fetch = useCallback(() => {
    setSeries([]); // Clear current series
    const proms = [];
    const requests: RequestEnvelope<number[]>[] = [];

    for (let i = 0; i < annos.length; i++) {
      let anno = annos[i];
      if (
        (property === Property.thermal &&
          anno.property === TargetProperty.nonthermal) ||
        (property !== Property.thermal &&
          anno.property === TargetProperty.thermal)
      ) {
        // Filter on thermal
        continue;
      }
      const req = api.fetchChromophoreTimeseries(
        {
          patientId: scan.patientId,
          scanId: scan.id,
          scanVersion: scan.version,
          chromophore: property,
        },
        anno.annotation.data.rect,
        bloodVesselsMask?.threshold,
        bloodVesselsMask?.extract
      );
      requests.push(req);

      let prom = req.result
        .then((res) => {
          const rts = {
            color: anno.color,
            id: anno.annotation.id,
            label: anno.label,
            series: cleanAndTrimSeries(
              res,
              indexRemap,
              timestamps,
              range?.factor
            ),
          } as RegionTimeseries;

          return rts;
        })
        .catch(handleError);
      proms.push(prom);
    }
    Promise.all(proms).then((res) => {
      let ret: RegionTimeseries[] = [];
      res.forEach((r) => {
        if (r) {
          ret.push(r);
        }
      });
      setSeries(ret);
    });

    return () => {
      requests.forEach((req) => req.abandon());
    };
  }, [
    annos,
    api,
    bloodVesselsMask,
    handleError,
    indexRemap,
    property,
    range?.factor,
    scan,
    timestamps,
  ]);

  useEffect(() => {
    fetch();
  }, [annos, fetch, property]);

  return series;
}
