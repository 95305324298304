import { codecs, Path } from "@pomle/paths";

export function createPaths<T extends {}>(origin: Path<T>) {
  const create = origin.append("/visit-summary", {});
  const edit = create.append("/:summaryId", {
    summaryId: codecs.string,
  });

  return { create, edit };
}
