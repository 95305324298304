import * as THREE from "three";

export function toCenter(box: THREE.Box3) {
  const pos = new THREE.Vector3();
  box.getCenter(pos);
  return pos;
}

export class CameraView {
  fov = 35;
  pos = new THREE.Vector3();
  focus = new THREE.Vector3();

  clone() {
    const next = new CameraView();
    next.fov = this.fov;
    next.pos = this.pos.clone();
    next.focus = this.focus.clone();
    return next;
  }
}

export class PodiumState {
  pos = new THREE.Vector3();
  angle = new THREE.Euler();

  clone() {
    const next = new PodiumState();
    next.pos.copy(this.pos);
    next.angle.copy(this.angle);
    return next;
  }
}
