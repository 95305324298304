import { DateTime } from "luxon";
import { PropsWithChildren } from "react";
import styles from "./styles.module.sass";
import { Direction } from "./enums";

export default function SlotRenderer({
  openingHour,
  closingHour,
  slot,
  direction,
  children,
  rowGapSize,
}: PropsWithChildren<{
  direction: Direction;
  openingHour: DateTime;
  closingHour: DateTime;
  rowGapSize: number;
  slot: {
    startTime: DateTime;
    endTime: DateTime;
  };
}>) {
  const hourRows = Math.ceil(closingHour.diff(openingHour, "hour").hours);
  const gapCount = hourRows - 1;

  const gapOffsetSize = (gapCount / hourRows) * rowGapSize;
  const dayStart = openingHour.toUnixInteger();
  const dayEnd = closingHour.toUnixInteger();
  const dayLength = dayEnd - dayStart;
  const offsetGaps = Math.floor(
    Math.floor(slot.startTime.hour - openingHour.hour)
  );
  const slotDuration =
    (slot.endTime.toUnixInteger() - slot.startTime.toUnixInteger()) / (60 * 60);
  const topGapOffset = offsetGaps * (gapOffsetSize / gapCount);
  const startOffset = slot.startTime.toUnixInteger();
  const endOffset = slot.endTime.toUnixInteger();
  const height = ((endOffset - startOffset) / dayLength) * 100;
  const top = ((startOffset - dayStart) / dayLength) * 100;
  const heighOffset = gapOffsetSize * slotDuration;

  return (
    <div
      className={styles.SlotRenderer}
      style={{
        pointerEvents: "auto",
        top: `calc(${top}% + ${topGapOffset}px)`,
        height: `calc(${height}% - ${heighOffset}px)`,
        left: direction === "left" ? 0 : `calc(50% + ${rowGapSize / 2}px)`,
        width: `calc(50% - ${rowGapSize / 2}px)`,
      }}
    >
      {children}
    </div>
  );
}
