import { useEffect, useState } from "react";

export function useDelay({
  active,
  delay,
}: {
  active: boolean;
  delay: number;
}) {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (!active) {
      return;
    }

    const timer = setTimeout(setReady, delay, true);

    return () => {
      setReady(false);
      clearTimeout(timer);
    };
  }, [active, delay]);

  return ready;
}
