import { TBI } from "@cur8/formulas";
import { Metric } from "lib/metric";
import { byPressure, bySystolic } from "lib/metric/sort";

export function calculateTBI(
  brachialPressure: {
    left: Metric<"cardio.brachial_pressure.left">[];
    right: Metric<"cardio.brachial_pressure.right">[];
  },
  toePressure: {
    left: Metric<"cardio.toe_pressure.left">[];
    right: Metric<"cardio.toe_pressure.right">[];
  }
) {
  const B = brachialPressure;
  const T = toePressure;

  const latestB = [...B.left.slice(0, 1), ...B.right.slice(0, 1)];
  const latestT = [...T.left.slice(0, 1), ...T.right.slice(0, 1)];

  const highestB = latestB.sort(bySystolic("desc")).at(0);
  const lowestT = latestT.sort(byPressure("asc")).at(0);

  if (!highestB || !lowestT) {
    return;
  }

  return TBI.calculate({
    highestBrachialPressure: {
      systolic: {
        mmHg: highestB.unit.systolic.mmHg,
      },
    },
    lowestToePressure: {
      mmHg: lowestT.unit.mmHg,
    },
  }).tbi;
}
