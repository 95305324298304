export type VadData = {
  active: boolean;
  meanRms: number;
};

export class VoiceActivityDetector {
  private audioContext: AudioContext = new window.AudioContext();
  source: MediaStreamAudioSourceNode;
  processor: AudioWorkletNode | undefined;

  constructor(
    private readonly stream: MediaStream,
    private readonly cb: (vadData: VadData) => void
  ) {
    this.source = this.audioContext.createMediaStreamSource(this.stream);
  }

  async initialize() {
    try {
      await this.audioContext.audioWorklet.addModule(
        "/worklets/vad-processing.js"
      );
    } catch (err) {
      console.error("Error while adding vad-processing module: ", err);
    }
    this.start();
  }

  stop() {
    if (this.processor && this.source) {
      this.processor.disconnect();
      this.source.disconnect();
    }
  }

  start() {
    const processor = new AudioWorkletNode(this.audioContext, "vad-processor");
    this.source.connect(processor);
    processor.connect(this.audioContext.destination);
    processor.port.onmessage = (event) => {
      this.cb(event.data);
    };
    this.processor = processor;
  }
}
