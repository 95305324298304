import { fromAPI, VisitSummary } from "@cur8/rich-entity";
import { toCreateRequest } from "lib/api/adapter/visit";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useVisitStore() {
  const { logError } = useReporting();

  const api = useAPIClient();

  const fetchVisit = useCallback(
    (patientId: string, summaryId: string) => {
      return api.visitSummary
        .fetchSummary({ patientId, summaryId })
        .result.then(fromAPI.toVisitSummary)
        .catch(logError);
    },
    [api, logError]
  );

  const createVisit = useCallback(
    (patientId: string, visit: VisitSummary) => {
      const payload = toCreateRequest(visit);
      return api.visitSummary
        .createSummary({ patientId }, payload)
        .result.then(fromAPI.toVisitSummary)
        .catch(logError);
    },
    [api, logError]
  );

  const updateVisit = useCallback(
    (patientId: string, summaryId: string, visit: VisitSummary) => {
      const payload = toCreateRequest(visit);
      return api.visitSummary
        .updateSummary({ patientId, summaryId }, payload)
        .result.then(fromAPI.toVisitSummary)
        .catch(logError);
    },
    [api, logError]
  );

  return { fetchVisit, createVisit, updateVisit };
}
