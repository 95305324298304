import { Path } from "@pomle/paths";
import { codecs } from "render/routes/codec";

export function createPaths<T extends {}>(origin: Path<T>) {
  const overview = origin.append("/recording", {});

  const detail = overview.append("/:deviceId/:recordingId", {
    recordingId: codecs.string,
    deviceId: codecs.string,
  });
  const atlas = detail.append("/atlas", {});

  const tissue = detail.append("/tissue", {});

  return {
    overview,
    detail,
    atlas,
    tissue,
  };
}
