import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import CreateBookingTokenPopup from "../CreateBookingTokenPopup";

export function useCreateBookingTokenPopup() {
  const { create } = usePopupDialog();

  const emitDialog = useCallback(
    (patientId: string, onSuccess: () => void) => {
      const { emit, close } = create();
      emit(
        <CreateBookingTokenPopup
          patientId={patientId}
          onClose={close}
          onSuccess={onSuccess}
        />
      );
    },
    [create]
  );

  return {
    emitDialog,
  };
}
