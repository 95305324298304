import { useContext } from "react";
import { Context } from "./context";

export function useDashboardContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useDashboardContext without DashboardContext");
  }
  return context;
}
