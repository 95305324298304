import { Risk } from "@cur8/health-risks-calc";
import { Unit } from "@cur8/measurements";
import { Patient, Sex } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import {
  AggregateEntry,
  GripRecord,
  MergedEntry,
  MergedRecord,
} from "lib/doctor-scribe/types";
import { Deviation } from "lib/health-risk";
import { DateTime } from "luxon";
import { femaleGripData } from "./grip_data/female";
import { maleGripData } from "./grip_data/male";
import { removeEntries } from "./resultHelpers";

export function addCombinedGripStrength(
  patient: Patient,
  record: MergedRecord
): MergedRecord {
  const age = patientAge(patient, DateTime.now());

  const leftRecords = record["body.grip_strength.left"];
  const rightRecords = record["body.grip_strength.right"];

  if (
    !leftRecords ||
    !rightRecords ||
    Object.keys(leftRecords).length !== Object.keys(rightRecords).length
  ) {
    //console.warn("unequal number of left- and right grip records!");
    return record;
  }

  const data: GripRecord[] =
    patient.sex === Sex.Male ? maleGripData : femaleGripData;

  const ageData = data.find((chunk) => {
    if (chunk.age.from > age) {
      return false;
    }
    if (chunk.age.to < age) {
      return false;
    }
    return true;
  });

  if (!ageData) {
    return record;
  }

  const newRecords: AggregateEntry[] = [];

  for (let i = 0; i < Object.keys(leftRecords).length; i++) {
    const leftGrip = (leftRecords[i].value as Unit<"body.grip_strength.left">)
      .kilograms;
    const rightGrip = (
      rightRecords[i].value as Unit<"body.grip_strength.right">
    ).kilograms;

    const maxGrip = Math.max(rightGrip, leftGrip);
    const diff = Math.abs(rightGrip - leftGrip);

    let risk = Risk.Normal;
    let deviation = Deviation.None;

    if (maxGrip > ageData.right.average + ageData.right.std) {
      deviation = Deviation.Above;
    } else if (maxGrip < ageData.right.average - ageData.right.std) {
      deviation = Deviation.Below;
      risk = Risk.Risk;
    }

    const largeDiff = diff > ageData.diff.average + ageData.diff.std;

    if (largeDiff) {
      risk = Risk.Risk;
      newRecords.push({
        timestamp: leftRecords[i].timestamp,
        value: {
          maxStrength: { kilograms: maxGrip },
          leftRightDifference: { kilograms: diff },
        },
        risk,
        deviation,
      });
    } else {
      newRecords.push({
        timestamp: leftRecords[i].timestamp,
        value: {
          maxStrength: { kilograms: maxGrip },
        },
        risk,
        deviation,
      });
    }
  }

  record["body.grip_strength"] = newRecords as MergedEntry[];

  return removeEntries(record, [
    "body.grip_strength.left",
    "body.grip_strength.right",
  ]);
}
