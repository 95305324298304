import { NormalizedSignal } from "../../hooks/useNormalizedSignal";

export type Range = [number, number];

export type PlotData = {
  signal: NormalizedSignal;
  label: string;
  color: string;
};

export enum PulsewaveType {
  lveti = "lveti",
  pulseWaveVelocity = "pulseWaveVelocity",
  handAsymmetry = "handAsymmetry",
  footAsymmetry = "footAsymmetry",
}

export const PulsewaveTypes = new Map<PulsewaveType, string>([
  [PulsewaveType.lveti, "lveti"],
  [PulsewaveType.pulseWaveVelocity, "pulseWaveVelocity"],
  [PulsewaveType.handAsymmetry, "handAsymmetry"],
  [PulsewaveType.footAsymmetry, "footAsymmetry"],
]);
