import { Sex } from "@cur8/rich-entity";
import { Typography } from "render/ui/presentation/Typography";
import { BloodPressureType } from "../../lib/projection";
import BenchmarkResultDescription from "../BenchmarkResultDescription";
import styles from "./styles.module.sass";

interface HeaderProps {
  title: string;
  subtitle: string;
  sex: Sex;
  age: number;
  currentValue: number;
  bloodPressureType: BloodPressureType;
}

export default function Header({
  title,
  sex,
  age,
  currentValue,
  bloodPressureType,
  subtitle,
}: HeaderProps) {
  return (
    <div className={styles.Header}>
      <div className={styles.titles}>
        <Typography as="h3" variant="title-s">
          {title}
        </Typography>
        <Typography as="span" variant="label-m" className={styles.aux}>
          [{subtitle}]
        </Typography>
      </div>
      <div className={styles.description}>
        <Typography variant="body-m">
          <BenchmarkResultDescription
            sex={sex}
            age={age}
            currentValue={currentValue}
            bloodPressureType={bloodPressureType}
          />
        </Typography>
      </div>
    </div>
  );
}
