import { BrachialPressure as BP } from "@cur8/measurements";

interface BrachialPressureProps {
  brachial?: BP;
}

export default function BrachialPressure({ brachial }: BrachialPressureProps) {
  return (
    <>
      {brachial?.systolic.mmHg ?? "--"}{" "}
      <sup>/ {brachial?.diastolic.mmHg ?? "--"}</sup>
    </>
  );
}
