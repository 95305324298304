import { Metric } from "lib/metric";
import { useCallback } from "react";
import { useMetricAPI } from "render/hooks/api/useMetricAPI";
import { toMetricList } from "lib/metric";

export type FractureMetrics = Partial<{
  fraxScore: Metric<"risk_assessment.frax_score">;
}>;

export function useFractureMetrics() {
  const { fetchMetrics, storeMetrics } = useMetricAPI();

  const fetchFractureMetrics = useCallback(
    async (patientId: string): Promise<FractureMetrics> => {
      const [frax] = await Promise.all([
        fetchMetrics(patientId, "risk_assessment.frax_score"),
      ]);

      return {
        fraxScore: frax.at(0),
      };
    },
    [fetchMetrics]
  );

  const updateFractureMetrics = useCallback(
    async (patientId: string, metrics: FractureMetrics) => {
      return storeMetrics(patientId, toMetricList(metrics));
    },

    [storeMetrics]
  );

  return {
    fetchFractureMetrics,
    updateFractureMetrics,
  };
}
