import { useMemo } from "react";
import { Metric } from "lib/metric";
import { MetricName } from "@cur8/measurements";

export function useMostRecentMetrics<M extends MetricName>(
  metrics: Record<string, Metric<M>[] | undefined>
): Metric<M>[] | undefined {
  return useMemo(() => {
    const filtered: Metric<M>[] = [];

    for (const metric of Object.values(metrics)) {
      if (metric == null) {
        return;
      }

      if (metric.length > 0) {
        filtered.push(metric[0]);
      }
    }

    return filtered;
    /* eslint-disable react-hooks/exhaustive-deps */
  }, Object.values(metrics));
}
