import { BloodPressure, BrachialPressure } from "@cur8/measurements";
import { createMetric } from "lib/metric";
import { CardioMetrics } from "./hooks/useCardioMetrics";

export type Entries = {
  ankleLeft: string;
  ankleRight: string;

  brachialLeft: string;
  brachialRight: string;

  bloodOxygen: string;

  toeLeft: string;
  toeRight: string;

  manualBrachial: string;
  manualFemoral: string;
};

function toSlashed(bp: BrachialPressure | BloodPressure) {
  return [bp.systolic.mmHg, bp.diastolic.mmHg].join("/");
}

export function toEntries(metrics: CardioMetrics): Entries {
  return {
    ankleLeft: metrics.ankle.left?.unit.mmHg.toString() ?? "",
    ankleRight: metrics.ankle.right?.unit.mmHg.toString() ?? "",

    brachialLeft: metrics.brachial.left
      ? toSlashed(metrics.brachial.left.unit)
      : "",
    brachialRight: metrics.brachial.right
      ? toSlashed(metrics.brachial.right.unit)
      : "",

    bloodOxygen: metrics.bloodOxygen?.unit.percent.toString() ?? "",

    toeLeft: metrics.toe.left?.unit.mmHg.toString() ?? "",
    toeRight: metrics.toe.right?.unit.mmHg.toString() ?? "",

    manualBrachial: metrics.manual.brachial
      ? toSlashed(metrics.manual.brachial.unit)
      : "",
    manualFemoral: metrics.manual.femoral
      ? toSlashed(metrics.manual.femoral.unit)
      : "",
  };
}

export function toMetrics(entries: Entries) {
  const metrics: CardioMetrics = {
    ankle: {},
    brachial: {},
    murmur: {},
    toe: {},
    manual: {},
  };

  {
    const value = parseFloat(entries.ankleLeft);
    if (isFinite(value)) {
      metrics.ankle.left = createMetric("cardio.ankle_pressure.left", {
        mmHg: value,
      });
    }
  }

  {
    const value = parseFloat(entries.ankleRight);
    if (isFinite(value)) {
      metrics.ankle.right = createMetric("cardio.ankle_pressure.right", {
        mmHg: value,
      });
    }
  }

  {
    const [sys, dia] = entries.brachialLeft.split("/").map(parseFloat);
    if (isFinite(sys) && isFinite(dia)) {
      metrics.brachial.left = createMetric("cardio.brachial_pressure.left", {
        systolic: {
          mmHg: sys,
        },
        diastolic: {
          mmHg: dia,
        },
      });
    }
  }

  {
    const [sys, dia] = entries.brachialRight.split("/").map(parseFloat);
    if (isFinite(sys) && isFinite(dia)) {
      metrics.brachial.right = createMetric("cardio.brachial_pressure.right", {
        systolic: {
          mmHg: sys,
        },
        diastolic: {
          mmHg: dia,
        },
      });
    }
  }

  {
    const value = parseFloat(entries.toeLeft);
    if (isFinite(value)) {
      metrics.toe.left = createMetric("cardio.toe_pressure.left", {
        mmHg: value,
      });
    }
  }

  {
    const value = parseFloat(entries.toeRight);
    if (isFinite(value)) {
      metrics.toe.right = createMetric("cardio.toe_pressure.right", {
        mmHg: value,
      });
    }
  }

  {
    const value = parseFloat(entries.bloodOxygen);
    if (isFinite(value)) {
      metrics.bloodOxygen = createMetric("cardio.oxygen_saturation", {
        percent: value,
      });
    }
  }

  {
    const [sys, dia] = entries.manualBrachial.split("/").map(parseFloat);
    if (isFinite(sys) && isFinite(dia)) {
      metrics.manual.brachial = createMetric("cardio.manual_bp.brachial", {
        systolic: {
          mmHg: sys,
        },
        diastolic: {
          mmHg: dia,
        },
      });
    }
  }

  {
    const [sys, dia] = entries.manualFemoral.split("/").map(parseFloat);
    if (isFinite(sys) && isFinite(dia)) {
      metrics.manual.femoral = createMetric("cardio.manual_bp.femoral", {
        systolic: {
          mmHg: sys,
        },
        diastolic: {
          mmHg: dia,
        },
      });
    }
  }

  return metrics;
}
