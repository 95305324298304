import { isPadAssessment, isPulseWaveAssessment } from "@cur8/rich-entity";
import { useAssessment } from "render/hooks/api/useAssessment";
import { usePatient } from "render/hooks/api/usePatient";
import PageFrame from "render/ui/layouts/PageFrame";
import AssessmentHeader from "./components/AssessmentHeader";
import PadAnnotationLoader from "./components/PadPage/PadAnnotationLoader";
import PulseWavePage from "./components/PulseWavePage";
import AssessmentContext from "./context/AssessmentContext";
import styles from "./styles.module.sass";

interface AssessmentPageProps {
  patientId: string;
  assessmentId: string;
  versionId: string;
}

export default function AssessmentPage({
  patientId,
  assessmentId,
  versionId,
}: AssessmentPageProps) {
  const patient = usePatient(patientId);

  const { selected, versions } = useAssessment(
    patientId,
    assessmentId,
    versionId
  );

  if (!selected && versions) {
    return (
      <PageFrame>
        <div className={styles.AssessmentPage}>
          <h1>Selected version not found</h1>
          <p>
            For: {patientId}
            <br />
            id: {assessmentId}, version: {versionId}
            <br />
          </p>
        </div>
      </PageFrame>
    );
  }

  if (!selected || !versions || !patient) {
    return (
      <PageFrame>
        <div className={styles.AssessmentPage}>
          <h1>Loading assessment</h1>
          <p>
            For: {patientId}
            <br />
            id: {assessmentId}
            <br />
          </p>
        </div>
      </PageFrame>
    );
  }

  return (
    <PageFrame>
      <div className={styles.AssessmentPage}>
        <header>
          <AssessmentHeader assessment={selected} patient={patient} />
        </header>

        <AssessmentContext
          assessments={versions}
          patient={patient}
          selected={selected}
        >
          {isPadAssessment(selected) && (
            <PadAnnotationLoader patient={patient} />
          )}
          {isPulseWaveAssessment(selected) && (
            <PulseWavePage assessment={selected} patient={patient} />
          )}
        </AssessmentContext>
      </div>
    </PageFrame>
  );
}
