import {
  BoundingBoxAnnotation,
  BoundingBoxDataType,
  Box,
} from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { Line, Point } from "lib/math";
import { useCallback, useEffect, useState } from "react";
import colors from "render/pages/TissuePages/colors.module.sass";
import { useTissueAnnotationContext } from "render/pages/TissuePages/context/TissueAnnotationContext";
import { useMessagePopup } from "render/pages/TissuePages/hooks/useMessagePopup";
import {
  CreateTissueAnnotation,
  ImageSize,
  TissueAnnotation,
} from "render/pages/TissuePages/lib/types";
import {
  checkRoIMaxSize,
  getColorName,
  roiWithinBoundry,
} from "render/pages/TissuePages/lib/utils";
import styles from "./styles.module.sass";

interface SvgRectProps {
  allowResize?: boolean;
  anno:
    | CreateTissueAnnotation<BoundingBoxDataType>
    | TissueAnnotation<BoundingBoxAnnotation>;
  box: Box;
  canvasSize: ImageSize;
  isSelected: boolean;
  scale: number;
}

export default function SvgRect({
  allowResize = true,
  anno,
  box,
  canvasSize,
  isSelected,
  scale,
}: SvgRectProps) {
  const { emitDialog } = useMessagePopup();
  const { move, select } = useTissueAnnotationContext();

  const [action, setAction] = useState<null | "resize" | "move">(null);
  const [movieBox, setMovieBox] = useState<Box>(box);
  useEffect(() => {
    setMovieBox(box);
  }, [box]);
  const [line, setLine] = useState<Line>();

  const [strokeWidth, setStrokeWidth] = useState<number>(2 / scale);
  useEffect(() => {
    if (isSelected) {
      setStrokeWidth(scale > 1 ? 4 : 3 / scale);
    } else {
      setStrokeWidth(2 / scale);
    }
  }, [isSelected, scale]);

  const movePointerDown = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1 || !isSelected || action) {
        return;
      }
      event.preventDefault();
      event.currentTarget.setPointerCapture(event.pointerId);
      setAction("move");
      setLine(
        new Line(
          new Point(movieBox.x, movieBox.y),
          new Point(movieBox.w, movieBox.h)
        )
      );
    },
    [action, isSelected, movieBox]
  );

  const movePointerMove = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1 || !isSelected || action !== "move") {
        return;
      }
      event.preventDefault();
      let bx = Math.round(movieBox.x + event.movementX / scale);
      let by = Math.round(movieBox.y + event.movementY / scale);
      const b = new Box(bx, by, movieBox.w, movieBox.h);
      if (roiWithinBoundry(b, canvasSize)) {
        setMovieBox(b);
      } else {
        emitDialog(
          "Can't move RoI",
          "The RoI seams to be out of bounds. It needs to be completely within the image."
        );
        setAction(null);
        setMovieBox(box);
      }
    },
    [
      action,
      box,
      canvasSize,
      emitDialog,
      isSelected,
      movieBox.h,
      movieBox.w,
      movieBox.x,
      movieBox.y,
      scale,
    ]
  );

  const movePointerUp = useCallback(
    (event: React.PointerEvent) => {
      event.currentTarget.releasePointerCapture(event.pointerId);
      if (!isSelected || !action) {
        return;
      }
      event.preventDefault();
      setAction(null);
      move(anno.annotation.id!, { rect: movieBox });
    },
    [action, isSelected, anno.annotation.id, move, movieBox]
  );

  const resizePointerDown = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1 || !isSelected || action) {
        return;
      }
      if (allowResize) {
        console.debug("Resize not allowed");
        return;
      }
      event.preventDefault();
      event.currentTarget.setPointerCapture(event.pointerId);
      setAction("resize");
      setLine(
        new Line(
          new Point(movieBox.x, movieBox.y),
          new Point(movieBox.x + movieBox.w, movieBox.y + movieBox.h)
        )
      );
    },
    [action, allowResize, isSelected, movieBox]
  );

  const resizePointerMove = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1 || !isSelected || action !== "resize" || !line) {
        return;
      }
      event.preventDefault();
      const b = new Point(
        Math.round(line.b.x + event.movementX / scale),
        Math.round(line.b.y + event.movementY / scale)
      );
      const nl = checkRoIMaxSize(line.a, b);
      if (roiWithinBoundry(nl.toBox(), canvasSize)) {
        setLine(nl);
        setMovieBox(nl.toBox());
      } else {
        emitDialog(
          "Can't resize RoI",
          "The RoI seams to be out of bounds. It needs to be completely within the image"
        );
        setAction(null);
        setMovieBox(box);
      }
    },
    [action, box, canvasSize, emitDialog, isSelected, line, scale]
  );

  const resizePointerUp = useCallback(
    (event: React.PointerEvent) => {
      event.currentTarget.releasePointerCapture(event.pointerId);
      if (!isSelected || !action) {
        return;
      }
      event.preventDefault();
      setAction(null);
      move(anno.annotation.id!, { rect: movieBox });
    },
    [action, isSelected, anno.annotation.id, move, movieBox]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (!isSelected) {
        event.preventDefault();
        select(anno.annotation.id);
      }
    },
    [anno.annotation.id, isSelected, select]
  );

  return (
    <g className={classNames(styles.roi, isSelected ? styles.selected : "")}>
      <rect
        className={classNames(colors.fill, colors.stroke)}
        data-color={getColorName(anno.color)}
        onClick={handleClick}
        onPointerDown={movePointerDown}
        onPointerMove={movePointerMove}
        onPointerUp={movePointerUp}
        x={movieBox.x}
        y={movieBox.y}
        width={movieBox.w}
        height={movieBox.h}
        strokeWidth={strokeWidth}
      />
      {allowResize && (
        <path
          className={colors.stroke}
          data-color={getColorName(anno.color)}
          onClick={handleClick}
          onPointerDown={resizePointerDown}
          onPointerMove={resizePointerMove}
          onPointerUp={resizePointerUp}
          d={`M ${movieBox.x + movieBox.w / 2} ${movieBox.y + movieBox.h} H ${
            movieBox.x + movieBox.w
          } V ${movieBox.y + movieBox.h / 2}`}
          strokeWidth={strokeWidth}
        />
      )}
    </g>
  );
}
