import { Patient } from "@cur8/rich-entity";
import { Interval } from "luxon";
import { Action } from "./actions";
import { CalendarEvent } from "./slotManagementUtils";

export interface State {
  mode: "editing" | "viewing" | "booking";
  viewRange: "week" | "day";
  selectedSlots: CalendarEvent[];
  selectedPatient?: Patient;
  censorAppointmens: boolean;
  selectedPeriod: Interval;
}

export const INITIAL_STATE: State = {
  mode: "viewing",
  viewRange: "week",
  censorAppointmens: false,
  selectedPeriod: Interval.fromDateTimes(new Date(), new Date()),
  selectedSlots: [],
  selectedPatient: undefined,
};

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_SELECTED_PERIOD":
      return { ...state, selectedPeriod: action.payload };

    case "ENTER_SLOT_EDITING_MODE":
      return { ...state, mode: "editing", selectedSlots: [] };
    case "ENTER_CALENDAR_VIEW_MODE":
      return { ...state, mode: "viewing", selectedSlots: [] };
    case "ENTER_CALENDAR_BOOKING_MODE":
      return { ...state, mode: "booking", selectedPatient: action.payload };

    case "SET_APPOINTMENTS_CENSORED":
      return { ...state, censorAppointmens: action.payload };

    case "ADD_SELECTED_SLOT":
      const slotAlreadySelected = state.selectedSlots.some((event) => {
        if (event.interval.equals(action.payload.interval)) {
          return true;
        }
        if (event.slot && event.slot.id === action.payload.slot?.id) {
          return true;
        }
        return false;
      });

      if (slotAlreadySelected) {
        return state;
      }

      return {
        ...state,
        selectedSlots: [...state.selectedSlots, action.payload],
      };
    case "REMOVE_SELECTED_SLOT":
      return {
        ...state,
        selectedSlots: state.selectedSlots.filter((event) => {
          if (event.interval.equals(action.payload.interval)) {
            return false;
          }
          if (event.slot && event.slot.id === action.payload.slot?.id) {
            return false;
          }
          return true;
        }),
      };

    case "RESET_SELECTED_SLOTS":
      return { ...state, selectedSlots: [] };

    default:
      throw Error(`Unknown action: ${action}`);
  }
};
