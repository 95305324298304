import { APIFetchError } from "@cur8/api-client";
import { UserURI } from "@cur8/uri";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "../useBlobImage";
import { useReporting } from "../useReporting";

export function useUserProfilePhoto(size: number, uri?: UserURI) {
  const { handleError } = useReporting();

  const api = useAPIClient().user;
  const [blob, setBlob] = useState<Blob>();
  const image = useBlobImage(blob);
  const userURI = uri?.toString();

  useEffect(() => {
    if (!userURI) {
      return;
    }

    const req = api.getUserProfilePhoto({ userUri: userURI, size });

    req.result
      .then((result) => result.blob())
      .then(setBlob)
      .catch((error) => {
        if (error instanceof APIFetchError && error.response.status === 404) {
          return;
        }
        handleError(error);
      });

    return () => {
      req.abandon();
      setBlob(undefined);
    };
  }, [api, handleError, userURI, size]);

  return image;
}
