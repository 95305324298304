import { Metric } from "lib/metric";
import { toBMI } from "lib/types/metrics/body";
import { useMemo } from "react";

export type BMIScore = {
  score: number;
};

type BMIScoreMetrics = {
  weight: Metric<"body.weight">[] | undefined;
  height: Metric<"body.height">[] | undefined;
};

export function useBMIScore({ weight: W, height: H }: BMIScoreMetrics) {
  return useMemo(() => {
    if (!W || !H) {
      return;
    }

    if (W.length === 0 || H.length === 0) {
      return {
        score: NaN,
      };
    }

    const score = toBMI(W[0], H[0]);

    return {
      score,
    };
  }, [W, H]);
}
