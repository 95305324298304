import { ImmutableScan } from "@cur8/rich-entity";
import { UserURI } from "@cur8/uri";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import { useUser } from "render/hooks/useUser";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { useCreateScanJournalNote } from "../../hooks/useCreateScanJournalNote";
import AssessmentAuthor from "../AssessmentAuthor";
import styles from "./styles.module.sass";

interface WriteAssessmentProps {
  onClose: () => void;
  scan: ImmutableScan;
}

export default function WriteAssessment({
  scan,
  onClose,
}: WriteAssessmentProps) {
  const user = useUser();
  const [assessmentContent, setAssessmentContent] = useState<string>("");

  const createJournalNote = useCreateScanJournalNote();

  const doSendAssessmentHandle = useAsyncHandle(() => {
    return createJournalNote(scan, { note: assessmentContent }).then(onClose);
  });

  const userUri = useMemo(
    () => new UserURI(user?.tenantId || "", user?.uniqueId || ""),
    [user]
  );

  return (
    <div className={styles.WriteAssessment}>
      <div className={styles.content}>
        <span>
          <h1>Write Assessment</h1>
          <h4>Please provide your skin assessment below</h4>
        </span>
        <textarea
          placeholder="Please add your comment here"
          value={assessmentContent}
          onChange={(e) => setAssessmentContent(e.target.value)}
          rows={15}
        />
        <span>
          <AssessmentAuthor date={DateTime.now()} userUri={userUri} />
        </span>
      </div>
      <div className={styles.footer}>
        <HoverTextButton onClick={onClose}>
          <span>Cancel</span>
        </HoverTextButton>
        <HoverTextButton
          active
          disabled={
            assessmentContent.trim().length === 0 || doSendAssessmentHandle.busy
          }
          onClick={doSendAssessmentHandle.callback}
        >
          <span>Save</span>
        </HoverTextButton>
      </div>
    </div>
  );
}
