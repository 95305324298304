import CloseIcon from "./assets/close.svg?react";
import styles from "./styles.module.sass";

export default function CloseButton({
  ...props
}: React.ButtonHTMLAttributes<any>) {
  return (
    <button type="button" className={styles.CloseButton} {...props}>
      <CloseIcon />
    </button>
  );
}
