import { isSide } from "./guard";
import { Side } from "./types";

export class ThermalImageURI {
  type = "flir";
  deviceId: string;
  recordingId: string;
  side: Side;
  cameraName: string;
  frameIndex: string;

  static fromString(uri: string) {
    const [type, deviceId, recordingId, side, cameraName, frameIndex] = uri
      .split(":")
      .map(decodeURIComponent);

    if (type !== "flir") {
      throw new Error("Not ThermalImageURI");
    }

    if (!isSide(side)) {
      throw new Error(`Invalid value for side: ${side}`);
    }

    return new ThermalImageURI(
      deviceId,
      recordingId,
      side,
      cameraName,
      frameIndex
    );
  }

  static parse(text: string) {
    const [type, deviceId, recordingId, side, cameraName, frameIndex] = text
      .split(":")
      .map(decodeURIComponent);

    if (type !== "flir") {
      return null;
    }

    if (!isSide(side)) {
      return null;
    }

    return new ThermalImageURI(
      deviceId,
      recordingId,
      side,
      cameraName,
      frameIndex
    );
  }

  constructor(
    deviceId: string,
    recordingId: string,
    side: Side,
    cameraName: string,
    frameIndex: string
  ) {
    this.type = "flir";
    this.deviceId = deviceId;
    this.recordingId = recordingId;
    this.side = side;
    this.cameraName = cameraName;
    this.frameIndex = frameIndex;
  }

  toString(): string {
    return [
      "flir",
      this.deviceId,
      this.recordingId,
      this.side,
      this.cameraName,
      this.frameIndex,
    ]
      .map(encodeURIComponent)
      .join(":");
  }
}
