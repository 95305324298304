import { useCallback } from "react";
import { useBookingStore } from "render/hooks/api/useBookingStore";
import { useReporting } from "render/hooks/useReporting";
import { CalendarEvent } from "render/pages/SchedulePage/reducer/slotManagementUtils";
import useFetchSlots from "./useFetchSlots";

export default function useSlotManagement() {
  const { logError } = useReporting();
  const { deleteSlot, freeSlot, reserveSlot, createSlot } = useBookingStore();
  const { refetchCurrentSlots } = useFetchSlots();

  const deleteSlots = useCallback(
    (slots: CalendarEvent[]) => {
      return Promise.all(
        slots.map((event) => {
          if (!event.slot) {
            return Promise.resolve();
          }

          return deleteSlot(event.slot);
        })
      )
        .catch(logError)
        .finally(refetchCurrentSlots);
    },
    [deleteSlot, logError, refetchCurrentSlots]
  );

  const openSlots = useCallback(
    (slots: CalendarEvent[]) => {
      return Promise.all(
        slots.map((event) => {
          if (!event.slot) {
            return createSlot(event, { isOpenPublicly: true });
          }

          return freeSlot(event.slot);
        })
      )
        .catch(logError)
        .finally(refetchCurrentSlots);
    },
    [freeSlot, createSlot, logError, refetchCurrentSlots]
  );

  const reserveSlots = useCallback(
    (slots: CalendarEvent[]) => {
      return Promise.all(
        slots.map((event) => {
          if (!event.slot) {
            return createSlot(event, { isOpenPublicly: false });
          }
          return reserveSlot(event.slot);
        })
      )
        .catch(logError)
        .finally(refetchCurrentSlots);
    },
    [logError, reserveSlot, refetchCurrentSlots, createSlot]
  );

  return {
    deleteSlots,
    openSlots,
    reserveSlots,
  };
}
