import { classNames } from "@pomle/classnames";
import { IconProps } from ".";
import styles from "./styles.module.sass";

export default function PauseIcon({ active, color }: IconProps) {
  return (
    <svg
      className={classNames(styles.Common, styles.PauseIcon)}
      data-active={active ? "yes" : "no"}
      data-color={color}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19" />
      <path d="M16 13v14m8-14v14" />
    </svg>
  );
}
