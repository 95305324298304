import { useEffect, useRef, useState } from "react";
import { useAvatarContext } from "render/pages/DashboardPage/context/AvatarContext";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "../../../../../../types";
import { useScene } from "./hooks/useScene";
import Overlays from "./overlays/Overlays";
import styles from "./styles.module.sass";
import CameraControl from "./world/CameraControl";
import PodiumMotion from "./world/PodiumMotion";
import PointCloud from "./world/PointCloud";
import RenderLayers from "./world/RenderLayers";
import Renderer from "./world/Renderer";

interface AvatarProps {
  layer: Layer;
}

export default function Avatar({ layer }: AvatarProps) {
  const { patient } = usePatientData();
  const { camera, podium } = useDashboardContext();

  const spatial = useAvatarContext();

  const areas = spatial.front.smpl.areas;

  const scene = useScene();

  useEffect(() => {
    scene.add(podium);

    return () => {
      scene.remove(podium);
    };
  }, [podium, scene]);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  useEffect(() => {
    setCanvas(canvasRef.current ?? undefined);
  }, []);

  return (
    <div className={styles.Avatar} data-layer={layer}>
      <canvas
        style={{
          width: "1920px",
          height: "1080px",
        }}
        ref={canvasRef}
      />

      {canvas && (
        <>
          <Renderer canvas={canvas} camera={camera} scene={scene} />

          {patient && areas && (
            <Overlays
              canvas={canvas}
              camera={camera}
              podium={podium}
              patient={patient}
              layer={layer}
              bodyAreas={areas}
            />
          )}
        </>
      )}

      <PointCloud layer={layer} podium={podium} scene={scene} />

      <PodiumMotion layer={layer} podium={podium} />

      <CameraControl camera={camera} layer={layer} areas={areas} />

      {areas && <RenderLayers layer={layer} podium={podium} areas={areas} />}
    </div>
  );
}
