import {
  EyePressure as EyePressureRisks,
  Risk,
  RiskRange,
} from "@cur8/health-risks-calc";
import { easeOutCubic } from "lib/ease";
import { Metric } from "lib/metric";
import { resolveHighestSide } from "lib/types/body";
import { useMemo } from "react";
import PrefixedNumber from "render/ui/format/PrefixedNumber";
import RampUpNumber from "render/ui/format/RampUpNumber";
import BarRangeGraph from "render/ui/presentation/BarRangeGraph";
import { PositionalNote } from "render/ui/presentation/BarRangeGraph/BarRangeGraph";
import { ConnectorHighlightToColorMap } from "render/ui/presentation/BarRangeGraph/colors";
import { RangeLineEntry } from "render/ui/presentation/RangeLine/lib";
import ValueItem from "render/ui/symbol/ValueItem";
import { EyePressureStatus, getEyePressureStatus } from "./balance";
import {
  DEFAULT_POINT_COLOR,
  RiskToColorMap,
  getHighlight,
  getMarkerVariant,
} from "./colors";
import styles from "./styles.module.sass";

function clamp(value: number) {
  return Math.max(0, Math.min(value, 28));
}

function toBarRangeRange(range: RiskRange) {
  const isFromFinite = isFinite(range.from);
  const isToFinite = isFinite(range.to);

  return {
    from: clamp(isFromFinite ? range.from : range.to * 0.6),
    to: clamp(isToFinite ? range.to : range.from * 1.1),
    color: RiskToColorMap[Risk.Unknown],
  };
}

function createPointNormalizer(
  riskRanges: ReturnType<typeof EyePressureRisks.rangesFor>,
  status?: EyePressureStatus,
  latestValue?: number,
  previousValue?: number
) {
  return function (
    point:
      | Metric<"risk_assessment.eye_pressure.left">
      | Metric<"risk_assessment.eye_pressure.right">
  ) {
    const value = point.unit.mmHg;
    const risk = riskRanges.findRisk(point.unit);

    return {
      color: DEFAULT_POINT_COLOR,
      value: clamp(value),
      previousValue: value !== previousValue ? previousValue : undefined,
      title: point.unit.mmHg.toString(),
      variant: getMarkerVariant({
        areValuesEqual: latestValue === previousValue,
        isLatestValue: value === latestValue,
      }),
      highlight: getHighlight({
        isUnbalanced: status === "unbalanced",
        risk,
      }),
    };
  };
}

function createRangeNormalizer(
  value: number | undefined,
  riskForValue?: Risk,
  status?: EyePressureStatus
) {
  return function (range: ReturnType<typeof toBarRangeRange>): RangeLineEntry {
    if (!value) {
      return {
        from: {
          value: range.from,
          label: range.from.toString(),
        },
        to: {
          value: range.to,
          label: range.to.toString(),
        },
        color: range.color,
      };
    }

    if (status === "unbalanced") {
      return {
        from: {
          value: range.from,
          label: range.from.toString(),
        },
        to: {
          value: range.to,
          label: range.to.toString(),
        },
        color: RiskToColorMap[Risk.Risk],
      };
    }

    const isActive = value >= range.from && value <= range.to;

    return {
      from: {
        value: range.from,
        label: range.from.toString(),
      },
      to: {
        value: range.to,
        label: range.to.toString(),
      },
      color:
        isActive && riskForValue ? RiskToColorMap[riskForValue] : range.color,
    };
  };
}

interface EyePressureProps {
  eyePressure: {
    left: Metric<"risk_assessment.eye_pressure.left">[] | undefined;
    right: Metric<"risk_assessment.eye_pressure.right">[] | undefined;
  };
}

export default function EyePressureGraph({ eyePressure }: EyePressureProps) {
  const { left, right } = eyePressure;

  const status = getEyePressureStatus({
    current: { left: left?.at(0), right: right?.at(0) },
  });

  const thresholds: PositionalNote[] = useMemo(() => {
    const topThresholdValue = status === "high" ? 28 : 24;
    return [
      {
        value: topThresholdValue,
        label: topThresholdValue.toString(),
        content: "",
        highlight: "warning",
      },
      { value: 8, label: "8", content: "" },
    ];
  }, [status]);

  const entries = useMemo(() => {
    const latestLeft = left?.at(0)?.unit.mmHg;
    const previousLeft = left?.at(1)?.unit.mmHg;
    const latestRight = right?.at(0)?.unit.mmHg;
    const previousRight = right?.at(1)?.unit.mmHg;
    const higherSide = resolveHighestSide(latestLeft, latestRight);

    let leftOffset = undefined;
    let rightOffset = undefined;

    if (latestLeft !== undefined && previousLeft !== undefined) {
      leftOffset = latestLeft - previousLeft;
    }

    if (latestRight !== undefined && previousRight !== undefined) {
      rightOffset = latestRight - previousRight;
    }

    const riskRanges = EyePressureRisks.rangesFor();
    const ranges = riskRanges.entries.slice(0, 3).map(toBarRangeRange);
    const toLeftPoint = createPointNormalizer(
      riskRanges,
      status,
      latestLeft,
      previousLeft
    );
    const toRightPoint = createPointNormalizer(
      riskRanges,
      status,
      latestRight,
      previousRight
    );

    const normalizeLeftRange = createRangeNormalizer(
      latestLeft,
      latestLeft !== undefined
        ? riskRanges.findRisk({ mmHg: latestLeft })
        : undefined,
      status
    );
    const normalizeRightRange = createRangeNormalizer(
      latestRight,
      latestRight !== undefined
        ? riskRanges.findRisk({ mmHg: latestRight })
        : undefined,
      status
    );

    const leftPoints = left ? left.slice(0, 2).map(toLeftPoint) : [];
    const leftRanges = ranges.map(normalizeLeftRange);

    const rightPoints = right ? right.slice(0, 2).map(toRightPoint) : [];
    const rightRanges = ranges.map(normalizeRightRange);

    return [
      {
        ranges: leftRanges,
        points: leftPoints,
        label: (
          <div className={styles.label}>
            <ValueItem
              value={
                <span
                  className={styles.offsetNumber}
                  data-bold={higherSide === "left"}
                >
                  {latestLeft !== undefined && isFinite(latestLeft) ? (
                    <RampUpNumber
                      value={latestLeft}
                      duration={2}
                      delay={1}
                      ease={easeOutCubic}
                    />
                  ) : (
                    "--"
                  )}
                </span>
              }
              unit={
                <div className={styles.offsetContainer}>
                  {higherSide === "left" &&
                  leftOffset !== undefined &&
                  leftOffset !== 0 ? (
                    <PrefixedNumber value={leftOffset} precision={1} />
                  ) : null}
                  <span>L</span>
                </div>
              }
            />
          </div>
        ),
      },
      {
        ranges: rightRanges,
        points: rightPoints,
        label: (
          <span className={styles.label}>
            <ValueItem
              value={
                <span
                  className={styles.offsetNumber}
                  data-bold={higherSide === "right"}
                >
                  {latestRight !== undefined && isFinite(latestRight) ? (
                    <RampUpNumber
                      value={latestRight}
                      duration={2}
                      delay={1}
                      ease={easeOutCubic}
                    />
                  ) : (
                    "--"
                  )}
                </span>
              }
              unit={
                <div className={styles.offsetContainer}>
                  {higherSide === "right" &&
                  rightOffset !== undefined &&
                  rightOffset !== 0 ? (
                    <PrefixedNumber value={rightOffset} precision={1} />
                  ) : null}
                  <span>R</span>
                </div>
              }
            />
          </span>
        ),
      },
    ];
  }, [left, right, status]);

  return (
    <div className={styles.EyePressure}>
      <BarRangeGraph entries={entries} thresholds={thresholds}>
        {({ bounds }) => {
          const latestLeft = left?.at(0)?.unit.mmHg;
          const latestRight = right?.at(0)?.unit.mmHg;

          if (!latestLeft || !latestRight || status !== "unbalanced") {
            return null;
          }

          const from = bounds.lerp(latestLeft) * 100;
          const to = bounds.lerp(latestRight) * 100;

          return (
            <svg
              className={styles.svg}
              strokeDasharray="2,3"
              viewBox="0 0 100 100"
            >
              <path
                d={`M5 ${100 - from}, 95 ${100 - to}`}
                stroke={ConnectorHighlightToColorMap["warning"]}
                fill="tranparent"
              />
            </svg>
          );
        }}
      </BarRangeGraph>
    </div>
  );
}
