import { APITypesV1 } from "@cur8/api-client";
import { useCallback } from "react";
import CheckSvg from "render/assets/svg/check.svg?react";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import AssessmentHandler from "../AssessmentHandler";
import { getAverageValue, printFirstValue } from "./lib/utils";
import styles from "./styles.module.sass";

export default function AssessmentSidebox() {
  const { createAssessment } = useAssessmentContext();
  const pwa = createAssessment as APITypesV1.CreatePulseWaveAssessmentRequest;

  const hasAllMeasurements = useCallback(() => {
    if (
      pwa.lveti &&
      pwa.pulseWaveVelocity &&
      pwa.footAsymmetry &&
      pwa.handAsymmetry
    ) {
      return true;
    }
    return false;
  }, [pwa.footAsymmetry, pwa.handAsymmetry, pwa.lveti, pwa.pulseWaveVelocity]);

  return (
    <div className={styles.AssessmentSidebox}>
      <h3>Measurements</h3>
      <div className={styles.tabledata}>
        <div>{pwa.lveti && <CheckSvg />}</div>
        <label>Ejection Time</label>
        <div className={styles.valueItem}>
          <span className={styles.value}>{getAverageValue(pwa.lveti)}</span>
          <span className={styles.unit}>{pwa.lveti && "ms"}</span>
        </div>
        <div>{pwa.pulseWaveVelocity && <CheckSvg />}</div>
        <label>Pulsewave Velocity</label>
        <div className={styles.valueItem}>
          <span className={styles.value}>
            {printFirstValue(pwa.pulseWaveVelocity)}
          </span>
          <span className={styles.unit}>{pwa.pulseWaveVelocity && "m/s"}</span>
        </div>
        <div>{pwa.handAsymmetry && <CheckSvg />}</div>
        <label>Hand Asymmetry</label>
        <div className={styles.valueItem}>
          <span className={styles.value}>
            {printFirstValue(pwa.handAsymmetry)}
          </span>
          <span className={styles.unit}>{pwa.handAsymmetry && "ms"}</span>
        </div>
        <div>{pwa.footAsymmetry && <CheckSvg />}</div>
        <label>Foot Asymmetry</label>
        <div className={styles.valueItem}>
          <span className={styles.value}>
            {printFirstValue(pwa.footAsymmetry)}
          </span>
          <span className={styles.unit}>{pwa.footAsymmetry && "ms"}</span>
        </div>
      </div>

      <AssessmentHandler />
      {!hasAllMeasurements() && (
        <div className={styles.disclaimer}>
          You need to make all measurements before you can save the assessment.
        </div>
      )}
    </div>
  );
}
