import { APITypesV1, Side } from "@cur8/api-client";
import { codecs as bundledCodecs, createCodec } from "@pomle/paths";
import { isSide, isSortOrder } from "lib/api/guard";
import { DateTime } from "luxon";

const DATE_FORMAT = "yyyy-LL-dd't'HH.mm.ssZZZ";

export const codecs = {
  ...bundledCodecs,

  date: createCodec(
    (source: DateTime) => source.toFormat(DATE_FORMAT).replace("+", "z"),
    (source: string) => {
      const date = DateTime.fromFormat(source.replace("z", "+"), DATE_FORMAT);
      if (date.isValid) {
        return date;
      }
      return DateTime.fromISO(source);
    }
  ),

  side: createCodec<Side>(
    (source) => {
      return source;
    },
    (source: string) => {
      if (!isSide(source)) {
        throw new Error(`Invalid side param: ${source}`);
      }
      return source;
    }
  ),

  sortOrder: createCodec<APITypesV1.SortOrder>(
    (source: APITypesV1.SortOrder) => source.toString(),
    (source: string) => {
      if (!isSortOrder(source)) {
        throw new Error(`Invalid sort param: ${source}`);
      }
      return source;
    }
  ),
};
