import { ImmutableScan } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useReporting } from "render/hooks/useReporting";
import { useConfirmationModal } from "render/ui/layouts/ConfirmationModal";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { useDeleteScanJournalNote } from "../../hooks/useDeleteScanJournalNote";
import { useScanJournalNoteQuery } from "../../hooks/useScanJournalNoteQuery";
import AssessmentAuthor from "../AssessmentAuthor";
import styles from "./styles.module.sass";

interface ViewAssessmentProps {
  onClose: () => void;
  scan: ImmutableScan;
}

export default function ViewAssessment({ onClose, scan }: ViewAssessmentProps) {
  const { handleError } = useReporting();
  const { emitConfirmationDialog } = useConfirmationModal();
  const { data: journalNote } = useScanJournalNoteQuery(scan);
  const deleteScanJournalNote = useDeleteScanJournalNote();

  const handleDelete = useCallback(() => {
    deleteScanJournalNote(scan).then(onClose).catch(handleError);
  }, [deleteScanJournalNote, handleError, onClose, scan]);

  const handleConfirmDeletion = useCallback(
    () =>
      emitConfirmationDialog({
        body: "Are you sure you want to delete this assessment?",
        onConfirm: handleDelete,
        title: "Delete Assessment",
        confirmButtonLabel: "Delete",
      }),
    [emitConfirmationDialog, handleDelete]
  );

  if (!scan) {
    return <>Loading</>;
  }

  const createdAuditInfo = journalNote?.auditInformation.created;

  return (
    <div className={styles.ViewAssessment}>
      <div className={styles.content}>
        <span>
          <h1>Skin Assessment</h1>
        </span>
        <div>{journalNote?.note}</div>
        <span>
          {createdAuditInfo?.timeStamp && (
            <AssessmentAuthor
              date={createdAuditInfo?.timeStamp}
              userUri={createdAuditInfo.userUri}
            />
          )}
        </span>
      </div>
      <div className={styles.footer}>
        <HoverTextButton onClick={onClose}>
          <span>Cancel</span>
        </HoverTextButton>
        <HoverTextButton variant="danger" onClick={handleConfirmDeletion}>
          <span>Delete</span>
        </HoverTextButton>
      </div>
    </div>
  );
}
