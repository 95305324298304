import React from "react";
import { Box, Point } from "lib/math";

export type Area = {
  id: string;
  physicalArtefactId: string;
  bounds: Box;
  content: React.ReactNode;
};

export enum Layout {
  Fit,
  Max,
}

export enum Interaction {
  Orient = "orient",
  Tag = "tag",
}

export type InteractionState = {
  layout: Layout;
  scale: number;
  pan: Point;
};
