import { ScheduleViewSlot } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { DateTime, Interval } from "luxon";
import { useCallback, useMemo } from "react";
import { CalendarEvent } from "render/pages/SchedulePage/reducer/slotManagementUtils";
import useCalendarState from "../../hooks/useCalendarState";
import PossibleSlot from "./components/PossibleSlot";
import styles from "./styles.module.sass";

interface Props {
  closingHour: DateTime;
  openingHour: DateTime;
  slots: ScheduleViewSlot[] | undefined;
  possibleSlots: [Interval, CalendarEvent[]][];
}

export default function PossibleSlotsCreationLayer({
  possibleSlots,
  slots,
  closingHour,
  openingHour,
}: Props) {
  const isToday = useCallback((date: DateTime) => {
    return date.toISODate() === DateTime.now().toISODate();
  }, []);

  const { calendarInteractionMode, bulkSelectedSlots } = useCalendarState();

  const slotsSet = useMemo(() => {
    const result = new Set<number>();

    if (slots) {
      slots.forEach((slot) => {
        result.add(slot.startTime.toUnixInteger());
      });
    }

    return result;
  }, [slots]);

  const takenIntervals = useMemo(() => {
    const result: Interval[] = [];

    if (slots) {
      result.push(
        ...slots.map((slot) =>
          Interval.fromDateTimes(slot.startTime, slot.endTime)
        )
      );
    }
    result.push(
      ...bulkSelectedSlots.map(
        (bulkSelectedSlots) => bulkSelectedSlots.interval
      )
    );

    return result;
  }, [bulkSelectedSlots, slots]);

  const isOverlapping = useCallback(
    (target: CalendarEvent) => {
      const isTarget = (interval: Interval) => {
        return interval.start.equals(target.interval.start);
      };
      const doOverlap = (interval: Interval) => {
        const overlap = (a: Interval, b: Interval) => {
          return a.start < b.end && b.start < a.end;
        };

        if (!overlap(target.interval, interval)) {
          return false;
        }
        // we are overlapping here !
        // we need to know how much of an overlap we have
        // if it's less than 30 minutes, then we are overlapping

        const diff = Math.abs(
          interval.start.diff(target.interval.start, "minutes").minutes
        );

        return diff < 30;
      };

      const result = !!takenIntervals
        .filter((intervals) => !isTarget(intervals))
        .find(doOverlap);

      return result;
    },
    [takenIntervals]
  );

  const isSlotSelectable = useCallback(
    (event: CalendarEvent) => {
      if (event.interval.start.hour < openingHour.hour) {
        return false;
      }

      if (event.interval.end.hour === closingHour.hour) {
        if (event.interval.end.minute > closingHour.minute) {
          return false;
        }
      }

      if (event.interval.end.hour > closingHour.hour) {
        return false;
      }

      if (!slots) {
        return false;
      }

      const key = event.interval.start.toUnixInteger();
      const slotExists = slotsSet.has(key);
      if (slotExists) {
        return false;
      }

      return true;
    },
    [slotsSet, openingHour, closingHour, slots]
  );

  const isSlotSelected = useCallback(
    (timeWindow: CalendarEvent) => {
      return bulkSelectedSlots.some((event) => {
        return event.interval.start.equals(timeWindow.interval.start);
      });
    },
    [bulkSelectedSlots]
  );

  const isSlotInteractive = useCallback(
    (event: CalendarEvent) => {
      switch (calendarInteractionMode) {
        case "editing":
          return isSlotSelectable(event) && !isOverlapping(event);
        default:
          return false;
      }
    },
    [calendarInteractionMode, isSlotSelectable, isOverlapping]
  );

  return (
    <>
      {possibleSlots.map(([day, hourSlots]) => {
        return (
          <div
            key={day.start.toUnixInteger()}
            className={classNames(styles.backgroundColumn, {
              [styles.isToday]: isToday(day.start),
            })}
          >
            {hourSlots.map((calendarEvent) => {
              const fifteenMinuteSlot = {
                ...calendarEvent,
                interval: Interval.fromDateTimes(
                  calendarEvent.interval.start.plus({ minute: 15 }),
                  calendarEvent.interval.end.plus({ minute: 15 })
                ),
              };
              const halfHourSlot = {
                ...calendarEvent,
                interval: Interval.fromDateTimes(
                  calendarEvent.interval.start.plus({ minute: 30 }),
                  calendarEvent.interval.end.plus({ minute: 30 })
                ),
              };
              const fortyFiveMinuteSlot = {
                ...calendarEvent,
                interval: Interval.fromDateTimes(
                  calendarEvent.interval.start.plus({ minute: 45 }),
                  calendarEvent.interval.end.plus({ minute: 45 })
                ),
              };

              return (
                <div
                  key={calendarEvent.interval.start.toUnixInteger()}
                  className={styles.backgroundSlot}
                >
                  <div
                    data-interactive={
                      isSlotInteractive(calendarEvent) &&
                      !isOverlapping(calendarEvent)
                    }
                    className={styles.backgroundSlotItem}
                  >
                    <PossibleSlot
                      calendarEvent={calendarEvent}
                      isSelected={isSlotSelected(calendarEvent)}
                      isInteractive={
                        isSlotInteractive(calendarEvent) &&
                        !isOverlapping(calendarEvent)
                      }
                    />
                  </div>
                  <div
                    data-interactive={false}
                    className={styles.backgroundSlotItem}
                  ></div>

                  <div
                    data-interactive={isSlotInteractive(fifteenMinuteSlot)}
                    className={styles.backgroundSlotItem}
                  >
                    <PossibleSlot
                      calendarEvent={fifteenMinuteSlot}
                      isSelected={isSlotSelected(fifteenMinuteSlot)}
                      isInteractive={isSlotInteractive(fifteenMinuteSlot)}
                    />
                  </div>
                  <div
                    data-interactive={false}
                    className={styles.backgroundSlotItem}
                  ></div>

                  <div
                    data-interactive={false}
                    className={styles.backgroundSlotItem}
                  ></div>
                  <div
                    data-interactive={isSlotInteractive(calendarEvent)}
                    className={styles.backgroundSlotItem}
                  >
                    <PossibleSlot
                      calendarEvent={halfHourSlot}
                      isSelected={isSlotSelected(halfHourSlot)}
                      isInteractive={isSlotInteractive(halfHourSlot)}
                    />
                  </div>

                  <div
                    data-interactive={false}
                    className={styles.backgroundSlotItem}
                  ></div>
                  <div
                    data-interactive={isSlotInteractive(calendarEvent)}
                    className={styles.backgroundSlotItem}
                  >
                    <PossibleSlot
                      calendarEvent={fortyFiveMinuteSlot}
                      isSelected={isSlotSelected(fortyFiveMinuteSlot)}
                      isInteractive={isSlotInteractive(fortyFiveMinuteSlot)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}
