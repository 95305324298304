import { Path } from "@pomle/paths";

export function createPaths<T extends {}>(origin: Path<T>) {
  const regular = origin.append("/tissue", {});
  const mind = regular.append("/mind", {});
  const pad = regular.append("/pad", {});

  return {
    regular,
    mind,
    pad,
  };
}
