type Unit = "mmHg" | "kilograms" | "meters" | "psi" | "feet" | "lbs";

export type UnitSystem = "metric" | "imperial";

export class UnitConverter<OutputUnit extends Unit = Unit> {
  unit: OutputUnit;

  constructor({ unit }: { unit: OutputUnit }) {
    this.unit = unit;
  }
}
