import PageFrame from "render/ui/layouts/PageFrame";
import PageContent from "render/ui/layouts/PageContent";

interface PageFrameProps {
  children: React.ReactNode;
}

export default function FramedPage({ children }: PageFrameProps) {
  return (
    <PageFrame>
      <PageContent>{children}</PageContent>
    </PageFrame>
  );
}
