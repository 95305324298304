import { Patient, Sex } from "@cur8/rich-entity";
import { useEffect, useState } from "react";
import { useRangeContext } from "../../context/RangeContext";
import { CardioSignals } from "../../hooks/useCardioSignals";
import GraphWithRuler from "../shared/GraphWithRuler";
import { ECG_COLOR, SSN_COLOR } from "../shared/constants";
import { JSXResult } from "../shared/fragments";
import { rangeToDistance } from "../shared/utils";

interface EjectionTimeProps {
  patient: Patient | undefined;
  signals: CardioSignals | undefined;
}

export default function EjectionTime({ patient, signals }: EjectionTimeProps) {
  const { selectionRange, setMeasurement, windowRange } = useRangeContext();
  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure LVETI")
  );

  useEffect(() => {
    if (
      !signals ||
      !selectionRange ||
      selectionRange[0] >= selectionRange[1] ||
      !patient
    ) {
      setResultElement(
        JSXResult("", "Make a selection in the plot to measure LVETI")
      );
      return;
    }
    const distanceMs = rangeToDistance(
      windowRange,
      selectionRange,
      signals.ecg.lengthMs
    );

    if (!signals || !signals.heart_rate || !signals.heart_rate.heartRate) {
      setResultElement(
        JSXResult(
          "Cannot compute LVETI",
          "No average heart rate computed",
          `LVET: ${distanceMs} ms`
        )
      );
      return;
    }
    const HR = signals.heart_rate.heartRate;

    if (patient.sex === Sex.Unknown) {
      setResultElement(
        JSXResult(
          "Cannot compute LVETI",
          "Member's sex not specified",
          `LVET: ${distanceMs} ms`
        )
      );
      return;
    }
    const lveti = Math.round(
      patient?.sex === Sex.Female
        ? 1.51 * HR + distanceMs
        : 1.38 * HR + distanceMs
    );
    setMeasurement(lveti);

    setResultElement(
      JSXResult(
        `LVETI: ${lveti} ms`,
        `${Sex[patient.sex]}, average heart rate: ${Math.round(
          HR
        )} bpm, LVET: ${distanceMs} ms`
      )
    );
  }, [
    patient,
    selectionRange,
    setMeasurement,
    signals,
    signals?.heart_rate?.heartRate,
    windowRange,
  ]);

  return signals ? (
    <div>
      <GraphWithRuler
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.ecg,
            color: ECG_COLOR,
          },
          { label: "SSN", signal: signals.ssn, color: SSN_COLOR },
        ]}
        diffData={[
          {
            label: "SSN Acceleration",
            signal: signals.ssn_acc,
            color: SSN_COLOR,
          },
        ]}
      />
      {resultElement}
    </div>
  ) : null;
}
