import { APITypesV1 } from "@cur8/api-client";
import React from "react";

const STATE_MAP: Record<APITypesV1.ScanState, React.ReactNode> = {
  [APITypesV1.ScanState.Available]: "Available",
  [APITypesV1.ScanState.Failed]: "Failed",
  [APITypesV1.ScanState.Inprogress]: "In Progress",
  [APITypesV1.ScanState.Finished]: "Finished",
};

interface ScanStateProps {
  state: APITypesV1.ScanState;
}

export default function ScanState({ state }: ScanStateProps) {
  return <>{STATE_MAP[state]}</>;
}
