import { Box, Line } from "lib/math";

export function lineToBox(line: Line): Box {
  const { a, b } = line;

  const maxX = Math.max(a.x, b.x);
  const maxY = Math.max(a.y, b.y);
  const minX = Math.min(a.x, b.x);
  const minY = Math.min(a.y, b.y);

  const x = minX;
  const y = minY;
  const w = maxX - minX;
  const h = maxY - minY;

  return new Box(x, y, w, h);
}
