import { MetricName } from "@cur8/measurements";
import { toMetricList } from "lib/metric";
import { RiskAssessment } from "lib/types/metrics/risk-assessment";
import { useCallback } from "react";
import { useMetricAPI } from "../useMetricAPI";

export function useRiskAssessmentStore() {
  const { fetchMetrics, storeMetrics } = useMetricAPI();

  const fetchRiskAssessment = useCallback(
    async (patientId: string) => {
      async function fetchMetric<M extends MetricName>(name: M) {
        return (await fetchMetrics(patientId, name, 1))[0];
      }

      // Split in due to https://github.com/microsoft/TypeScript/issues/11924
      const [
        congestiveHeartFailureHistory,
        diabetesHistory,
        drugInducedBleeding,
        hypertensionHistory,
        kidneyDisease,
        preDiabetes,
      ] = await Promise.all([
        fetchMetric("risk_assessment.congestive_heart_failure_history"),
        fetchMetric("risk_assessment.diabetes_history"),
        fetchMetric("risk_assessment.drug-induced_bleeding"),
        fetchMetric("risk_assessment.hypertension_history"),
        fetchMetric("risk_assessment.kidney_disease"),
        fetchMetric("risk_assessment.pre_diabetes"),
      ]);

      const [
        dermatologicalCondition,
        peripheralVascularDisease,
        strokeHistory,
        arthritis,
        raynauds,
        valvularDisease,
      ] = await Promise.all([
        fetchMetric("risk_assessment.dermatological_condition"),
        fetchMetric("risk_assessment.peripheral_vascular_disease"),
        fetchMetric("risk_assessment.stroke_history"),
        fetchMetric("risk_assessment.arthritis"),
        fetchMetric("risk_assessment.raynauds"),
        fetchMetric("risk_assessment.valvular_disease"),
      ]);

      const [
        familialHypercholesterolemia,
        diabetesTypeOne,
        diabetesWithComplications,
        aorticValveDisease,
        aortaStenosis,
        aortaInsufficiency,
      ] = await Promise.all([
        fetchMetric("risk_assessment.familial_hypercholesterolemia"),
        fetchMetric("risk_assessment.diabetes_type_one"),
        fetchMetric("risk_assessment.diabetes_with_complications"),
        fetchMetric("risk_assessment.aortic_valve_disease"),
        fetchMetric("risk_assessment.aorta_stenosis"),
        fetchMetric("risk_assessment.aorta_insufficiency"),
      ]);

      const [
        mitralValveDisease,
        mitralValveStenosis,
        mitralValveInsufficiency,
        skinCancer,
        cutaneousMelanomaFamilyHistory,
        cutaneousMelanoma,
        basalCellCarinoma,
      ] = await Promise.all([
        fetchMetric("risk_assessment.mitral_valve_disease"),
        fetchMetric("risk_assessment.mitral_valve_stenosis"),
        fetchMetric("risk_assessment.mitral_valve_insufficiency"),
        fetchMetric("risk_assessment.skin_cancer"),
        fetchMetric("risk_assessment.cutaneous_melanoma_family_history"),
        fetchMetric("risk_assessment.cutaneous_melanoma"),
        fetchMetric("risk_assessment.basal_cell_carinoma"),
      ]);

      const [
        squamousCellCarcinoma,
        acneVulgaris,
        atopicDermatitis,
        psoriasis,
        rosacea,
      ] = await Promise.all([
        fetchMetric("risk_assessment.squamous_cell_carcinoma"),
        fetchMetric("risk_assessment.acne_vulgaris"),
        fetchMetric("risk_assessment.atopic_dermatitis"),
        fetchMetric("risk_assessment.psoriasis"),
        fetchMetric("risk_assessment.rosacea"),
      ]);

      const [eyeSurgery] = await Promise.all([
        fetchMetric("risk_assessment.recent_eye_surgery"),
      ]);

      return {
        diabetesHistory,
        familialHypercholesterolemia,
        congestiveHeartFailureHistory,
        drugInducedBleeding,
        hypertensionHistory,
        kidneyDisease,
        preDiabetes,
        dermatologicalCondition,
        peripheralVascularDisease,
        strokeHistory,
        arthritis,
        raynauds,
        valvularDisease,
        diabetesTypeOne,
        diabetesWithComplications,
        aorticValveDisease,
        aortaStenosis,
        aortaInsufficiency,
        mitralValveDisease,
        mitralValveStenosis,
        mitralValveInsufficiency,
        skinCancer,
        cutaneousMelanomaFamilyHistory,
        cutaneousMelanoma,
        basalCellCarinoma,
        squamousCellCarcinoma,
        acneVulgaris,
        atopicDermatitis,
        psoriasis,
        rosacea,
        eyeSurgery,
      } as RiskAssessment;
    },
    [fetchMetrics]
  );

  const storeRiskAssessment = useCallback(
    (patientId: string, metrics: RiskAssessment) => {
      return storeMetrics(patientId, toMetricList(metrics));
    },
    [storeMetrics]
  );

  return { fetchRiskAssessment, storeRiskAssessment };
}
