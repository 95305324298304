import styles from "./fragments.module.sass";

export function JSXResult(main: string, detail?: string, subDetail?: string) {
  return (
    <div className={styles.JSXResult}>
      <div className={styles.mainResult}>{main}</div>
      <div>{detail}</div>
      <div>{subDetail}</div>
    </div>
  );
}
