import { useCallback, useState } from "react";

interface MetricValueChangeTogglerProps<T> {
  metrics: T[];
  content: (metrics: T[]) => React.ReactNode;
}

export default function MetricValueChangeToggler<T>({
  metrics,
  content,
}: MetricValueChangeTogglerProps<T>) {
  const [showPrevious, setShowPrevious] = useState(false);

  const handleOnClick = useCallback(() => {
    setShowPrevious((v) => !v);
  }, []);

  return (
    <div onClick={handleOnClick}>
      {content(metrics.slice(0, showPrevious ? 2 : 1))}
    </div>
  );
}
