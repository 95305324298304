import { createTimer } from "lib/timer";
import { useEffect, useMemo } from "react";
import { useRenderAspect } from "render/hooks/useRenderAspect";
import { useRenderScale } from "render/hooks/useRenderScale";
import * as THREE from "three";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";

function createRenderer(canvas: HTMLCanvasElement) {
  const renderer = new THREE.WebGLRenderer({
    alpha: true,
    canvas,
  });

  renderer.setClearColor(0x000000, 0);
  renderer.outputColorSpace = THREE.SRGBColorSpace;

  return renderer;
}

interface RendererProps {
  canvas: HTMLCanvasElement;
  scene: THREE.Scene;
  camera: THREE.PerspectiveCamera;
}

export default function Renderer({ canvas, scene, camera }: RendererProps) {
  const renderer = useMemo(() => {
    return createRenderer(canvas);
  }, [canvas]);

  useRenderAspect(renderer, camera);

  useRenderScale(renderer, 1);

  const renderPass = useMemo(() => {
    return new RenderPass(scene, camera);
  }, [scene, camera]);

  const composer = useMemo(() => {
    const composer = new EffectComposer(renderer);

    composer.addPass(renderPass);

    return composer;
  }, [renderer, renderPass]);

  useEffect(() => {
    return createTimer(() => {
      composer.render();
    });
  }, [composer]);

  return null;
}
