import { Patient } from "@cur8/rich-entity";
import { useCallback, useEffect, useMemo } from "react";
import { useBlobAudio } from "render/hooks/useBlobAudio";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import ScrubArea from "render/ui/form/ScrubArea";
import { Typography } from "render/ui/presentation/Typography";
import StudySymbol from "render/ui/symbol/StudySymbol";
import AudioGraph from "./components/AudioGraph";
import ECGGraph from "./components/ECGGraph";
import { useAudioAmplitude } from "./hooks/useAudioAmplitude";
import { PlaybackState, useAudioPlayback } from "./hooks/useAudioPlayback";
import { useECGSyncedPlot } from "./hooks/useECGSynced";
import { useHeartSound } from "./hooks/useHeartSound";
import styles from "./styles.module.sass";

const EMPTY: number[] = [];

function toTimelineSteps(duration: number, steps: number) {
  const step = duration / 5;
  const milestones: { label: string; pos: number }[] = [];

  let pos = 0;
  while (pos <= duration) {
    milestones.push({ pos: pos / duration, label: pos.toFixed() });
    pos += step;
  }

  return milestones;
}

interface HeartPanelProps {
  patient: Patient;
  active: boolean;
}

export default function HeartPanel({ active }: HeartPanelProps) {
  const { scans } = usePatientData();

  const scanStoringAudio = useMemo(() => {
    if (scans == null) {
      return undefined;
    }
    return scans.cardio.at(0) ?? scans.stethoscope.at(0);
  }, [scans]);

  const ecgPlot = useECGSyncedPlot(scanStoringAudio);

  const audioBlob = useHeartSound(scanStoringAudio);

  const audioSignal = useAudioAmplitude(audioBlob);

  const audio = useBlobAudio(audioBlob);

  useEffect(() => {
    if (audio) {
      audio.loop = true;
    }
  }, [audio]);

  const { state, play, pause, progress, setProgress } = useAudioPlayback(audio);

  const audioProgress = progress || 0;

  const handleScrub = useCallback(() => {
    if (state === PlaybackState.Paused) {
      setProgress(0);
      play();
    } else {
      pause();
    }
  }, [state, setProgress, play, pause]);

  useEffect(() => {
    if (!active) {
      pause();
    }
  }, [active, pause]);

  const audioTimeline = useMemo(() => {
    if (!audio) {
      return [];
    }

    return toTimelineSteps(audio.duration, 5);
  }, [audio]);

  const ecgTimeline = useMemo(() => {
    if (!ecgPlot) {
      return [];
    }

    return toTimelineSteps(ecgPlot.signal_length_sec, 5);
  }, [ecgPlot]);

  const ecgProgress =
    audio && ecgPlot ? audio.currentTime / ecgPlot.signal_length_sec : 0;

  return (
    <div className={styles.HeartPanel}>
      <Typography as="h2" variant="display-s">
        Heart rhythm
      </Typography>

      <hr />

      <div className={styles.graphs}>
        <div className={styles.timeline}>
          {ecgTimeline.map((step) => {
            return (
              <div key={step.pos} style={{ left: `${step.pos * 100}%` }}>
                {step.label}
              </div>
            );
          })}
        </div>

        <ScrubArea onScrub={handleScrub}>
          <div className={styles.graph}>
            <div className={styles.label}>
              ECG <StudySymbol />
            </div>

            {ecgPlot && <ECGGraph plot={ecgPlot} progress={ecgProgress} />}
          </div>

          <div className={styles.graph}>
            <div className={styles.label}>
              Heart Sound <StudySymbol forceShow={true} />
            </div>

            <AudioGraph
              volume={audioSignal ?? EMPTY}
              progress={audioProgress}
            />
          </div>
        </ScrubArea>

        <div className={styles.timeline}>
          {audioTimeline.map((step) => {
            return (
              <div key={step.pos} style={{ left: `${step.pos * 100}%` }}>
                {step.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
