import { Path } from "@pomle/paths";
import { codecs } from "render/routes/codec";
import { createPaths as tissue } from "./tissue";

export function createPaths<T extends {}>(origin: Path<T>) {
  const overview = origin.append("/scans", {});

  const detail = overview.append("/:scanId/:scanVersion", {
    scanId: codecs.string,
    scanVersion: codecs.string,
  });

  const cardio = detail.append("/cardio", {});

  return {
    cardio,
    tissue: tissue(detail),
  };
}
