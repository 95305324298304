import { Patient } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import { Metric } from "lib/metric";
import { bySystolic } from "lib/metric/sort";
import { patientScore2 } from "lib/scores/score2";
import { DateTime } from "luxon";
import { useMemo } from "react";

export type Score2 = {
  score: number;
};

type Score2Factors = {
  patient: Patient | undefined;
  date: DateTime;
  isSmoker: boolean | undefined;
  brachial: {
    left: Metric<"cardio.brachial_pressure.left">[] | undefined;
    right: Metric<"cardio.brachial_pressure.right">[] | undefined;
  };
  nonHDL: Metric<"bloodwork.non_hdl">[] | undefined;
};

export function useScore2({
  patient,
  date,
  nonHDL,
  brachial: { left, right },
  isSmoker,
}: Score2Factors): Score2 | undefined {
  return useMemo(() => {
    if (!patient || !nonHDL || !left || !right || isSmoker == null) {
      return;
    }

    const age = patientAge(patient, date);

    if (nonHDL.length === 0 || left.length + right.length === 0) {
      return { score: NaN };
    }

    const highest = [...left.slice(0, 1), ...right.slice(0, 1)].sort(
      bySystolic("desc")
    )[0];

    const score2 = patientScore2({
      age,
      sex: patient.sex,
      metric: {
        nonHDL: nonHDL[0],
        isSmoker,
        brachialPressure: highest,
      },
    });

    return {
      score: score2 ?? NaN,
    };
  }, [patient, nonHDL, left, right, date, isSmoker]);
}
