import { useQueryParams } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { query } from "render/routes/querys";

export function useManageSelectedPatient() {
  const [queryParams, setQueryParams] = useQueryParams(query.schedule);

  const setPatientToAssign = useCallback(
    (patientId: string | null) => {
      setQueryParams({
        ...queryParams,
        assignPatientId: patientId ? [patientId] : [],
      });
    },
    [queryParams, setQueryParams]
  );

  const clearPatient = useCallback(() => {
    setPatientToAssign(null);
  }, [setPatientToAssign]);

  return {
    clearPatient,
    setPatientToAssign,
  };
}
