export function loadImage(url: string) {
  return new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", () => {
      reject(new Error(`Image load fail: ${url}`));
    });
    image.src = url;
  });
}

export function loadAudio(url: string): Promise<HTMLAudioElement> {
  return new Promise((resolve, reject) => {
    const audio = new Audio();
    audio.addEventListener("canplaythrough", () => resolve(audio));
    audio.addEventListener("error", () => {
      reject(new Error(`Audio load fail: ${url}`));
    });
    audio.src = url;
  });
}
