import { PhysicalIdType } from "@cur8/uri";
import { Patient } from "@cur8/rich-entity";
import { isPhysicalIdURI } from "lib/uri/guard";

interface PatientNameProps {
  patient: Patient;
}

export default function PatientName({ patient }: PatientNameProps) {
  const { name } = patient;

  if (name) {
    if (name.firstName || name.lastName) {
      return (
        <>
          {name.firstName} {name.lastName}
        </>
      );
    }

    if (name.displayName) {
      return <>{name.displayName}</>;
    }
  }

  const legacyId = patient.physicalIds.filter(isPhysicalIdURI).find((uri) => {
    return uri.idType === PhysicalIdType.Legacy;
  });

  if (legacyId) {
    return <>{legacyId.id}</>;
  }

  return <>{patient.patientId}</>;
}
