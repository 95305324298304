import { ChartRange } from "../types";

export function isSameRange(a: ChartRange, b?: ChartRange) {
  if (!b) {
    return false;
  }

  return a.from === b.from && a.to === b.to;
}

export function isLowerOrEqualThanRange(value: number, range: ChartRange) {
  return value <= range.from;
}

export function isHigherOrEqualThanRange(value: number, range: ChartRange) {
  return value >= range.to;
}

export function sanitizeValue(value: number) {
  return +value.toFixed(1);
}

export function normalize(
  value: number,
  { max, min }: { max: number; min: number }
) {
  return (value - min) / (max - min);
}

export function getLeftLabel({
  isHigherOrEqualThanMiddleMiddleRange,
  value,
}: {
  isLowest: boolean;
  isHigherOrEqualThanMiddleMiddleRange: boolean;
  value: number;
}) {
  if (isHigherOrEqualThanMiddleMiddleRange) {
    return value;
  }

  return undefined;
}

export function getRightLabel({
  isLowerThanMiddleMiddleRange,
  value,
}: {
  isHighest: boolean;
  isLowerThanMiddleMiddleRange: boolean;
  value: number;
}) {
  if (isLowerThanMiddleMiddleRange) {
    return value;
  }
  return undefined;
}

export function groomDecilmasIfNecessary(
  value?: number,
  maxDecimals: number = 1
) {
  if (!value) {
    return value;
  }

  const stringValue = value.toString();
  const [integer, decimals] = stringValue.split(".");

  if (decimals && decimals.length > maxDecimals) {
    return `${integer}.${decimals.slice(0, maxDecimals)}`;
  }

  return stringValue;
}

export const concatPercent = (value: number) => `${value}%`;
