import { useCallback, useEffect, useState } from "react";
import { useProjectionGraphContext } from "../ProjectionGraph.context";

type ProjectionTimelineStateMarker = "timeline-start" | "timeline-end";

interface ProjectionTimelineRangeProps {
  from?: number | ProjectionTimelineStateMarker;
  to?: number | ProjectionTimelineStateMarker;
  children: (isVisible: boolean) => JSX.Element;
}
/**
 * Wrapper component for determining if a certain part of the projection graph should be in view based on the projection timeline

 * @example
 * <ProjectionTimelineRange from="timeline-start" to={0.8}>
 *   <{(isVisible) => ({isVisible && <div>Show</div>})>
 * </ProjectionTimelineRange>
 */
export function ProjectionTimelineRange({
  from = 0,
  to = 1,
  children,
}: ProjectionTimelineRangeProps) {
  const { timelineProgress, timelineSeek } = useProjectionGraphContext();
  const [isVisible, setIsVisible] = useState(true);

  // Convert a timeline marker to a numeric value (e.g. "timeline-end" -> 1, 0.5 -> 0.5)
  const getNumericValueForTimelineRangeMarker = useCallback(
    (marker: number | ProjectionTimelineStateMarker): number => {
      if (typeof marker === "number") {
        return marker;
      }
      if (marker === "timeline-start") {
        return timelineSeek;
      }
      if (marker === "timeline-end") {
        return 1;
      }
      return 0;
    },
    [timelineSeek]
  );

  const _from = getNumericValueForTimelineRangeMarker(from);
  const _to = getNumericValueForTimelineRangeMarker(to);

  useEffect(() => {
    const onTimelineProgressUpdate = (value = 0) => {
      const shouldShow = value >= _from && value <= _to;
      if (isVisible !== shouldShow) {
        setIsVisible(shouldShow);
      }
    };

    onTimelineProgressUpdate(timelineProgress.get());

    return timelineProgress.on("change", onTimelineProgressUpdate);
  }, [timelineProgress, isVisible, _from, _to]);

  return <>{children(isVisible)}</>;
}
