import styles from "./styles.module.sass";
import { useCallback } from "react";
import usePeriodSelector from "render/pages/SchedulePage/hooks/usePeriodSelector";
import PeriodSelector from "./components/PeriodSelector";
import useCalendarState, {
  ViewRangeMode,
} from "../Calendar/hooks/useCalendarState";

export default function SchedulePageHeader() {
  const {
    navigateToNextWeek,
    navigateToPreviousWeek,
    navigateToNextDay,
    navigateToPrevDay,
  } = usePeriodSelector();

  const { viewRange } = useCalendarState();

  const navigateToNextPeriod = useCallback(() => {
    if (viewRange === ViewRangeMode.Weekly) {
      navigateToNextWeek();
    } else {
      navigateToNextDay();
    }
  }, [navigateToNextWeek, viewRange, navigateToNextDay]);

  const navigateToPreviousPeriod = useCallback(() => {
    if (viewRange === ViewRangeMode.Weekly) {
      navigateToPreviousWeek();
    } else {
      navigateToPrevDay();
    }
  }, [navigateToPreviousWeek, viewRange, navigateToPrevDay]);

  return (
    <div className={styles.SchedulePageHeader}>
      <PeriodSelector
        navigateToNextPeriod={navigateToNextPeriod}
        navigateToPreviousPeriod={navigateToPreviousPeriod}
      />
    </div>
  );
}
