import styles from "./styles.module.sass";

interface AssessmentPanelProps {
  title: React.ReactNode;
  aux?: React.ReactNode;
  value: React.ReactNode;
  children?: React.ReactNode;
}

export default function AssessmentPanel({
  title,
  aux,
  value,
  children,
}: AssessmentPanelProps) {
  return (
    <div className={styles.AssessmentPanel}>
      <header>
        <h3>{title}</h3>

        <div className={styles.aux}>{aux}</div>
      </header>

      <section className={styles.value}>{value}</section>

      {children && <section className={styles.content}>{children}</section>}
    </div>
  );
}
