import { Annotation } from "@cur8/rich-entity";

function isValidHistoryImage(annotation: Annotation) {
  // Comments guaranteed to not have images
  if (annotation.applicationSpecificTarget === "annotation:comment") {
    return false;
  }

  if (annotation.applicationSpecificTarget === "derma:derma") {
    return false;
  }

  return true;
}

export type ArtefactHistoryEntry = {
  image: Annotation;
  comments: Annotation[];
};

export function createArtefactHistory(
  annotations: Annotation[]
): ArtefactHistoryEntry[] {
  const history: ArtefactHistoryEntry[] = [];

  const images = annotations.filter(isValidHistoryImage);

  const comments = annotations.filter((annotation) => {
    return annotation.applicationSpecificTarget === "annotation:comment";
  });

  for (const image of images) {
    history.push({
      image,
      comments: comments.filter((annotation) => {
        return annotation.targetURI.toString() === image.uri.toString();
      }),
    });
  }

  return history;
}
