import { APITypesV1 } from "@cur8/api-client";
import { LineAnnotation, LineDataType } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { Line, Point } from "lib/math";
import { useCallback, useEffect, useRef, useState } from "react";
import colors from "render/pages/TissuePages/colors.module.sass";
import { useTissueAnnotationContext } from "render/pages/TissuePages/context/TissueAnnotationContext";
import { useMessagePopup } from "render/pages/TissuePages/hooks/useMessagePopup";
import {
  CreateTissueAnnotation,
  ImageSize,
  TissueAnnotation,
} from "render/pages/TissuePages/lib/types";
import {
  getColorName,
  line2DtoLine,
  lineWithinBoundry,
} from "render/pages/TissuePages/lib/utils";
import styles from "./styles.module.sass";

interface SvgLineProps {
  anno: CreateTissueAnnotation<LineDataType> | TissueAnnotation<LineAnnotation>;
  canvasSize: ImageSize;
  isSelected: boolean;
  line: APITypesV1.Line2D;
  moveAnchor?: (pos: Point) => void;
  scale: number;
}

type LineAction = null | "resize" | "move";

export default function SvgLine({
  anno,
  canvasSize,
  isSelected,
  line,
  moveAnchor,
  scale,
}: SvgLineProps) {
  const { emitDialog } = useMessagePopup();
  const { move, select } = useTissueAnnotationContext();

  const lineMarkerEnd = useRef<SVGMarkerElement>(null);
  const [action, setAction] = useState<LineAction>(null);
  const [moveLine, setMoveLine] = useState<Line>(line2DtoLine(line));

  useEffect(() => {
    setMoveLine(line2DtoLine(line));
  }, [line]);

  useEffect(() => {
    if (!isSelected || !lineMarkerEnd.current) {
      return;
    }
    const dx = moveLine.b.x - moveLine.a.x;
    const dy = moveLine.b.y - moveLine.a.y;
    const angle = (Math.atan2(dy, dx) * (180 / Math.PI)).toString();
    lineMarkerEnd.current.setAttribute("orient", angle);
  }, [isSelected, moveLine]);

  const [strokeWidth, setStrokeWidth] = useState<number>(2 / scale);
  useEffect(() => {
    if (isSelected) {
      setStrokeWidth(scale > 1 ? 4 : 3 / scale);
    } else {
      setStrokeWidth(2 / scale);
    }
  }, [isSelected, scale]);

  const pointerDown = useCallback(
    (event: React.PointerEvent, type: LineAction) => {
      if (event.buttons !== 1 || !isSelected || action) {
        return;
      }
      event.preventDefault();
      setAction(type);
      event.currentTarget.setPointerCapture(event.pointerId);
    },
    [action, isSelected]
  );

  const movePointerDown = useCallback(
    (event: React.PointerEvent) => pointerDown(event, "move"),
    [pointerDown]
  );

  const resizePointerDown = useCallback(
    (event: React.PointerEvent) => pointerDown(event, "resize"),
    [pointerDown]
  );

  const pointerMove = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1 || !isSelected || !action) {
        return;
      }
      event.preventDefault();
      let dx = event.movementX / scale;
      let dy = event.movementY / scale;
      let a = moveLine.a;
      const b = new Point(moveLine.b.x + dx, moveLine.b.y + dy);
      if (action === "move") {
        a = new Point(moveLine.a.x + dx, moveLine.a.y + dy);
      }
      const nl = new Line(a, b);
      if (lineWithinBoundry(nl, canvasSize)) {
        setMoveLine(new Line(a, b));
        /*
        // Anchor point for InfoBox
        if (moveAnchor) {
          moveAnchor(moveLine.midPoint());
        }
*/
      } else {
        emitDialog(
          "Line is out of bounds",
          "The line needs to be within the image."
        );
        setMoveLine(line2DtoLine(line));
      }
    },
    [
      action,
      canvasSize,
      emitDialog,
      isSelected,
      line,
      //moveAnchor,
      moveLine,
      scale,
    ]
  );

  const pointerUp = useCallback(
    (event: React.PointerEvent) => {
      event.currentTarget.releasePointerCapture(event.pointerId);
      if (!isSelected || !action) {
        return;
      }
      event.preventDefault();
      setAction(null);
      const ml = new Line(
        new Point(Math.round(moveLine.a.x), Math.round(moveLine.a.y)),
        new Point(Math.round(moveLine.b.x), Math.round(moveLine.b.y))
      );
      move(anno.annotation.id!, { line: ml });
    },
    [action, isSelected, anno.annotation.id, move, moveLine]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent) => {
      if (!isSelected) {
        event.preventDefault();
        select(anno.annotation.id);
      }
    },
    [anno.annotation.id, isSelected, select]
  );

  return (
    <g className={classNames(styles.line, isSelected ? styles.selected : "")}>
      {isSelected && (
        <defs>
          <marker
            id="lineArrowEnd"
            ref={lineMarkerEnd}
            markerWidth="10"
            markerHeight="10"
            refX="8"
            refY="3"
            orient="auto"
          >
            <path
              d="M0,0 L0,6 L9,3 z"
              className={colors.fill}
              data-color={getColorName(anno.color)}
            />
          </marker>
        </defs>
      )}
      <line
        className={colors.stroke}
        data-color={getColorName(anno.color)}
        onClick={handleClick}
        onPointerDown={movePointerDown}
        onPointerMove={pointerMove}
        onPointerUp={pointerUp}
        x1={moveLine.a.x}
        y1={moveLine.a.y}
        x2={moveLine.b.x}
        y2={moveLine.b.y}
        markerEnd={isSelected ? "url(#lineArrowEnd)" : undefined}
        strokeWidth={strokeWidth}
      />
      <rect
        className={styles.markerClick}
        onClick={handleClick}
        onPointerDown={resizePointerDown}
        onPointerMove={pointerMove}
        onPointerUp={pointerUp}
        x={moveLine.b.x - 21}
        y={moveLine.b.y - 21}
        width={42}
        height={42}
      />
    </g>
  );
}
