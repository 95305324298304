import { Box } from "@cur8/rich-entity";

export type PredefinedRoI = {
  pos: {
    x: number;
    y: number;
    w: number;
    h: number;
  };
  label: string;
};

export function coordsToBox(
  pos: PredefinedRoI["pos"],
  pixelsPerCm: number
): Box {
  return new Box(
    pos.x,
    pos.y,
    Math.round(pos.w * pixelsPerCm),
    Math.round(pos.h * pixelsPerCm)
  );
}

export const heatProvocationArm: PredefinedRoI[] = [
  {
    pos: { x: 674, y: 256, w: 1, h: 1 },
    label: "armRef",
  },
  {
    pos: { x: 860, y: 310, w: 0.7, h: 0.7 },
    label: "arm35",
  },
  {
    pos: { x: 1030, y: 430, w: 0.7, h: 0.7 },
    label: "arm42",
  },
  {
    pos: { x: 94, y: 84, w: 1, h: 1 },
    label: "handRef",
  },
  {
    pos: { x: 110, y: 220, w: 0.7, h: 0.7 },
    label: "hand35",
  },
  {
    pos: { x: 190, y: 470, w: 0.7, h: 0.7 },
    label: "hand42",
  },
  {
    pos: { x: 600, y: 300, w: 0.3, h: 0.3 },
    label: "vessel",
  },
];

export const occlusionArm: PredefinedRoI[] = [
  {
    pos: { x: 694, y: 274, w: 1, h: 1 },
    label: "forearm",
  },
  {
    pos: { x: 62, y: 80, w: 1, h: 1 },
    label: "thenar",
  },
  {
    pos: { x: 65, y: 420, w: 0.7, h: 0.7 },
    label: "hypothenar",
  },
  {
    pos: { x: 680, y: 376, w: 0.3, h: 0.3 },
    label: "vessel",
  },
];

export const heatProvocationFoot: PredefinedRoI[] = [
  {
    pos: { x: 910, y: 570, w: 1, h: 1 },
    label: "footRef",
  },
  {
    pos: { x: 730, y: 440, w: 0.7, h: 0.7 },
    label: "foot35",
  },
  {
    pos: { x: 1050, y: 370, w: 0.7, h: 0.7 },
    label: "foot42",
  },
  {
    pos: { x: 120, y: 200, w: 0.7, h: 0.7 },
    label: "toe",
  },
  {
    pos: { x: 1070, y: 490, w: 0.3, h: 0.3 },
    label: "vessel",
  },
];
