import { APITypesV1 } from "@cur8/api-client";
import { MemberSummary } from "@cur8/api-client/dist/api/cur8/generated-types/v1/Api";
import { ImmutableScan } from "@cur8/rich-entity";
import { ScribeFlavor, TransSumPair } from "lib/doctor-scribe/types";
import { formatDate, MSToTSPair } from "lib/doctor-scribe/utils";
import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useAppInsights } from "render/context/AppInsightsContext";
import LineThrobber from "render/ui/throbber/LineThrobber";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { useReportProblem } from "../ReportLLMProblem/useReportProblem";
import { useReportProblemDialog } from "../ReportLLMProblem/useReportProblemDialog";
import Feedback from "./components/Feedback";
import Improve from "./components/Improve";
import RightDrawer from "./components/RightDrawer";
import SummaryDetails from "./components/SummaryDetails";
import { LLMScanType } from "./hooks/useLLMScan";
import { postProcess } from "./postProcessing";
import styles from "./styles.module.sass";

interface DoctorScribeProps {
  patientId: string;
  llmScan: LLMScanType;
  onSummary: (summary: TransSumPair | undefined) => void;
  onLike: (isLike: boolean) => void;
}

enum State {
  loading,
  noOngoingVisit,
  processing,
  noVisit,
  alreadySaved,
  noConsent,
  noMemberSummary,
  hasSummary,
}

export default function DoctorScribe({
  patientId,
  llmScan,
  onSummary,
  onLike,
}: DoctorScribeProps) {
  const api = useAPIClient();

  const [state, setState] = useState(State.loading);
  const [memberSummary, setMemberSummary] = useState<MemberSummary>();
  const [tunedMemberSummary, setTunedMemberSummary] = useState<MemberSummary>();
  const [showSummaryDetails, setShowSummaryDetails] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [flavor, setFlavor] = useState<ScribeFlavor>(ScribeFlavor.Stable);

  const { appInsights } = useAppInsights();
  const { emitProblemDialog } = useReportProblemDialog();
  const { saveProblemReportResult } = useReportProblem();

  const {
    latestLLMScan: scan,
    createLLMScan,
    ongoingVisit,
    triggerMemberSummaryGenerationWithoutTranscription,
    pollForNewScan,
    reloadLatestLLMScan,
  } = llmScan;

  const pushSummary = useCallback(
    (ms: MemberSummary | undefined, selectedFlavor: ScribeFlavor) => {
      if (ms) {
        ms.summary = postProcess(ms.summary);
        onSummary({ ...MSToTSPair(ms), flavor: selectedFlavor });
      } else {
        onSummary(undefined);
      }
    },
    [onSummary]
  );

  const handleGenerateNew = useCallback(async () => {
    createLLMScan(patientId).then(async (r) => {
      if (r) {
        await triggerMemberSummaryGenerationWithoutTranscription(r);
        reloadLatestLLMScan();
      }
    });
  }, [
    patientId,
    createLLMScan,
    triggerMemberSummaryGenerationWithoutTranscription,
    reloadLatestLLMScan,
  ]);

  const onReportProblem = useCallback(() => {
    if (!memberSummary) {
      return;
    }

    emitProblemDialog(memberSummary.summary, (feedback: string) => {
      if (!scan) {
        return;
      }

      saveProblemReportResult(scan, {
        category: "generic",
        feedback,
      });
    });
  }, [memberSummary, emitProblemDialog, scan, saveProblemReportResult]);

  function hasSummaryComplete(
    record: Record<string, APITypesV1.ResultState>
  ): boolean {
    return record["memberSummary"] === APITypesV1.ResultState.Complete;
  }
  function hasTunedSummaryComplete(
    record: Record<string, APITypesV1.ResultState>
  ): boolean {
    return record["tunedMemberSummary"] === APITypesV1.ResultState.Complete;
  }

  function scanAgeMinutes(scan: ImmutableScan) {
    const now = DateTime.now();
    const diff =
      (now.toMillis() - scan.versionCreatedAt.toMillis()) / 1000 / 60;
    return diff;
  }

  function handleFlavour(selectedFlavor: ScribeFlavor) {
    appInsights.trackEvent({
      name: "scribe-selected-flavor",
      properties: { patientId, selectedFlavor },
    });
    setFlavor(selectedFlavor);
    switch (selectedFlavor) {
      case ScribeFlavor.Stable:
        pushSummary(memberSummary, ScribeFlavor.Stable);
        break;
      case ScribeFlavor.Tuned:
        pushSummary(tunedMemberSummary, ScribeFlavor.Tuned);
        break;
      case ScribeFlavor.None:
        pushSummary(undefined, ScribeFlavor.None);
        break;
    }
  }

  useEffect(() => {
    if (scan && scan.state === APITypesV1.ScanState.Finished) {
      // Scan already saved
      setState(State.alreadySaved);
    } else if (
      scan &&
      scanAgeMinutes(scan) < 10 && // if the processing time for a scan is larger than 10 minutes, we give up on it.
      (scan.state === APITypesV1.ScanState.Inprogress ||
        scan.state === APITypesV1.ScanState.Available) &&
      !hasSummaryComplete(scan.resultStateSummary)
    ) {
      // A memberSummary is crunching, poll for it
      setMemberSummary(undefined);
      if (state !== State.processing) {
        pollForNewScan().then(() => {
          reloadLatestLLMScan();
        });
        setState(State.processing);
      }
    } else if (scan && hasSummaryComplete(scan.resultStateSummary)) {
      // a scan with member summary is available, fetch it if not done already
      if (!memberSummary) {
        api.scan
          .fetchScanResult({
            patientId: scan.patientId,
            scanId: scan.id,
            scanVersion: scan.version,
            resultName: "memberSummary",
          })
          .result.then((result) => {
            const ms = result.data as MemberSummary;
            setMemberSummary(ms);
            pushSummary(ms, flavor);
            setState(State.hasSummary);
          })
          .catch(console.error);
        if (hasTunedSummaryComplete(scan.resultStateSummary)) {
          api.scan
            .fetchScanResult({
              patientId: scan.patientId,
              scanId: scan.id,
              scanVersion: scan.version,
              resultName: "tunedMemberSummary",
            })
            .result.then((result) => {
              const ms = result.data as MemberSummary;
              setTunedMemberSummary(ms);
            })
            .catch(console.error);
        }
      }
    } else if (!ongoingVisit) {
      setState(State.noOngoingVisit);
    } else {
      setState(State.noMemberSummary);
    }
  }, [
    api,
    ongoingVisit,
    scan,
    pushSummary,
    pollForNewScan,
    reloadLatestLLMScan,
    memberSummary,
    state,
    flavor,
  ]);

  const noVisitFragment = (
    <div className={styles.warning}>
      Scribe is not available because the member has no recent visit.
    </div>
  );

  const noRoomFragment = (
    <div className={styles.warning}>
      Scribe is not available because the member has no ongoing visit.
    </div>
  );

  const scanCompleteFragment = (
    <div className={styles.warning}>
      Scribe is not available because the last scan already has a visit summary
      saved.
    </div>
  );

  const scanProcessingFragment = (
    <div className={styles.container}>
      <LineThrobber />
      Scribe is processing the latest scan from{" "}
      {formatDate(scan?.timestamp.toISO() || "")}
    </div>
  );

  const noMemberSummaryFragment = (
    <div className={styles.row}>
      <div className={styles.warningContainer}>
        No pre-generated summary available since there is no audio transcription
        available from a recent de-brief.
      </div>
      <HoverTextButton onClick={handleGenerateNew}>
        Generate summary
      </HoverTextButton>
    </div>
  );

  const summaryFragment = (
    <div className={styles.container}>
      <div className={styles.row}>
        Scribe from {formatDate(scan?.timestamp.toISO() || "")} available
      </div>
      <div className={styles.row}>
        Use:
        <div className={styles.row}>
          <div className={styles.buttons}>
            <HoverTextButton
              onClick={() => handleFlavour(ScribeFlavor.Stable)}
              active={flavor === ScribeFlavor.Stable}
            >
              Stable
            </HoverTextButton>
            <HoverTextButton
              onClick={() => handleFlavour(ScribeFlavor.Tuned)}
              active={flavor === ScribeFlavor.Tuned}
              disabled={!tunedMemberSummary}
            >
              Fine-tuned
            </HoverTextButton>
            <HoverTextButton
              onClick={() => handleFlavour(ScribeFlavor.None)}
              active={flavor === ScribeFlavor.None}
            >
              None
            </HoverTextButton>
          </div>
          <div className={styles.buttons}>
            {/* <HoverTextButton onClick={handleGenerateNew}>Retrigger</HoverTextButton> */}
            <HoverTextButton
              onClick={() => setShowSummaryDetails((prev) => !prev)}
              active={showSummaryDetails}
            >
              Details
            </HoverTextButton>
            <HoverTextButton
              onClick={() => setShowChat((prev) => !prev)}
              active={showChat}
            >
              🤖 Improve
            </HoverTextButton>
          </div>
        </div>
      </div>
    </div>
  );

  function renderComponent(state: State) {
    switch (state) {
      case State.loading:
        return <LineThrobber />;
      case State.noVisit:
        return noVisitFragment;
      case State.alreadySaved:
        return scanCompleteFragment;
      case State.processing:
        return scanProcessingFragment;
      case State.noMemberSummary:
        return noMemberSummaryFragment;
      case State.hasSummary:
        return summaryFragment;
      case State.noOngoingVisit:
        return noRoomFragment;
      default:
        return <></>;
    }
  }

  return (
    <div className={styles.DoctorScribe}>
      <div className={styles.card}>{renderComponent(state)}</div>
      {state === State.hasSummary && (
        <div className={styles.card}>
          <Feedback onLike={onLike} onReportProblem={onReportProblem} />
        </div>
      )}
      {scan && (
        <div>
          <RightDrawer
            title="Pre-generated summary details"
            onClose={() => setShowSummaryDetails(false)}
            active={showSummaryDetails}
          >
            <SummaryDetails
              patientId={scan.patientId}
              memberSummary={memberSummary}
            />
          </RightDrawer>
          <RightDrawer title="Improve on summary" active={showChat}>
            {memberSummary ? (
              <Improve
                patientId={scan.patientId}
                summary={memberSummary}
                active={showChat}
                onOK={(newSummary) => {
                  if (newSummary) {
                    setMemberSummary(newSummary);
                    pushSummary(newSummary, flavor);
                  }
                  setShowChat(false);
                }}
              />
            ) : (
              <div>No generated summary to improve on</div>
            )}
          </RightDrawer>
        </div>
      )}
    </div>
  );
}
