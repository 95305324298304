import { ImmutableScan } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useDeleteScanJournalNote() {
  const api = useAPIClient();

  return useCallback(
    async (scan: ImmutableScan) => {
      if (!scan.journalNoteId) {
        throw new Error("Scan does not have a journal note id");
      }

      return api.scan.deleteScanJournalNote({
        patientId: scan.patientId,
        scanId: scan.id,
        scanVersion: scan.version,
        journalNoteId: scan.journalNoteId,
      }).result;
    },
    [api.scan]
  );
}
