import { APITypesV1 } from "@cur8/api-client";
import { Recording, fromAPI } from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";

export async function findPreviousRecording(
  api: APIClient,
  originRecording: Recording
) {
  const BATCH_SIZE = 10;

  const { patientId } = originRecording;
  if (!patientId) {
    return null;
  }

  let recordings: Recording[] = [];
  let index = -1;
  let token: string | undefined;

  for (;;) {
    const response = await api.recording.queryRecordings({
      patientId,
      recordingType: originRecording.recordingType,
      pageSize: BATCH_SIZE,
      orderBy: "createdAt",
      order: APITypesV1.SortOrder.Desc,
      continuationToken: token,
    }).result;

    const { items } = response;

    recordings.push(...items.map(fromAPI.toRecording));

    index = recordings.findIndex(
      (recording) => recording.id === originRecording.id
    );

    if (index > -1) {
      const prevRecording = recordings[index + 1];
      if (prevRecording) {
        return prevRecording;
      }
    }

    if (!response.nextPage) {
      return null;
    }

    token = response.nextPage;
  }
}
