import { APITypesV1 } from "@cur8/api-client";
import {
  Diastolic,
  highestRisk,
  Risk,
  Systolic,
} from "@cur8/health-risks-calc";
import { AggregatedBloodPressureData, Patient } from "@cur8/rich-entity";
import { patientAge } from "lib/datetime";
import { DateTime } from "luxon";
import { useMemo } from "react";
import { getRiskColor } from "../../../../../../lib/utils";
import MoonIcon from "./assets/moon.svg?react";
import SunIcon from "./assets/sun.svg?react";
import Candlestick from "./components/Candlestick";
import Tooltip from "./components/Tooltip";
import styles from "./styles.module.sass";

function onlyNumber(value: unknown): value is number {
  return typeof value === "number" && isFinite(value);
}

interface DayDataColumnProps {
  patient: Patient;
  data: AggregatedBloodPressureData | undefined;
  toY: (n: number) => number;
}

export default function DayDataColumn({
  data,
  toY,
  patient,
}: DayDataColumnProps) {
  const age = patientAge(patient, DateTime.now());

  const findRiskColor = useMemo(() => {
    const sys = Systolic.rangesFor({ age });
    const dia = Diastolic.rangesFor({ age });

    function findRiskColor({ diastolic, systolic }: APITypesV1.BloodPressure) {
      const risks: Risk[] = [];

      if (systolic) {
        risks.push(sys.findRisk({ mmHg: systolic }));
      }
      if (diastolic) {
        risks.push(dia.findRisk({ mmHg: diastolic }));
      }

      return getRiskColor(highestRisk(risks));
    }

    return findRiskColor;
  }, [age]);

  const { diastolic, systolic } = useMemo(() => {
    return {
      diastolic: {
        day: [
          data?.maxDay?.diastolic,
          data?.minDay?.diastolic,
          data?.avgDay?.diastolic,
        ].filter(onlyNumber),
        night: [
          data?.maxNight?.diastolic,
          data?.minNight?.diastolic,
          data?.avgNight?.diastolic,
        ].filter(onlyNumber),
      },
      systolic: {
        day: [
          data?.maxDay?.systolic,
          data?.minDay?.systolic,
          data?.avgDay?.systolic,
        ].filter(onlyNumber),
        night: [
          data?.maxNight?.systolic,
          data?.minNight?.systolic,
          data?.avgNight?.systolic,
        ].filter(onlyNumber),
      },
    };
  }, [data]);

  const avgDay = data?.avgDay;
  const maxDay = data?.maxDay;
  const minDay = data?.minDay;

  const avgNight = data?.avgNight;
  const maxNight = data?.maxNight;
  const minNight = data?.minNight;

  return (
    <div className={styles.DayDataColumn}>
      <div className={styles.nightTime}>
        <Candlestick
          patient={patient}
          toY={toY}
          systolic={systolic.night}
          diastolic={diastolic.night}
        />
        {data && (
          <div className={styles.tooltip}>
            <Tooltip
              data={[
                {
                  label: "Max",
                  color: maxNight ? findRiskColor(maxNight) : "",
                  value: maxNight ? (
                    <>
                      {maxNight.systolic}/{maxNight.diastolic}
                    </>
                  ) : (
                    "--/--"
                  ),
                },
                {
                  label: "Min",
                  color: minNight ? findRiskColor(minNight) : "",
                  value: minNight ? (
                    <>
                      {minNight.systolic}/{minNight.diastolic}
                    </>
                  ) : (
                    "--/--"
                  ),
                },
                {
                  label: "Avg",
                  color: avgNight ? findRiskColor(avgNight) : "",
                  value: avgNight ? (
                    <>
                      {avgNight.systolic}/{avgNight.diastolic}
                    </>
                  ) : (
                    "--/--"
                  ),
                },
              ]}
            />
          </div>
        )}

        <MoonIcon className={styles.icon} />
      </div>
      <div className={styles.dayTime}>
        <Candlestick
          patient={patient}
          toY={toY}
          systolic={systolic.day}
          diastolic={diastolic.day}
        />
        {data && (
          <div className={styles.tooltip}>
            <Tooltip
              data={[
                {
                  label: "Max",
                  color: maxDay ? findRiskColor(maxDay) : "",
                  value: maxDay ? (
                    <>
                      {maxDay.systolic}/{maxDay.diastolic}
                    </>
                  ) : (
                    "--/--"
                  ),
                },
                {
                  label: "Min",
                  color: minDay ? findRiskColor(minDay) : "",
                  value: minDay ? (
                    <>
                      {minDay.systolic}/{minDay.diastolic}
                    </>
                  ) : (
                    "--/--"
                  ),
                },
                {
                  label: "Avg",
                  color: avgDay ? findRiskColor(avgDay) : "",
                  value: avgDay ? (
                    <>
                      {avgDay.systolic}/{avgDay.diastolic}
                    </>
                  ) : (
                    "--/--"
                  ),
                },
              ]}
            />
          </div>
        )}
        <SunIcon className={styles.icon} />
      </div>
    </div>
  );
}
