export function distinct<T>(selectKey: (value: T) => unknown) {
  const seen = new Set();

  return function compare(item: T) {
    const key = selectKey(item);
    if (key === undefined) {
      return false;
    }

    if (seen.has(key)) {
      return false;
    }
    seen.add(key);
    return true;
  };
}
