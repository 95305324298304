import { useCallback } from "react";
import { useTissueAnnotationContext } from "render/pages/TissuePages/context/TissueAnnotationContext";
import { useQueries } from "render/pages/TissuePages/hooks/useQueries";
import {
  Property,
  TissueAnnotationDataType,
} from "render/pages/TissuePages/lib/types";
import { EnlargeShrinkIcon } from "render/ui/symbol/HoverIcon";
import ButtonSet from "render/ui/trigger/ButtonSet";
import FigureButton from "render/ui/trigger/FigureButton";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import LabeledFigure from "render/ui/trigger/LabeledFigure";
import ImageToggles from "./ImageToggles";
import styles from "./styles.module.sass";

interface ControlsProps {
  allowAdd?: boolean;
  isFullscreen: boolean;
  setFullscreen: (fullscreen: boolean) => void;
  showBloodVessels: boolean;
}

export default function Controls({
  allowAdd = true,
  isFullscreen,
  setFullscreen,
  showBloodVessels,
}: ControlsProps) {
  const { create, newAnno } = useTissueAnnotationContext();
  const { property } = useQueries();

  const enlarge = useCallback(
    (ev: React.MouseEvent) => {
      setFullscreen(!isFullscreen);
    },
    [isFullscreen, setFullscreen]
  );

  const addRoI = useCallback(() => {
    create(TissueAnnotationDataType.Region);
  }, [create]);

  const addLine = useCallback(() => {
    create(TissueAnnotationDataType.Line);
  }, [create]);

  return (
    <div className={styles.Controls}>
      <FigureButton onClick={enlarge}>
        <LabeledFigure label={!isFullscreen ? "Enlarge" : "Shrink"}>
          <EnlargeShrinkIcon active={!isFullscreen} color="black" />
        </LabeledFigure>
      </FigureButton>
      {allowAdd && (
        <div>
          <label>Add</label>
          <ButtonSet stacked={true}>
            <HoverTextButton
              onClick={addRoI}
              disabled={!!newAnno}
              title="Add Region of Interest"
            >
              RoI
            </HoverTextButton>
            {property === Property.thermal && (
              <HoverTextButton
                onClick={addLine}
                disabled={!!newAnno}
                title="Add Thermal profile line"
              >
                Line
              </HoverTextButton>
            )}
          </ButtonSet>
        </div>
      )}
      <ImageToggles showBloodVessels={showBloodVessels} />
    </div>
  );
}
