export function getBenchmarkValueClampLimitsForResultDiff(resultDiff: number) {
  if (resultDiff <= 0) {
    return {
      from: 20,
      to: 100,
    };
  }

  return {
    from: 25,
    to: 70,
  };
}

export function getMemberValueClampLimitsForResultDiff(resultDiff: number) {
  if (resultDiff >= 0) {
    return {
      from: 25,
      to: 100,
    };
  }

  return {
    from: 25,
    to: 70,
  };
}

export function toFixedIfNecessary(number: number) {
  return +number.toFixed(1);
}

export function normalizePercentageDifference(value: number) {
  if (value <= 80) {
    return 60;
  }

  if (value <= 90) {
    return 70;
  }

  return 100;
}
