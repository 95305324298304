import { APITypesV1 } from "@cur8/api-client";
import {
  Annotation,
  BoundingBoxAnnotation,
  Box,
  hasBoundingBox,
} from "@cur8/rich-entity";
import { RecordingURI, URIType } from "@cur8/uri";
import { APIClient } from "lib/api/client";
import { DermoscopyAnnotation } from "lib/lesion";
import { clamp } from "lib/math";
import { OutputImageSize, SourceImageCrop } from "../types";
import { PanoramaImageURI, RGBInoImageURI } from "../uri";

export function matchesPanorama(
  annotation: Annotation,
  panoURI: PanoramaImageURI
): annotation is BoundingBoxAnnotation {
  if (!hasBoundingBox(annotation)) {
    return false;
  }

  const appTarget = annotation.applicationSpecificTarget;
  if (!appTarget) {
    return false;
  }

  const uri = RGBInoImageURI.parse(appTarget);
  if (!uri) {
    return false;
  }

  if (uri.side === panoURI.side && uri.module === panoURI.cameraName) {
    return true;
  }

  return false;
}

export async function exhaustAnnotations(
  api: APIClient["annotation"],
  query: Parameters<APIClient["annotation"]["queryAnnotations"]>[0],
  max: number
) {
  const fetchNextPage = (continuationToken: string | undefined) => {
    return api.queryAnnotations({
      ...query,
      continuationToken: continuationToken,
    }).result;
  };

  const fetchAnnotations = async () => {
    const buffer: APITypesV1.Annotation[] = [];

    let nextPage: string | undefined;
    do {
      const res = await fetchNextPage(nextPage);
      buffer.push(...res.items);
      nextPage = res.nextPage;
    } while (nextPage && buffer.length < max);

    return buffer;
  };

  return (await fetchAnnotations()).slice(0, max);
}

export async function fetchDetections(
  api: APIClient["annotation"],
  patientId: string,
  panoramaURI: PanoramaImageURI,
  maxDetections: number
) {
  const targetUri = new RecordingURI(
    panoramaURI.deviceId,
    panoramaURI.recordingId
  ).toString();

  const applicationSpecificTarget = `rgbino:${panoramaURI.deviceId}:${panoramaURI.recordingId}:${panoramaURI.side}:${panoramaURI.cameraName}`;

  return exhaustAnnotations(
    api,
    {
      patientId,
      targetUri,
      applicationSpecificTarget,
      minSize: 1.0,
      pageSize: 100,
    },
    maxDetections
  );
}

export function fetchDermaImage(
  api: APIClient,
  annotation: DermoscopyAnnotation
) {
  const target = annotation.targetURI;
  return api.blob.fetchPatientBlob(target);
}

export function fetchLesionImage(
  api: APIClient,
  annotation: BoundingBoxAnnotation,
  bounds?: SourceImageCrop,
  size?: OutputImageSize
) {
  const crop = bounds ?? annotation.data.rect;
  const target = annotation.targetURI;
  if (target.type === URIType.ImmutableScanBlob) {
    return api.transcode.fetchScanBlob(target, crop, size, {
      sharpen: 1,
    });
  }

  if (target.type === URIType.FrameSequence) {
    return api.transcode.fetchFrame(target, crop, size, { sharpen: 1 });
  }

  console.warn(
    "No suitable image source for annotation",
    annotation,
    annotation.targetURI
  );
}

export function overscan(crop: Box, factor: number): Box {
  const { h, w, x, y } = crop;

  const center = {
    x: x + w / 2,
    y: y + h / 2,
  };

  const size = Math.max(w, h) * factor;

  return new Box(center.x - size / 2, center.y - size / 2, size, size);
}

export function clampBox(crop: Box, bounds: Box): Box {
  const clamped = new Box(0, 0, 0, 0);
  clamped.w = Math.min(crop.w, bounds.w);
  clamped.h = Math.min(crop.h, bounds.h);
  clamped.x = clamp(crop.x, 0, bounds.w - crop.w);
  clamped.y = clamp(crop.y, 0, bounds.h - crop.h);
  return clamped;
}
