import { APITypesV1 } from "@cur8/api-client";
import { useMemo } from "react";
import { usePatientVisitSummaryQuery } from "render/hooks/api/usePatientVisitSummaryQuery";

export function usePatientVisitSummaries(patientId: string) {
  const namespace = useMemo(() => {
    return `patient/${patientId}/details-page-paired`;
  }, [patientId]);

  const options = useMemo(() => {
    return {
      patientId,
      order: APITypesV1.SortOrder.Desc,
      pageSize: 25,
    };
  }, [patientId]);

  return usePatientVisitSummaryQuery(options, namespace);
}
