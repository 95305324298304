import { Length } from "@cur8/measurements";
import { toFeet } from "lib/conversion/height";
import { useUnitConverter } from "render/hooks/conversion/useUnitConverter";
import { Typography } from "render/ui/presentation/Typography";
import ValueItem from "render/ui/symbol/ValueItem";
import styles from "./styles.module.sass";

function HeightInFeet({ height }: { height: Length }) {
  const value = toFeet(height).feet;
  const feet = Math.floor(value);
  const inch = Math.floor((value - feet) / 0.083333);

  return (
    <>
      {feet}' {inch}"
    </>
  );
}

function HeightInCentimeters({ height }: { height: Length }) {
  const cm = height.meters * 100;

  return <>{cm.toFixed(0)}</>;
}

interface HeightProps {
  height: Length;
}

export default function Height({ height }: HeightProps) {
  const conv = useUnitConverter().height;
  const unit = conv.unit;

  return (
    <div className={styles.Height}>
      <label>
        <Typography as="span" variant="label-m">
          Height
        </Typography>
      </label>
      {unit === "feet" ? (
        <ValueItem value={<HeightInFeet height={height} />} size="huge" />
      ) : (
        <ValueItem
          value={<HeightInCentimeters height={height} />}
          unit="cm"
          size="huge"
        />
      )}
    </div>
  );
}
