import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import PulseWaveLocation from "render/fragments/avatar/overlay/PulseWaveLocation";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { Layer } from "render/pages/DashboardPage/types";
import Overlay from "../../components/Overlay";
import styles from "./styles.module.sass";

interface PiezoLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export default function PiezoLayer({
  layer,
  areas,
  projector,
}: PiezoLayerProps) {
  const positions = useMemo(() => {
    const leftHand = toCenter(areas.leftHand);
    const rightHand = toCenter(areas.rightHand);

    const leftToe = toCenter(areas.leftToe);
    const rightToe = toCenter(areas.rightToe);

    // Pretty sure toes are on the ground
    leftToe.z = 0;
    rightToe.z = 0;

    // Adjust closer to assumption of finger location
    leftHand.z -= 80;
    rightHand.z -= 80;

    return {
      leftHand,
      rightHand,

      leftToe,
      rightToe,
    };
  }, [areas]);

  const active = layer === Layer.PulseWaves;

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <div className={styles.PiezoLayer} data-active={active}>
      {anchors && (
        <>
          <Overlay pos={anchors.leftHand}>
            <div className={styles.item}>
              <PulseWaveLocation />
            </div>
          </Overlay>

          <Overlay pos={anchors.rightHand}>
            <div className={styles.item}>
              <PulseWaveLocation />
            </div>
          </Overlay>

          <Overlay pos={anchors.leftToe}>
            <div className={styles.item}>
              <PulseWaveLocation />
            </div>
          </Overlay>

          <Overlay pos={anchors.rightToe}>
            <div className={styles.item}>
              <PulseWaveLocation />
            </div>
          </Overlay>
        </>
      )}
    </div>
  );
}
