import { DateTime } from "luxon";
import FullDate from "render/ui/format/FullDate";
import styles from "./styles.module.sass";

interface ArtefactHistoryEntryTitleProps {
  caption: React.ReactNode;
  date: DateTime;
}

export default function ArtefactHistoryEntryTitle({
  caption,
  date,
}: ArtefactHistoryEntryTitleProps) {
  return (
    <div className={styles.ArtefactHistoryEntryTitle}>
      <span className={styles.caption}>{caption}</span>
      &ensp;
      <span className={styles.date}>
        <FullDate date={date} />
      </span>
    </div>
  );
}
