import { OutputImageSize, SourceImageCrop } from "./types";

export function normalizeSize(
  size?: OutputImageSize,
  crop?: SourceImageCrop
): OutputImageSize | undefined {
  if (!size) {
    return;
  }

  if (!crop) {
    return size;
  }

  return {
    ...size,
    w: Math.min(crop.w, size.w),
    h: Math.min(crop.h, size.h),
  };
}
