import { APITypesV1 } from "@cur8/api-client";
import { BoundingBoxAnnotation } from "@cur8/rich-entity";
import { Point } from "lib/math";
import { Matrix3, Vector3 } from "three";
import { Homographies } from "./types/homographies";

export type PanoramaCoord = {
  center: Point;
  radius: number;
};

const computePanoramaCoords = (
  coords: Point[],
  h: Array<Array<number>>
): Point[] => {
  const pCoords: Point[] = [];

  const hMatrix = new Matrix3();
  hMatrix.set(
    h[0][0],
    h[0][1],
    h[0][2],
    h[1][0],
    h[1][1],
    h[1][2],
    h[2][0],
    h[2][1],
    h[2][2]
  );

  coords.forEach((c) => {
    const p = new Vector3(c.x, c.y, 1.0);
    p.applyMatrix3(hMatrix);
    pCoords.push(new Point(p.x / p.z, p.y / p.z));
  });

  return pCoords;
};

export function panoramaCoords(
  annotation: BoundingBoxAnnotation,
  homographies: Homographies
): PanoramaCoord | undefined {
  if (
    annotation.applicationSpecificTarget &&
    homographies &&
    (annotation.classification?.$type === "mole" ||
      annotation.classification?.$type === "other")
  ) {
    const tokens = annotation.applicationSpecificTarget.split(":");
    const mirrorPosition = `${tokens[5]}_${tokens[6]}`;
    const h = homographies[mirrorPosition];
    const r = annotation.data.rect;

    const classification:
      | APITypesV1.MoleClassification
      | APITypesV1.OtherClassification =
      annotation.classification.$type === "mole"
        ? (annotation.classification as APITypesV1.MoleClassification)
        : (annotation.classification as APITypesV1.OtherClassification);

    const cc = classification.center;

    // Correction for offset from bb center:
    const dx = cc ? r.w / 2 - cc[0] : 0;
    const dy = cc ? r.h / 2 - cc[1] : 0;

    const center = new Point(r.left + r.w / 2 - dx, r.top + r.h / 2 - dy);

    const tl = new Point(r.left - dx, r.top - dy);
    const tr = new Point(r.left + r.w - dx, r.top + -dy);
    const bl = new Point(r.left - dx, r.top + r.h - dy);
    const br = new Point(r.left + r.w - dx, r.top + r.h - dy);

    const pos = computePanoramaCoords([center, tl, tr, bl, br], h);

    const radius = Math.max(
      pos[0].distanceTo(pos[1]),
      pos[0].distanceTo(pos[2]),
      pos[0].distanceTo(pos[3]),
      pos[0].distanceTo(pos[4])
    );

    return {
      center: pos[0],
      radius: radius,
    };
  }
  return undefined;
}
