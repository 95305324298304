import styles from "./styles.module.sass";

type Direction = "left" | "right";

export default function ArrowIcon({
  direction = "left",
}: {
  direction?: Direction;
}) {
  return (
    <svg
      className={styles.ArrowIcon}
      data-dir={direction}
      width="19"
      height="10"
      viewBox="0 0 19 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1.75 5.49707L18.25 5.49707" />
      <path d="M4.5 1.74707L0.75 5.49707L4.5 9.24707" />
    </svg>
  );
}
