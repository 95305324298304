import { Children } from "react";
import SlidingWindow from "render/ui/layouts/SlidingWindow";
import styles from "./styles.module.sass";

interface ViewSwitcherProps {
  activeIndex: number;
  children: React.ReactElement[];
}

export default function ViewSwitcher({
  activeIndex,
  children,
}: ViewSwitcherProps) {
  return (
    <div className={styles.ViewSwitcher}>
      <SlidingWindow activeIndex={activeIndex}>
        {Children.map(children, (child, index) => {
          const offset = Math.abs(index - activeIndex);

          return (
            <div
              key={index}
              className={styles.container}
              style={{
                transform: `
                  scale(${offset === 0 ? 1 : 0.9})
                `,
              }}
            >
              {child}
            </div>
          );
        })}
      </SlidingWindow>
    </div>
  );
}
