import { useEffect } from "react";
import { useLocation } from "@pomle/react-router-paths";
import { useAppInsights } from "render/context/AppInsightsContext";

export function useTrackPage() {
  const { pathname } = useLocation();
  const appInsights = useAppInsights();

  useEffect(() => {
    if (appInsights) {
      appInsights.trackPageView();
    }
  }, [appInsights, pathname]);
}
