export interface Point {
  x: number;
  y: number;
}

/**
 * Based on input points, creates a string with SVG path commands ("d" attribute)
 */
export function createPathString(
  points: Point[],
  options: { smooth?: boolean } = { smooth: false }
): string {
  if (points.length < 2) {
    console.warn("At least two points are required to create a path.");
    return "";
  }

  const pathCommands: string[] = [];
  pathCommands.push(`M ${points[0].x},${points[0].y}`); // Move to the first point

  if (options.smooth) {
    // Use quadratic Bezier curves
    for (let i = 1; i < points.length - 1; i++) {
      const xAvg = (points[i].x + points[i + 1].x) / 2;
      const yAvg = (points[i].y + points[i + 1].y) / 2;
      pathCommands.push(`Q ${points[i].x},${points[i].y} ${xAvg},${yAvg}`);
    }
    pathCommands.push(
      `T ${points[points.length - 1].x},${points[points.length - 1].y}`
    );
  } else {
    // Use straight lines
    for (let i = 1; i < points.length; i++) {
      pathCommands.push(`L ${points[i].x},${points[i].y}`);
    }
  }

  return pathCommands.join(" ");
}

/**
 * Based on the "d" attribute, returns a new SVG <path> element
 */
export const createPathElement = (d = ""): SVGPathElement => {
  const svgNS = "http://www.w3.org/2000/svg";
  const path = document.createElementNS(svgNS, "path");
  path.setAttribute("d", d);
  return path;
};
