import { getRebookingDiscountCode } from "render/hooks/api/billing/useBookingPrice";

const baseUrlMap: Record<string, string> = {
  localhost: "patient.dev.cur8.co",
  "doctor.dev.cur8.co": "patient.dev.cur8.co",
  "doctor.preprod.cur8.co": "patient.preprod.cur8.co",
  "doctor.cur8.co": "app.nekohealth.com",
};

function getPatientUrl(path: string) {
  const domain = baseUrlMap[window.location.hostname];
  const url = new URL(`https://${domain}/` + path);
  return url.toString();
}

function getPatientPaymentUrl({ code }: { code?: string }) {
  let base = "payment";
  const params: string[] = [];
  if (code != null) {
    params.push(`code=${code}`);
  }

  if (params.length) {
    base = [base, params.join("&")].join("?");
  }

  return getPatientUrl(base);
}

export function getPatientAppPaymentUrl(country: string) {
  const code = getRebookingDiscountCode(country);
  const result = getPatientPaymentUrl({
    code,
  });

  return result;
}
