import { useNav, PathLink } from "@pomle/react-router-paths";
import { Patient, Recording } from "@cur8/rich-entity";
import { paths } from "render/routes/paths";
import DayDate from "render/ui/format/DayDate";
import PatientAge from "../PatientAge";
import PatientName from "../PatientName";
import PatientPhysicalIdentifier from "../PatientPhysicalIdentifier";
import styles from "./styles.module.sass";

interface PatientSummaryProps {
  patient: Patient;
  recording: Recording;
}

export default function PatientSummary({
  patient,
  recording,
}: PatientSummaryProps) {
  const patientId = patient.patientId;

  const nav = {
    patient: useNav(paths.patient.detail),
  };
  return (
    <div className={styles.PatientSummary}>
      <div className={styles.identity}>
        <div className={styles.name}>
          <PathLink to={nav.patient.to({ patientId })}>
            <PatientName patient={patient} />
          </PathLink>
        </div>
        <div>
          <PatientPhysicalIdentifier patient={patient} />
          {patient.dateOfBirth && (
            <>
              {" "}
              (<PatientAge patient={patient} /> years)
            </>
          )}
        </div>
      </div>
      <div className={styles.date}>
        This Scan: <DayDate date={recording.createdAt} />
      </div>
    </div>
  );
}
