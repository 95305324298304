interface CardioGridProps extends React.SVGAttributes<unknown> {
  horizontal: number[];
  vertical: number[];
}

export default function CardioGrid({
  horizontal,
  vertical,
  ...props
}: CardioGridProps) {
  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none" {...props}>
      <g stroke="#c5d9dd" strokeWidth={1}>
        {vertical.map((x) => {
          return (
            <line
              key={x}
              x1={x}
              x2={x}
              y1={0}
              y2={100}
              vectorEffect="non-scaling-stroke"
            />
          );
        })}

        {horizontal.map((y) => {
          return (
            <line
              key={y}
              x1={0}
              x2={100}
              y1={y}
              y2={y}
              vectorEffect="non-scaling-stroke"
            />
          );
        })}
      </g>
    </svg>
  );
}
