import styles from "./styles.module.sass";

interface PageHeaderProps {
  caption: React.ReactNode;
  children?: React.ReactNode;
}

export default function PageHeader({ caption, children }: PageHeaderProps) {
  return (
    <header className={styles.PageHeader}>
      <h2>{caption}</h2>

      <div>{children}</div>
    </header>
  );
}
