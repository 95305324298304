import { APITypesV2 } from "@cur8/api-client";
import { useMemo } from "react";
import { usePatientBookingTokenQuery } from "./usePatientBookingTokenQuery";

export function usePatientBookingToken(patientId: string) {
  const namespace = useMemo(() => {
    return `patient/${patientId}/bookingTokens`;
  }, [patientId]);

  const options: APITypesV2.BookingTokenQueryPatientsParams = useMemo(() => {
    return {
      patientId,
      availableForBooking: true,
      pageSize: 100,
    };
  }, [patientId]);

  return usePatientBookingTokenQuery(options, namespace);
}
