import { GripRecord } from "lib/doctor-scribe/types";

export const femaleGripData: GripRecord[] = [
  {
    age: {
      from: 0,
      to: 29,
    },
    left: {
      average: 27.9,
      std: 4.69,
    },
    right: {
      average: 30.07,
      std: 5.1,
    },
    diff: {
      average: 3.08,
      std: 2.46,
    },
  },
  {
    age: {
      from: 30,
      to: 34,
    },
    left: {
      average: 27.53,
      std: 4.94,
    },
    right: {
      average: 29.2,
      std: 5.61,
    },
    diff: {
      average: 2.63,
      std: 2.49,
    },
  },
  {
    age: {
      from: 35,
      to: 39,
    },
    left: {
      average: 28.36,
      std: 3.93,
    },
    right: {
      average: 29.99,
      std: 4.53,
    },
    diff: {
      average: 2.94,
      std: 1.94,
    },
  },
  {
    age: {
      from: 40,
      to: 44,
    },
    left: {
      average: 28.83,
      std: 4.9,
    },
    right: {
      average: 30.97,
      std: 4.98,
    },
    diff: {
      average: 2.7,
      std: 2.21,
    },
  },
  {
    age: {
      from: 45,
      to: 49,
    },
    left: {
      average: 28.87,
      std: 4.99,
    },
    right: {
      average: 30.4,
      std: 5.04,
    },
    diff: {
      average: 2.46,
      std: 1.88,
    },
  },
  {
    age: {
      from: 50,
      to: 54,
    },
    left: {
      average: 28.42,
      std: 4.56,
    },
    right: {
      average: 30.09,
      std: 5.04,
    },
    diff: {
      average: 2.62,
      std: 2.37,
    },
  },
  {
    age: {
      from: 55,
      to: 59,
    },
    left: {
      average: 28.07,
      std: 4.12,
    },
    right: {
      average: 29.41,
      std: 4.29,
    },
    diff: {
      average: 2.52,
      std: 2.14,
    },
  },
  {
    age: {
      from: 60,
      to: 64,
    },
    left: {
      average: 24.89,
      std: 4.18,
    },
    right: {
      average: 26.68,
      std: 4.41,
    },
    diff: {
      average: 2.71,
      std: 2.47,
    },
  },
  {
    age: {
      from: 65,
      to: 69,
    },
    left: {
      average: 24,
      std: 4.63,
    },
    right: {
      average: 24.82,
      std: 4.3,
    },
    diff: {
      average: 2.25,
      std: 2.14,
    },
  },
  {
    age: {
      from: 70,
      to: 100,
    },
    left: {
      average: 21.53,
      std: 4.18,
    },
    right: {
      average: 22.79,
      std: 4.13,
    },
    diff: {
      average: 1.98,
      std: 1.62,
    },
  },
];
