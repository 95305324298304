import { APITypesV1 } from "@cur8/api-client";
import { Assessment } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import AssessedBy from "./AssessedBy";
import styles from "./styles.module.sass";

interface DisplayAssessmentProps {
  assessment: Assessment;
}

function readableState(state: APITypesV1.AssessmentState): string {
  switch (state) {
    case APITypesV1.AssessmentState.Cleared:
      return "Cleared";
    case APITypesV1.AssessmentState.FollowUpRequested:
      return "Follow up";
    case APITypesV1.AssessmentState.ReviewRequested:
      return "Review requested";
    case APITypesV1.AssessmentState.New:
    default:
      return "Unassessed";
  }
}

export default function DisplayAssessment({
  assessment,
}: DisplayAssessmentProps) {
  return (
    <div
      className={classNames(styles.DisplayAssessment, styles.assessmentInfo)}
      data-state={assessment.assessmentState}
    >
      <div className={styles.item}>
        <label>State</label>
        <span className={styles.state}>
          {readableState(assessment.assessmentState)}
        </span>
      </div>
      {assessment.comment && (
        <div className={styles.item}>
          <label>Comment</label>
          <span className={styles.comment}>{assessment.comment}</span>
        </div>
      )}
      <div className={styles.byline}>
        <label>Created</label>
        <span>{assessment.createdAt.toFormat("yyyy-LL-dd HH:mm")}</span>
      </div>
      {assessment.audit && (
        <div className={styles.byline}>
          <label>by</label>
          <span>
            <AssessedBy auditInfo={assessment.audit} />
          </span>
        </div>
      )}
    </div>
  );
}
