import { useCallback } from "react";
import CheckIcon from "./assets/check.svg?react";
import styles from "./styles.module.sass";

interface CheckboxProps {
  label: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export default function Checkbox({
  label,
  checked,
  onChange,
  disabled = false,
}: CheckboxProps) {
  const handleClick = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);

  return (
    <button
      disabled={disabled}
      className={styles.Checkbox}
      type="button"
      value={checked ? "on" : "off"}
      onClick={handleClick}
    >
      {label}
      <CheckIcon />
    </button>
  );
}
