import { useContext } from "react";
import { Context } from "./context";

export function useTissueImageContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useTissueImageContext without TissueImageContext");
  }
  return context;
}
