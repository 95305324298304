import { useCallback, useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { fromAPI } from "@cur8/rich-entity";
import { useStore } from "render/context/StoreContext";
import { useEntries } from "render/hooks/store/refs";
import { APITypesV1 } from "@cur8/api-client";
import { useReporting } from "../useReporting";

export function useAnnotationQuery(
  options: APITypesV1.AnnotationListByPatientParams,
  namespace: string
) {
  const { handleError } = useReporting();

  const api = useAPIClient().annotation;

  const annotationStore = useStore().annotation;

  const { getEntries, setEntries } = useEntries(annotationStore, namespace);

  const fetch = useCallback(() => {
    return api
      .queryAnnotations(options)
      .result.then((result) => {
        const annotations = result.items.map(fromAPI.toAnnotation);
        setEntries(annotations);
      })
      .catch(handleError);
  }, [api, setEntries, options, handleError]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return useMemo(() => {
    return {
      entries: getEntries(),
      fetch,
    };
  }, [getEntries, fetch]);
}
