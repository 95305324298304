import { useEffect, useState } from "react";
import TextScrambler from "../TextScrambler";

interface TextShifterProps {
  texts: readonly string[];
  delay: number;
}

export default function TextShifter({ texts, delay }: TextShifterProps) {
  const [activeText, setActiveText] = useState("");

  useEffect(() => {
    let index = 0;
    let timer: NodeJS.Timeout;

    const next = () => {
      const text = texts.at(index++);
      if (text != null) {
        setActiveText(text);
        timer = setTimeout(next, delay * 1000);
      } else {
        setActiveText("");
      }
    };

    next();

    return () => {
      clearTimeout(timer);
    };
  }, [texts, delay]);

  return <TextScrambler text={activeText} duration={1} framerate={1 / 30} />;
}
