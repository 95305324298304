import { distinct } from "lib/filter";
import { Lesion, byLinkDate, isPreferredLink } from "lib/lesion";
import { range } from "lib/map";
import { useMemo } from "react";
import { Typography } from "render/ui/presentation/Typography";
import StudySymbol from "render/ui/symbol/StudySymbol";
import LesionEvolutionImage from "./components/LesionEvolutionImage";
import LesionEvolutionPlaceholder from "./components/LesionEvolutionPlaceholder";
import styles from "./styles.module.sass";

interface LesionMacroEvolutionProps {
  selected: Lesion | undefined;
}

export default function LesionMacroEvolution({
  selected,
}: LesionMacroEvolutionProps) {
  const evolutions = useMemo(() => {
    return selected?.links
      .filter(isPreferredLink)
      .toSorted(byLinkDate("desc"))
      .filter(distinct((link) => link.scan))
      .reverse();
  }, [selected]);

  return (
    <div className={styles.LesionMacroEvolution}>
      <div className={styles.header}>
        <Typography variant="title-s">
          Evolution <StudySymbol />
        </Typography>
      </div>

      <div className={styles.evolutions}>
        <ol className={styles.history}>
          {range(0, 4, (index) => {
            const link = evolutions?.at(index);
            const scan = link?.scan;
            const anno = link?.annotation;
            const date = scan?.timestamp;
            return (
              <li
                key={anno?.id ?? index}
                className={styles.item}
                data-scan-id={scan?.id}
              >
                {anno ? (
                  <LesionEvolutionImage annotation={anno} />
                ) : (
                  <LesionEvolutionPlaceholder />
                )}

                <div className={styles.caption}>
                  <div className={styles.scanIndex}>
                    <Typography variant="eyebrow-m">
                      Scan {index + 1}
                    </Typography>
                  </div>
                  <time className={styles.date} dateTime={date?.toISO()}>
                    <Typography variant="label-m">
                      {date ? date.toFormat("LLLL yyyy") : "—"}
                    </Typography>
                  </time>
                </div>
              </li>
            );
          })}
        </ol>
      </div>
    </div>
  );
}
