import { APITypesV1 } from "@cur8/api-client";
import { fromAPI } from "@cur8/rich-entity";
import { ScanURI } from "@cur8/uri";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { TissueAnnotation, TissueAnnotationTypes } from "../lib/types";
import { toTissueAnnotation } from "../lib/utils";

export function useAnnotation(
  patientId: string | undefined,
  scanId: string | undefined,
  scanVersion: string | undefined
) {
  const annoApi = useAPIClient().annotation;

  const [annotations, setAnnotations] = useState<TissueAnnotation[]>([]);

  useEffect(() => {
    return () => {
      setAnnotations([]);
    };
  }, [scanId, scanVersion]);

  const fetch = useCallback(() => {
    if (!patientId || !scanId || !scanVersion) {
      return;
    }
    const targetUri = new ScanURI(patientId, scanId, scanVersion).toString();
    annoApi
      .queryAnnotations({
        patientId,
        targetUri,
        pageSize: 100,
        order: APITypesV1.SortOrder.Asc,
      })
      .result.then((resp) => {
        const annos = resp.items.map(
          fromAPI.toAnnotation
        ) as TissueAnnotationTypes[];
        const all = [] as TissueAnnotation[];

        annos.forEach((anno: TissueAnnotationTypes) => {
          if (
            anno.acceptState === APITypesV1.AcceptState.Accepted ||
            anno.acceptState === APITypesV1.AcceptState.Proposed
          ) {
            all.push(toTissueAnnotation(anno, all.length));
          }
        });
        setAnnotations(all);
      });
  }, [annoApi, patientId, scanId, scanVersion]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    annotations,
    fetch,
    setAnnotations,
  };
}
