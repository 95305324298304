import { Patient, Sex } from "@cur8/rich-entity";
import { useCallback, useState } from "react";
import { CardioSignals } from "../../hooks/useCardioSignals";
import { Range } from "../shared/DataWindow/DataWindow";
import GraphWithRuler from "../shared/GraphWithRuler";
import { RulerData } from "../shared/GraphWithRuler/GraphWithRuler";
import { ECG_COLOR, SSN_COLOR } from "../shared/constants";
import { JSXResult } from "../shared/fragments";
interface EjectionTimeProps {
  patient: Patient | undefined;
  signals: CardioSignals | undefined;
  range: Range;
  setRange: React.Dispatch<React.SetStateAction<Range>>;
}

export default function EjectionTime({
  patient,
  signals,
  range,
  setRange,
}: EjectionTimeProps) {
  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure LVETI")
  );

  const handleMeasurement = useCallback(
    (data: RulerData | undefined) => {
      const HR = signals?.heart_rate?.heartRate;

      if (!(data && patient)) {
        return;
      }

      if (patient.sex === Sex.Unknown) {
        setResultElement(
          JSXResult(
            "Cannot compute LVETI",
            "Member's sex not specified",
            `LVET: ${data.distanceMs} ms`
          )
        );
        return;
      }

      if (HR === undefined) {
        setResultElement(
          JSXResult(
            "Cannot compute LVETI",
            "No average heart rate computed",
            `LVET: ${data.distanceMs} ms`
          )
        );
        return;
      }

      const lveti = Math.round(
        patient?.sex === Sex.Female
          ? 1.51 * HR + data.distanceMs
          : 1.38 * HR + data.distanceMs
      );

      setResultElement(
        JSXResult(
          `LVETI: ${lveti} ms`,
          `${Sex[patient.sex]}, average heart rate: ${Math.round(
            HR
          )} bpm, LVET: ${data.distanceMs} ms`
        )
      );
    },
    [patient, signals?.heart_rate?.heartRate]
  );

  return signals ? (
    <div>
      <GraphWithRuler
        title="Ejection Time"
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.ecg,
            color: ECG_COLOR,
          },
          { label: "SSN", signal: signals.ssn, color: SSN_COLOR },
        ]}
        diffData={[
          {
            label: "SSN Acceleration",
            signal: signals.ssn_acc,
            color: SSN_COLOR,
          },
        ]}
        onMeasurement={handleMeasurement}
        range={range}
        setRange={setRange}
      />
      {resultElement}
    </div>
  ) : null;
}
