import { Risk } from "@cur8/health-risks-calc";
import MetricStatusBadge from "../MetricStatusBadge";
import { Typography } from "../Typography";
import styles from "./styles.module.sass";
interface FixedAvatarValueProps {
  label: React.ReactNode;
  value: React.ReactNode;
  risk: Risk;
  align?: "left" | "right";
}

export default function FixedAvatarValue({
  label,
  value,
  risk,
  align = "left",
}: FixedAvatarValueProps) {
  return (
    <div className={styles.FixedAvatarValue} data-align={align}>
      {value}
      <label className={styles.label}>
        <Typography as="span" variant="label-m">
          {label}
        </Typography>
      </label>
      {risk && <MetricStatusBadge risk={risk} />}
    </div>
  );
}
