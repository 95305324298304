import { useCallback } from "react";
import { useTissueAnnotationContext } from "render/pages/TissuePages/context/TissueAnnotationContext";
import { ImageSize } from "render/pages/TissuePages/lib/types";
import AddAnnotation from "./AddAnnotation";
import SvgLine from "./SvgLine";
import SvgRect from "./SvgRect";
import styles from "./styles.module.sass";

interface AnnotationsProps {
  allowResize?: boolean;
  canvasSize: ImageSize;
  onAnnoCreated: () => void;
  scale: number;
}

export default function Annotations({
  allowResize = true,
  canvasSize,
  onAnnoCreated,
  scale,
}: AnnotationsProps) {
  const { cancelCreate, filtered, newAnno, selected } =
    useTissueAnnotationContext();

  const cancel = useCallback(() => {
    cancelCreate();
  }, [cancelCreate]);

  return (
    <g className={styles.Annos}>
      {filtered.roi.map((anno) => {
        const box = anno.annotation.data.rect;
        return (
          <SvgRect
            key={anno.annotation.id}
            allowResize={allowResize}
            anno={anno}
            box={box}
            canvasSize={canvasSize}
            isSelected={selected === anno.annotation.id}
            scale={scale}
          />
        );
      })}
      {filtered.lines.map((anno) => {
        return (
          <SvgLine
            key={anno.annotation.id}
            anno={anno}
            canvasSize={canvasSize}
            isSelected={selected === anno.annotation.id}
            line={anno.annotation.data.line}
            scale={scale}
          />
        );
      })}
      {newAnno && (
        <AddAnnotation
          canvasSize={canvasSize}
          onCancelCreate={cancel}
          onCreated={onAnnoCreated}
          scale={scale}
        />
      )}
    </g>
  );
}
