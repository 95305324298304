import { APITypesV1 } from "@cur8/api-client";
import { ArtefactStateColor } from "render/pages/AtlasPage/markingColors";
import styles from "./styles.module.sass";

interface PhysicalArtefactStateMarkerProps {
  state: APITypesV1.PhysicalArtefactState;
}

export default function PhysicalArtefactStateMarker({
  state,
}: PhysicalArtefactStateMarkerProps) {
  const color = ArtefactStateColor[state];

  return (
    <div
      className={styles.PhysicalArtefactStateMarker}
      style={{ backgroundColor: color }}
    />
  );
}
