import { Metric } from "lib/metric";
import { useCallback } from "react";
import { useMetricAPI } from "render/hooks/api/useMetricAPI";
import { toMetricList } from "lib/metric";

export type CardioMetrics = {
  ankle: {
    left?: Metric<"cardio.ankle_pressure.left">;
    right?: Metric<"cardio.ankle_pressure.right">;
  };

  brachial: {
    left?: Metric<"cardio.brachial_pressure.left">;
    right?: Metric<"cardio.brachial_pressure.right">;
  };

  murmur: {
    diastolic?: Metric<"cardio.murmur.diastolic">;
    systolic?: Metric<"cardio.murmur.systolic">;
  };

  toe: {
    left?: Metric<"cardio.toe_pressure.left">;
    right?: Metric<"cardio.toe_pressure.right">;
  };

  ecgDiscrepancy?: Metric<"risk_assessment.ecg_discrepancy">;
  bloodOxygen?: Metric<"cardio.oxygen_saturation">;

  manual: {
    brachial?: Metric<"cardio.manual_bp.brachial">;
    femoral?: Metric<"cardio.manual_bp.femoral">;
  };
};

export function useCardioMetrics() {
  const { fetchMetrics, storeMetrics } = useMetricAPI();

  const fetchCardioMetrics = useCallback(
    async (patientId: string): Promise<CardioMetrics> => {
      const [
        ankleLeft,
        ankleRight,
        brachialLeft,
        brachialRight,
        murmurDiastolic,
        murmurSystolic,
        toeLeft,
        toeRight,

        ecgDiscrepancy,
        bloodOxygen,

        manualBrachial,
        manualFemoral,
      ] = await Promise.all([
        fetchMetrics(patientId, "cardio.ankle_pressure.left"),
        fetchMetrics(patientId, "cardio.ankle_pressure.right"),

        fetchMetrics(patientId, "cardio.brachial_pressure.left"),
        fetchMetrics(patientId, "cardio.brachial_pressure.right"),

        fetchMetrics(patientId, "cardio.murmur.diastolic"),
        fetchMetrics(patientId, "cardio.murmur.systolic"),

        fetchMetrics(patientId, "cardio.toe_pressure.left"),
        fetchMetrics(patientId, "cardio.toe_pressure.right"),

        fetchMetrics(patientId, "risk_assessment.ecg_discrepancy"),
        fetchMetrics(patientId, "cardio.oxygen_saturation"),

        fetchMetrics(patientId, "cardio.manual_bp.brachial"),
        fetchMetrics(patientId, "cardio.manual_bp.femoral"),
      ]);

      return {
        ankle: {
          left: ankleLeft.at(0),
          right: ankleRight.at(0),
        },

        brachial: {
          left: brachialLeft.at(0),
          right: brachialRight.at(0),
        },

        murmur: {
          diastolic: murmurDiastolic.at(0),
          systolic: murmurSystolic.at(0),
        },

        toe: {
          left: toeLeft.at(0),
          right: toeRight.at(0),
        },

        ecgDiscrepancy: ecgDiscrepancy.at(0),
        bloodOxygen: bloodOxygen.at(0),

        manual: {
          brachial: manualBrachial.at(0),
          femoral: manualFemoral.at(0),
        },
      };
    },
    [fetchMetrics]
  );

  const storeCardioMetrics = useCallback(
    async (patientId: string, metrics: CardioMetrics) => {
      const all = [
        ...toMetricList(metrics.ankle),
        ...toMetricList(metrics.brachial),
        ...toMetricList(metrics.manual),
        ...toMetricList(metrics.toe),
      ];

      if (metrics.bloodOxygen) {
        all.push(metrics.bloodOxygen);
      }
      if (metrics.ecgDiscrepancy) {
        all.push(metrics.ecgDiscrepancy);
      }
      if (metrics.murmur.diastolic) {
        all.push(metrics.murmur.diastolic);
      }
      if (metrics.murmur.systolic) {
        all.push(metrics.murmur.systolic);
      }

      return storeMetrics(patientId, all);
    },

    [storeMetrics]
  );

  return {
    fetchCardioMetrics,
    storeCardioMetrics,
  };
}
