import { APITypesV2 } from "@cur8/api-client";
import { APIClient } from "lib/api/client";

export function fetchQuestionnaireResponses(
  api: APIClient,
  patientId: string,
  visitId: string | undefined
) {
  return api.questionnaire.getQuestionnaireResponses(
    { patientId },
    { visitId }
  );
}

export function fetchLatestOnboardingQuestionnaireResponse(
  api: APIClient,
  patientId: string,
  visitId: string | undefined
) {
  const request = fetchQuestionnaireResponses(api, patientId, visitId);

  return {
    result: request.result.then((q) => getLatestOnboarding(q.items)),
    abandon: request.abandon,
  };
}

function getLatestOnboarding(responses: APITypesV2.QuestionnaireResponse[]) {
  return responses
    .filter((r) => r.state === APITypesV2.QuestionnaireResponseState.Complete)
    .find((r) => r.questionnaireType.startsWith("onboarding/"));
}
