import { APITypesV1 } from "@cur8/api-client";
import {
  AktiiaMonitorBloodPressureAssignmentData,
  Assignment,
} from "@cur8/rich-entity";
import { DateTime } from "luxon";

export type Entries = {
  serialNo: string;
  serialNoAlias: string;
};

export function toCreateAssignment(
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>
): APITypesV1.CreateAssignmentRequest {
  const data: APITypesV1.AktiiaMonitorBloodPressureAssignmentRequest = {
    $type: "AktiiaMonitorBloodPressureAssignment",
    deviceSerialNumber: assignment.data.deviceSerialNumber,
    minCompletedMeasurements: 7,
    returnAt: assignment.data.returnAt.toISO(),
    wearableLocation: assignment.data.wearableLocation,
  };

  return {
    startAt: assignment.startAt.toISO(),
    finishAt: assignment.finishAt.toISO(),
    data,
  };
}

export function createInitialAssignment(): Assignment<AktiiaMonitorBloodPressureAssignmentData> {
  const origin = DateTime.now().plus({ day: 1 });
  return {
    allowedActions: [],
    audit: {},
    assignedTo: undefined,
    eTag: "",
    id: "",
    finishAt: origin.plus({ day: 14 }).endOf("day"),
    startAt: origin.set({ hour: 8 }).startOf("hour"),
    patientId: "",
    data: {
      state: APITypesV1.AssignmentState.Submitted,
      $type: "AktiiaMonitorBloodPressureAssignment",
      deviceSerialNumber: "",
      measurements: [],
      minCompletedMeasurements: 0,
      returnAt: origin.plus({ day: 15 }).set({ hour: 10, minute: 30 }),
      rawMeasurements: [],
      returnLocation:
        APITypesV1.DeviceReturnLocation.Regeringsgatan61BStockholm,
    },
  };
}
