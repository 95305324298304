import { createMetric } from "lib/metric";
import { BodyMetrics } from "lib/types/metrics/body";

export type Entries = {
  biceps: string;
  calf: string;
  calf_left: string;
  calf_right: string;
  height: string;
  hip: string;
  neck: string;
  waist: string;
  weight: string;

  femoral_instep_left: string;
  femoral_instep_right: string;
  ssn_carotis_left: string;
  ssn_femoralis_left: string;
  ssn_symphysis: string;
  ssn_piezo_finger_left: string;
  ssn_piezo_finger_right: string;
};

export function toEntries(metrics: BodyMetrics): Entries {
  return {
    biceps: metrics.biceps ? (metrics.biceps.unit.meters * 100).toString() : "",
    calf: metrics.calf ? (metrics.calf.unit.meters * 100).toString() : "",
    calf_left: metrics.calf_left
      ? (metrics.calf_left.unit.meters * 100).toString()
      : "",
    calf_right: metrics.calf_right
      ? (metrics.calf_right.unit.meters * 100).toString()
      : "",
    height: metrics.height ? (metrics.height.unit.meters * 100).toString() : "",
    hip: metrics.hip ? (metrics.hip.unit.meters * 100).toString() : "",
    neck: metrics.neck ? (metrics.neck.unit.meters * 100).toString() : "",
    waist: metrics.waist ? (metrics.waist.unit.meters * 100).toString() : "",
    weight: metrics.weight ? metrics.weight.unit.kilograms.toString() : "",

    femoral_instep_left: metrics.femoral_instep_left
      ? (metrics.femoral_instep_left.unit.meters * 100).toString()
      : "",
    femoral_instep_right: metrics.femoral_instep_right
      ? (metrics.femoral_instep_right.unit.meters * 100).toString()
      : "",
    ssn_carotis_left: metrics.ssn_carotis_left
      ? (metrics.ssn_carotis_left.unit.meters * 100).toString()
      : "",
    ssn_femoralis_left: metrics.ssn_femoralis_left
      ? (metrics.ssn_femoralis_left.unit.meters * 100).toString()
      : "",
    ssn_symphysis: metrics.ssn_symphysis
      ? (metrics.ssn_symphysis.unit.meters * 100).toString()
      : "",
    ssn_piezo_finger_left: metrics.ssn_piezo_finger_left
      ? (metrics.ssn_piezo_finger_left.unit.meters * 100).toString()
      : "",
    ssn_piezo_finger_right: metrics.ssn_piezo_finger_right
      ? (metrics.ssn_piezo_finger_right.unit.meters * 100).toString()
      : "",
  };
}

export function toBodyMetrics(entries: Entries): BodyMetrics {
  return {
    biceps: createMetric("body.biceps", {
      meters: parseFloat(entries.biceps) / 100,
    }),
    calf: createMetric("body.calf", {
      meters: parseFloat(entries.calf) / 100,
    }),
    calf_left: createMetric("body.calf.left", {
      meters: parseFloat(entries.calf_left) / 100,
    }),
    calf_right: createMetric("body.calf.right", {
      meters: parseFloat(entries.calf_right) / 100,
    }),
    height: createMetric("body.height", {
      meters: parseFloat(entries.height) / 100,
    }),
    hip: createMetric("body.hip", {
      meters: parseFloat(entries.hip) / 100,
    }),
    neck: createMetric("body.neck", {
      meters: parseFloat(entries.neck) / 100,
    }),
    waist: createMetric("body.waist", {
      meters: parseFloat(entries.waist) / 100,
    }),
    weight: createMetric("body.weight", {
      kilograms: parseFloat(entries.weight),
    }),

    femoral_instep_left: createMetric(
      "cardio.distance.femoralis-left_instep.left",
      {
        meters: parseFloat(entries.femoral_instep_left) / 100,
      }
    ),
    femoral_instep_right: createMetric(
      "cardio.distance.femoralis-left_instep.right",
      {
        meters: parseFloat(entries.femoral_instep_right) / 100,
      }
    ),
    ssn_carotis_left: createMetric("cardio.distance.ssn_carotis.left", {
      meters: parseFloat(entries.ssn_carotis_left) / 100,
    }),
    ssn_femoralis_left: createMetric("cardio.distance.ssn_femoralis.left", {
      meters: parseFloat(entries.ssn_femoralis_left) / 100,
    }),
    ssn_symphysis: createMetric("cardio.distance.ssn_symphysis", {
      meters: parseFloat(entries.ssn_symphysis) / 100,
    }),
    ssn_piezo_finger_left: createMetric(
      "cardio.distance.ssn_piezo-finger.left",
      {
        meters: parseFloat(entries.ssn_piezo_finger_left) / 100,
      }
    ),
    ssn_piezo_finger_right: createMetric(
      "cardio.distance.ssn_piezo-finger.right",
      {
        meters: parseFloat(entries.ssn_piezo_finger_right) / 100,
      }
    ),
  };
}
