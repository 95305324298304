import { usePatient } from "render/hooks/api/usePatient";
import { useQuestionnaire } from "render/hooks/api/useQuestionnaire";
import { usePatientHealthData } from "render/hooks/patient/usePatientHealthData";
import BloodworkTable from "./components/BloodworkTable";
import BodyTable from "./components/BodyTable";
import CardioTable from "./components/CardioTable";
import LipidPanelTable from "./components/LipidPanelTable";
import { QuestionnaireOverview } from "./components/QuestionnaireOverview";
import { QuestionnaireOverviewV1 } from "./components/QuestionnaireOverviewV1";
import WhiteBloodCellsTable from "./components/WhiteBloodCellsTable";
import styles from "./styles.module.sass";

interface PatientEncyclopediaPageProps {
  patientId: string;
}

export default function PatientEncyclopediaPage({
  patientId,
}: PatientEncyclopediaPageProps) {
  const patient = usePatient(patientId);

  const healthData = usePatientHealthData(patientId);
  const metrics = healthData.metrics;

  const questionnaireResponse = useQuestionnaire({
    patientId,
    visitId: undefined,
  });

  return (
    <div className={styles.PatientEncyclopediaPage}>
      <section>
        <CardioTable
          patient={patient}
          metrics={metrics}
          healthData={healthData}
        />
      </section>

      <section>
        <BodyTable
          patient={patient}
          metrics={metrics}
          healthData={healthData}
        />
      </section>

      <section>
        <LipidPanelTable
          patient={patient}
          metrics={metrics}
          healthData={healthData}
        />
      </section>

      <section>
        <WhiteBloodCellsTable patient={patient} metrics={metrics} />
      </section>

      <section>
        <BloodworkTable patient={patient} metrics={metrics} />
      </section>

      <section>
        {questionnaireResponse.data?.questionnaireType === "onboarding/1" ? (
          <QuestionnaireOverviewV1
            patient={patient}
            questionnaireResponse={questionnaireResponse.data}
          />
        ) : (
          <QuestionnaireOverview
            patient={patient}
            questionnaireResponse={questionnaireResponse.data}
          />
        )}
      </section>
    </div>
  );
}
