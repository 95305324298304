import { APITypesV1 } from "@cur8/api-client";
import { BoundingBoxAnnotation } from "@cur8/rich-entity";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useLatestPhysicalArtefact } from "render/hooks/api/physical-artefact/useLatestPhysicalArtefact";
import PhysicalArtefactState from "render/ui/format/PhysicalArtefactState";
import StateIcon from "./assets/state.svg?react";
import styles from "./styles.module.sass";

interface ChangeStateProps {
  annotation: BoundingBoxAnnotation;
}

const PossibleStates: APITypesV1.PhysicalArtefactState[] = [
  APITypesV1.PhysicalArtefactState.None,
  APITypesV1.PhysicalArtefactState.SelfMonitor,
  APITypesV1.PhysicalArtefactState.NeedsDermoscope,
  APITypesV1.PhysicalArtefactState.FollowUp4Months,
  APITypesV1.PhysicalArtefactState.NeedsExcision,
  APITypesV1.PhysicalArtefactState.NeedsDermatologistReferral,
  APITypesV1.PhysicalArtefactState.Dermoscoped,
  APITypesV1.PhysicalArtefactState.ReferralSent,
  APITypesV1.PhysicalArtefactState.Excised,
  APITypesV1.PhysicalArtefactState.ReferralSentDerma,
  APITypesV1.PhysicalArtefactState.ReferralSentExcision,
];

export default function ChangeState({ annotation }: ChangeStateProps) {
  const { latestPhysicalArtefact: physicalArtefact, createVersion } =
    useLatestPhysicalArtefact({
      patientId: annotation.patientId,
      physicalArtefactId: annotation.physicalArtefactId || "",
    });

  const legacyState = useMemo(() => {
    if (
      physicalArtefact.data?.state &&
      !PossibleStates.includes(physicalArtefact.data.state)
    ) {
      return physicalArtefact.data.state;
    }
  }, [physicalArtefact.data?.state]);

  const [selectedValue, setSelectedValue] = useState(
    APITypesV1.PhysicalArtefactState.None
  );

  useEffect(() => {
    if (physicalArtefact.data) {
      setSelectedValue(
        physicalArtefact.data.state || APITypesV1.PhysicalArtefactState.None
      );
    }
  }, [physicalArtefact]);

  async function handleChange(event: ChangeEvent<HTMLSelectElement>) {
    const selected = event.currentTarget
      .value as APITypesV1.PhysicalArtefactState;
    if (physicalArtefact.data) {
      const { patientId, id, version, audit, ...physicalArtefactRequest } =
        physicalArtefact?.data;
      await createVersion({ ...physicalArtefactRequest, state: selected });
    }
  }

  return (
    <>
      <div className={styles.ChangeState}>
        <div className={styles.icon}>
          <StateIcon />
        </div>
        <select
          disabled={!physicalArtefact.data}
          value={selectedValue}
          onChange={handleChange}
        >
          {PossibleStates.map((state) => (
            <option key={state} value={state}>
              <PhysicalArtefactState state={state} />
            </option>
          ))}
          {legacyState && (
            <option
              selected={selectedValue === physicalArtefact.data?.state}
              value={legacyState}
              disabled
            >
              <PhysicalArtefactState state={legacyState} />
            </option>
          )}
        </select>
      </div>
    </>
  );
}
