import { fromAPI } from "@cur8/rich-entity";
import { useQueryParams } from "@pomle/react-router-paths";
import { useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useReporting } from "render/hooks/useReporting";
import { query } from "render/routes/querys";

export function useSelectedPatient() {
  const [queryParams] = useQueryParams(query.schedule);
  const { handleError } = useReporting();

  const api = useAPIClient().patient;
  const { get, set } = useStore().patient.entries;

  const selectedPatientId = useMemo(
    () => queryParams.assignPatientId[0] || undefined,
    [queryParams]
  );

  useEffect(() => {
    if (!selectedPatientId) {
      return;
    }

    // TODO: kill me. This hook is called many times (once per booking slot)
    // and we get to  where it is used usually from a place that already queried this
    // so check if we have this cached
    if (get(selectedPatientId).ready) {
      return;
    }

    const request = api.fetchPatient({ patientId: selectedPatientId });

    request.result
      .then(fromAPI.toPatientDetails)
      .then((patient) => {
        set(patient.patientId, patient);
      })
      .catch(handleError);
  }, [set, get, api, selectedPatientId, handleError]);

  return selectedPatientId
    ? get(selectedPatientId).data ?? undefined
    : undefined;
}
