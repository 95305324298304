import { Patient } from "@cur8/rich-entity";
import { DateTime } from "luxon";

export function calcAge(birthdate: DateTime, today: DateTime): number {
  const years = today.diff(birthdate, "years").years;
  const result = Math.floor(years);
  return result;
}

export function patientAge(patient: Patient, atTime: DateTime) {
  if (patient.dateOfBirth?.isValid) {
    return calcAge(patient.dateOfBirth, atTime);
  }
  return NaN;
}
