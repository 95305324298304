import { Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import AssessmentPanel from "render/ui/presentation/AssessmentPanel";
import MeasurementTimeline from "render/ui/presentation/MeasurementTimeline";
import ValueItem from "render/ui/symbol/ValueItem";

const RANGES = [{ from: 0, to: 40, risk: Risk.Risk }];

interface FractureRiskRangeProps {
  metrics: Metric<"risk_assessment.frax_score">[] | undefined;
}

export default function FractureRiskRange({ metrics }: FractureRiskRangeProps) {
  const entries = useMemo(() => {
    if (!metrics) {
      return;
    }

    return metrics.map((metric) => {
      return {
        risk: Risk.Risk,
        timestamp: metric.measurement.timestampStart,
        value: metric.unit.percent,
      };
    });
  }, [metrics]);

  const latest = metrics?.at(0);

  return (
    <AssessmentPanel
      title="Fracture Risk"
      value={<ValueItem value={latest ? latest.unit.percent : "--"} unit="%" />}
    >
      <MeasurementTimeline ranges={RANGES} entries={entries} />
    </AssessmentPanel>
  );
}
