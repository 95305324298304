import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import ConfirmationModal, { ConfirmationModalProps } from "./ConfirmationModal";

// this is a utility type that makes all properties of T optional except for K
type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

type DialogProps = Optional<ConfirmationModalProps, "onClose">;

export function useConfirmationModal() {
  const { create } = usePopupDialog();

  const emitConfirmationDialog = useCallback(
    ({ onClose, ...props }: DialogProps) => {
      const { emit, close } = create();

      const handleClose = () => {
        onClose?.();
        close();
      };

      emit(<ConfirmationModal {...props} onClose={handleClose} />);
    },
    [create]
  );

  return {
    emitConfirmationDialog,
  };
}
