import { createColorizer } from "lib/webgl/colorizer";
import { useEffect } from "react";

type GLEngine = {
  colorizer?: ReturnType<typeof createColorizer>;
  users: number;
};

// Singleton handle for GLContext prevents WebGL context count limitation.
// Colorizer operations between draw > get data must be sync.
const Engine: GLEngine = {
  colorizer: undefined,
  users: 0,
};

export function useColorizer() {
  useEffect(() => {
    if (!Engine.colorizer) {
      console.debug("Creating Colorizer GL Context");
      Engine.colorizer = createColorizer();
    }

    Engine.users++;

    return () => {
      Engine.users--;

      setTimeout(() => {
        if (Engine.colorizer && Engine.users === 0) {
          console.debug("Destroying Colorizer GL Context");
          Engine.colorizer = undefined;
        }
      }, 1000);
    };
  }, []);

  return Engine.colorizer;
}
