import { useCallback, useState } from "react";

export function useToggle(initial: boolean): [boolean, () => void] {
  const [state, setState] = useState(initial);

  const toggle = useCallback(() => {
    setState((state) => !state);
  }, []);

  return [state, toggle];
}
