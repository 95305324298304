import { Patient, ScheduleViewSlot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import { paths } from "render/routes/paths";
import SlotManagementPopup from "../components/SlotManagementPopup/SlotManagementPopup";

export function useSlotManagementPopup() {
  const { create } = usePopupDialog();
  const nav = {
    appointment: useNav(paths.patient.appointment.view),
  };

  const emitDialog = useCallback(
    (patient: Patient, slot: ScheduleViewSlot) => {
      const { emit, close } = create();

      emit(
        <SlotManagementPopup
          slot={slot}
          patient={patient}
          onCancel={close}
          onDone={({ slotId }) => {
            close();
            nav.appointment.go({
              patientId: patient.patientId,
              bookingSlotId: slotId,
            });
          }}
        />
      );
    },
    [create, nav.appointment]
  );

  return {
    emitDialog,
  };
}
