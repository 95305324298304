import { cutSignal, Plot } from "lib/api/cardio";
import { normalize } from "lib/normalize";
import { useMemo } from "react";
import CardioGraph from "render/ui/graph/CardioGraph";
import CardioGrid from "render/ui/graph/CardioGrid";
import styles from "./styles.module.sass";

const DURATION = 5;

interface HipAortaGraphProps {
  femoral: Plot;
  ssn: Plot;
}

export default function HipAortaGraph({ femoral, ssn }: HipAortaGraphProps) {
  const femoralPoints = useMemo(() => {
    const slice = cutSignal(femoral, DURATION);

    return slice.map(normalize(0, 1)).map((v, index) => {
      return {
        x: (index / slice.length) * 100,
        y: 10 + (1 + -v) * 40,
      };
    });
  }, [femoral]);

  const ssnPoints = useMemo(() => {
    const slice = cutSignal(ssn, DURATION);

    return slice.map(normalize(0, 1)).map((v, index) => {
      return {
        x: (index / slice.length) * 100,
        y: 50 + (1 + -v) * 40,
      };
    });
  }, [ssn]);

  const points = useMemo(() => {
    const last = femoralPoints.at(-1);
    if (!last) {
      return [];
    }

    const points = [
      ...femoralPoints,

      // Point outside the canvas to connect the path for fill
      { x: 200, y: 50 },

      ...ssnPoints.reverse(),
    ];

    return points;
  }, [femoralPoints, ssnPoints]);

  return (
    <div className={styles.HipAortaGraph}>
      <svg viewBox="0 0 100 100" preserveAspectRatio="none">
        <CardioGrid vertical={[20, 40, 60, 80]} horizontal={[20, 40, 60, 80]} />

        <CardioGraph points={points} duration={DURATION} />
      </svg>

      <div className={styles.timeline}>
        {[0, 1, 2, 3, 4, 5].map((n) => {
          return <div key={n}>{n}</div>;
        })}
      </div>
    </div>
  );
}
