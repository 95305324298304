import { Risk, Systolic, Value } from "@cur8/health-risks-calc";
import { findClosest, mapLinear } from "lib/math";
import { getKeyByValue } from "lib/object";
import RAW_DATA from "./data/framingham-study-data.json";
import { getAgeBinFromString } from "./get-age-bin-from-string";

// see: Projections.specs.md#blood-pressure-projection for more info

const groups = ["1", "2", "3", "4"] as const;

const GroupToRiskMap = {
  "1": Risk.Optimal,
  "2": Risk.Normal,
  "3": Risk.HighRisk,
  "4": Risk.ImmediateRisk,
} as const;

const data = RAW_DATA.map((d) => ({
  age: d.Age,
  ageBin: getAgeBinFromString(d.Age),
  "1": d["1"],
  "2": d["2"],
  "3": d["3"],
  "4": d["4"],
}));

const values = data.flatMap((d) => groups.map((g) => d[g]));

const domain = {
  x: {
    min: Math.min(...data.map((d) => d.ageBin.from)),
    max: Math.max(...data.map((d) => d.ageBin.to)),
  },
  y: {
    min: Math.min(...values),
    max: Math.max(...values),
  },
};

export interface BloodPressureProjectionParams {
  age: number;
  systolicBloodPressure: Value<"mmHg">;
}

export interface BloodPressureProjectionResult {
  /**
   * Projected risk
   */
  risk: Risk;
  /**
   * Projected age and value at which the risk threshold is reached
   */
  riskThreshold: {
    value: Value<"mmHg">;
    age: number;
  } | null;
}

/**
 * Blood pressure projection:
 *
 * Based on the Framingham study data, ranks the patient's systolic blood pressure into one of the following groups:
 * - "1" => Optimal
 * - "2" => Normal
 * - "3" => Hypertension
 * - "4" => Hypertension 2
 *
 * @param {number} age
 * @param {Value<"mmHg">} systolicBloodPressure
 *
 * @returns Risk group information and (if applicable) the age and value at which the projection reaches a high risk threshold
 */
function project({
  systolicBloodPressure,
  age,
}: BloodPressureProjectionParams): BloodPressureProjectionResult {
  // first, find the age bin data entry that matches the patient's age
  const ageBinDataEntry = data.find((d, i) => {
    const isFirstAgeBin = i === 0;
    const isLastAgeBin = i === data.length - 1;

    if (isFirstAgeBin && age < d.ageBin.from) {
      return true;
    }
    if (isLastAgeBin && age >= d.ageBin.to) {
      return true;
    }

    return age >= d.ageBin.from && age <= d.ageBin.to;
  });

  if (!ageBinDataEntry) {
    return {
      risk: Risk.Unknown,
      riskThreshold: null,
    };
  }

  const ageBinBloodPressureData = {
    "1": ageBinDataEntry["1"],
    "2": ageBinDataEntry["2"],
    "3": ageBinDataEntry["3"],
    "4": ageBinDataEntry["4"],
  } as const;

  const closestBloodPressureValue = findClosest(
    systolicBloodPressure.mmHg,
    Object.values(ageBinBloodPressureData)
  );

  const closestStudyGroup = getKeyByValue(
    ageBinBloodPressureData,
    closestBloodPressureValue
  ) as (typeof groups)[number];

  const closestRiskGroup = GroupToRiskMap[closestStudyGroup];

  // if the patient's closest risk group is not high risk or immediate risk, don't calculate the risk threshold, return early
  if (closestRiskGroup < GroupToRiskMap["3"]) {
    return {
      risk: closestRiskGroup,
      riskThreshold: null,
    };
  }

  const riskRanges = Systolic.rangesFor({ age });
  const highRiskThresholdValue = riskRanges.entries.find(
    (range) => range.risk === Risk.HighRisk
  )!.from;

  let ageAtWhichRiskIsProjected: number | null = null;

  // the data is organized in age bins (e.g. age bin 30-34, group 1 => 133 mmHg)
  // in order to get a specific number at which the trajectory crosses the high risk threshold (e.g. 140 mmHg)
  // we need to find the two age bins between which the high risk threshold value is crossed (e.g. age bin 30-34 and 35-39)
  // and then use linear interpolation to find the exact age at which the threshold is crossed
  for (let i = 0; i < data.length - 1; i++) {
    const currentAgeBin = data[i];
    const nextAgeBin = data[i + 1];
    const currentAgeBinValue = currentAgeBin[closestStudyGroup];
    const nextAgeBinValue = nextAgeBin[closestStudyGroup];

    if (
      highRiskThresholdValue >= currentAgeBinValue &&
      highRiskThresholdValue < nextAgeBinValue
    ) {
      ageAtWhichRiskIsProjected = mapLinear(
        highRiskThresholdValue,
        currentAgeBinValue,
        nextAgeBinValue,
        currentAgeBin.ageBin.from,
        nextAgeBin.ageBin.from
      );
      break;
    }
  }

  const riskThreshold: BloodPressureProjectionResult["riskThreshold"] =
    typeof ageAtWhichRiskIsProjected === "number"
      ? {
          value: { mmHg: highRiskThresholdValue },
          age: ageAtWhichRiskIsProjected,
        }
      : null;

  return {
    risk: closestRiskGroup,
    riskThreshold,
  };
}

export const BloodPressureProjection = {
  project,
  data,
  domain,
  groups,
  GroupToRiskMap,
};
