import { APITypesV1 } from "@cur8/api-client";
import { MergedRecord } from "lib/doctor-scribe/types";
import { serializeMergedRecord } from "./results/resultHelpers";

const formatting =
  " Use markdown (bold) to emphasize important things such as referenced values, risks, deviations or suggestions. Mix in emojis to highlight content. Respond with (max 3) bullet points only, ordered by importance. Use the '*' character for bullets. Keep sentences short and to the point: use as few words as possible.";

const tone =
  " The tone should make it clear that what you prose is suggestions only. Never tell the doctor directly what to do. To that end, always use phrases like 'You may wish to consider', 'You might want to think about', 'It could be beneficial to explore' etc.";

export function latestSummaryRequest(
  record: MergedRecord
): APITypesV1.OpenAIChatRequest {
  const data = serializeMergedRecord(record, false);
  const instruction = `You are an expert in helping doctors summarize scan results, blood tests data and other health data. Make a short, concise summary of the following data: ${data}`;
  const prompt =
    "Write an objective summary of the latest results. Focus on values which deviate from normal or constitute a risk. When reporting on deviation or risk, make sure to also reference actual values. Also list all optimal results (risk:Optimal), but without referencing values. Do not comment on normal values. Do not make diagnoses or suggestions." +
    formatting;
  return {
    messages: [
      { role: "system", content: instruction },
      { role: "user", content: prompt },
    ],
    model: "gpt-4-32k",
    temperature: 0.21,
    max_tokens: 800,
    stop: "",
  };
}

export function compareSummaryRequest(
  record: MergedRecord
): APITypesV1.OpenAIChatRequest {
  const data = serializeMergedRecord(record, false);
  const instruction = `You are an expert in helping doctors compare scan results, blood tests data and other health data. You make short, concise summaries. You have access to the following data, including summaries of previous scans: ${data}`;
  const prompt =
    "Make an objective comparison between the latest and earlier results. We are mainly interested in results that have changed significantly over time. When reporting on differences, deviation or risk, make sure to also reference actual values. If there are previous visit summaries with doctor advice, summarize and include them in one of the bullets. Do not make new diagnoses or suggestions." +
    formatting;

  return {
    messages: [
      { role: "system", content: instruction },
      { role: "user", content: prompt },
    ],
    model: "gpt-4-32k",
    temperature: 0.21,
    max_tokens: 800,
    stop: "",
  };
}

export function suggestionRequest(
  todaySummary: string,
  compareResults: string
): APITypesV1.OpenAIChatRequest {
  const instruction = compareResults
    ? `You are an expert in helping doctors analyze scan results, blood tests data and other health data. You have access to a summary of today's results and also a comparison with previous scans:\n\nSummary of today's results:\n${todaySummary}\n\nComparison with previous scans: ${compareResults}`
    : `You are an expert in helping doctors analyze scan results, blood tests data and other health data. You have access to a summary of today's results:\n${todaySummary}`;
  const prompt =
    "Suggest suitable outcomes. Recommended lifestyle changes? Dietary considerations? Only recommend referrals when really motivated, otherwise do not mention referrals. If you do recommend a referral, explain why it is motivated and refer only to 'a GP at Atrium' and not directly to a specialist. " +
    formatting +
    tone;
  return {
    messages: [
      { role: "system", content: instruction },
      { role: "user", content: prompt },
    ],
    model: "gpt-4-32k",
    temperature: 0.21,
    max_tokens: 800,
    stop: "",
  };
}

export function questionRequest(
  todaySummary: string,
  compareResults: string
): APITypesV1.OpenAIChatRequest {
  const instruction = compareResults
    ? `You are an expert in helping doctors analyze scan results, blood tests data and other health data. You have access to a summary of today's results and also a comparison with previous scans:\n\nSummary of today's results:\n${todaySummary}\n\nComparison with previous scans: ${compareResults}`
    : `You are an expert in helping doctors analyze scan results, blood tests data and other health data. You have access to a summary of today's results:\n${todaySummary}`;
  const prompt =
    "Suggest questions to ask the patient to help clarify the picture and help with diagnosing. If there are previous doctor recommendations, suggest following up on them." +
    formatting +
    tone;
  return {
    messages: [
      { role: "system", content: instruction },
      { role: "user", content: prompt },
    ],
    model: "gpt-4-32k",
    temperature: 0.21,
    max_tokens: 800,
    stop: "",
  };
}
