export type PrePrompt = {
  title: string;
  prompt: string;
};

export const prePrompts: PrePrompt[] = [
  {
    title: "Shorter",
    prompt: "Make it shorter",
  },
  {
    title: "Add referral to GP",
    prompt:
      "Add to the text that a referral to a GP at Atrium Vårdcentral will be made.",
  },
  {
    title: "Add blood pressure device",
    prompt:
      "Add to the text that the patient will get a take-home 24h blood pressure device.",
  },
];
