import { useCallback } from "react";
import { EntityStore } from "@pomle/react-flat-store";

export function useEntries<T extends { id: string }>(
  store: EntityStore<T>,
  namespace: string
) {
  const { get: getCollection, set: setCollection } = store.collection;
  const { batch: batchEntries } = store.entries;

  const getEntries = useCallback(() => {
    return getCollection(namespace);
  }, [namespace, getCollection]);

  const setEntries = useCallback(
    (entities: T[]) => {
      const entries = entities.map((entry) => {
        return {
          id: entry.id,
          data: entry,
        };
      });

      const ids = entries.map((entry) => entry.id);

      batchEntries(entries);
      setCollection(namespace, ids);
    },
    [namespace, batchEntries, setCollection]
  );

  return { getEntries, setEntries };
}
