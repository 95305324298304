import {
  Annotation,
  BoundingBoxAnnotation,
  hasBoundingBox,
} from "@cur8/rich-entity";
import { PanoramaImageURI } from "lib/api/uri";
import { Bounds, Box, Line, Point, toAbsoluteBox } from "lib/math";
import { panoramaCoords } from "lib/panorama-coords";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getPointerMovement, stopPropagation } from "render/event";
import { useDOMElement } from "render/hooks/useDOMElement";
import { useDOMElementSize } from "render/hooks/useDOMElementSize";
import { useHomographies } from "render/hooks/useHomographies";
import { Marking } from "render/pages/AtlasPage/types";
import AutoDetection from "./components/AutoDetection";
import Controls from "./components/Controls";
import DragArea from "./components/DragArea";
import { lineToBox } from "./math";
import styles from "./styles.module.sass";
import { Area, Interaction, Layout } from "./types";

type Crop = {
  x: number;
  y: number;
  w: number;
  h: number;
};

type Size = {
  w: number;
  h: number;
};

export type ViewBox = {
  crop: Crop;
  size: Size;
};

const NO_BOUNDS = new Bounds(
  new Point(-Infinity, -Infinity),
  new Point(Infinity, Infinity)
);

const SCALE_INITIAL = 1;

const ZOOM_SCALE = 0.6;

const PAN_ZERO = new Point(0, 0);

interface BoxAreasProps {
  panoramaURI: PanoramaImageURI;
  areas: Area[];
  autoDetections: Annotation[];
  onArea: (
    area: Area,
    isRelative: boolean,
    annotation?: Annotation,
    event?: React.MouseEvent<Element>
  ) => Promise<void>;
  children: (view: ViewBox) => React.ReactNode;
  scaleFactor: number;
  movingMarking: Marking | undefined;
  onMove: (annotation: BoundingBoxAnnotation, movement: Point) => void;
  focusPoint?: Point | undefined;
  onFocused: () => void;
}

export default function BoxAreas({
  panoramaURI,
  areas,
  onArea,
  autoDetections,
  children: content,
  scaleFactor,
  movingMarking,
  onMove,
  focusPoint,
  onFocused,
}: BoxAreasProps) {
  const frameRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const frameElement = useDOMElement(frameRef);
  const contentElement = useDOMElement(contentRef);

  const contentSize = useDOMElementSize(contentRef);

  const [draggedArea, setDraggedArea] = useState<{
    state: "pending" | "ready";
    points: Line;
  }>();

  const [smooth, setSmooth] = useState<boolean>(false);

  const [layout, setLayout] = useState<Layout>(Layout.Fit);
  const [interaction, setInteraction] = useState<Interaction>(
    Interaction.Orient
  );

  const [pan, setPan] = useState<Point>(PAN_ZERO);
  const [scale, setScale] = useState<number>(SCALE_INITIAL);

  const homographies = useHomographies({ panoramaURI: panoramaURI });

  const areasPaid = useMemo(() => {
    return areas.map((a) => {
      return a.physicalArtefactId;
    });
  }, [areas]);

  const autoDetectionElements = useMemo(() => {
    const handleConvertAutoDetection = async (
      box: Box,
      annotation: Annotation,
      event: React.MouseEvent<Element>
    ) => {
      const area: Area = {
        id: "next",
        physicalArtefactId: annotation.physicalArtefactId || "",
        bounds: box,
        content: (
          <DragArea
            dragging={false}
            onClick={() => {}}
            onSelectedEvent={() => {}}
          />
        ),
      };
      await onArea(area, false, annotation, event);
    };

    return (
      autoDetections &&
      homographies &&
      autoDetections
        .filter(hasBoundingBox)
        .filter((a) => {
          return !areasPaid.includes(a.physicalArtefactId || "");
        })
        .map((a) => {
          const pc = panoramaCoords(a, homographies);
          return pc ? (
            <AutoDetection
              annotation={a}
              coords={pc}
              pan={pan}
              scale={scale}
              scaleFactor={scaleFactor}
              key={a.id}
              smooth={smooth}
              onAddMarking={handleConvertAutoDetection}
            />
          ) : (
            <></>
          );
        })
    );
  }, [
    autoDetections,
    pan,
    scale,
    scaleFactor,
    homographies,
    smooth,
    onArea,
    areasPaid,
  ]);

  const [showAIMagic, setShowAIMagic] = useState(false);

  const calcFitScale = useCallback(() => {
    if (!frameElement || !contentElement) {
      return SCALE_INITIAL;
    }

    const scale = frameElement.offsetWidth / contentElement.offsetWidth;

    if (!isFinite(scale)) {
      return SCALE_INITIAL;
    }

    return scale;
  }, [frameElement, contentElement]);

  const calcBounds = useCallback(
    (scale: number): Bounds => {
      if (!frameElement || !contentElement) {
        return NO_BOUNDS;
      }

      const bounds = new Bounds(
        new Point(0, 0),
        new Point(
          Math.max(
            0,
            contentElement.offsetWidth * scale - frameElement.offsetWidth
          ),
          Math.max(
            0,
            contentElement.offsetHeight * scale - frameElement.offsetHeight
          )
        )
      );

      return bounds;
    },
    [frameElement, contentElement]
  );

  const calcScreenCenter = useCallback((): Point => {
    if (!frameElement || !contentElement) {
      return new Point(0.5, 0.5);
    }

    const frameRect = frameElement.getBoundingClientRect();
    const contentRect = contentElement.getBoundingClientRect();

    return new Point(
      (frameRect.left - contentRect.left + frameRect.width / 2) /
        contentRect.width,
      (frameRect.top - contentRect.top + frameRect.height / 2) /
        contentRect.height
    );
  }, [frameElement, contentElement]);

  const handleScroll = useCallback(
    (event: React.WheelEvent) => {
      if (interaction !== Interaction.Orient) {
        return;
      }

      setSmooth(false);

      const bounds = calcBounds(scale);
      const deltaY = event.deltaY;

      setPan((pan) => {
        const nextPan = new Point(pan.x, pan.y + deltaY);
        return bounds.clamp(nextPan);
      });
    },
    [interaction, calcBounds, scale]
  );

  const handleSelectStart = useCallback(
    (event: React.PointerEvent) => {
      if (interaction !== Interaction.Tag) {
        return;
      }

      if (!contentElement) {
        return;
      }

      if (event.target !== contentElement) {
        return;
      }

      const contentRect = contentElement.getBoundingClientRect();

      const point = new Point(
        (event.clientX - contentRect.x) / contentRect.width,
        (event.clientY - contentRect.y) / contentRect.height
      );

      const points = new Line(point, point);

      setDraggedArea({
        state: "pending",
        points,
      });
    },
    [contentElement, interaction]
  );

  const handleSelectDrag = useCallback(
    (event: React.PointerEvent) => {
      if (interaction !== Interaction.Tag) {
        return;
      }

      if (event.buttons !== 1) {
        return;
      }

      if (!draggedArea) {
        return;
      }

      event.preventDefault();

      setSmooth(false);

      if (!contentElement) {
        return;
      }

      const contentRect = contentElement.getBoundingClientRect();

      const relativePos = new Point(
        (event.clientX - contentRect.x) / contentRect.width,
        (event.clientY - contentRect.y) / contentRect.height
      );

      const sourcePos = draggedArea.points.a;

      const delta = new Point(
        relativePos.x - sourcePos.x,
        relativePos.y - sourcePos.y
      );

      const size = Math.max(Math.abs(delta.x), Math.abs(delta.y));

      const nextPoints = new Line(
        sourcePos,
        new Point(
          sourcePos.x + size * Math.sign(delta.x),
          sourcePos.y + size * Math.sign(delta.y)
        )
      );

      setDraggedArea({ ...draggedArea, points: nextPoints });
    },
    [contentElement, draggedArea, interaction]
  );

  const handleSelectEnd = useCallback(() => {
    if (draggedArea) {
      setDraggedArea({ ...draggedArea, state: "ready" });
    }
  }, [draggedArea]);

  const handlePan = useCallback(
    (event: React.PointerEvent) => {
      if (interaction !== Interaction.Orient) {
        return;
      }

      if (event.buttons !== 1) {
        return;
      }

      if (movingMarking) {
        return;
      }

      event.preventDefault();

      setSmooth(false);

      const bounds = calcBounds(scale);
      const movement = getPointerMovement(event);

      setPan((pan) => {
        const nextPan = pan.subtract(movement);
        return bounds.clamp(nextPan);
      });
    },
    [interaction, calcBounds, scale, movingMarking]
  );

  const handleMove = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1) {
        return;
      }

      if (!movingMarking || movingMarking.type !== "assumed") {
        return;
      }

      event.preventDefault();

      setSmooth(false);

      const movement = getPointerMovement(event).multiplyScalar(1 / ZOOM_SCALE);

      onMove(movingMarking.annotation, movement);
    },
    [movingMarking, onMove]
  );

  const zoomIn = useCallback(
    (coords: Point) => {
      if (!frameElement || !contentElement) {
        return;
      }

      const frameRect = frameElement.getBoundingClientRect();

      setSmooth(true);

      setLayout(Layout.Max);

      const zoomScaleFactor = ZOOM_SCALE * scaleFactor;

      const offset = new Point(
        contentElement.offsetWidth * zoomScaleFactor * coords.x -
          frameRect.width / 2,
        contentElement.offsetHeight * zoomScaleFactor * coords.y -
          frameRect.height / 2
      );

      setPan(offset);
      setScale(zoomScaleFactor);
    },
    [frameElement, contentElement, scaleFactor, setPan]
  );

  const zoomOut = useCallback(
    (coords: Point) => {
      if (!frameElement || !contentElement) {
        return;
      }

      const frameRect = frameElement.getBoundingClientRect();

      setSmooth(true);

      const scale = calcFitScale();

      const size = new Point(
        contentElement.offsetWidth,
        contentElement.offsetHeight
      );

      const offset = new Point(
        coords.x * scale * size.x - frameRect.width / 2,
        coords.y * scale * size.y - frameRect.height / 2
      );

      const bounds = calcBounds(scale);

      setPan(bounds.clamp(offset));
      setScale(scale);
    },
    [frameElement, contentElement, calcFitScale, calcBounds]
  );

  const zoomInAt = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      if (!contentElement) {
        return;
      }

      const contentRect = contentElement.getBoundingClientRect();

      const point = new Point(
        (event.clientX - contentRect.x) / contentRect.width,
        (event.clientY - contentRect.y) / contentRect.height
      );

      zoomIn(point);
    },
    [contentElement, zoomIn]
  );

  const toggleZoom = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      setSmooth(true);

      if (layout === Layout.Fit) {
        setLayout(Layout.Max);
        zoomInAt(event);
      } else {
        setLayout(Layout.Fit);
        const center = calcScreenCenter();
        zoomOut(center);
      }
    },
    [layout, zoomInAt, zoomOut, calcScreenCenter]
  );

  const handleInteractionChange = useCallback((interaction: Interaction) => {
    setInteraction(interaction);
    if (interaction === Interaction.Orient) {
      setDraggedArea(undefined);
    }
  }, []);

  const handleLayoutChange = useCallback(
    (layout: Layout) => {
      const screenCenter = calcScreenCenter();
      setLayout(layout);

      if (layout === Layout.Max) {
        zoomIn(screenCenter);
      } else {
        zoomOut(screenCenter);
      }
    },
    [zoomIn, zoomOut, calcScreenCenter]
  );

  const fitContent = useCallback(() => {
    setLayout((layout) => {
      if (layout === Layout.Max) {
        return layout;
      }

      setSmooth(false);

      const scale = calcFitScale();

      const offset = new Point(0, 0);

      const bounds = calcBounds(scale);
      setPan(bounds.clamp(offset));
      setScale(scale);
      return layout;
    });
  }, [calcBounds, calcFitScale]);

  const handlePointerDown = useCallback(
    (event: React.PointerEvent) => {
      handleSelectStart(event);
    },
    [handleSelectStart]
  );

  const handlePointerMove = useCallback(
    (event: React.PointerEvent) => {
      handleSelectDrag(event);
      handlePan(event);
      handleMove(event);
    },
    [handlePan, handleSelectDrag, handleMove]
  );

  useEffect(() => {
    if (!frameElement) {
      return;
    }

    fitContent();

    const observer = new ResizeObserver(() => {
      fitContent();
    });

    observer.observe(frameElement);

    return () => {
      observer.disconnect();
    };
  }, [frameElement, fitContent]);

  useEffect(() => {
    if (!contentElement) {
      return;
    }

    const stopScroll = (event: Event) => {
      event.preventDefault();
    };

    contentElement.addEventListener("wheel", stopScroll, { passive: false });

    return () => {
      contentElement.removeEventListener("wheel", stopScroll);
    };
  }, [contentElement]);

  const manufacturedAreas = useMemo(() => {
    if (!draggedArea) {
      return [];
    }

    if (!contentElement) {
      return [];
    }

    const area: Area = {
      id: "next",
      physicalArtefactId: "",
      bounds: lineToBox(draggedArea.points),
      content: (
        <DragArea
          dragging={draggedArea.state === "pending"}
          onClick={(e) => {
            handleInteractionChange(Interaction.Orient);
            onArea(area, true, undefined, e).then(() =>
              setDraggedArea(undefined)
            );
          }}
          onSelectedEvent={() => {}}
        />
      ),
    };

    return [area];
  }, [contentElement, draggedArea, onArea, handleInteractionChange]);

  const boxAreas = [...areas, ...manufacturedAreas];

  const viewBox = useMemo((): ViewBox | undefined => {
    if (!frameElement) {
      return;
    }

    const frameRect = frameElement.getBoundingClientRect();

    return {
      crop: {
        x: (pan.x / scale) * scaleFactor,
        y: (pan.y / scale) * scaleFactor,
        w: (frameRect.width / scale) * scaleFactor,
        h: (frameRect.height / scale) * scaleFactor,
      },
      size: {
        w: frameRect.width,
        h: frameRect.height,
      },
    };
  }, [frameElement, pan, scale, scaleFactor]);

  useEffect(() => {
    if (!focusPoint) {
      return;
    }

    if (!contentElement) {
      return;
    }

    const contentRect = contentElement.getBoundingClientRect();

    const point = new Point(
      (focusPoint.x - contentRect.x) / contentRect.width,
      (focusPoint.y - contentRect.y) / contentRect.height
    );

    zoomIn(point);
    onFocused();
  }, [contentElement, focusPoint, onFocused, zoomIn]);

  return (
    <div
      className={styles.BoxAreas}
      ref={frameRef}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onPointerLeave={handleSelectEnd}
      onPointerCancel={handleSelectEnd}
      onPointerUp={handleSelectEnd}
      onWheel={handleScroll}
    >
      <div className={styles.controls}>
        <Controls
          interaction={interaction}
          onInteractionChange={handleInteractionChange}
          layout={layout}
          onLayoutChange={handleLayoutChange}
          showAIMagic={showAIMagic}
          onAIMagicChange={setShowAIMagic}
        />
      </div>

      <div
        className={styles.areas}
        data-drawing={draggedArea?.state === "pending"}
      >
        {contentSize &&
          boxAreas.map((area) => {
            const bounds = toAbsoluteBox(
              area.bounds,
              contentSize.width,
              contentSize.height
            );

            const style = {
              left: bounds.x * scale + -pan.x,
              top: bounds.y * scale + -pan.y,
              width: bounds.w * scale,
              height: bounds.h * scale,
            };

            return (
              <div
                key={area.id}
                className={styles.area}
                data-smooth={smooth}
                onPointerDown={stopPropagation}
                onPointerUp={stopPropagation}
                style={style}
              >
                {area.content}
              </div>
            );
          })}
      </div>

      {showAIMagic && (
        <div className={styles.areas}>{autoDetectionElements}</div>
      )}

      <div
        className={styles.content}
        data-interaction={interaction}
        data-smooth={smooth}
        ref={contentRef}
        onDoubleClick={toggleZoom}
        style={{
          transform: `
            translate(${-pan.x}px, ${-pan.y}px)
            scale(${scale})
          `,
        }}
      >
        {viewBox && content(viewBox)}
      </div>
    </div>
  );
}
