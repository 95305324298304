import { useContext } from "react";
import { Context } from "./context";

export function useAPIContext() {
  return useContext(Context);
}

export function useAPIClient() {
  const { client } = useAPIContext();
  if (!client) {
    throw new Error("API Client not initialized");
  }
  return client;
}
