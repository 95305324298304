import { APITypesV1 } from "@cur8/api-client";
import { Visit } from "@cur8/rich-entity";

export enum Country {
  GB = "GB",
  Sweden = "SE",
  Unknown = "Unknown",
}

export function getCountryFromVisit(visit: Visit): Country {
  if (visit.siteId.startsWith("SE-")) {
    return Country.Sweden;
  }
  if (visit.siteId.startsWith("GB-")) {
    return Country.GB;
  }
  return Country.Unknown;
}

const CurrentVisitTypes = new Set([
  APITypesV1.VisitStatus.CheckedIn,
  APITypesV1.VisitStatus.Ongoing,
  APITypesV1.VisitStatus.Completed,
]);

export function getLatestVisit(visits: Visit[]) {
  return visits?.find(
    (visit) => visit.status != null && CurrentVisitTypes.has(visit.status)
  );
}
