import { LocaleCode, Locales } from "lib/locales";

interface LocaleLanguageProps {
  locale: LocaleCode | string;
}

export default function LocaleLanguage({ locale }: LocaleLanguageProps) {
  const entry = Locales.find((entry) => {
    return entry.locale === locale;
  });

  if (entry) {
    return <>{entry.displayName}</>;
  }

  return null;
}
