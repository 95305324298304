export function makeChecksum(id: string) {
  const digits = id.replace(/\D/, "");

  if (digits.length !== 9) {
    return;
  }

  let sum = 0;
  let index = 0;

  for (const digit of digits) {
    const product = (+digit * (2 - (index++ % 2))).toString();
    for (const digit of product) {
      sum += +digit;
    }
  }

  const last = +sum.toString().slice(-1);
  return ((10 - last) % 10).toString();
}

export function isValid(id: string) {
  const digits = id.replace(/\D/, "");

  if (digits.length !== 10) {
    return false;
  }

  const firstNine = digits.slice(0, 9);
  const lastOne = digits.slice(9, 10);
  const checksum = makeChecksum(firstNine);

  if (checksum == null) {
    return false;
  }

  return lastOne === checksum;
}
