import { APITypesV1 } from "@cur8/api-client";
import { VisitSummary } from "@cur8/rich-entity";

export function toCreateRequest(
  visit: VisitSummary
): APITypesV1.CreateVisitSummaryRequest {
  return {
    followUpDate: visit.followUpDate ? visit.followUpDate.toISODate() : null,
    visitDate: visit.visitDate.toISODate(),
    summaryText: visit.summaryText,
  };
}

export function toUpdateRequest(
  visit: VisitSummary
): APITypesV1.ReplaceVisitSummaryRequest {
  return toCreateRequest(visit);
}
