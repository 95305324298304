import { LesionAnnotation } from "lib/lesion";
import XRay from "./components/XRay";
import styles from "./styles.module.sass";

interface LesionHeroImageProps {
  annotation: LesionAnnotation;
}

export default function LesionHeroImage({ annotation }: LesionHeroImageProps) {
  return (
    <div className={styles.LesionHeroImage}>
      <div className={styles.image} data-annotation-id={annotation?.id}>
        <XRay annotation={annotation} />
      </div>
    </div>
  );
}
