import { Patient } from "@cur8/rich-entity";
import CRPRange from "render/fragments/metric/range-charts/CRPRange";
import ArterialHealthItem from "render/fragments/metric/summary/ArterialHealthItem";
import ArterialStiffnessItem from "render/fragments/metric/summary/ArterialStiffnessItem";
import BloodFatsItem from "render/fragments/metric/summary/BloodFatsItem";
import HeartRythmItem from "render/fragments/metric/summary/HeartRythmItem";
import { useHighestBrachialPressure } from "render/hooks/api/metrics/useHighestBrachialPressure";
import { useAge } from "render/hooks/patient/useAge";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { Typography } from "render/ui/presentation/Typography";
import CardioGraphs from "./components/CardioGraphs";
import ProtectiveScore from "./components/ProtectiveScore";
import styles from "./styles.module.sass";

interface CardioSectionProps {
  patient: Patient;
}

export default function CardioSection({ patient }: CardioSectionProps) {
  const { ui } = useDashboardContext();

  const {
    scans,
    metrics: { cardio, bloodwork, risk },
    aggregates: { abi, tbi, score2 },
  } = usePatientData();

  const cardioScan = scans?.cardio.at(0);

  const age = useAge(patient);

  const highestBrachial = useHighestBrachialPressure(cardio.brachialPressure);

  return (
    <div className={styles.CardioSection}>
      <Typography as="h2" variant="display-s">
        Heart & Circulation
      </Typography>

      <hr />

      {age >= 40 && age <= 90 && (
        <div className={styles.assessments}>
          <ProtectiveScore patient={patient} />
        </div>
      )}

      <div className={styles.summary}>
        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.Heart });
          }}
        >
          <HeartRythmItem ecgDisc={risk.ecgDiscrepancy} />
        </button>

        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.ArterialHealth });
          }}
        >
          <ArterialHealthItem
            patient={patient}
            abi={abi}
            tbi={tbi}
            highestBrachial={highestBrachial?.unit}
          />
        </button>

        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.Cholesterol });
          }}
        >
          <BloodFatsItem
            patient={patient}
            bloodfats={bloodwork}
            score2={score2}
          />
        </button>

        <button
          type="button"
          onClick={() => {
            ui.set({ layer: Layer.PulseWaves });
          }}
        >
          <ArterialStiffnessItem />
        </button>
      </div>

      <div className={styles.graphs}>
        {cardioScan && <CardioGraphs scan={cardioScan} />}
      </div>

      <div>{bloodwork.crp && <CRPRange metrics={bloodwork.crp} />}</div>
    </div>
  );
}
