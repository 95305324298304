import { Sex } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { isValid } from "./pnr";

export function bankIdValid(id: string) {
  const digits = id.replace(/\D/, "");

  if (digits.length !== 12) {
    return false;
  }

  const pnr = digits.slice(2, 12);

  return isValid(pnr);
}

export function extractSex(id: string) {
  const filtered = id.replace(/[^\d]/g, "");
  if (filtered.length === 12) {
    const s = parseFloat(filtered[10]);
    if (isFinite(s)) {
      return s % 2 === 0 ? Sex.Female : Sex.Male;
    }
  }

  return Sex.Unknown;
}

export function extractBirthdate(id: string) {
  const filtered = id.replace(/[^\d]/g, "").slice(0, 8);
  if (filtered.length === 8) {
    const date = DateTime.fromFormat(filtered, "yyyyLLdd");

    if (date.isValid) {
      return date;
    }
  }
}
