import { APITypesV1 } from "@cur8/api-client";
import { Slot, Visit, fromAPI } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

function isCanceled(visit: Visit) {
  return visit.status === APITypesV1.VisitStatus.Cancelled;
}

export function usePatientUpcomingSlot(patientId: string) {
  const api = useAPIClient();
  const [slot, setSlot] = useState<AsyncResult<Slot>>();

  useEffect(() => {
    const req = api.visit.getPatientVisits(
      { patientId },
      {
        order: APITypesV1.SortOrder.Asc,
        endDateTimeOffsetRange: { start: DateTime.now().toISO() },
      }
    );

    req.result
      .then((response) => {
        return response.items
          .map(fromAPI.toVisit)
          .filter((visit) => !isCanceled(visit));
      })
      .then(async (visits) => {
        const visit = visits.at(0);
        if (!visit) {
          return;
        }

        const slot = await api.bookingV2
          .getSlot({ slotId: visit.slotId })
          .result.then(fromAPI.toSlot);
        setSlot({ data: slot });
      });
  }, [api.bookingV2, api.visit, patientId]);
  return slot?.data;
}
