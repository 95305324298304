import * as THREE from "three";

function createScatterBuffer(length: number) {
  const array = new Float32Array(length * 4);
  const buf = new THREE.Float32BufferAttribute(array, 4);

  for (let i = 0; i < buf.count; i++) {
    const x = (Math.random() - 0.5) * 2000;
    const y = Math.random() * -16000;
    const z = Math.random() * 2000;
    buf.setXYZW(i, x, y, z, 1);
  }

  return buf;
}

function createRandomSeedBuffer(length: number) {
  const array = Float32Array.from({ length }, () => Math.random() * 2 - 1);

  return new THREE.Float32BufferAttribute(array, 1);
}

export class AvatarGeometry extends THREE.BufferGeometry {
  constructor(pointCount: number) {
    super();

    const scatter = createScatterBuffer(pointCount);
    const random = createRandomSeedBuffer(pointCount);

    // Position must be set even when we do not use it in our shaders
    // otherwise THREE js freaks out :)
    this.setAttribute("position", scatter);
    this.setAttribute("scatter", scatter);
    this.setAttribute("deviation", random);
  }

  setPointPositions(
    attribute: "front" | "back" | "heart",
    buffer: THREE.Float32BufferAttribute
  ) {
    this.setAttribute(attribute, buffer);
  }
}
