import { ScheduleViewSlot, Slot, fromAPI } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { CalendarEvent } from "render/pages/SchedulePage/reducer/slotManagementUtils";

const IGNORE_ETAG = "*";

interface CreateSlotOptions {
  isOpenPublicly: boolean;
}

enum ScanVisitTypeId {
  UK = "4dd622c1-e65b-484e-84d5-87bbe78beffa",
  SE = "Scan",
}
enum Country {
  UK = "United Kingdom",
  Sweden = "Sweden",
}

const CountryToVisitIdMap = new Map<string, ScanVisitTypeId>([
  [Country.Sweden, ScanVisitTypeId.SE],
  [Country.UK, ScanVisitTypeId.UK],
]);

function getScanVisitTypeId(event: CalendarEvent): ScanVisitTypeId {
  const country = event.room.site?.address.country;
  if (country == null) {
    throw Error(`Country not defined for room: [${event.room.id}]`);
  }

  const scanVisitTypeId = CountryToVisitIdMap.get(country);

  if (scanVisitTypeId == null) {
    throw Error(`Could not match country: [${country}], to visit type`);
  }

  return scanVisitTypeId;
}

function createCreateSlotPayload(
  calendarEvent: CalendarEvent,
  options: CreateSlotOptions
) {
  const format = "yyyy-MM-dd'T'HH:mm:ss";
  const visitTypeId = getScanVisitTypeId(calendarEvent);

  const result = {
    startTime: calendarEvent.interval.start.toFormat(format),
    endTime: calendarEvent.interval.end.toFormat(format),
    roomId: calendarEvent.room.id,
    visitTypeId,
    isOpenPublicly: options.isOpenPublicly,
  };

  return result;
}

export function useBookingStore() {
  const { bookingV2 } = useAPIClient();

  const bookSlot = useCallback(
    async (
      slot: ScheduleViewSlot,
      payload: { patientId: string; bookingTokenId: string }
    ) => {
      if (slot.isBooked) {
        throw new Error("Slot is already booked");
      }

      return bookingV2
        .bookSlot({
          slot: { slotId: slot.id, eTag: slot.eTag },
          payload: payload,
        })
        .result.then(fromAPI.toSlot);
    },
    [bookingV2]
  );

  const unBookSlot = useCallback(
    (slot: Slot, payload: { refundToken: boolean }) => {
      return bookingV2.unBookSlot({
        slot: { slotId: slot.slotId, eTag: IGNORE_ETAG },
        payload,
      });
    },
    [bookingV2]
  );

  // Reserved -> Free transition
  const freeSlot = useCallback(
    async (slot: ScheduleViewSlot) => {
      return bookingV2.openSlot({ slotId: slot.id }).result;
    },
    [bookingV2]
  );

  // Free -> Reserved transition
  const reserveSlot = useCallback(
    async (slot: ScheduleViewSlot) => {
      return bookingV2.closeSlot({ slotId: slot.id }).result;
    },
    [bookingV2]
  );

  // Closed -> Free/Reserved transition
  const createSlot = useCallback(
    async (calendarEvent: CalendarEvent, options: CreateSlotOptions) => {
      const payload = createCreateSlotPayload(calendarEvent, options);

      return bookingV2.createSlot(payload).result;
    },
    [bookingV2]
  );

  // Free/Reserved -> Closed transition
  const deleteSlot = useCallback(
    (slot: ScheduleViewSlot) => {
      return bookingV2.deleteSlot({ eTag: slot.eTag, slotId: slot.id }).result;
    },
    [bookingV2]
  );

  return {
    unBookSlot,
    bookSlot,
    createSlot,
    freeSlot,
    reserveSlot,
    deleteSlot,
  };
}
