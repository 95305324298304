import { useCallback, useState } from "react";
import { cycle } from "lib/algo";

export function useCycleState<T>(
  states: T[]
): [T, () => void, (value: T) => void] {
  const [state, setState] = useState<T>(states[0]);

  const cycleState = useCallback(() => {
    setState((state) => cycle(states, state));
  }, [states]);

  return [state, cycleState, setState];
}
