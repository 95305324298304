export enum Layer {
  PulseWaves = "pwv",
  Bloodwork = "bloodwork",
  Body = "body",
  Cardio = "cardio",
  Circulation = "circulation",
  ArterialHealth = "arterial-health",
  Cholesterol = "cholesterol",
  Diabetes = "diabetes",
  Identity = "identity",
  Heart = "heart",
  Skin = "skin",
  LesionLibrary = "lesions",
  LesionMacro = "lesion-macro",
}
