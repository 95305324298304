import { useToggle } from "render/hooks/useToggle";
import styles from "./styles.module.sass";

interface ToggleProps {
  children: React.ReactNode;
  anchor: "left" | "right";
}

export default function Toggle({ children, anchor }: ToggleProps) {
  const [visible, toggleVisible] = useToggle(false);

  return (
    <div className={styles.Toggle} data-visible={visible}>
      <button
        className={styles.toggle}
        onClick={toggleVisible}
        style={{ [anchor]: "100%" }}
      >
        Toggle
      </button>

      {children}
    </div>
  );
}
