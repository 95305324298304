import { Annotation, fromAPI } from "@cur8/rich-entity";
import {
  CreateAnnotation,
  toCreateAnnotationRequest,
} from "lib/api/adapter/annotation";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";

export function useAnnotationCRUD() {
  const api = useAPIClient().annotation;

  const {
    entries: { set },
  } = useStore().annotation;

  const store = useCallback(
    (annotation: Annotation) => {
      set(annotation.id, annotation);
    },
    [set]
  );

  const create = useCallback(
    async (patientId: string, source: CreateAnnotation) => {
      const payload = toCreateAnnotationRequest(source);

      const annotation = await api
        .createAnnotation({ patientId }, payload)
        .result.then(fromAPI.toAnnotation);

      store(annotation);

      return annotation;
    },
    [api, store]
  );

  const replace = useCallback(
    async (
      patientId: string,
      annotationId: string,
      source: CreateAnnotation
    ) => {
      const payload = toCreateAnnotationRequest(source);

      const annotation = await api
        .replaceAnnotation({ patientId, annotationId }, payload)
        .result.then(fromAPI.toAnnotation);

      store(annotation);

      return annotation;
    },
    [api, store]
  );

  const remove = useCallback(
    (patientId: string, annotationId: string) => {
      return api.deleteAnnotation({ patientId, annotationId }).result;
    },
    [api]
  );

  return { create, replace, remove };
}
