import { Point as LCJSPoint } from "@arction/lcjs";
import {
  BoundingBoxAnnotation,
  BoundingBoxDataType,
  LineAnnotation,
  LineDataType,
  RangeAnnotation,
  RangeDataType,
} from "@cur8/rich-entity";
import { RecordingURI } from "@cur8/uri";
import { DateTime } from "luxon";

export enum Property {
  //chisqrNir = "chisqr_nir",
  //chisqrVis = "chisqr_vis",
  epidermisVolume = "melanin_volume_fraction_l1",
  t1BloodVolume = "blood_volume_fraction_T1",
  t1Oxygenation = "oxygenation_T1",
  t2BloodVolume = "blood_volume_fraction_T2",
  t2Oxygenation = "oxygenation_T2",
  waterConcentration = "water_volume_fraction_T2",
  t1Overt2BloowVolume = "blood_volume_fraction_T1_over_T2",
  t1Overt2Oxygenation = "oxygenation_T1_over_T2",
  thermal = "thermal",
  penDepthNir = "penetration_depth_nir",
  penDepthVis = "penetration_depth_vis",
}

export type PropertyLabel = [Property, string];

export const PROPERTIES = new Map<Property, string>([
  //[ Property.chisqrNir, "Chisqr NIR" ],
  //[ Property.chisqrVis, "Chisqr Visual" ],
  [Property.epidermisVolume, "Epidermis Melanin Volume Fraction"],
  [Property.t1BloodVolume, "T1 Blood Volume Fraction"],
  [Property.t1Oxygenation, "T1 Oxygenation"],
  [Property.t2BloodVolume, "T2 Blood Volume Fraction"],
  [Property.t2Oxygenation, "T2 Oxygenation"],
  [Property.waterConcentration, "T2 Water Concentration"],
  [Property.t1Overt2BloowVolume, "T1/T2 Blood Volume Fraction"],
  [Property.t1Overt2Oxygenation, "T1/T2 Oxygenation"],
  [Property.thermal, "Thermal"],
  [Property.penDepthNir, "Penetration Depth NIR"],
  [Property.penDepthVis, "Penetration Depth Visual"],
]);

export type BloodVesselsMask = {
  threshold?: number;
  extract?: boolean;
};
export enum BloodVesselsMode {
  inactive = "inactive",
  excluded = "excluded",
  extracted = "extracted",
}
export type BloodVesselsThresholds = {
  extracted: number;
  excluded: number;
};

export const BloodVesselsModes = [
  BloodVesselsMode.inactive,
  BloodVesselsMode.excluded,
  BloodVesselsMode.extracted,
];

export enum ImageType {
  normal = "normal",
  highContrast = "highContrast",
  rgb = "rgb",
}
export const IMAGETYPES = new Set<ImageType>([
  ImageType.normal,
  ImageType.highContrast,
  ImageType.rgb,
]);

export const PLAYBACK_FRAME_RATE = 2;

export enum TissueContextMode {
  Regular,
  PAD,
}

export type TissueImage = {
  image?: HTMLImageElement;
  imin?: number;
  imax?: number;
  threshold_exclude_blood_vessels?: number;
  threshold_extract_blood_vessels?: number;
};

export type TissueImages = {
  viewer: TissueImage | undefined;
  chart: TissueImage | undefined;
  bloodVessels?: TissueImage | undefined;
};

export type ImageSize = {
  width: number;
  height: number;
};

export type PropertyRange = {
  low: number;
  high: number;
  unit: string;
  factor?: number; // Conversion factor between unit => display unit (+ correction sometimes)
  displayUnit?: string;
};
export type PropertyRanges = Record<Property, PropertyRange>;

export type MetadataScan = {
  id: string;
  version: string;
  patientId: string;
  sourceUris: (RecordingURI | string)[];
  type: string;
};

export type RawProcessedMetadata = {
  blood_vessels_mask?: boolean;
  created_at?: string;
  errors?: ProcessedErrors<string>;
  events?: string[];
  pad_mean_blood_volume_fraction_T1?: boolean;
  pad_mean_oxygenation_T1?: boolean;
  premetadata?: boolean;
  ranges: PropertyRanges;
  scan: MetadataScan;
  succeeded?: number[];
  timestamp_s: number[];
  tissue_algo_version?: string;
  version?: number;
};

export type ProcessedMetadata = {
  blood_vessels_mask: boolean;
  created_at?: DateTime;
  errors: ProcessedErrors<ProcessedErrorData>;
  events: string[];
  pad_mean_blood_volume_fraction_T1: boolean;
  pad_mean_oxygenation_T1: boolean;
  premetadata: boolean;
  ranges: PropertyRanges;
  scan: MetadataScan;
  succeeded: number[];
  timestamp_s: number[];
  tissue_algo_version: string;
  version: number;
};

export type ProcessedErrorData = {
  description: string;
  details: string;
};

export type ProcessedErrors<T> = Record<number, T>;

export type Tick = {
  label: string;
  bottom: number;
};

export enum TargetProperty {
  nonthermal = "nonthermal",
  thermal = "thermal",
}
export const isTargetProperty = (p: string): p is TargetProperty => {
  return Object.values(TargetProperty).includes(p as TargetProperty);
};

export type TissueAnnotationTypes =
  | BoundingBoxAnnotation
  | LineAnnotation
  | RangeAnnotation;
export declare type TissueAnnotation<
  Annotation extends TissueAnnotationTypes = TissueAnnotationTypes
> = {
  annotation: Annotation;
  color?: string;
  label: string;
  property: TargetProperty;
};

export type TissueAnnotationDataTypes =
  | LineDataType
  | BoundingBoxDataType
  | RangeDataType;
export enum TissueAnnotationDataType {
  Region = "roi",
  Line = "line",
  Time = "toi",
}

export type CreateTissueAnnotation<
  Data extends TissueAnnotationDataTypes = TissueAnnotationDataTypes
> = {
  annotation: {
    applicationSpecificTarget: string;
    data: Data;
    id?: string;
  };
  color?: string;
  label: string;
  property: TargetProperty;
  type: TissueAnnotationDataType;
};

export type TimeRange = {
  from: number; // Timestamp index
  to: number;
  anno: TissueAnnotation<RangeAnnotation>;
};

export type { LCJSPoint };
export type RegionTimeseries = {
  color: string;
  id: TissueAnnotation["annotation"]["id"];
  label: string;
  series: LCJSPoint[];
};

export const ToIModes = {
  Disabled: 0,
  SetStartTime: 1,
  SetEndTime: 2,
  Label: 3,
  Done: 4,
} as const;
