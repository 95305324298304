import { useCallback, useState, useEffect } from "react";
import Fieldset from "render/ui/form/Fieldset";
import Field from "render/ui/form/Field";
import Input from "render/ui/form/Input";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";
import { useUserInput } from "render/hooks/useUserInput";
import { useFormHandle } from "render/hooks/useFormHandle";
import { Entries, toMetrics, toEntries } from "./conversion";
import { useFractureMetrics } from "./hooks/useFractureMetrics";

interface FractureSectionProps {
  patientId: string;
}

const INITIAL: Entries = {
  fraxScore: "",
};

export default function FractureSection({ patientId }: FractureSectionProps) {
  const { fetchFractureMetrics, updateFractureMetrics } = useFractureMetrics();

  const [entries, setEntries] = useState<Entries>(INITIAL);
  const userInput = useUserInput(entries, setEntries);

  const handleSave = useCallback(async () => {
    return updateFractureMetrics(patientId, toMetrics(entries));
  }, [entries, patientId, updateFractureMetrics]);

  const formHandle = useFormHandle(handleSave);

  useEffect(() => {
    fetchFractureMetrics(patientId).then(toEntries).then(setEntries);
    return () => {
      setEntries(INITIAL);
    };
  }, [fetchFractureMetrics, patientId]);

  return (
    <form onSubmit={formHandle.onSubmit}>
      <Fieldset legend="Fracture">
        <Field label="FRAX Score">
          <Input hint="%">
            <input
              type="number"
              min={0}
              max={40}
              step="any"
              autoComplete="off"
              {...userInput.fraxScore}
            />
          </Input>
        </Field>

        <ButtonSet>
          <SubmitButton handle={formHandle}>Save</SubmitButton>
        </ButtonSet>
      </Fieldset>
    </form>
  );
}
