const CHARS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export function generatePsuedoRandomString(len: number, prefix = "") {
  const chars: string[] = [];
  for (let i = 0; i < len; i++) {
    const index = Math.random() * CHARS.length;
    const char = CHARS[Math.floor(index)];
    chars.push(char);
  }
  return prefix + chars.join("");
}
