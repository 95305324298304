import { Risk } from "@cur8/health-risks-calc";
import { Unit } from "@cur8/measurements";
import {
  AggregateEntry,
  MergedEntry,
  MergedRecord,
} from "lib/doctor-scribe/types";

export function addAlcoholRisk(record: MergedRecord) {
  const entries = record["lifestyle.drinking-habit"];
  const N = entries ? Object.keys(entries).length : 0;
  if (N > 0) {
    const newRecords: AggregateEntry[] = [];
    for (let i = 0; i < N; i++) {
      const weeklyDrinks = (
        entries[i].value as Unit<"lifestyle.drinking-habit">
      ).weeklyDrinks;
      if (weeklyDrinks) {
        newRecords.push({
          timestamp: entries[i].timestamp,
          value: { weeklyDrinks },
          risk: weeklyDrinks > 9 ? Risk.HighRisk : Risk.Normal,
        });
      }
    }

    record["lifestyle.drinking-habit"] = newRecords as MergedEntry[];
  }

  return record;
}
