import { TargetProperty, isTargetProperty } from "./types";

const PREFIX = "tci1";

export class TissueURI {
  property: TargetProperty;
  label: string;

  /**
   * Example URI:
   *   tci:property:<property>:label:<label>
   */
  static parse(text: string | undefined) {
    if (!text) {
      return null;
    }
    const parts = text.split(":");
    if (
      parts.length !== 5 ||
      parts[0] !== PREFIX ||
      parts[1] !== "property" ||
      parts[3] !== "label"
    ) {
      return null;
    }

    const [, , property, , label] = parts.map(decodeURIComponent);

    if (!isTargetProperty(property)) {
      return null;
    }

    return new TissueURI(property, label);
  }

  static fromString(text: string) {
    const uri = TissueURI.parse(text);
    if (!uri) {
      throw new Error("Not a TissueURI");
    }
    return uri;
  }

  constructor(property: TargetProperty, label: string) {
    this.label = label;
    this.property = property;
  }

  toString(): string {
    const parts = [PREFIX, "property", this.property, "label", this.label];

    return parts.map(encodeURIComponent).join(":");
  }
}
