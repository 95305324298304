import { useMemo } from "react";
import { useDiscountToken } from "render/hooks/api/billing/useDiscountToken";
import { usePatientProduct } from "./usePatientProduct";

export const THAT_ONE_PRODUCT_WE_HAVE = "126cc5ed-ce71-4965-845d-bf3423bdb6b9";
const REBOOKING_DISCOUNT_CODE_SE = "rebook_se_drzzf8dt";
const REBOOKING_DISCOUNT_CODE_GB = "rebook_gb_cosuytid";

export const getRebookingDiscountCode = (country: string) => {
  switch (country) {
    case "SE":
      return REBOOKING_DISCOUNT_CODE_SE;
    case "GB":
      return REBOOKING_DISCOUNT_CODE_GB;
    default:
      throw new Error(`No rebooking code for country: ${country}`);
  }
};

export function useBookingPrice(patientId: string, country: string) {
  const { product } = usePatientProduct(patientId, THAT_ONE_PRODUCT_WE_HAVE);
  const { discountToken } = useDiscountToken(getRebookingDiscountCode(country));

  const totalDiscount = useMemo(() => {
    if (!discountToken?.discountAmount) {
      return undefined;
    }

    return discountToken.discountAmount;
  }, [discountToken]);

  const total = useMemo(() => {
    if (!product?.price || !discountToken?.discountAmount) {
      return undefined;
    }

    return product.price - discountToken.discountAmount;
  }, [discountToken, product]);

  const currency = useMemo(() => {
    if (!product?.currency) {
      return undefined;
    }

    return product.currency;
  }, [product]);

  return {
    totalDiscount,
    total,
    currency,
  };
}
