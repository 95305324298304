import { APIClient } from "lib/api/client";
import { createContext } from "react";

type APIContextState = {
  client?: APIClient;
};

const INITIAL: APIContextState = {
  client: undefined,
};

export const Context = createContext<APIContextState>(INITIAL);
