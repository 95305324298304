import {
  Assignment,
  AktiiaMonitorBloodPressureAssignmentData,
} from "@cur8/rich-entity";
import { useEffect } from "react";
import { useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import styles from "./styles.module.sass";
import FullDate from "render/ui/format/FullDate";
import AssignedToDoctor from "../AssignedToDoctor";
import LongArrowIcon from "./assets/long-arrow.svg?react";
import { useMemo, useState } from "react";
import { APITypesV1 } from "@cur8/api-client";
import AssignedToPatient from "../AssignedToPatient";
import { useAPIClient } from "render/context/APIContext";

interface AktiiaAssignmentTableRowProps {
  assignment: Assignment<AktiiaMonitorBloodPressureAssignmentData>;
}

const AktiiaAssignmentLabels: Record<APITypesV1.AssignmentState, string> = {
  [APITypesV1.AssignmentState.Assigned]: "Assigned",
  [APITypesV1.AssignmentState.Terminated]: "Abandoned",
  [APITypesV1.AssignmentState.Postponed]: "Postponed",
  [APITypesV1.AssignmentState.Accepted]: "Not started",
  [APITypesV1.AssignmentState.InProgress]: "In Progress",
  [APITypesV1.AssignmentState.Submitted]: "Submitted",
  [APITypesV1.AssignmentState.Completed]: "Completed",
  [APITypesV1.AssignmentState.Closed]: "Closed",
};

const Status = {
  NoAction: "#E3E5E5",
  InProgress: "#48D3E5",
  RequiresAction: "#F96452",
};

const Assigned = {
  Doctor: "doctor",
  Patient: "patient",
  None: "none",
} as const;

export default function AktiiaAssignmentTableRow({
  assignment,
}: AktiiaAssignmentTableRowProps) {
  const apiClient = useAPIClient();
  const [assignmentDevice, setAssignmentDevice] =
    useState<APITypesV1.AssignmentDeviceInventory>();

  useEffect(() => {
    const request = apiClient.assignment.queryDevicesBy({
      assignmentId: assignment.id,
      pageSize: 1,
    });

    request.result.then(({ items }) => {
      setAssignmentDevice(items[0]);
    });

    return request.abandon;
  }, [apiClient.assignment, assignment.id]);

  const { id, patientId } = assignment;
  const nav = {
    bloodpressure: useNav(paths.patient.assignment.bloodpressure),
  };
  const label = useMemo(() => {
    return AktiiaAssignmentLabels[assignment.data.state];
  }, [assignment]);
  const action = useMemo(() => {
    switch (assignment.data.state) {
      case APITypesV1.AssignmentState.Accepted:
      case APITypesV1.AssignmentState.InProgress:
        return Status.InProgress;

      case APITypesV1.AssignmentState.Submitted:
      case APITypesV1.AssignmentState.Completed:
        return Status.RequiresAction;
      default:
        return Status.NoAction;
    }
  }, [assignment]);

  const assignedTo = useMemo(() => {
    switch (assignment.data.state) {
      case APITypesV1.AssignmentState.Accepted:
      case APITypesV1.AssignmentState.InProgress:
        return Assigned.Patient;
      case APITypesV1.AssignmentState.Completed:
      case APITypesV1.AssignmentState.Submitted:
        return Assigned.Doctor;
      default:
        return Assigned.None;
    }
  }, [assignment]);

  return (
    <tr
      className={styles.AktiiaAssignmentTableRow}
      key={id}
      onClick={nav.bloodpressure.on({ patientId, assignmentId: id })}
    >
      <td>
        <div className={styles.bubble} style={{ background: action }} />
      </td>
      <td>Monitor Blood Pressure</td>
      <td>Assignment</td>
      <td>
        <FullDate date={assignment.startAt} />
      </td>
      <td>{label}</td>
      <td>{assignmentDevice?.alias ?? "--/--"}</td>
      <td>
        {assignedTo === Assigned.Patient && (
          <AssignedToPatient patientId={assignment.patientId} />
        )}
        {assignedTo === Assigned.Doctor && (
          <>
            {assignment.assignedTo && (
              <AssignedToDoctor doctor={assignment.assignedTo} />
            )}
          </>
        )}
        {assignedTo === Assigned.None && "--/--"}
      </td>

      <td className={styles.cta}>
        <div>View</div>
        <div>
          <LongArrowIcon />
        </div>
      </td>
    </tr>
  );
}
