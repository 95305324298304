import { APIClient } from "lib/api/client";
import { useEffect, useState } from "react";
import { useBlobImage } from "render/hooks/useBlobImage";
import { useReporting } from "render/hooks/useReporting";

type ImageRequest = ReturnType<APIClient["transcode"]["fetchPatientImage"]>;

export function useTranscoderImage(fetch?: () => ImageRequest) {
  const { handleError } = useReporting();

  const [blob, setBlob] = useState<Blob>();

  useEffect(() => {
    if (!fetch) {
      return;
    }

    const request = fetch();

    request.result
      .then((res) => {
        setBlob(res.blob);
      })
      .catch(handleError);

    return () => {
      setBlob(undefined);
      request.abandon();
    };
  }, [fetch, handleError]);

  return useBlobImage(blob);
}
