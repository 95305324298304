import { Patient } from "@cur8/rich-entity";
import ProtectiveScoreGraph from "./components/ProtectiveScoreGraph";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";

interface ProtectiveScoreProps {
  patient: Patient;
}

export default function ProtectiveScore({ patient }: ProtectiveScoreProps) {
  const age = useAge(patient);
  const { ageBreakPoints, ageMax, ageMin, labels, rangeY } = useMemo(() => {
    if (age < 70) {
      return {
        rangeY: 25,
        labels: [
          { value: 97.5, title: "Moderate" },
          { value: 92.5, title: "Low" },
        ],
        ageMin: 35,
        ageMax: 75,
        ageBreakPoints: [40, 50, 60, 70],
      };
    }
    return {
      rangeY: 62,
      labels: [
        { value: 92.5, title: "Moderate" },
        { value: 85.0, title: "Low" },
      ],
      ageMin: 65,
      ageMax: 95,
      ageBreakPoints: [70, 80, 90],
    };
  }, [age]);

  return (
    <ProtectiveScoreGraph
      patient={patient}
      ageBreakPoints={ageBreakPoints}
      ageMax={ageMax}
      ageMin={ageMin}
      labels={labels}
      rangeY={rangeY}
    />
  );
}
