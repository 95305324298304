import { useAPIClient } from "render/context/APIContext";
import { APIFetchError, APITypesV1 } from "@cur8/api-client";
import { useReporting } from "render/hooks/useReporting";
import { useCallback } from "react";

export function usePhysicalArtefactCRUD(patientId: string) {
  const { handleError } = useReporting();

  const api = useAPIClient().physicalArtefact;

  const create = useCallback(
    async (
      physicalArtefact: APITypesV1.CreatePhysicalArtefactFromAnnotationRequest
    ) => {
      return api
        .createForAnnotation({ patientId }, physicalArtefact)
        .result.catch((err) => {
          if (err instanceof APIFetchError && err.response.status === 409) {
            // We ignore the error if the physical artefact already exists
            // Explanation:
            // in case of using magic wand (createing a panorama annotation from the existing RGBino annotation)
            // the physical artefact will already exist, since it has been created by processing.
            //
            // But in case of creating the panorama annotation via selection tool, we still need to create Physical Artefact

            return;
          }

          handleError(err);
        });
    },
    [api, patientId, handleError]
  );

  return {
    create,
  };
}
