import { NonHDL } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { isScore2HighRisk } from "lib/scores/score2";
import { useMemo } from "react";
import { Score2 } from "render/hooks/api/metrics/useScore2";
import { useAge } from "render/hooks/patient/useAge";
import MetricRange from "render/ui/presentation/MetricRange";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface NonHDLRangeProps {
  patient: Patient;
  metrics: Metric<"bloodwork.non_hdl">[];
  score2: Score2 | undefined;
}

export default function NonHDLRange({
  patient,
  metrics,
  score2,
}: NonHDLRangeProps) {
  const age = useAge(patient);
  const isScore2WithHighRisk = useMemo(() => {
    if (!score2) {
      return false;
    }

    return isScore2HighRisk(age, score2.score);
  }, [age, score2]);

  const riskRange = useMemo(() => {
    return NonHDL.rangesFor({
      sex: patient.sex,
      age,
      isScore2HighRisk: isScore2WithHighRisk,
    });
  }, [patient.sex, age, isScore2WithHighRisk]);

  const ranges = useMemo(() => {
    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["mmol/L"],
      };
    });
  }, [metrics, riskRange]);

  return (
    <MetricRange
      title="Non-HDL"
      unit="mmol/L"
      entries={entries}
      ranges={ranges}
      formatValue={(value) => value?.toFixed(2)}
    />
  );
}
