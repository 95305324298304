import { useCallback } from "react";
import styles from "./styles.module.sass";

import WeekTitle from "./fragments";
import CalendarStateDescription from "./components/CalendarStateDescription";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import useCalendarInteraction from "../Calendar/hooks/useCalendarInteraction";
import PeriodJumper from "./components/PeriodJumper";
import ViewRangeSelector from "./components/ViewRangeSelector/ViewRangeSelector";
import AppointmentCensor from "./components/AppointmentCensor";
import useCalendarState from "../Calendar/hooks/useCalendarState";
import { useSchedule } from "../../context/ScheduleContext";

export default function SchedulePageSubHeader() {
  const { isSlotManagementEnabled } = useSchedule();
  const { enterCalendarEditMode } = useCalendarInteraction();
  const { calendarInteractionMode, selectedPeriod, viewRange } =
    useCalendarState();

  const handleEditCalendarClicked = useCallback(() => {
    enterCalendarEditMode();
  }, [enterCalendarEditMode]);

  return (
    <div className={styles.SchedulePageSubHeader}>
      <ViewRangeSelector />

      <div key={selectedPeriod.start.toUnixInteger()} className={styles.period}>
        <WeekTitle
          selectedInterval={selectedPeriod}
          viewRangeMode={viewRange}
        />
      </div>
      <AppointmentCensor />
      {isSlotManagementEnabled && calendarInteractionMode === "viewing" && (
        <div>
          <HoverTextButton onClick={handleEditCalendarClicked}>
            Edit slots
          </HoverTextButton>
        </div>
      )}
      <div className={styles.details}>
        <CalendarStateDescription />
      </div>
      <div className={styles.rightSide}>
        <PeriodJumper />
      </div>
    </div>
  );
}
