import { Side } from "@cur8/api-client";
import { codecs, createCodec, createQuery } from "@pomle/paths";
import { useQueryParams } from "@pomle/react-router-paths";
import { useCallback } from "react";
import { Layer } from "render/pages/DashboardPage/types";

const VALID_LAYERS = new Set(Object.values(Layer));

const layer = createCodec(
  (source: Layer) => source,
  (source: string) => {
    if (VALID_LAYERS.has(source as Layer)) {
      return source as Layer;
    }

    return Layer.Identity;
  }
);

type Camera = {
  side: Side;
  module: string;
};

const camera = createCodec(
  (source: Camera) => [source.side, source.module].join("."),
  (source: string) => {
    const [side, module] = source.split(".");
    return {
      side,
      module,
    } as Camera;
  }
);

const query = createQuery({
  layer,
  physId: codecs.string,
  dermaId: codecs.string,
  camera,
});

type Prefs = Partial<{
  layer: Layer;
  physId: string;
  dermaId: string;
  camera: Camera;
}>;

export function useUI() {
  const [params, setParams] = useQueryParams(query);

  const set = useCallback(
    (prefs: Prefs) => {
      const params: Record<string, unknown[]> = {};
      for (const [key, value] of Object.entries(prefs)) {
        params[key] = value ? [value] : [];
      }

      setParams(params);
    },
    [setParams]
  );

  return {
    layer: params.layer.at(0) ?? Layer.Identity,

    physId: params.physId.at(0),

    camera: params.camera.at(0),

    dermaId: params.dermaId.at(0),

    set,
  };
}
