import { ImmutableScan } from "@cur8/rich-entity";

export enum ValidScanTypes {
  Cardio = "cardio",
  Skin = "skin",
  Stethoscope = "stetho",
  Tissue = "tci",
}
export type ParsedScanType = {
  type: ValidScanTypes;
  version: number;
  subtype?: string;
};

export function isValidScanTypes(type: string): type is ValidScanTypes {
  return Object.values(ValidScanTypes).includes(type as ValidScanTypes);
}

export function parseScanType(
  scanType: ImmutableScan["scanType"]
): ParsedScanType | null {
  const [rawType, version] = scanType.split("/");
  const [splitType, subtype] = rawType.split(".");

  if (!parseInt(version)) {
    return null;
  }
  if (!isValidScanTypes(splitType)) {
    return null;
  }

  return {
    type: splitType,
    version: parseInt(version),
    subtype: subtype || undefined,
  };
}

export function toString(pt: ParsedScanType): string {
  let str = "" + pt.type;
  if (pt.subtype && pt.subtype.length > 0) {
    str += `.${pt.subtype}`;
  }
  str += `/${pt.version}`;
  return str;
}
