import { debounce } from "@pomle/throb";
import { Lesion } from "lib/lesion";
import { mapRange } from "lib/map";
import { clamp } from "lib/math";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { Layer } from "render/pages/DashboardPage/types";
import LesionGridItem from "./components/LesionGridItem";
import styles from "./styles.module.sass";

const CELL_SIZE = {
  x: 108,
  y: 124,
};

const GRID_GAP = {
  x: 8,
  y: 16,
};

type Size = {
  x: number;
  y: number;
};

class GridLayout {
  size: Size;
  cell: Size;
  gap: Size;
  slots: Size;
  content: Size;
  spacing: Size;
  length: number;

  constructor(size: Size, cell: Size, gap: Size) {
    this.size = size;
    this.cell = cell;
    this.gap = gap;

    this.slots = {
      x: Math.floor(size.x / (cell.x + gap.x)),
      y: Math.floor(size.y / (cell.y + gap.y)),
    };

    this.content = {
      x: cell.x * this.slots.x,
      y: cell.y * this.slots.y,
    };

    this.spacing = {
      x: (size.x - this.content.x) / (this.slots.x - 1),
      y: (size.y - this.content.y) / (this.slots.y - 1),
    };

    this.length = this.slots.x * this.slots.y;
  }

  getPosition(index: number) {
    const absIndex = index % this.length;

    const iy = Math.floor(absIndex / this.slots.x);
    const ix = absIndex - iy * this.slots.x;

    return {
      x: ix * (this.cell.x + this.spacing.x),
      y: iy * (this.cell.y + this.gap.y),
      ix,
      iy,
    };
  }
}

type Page = {
  index: number;
  count: number;
  size: number;
};

interface LesionAnnotationImagePoolProps {
  active: boolean;
  lesions: Lesion[];
  page: Page;
  onChange(fn: (page: Page) => Page): void;
}

export default function LesionAnnotationImagePool({
  active,
  lesions,
  page,
  onChange,
}: LesionAnnotationImagePoolProps) {
  const { ui } = useDashboardContext();

  const ref = useRef<HTMLDivElement>(null);

  const [grid, setGrid] = useState<GridLayout>();

  const pageIndex = page.index;
  const pageSize = grid?.length ?? NaN;

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    const updatePageSize = () => {
      const grid = new GridLayout(
        { x: element.offsetWidth, y: element.offsetHeight },
        CELL_SIZE,
        GRID_GAP
      );

      setGrid(grid);

      const pageSize = grid.length;

      onChange((page) => {
        return {
          ...page,
          count: Math.ceil(lesions.length / pageSize),
          size: pageSize,
        };
      });
    };

    const observer = new ResizeObserver(debounce(updatePageSize, 500));
    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [lesions, onChange]);

  const pages = useMemo(() => {
    const pages: (typeof lesions)[] = [];
    if (!pageSize) {
      return pages;
    }

    const pool = [...lesions];

    while (pool.length) {
      const slice = pool.splice(0, pageSize);
      pages.push(slice);
    }

    return pages;
  }, [lesions, pageSize]);

  return (
    <div className={styles.PaginatedImagePool} data-active={active}>
      <div className={styles.pages} ref={ref}>
        {grid &&
          mapRange(pages, pageIndex - 1, pageIndex + 1, (lesions, index) => {
            const offset = clamp(index - pageIndex, -1, 1);

            return (
              <div
                className={styles.lesions}
                key={index}
                style={{
                  transform: `translateX(calc((100% + 100px) * ${offset}))`,
                }}
              >
                {lesions.map((lesion, index) => {
                  const pos = grid?.getPosition(index);

                  const delay = 0.3 + pos.ix * 0.025 + pos.iy * 0.08;

                  return (
                    <button
                      className={styles.lesion}
                      key={lesion.physicalId}
                      style={{
                        transform: `translate(
                          ${pos.x}px,
                          ${pos.y}px
                        )`,
                      }}
                      data-physical-id={lesion.physicalId}
                      onClick={() => {
                        ui.set({
                          layer: Layer.LesionMacro,
                          physId: lesion.physicalId,
                        });
                      }}
                    >
                      <LesionGridItem
                        active={active && offset === 0}
                        delay={delay}
                        lesion={lesion}
                      />
                    </button>
                  );
                })}
              </div>
            );
          })}
      </div>
    </div>
  );
}
