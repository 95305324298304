import usePeriodSelector from "render/pages/SchedulePage/hooks/usePeriodSelector";
import useCalendarState, {
  ViewRangeMode,
} from "../../../Calendar/hooks/useCalendarState";
import styles from "./styles.module.sass";

export default function PeriodJumper() {
  const { viewRange } = useCalendarState();
  const { monthJumper, goToToday } = usePeriodSelector();

  return (
    <div className={styles.PeriodJumper}>
      Jump to:
      <button onClick={() => goToToday(viewRange)}>Today</button>
      {viewRange !== ViewRangeMode.Daily && (
        <>
          <button onClick={() => monthJumper(6)}>6M</button>
          <button onClick={() => monthJumper(9)}>9M</button>
          <button onClick={() => monthJumper(12)}>12M</button>
        </>
      )}
    </div>
  );
}
