import { useCallback, useEffect, useRef } from "react";
import { PhysicalIdType } from "@cur8/uri";
import { bankIdValid } from "lib/bankId";

const ID_PATTERNS: Partial<Record<PhysicalIdType, string>> = {
  [PhysicalIdType.BankId]: "^\\d{12}$",
};

const PLACEHOLDERS: Partial<Record<PhysicalIdType, string>> = {
  [PhysicalIdType.BankId]: "YYYYMMDD NNNN",
};

interface PhysicalIdInputProps {
  idType: PhysicalIdType;
  value: string;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
  required?: boolean;
}

export default function PhysicalIdInput({
  idType,
  value,
  onChange,
  required = false,
}: PhysicalIdInputProps) {
  const ref = useRef<HTMLInputElement>(null);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (idType === PhysicalIdType.BankId) {
        event.currentTarget.value = event.currentTarget.value.replace(/\D/, "");
      }

      onChange(event);
    },
    [idType, onChange]
  );

  const checkError = useCallback((idType: PhysicalIdType, text: string) => {
    const input = ref.current;
    if (!input) {
      return;
    }

    if (text.length === 0) {
      input.setCustomValidity("");
      return;
    }

    if (idType === PhysicalIdType.BankId) {
      if (input.pattern.length > 0) {
        const regexp = new RegExp(input.pattern);
        const match = text.match(regexp);
        if (!match) {
          input.setCustomValidity("Please match YYYYMMDDNNNN");
          return;
        }
      }

      if (!bankIdValid(text)) {
        input.setCustomValidity("Please enter valid number");
        return;
      }
    }

    input.setCustomValidity("");
  }, []);

  useEffect(() => {
    checkError(idType, value);
  }, [checkError, idType, value]);

  const pattern = ID_PATTERNS[idType];
  const placeholder = PLACEHOLDERS[idType];

  return (
    <input
      ref={ref}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      pattern={pattern}
      required={required}
    />
  );
}
