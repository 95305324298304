import { Annotation, BoundingBoxAnnotation } from "@cur8/rich-entity";
import { TranscoderImageResponse } from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { Box, Point, toAbsoluteBox, toRelativeBox } from "lib/math";
import { MouseEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useReporting } from "render/hooks/useReporting";
import BoxAreas from "render/ui/presentation/BoxAreas";
import { Area } from "render/ui/presentation/BoxAreas/types";
import TiledImage from "render/ui/presentation/TiledImage";
import BusyPlaceholder from "render/ui/throbber/BusyPlaceholder";
import { Marking } from "../../../../../../types";
import MarkingBox from "./components/Marking";
import { usePanoramaFetch } from "./hooks/usePanoramaFetch";

/*
While building this tool and using DOM elements large as 25000x25000 pixels,
I noticed lots of flickering when zooming in and out. Presumably because the 
render engine threw the decoded image data out.
In order to fix this, scaling down the DOM elements, and upscaling the image 
data made the flicker effects go away. That is why this strange magic number 
exists, which seems to pointlessly divide and multiply various numbers.
It is currently unknown if this is still a problem.
*/
const MAGIC_SCALE_FACTOR = 5;

interface ImagePresentationLayerProps {
  panoramaURI: PanoramaImageURI;
  markings: Marking[];
  onMarkingSelected: (marking: Marking) => void;
  onRectCreated: (rect: Box, annotation?: Annotation) => Promise<void>;
  onAnnotationMove: (
    annotation: BoundingBoxAnnotation,
    movement: Point
  ) => void;
  highlightedAnnotation?: Marking;
  detectedAnnotations: Annotation[];
}

export default function ImagePresentationLayer({
  panoramaURI,
  markings,
  onMarkingSelected,
  onRectCreated,
  onAnnotationMove,
  highlightedAnnotation,
  detectedAnnotations,
}: ImagePresentationLayerProps) {
  const { handleError } = useReporting();

  const fetchTile = usePanoramaFetch(panoramaURI);

  const [source, setSource] = useState<TranscoderImageResponse>();

  const [movingMarking, setMovingMarking] = useState<Marking>();

  const [focusPoint, setFocusPoint] = useState<Point>();

  const size = useMemo(() => {
    if (!source) {
      return;
    }

    return source.meta.original.size;
  }, [source]);

  useEffect(() => {
    fetchTile({ w: 2048, h: 2048, fit: "contain" })
      .then(setSource)
      .catch(handleError);

    return () => {
      setSource(undefined);
    };
  }, [fetchTile, handleError]);

  const areas = useMemo(() => {
    if (!size) {
      return [];
    }

    return markings.map((marking): Area => {
      const annotation = marking.annotation;
      const rect = annotation.data.rect;

      return {
        id: annotation.id,
        physicalArtefactId: annotation.physicalArtefactId || "",
        bounds: toRelativeBox(rect, size.w, size.h),
        content: (
          <MarkingBox
            marking={marking}
            highlight={
              marking.annotation.id === highlightedAnnotation?.annotation.id
            }
            onClick={(event) => {
              onMarkingSelected(marking);
              if (event) {
                setFocusPoint(new Point(event.clientX, event.clientY));
              }
            }}
            onSelectedEvent={(selected) => {
              if (selected) {
                setMovingMarking(marking);
              } else {
                setMovingMarking(undefined);
                setFocusPoint(undefined);
              }
            }}
          />
        ),
      };
    });
  }, [size, markings, highlightedAnnotation, onMarkingSelected]);

  const handleArea = useCallback(
    async (
      area: Area,
      isRelative: boolean,
      annotation?: Annotation,
      event?: MouseEvent<Element>
    ) => {
      if (!size) {
        throw new Error("Size not available");
      }

      const bounds = area.bounds;

      const crop = isRelative ? toAbsoluteBox(bounds, size.w, size.h) : bounds;

      await onRectCreated(crop, annotation);
      if (event) {
        setFocusPoint(new Point(event.clientX, event.clientY));
      }
    },
    [size, onRectCreated]
  );

  const handleMove = useCallback(
    (annotation: BoundingBoxAnnotation, movement: Point) => {
      onAnnotationMove(annotation, movement);
      setFocusPoint(undefined);
    },
    [onAnnotationMove]
  );

  const onFocused = useCallback(() => {
    setFocusPoint(undefined);
  }, []);

  if (!source) {
    return <BusyPlaceholder />;
  }

  return (
    <BoxAreas
      panoramaURI={panoramaURI}
      autoDetections={detectedAnnotations}
      areas={areas}
      onArea={handleArea}
      scaleFactor={MAGIC_SCALE_FACTOR}
      movingMarking={movingMarking}
      onMove={handleMove}
      focusPoint={focusPoint}
      onFocused={onFocused}
    >
      {(viewBox) => {
        return (
          <TiledImage
            scaleFactor={MAGIC_SCALE_FACTOR}
            source={source}
            viewCrop={viewBox.crop}
            viewSize={viewBox.size}
            fetchTile={fetchTile}
          />
        );
      }}
    </BoxAreas>
  );
}
