import { getAgeBinForAge } from "render/pages/DashboardPage/lib/projection/age";
import distributionModel from "render/pages/DashboardPage/lib/projection/assets/hba1c_blood_pressure_grip_strength_distribution_model.json";

import { SexBinsType } from "render/pages/DashboardPage/lib/projection/sex";

export type BloodPressureType =
  | "systolic_blood_pressure"
  | "diastolic_blood_pressure";

export function bloodPressureValueToCpv(
  bloodPressureValue: number,
  bloodPressureType: BloodPressureType,
  sex: SexBinsType,
  age: number
) {
  // Get age group for given age
  const ageGroup = getAgeBinForAge(age);

  // Find distribution arrays from data model
  const distributionArrays =
    distributionModel[sex][ageGroup][bloodPressureType];

  // Extract distribution arrays
  const binCenters = distributionArrays["bin_centers"];
  const cdf = distributionArrays["cdf_values"];

  // Compute what bin the given hba1c value belongs to
  const differences = binCenters.map((value) =>
    Math.abs(value - bloodPressureValue)
  );
  const binCenterIdx = differences.indexOf(Math.min(...differences));

  if (binCenterIdx !== -1) {
    // Get the cumulative probability value by indexing the cdf_values array
    const c = cdf[binCenterIdx];

    return c;
  } else {
    // Handle the case where no bin center index was found
    throw new Error("No matching bin center found for the given HbA1c value.");
  }
}

export function getPercentileMean(
  bloodPressureType: BloodPressureType,
  sex: SexBinsType,
  age: number,
  pStart: number,
  pStop: number
) {
  const ageGroup = getAgeBinForAge(age);
  // Find distribution arrays from data model
  const distributionArrays =
    distributionModel[sex][ageGroup][bloodPressureType];

  // Extract distribution arrays
  const binCenters = distributionArrays["bin_centers"];
  const pdf = distributionArrays["pdf_values"];
  const cdf = distributionArrays["cdf_values"];

  // Find indices where the cdf array contains cpv in the same quartile
  const indices = [];
  for (let i = 0; i < cdf.length; i++) {
    if (pStart <= cdf[i] && cdf[i] <= pStop) {
      indices.push(i);
    }
  }

  const sumWeights: number = indices.reduce(
    (sum, index) => sum + pdf[index],
    0
  );
  const meanHba1c: number = indices.reduce(
    (sum, index) => sum + (binCenters[index] * pdf[index]) / sumWeights,
    0
  );

  return meanHba1c;
}
