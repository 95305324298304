import { MotionValue } from "framer-motion";
import { createContext, useContext } from "react";

export interface IProjectionGraphContext {
  X: (value: number) => number;
  Y: (value: number) => number;
  Yflip: (value: number) => number;
  Xnormalize: (value: number) => number;
  Ynormalize: (value: number) => number;
  Xpercent: (value: number) => number;
  Ypercent: (value: number) => number;

  domain: { x: [number, number]; y: [number, number] };
  range: { x: [number, number]; y: [number, number] };

  svgContainer?: SVGSVGElement;

  timelineState: "idle" | "play" | "complete";
  timelineProgress: MotionValue<number>;
  timelineSeek: number;

  debug?: boolean;
}

const identity = (value: number) => value;

export const ProjectionGraphContext = createContext<IProjectionGraphContext>({
  X: identity,
  Y: identity,
  Yflip: identity,
  Xnormalize: identity,
  Ynormalize: identity,
  Xpercent: identity,
  Ypercent: identity,
  domain: { x: [0, 0], y: [0, 0] },
  range: { x: [0, 0], y: [0, 0] },
  timelineState: "idle",
  timelineProgress: new MotionValue(),
  timelineSeek: 0,
  svgContainer: undefined,
  debug: false,
});

export const useProjectionGraphContext = () =>
  useContext(ProjectionGraphContext);
