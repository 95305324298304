import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { resolveHighestSide } from "lib/types/body";
import { useMemo } from "react";
import FractureRiskRange from "render/fragments/metric/range-charts/FractureRiskRange";
import BMIItem from "render/fragments/metric/summary/BMIItem";
import BloodworkItem from "render/fragments/metric/summary/BloodworkItem";
import DiabetesItem from "render/fragments/metric/summary/DiabetesItem";
import LifestyleItems from "render/fragments/metric/summary/LifestyleItems";
import { useBMIScore } from "render/hooks/api/metrics/useBMIScore";
import { useAge } from "render/hooks/patient/useAge";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import SummarySet from "render/pages/DashboardPage/ui/SummarySet";
import MetricResultHeader, {
  MainTitle,
  Subtitle,
  Titles,
  Unit,
} from "render/ui/presentation/MetricResultHeader";
import { Description } from "render/ui/presentation/MetricResultHeader/MetricResultHeader";
import { Typography } from "render/ui/presentation/Typography";
import { getBenchmarkMedianFor } from "../../lib/benchmark";
import GripStrengthBenchmarkResult from "../GripStrengthBenchmarkResult";
import EyePressure from "./components/EyePressure";
import { getEyePressureStatus } from "./components/EyePressure/balance";
import GripStrength from "./components/GripStrength";
import styles from "./styles.module.sass";

interface BodySectionProps {
  patient: Patient;
}

interface GetEyePressureTitlesParams {
  current: {
    left?: Metric<"risk_assessment.eye_pressure.left">;
    right?: Metric<"risk_assessment.eye_pressure.right">;
  };
}

function getEyePressureTitles({ current }: GetEyePressureTitlesParams) {
  const status = getEyePressureStatus({ current });

  if (status === "no-data") {
    return {
      title: "Eye Pressure",
      subtitle: "",
    };
  }

  if (status === "unbalanced") {
    return {
      title: "Unbalanced",
      subtitle: "eye pressure",
    };
  }

  if (status === "high") {
    return {
      title: "High",
      subtitle: "eye pressure",
    };
  }

  if (status === "elevated") {
    return {
      title: "Elevated",
      subtitle: "eye pressure",
    };
  }

  if (status === "low") {
    return {
      title: "Low",
      subtitle: "eye pressure",
    };
  }

  return {
    title: "Balanced",
    subtitle: "eye pressure",
  };
}

export default function BodySection({ patient }: BodySectionProps) {
  const { ui } = useDashboardContext();

  const {
    metrics: { body, bloodwork, risk },
    aggregates: { bmi: BMI, score2 },
    lifestyle,
  } = usePatientData();

  const previousWeight = body.weight?.slice(1);
  const previousHeight = body.height?.slice(1) ?? body.height?.slice(0, 1);

  const previousBMI = useBMIScore({
    weight: previousWeight,
    height: previousHeight,
  });

  const eyePressure = body.eyePressure;

  const eyePressureTitles = getEyePressureTitles({
    current: {
      left: eyePressure.left?.at(0),
      right: eyePressure.right?.at(0),
    },
  });

  const gripStrength = body.gripStrength;

  const gripStrengthRight = gripStrength.right?.[0]?.unit.kilograms;
  const gripStrengthLeft = gripStrength.left?.[0]?.unit.kilograms;

  const higherGripStrengthSide = useMemo(() => {
    return resolveHighestSide(gripStrengthLeft, gripStrengthRight, "right");
  }, [gripStrengthLeft, gripStrengthRight]);

  const patientAge = useAge(patient);

  const gripStrengthPopulationMedian = getBenchmarkMedianFor(
    higherGripStrengthSide,
    patientAge,
    patient.sex
  );

  return (
    <div className={styles.BodySection}>
      <Typography as="h2" variant="display-s">
        Body
      </Typography>

      <hr />

      <div className={styles.assessments}>
        <div>
          <div className={styles.titlesContainer}>
            <MetricResultHeader>
              <Titles>
                <MainTitle>{eyePressureTitles.title}</MainTitle>
                <Subtitle>{eyePressureTitles.subtitle}</Subtitle>
              </Titles>
              <Unit>mmHg</Unit>
            </MetricResultHeader>
          </div>

          <EyePressure eyePressure={eyePressure} />
        </div>

        <div>
          <div className={styles.titlesContainer}>
            <MetricResultHeader>
              <Titles>
                <MainTitle>Strength</MainTitle>
              </Titles>
              <Unit>kg</Unit>
            </MetricResultHeader>
            {gripStrengthLeft && gripStrengthRight && (
              <Description>
                <GripStrengthBenchmarkResult
                  currentValue={
                    higherGripStrengthSide === "left"
                      ? gripStrengthLeft
                      : gripStrengthRight
                  }
                  gripStrengthSide={higherGripStrengthSide}
                  sex={patient.sex}
                  age={patientAge}
                />
              </Description>
            )}
          </div>

          <GripStrength
            gripStrength={gripStrength}
            populationMedian={gripStrengthPopulationMedian}
          />
        </div>

        {!!risk.frax?.length && (
          <div>
            <FractureRiskRange metrics={risk.frax} />
          </div>
        )}

        <SummarySet>
          <button
            type="button"
            onClick={() => {
              ui.set({ layer: Layer.Diabetes });
            }}
          >
            <DiabetesItem
              patient={patient}
              bloodwork={bloodwork}
              body={body}
              bmi={BMI}
            />
          </button>

          <button
            type="button"
            onClick={() => {
              ui.set({ layer: Layer.Bloodwork });
            }}
          >
            <BloodworkItem
              patient={patient}
              bloodwork={bloodwork}
              score2={score2}
            />
          </button>

          <BMIItem patient={patient} bmi={BMI} previousBMI={previousBMI} />
        </SummarySet>

        {lifestyle?.isV1 && (
          <div className={styles.lifestyle}>
            <LifestyleItems lifestyle={lifestyle} />
          </div>
        )}
      </div>
    </div>
  );
}
