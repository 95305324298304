import { SkinBookTracking } from "@cur8/rich-entity";
import FullDate from "render/ui/format/FullDate";
import PaginatedTable from "../PaginatedTable";
import { PaginatedTableProps } from "../PaginatedTable/PaginatedTable";
import SendSMSMenu from "../SendSMSMenu";
import styles from "./styles.module.sass";

type ClearedTableProps = Pick<
  PaginatedTableProps<SkinBookTracking>,
  "data" | "fetchStatus" | "hasMoreData" | "onLoadMore"
> & {
  onDataRefresh: () => void;
};

export default function ClearedTable({
  data,
  fetchStatus,
  hasMoreData,
  onLoadMore,
  onDataRefresh,
}: ClearedTableProps) {
  return (
    <div className={styles.ClearedTable}>
      <PaginatedTable
        data={data}
        fetchStatus={fetchStatus}
        hasMoreData={hasMoreData}
        onLoadMore={onLoadMore}
        renderHeader={() => (
          <tr>
            <th>Member</th>
            <th>Member ID</th>
            <th>Phone no</th>
            <th>Sent at</th>
            <th>Action</th>
          </tr>
        )}
        renderRow={(item) => (
          <tr
            key={`${item.scanId}-${item.patientId}-${item.audit.created?.timeStamp}`}
          >
            <td>{item.patientDisplayName}</td>
            <td>{item.patientId}</td>
            <td>{item.patientPhoneNumber}</td>
            <td>
              {item.nothingSMSNotificationScheduledTimestamp ? (
                <FullDate
                  date={item.nothingSMSNotificationScheduledTimestamp}
                />
              ) : (
                "N/A"
              )}
            </td>
            <td>
              {!item.nothingSMSNotificationScheduledTimestamp && (
                <SendSMSMenu item={item} onActionCompleted={onDataRefresh} />
              )}
            </td>
          </tr>
        )}
      />
    </div>
  );
}
