import { distinct } from "lib/filter";
import { Lesion, byLinkDate, isPreferredLink } from "lib/lesion";
import { useMemo } from "react";
import StudySymbol from "render/ui/symbol/StudySymbol";
import DetailsGraph from "./components/DetailsGraph";
import LesionHeroImage from "./components/LesionHeroImage";
import styles from "./styles.module.sass";

interface LesionMacroClassificationProps {
  selected: Lesion | undefined;
}

export default function LesionMacroClassification({
  selected,
}: LesionMacroClassificationProps) {
  const evolutions = useMemo(() => {
    return selected?.links
      .filter(isPreferredLink)
      .toSorted(byLinkDate("desc"))
      .filter(distinct((link) => link.scan));
  }, [selected]);

  const latest = evolutions?.at(0)?.annotation;

  return (
    <div className={styles.LesionMacroClassification}>
      <div className={styles.hero}>
        <div className={styles.image}>
          {latest && <LesionHeroImage annotation={latest} />}
        </div>
      </div>
      <div className={styles.details}>
        <DetailsGraph
          bounds={{ min: 0, max: 1 }}
          header={
            <>
              Asymmetry <StudySymbol />
            </>
          }
          labels={{ from: "Symmetric", to: "Asymmetric" }}
          value={latest?.classification.scoreFeaturesAsymmetry ?? 0}
        />

        <DetailsGraph
          bounds={{ min: 0, max: 1 }}
          header={
            <>
              Borders <StudySymbol />
            </>
          }
          labels={{ from: "Even", to: "Uneven" }}
          value={latest?.classification.scoreFeaturesBorders ?? 0}
        />

        <DetailsGraph
          bounds={{ min: 0, max: 1 }}
          header={
            <>
              Colour <StudySymbol />
            </>
          }
          labels={{ from: "Uniform", to: "Varying" }}
          value={latest?.classification.scoreFeaturesColor ?? 0}
        />

        <DetailsGraph
          bounds={{ min: 0, max: 10 }}
          header={
            <>
              Diameter <StudySymbol />
            </>
          }
          labels={{ from: "1mm", to: "10mm+" }}
          value={latest?.classification.size ?? 0}
        />
      </div>
    </div>
  );
}
