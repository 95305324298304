import { BodyAreas } from "lib/smpl";
import { toCenter } from "lib/three";
import { useMemo } from "react";
import HeartRateFloat from "render/fragments/avatar/overlay/HeartRate";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useScreenAnchorsForObjects } from "render/hooks/three/useScreenAnchors";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import Overlay from "../../components/Overlay";
import PerspectiveOverlay from "../../components/PerspectiveOverlay";
import HeartImage from "./assets/heart.png";
import styles from "./styles.module.sass";

const ACTIVE = new Set([
  Layer.Heart,
  Layer.Cardio,
  Layer.Circulation,
  Layer.Cholesterol,
]);

interface HeartLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export default function HeartLayer({
  layer,
  areas,
  projector,
}: HeartLayerProps) {
  const { ui } = useDashboardContext();

  const {
    metrics: { cardio },
  } = usePatientData();

  const positions = useMemo(() => {
    const heart = toCenter(areas.heart);
    heart.y = 0;

    const heartRate = heart.clone();
    heartRate.x -= 33;
    heartRate.z += 26;

    const respRate = heart.clone();
    respRate.x -= 35;
    respRate.z -= 30;

    return {
      heart,
      heartRate,
      respRate,
    };
  }, [areas]);

  const active = ACTIVE.has(layer);

  const anchors = useScreenAnchorsForObjects(projector, positions, active);

  return (
    <div className={styles.HeartLayer} data-layer={ui.layer}>
      {anchors && (
        <>
          {cardio.heartRate && (
            <Overlay pos={anchors.heartRate}>
              <div className={styles.heartRate}>
                <HeartRateFloat heartRate={cardio.heartRate} width={200} />
              </div>
            </Overlay>
          )}

          <PerspectiveOverlay perspective={350} pos={anchors.heart}>
            <div className={styles.heart}>
              <button
                onClick={() => {
                  ui.set({ layer: Layer.Heart });
                }}
              >
                <img src={HeartImage} alt="Your heart" />
              </button>
            </div>
          </PerspectiveOverlay>
        </>
      )}
    </div>
  );
}
