import { APITypesV2 } from "@cur8/api-client";
import { useCallback, useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useEntries } from "render/hooks/store/refs";
import { useReporting } from "render/hooks/useReporting";
import { BookingToken } from "../types";

const toBookingToken = (item: APITypesV2.BookingTokenV2): BookingToken => ({
  ...item,
  id: item.bookingTokenId!,
});

export function usePatientBookingTokenQuery(
  options: APITypesV2.BookingTokenQueryPatientsParams,
  namespace: string
) {
  const apiClient = useAPIClient().bookingV2;
  const { logError } = useReporting();

  const patientBookingTokenStore = useStore().patientBookingToken;
  const { getEntries, setEntries } = useEntries(
    patientBookingTokenStore,
    namespace
  );

  // TODO: does not support paginated response (will fail on over 50 items)
  const fetch = useCallback(async () => {
    return apiClient
      .getBookingTokensForPatient(options)
      .result.then((result) => {
        const items = result.items
          .filter((item) => !!item.bookingTokenId)
          .map(toBookingToken);

        setEntries(items);
        return items;
      })
      .catch(logError);
  }, [apiClient, options, setEntries, logError]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const create = useCallback(async () => {
    return apiClient
      .createBookingTokenForPatient(options.patientId)
      .result.then(() => fetch())
      .catch(logError);
  }, [apiClient, fetch, logError, options]);

  return useMemo(() => {
    return {
      entries: getEntries(),
      fetch,
      create,
    };
  }, [getEntries, fetch, create]);
}
