import { Annotation } from "@cur8/rich-entity";
import { PatientBlobURI } from "@cur8/uri";
import { OutputImageSize, SourceImageCrop } from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { useMemo } from "react";
import PanoramaImage from "./components/PanoramaImage";
import PatientBlobImage from "./components/PatientBlobImage";

function pickImageURI(annotation: Annotation) {
  if (annotation.applicationSpecificTarget) {
    const blobURI = PatientBlobURI.parse(annotation.applicationSpecificTarget);

    if (blobURI) {
      return blobURI;
    }

    const panoramaURI = PanoramaImageURI.parse(
      annotation.applicationSpecificTarget
    );

    if (panoramaURI) {
      return panoramaURI;
    }
  }
}

const SIZE = {
  w: 1024,
  h: 1024,
  fit: "contain" as const,
};

interface AnnotationImageProps {
  annotation: Annotation;
  crop?: SourceImageCrop;
  size?: OutputImageSize;
}

export default function AnnotationImage({
  annotation,
  crop,
  size,
}: AnnotationImageProps) {
  const imageURI = useMemo(() => {
    return pickImageURI(annotation);
  }, [annotation]);

  if (imageURI instanceof PanoramaImageURI) {
    return <PanoramaImage uri={imageURI} crop={crop} size={size} />;
  }

  if (imageURI instanceof PatientBlobURI) {
    return <PatientBlobImage uri={imageURI} size={SIZE} />;
  }

  return null;
}
