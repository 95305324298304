import { AsyncHandle } from "render/hooks/useAsyncHandle";
import AsyncButton from "../AsyncButton";

interface ActionButtonProps
  extends Omit<React.ButtonHTMLAttributes<any>, "type" | "onClick"> {
  children: React.ReactNode;
  handle: AsyncHandle;
}

export default function ActionButton({
  children,
  handle,
  ...props
}: ActionButtonProps) {
  return (
    <AsyncButton
      {...props}
      type="button"
      handle={handle}
      onClick={handle.callback}
    >
      {children}
    </AsyncButton>
  );
}
