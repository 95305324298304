import { easeOutCubic } from "lib/ease";
import { Lesion } from "lib/lesion";
import { ReactNode } from "react";
import LesionId from "render/ui/format/LesionId";
import RampUpNumber from "render/ui/format/RampUpNumber";
import { Typography } from "render/ui/presentation/Typography";
import StudySymbol from "render/ui/symbol/StudySymbol";
import styles from "./styles.module.sass";

function Detail({
  label,
  value,
  unit,
  precision = 0,
}: {
  label: ReactNode;
  value: number | undefined | null;
  unit?: string;
  precision?: number;
}) {
  return (
    <div className={styles.Detail}>
      <div className={styles.label}>
        <Typography variant="label-m">{label}</Typography>
      </div>
      <div className={styles.data}>
        <Typography as="span" variant="numeral-m" className={styles.value}>
          {value == null && <>--</>}
          {value != null && (
            <RampUpNumber
              value={value}
              duration={4}
              precision={precision}
              ease={easeOutCubic}
            />
          )}
        </Typography>
        <Typography as="span" variant="body-m" className={styles.unit}>
          {unit}
        </Typography>
      </div>
    </div>
  );
}

interface LesionMacroDetailsProps {
  lesion: Lesion | undefined;
}

export default function LesionMacroDetails({
  lesion,
}: LesionMacroDetailsProps) {
  const anno = lesion?.links
    .map((link) => link.annotation)
    .find((anno) => !!anno.classification);
  const classification = anno?.classification;

  const diameter = classification?.size;
  const area = {
    mm2: diameter ? Math.PI * (diameter / 2) ** 2 : undefined,
  };

  return (
    <div className={styles.LesionMacroDetails}>
      <div className={styles.header}>
        <Typography variant="title-s">Marking ID</Typography>
      </div>
      <div className={styles.content}>
        <div className={styles.name}>
          {lesion ? <LesionId lesion={lesion} /> : "--"}
        </div>
        <div className={styles.metrics}>
          <Detail
            label={
              <>
                Diameter <StudySymbol />
              </>
            }
            value={diameter}
            unit="mm"
            precision={1}
          />
          <Detail
            label={
              <>
                Surface Area <StudySymbol />
              </>
            }
            value={area?.mm2}
            unit="mm2"
            precision={1}
          />
        </div>
      </div>
    </div>
  );
}
