import { OutputImageSize, SourceImageCrop } from "lib/api/types";
import { ThermalImageURI } from "lib/api/thermal-uri";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "./useBlobImage";
import { normalizeSize } from "lib/api/image";
import { useReporting } from "./useReporting";

export function useThermalImage(
  thermalURI: ThermalImageURI,
  size?: OutputImageSize,
  crop?: SourceImageCrop
) {
  const { handleError } = useReporting();
  const api = useAPIClient().transcode;
  const [blob, setBlob] = useState<Blob>();

  useEffect(() => {
    const effectiveSize = normalizeSize(size);
    const flirCamera = `flir_${thermalURI.cameraName}`;
    const request = api.fetchFrame(
      {
        deviceId: thermalURI.deviceId,
        recordingId: thermalURI.recordingId,
        node: flirCamera,
        publisher: "data",
        frameId: thermalURI.frameIndex,
      },
      crop,
      effectiveSize
    );

    request.result
      .then(async (res) => {
        setBlob(res.blob);
      })
      .catch(handleError);

    return () => {
      setBlob(undefined);
      request.abandon();
    };
  }, [api, crop, size, handleError, thermalURI]);

  return useBlobImage(blob);
}
