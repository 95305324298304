import { Metric } from "lib/metric";
import { calculateABI } from "lib/scores/abi";
import { BodySide } from "lib/types/body";
import { useMemo } from "react";

export type ABIScore = {
  score: number;
};

type ABIScoreMetrics = {
  brachialPressure: {
    left: Metric<"cardio.brachial_pressure.left">[] | undefined;
    right: Metric<"cardio.brachial_pressure.right">[] | undefined;
  };
  anklePressure: {
    left: Metric<"cardio.ankle_pressure.left">[] | undefined;
    right: Metric<"cardio.ankle_pressure.right">[] | undefined;
  };
};

export function useABIScore(
  side: BodySide,
  { brachialPressure: brachial, anklePressure: ankle }: ABIScoreMetrics
) {
  const ankleSide = ankle[side];

  return useMemo(() => {
    if (!brachial.left || !brachial.right || !ankleSide) {
      return;
    }

    const score = calculateABI(
      { left: brachial.left, right: brachial.right },
      ankleSide
    );

    return {
      score: score ?? NaN,
    };
  }, [brachial, ankleSide]);
}
