import { ImmutableScan } from "@cur8/rich-entity";
import { useHistory, useNav } from "@pomle/react-router-paths";
import { ValidScanTypes, parseScanType } from "lib/scanType";
import { isRecordingURI } from "lib/uri/guard";
import { paths } from "render/routes/paths";
import { query as routerQuery } from "render/routes/querys";
import FullDate from "render/ui/format/FullDate";
import Table from "render/ui/layouts/Table";
import ScanBullet from "../ScanBullet";
import ScanLinks from "../ScanLinks";
import ScanReviewed from "../ScanReviewed/ScanReviewed";
import ScanState from "../ScanState";
import ScanType from "../ScanType";
import { READY_STATE } from "../state";

interface PatientScanTableProps {
  scans: ImmutableScan[];
}

export default function PatientScanTable({ scans }: PatientScanTableProps) {
  const nav = {
    cardio: useNav(paths.patient.scans.cardio),
    tissue: {
      regular: useNav(paths.patient.scans.tissue.regular),
      mind: useNav(paths.patient.scans.tissue.mind),
      pad: useNav(paths.patient.scans.tissue.pad),
    },
    atlas: useNav(paths.patient.recording.atlas, routerQuery.atlas),
  };

  const history = useHistory();
  function goto(url: string | undefined, ready: boolean) {
    if (!url || !ready) {
      return;
    }
    history.push(url);
  }

  const getScanLink = (scan: ImmutableScan) => {
    const patientId = scan.patientId;
    const scanId = scan.id;
    const scanVersion = scan.version;

    const scanType = parseScanType(scan.scanType);
    if (!scanType) {
      return null;
    }

    if (scanType.type === ValidScanTypes.Cardio) {
      return {
        type: "Cardio",
        link: nav.cardio.to({ patientId, scanId, scanVersion }),
      };
    }

    if (scanType.type === ValidScanTypes.Skin) {
      const recordingURI = scan.sourceUris.filter(isRecordingURI).at(0);
      if (recordingURI) {
        const { recordingId, deviceId } = recordingURI;

        return {
          type: "Atlas",
          link: nav.atlas.to(
            { patientId, recordingId, deviceId },
            {
              scanId: [scanId],
              scanVersion: [scanVersion],
            }
          ),
        };
      }
    }

    if (scanType.type === ValidScanTypes.Tissue) {
      let tissueLink = nav.tissue.regular;
      let label = "Tissue";
      if (scanType.subtype === "mind") {
        tissueLink = nav.tissue.mind;
        label = "Tissue/MIND";
      } else if (scanType.subtype === "pad") {
        tissueLink = nav.tissue.pad;
        label = "Tissue/PAD";
      }
      return {
        type: label,
        link: tissueLink.to({ patientId, scanId, scanVersion }),
      };
    }

    return null;
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <svg width="12" height="12" viewBox="0 0 12 12">
              <circle cx="6" cy="6" r="2.5" stroke="#EFF0F8" />
            </svg>
          </th>
          <th>Medical tool</th>
          <th>State</th>
          <th>Action</th>
          <th>Date</th>
          <th>Processed</th>
        </tr>
      </thead>

      <tbody>
        {scans.map((scan) => {
          const isReady = scan.state === READY_STATE;
          const scanLink = getScanLink(scan);
          return (
            <tr
              key={scan.id}
              onClick={(ev) => goto(scanLink?.link, isReady)}
              style={{ cursor: isReady && scanLink ? "pointer" : "default" }}
            >
              <td>
                <ScanBullet state={scan.state} />
              </td>
              <td>
                <ScanType scanType={scan.scanType} />
              </td>
              <td>
                {scan.journalNoteId ? (
                  <ScanReviewed />
                ) : (
                  <ScanState state={scan.state} />
                )}
              </td>
              <td>{isReady && <ScanLinks scan={scan} />}</td>
              <td>
                <FullDate date={scan.timestamp} />
              </td>
              <td>
                <FullDate date={scan.versionCreatedAt} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
