import { Patient, Slot } from "@cur8/rich-entity";
import { useCallback } from "react";
import PatientName from "render/fragments/patient/PatientName";
import { useConfirmationModal } from "render/ui/layouts/ConfirmationModal";

export function useDoubleBookingConfirmation() {
  const { emitConfirmationDialog } = useConfirmationModal();

  const emitDoubleBookingConfirmation = useCallback(
    (patient: Patient, upcomingAppointment: Slot, onConfirm: () => void) => {
      emitConfirmationDialog({
        title: (
          <h2>
            Double-book <PatientName patient={patient} />
          </h2>
        ),
        body: (
          <>
            <p>
              This member already has an upcoming appointment on{" "}
              <strong>
                {upcomingAppointment.startTime.toFormat("dd LLLL yyyy")} at{" "}
                {upcomingAppointment.startTime.toFormat("HH:mm")}
              </strong>
              .
            </p>
            <p>Do you want to schedule another one?</p>
          </>
        ),
        confirmButtonLabel: "Yes",
        cancelButtonLabel: "No",
        onConfirm,
      });
    },
    [emitConfirmationDialog]
  );

  return {
    emitDoubleBookingConfirmation,
  };
}
