import { useMemo } from "react";
import { useUser } from "./useUser";

type Config = {
  booking: boolean;
  calendar: boolean;
  skinCRM: boolean;
};

const atriumConfig: Config = {
  booking: false,
  calendar: false,
  skinCRM: true,
};
const nekoConfig: Config = { booking: true, calendar: true, skinCRM: true };

const tennatIdMap = new Map([
  //********************Neko ids**********************
  ["839bd755-86cb-4026-b14e-56aae44a0acf", nekoConfig],
  //********************Atrium ids********************
  ["d2513ca2-d4b5-464a-adc1-f0a4a02b6b7e", atriumConfig],
  ["62631196-036b-4324-9ea0-b90233955588", atriumConfig],
]);

type Role = "BookingToken.Read" | "BookingToken.ReadWrite";

export function useRoleBasedAccess() {
  const auth = useUser();
  if (!auth) {
    throw Error("useRoleBasedAccess called before user was authenticated");
  }

  const clinicFeatures = tennatIdMap.get(auth.tenantId);
  if (clinicFeatures == null) {
    throw Error(`feature config for tid: [${auth.tenantId}] does not exist`);
  }

  const userRoles = useMemo(() => {
    const token = auth.accessToken;
    const tokenParsed = JSON.parse(window.atob(token.split(".")[1]));
    return new Set(tokenParsed.roles as Role[]);
  }, [auth]);

  const booking = useMemo(() => {
    if (!clinicFeatures.calendar) {
      return false;
    }
    return (
      userRoles.has("BookingToken.Read") ||
      userRoles.has("BookingToken.ReadWrite")
    );
  }, [userRoles, clinicFeatures]);

  const result = useMemo(
    () => ({
      calendar: clinicFeatures.calendar,
      booking,
      skinCRM: clinicFeatures.skinCRM,
    }),
    [booking, clinicFeatures]
  );

  return { feature: result };
}
