import { Side } from "lib/api/types";
import { Transform } from "lib/avatar";
import { LesionAnnotation, extractSide, isLesionAnnotation } from "lib/lesion";
import { BodyAreas } from "lib/smpl";
import { shuffle } from "lib/sort";
import { Duration } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { ScreenProjector } from "render/hooks/three/useProject";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { getSide } from "render/pages/DashboardPage/context/DashboardContext/hooks/usePodium";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import * as THREE from "three";
import Overlay from "../../components/Overlay";
import LesionMarker from "./components/LesionMarker";
import styles from "./styles.module.sass";

const SPAWN_RATE = Duration.fromObject({ seconds: 0.1 });
const SPAWN_COUNT = 1;
const KILL_AGE = 10;
const BURY_AGE = KILL_AGE + 4;

type Spot = { annotation: LesionAnnotation; pos: THREE.Vector3; side: Side };

type LesionElement = {
  id: number;
  spawnCycle: number;
  position: THREE.Vector3;
  spot: Spot;
};

type Board = {
  cycle: number;
  counter: number;
  elements: LesionElement[];
  mapping: number[];
};

interface DetectedLesionsLayerProps {
  layer: Layer;
  areas: BodyAreas;
  projector: ScreenProjector;
}

export default function DetectedLesionsLayer({
  layer,
  projector,
}: DetectedLesionsLayerProps) {
  const active = layer === Layer.Skin;

  const { podium } = useDashboardContext();

  const {
    scans,
    skin: { lesions },
  } = usePatientData();

  const latestSkinScan = scans?.skin.at(0);

  const spots = useMemo(() => {
    if (!lesions) {
      return;
    }

    const spots: Record<Side, Spot[]> = {
      front: [],
      back: [],
    };

    const relevantDetections: LesionAnnotation[] = [];
    for (const lesion of lesions) {
      for (const link of lesion.links) {
        if (link.scan === latestSkinScan) {
          const anno = link.annotation;
          if (isLesionAnnotation(anno)) {
            relevantDetections.push(anno);
          }
        }
      }
    }

    for (const annotation of relevantDetections) {
      const xyz = annotation.classification?.xyz;
      const side = extractSide(annotation);
      if (xyz && side) {
        const pos = new THREE.Vector3(...xyz);
        pos.applyMatrix4(Transform[side]);

        spots[side].push({
          side,
          annotation,
          pos,
        });
      }
    }

    shuffle(spots.front);
    shuffle(spots.back);

    return spots;
  }, [lesions, latestSkinScan]);

  const [board, setBoard] = useState<Board>({
    cycle: 0,
    counter: 0,
    mapping: [],
    elements: [],
  });

  const pickItem = useMemo(() => {
    let count = 0;

    return function pick(side: Side) {
      if (!spots) {
        return;
      }
      const len = spots[side].length;
      return spots[side].at(count++ % len);
    };
  }, [spots]);

  useEffect(() => {
    if (!active) {
      return;
    }

    const spawnElements = (count: number) => {
      const side = getSide(podium);
      setBoard((board) => {
        const cycle = board.cycle + 1;

        const elements = board.elements.filter((element) => {
          return cycle - element.spawnCycle < BURY_AGE;
        });

        let mapping = board.mapping;

        let counter = board.counter;

        for (let i = 0; i < count; i++) {
          const item = pickItem(side);
          if (item) {
            const [position] = projector.getOffsets([item.pos]);

            elements.push({
              id: counter++,
              spot: item,
              spawnCycle: cycle,
              position,
            });
          }
        }

        return {
          cycle,
          counter,
          mapping,
          elements,
        };
      });
    };

    const timer = setInterval(
      spawnElements,
      SPAWN_RATE.as("milliseconds"),
      SPAWN_COUNT
    );

    return () => {
      clearInterval(timer);
    };
  }, [podium, active, projector, pickItem]);

  const { cycle } = board;

  return (
    <div className={styles.DetectedLesionsLayer} data-active={active}>
      {board.elements.map((element) => {
        const visible = cycle - element.spawnCycle < KILL_AGE;

        return (
          <Overlay pos={element.position} key={element.id}>
            <LesionMarker active={active && visible} />
          </Overlay>
        );
      })}
    </div>
  );
}
