import { useMemo } from "react";
import { Annotation } from "@cur8/rich-entity";
import { PatientBlobURI } from "@cur8/uri";
import PatientBlobImage from "./components/PatientBlobImage";
import { sortBy } from "lib/sort";
import styles from "./styles.module.sass";

const byCreated = sortBy<Annotation>((anno) => anno.createdAt.valueOf());

function pickImageURI(annotations: Annotation[]) {
  const sortedAnnotations = [...annotations].sort(byCreated("desc"));
  for (const annotation of sortedAnnotations) {
    if (annotation.applicationSpecificTarget) {
      const blobURI = PatientBlobURI.parse(
        annotation.applicationSpecificTarget
      );
      if (blobURI) {
        return blobURI;
      }
    }
  }
}

const IMAGE_SIZE = {
  w: 1024,
  h: 1024,
  fit: "contain" as const,
};

interface RelatedImageProps {
  annotations: Annotation[];
}

export default function RelatedImage({ annotations }: RelatedImageProps) {
  const imageURI = useMemo(() => {
    return pickImageURI(annotations);
  }, [annotations]);
  if (imageURI instanceof PatientBlobURI) {
    return (
      <div className={styles.RelatedImage}>
        <PatientBlobImage uri={imageURI} size={IMAGE_SIZE} />
      </div>
    );
  }

  return null;
}
