import { useContext } from "react";
import { Context } from "./RangeContext";

export function useRangeContext() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useRangeContext without RangeContext");
  }
  return context;
}
