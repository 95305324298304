import { MetricName } from "@cur8/measurements";
import { Metric } from "lib/metric";
import { useEffect, useMemo, useState } from "react";
import { useMetricAPI } from "./useMetricAPI";

function metricsEqual(listA: Metric[], listB: Metric[]) {
  return listA.every((metricA, index) => {
    const metricB = listB.at(index);
    if (metricA.measurement.id === metricB?.measurement.id) {
      return true;
    }

    return false;
  });
}

export function useMetric<M extends MetricName>(
  metricName: M,
  patientId: string
) {
  return useMetrics(metricName, patientId, 1)?.at(0);
}

export function useMetrics<M extends MetricName>(
  metricName: M,
  patientId: string,
  length: number = 1
): Metric<M>[] | undefined {
  const { fetchMetrics } = useMetricAPI();

  const [result, setResult] = useState<Metric<M>[]>();

  const update = useMemo(() => {
    let id = 0;

    let last: Metric<M>[] | undefined;

    async function tryUpdate() {
      const flight = ++id;
      const metrics = await fetchMetrics(patientId, metricName, length);
      if (flight !== id) {
        console.debug("Ignoring mismatch poll id", metricName, id, flight);
        return;
      }

      if (last && metricsEqual(metrics, last)) {
        console.debug("Ignoring equal metrics", metricName, metrics, last);
        return;
      }

      console.debug("Setting metrics", metricName, metrics);
      setResult(metrics);
      last = metrics;
    }

    return tryUpdate;
  }, [fetchMetrics, patientId, metricName, length]);

  useEffect(() => {
    let active = true;

    async function attempt() {
      if (!active) {
        return;
      }

      await update();

      const delay = 45000 + Math.random() * 15000;

      setTimeout(attempt, delay);
    }

    attempt();

    return () => {
      active = false;
      setResult(undefined);
    };
  }, [update]);

  return result;
}
