import { classNames } from "@pomle/classnames";
import { IconProps } from ".";
import styles from "./styles.module.sass";

interface DownIconProps extends IconProps {
  rotation?: number;
}

export default function DownIcon({
  active,
  color,
  rotation = 0,
}: DownIconProps) {
  return (
    <svg
      className={classNames(styles.Common, styles.ArrowIcon)}
      data-active={active ? "yes" : "no"}
      data-color={color}
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      style={{ transform: `rotate(${rotation}deg)` }}
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20" r="19" />
      <path d="M20.86 11a1 1 0 1 0-2 0h2Zm-1.7 18.7a1 1 0 0 0 1.41 0l6.37-6.36a1 1 0 0 0-1.42-1.41l-5.66 5.66-5.65-5.66a1 1 0 0 0-1.42 1.41l6.37 6.37Zm-.3-18.7v18h2V11h-2Z" />
    </svg>
  );
}
