import { APITypesV1 } from "@cur8/api-client";
import { BoundingBoxAnnotation, fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";
import { ArtefactHistoryEntry, createArtefactHistory } from "../history";

export function useArtefactHistory(sourceAnnotation: BoundingBoxAnnotation) {
  const { physicalArtefactId, patientId } = sourceAnnotation;

  const api = useAPIClient().annotation;

  const { handleError } = useReporting();

  const [result, setResult] = useState<ArtefactHistoryEntry[]>();

  const fetch = useCallback(() => {
    return api
      .queryAnnotations({
        patientId,
        physicalArtefactId,
        pageSize: 100,
        order: APITypesV1.SortOrder.Desc,
      })
      .result.then((result) => {
        const annotations = result.items.map(fromAPI.toAnnotation);
        const history = createArtefactHistory(annotations);
        setResult(history);
      })
      .catch(handleError);
  }, [api, handleError, patientId, physicalArtefactId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { entries: result, fetch };
}
