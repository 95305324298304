import styles from "./styles.module.sass";

export default function DropdownItem({
  children,
  ...props
}: React.ButtonHTMLAttributes<any>) {
  return (
    <button
      className={styles.DropdownItem}
      type="button"
      {...props}
      data-clickabe={!!props.onClickCapture || !!props.onClick}
    >
      {children}
    </button>
  );
}
