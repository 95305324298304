import { useCallback, useState } from "react";
import { CardioSignals } from "../../hooks/useCardioSignals";
import { ECG_COLOR, LEFT_COLOR, RIGHT_COLOR } from "../shared/constants";
import { Range } from "../shared/DataWindow/DataWindow";
import { JSXResult } from "../shared/fragments";
import GraphWithRuler from "../shared/GraphWithRuler";
import { RulerData } from "../shared/GraphWithRuler/GraphWithRuler";
interface HandAsymmetryProps {
  signals: CardioSignals | undefined;
  range: Range;
  setRange: React.Dispatch<React.SetStateAction<Range>>;
}

export default function HandAsymmetry({
  signals,
  range,
  setRange,
}: HandAsymmetryProps) {
  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure delay")
  );

  const handleMeasurement = useCallback((rd: RulerData | void) => {
    if (!rd) {
      return;
    }
    setResultElement(JSXResult(`Delay: ${rd.distanceMs} ms`));
  }, []);

  return signals ? (
    <div>
      <GraphWithRuler
        title="Pulse Asymmetry (hands)"
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.ecg,
            color: ECG_COLOR,
          },
          {
            label: "Left",
            signal: signals.piezo_hand_left,
            color: LEFT_COLOR,
          },
          {
            label: "Right",
            signal: signals.piezo_hand_right,
            color: RIGHT_COLOR,
          },
        ]}
        diffData={[
          {
            label: "Left Acceleration",
            signal: signals.piezo_hand_left_acc,
            color: LEFT_COLOR,
          },
          {
            label: "Right Acceleration",
            signal: signals.piezo_hand_right_acc,
            color: RIGHT_COLOR,
          },
        ]}
        onMeasurement={handleMeasurement}
        range={range}
        setRange={setRange}
      />
      {resultElement}
    </div>
  ) : null;
}
