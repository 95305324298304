import { ColorMap } from "lib/webgl/colorizer";
import { useEffect, useState } from "react";
import { useColorizer } from "./useColorizer";

export function useGradientMap(
  colorMap: ColorMap,
  image?: HTMLImageElement,
  transparencyCutoff?: number
) {
  const colorizer = useColorizer();

  const [output, setOutput] = useState<HTMLImageElement>();

  useEffect(() => {
    if (!image || !colorizer) {
      return;
    }

    image.decode().then(() => {
      colorizer.draw(image, colorMap, transparencyCutoff);
      const output = new Image();
      output.src = colorizer.canvas.toDataURL("image/png");
      setOutput(output);
    });
  }, [colorizer, colorMap, image, transparencyCutoff]);

  return output;
}
