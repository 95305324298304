import { ImmutableScan } from "@cur8/rich-entity";
import { useHeartRate } from "./useHeartRate";
import { useNormalizedSignal } from "./useNormalizedSignal";
import { useSirad } from "./useSirad";

export function useCardioSignals(scan: ImmutableScan | undefined) {
  const ecg = useNormalizedSignal(scan, "bioamp_ecg");
  const ssn = useNormalizedSignal(scan, "ssn_displacement");
  const ssn_acc = useNormalizedSignal(scan, "ssn_acceleration");

  const sirad = useSirad(scan);

  const piezo_foot_left = useNormalizedSignal(scan, "piezo_foot_left");
  const piezo_foot_right = useNormalizedSignal(scan, "piezo_foot_right");
  const piezo_foot_left_acc = useNormalizedSignal(
    scan,
    "piezo_foot_left_acceleration"
  );
  const piezo_foot_right_acc = useNormalizedSignal(
    scan,
    "piezo_foot_right_acceleration"
  );

  const piezo_hand_left = useNormalizedSignal(scan, "piezo_hand_left");
  const piezo_hand_right = useNormalizedSignal(scan, "piezo_hand_right");
  const piezo_hand_left_acc = useNormalizedSignal(
    scan,
    "piezo_hand_left_acceleration"
  );
  const piezo_hand_right_acc = useNormalizedSignal(
    scan,
    "piezo_hand_right_acceleration"
  );

  const heart_rate = useHeartRate(scan);

  return {
    ecg,
    ssn,
    ssn_acc,
    piezo_foot_left,
    piezo_foot_right,
    piezo_foot_left_acc,
    piezo_foot_right_acc,
    piezo_hand_left,
    piezo_hand_right,
    piezo_hand_left_acc,
    piezo_hand_right_acc,
    heart_rate,
    sirad,
  };
}

export type CardioSignals = ReturnType<typeof useCardioSignals>;
