import PatientName from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import PopupDialog from "render/ui/layouts/PopupDialog";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { usePatientUpcomingVisits } from "../../hooks/usePatientUpcomingVisits";
import styles from "../BookPatientButton/styles.module.sass";

interface Props {
  patientId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export default function NoActiveBookingTokenPopup({
  patientId,
  onClose,
}: Props) {
  const patient = usePatient(patientId);
  const visits = usePatientUpcomingVisits(patientId);

  return (
    <PopupDialog>
      <div className={styles.CreateBookingTokenPopup}>
        <div className={styles.content}>
          <header>
            <h2>No active booking token</h2>
          </header>
          <section className={styles.body}>
            {visits?.data && (
              <>
                {visits.data.length > 0 && (
                  <>
                    <p>
                      {patient ? <PatientName patient={patient} /> : patientId}
                      &nbsp; has &nbsp;
                      {visits.data.length} upcoming appointment(s), cancel it
                      first, so that the corresponding booking token becomes
                      active and eligible for refund
                    </p>
                  </>
                )}
                {visits.data.length === 0 && (
                  <>
                    <p>
                      There are no active booking tokens or upcoming
                      appointments for this member.
                    </p>
                  </>
                )}
              </>
            )}
          </section>
          <footer className={styles.footer}>
            <HoverTextButton type="button" onClick={onClose}>
              Close
            </HoverTextButton>
          </footer>
        </div>
      </div>
    </PopupDialog>
  );
}
