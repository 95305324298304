import { silenceAbort } from "lib/error";
import { createPoints, parsePointCloud } from "lib/meshuga/pointcloud";
import { useEffect, useState } from "react";
import * as THREE from "three";

export function usePCD(url: string) {
  const [result, setResult] = useState<AsyncResult<THREE.Points>>();

  useEffect(() => {
    const abort = new AbortController();

    fetch(url, {
      signal: abort.signal,
    })
      .then((response) => response.arrayBuffer())
      .then(parsePointCloud)
      .then(createPoints)
      .then((data) => {
        setResult({ data });
      })
      .catch(silenceAbort);

    return () => {
      abort.abort();
      setResult(undefined);
    };
  }, [url]);

  return result;
}
