import { BoundingBoxAnnotation, Box } from "@cur8/rich-entity";
import { matchesPanorama } from "lib/api/resolvers/annotation";
import { Side, TranscoderImageResponse } from "lib/api/types";
import { PanoramaImageURI } from "lib/api/uri";
import { Point } from "lib/math";
import { panoramaCoords } from "lib/panorama-coords";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useHomographies } from "render/hooks/useHomographies";
import { usePanoramaFetch } from "render/pages/AtlasPage/components/ScanExplorer/components/BoundingBoxAnnotator/components/ImagePresentationLayer/hooks/usePanoramaFetch";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import PanView from "render/ui/presentation/PanView";
import { PanViewConfig } from "render/ui/presentation/PanView/PanView";
import BoundaryBoxSVG from "./assets/BoundaryBox.svg";
import LodImageViewer from "./components/LodImageViewer";
import styles from "./styles.module.sass";
import { BoundaryBoxLocation } from "./types";

const PAN_VIEW_CONFIG: Record<Side, PanViewConfig> = {
  front: {
    bleed: 1,
    zoomScale: 2,
    focusPoint: new Point(0.5, 0.4),
  },
  back: {
    bleed: 0.35,
    zoomScale: 5,
    focusPoint: new Point(0.5, 0.8),
  },
};

interface PanoramaOverlayProps {
  active: boolean;
  side: Side;
  panoramaURI: PanoramaImageURI | undefined;
  render?: boolean;
}

export default function PanoramaOverlay({
  active,
  side,
  panoramaURI,
  render = true,
}: PanoramaOverlayProps) {
  const imageContainer = useRef<HTMLImageElement>(null);

  const panViewConfig = useMemo(() => PAN_VIEW_CONFIG[side], [side]);

  const { ui } = useDashboardContext();

  const { skin, scans } = usePatientData();

  const latestSkinScan = scans?.skin.at(0);
  const lesions = skin.lesions;

  const autoDetections = useMemo(() => {
    if (!lesions || !latestSkinScan || !panoramaURI) {
      return;
    }

    const autoDetections: BoundingBoxAnnotation[] = [];
    for (const lesion of lesions) {
      for (const link of lesion.links) {
        if (link.scan !== latestSkinScan) {
          continue;
        }

        if (matchesPanorama(link.annotation, panoramaURI)) {
          autoDetections.push(link.annotation);
        }
      }
    }

    return autoDetections;
  }, [latestSkinScan, panoramaURI, lesions]);

  const { camera, set } = ui;

  const visible = !!camera;
  const setVisible = useCallback(
    (visible: boolean) => {
      if (visible && panoramaURI) {
        set({
          camera: {
            side: panoramaURI.side,
            module: panoramaURI.cameraName,
          },
        });
      } else {
        set({ camera: undefined });
      }
    },
    [set, panoramaURI]
  );

  useEffect(() => {
    if (active === false) {
      setVisible(false);
    }
  }, [active, setVisible]);

  const homographies = useHomographies({ panoramaURI });

  const fetchTile = usePanoramaFetch(panoramaURI as PanoramaImageURI);

  const [source, setSource] = useState<TranscoderImageResponse>();

  useEffect(() => {
    /**
     * The panorama image size is based on:
     * 1. viewport width (1920px) so that a 1:1 image will fit well on a 16:9 screen
     * 2. a bleed factor to account for image scaling beyond the viewport dimensions that's used to crop out black edges in the panorama images
     */
    const size = {
      w: 2048,
      h: 2048,
      fit: "contain",
    } as const;

    fetchTile(size)
      .then(setSource)
      .catch(() => {});

    return () => {
      setSource(undefined);
    };
  }, [fetchTile, setSource, panoramaURI]);

  const locations = useMemo(() => {
    if (!autoDetections || !homographies) {
      return [];
    }

    const lowest = {
      x: Infinity,
      y: Infinity,
    };

    const locations: BoundaryBoxLocation[] = [];
    for (const annotation of autoDetections) {
      const coords = panoramaCoords(annotation, homographies);
      if (coords) {
        const bounds = new Box(
          coords.center.x - coords.radius,
          coords.center.y - coords.radius,
          2 * coords.radius,
          2 * coords.radius
        );

        locations.push({
          id: annotation.id,
          type: "auto-detection",
          bounds,
          delay: 0,
        });

        if (bounds.top < lowest.y) {
          lowest.y = bounds.top;
        }
        if (bounds.left < lowest.x) {
          lowest.x = bounds.left;
        }
      }
    }

    for (const location of locations) {
      location.delay = 0 + Math.random() * 2.5;
    }

    return locations;
  }, [autoDetections, homographies]);

  const [imageContainerSize, setImageContainerSize] = useState<
    { width: number; height: number } | undefined
  >();

  useEffect(() => {
    const element = imageContainer.current;
    if (!element) {
      return;
    }
    const observer = new ResizeObserver(([elem]) => {
      setImageContainerSize({
        width: elem.target.clientWidth,
        height: elem.target.clientHeight,
      });
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [source]);

  const scale: { x: number; y: number } | undefined = useMemo(() => {
    if (!source || !imageContainerSize) {
      return;
    }

    const { w, h } = source.meta.original.size;
    const x = imageContainerSize.width / w;
    const y = imageContainerSize.height / h;

    return { x, y };
  }, [source, imageContainerSize]);

  const sourceAspectRatio = useMemo(() => {
    if (!source) {
      return 1;
    }

    return source.meta.original.size.w / source.meta.original.size.h;
  }, [source]);

  /**
   * Render box areas after a delay to prevent visual stuttering during thumb-to-fullscreen expand and collapse transitions
   */
  const [renderBoxAreas, setRenderBoxAreas] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => setRenderBoxAreas(visible), 750);
    return () => clearTimeout(timer);
  }, [visible]);

  if (!render) {
    return null;
  }

  return (
    <div
      className={styles.PanoramaOverlay}
      data-active={visible}
      data-ready={!!source?.image && false}
    >
      <button onClick={() => setVisible(true)} />

      <div className={styles.container}>
        <PanView {...panViewConfig} active={visible}>
          {(viewBox) => {
            return (
              <div
                className={styles.content}
                ref={imageContainer}
                style={{
                  width: `calc(100vw + ${panViewConfig?.bleed ?? 0} * 100vw)`,
                  aspectRatio: sourceAspectRatio,
                }}
              >
                {source && (
                  <LodImageViewer
                    source={source}
                    viewCrop={viewBox.crop}
                    viewSize={viewBox.size}
                    fetchTile={fetchTile}
                  />
                )}

                <div className={styles.boxes}>
                  {renderBoxAreas &&
                    scale &&
                    locations.map((location) => {
                      const bounds = location.bounds;
                      const scaled = {
                        x: bounds.x * scale.x,
                        y: bounds.y * scale.y,
                        w: bounds.w * scale.x,
                        h: bounds.h * scale.y,
                      };

                      return (
                        <div
                          key={location.id}
                          className={styles.box}
                          data-type={location.type}
                          style={{
                            left: scaled.x,
                            top: scaled.y,
                          }}
                        >
                          <img
                            alt=""
                            className={styles.boundaryBox}
                            src={BoundaryBoxSVG}
                            style={{
                              width: scaled.w,
                              height: scaled.w,
                              animationDelay: `${
                                visible ? location.delay : 0
                              }s`,
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          }}
        </PanView>
      </div>
    </div>
  );
}
