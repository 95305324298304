import { classNames } from "@pomle/classnames";
import { Point } from "lib/math";
import { useCallback, useEffect, useState } from "react";
import Input from "render/ui/form/Input";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { TrashIconButton } from "render/ui/trigger/IconButton";
import { TissueAnnotationDataType } from "../lib/types";
import styles from "./styles.module.sass";

interface LabelEditorProps {
  addOrEdit: "add" | "edit";
  type: TissueAnnotationDataType;
  orgLabel: string;
  anchor?: Point;
  onCancel: () => void;
  onRemove?: () => void;
  onSave: (label: string) => void;
}

export default function LabelEditor({
  addOrEdit,
  type,
  orgLabel,
  anchor,
  onCancel,
  onRemove,
  onSave,
}: LabelEditorProps) {
  const [label, setLabel] = useState<string>(orgLabel);
  const [realPosition, setRealPosition] = useState<Point>(new Point(0, 0));

  const cssClass = addOrEdit === "add" ? styles.AddLabel : styles.EditLabel;

  useEffect(() => {
    if (!anchor) {
      return;
    }
    let x, y: number;
    x = anchor.x - 111; // Half editor width is 111px
    if (x < 0) {
      x = 0;
    }
    y = anchor.y + 5;
    setRealPosition(new Point(x, y));
  }, [anchor]);

  const onLabelChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      ev.stopPropagation();
      setLabel(ev.currentTarget.value);
    },
    []
  );

  const save = useCallback(() => {
    onSave(label);
  }, [label, onSave]);

  const catchEnter = useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if (ev.key === "Enter") {
        ev.stopPropagation();
        save();
      }
    },
    [save]
  );

  return (
    <div
      className={classNames(styles.LabelEditor, cssClass)}
      style={{ left: realPosition.x, top: realPosition.y }}
      data-labeltype={type}
    >
      <div className={styles.inputs}>
        <Input>
          <input
            autoFocus
            onChange={onLabelChange}
            onKeyDown={catchEnter}
            placeholder="Label"
            value={label}
          />
        </Input>
        {addOrEdit === "edit" && <TrashIconButton onClick={onRemove} />}
      </div>
      <div className={styles.Buttons}>
        <HoverTextButton onClick={save}>Save</HoverTextButton>
        <HoverTextButton onClick={onCancel} type="reset">
          Cancel
        </HoverTextButton>
      </div>
    </div>
  );
}
