export function normalize(min: number, max: number) {
  let high = NaN;
  let low = NaN;

  return function stretch(value: number, _: number, values: number[]) {
    if (Number.isNaN(low)) {
      low = Math.min(...values);
    }
    if (Number.isNaN(high)) {
      high = Math.max(...values);
    }

    value -= low;
    value /= high - low;
    value *= max - min;
    value += min;

    return value;
  };
}
