import { roundLeast } from "lib/math";
import { createMetric } from "lib/metric";
import { Bloodwork } from "lib/types/metrics/bloodwork";

export function autoComplete(source: Bloodwork): Bloodwork {
  const bw = { ...source };

  if (!bw.cholesterolHDL) {
    if (bw.cholesterol && bw.HDL) {
      const c = bw.cholesterol.unit["mmol/L"];
      const h = bw.HDL.unit["mmol/L"];

      bw.cholesterolHDL = createMetric("bloodwork.cholesterol_hdl", {
        fraction: roundLeast(c / h, c, h),
      });
    }
  }

  if (!bw.nonHDL) {
    if (bw.cholesterol && bw.HDL) {
      const c = bw.cholesterol.unit["mmol/L"];
      const h = bw.HDL.unit["mmol/L"];

      bw.nonHDL = createMetric("bloodwork.non_hdl", {
        "mmol/L": roundLeast(c - h, c, h),
      });
    }
  }

  return bw;
}
