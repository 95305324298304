import { toPolyline } from "lib/svg";
import { useMemo } from "react";
import { useCardioGraphPattern } from "render/assets/svg/gradient";

type Point = { x: number; y: number };

interface CardioGraphProps
  extends Omit<React.SVGAttributes<unknown>, "points"> {
  points: Point[];
  duration?: number;
  progress?: number;
}

export default function CardioGraph({
  points,
  duration,
  progress,
}: CardioGraphProps) {
  const path = useMemo(() => {
    return toPolyline(points);
  }, [points]);

  const progressX = progress ?? 0;

  const pattern = useCardioGraphPattern({ progress: progressX, duration });

  return (
    <svg viewBox="0 0 100 100" preserveAspectRatio="none">
      <defs>{pattern.def}</defs>

      <polyline
        vectorEffect="non-scaling-stroke"
        points={path}
        stroke={pattern.line.ref}
        fill={pattern.fill.ref}
      />

      <rect width="100" height="100" fill={pattern.beam.ref} />
    </svg>
  );
}
