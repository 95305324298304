import ButtonSet from "render/ui/trigger/ButtonSet";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { PlotType } from "../constants";
import styles from "./styles.module.sass";

interface ButtonControlProps {
  activePlot: PlotType;
  setActivePlot: React.Dispatch<React.SetStateAction<PlotType>>;
}

export default function ButtonControl({
  activePlot,
  setActivePlot,
}: ButtonControlProps) {
  return (
    <div className={styles.ButtonControl}>
      <ButtonSet>
        <HoverTextButton
          active={activePlot === PlotType.EJECTION_TIME}
          onClick={() => {
            setActivePlot(PlotType.EJECTION_TIME);
          }}
        >
          Ejection Time
        </HoverTextButton>

        <HoverTextButton
          active={activePlot === PlotType.PWV}
          onClick={() => {
            setActivePlot(PlotType.PWV);
          }}
        >
          Pulse Wave Velocity
        </HoverTextButton>

        <HoverTextButton
          active={activePlot === PlotType.HAND_ASYMMETRY}
          onClick={() => {
            setActivePlot(PlotType.HAND_ASYMMETRY);
          }}
        >
          Hand Asymmetry
        </HoverTextButton>
        <HoverTextButton
          active={activePlot === PlotType.FOOT_ASYMMETRY}
          onClick={() => {
            setActivePlot(PlotType.FOOT_ASYMMETRY);
          }}
        >
          Foot Asymmetry
        </HoverTextButton>
      </ButtonSet>
    </div>
  );
}
