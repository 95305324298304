import { useCallback, useEffect, useState } from "react";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useUserInput } from "render/hooks/useUserInput";
import Field from "render/ui/form/Field";
import Fieldset from "render/ui/form/Fieldset";
import Input from "render/ui/form/Input";
import RadioButtons from "render/ui/form/RadioButtons";
import BodyMetricPressure from "render/ui/format/BodyMetricPressure";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";
import ReferenceValue from "../../fragments/ReferenceValue";
import { Entries, toMetrics } from "./conversion";
import {
  EyePressureMetrics,
  useEyePressureMetrics,
} from "./hooks/useEyePressureMetrics";
import styles from "./styles.module.sass";

const INITIAL: Entries = {
  eyePressureLeft: "",
  eyePressureRight: "",
};

const BipolarResponse = [
  { value: false, content: "No" },
  { value: true, content: "Yes" },
];

interface EyePressureSectionProps {
  patientId: string;
}

export default function EyePressureSection({
  patientId,
}: EyePressureSectionProps) {
  const { fetchEyePressureMetrics, updateEyePressureMetrics } =
    useEyePressureMetrics();

  const [latest, setLatest] = useState<EyePressureMetrics>();
  const [wearsContactLenses, setWearsContactLenses] = useState<boolean>();
  const updateLatest = useCallback(() => {
    fetchEyePressureMetrics(patientId).then((metrics) => {
      setLatest(metrics);
      setWearsContactLenses(metrics.wearsContactLenses?.unit);
    });
  }, [fetchEyePressureMetrics, patientId]);

  useEffect(() => {
    updateLatest();

    return () => {
      setLatest(undefined);
    };
  }, [updateLatest]);

  const [entries, setEntries] =
    useState<Omit<Entries, "wearsContactLenses">>(INITIAL);

  const userInput = useUserInput(entries, setEntries);

  const handleSave = useCallback(async () => {
    await updateEyePressureMetrics(
      patientId,
      toMetrics({ ...entries, wearsContactLenses })
    );
    setEntries(INITIAL);

    setTimeout(updateLatest, 1000);
  }, [
    entries,
    patientId,
    updateEyePressureMetrics,
    updateLatest,
    wearsContactLenses,
  ]);

  const formHandle = useFormHandle(handleSave);

  return (
    <form className={styles.EyePressureSection} onSubmit={formHandle.onSubmit}>
      <Fieldset direction="row" legend="Eye Pressure">
        <Field label="Left Eye">
          <Input hint="mm Hg">
            <input
              type="number"
              min={5}
              max={35}
              step={1}
              autoComplete="off"
              {...userInput.eyePressureLeft}
            />
          </Input>

          <ReferenceValue
            value={
              <BodyMetricPressure pressure={latest?.eyePressureLeft?.unit} />
            }
          />
        </Field>

        <Field label="Right Eye">
          <Input hint="mm Hg">
            <input
              type="number"
              min={5}
              max={35}
              step={1}
              autoComplete="off"
              {...userInput.eyePressureRight}
            />
          </Input>

          <ReferenceValue
            value={
              <BodyMetricPressure pressure={latest?.eyePressureRight?.unit} />
            }
          />
        </Field>
      </Fieldset>
      <Fieldset>
        <Field label="Is the member wearing lenses?">
          <RadioButtons
            value={wearsContactLenses}
            entries={BipolarResponse}
            onChange={(value) => setWearsContactLenses(value)}
          />
        </Field>
      </Fieldset>

      <ButtonSet>
        <SubmitButton handle={formHandle}>Save</SubmitButton>
      </ButtonSet>
    </form>
  );
}
