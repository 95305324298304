import { Patient, Slot } from "@cur8/rich-entity";
import { useNav } from "@pomle/react-router-paths";
import { DateTime } from "luxon";
import { useCallback, useMemo } from "react";
import {
  THAT_ONE_PRODUCT_WE_HAVE,
  useBookingPrice,
} from "render/hooks/api/billing/useBookingPrice";
import { usePatientProduct } from "render/hooks/api/billing/usePatientProduct";
import { paths } from "render/routes/paths";
import { query } from "render/routes/querys";
import PriceNumber from "render/ui/format/PriceNumber";
import DropdownMenuButton from "render/ui/trigger/DropdownMenuButton";
import DropdownItem from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import CalendarAdd from "render/ui/trigger/DropdownMenuButton/components/DropdownItem/assets/calendar-add.svg?react";
import { useDoubleBookingConfirmation } from "../../hooks/useDoubleBookingConfirmation";
import BookPatientButton from "../BookPatientButton";
import RefundPatientBookingTokenButton from "../RefundPatientBookingTokenButton";
import styles from "./styles.module.sass";

export interface ScheduleFollowUpButtonProps {
  patient: Patient;
  upcomingAppointment?: Slot;
}

export default function ScheduleFollowUpButton({
  patient,
  upcomingAppointment,
}: ScheduleFollowUpButtonProps) {
  const { product } = usePatientProduct(
    patient.patientId,
    THAT_ONE_PRODUCT_WE_HAVE
  );
  const { total, totalDiscount } = useBookingPrice(
    patient.patientId,
    patient.preferredCountry
  );
  const { emitDoubleBookingConfirmation } = useDoubleBookingConfirmation();

  const nav = {
    schedule: useNav(paths.schedule, query.schedule),
    siteSelect: useNav(paths.scheduleSiteSelect, query.schedule),
  };

  const target = useMemo(
    () => DateTime.now().plus({ months: 12 }).startOf("week"),
    []
  );

  const navigateToCalendar = useCallback(() => {
    const from = target;
    const to = target.endOf("week").endOf("day");

    const params = {
      assignPatientId: [patient.patientId],
      from: [from],
      to: [to],
      censorAppointments: [true],
    };

    nav.siteSelect.go({}, params);
  }, [nav.siteSelect, patient.patientId, target]);

  const handleClick = useCallback(() => {
    if (!upcomingAppointment) {
      navigateToCalendar();
      return;
    }
    emitDoubleBookingConfirmation(
      patient,
      upcomingAppointment,
      navigateToCalendar
    );
  }, [
    navigateToCalendar,
    emitDoubleBookingConfirmation,
    upcomingAppointment,
    patient,
  ]);

  return (
    <DropdownMenuButton header="Booking actions" label="Book appointment...">
      <DropdownItem>
        <div className={styles.rebookingInfo}>
          <h3>Pricing details:</h3>
          <div className={styles.priceBreakdown}>
            <div>Base price: </div>
            <strong>
              {product && product.price ? (
                <PriceNumber
                  price={product.price}
                  currency={product.currency}
                />
              ) : (
                "--"
              )}
            </strong>
            <div>Discount: </div>
            <strong>
              {totalDiscount && product ? (
                <PriceNumber
                  price={-totalDiscount}
                  currency={product.currency}
                />
              ) : (
                "--"
              )}
            </strong>
            <div>Total: </div>
            <strong>
              {total && product ? (
                <PriceNumber price={total} currency={product.currency} />
              ) : (
                "--"
              )}
            </strong>
          </div>
        </div>
      </DropdownItem>
      <DropdownItem onClick={handleClick}>
        <CalendarAdd /> Book Appointment
      </DropdownItem>
      <BookPatientButton
        patient={patient}
        upcomingAppointment={upcomingAppointment}
      />
      <RefundPatientBookingTokenButton patientId={patient.patientId} />
    </DropdownMenuButton>
  );
}
