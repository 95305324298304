import { Bounds, Point } from "lib/math";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { getPointerMovement } from "render/event";
import { useDOMElement } from "render/hooks/useDOMElement";
import styles from "./styles.module.sass";
import { Layout, ViewBox } from "./types";

const NO_BOUNDS = new Bounds(
  new Point(-Infinity, -Infinity),
  new Point(Infinity, Infinity)
);

const SCALE_INITIAL = 1;
const ZOOM_SCALE = 2;
const PAN_ZERO = new Point(0, 0);
const PAN_CENTER = new Point(0.5, 0.5);

export interface PanViewConfig {
  active?: boolean;
  focusPoint?: Point;
  zoomScale?: number;
  bleed?: number;
}

export interface PanViewProps extends PanViewConfig {
  children: (view: ViewBox) => React.ReactNode;
  onMove?: (movement: Point) => void;
}

export default function PanView({
  active = true,
  children: content,
  zoomScale = ZOOM_SCALE,
  bleed = 0,
  focusPoint = PAN_CENTER,
  onMove = () => {},
}: PanViewProps) {
  const frameRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const frameElement = useDOMElement(frameRef);
  const contentElement = useDOMElement(contentRef);

  const [smooth, setSmooth] = useState<boolean>(false);
  const [layout, setLayout] = useState<Layout>(Layout.Fit);

  const [pan, setPan] = useState(PAN_ZERO);
  const [scale, setScale] = useState<number>(SCALE_INITIAL);

  const calcFitScale = useCallback(() => {
    if (!frameElement || !contentElement) {
      return SCALE_INITIAL;
    }

    const scale =
      (frameElement.offsetWidth * (1 + bleed)) / contentElement.offsetWidth;

    if (!isFinite(scale)) {
      return SCALE_INITIAL;
    }

    return scale;
  }, [frameElement, contentElement, bleed]);

  const calcBounds = useCallback(
    (scale: number): Bounds => {
      if (!frameElement || !contentElement) {
        return NO_BOUNDS;
      }

      const bounds = new Bounds(
        new Point(
          frameElement.offsetWidth * bleed * 0.5 * scale,
          frameElement.offsetHeight * bleed * 0.5 * scale
        ),
        new Point(
          Math.max(
            0,
            contentElement.offsetWidth * scale -
              frameElement.offsetWidth -
              frameElement.offsetWidth * bleed * 0.5 * scale
          ),
          Math.max(
            0,
            contentElement.offsetHeight * scale -
              frameElement.offsetHeight -
              frameElement.offsetHeight * bleed * 0.5 * scale
          )
        )
      );

      return bounds;
    },
    [frameElement, contentElement, bleed]
  );

  const calcScreenCenter = useCallback((): Point => {
    if (!frameElement || !contentElement) {
      return new Point(0.5, 0.5);
    }

    const frameRect = frameElement.getBoundingClientRect();
    const contentRect = contentElement.getBoundingClientRect();

    return new Point(
      (frameRect.left - contentRect.left + frameRect.width / 2) /
        contentRect.width,
      (frameRect.top - contentRect.top + frameRect.height / 2) /
        contentRect.height
    );
  }, [frameElement, contentElement]);

  const handleScroll = useCallback(
    (event: React.WheelEvent) => {
      setSmooth(false);

      const bounds = calcBounds(scale);
      const deltaY = event.deltaY;

      setPan((pan) => {
        const nextPan = new Point(pan.x, pan.y + deltaY);
        return bounds.clamp(nextPan);
      });
    },
    [calcBounds, scale]
  );

  const handlePan = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1) {
        return;
      }

      event.preventDefault();

      setSmooth(false);

      const bounds = calcBounds(scale);
      const movement = getPointerMovement(event);

      setPan((pan) => {
        const nextPan = pan.subtract(movement);
        return bounds.clamp(nextPan);
      });
    },
    [calcBounds, scale]
  );

  const handleMove = useCallback(
    (event: React.PointerEvent) => {
      if (event.buttons !== 1) {
        return;
      }

      event.preventDefault();

      setSmooth(false);

      const movement = getPointerMovement(event).multiplyScalar(1 / ZOOM_SCALE);

      onMove(movement);
    },
    [onMove]
  );

  const zoomIn = useCallback(
    (coords: Point) => {
      if (!frameElement || !contentElement) {
        return;
      }

      const frameRect = frameElement.getBoundingClientRect();

      setSmooth(true);

      setLayout(Layout.Max);

      const offset = new Point(
        contentElement.offsetWidth * zoomScale * coords.x - frameRect.width / 2,
        contentElement.offsetHeight * zoomScale * coords.y -
          frameRect.height / 2
      );

      const bounds = calcBounds(zoomScale);

      setPan(bounds.clamp(offset));
      setScale(zoomScale);
    },
    [frameElement, contentElement, setPan, zoomScale, calcBounds]
  );

  const zoomOut = useCallback(
    (coords: Point) => {
      if (!frameElement || !contentElement) {
        return;
      }

      const frameRect = frameElement.getBoundingClientRect();

      setSmooth(true);

      const scale = calcFitScale();

      const size = new Point(
        contentElement.offsetWidth,
        contentElement.offsetHeight
      );

      const offset = new Point(
        coords.x * scale * size.x - frameRect.width / 2,
        coords.y * scale * size.y - frameRect.height / 2
      );

      const bounds = calcBounds(scale);

      setPan(bounds.clamp(offset));
      setScale(scale);
    },
    [frameElement, contentElement, calcFitScale, calcBounds]
  );

  const zoomInAt = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      if (!contentElement) {
        return;
      }

      const contentRect = contentElement.getBoundingClientRect();

      const point = new Point(
        (event.clientX - contentRect.x) / contentRect.width,
        (event.clientY - contentRect.y) / contentRect.height
      );

      zoomIn(point);
    },
    [contentElement, zoomIn]
  );

  const toggleZoom = useCallback(
    (event: React.MouseEvent<HTMLImageElement>) => {
      setSmooth(true);

      if (layout === Layout.Fit) {
        setLayout(Layout.Max);
        zoomInAt(event);
      } else {
        setLayout(Layout.Fit);
        const center = calcScreenCenter();
        zoomOut(center);
      }
    },
    [layout, zoomInAt, zoomOut, calcScreenCenter]
  );

  const fitContent = useCallback(() => {
    setLayout((layout) => {
      if (layout === Layout.Max) {
        return layout;
      }

      setSmooth(false);

      const scale = calcFitScale();

      const bounds = calcBounds(scale);

      const offset = bounds.min.add(bounds.max).multiply(focusPoint);

      setPan(bounds.clamp(offset));
      setScale(scale);
      return layout;
    });
  }, [calcBounds, calcFitScale, focusPoint]);

  const handlePointerMove = useCallback(
    (event: React.PointerEvent) => {
      handlePan(event);
      handleMove(event);
    },
    [handlePan, handleMove]
  );

  useEffect(() => {
    if (!frameElement || !contentElement) {
      return;
    }

    fitContent();

    const observer = new ResizeObserver(() => {
      fitContent();
    });

    observer.observe(frameElement);

    return () => {
      observer.disconnect();
    };
  }, [frameElement, fitContent, contentElement]);

  useEffect(() => {
    if (!contentElement) {
      return;
    }

    const stopScroll = (event: Event) => {
      event.preventDefault();
    };

    contentElement.addEventListener("wheel", stopScroll, { passive: false });

    return () => {
      contentElement.removeEventListener("wheel", stopScroll);
    };
  }, [contentElement]);

  const viewBox = useMemo((): ViewBox | undefined => {
    if (!frameElement) {
      return;
    }

    const frameRect = frameElement.getBoundingClientRect();

    return {
      crop: {
        x: pan.x / scale,
        y: pan.y / scale,
        w: frameRect.width / scale,
        h: frameRect.height / scale,
      },
      size: {
        w: frameRect.width * (1 + bleed),
        h: frameRect.height * (1 + bleed),
      },
    };
  }, [frameElement, pan, scale, bleed]);

  // once the pan view is active, enable interactions only after a slight delay to prevent accidental zooming/panning
  const [enableInteractions, setEnableInteractions] = useState(false);
  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (active) {
      timer = setTimeout(() => {
        setEnableInteractions(true);
      }, 200);
    } else {
      setEnableInteractions(false);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [active]);

  return (
    <div
      className={styles.PanView}
      ref={frameRef}
      onPointerMove={handlePointerMove}
      onWheel={handleScroll}
      data-enable-interactions={enableInteractions}
    >
      <div
        className={styles.content}
        data-smooth={smooth}
        ref={contentRef}
        onDoubleClick={toggleZoom}
        style={{
          transform: `
              translate(${-pan.x}px, ${-pan.y}px)
              scale(${scale})
            `,
        }}
      >
        {viewBox && content(viewBox)}
      </div>
    </div>
  );
}
