import { useMemo } from "react";
import RangeLine, { getBounds } from "../RangeLine";
import { RangeLineEntry, RangePointEntry } from "../RangeLine/lib";
import styles from "./styles.module.sass";

interface ValueTimelineProps {
  ranges: RangeLineEntry[];
  points: RangePointEntry[];
}

export default function ValueTimeline({ ranges, points }: ValueTimelineProps) {
  const bounds = useMemo(() => {
    return getBounds(ranges, points);
  }, [ranges, points]);

  return (
    <div className={styles.ValueTimeline}>
      <div className={styles.items}>
        <div className={styles.range}>
          <RangeLine bounds={bounds} ranges={ranges} points={points} labels />
        </div>
      </div>
    </div>
  );
}
