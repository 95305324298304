import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useMemo, useState } from "react";
import { useFormHandle } from "render/hooks/useFormHandle";
import Field from "render/ui/form/Field";
import Fieldset from "render/ui/form/Fieldset";
import Input from "render/ui/form/Input";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";

import { useAPIClient } from "render/context/APIContext";
import { useUserInput } from "render/hooks/useUserInput";
import PageHeader from "render/ui/layouts/PageHeader";
import styles from "./styles.module.sass";

interface FormState {
  phone: string;
  language: "en" | "sv" | "";
  sender: "Neko Health" | "+46726009690" | "";
}

export default function PatientInvitePage() {
  const client = useAPIClient();
  const [formData, setFormData] = useState<FormState>({
    phone: "",
    language: "",
    sender: "",
  });

  const entry = useMemo(() => ({ ...formData }), [formData]);

  const handleEntries = useCallback(
    (entries: FormState) => {
      setFormData(entries);
    },
    [setFormData]
  );
  const userInput = useUserInput(entry, handleEntries);

  const handleSave = useCallback(async () => {
    const notification = await client.notification.createNotification({
      phoneNumber: formData.phone,
      notificationTextId: APITypesV1.GenericNotificationText.VipBooking,
      scheduledTimeStamp: null,
      language: formData.language,
      // Remove sender if it's the SE number, as this will be sent via Front and not Twilio
      sender: formData.sender === "+46726009690" ? undefined : formData.sender,
    }).result;

    return notification;
  }, [formData, client]);
  const handle = useFormHandle(handleSave);

  return (
    <FramedPage>
      <div className={styles.PatientInvitePage}>
        <PageHeader caption="Invite Member" />

        <form onSubmit={handle.onSubmit} autoComplete="off">
          <Fieldset legend="Phone no.">
            <Field>
              <Input>
                <input
                  type="text"
                  placeholder="Phone no. (+46)"
                  minLength={10}
                  {...userInput.phone}
                  required
                  pattern="^\+\d{6,}"
                />
              </Input>
            </Field>
          </Fieldset>

          <Fieldset legend="Language">
            <Field>
              <Input>
                <select {...userInput.language} required>
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="en">English</option>
                  <option value="sv">Swedish</option>
                </select>
              </Input>
            </Field>
          </Fieldset>

          <Fieldset legend="Send From">
            <Field>
              <Input>
                <select {...userInput.sender} required>
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="+46726009690">+46726009690</option>
                  <option value="Neko Health">Neko Health</option>
                </select>
              </Input>
            </Field>
          </Fieldset>

          <ButtonSet>
            <SubmitButton handle={handle}>Send SMS</SubmitButton>
          </ButtonSet>
        </form>
      </div>
    </FramedPage>
  );
}
