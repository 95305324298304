import styles from "./styles.module.sass";

interface ContextHeaderProps {
  context: React.ReactNode;
  children: React.ReactNode;
}

export default function ContextHeader({
  context,
  children,
}: ContextHeaderProps) {
  return (
    <div className={styles.ContextHeader}>
      <div className={styles.context}>{context}</div>

      <div className={styles.content}>{children}</div>
    </div>
  );
}
