import LesionMarkerIcon from "./assets/lesion-marker.svg?react";
import styles from "./styles.module.sass";

interface LesionMarkerProps {
  active: boolean;
}

export default function LesionMarker({ active }: LesionMarkerProps) {
  return (
    <div className={styles.LesionMarker} data-active={active}>
      <LesionMarkerIcon />
    </div>
  );
}
