import { APITypesV1 } from "@cur8/api-client";
import {
  Annotation,
  AnnotationClassification,
  AnnotationData,
  BoundingBoxDataType,
  LineDataType,
  NamedTimestampsDataType,
  PointSpaceDataType,
  RangeDataType,
} from "@cur8/rich-entity";
import { Box } from "lib/math";

export function toRect(box: Box): APITypesV1.Rect {
  return {
    left: box.x,
    top: box.y,
    width: box.w,
    height: box.h,
  };
}

function toClassification(
  classification: AnnotationClassification
): APITypesV1.Classification | null {
  if (classification == null) {
    return null;
  }

  return classification;
}

function toAnnotationData2D(
  data: BoundingBoxDataType
): APITypesV1.AnnotationData2D {
  return {
    $type: "2d",
    rect: toRect(data.rect),
  };
}

function toAnnotationData3D(
  data: PointSpaceDataType
): APITypesV1.AnnotationData3D {
  return {
    $type: "3d",
    vertices: data.vertices,
    area: data.area,
  };
}

function toAnnotationDataRange(
  data: RangeDataType
): APITypesV1.AnnotationDataRange {
  return {
    $type: "range",
    range: data.range,
  };
}

function toAnnotationDataLine(
  data: LineDataType
): APITypesV1.AnnotationDataLine2D {
  return {
    $type: "line2d",
    line: data.line,
  };
}

function toAnnotationDataNamedTimestamps(
  data: NamedTimestampsDataType
): APITypesV1.AnnotationDataNamedTimestamps {
  return {
    $type: "namedTimestamps",
    namedTimestamps: data.namedTimestamps,
  };
}

function toData(data: AnnotationData): APITypesV1.Annotation["data"] {
  if (data == null) {
    return null;
  }

  if ("line" in data) {
    return toAnnotationDataLine(data);
  }

  if ("namedTimestamps" in data) {
    return toAnnotationDataNamedTimestamps(data);
  }

  if ("range" in data) {
    return toAnnotationDataRange(data);
  }

  if ("rect" in data) {
    return toAnnotationData2D(data);
  }

  if ("vertices" in data) {
    return toAnnotationData3D(data);
  }

  return null;
}

export type CreateAnnotation = Partial<Annotation> &
  Pick<Annotation, "targetURI"> &
  Pick<Annotation, "acceptState">;

export function toCreateAnnotationRequest(
  annotation: CreateAnnotation
): APITypesV1.CreateAnnotationRequest {
  return {
    applicationSpecificTarget: annotation.applicationSpecificTarget,
    classification: annotation.classification
      ? toClassification(annotation.classification)
      : undefined,
    data: annotation.data ? toData(annotation.data) : undefined,
    comment: annotation.comment,
    physicalArtefactId: annotation.physicalArtefactId,
    targetUri: annotation.targetURI.toString(),
    acceptState: annotation.acceptState,
  };
}
