import { DateTime } from "luxon";
import { ReactNode, useMemo } from "react";
import styles from "./styles.module.sass";

interface MiniCalendarProps {
  date: DateTime;
  renderDay: (date: DateTime) => ReactNode;
}

interface Day {
  date: DateTime | undefined;
}

export function MiniCalendar({ date, renderDay }: MiniCalendarProps) {
  const dateKey = date.toUTC().toISO();

  const days = useMemo(() => {
    const target = DateTime.fromISO(dateKey);
    const result: Day[] = [];
    const start = target.startOf("month");
    const end = target.endOf("month");

    let pointer = start.startOf("week");

    while (pointer <= end) {
      if (pointer < start) {
        result.push({ date: undefined });
      } else {
        result.push({
          date: pointer,
        });
      }

      pointer = pointer.plus({ day: 1 });
    }

    return result;
  }, [dateKey]);

  return (
    <div>
      <ol className={styles.header}>
        <li>M</li>
        <li>T</li>
        <li>W</li>
        <li>T</li>
        <li>F</li>
        <li>S</li>
        <li>S</li>
      </ol>
      <ol className={styles.days}>
        {days.map(({ date }, index) => {
          if (date == null) {
            return <li key={index}></li>;
          }
          return (
            <li className={styles.dayListItem} key={index}>
              {renderDay(date)}
            </li>
          );
        })}
      </ol>
    </div>
  );
}
