import { PlotData } from "../../../types";
import styles from "./styles.module.sass";

interface PlotLegendProps {
  data: PlotData[];
}

export default function PlotLegend({ data }: PlotLegendProps) {
  return (
    <div className={styles.PlotLegend}>
      {data.map((item, index) => (
        <div key={index} className={styles.item}>
          <span
            className={styles.line}
            style={{
              backgroundColor: item.color,
            }}
          />
          <span>{item.label}</span>
        </div>
      ))}
    </div>
  );
}
