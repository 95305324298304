import { APITypesV2 } from "@cur8/api-client";
import { convertQuestionnaire } from "lib/questionnaire/convert";
import { getFamilyHealthConditions } from "lib/questionnaire/getFamilyHealthConditions";
import styles from "./styles.module.sass";

interface OnboardingQuestionnaireProps {
  response: APITypesV2.QuestionnaireResponse;
}

export default function OnboardingQuestionnaire({
  response,
}: OnboardingQuestionnaireProps) {
  if (!response) {
    return (
      <div className={styles.question}>
        <div className={styles.title}>Questionnaire was not completed.</div>
      </div>
    );
  }

  const answers = convertQuestionnaire(response);

  const healthConditions = answers?.healthConditions;
  const additionalWorries = answers?.additionalWorries;
  const healthConditionsFamily =
    answers?.v1.healthConditionsFamily || getFamilyHealthConditions(answers);

  if (!healthConditions && !additionalWorries && !healthConditionsFamily) {
    return (
      <div className={styles.question}>
        <div className={styles.title}>
          No information of health conditions, family health conditions or
          health concerns provided in the onboarding.
        </div>
      </div>
    );
  }

  return (
    <>
      {healthConditions && (
        <div className={styles.question}>
          <div className={styles.title}>Health conditions in the last year</div>
          <div className={styles.answer}>{healthConditions}</div>
        </div>
      )}
      {additionalWorries && (
        <div className={styles.question}>
          <div className={styles.title}>Questions or concerns</div>
          <div className={styles.answer}>{additionalWorries}</div>
        </div>
      )}
      {healthConditionsFamily && (
        <div className={styles.question}>
          <div className={styles.title}>Family health conditions</div>
          <div className={styles.answer}>{healthConditionsFamily}</div>
        </div>
      )}
    </>
  );
}
