import { useQueryParams } from "@pomle/react-router-paths";
import { DateTime, Interval } from "luxon";
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import { query } from "render/routes/querys";
import { useSelectedPatient } from "../../hooks/useSelectedPatient";
import {
  INITIAL_STATE,
  enterCalendarBookingModeAction,
  enterCalendarViewModeAction,
  reducer,
  setSelectedPeriodAction,
} from "../../reducer";

export const Context = createContext<ReturnType<
  typeof useScheduleState
> | null>(null);

export function useScheduleState(siteId: string) {
  const now = DateTime.now();
  const [queryParams, setQueryParams] = useQueryParams(query.schedule);
  const selectedPatient = useSelectedPatient();

  const startDate = queryParams.from.at(0);
  const endDate = queryParams.to.at(0);

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE, (state) => {
    // Initialize reducer state from query params
    const modifiedState = { ...state };

    // Appointment censoring
    const isQuerySet = !!queryParams.censorAppointments[0];

    if (isQuerySet) {
      modifiedState.censorAppointmens = true;
    }

    // Selected patient
    if (selectedPatient) {
      modifiedState.selectedPatient = selectedPatient;
      modifiedState.mode = "booking";
    }

    // Selected period
    if (startDate && endDate) {
      modifiedState.selectedPeriod = Interval.fromDateTimes(startDate, endDate);
    } else {
      modifiedState.selectedPeriod = Interval.fromDateTimes(
        now.startOf("week"),
        now.endOf("week")
      );
    }

    return modifiedState;
  });

  useEffect(() => {
    if (selectedPatient) {
      dispatch(enterCalendarBookingModeAction(selectedPatient));
    } else {
      dispatch(enterCalendarViewModeAction());
    }
  }, [selectedPatient]);

  useEffect(() => {
    // if from and to changes from query params
    // update selectedPeriod in the reducer accordingly
    const currentSelectedPeriod = state.selectedPeriod;

    // if empty query params - set to current week
    // and reset interaction mode to viewing
    if (!startDate && !endDate) {
      const currentWeek = Interval.fromDateTimes(
        now.startOf("week"),
        now.endOf("week")
      );
      if (!currentSelectedPeriod.equals(currentWeek)) {
        dispatch(setSelectedPeriodAction(currentWeek));
        dispatch(enterCalendarViewModeAction());
      }
    }

    // if both from and to are set - set selectedPeriod to match query params
    if (startDate && endDate) {
      const queryParamsInteval = Interval.fromDateTimes(startDate, endDate);
      if (!currentSelectedPeriod.equals(queryParamsInteval)) {
        dispatch(setSelectedPeriodAction(queryParamsInteval));
      }
    }
    // we only care for query param changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  // TODO: Figure out who is able to use this
  const isSlotManagementEnabled = useMemo(() => {
    return queryParams.slotManagement[0];
  }, [queryParams.slotManagement]);

  return {
    siteId,
    state,
    isSlotManagementEnabled,
    dispatch,
    setQueryParams,
  };
}

export function useSchedule() {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useSchedule invoked outside of ScheduleContext");
  }
  return context;
}
