import { MetricName, Unit, toDimensions, toUnit } from "@cur8/measurements";
import { Measurement } from "@cur8/rich-entity";
import { DateTime } from "luxon";

export type MetricGroup = Partial<Record<string, Metric>>;

export type Metric<M extends MetricName = MetricName> = {
  name: M;
  measurement: Measurement;
  unit: Unit<M>;
};

export function toMetricList(group: MetricGroup) {
  return Object.values(group).filter((v) => v !== undefined) as Metric[];
}

export function toMetric<M extends MetricName>(
  metricName: M,
  measurement: Measurement
): Metric<M> {
  return {
    name: metricName,
    measurement: measurement as Metric<M>["measurement"],
    unit: toUnit<M>(metricName, measurement.dimensions),
  };
}

const INVALID_DATE = DateTime.invalid("Unsaved metric");

export function createMetric<M extends MetricName>(
  metricName: M,
  unit: Unit<M>
): Metric<M> {
  const measurement: Metric<M>["measurement"] = {
    id: "",
    timestampStart: INVALID_DATE,
    metricName,
    dimensions: toDimensions(metricName, unit),
  };

  return {
    name: metricName,
    measurement,
    unit,
  };
}

export function isMetric<M extends MetricName>(metricName: M) {
  return function hasMetricName(metric: Metric): metric is Metric<M> {
    return (
      metric.name === metricName && metric.measurement.metricName === metricName
    );
  };
}

export function sourceGroupMetrics<K extends string>(
  metrics: Record<K, Metric[]>
) {
  const metricList = (Object.entries(metrics) as [K, Metric[]][]).flatMap(
    ([key, metrics]) => {
      return {
        key,
        metrics,
      };
    }
  );

  const sourceURIs = new Set(
    metricList.flatMap((entry) =>
      entry.metrics.map((metric) => metric.measurement.sourceUri)
    )
  );

  const keys = Object.keys(metrics) as K[];
  const pairs: Record<K, Metric[]>[] = [];
  for (const sourceURI of sourceURIs) {
    if (sourceURI == null) {
      continue;
    }

    const pair: Record<string, Metric[]> = {};
    for (const key of keys) {
      const metrics = metricList
        .filter((group) => group.key === key)
        .flatMap((group) => {
          return group.metrics.filter((metric) => {
            return metric.measurement.sourceUri === sourceURI;
          });
        });

      pair[key] = metrics;
    }
    pairs.push(pair);
  }

  return pairs;
}
