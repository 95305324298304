import BackIcon from "./assets/back.svg?react";
import styles from "./styles.module.sass";

export default function BackButton({
  ...props
}: React.ButtonHTMLAttributes<any>) {
  return (
    <button type="button" className={styles.BackButton} {...props}>
      <BackIcon />
    </button>
  );
}
