import {
  Annotation,
  AnnotationClassification,
  AnnotationData,
  ImmutableScan,
  Patient,
  Recording,
} from "@cur8/rich-entity";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { ThermalImageURI } from "lib/api/thermal-uri";
import { PanoramaImageURI } from "lib/api/uri";
import { clamp } from "lib/math";
import { useCallback, useMemo, useState } from "react";
import BackIcon from "render/assets/nav/back.svg?react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import DermaLinkDialog from "render/fragments/derma-app/DermaLinkDialog";
import PatientSummary from "render/fragments/patient/PatientSummary";
import { useKeyboardPress } from "render/hooks/useKeyboardPress";
import { paths } from "render/routes/paths";
import ContextHeader from "render/ui/layouts/ContextHeader";
import { NextIcon, PrevIcon } from "render/ui/symbol/HoverIcon";
import ButtonSet from "render/ui/trigger/ButtonSet";
import DropdownMenuButton from "render/ui/trigger/DropdownMenuButton";
import DropdownItem from "render/ui/trigger/DropdownMenuButton/components/DropdownItem";
import FigureButton from "render/ui/trigger/FigureButton";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import Carousel from "./components/Carousel";
import ScanItem from "./components/ScanItem";
import ThermalScanItem from "./components/ThermalScanItem";
import styles from "./styles.module.sass";
import { ImageType } from "./types";

interface ScanOverviewProps {
  patient: Patient;
  recording: Recording;
  annotations: Annotation[];
  detectedAnnotations: Annotation[];
  panoramaURIs: PanoramaImageURI[];
  thermalURIs: ThermalImageURI[];
  selectedURI: PanoramaImageURI;
  scan: ImmutableScan;
  onSelect: (uri: PanoramaImageURI) => void;
  onEnter: (uri: PanoramaImageURI) => void;
  onAssess: () => void;
}

const panoramaAnnotations = (
  uri: PanoramaImageURI,
  annotations: Annotation<AnnotationClassification, AnnotationData>[]
) => {
  return annotations.filter((annotation) => {
    if (annotation.applicationSpecificTarget?.startsWith("panorama")) {
      const aUri = PanoramaImageURI.fromString(
        annotation.applicationSpecificTarget || ""
      );
      return uri.side === aUri.side && uri.cameraName === aUri.cameraName;
    } else {
      return false;
    }
  });
};

export default function ScanOverview({
  patient,
  recording,
  annotations,
  detectedAnnotations,
  panoramaURIs,
  thermalURIs,
  selectedURI,
  scan,
  onSelect,
  onEnter,
  onAssess,
}: ScanOverviewProps) {
  const [imageType, setImageType] = useState<ImageType>(ImageType.Rgb);
  const lesions = useNav(paths.patient.lesions);
  const trackedMarkings = useNav(paths.patient.trackedMarkings);

  const { create: createPopup } = usePopupDialog();

  const handleTakePicture = useCallback(() => {
    const { emit, close } = createPopup();

    emit(<DermaLinkDialog patientId={patient.patientId} onClose={close} />);
  }, [patient, createPopup]);

  const items = useMemo(() => {
    return imageType === ImageType.Rgb
      ? panoramaURIs.map((uri) => {
          return {
            uri,
            annotations: panoramaAnnotations(uri, annotations),
          };
        })
      : thermalURIs.map((uri) => {
          return {
            uri,
            annotations: [],
          };
        });
  }, [panoramaURIs, annotations, imageType, thermalURIs]);

  const activeIndex = items.findIndex(({ uri }) => {
    return (
      uri.side === selectedURI.side && uri.cameraName === selectedURI.cameraName
    );
  });

  const selectIndex = useCallback(
    (index: number) => {
      const item = items[index];
      onSelect(item.uri);
    },
    [items, onSelect]
  );

  const stepIndex = useCallback(
    (offset: number) => {
      const nextIndex = clamp(activeIndex + offset, 0, items.length - 1);
      selectIndex(nextIndex);
    },
    [selectIndex, activeIndex, items]
  );

  const enterIndex = useCallback(
    (index: number) => {
      const item = items[index];
      onEnter(item.uri);
    },
    [onEnter, items]
  );

  const handleSelect = useCallback(
    (selectedIndex: number) => {
      const item = items[selectedIndex];
      if (selectedIndex === activeIndex && imageType === ImageType.Rgb) {
        /* We only enter ImageType.Rgb, clicking on ImageType.Thermal
        is handled by the ThermalImage. */
        onEnter(item.uri);
      } else {
        onSelect(item.uri);
      }
    },
    [activeIndex, items, onSelect, onEnter, imageType]
  );

  const handleKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "ArrowLeft") {
        stepIndex(-1);
      } else if (event.code === "ArrowRight") {
        stepIndex(1);
      } else if (event.code === "ArrowUp") {
        enterIndex(activeIndex);
      }
    },
    [activeIndex, enterIndex, stepIndex]
  );

  useKeyboardPress(handleKeyboard);

  const nextIndex = activeIndex + 1;
  const prevIndex = activeIndex - 1;

  const navDetails = useNav(paths.patient.detail);
  return (
    <div className={styles.ScanOverview}>
      <header>
        <ContextHeader
          context={
            <PathLink to={navDetails.to({ patientId: patient.patientId })}>
              <BackIcon />
            </PathLink>
          }
        >
          <div className={styles.content}>
            <div>
              <PatientSummary patient={patient} recording={recording} />
            </div>

            {thermalURIs.length > 0 && (
              <ButtonSet>
                <HoverTextButton
                  active={imageType === ImageType.Rgb}
                  onClick={() => {
                    setImageType(ImageType.Rgb);
                  }}
                >
                  RGB
                </HoverTextButton>
                <HoverTextButton
                  active={imageType === ImageType.Thermal}
                  onClick={() => {
                    setImageType(ImageType.Thermal);
                  }}
                >
                  Thermal
                </HoverTextButton>
              </ButtonSet>
            )}

            <div className={styles.rightMenu}>
              <DropdownMenuButton label="Lesions..." header="Lesions">
                <DropdownItem
                  onClick={lesions.on({ patientId: patient.patientId })}
                >
                  See All
                </DropdownItem>

                <DropdownItem
                  onClick={trackedMarkings.on({ patientId: patient.patientId })}
                >
                  Tracked Markings
                </DropdownItem>

                <DropdownItem onClick={handleTakePicture}>
                  Take New Image
                </DropdownItem>

                <DropdownItem onClick={onAssess}>
                  {scan.journalNoteId ? "View Assessment" : "Write Assessment"}
                </DropdownItem>
              </DropdownMenuButton>
            </div>
          </div>
        </ContextHeader>
      </header>

      <main>
        <div className={styles.images}>
          <Carousel activeIndex={activeIndex}>
            {items.map((item, index) => {
              const annotations = item.annotations;

              return (
                <div
                  key={index}
                  onClick={() => handleSelect(index)}
                  style={{
                    cursor: index === activeIndex ? "pointer" : "default",
                  }}
                >
                  {imageType === ImageType.Rgb ? (
                    <ScanItem
                      panoramaURI={item.uri}
                      annotations={annotations}
                      detectedAnnotations={detectedAnnotations}
                    />
                  ) : (
                    <ThermalScanItem
                      thermalURI={item.uri as ThermalImageURI}
                      annotations={item.annotations}
                    />
                  )}
                </div>
              );
            })}
          </Carousel>
        </div>
      </main>

      <footer>
        <div className={styles.caption}>Skin Evaluation</div>

        <div className={styles.index}>
          {activeIndex + 1} / {items.length}
        </div>

        <div className={styles.control}>
          <FigureButton
            disabled={prevIndex < 0}
            onClick={() => selectIndex(prevIndex)}
            title="Previous"
          >
            <PrevIcon color="black" />
          </FigureButton>
          <FigureButton
            disabled={nextIndex >= items.length}
            onClick={() => selectIndex(nextIndex)}
            title="Next"
          >
            <NextIcon color="black" />
          </FigureButton>
        </div>
      </footer>
    </div>
  );
}
