import { clamp } from "lib/math";
import React, { useCallback, useEffect, useRef } from "react";
import { useRangeContext } from "../../../context/RangeContext";
import DataPlot from "../DataPlot";
import DataWindow from "../DataWindow";
import NavGraph from "../NavGraph";
import Ruler from "../Ruler";
import { MINIMUM_SELECTION } from "../constants";
import { PlotData, Range } from "../types";
import styles from "./styles.module.sass";

export interface GraphWithRulerProps {
  data: PlotData[];
  diffData: PlotData[];
  plugin?: React.ReactNode;
}

export type RulerData = {
  distanceMs: number;
  bpm: number | undefined;
  range: Range;
};

export default function GraphWithRuler({
  data,
  diffData,
  plugin,
}: GraphWithRulerProps) {
  const { windowRange, setWindowRange } = useRangeContext();
  const ref = useRef<HTMLDivElement>(null);

  const signalLengthMs = data[0].signal.lengthMs;

  const handleScroll = useCallback(
    (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();
      const stepSize = MINIMUM_SELECTION;
      const step = event.deltaY > 0 ? stepSize : -stepSize;
      const start = clamp(Math.min(...windowRange) + step, 0, 1);
      let end = clamp(Math.max(...windowRange) - step, 0, 1);
      setWindowRange((start < end ? [start, end] : [end, start]) as Range);
    },
    [setWindowRange, windowRange]
  );

  useEffect(() => {
    const div = ref.current;
    if (div) {
      div.addEventListener("wheel", handleScroll, { passive: false });
    }

    return () => {
      if (div) {
        div.removeEventListener("wheel", handleScroll);
      }
    };
  }, [handleScroll]);

  if (data.length === 0) {
    console.warn("No signal provided");
    return null;
  }

  return (
    <div
      className={styles.GraphWithRuler}
      data-testid="graph"
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <div className={styles.signal}>
        <div className={styles.grow}>
          <DataWindow>
            <div className={styles.fullSignal} ref={ref}>
              <NavGraph data={data} height={60} width="100%" />
            </div>
          </DataWindow>
        </div>
        <div className={styles.plugin}>{plugin}</div>
      </div>

      <div className={styles.GraphContainer}>
        <Ruler signalLengthMs={signalLengthMs}>
          <DataPlot
            grid={{ x: 40 }}
            lengthMs={signalLengthMs}
            data={data}
            height={140}
            width="100%"
          />
          <DataPlot
            grid={{ x: 40 }}
            lengthMs={signalLengthMs}
            data={diffData}
            height={140}
            width="100%"
          />
        </Ruler>
      </div>
    </div>
  );
}
