import { Weight } from "@cur8/measurements";
import { Sex } from "@cur8/rich-entity";
import { BodySide } from "lib/types/body";
import { getAgeBinForAge } from "render/pages/DashboardPage/lib/projection/age";
import distributionModel from "render/pages/DashboardPage/lib/projection/assets/hba1c_blood_pressure_grip_strength_distribution_model.json";
import { SIDE_MAP } from "render/pages/DashboardPage/lib/projection/assets/types";
import { mapSexToModel } from "render/pages/DashboardPage/lib/projection/sex";
import { valueToCpv } from "./projection";

export function roundToNearestMultipleOfFive(number: number): number {
  return Math.round(number / 10) * 10;
}

export function cpvToPercentage(cpv: number) {
  return cpv * 100;
}

export function normalizeCpv(cpv: number) {
  if (cpv <= 0.5) {
    return 1 - cpv;
  }

  return cpv;
}

export function getBenchmarkCpvFor(
  currentHba1cValue: number,
  gripStrengthSide: BodySide,
  age: number,
  sex: Sex
) {
  return valueToCpv(
    currentHba1cValue,
    gripStrengthSide,
    mapSexToModel(sex),
    age
  );
}

export function getBenchmarkMedianFor(
  gripStrengthSide: BodySide,
  age: number,
  sex: Sex
): Weight {
  const side = SIDE_MAP[gripStrengthSide];

  const ageGroup = getAgeBinForAge(age);
  const sexGroup = mapSexToModel(sex);
  // Find distribution arrays from data model
  const distributionArrays = distributionModel[sexGroup][ageGroup][side];

  // Extract distribution arrays
  const binCenters: number[] = distributionArrays["bin_centers"];
  const cdf: number[] = distributionArrays["cdf_values"];

  // Find bin center value for cpv=0.5
  const closestIndex = cdf.reduce((prevIndex, value, currentIndex) => {
    const prevValue = cdf[prevIndex];
    return Math.abs(value - 0.5) < Math.abs(prevValue - 0.5)
      ? currentIndex
      : prevIndex;
  }, 0);

  const medianGripStrength: number = binCenters[closestIndex];

  return {
    kilograms: Math.round(medianGripStrength),
  };
}
