import { createTimer } from "lib/timer";
import { useEffect, useState } from "react";
import * as THREE from "three";
import { ScreenProjector } from "./useProject";

export function useScreenAnchorsForObjects<Key extends string>(
  { getOffsets }: ScreenProjector,
  positions: Record<Key, THREE.Vector3>,
  active: boolean
) {
  const [anchors, setAnchors] = useState<Record<Key, THREE.Vector3>>();

  useEffect(() => {
    if (!active) {
      return;
    }

    return createTimer(() => {
      const anchors = getOffsets(positions);
      setAnchors(anchors);
    });
  }, [active, positions, getOffsets]);

  return anchors;
}

export function useScreenAnchorsForLists(
  { getOffsets }: ScreenProjector,
  positions: THREE.Vector3[],
  active: boolean
) {
  const [anchors, setAnchors] = useState<THREE.Vector3[]>();

  useEffect(() => {
    if (!active) {
      return;
    }

    return createTimer(() => {
      const anchors = getOffsets(positions);
      setAnchors(anchors);
    });
  }, [active, positions, getOffsets]);

  return anchors;
}
