import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import Layout, { LayoutProps } from "../Layout";
import SidePanel from "../SidePanel";
import BloodworkSection from "./components/BloodworkSection";
import styles from "./styles.module.sass";

interface BloodworkLayoutProps extends LayoutProps {}

export default function BloodworkLayout({ active }: BloodworkLayoutProps) {
  const { patient } = usePatientData();

  return (
    <Layout active={active}>
      <div className={styles.BloodworkLayout} data-active={active}>
        <SidePanel>
          {patient && <BloodworkSection patient={patient} />}
        </SidePanel>
      </div>
    </Layout>
  );
}
