import { useMemo } from "react";
import { PatientMetrics } from "render/hooks/patient/usePatientMetrics";
import { useDashboardContext } from "render/pages/DashboardPage/context/DashboardContext";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Layer } from "render/pages/DashboardPage/types";
import { Typography } from "render/ui/presentation/Typography";
import ValueItem from "render/ui/symbol/ValueItem";
import InfoPanel from "../InfoPanel";
import MainUSPItem from "../MainUSPItem";
import ValueBox from "../ValueBox";
import LifestyleIcon from "./assets/lifestyle.svg?react";
import styles from "./styles.module.sass";

function useBloodworkCount(metrics: PatientMetrics["bloodwork"]) {
  const subjects = Object.values(metrics);

  return useMemo(() => {
    return subjects.filter((s) => s?.length).length;
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, subjects);
}
export default function BodyPanel() {
  const { metrics } = usePatientData();

  const bloodworkCount = useBloodworkCount(metrics.bloodwork);

  const { ui } = useDashboardContext();

  return (
    <div className={styles.BodyPanel}>
      <InfoPanel
        onClick={() => {
          ui.set({ layer: Layer.Body });
        }}
        align="right"
        caption={
          <>
            <Typography as="h3" variant="eyebrow-m">
              Body
            </Typography>
            <MainUSPItem value={34} unit="MARKERS" />
          </>
        }
      >
        <div className={styles.values}>
          <ValueBox caption="Measurements" value={<ValueItem value={10} />} />

          <ValueBox
            caption="Bloodwork"
            value={<ValueItem value={bloodworkCount} />}
          />

          <ValueBox caption="Lifestyle" value={<LifestyleIcon />} />
        </div>
      </InfoPanel>
    </div>
  );
}
