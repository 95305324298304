import { codecs, createCodec, createQuery } from "@pomle/paths";
import { ColorMap, Colormaps } from "lib/webgl/colorizer";
import {
  BloodVesselsMode,
  BloodVesselsModes,
  IMAGETYPES,
  ImageType,
  PROPERTIES,
  Property,
} from "./types";

const bloodVesselsSet = new Set<BloodVesselsMode>(BloodVesselsModes);
const bloodVesselsModeCodec = createCodec<BloodVesselsMode>(
  (source) => {
    return source;
  },
  (source: string) => {
    if (bloodVesselsSet.has(source as BloodVesselsMode)) {
      return source as BloodVesselsMode;
    }
    return BloodVesselsMode.inactive;
  }
);

const colormapSet = new Set<ColorMap>(Object.values(Colormaps));
const colormapCodec = createCodec<ColorMap>(
  (source: ColorMap) => {
    return source.toString();
  },
  (source: string) => {
    const idx = parseInt(source);
    if (colormapSet.has(idx as ColorMap)) {
      return idx as ColorMap;
    }
    return ColorMap.Viridis;
  }
);

const imageTypeCodec = createCodec<ImageType>(
  (source) => {
    return source;
  },
  (source: string) => {
    if (IMAGETYPES.has(source as ImageType)) {
      return source as ImageType;
    }
    return ImageType.normal;
  }
);

const propertyCodec = createCodec<Property>(
  (source) => {
    return source;
  },
  (source: string) => {
    if (PROPERTIES.has(source as Property)) {
      return source as Property;
    }
    return Property.t1BloodVolume;
  }
);

export const annotationIdQuery = createQuery({
  anno: codecs.string,
});

export const bloodVesselsModeQuery = createQuery({
  bvmode: bloodVesselsModeCodec,
});

export const colormapQuery = createQuery({
  colormap: colormapCodec,
});

export const imageTypeQuery = createQuery({
  type: imageTypeCodec,
});

export const indexQuery = createQuery({
  idx: codecs.number,
});

export const propertyQuery = createQuery({
  property: propertyCodec,
});
