import { codecs, Path } from "@pomle/paths";

export function createPaths<T extends {}>(origin: Path<T>) {
  const list = origin.append("/appointment", {});
  const view = list.append("/:bookingSlotId", {
    bookingSlotId: codecs.string,
  });

  return { list, view };
}
