import { Annotation } from "@cur8/rich-entity";
import LesionMarkerIcon from "./assets/lesion-marker.svg?react";
import styles from "./styles.module.sass";

interface LesionMarkerProps {
  active: boolean;
  selected: boolean;
  annotation: Annotation | undefined;
}

export default function LesionMarker({
  active,
  selected,
  annotation,
}: LesionMarkerProps) {
  return (
    <div
      className={styles.LesionMarker}
      data-active={active}
      data-selected={selected}
    >
      <LesionMarkerIcon />
    </div>
  );
}
