import { Haemoglobin } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import MetricRange from "render/ui/presentation/MetricRange";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface HaemoglobinRangeProps {
  patient: Patient;
  metrics: Metric<"bloodwork.haemoglobin">[];
}

export default function HaemoglobinRange({
  patient,
  metrics,
}: HaemoglobinRangeProps) {
  const riskRange = useMemo(() => {
    return Haemoglobin.rangesFor({ sex: patient.sex });
  }, [patient]);

  const entries = useMemo(() => {
    return metrics.map((metric) => {
      return {
        risk: riskRange.findRisk(metric.unit),
        timestamp: metric.measurement.timestampStart,
        value: metric.unit["g/L"],
      };
    });
  }, [metrics, riskRange]);

  const currentValue = entries.at(0)?.value;

  const ranges = useMemo(() => {
    return capToMaxRanges(toChartRanges(riskRange.entries), currentValue);
  }, [currentValue, riskRange]);

  return (
    <MetricRange
      title="Haemoglobin"
      unit="g/L"
      entries={entries}
      ranges={ranges}
    />
  );
}
