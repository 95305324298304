import { APITypesV1 } from "@cur8/api-client";

export function mergePaginatedResponseWith(
  currentPage: APITypesV1.SkinBookQueryResultPaginatedResponse
) {
  return (newPage: APITypesV1.SkinBookQueryResultPaginatedResponse) => {
    const newPageTaskGroupsGroupsMap = newPage.items.reduce((acc, val) => {
      return acc.set(val.taskGroup, val);
    }, new Map<string, APITypesV1.SkinBookQueryResult>());

    const currentPageGroupsMap = currentPage.items.reduce((acc, val) => {
      return acc.set(val.taskGroup, val);
    }, new Map<string, APITypesV1.SkinBookQueryResult>());

    const items: APITypesV1.SkinBookQueryResult[] = [
      ...currentPageGroupsMap.values(),
    ];

    // merge all items
    newPage.items.forEach((item) => {
      const itemGroup = newPageTaskGroupsGroupsMap.get(item.taskGroup);
      if (itemGroup) {
        const currentItems = items.find(
          (item) => item.taskGroup === itemGroup.taskGroup
        );

        if (currentItems) {
          currentItems.skinBookTrackings = [
            ...currentItems.skinBookTrackings,
            ...itemGroup.skinBookTrackings,
          ];
        } else {
          items.push(itemGroup);
        }
      }
    });

    return {
      nextPage: newPage.nextPage,
      items,
    };
  };
}
