import { sortBy } from "lib/sort";
import { Metric } from "../metric";

export const byTimestamp = sortBy<Metric>((m) =>
  m.measurement.timestampStart.valueOf()
);

type BrachialPressure = Metric<
  "cardio.brachial_pressure.left" | "cardio.brachial_pressure.right"
>;

type Pressure =
  | Metric<"cardio.toe_pressure.left" | "cardio.toe_pressure.right">
  | Metric<"cardio.ankle_pressure.left" | "cardio.ankle_pressure.right">;

export const bySystolic = sortBy<BrachialPressure>((b) => b.unit.systolic.mmHg);

export const byPressure = sortBy<Pressure>((p) => p.unit.mmHg);
