import { useEffect } from "react";
import { fromAPI } from "@cur8/rich-entity";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useReporting } from "../useReporting";

export function useRecording(
  deviceId: string | undefined,
  recordingId: string | undefined
) {
  const { handleError } = useReporting();

  const api = useAPIClient().recording;

  const { get, set } = useStore().recording.entries;

  useEffect(() => {
    if (!deviceId || !recordingId) {
      return;
    }
    api
      .fetchRecording({ deviceId, recordingId })
      .result.then(fromAPI.toRecording)
      .then((recording) => {
        set(recording.id, recording);
      })
      .catch(handleError);
  }, [set, api, deviceId, recordingId, handleError]);

  return recordingId ? get(recordingId).data ?? undefined : undefined;
}
