import { VisitSummary } from "@cur8/rich-entity";
import DoctorName from "./components/DoctorName/DoctorName";
import styles from "./style.module.sass";

interface SummaryProps {
  visit: VisitSummary;
}

export default function Summary({ visit }: SummaryProps) {
  return (
    <div className={styles.summary}>
      <label>Previous Summary</label>
      <div className={styles.time}>
        {visit.audit.lastModified?.userUri && (
          <>
            <DoctorName uri={visit.audit.lastModified?.userUri} />
            &nbsp;•&nbsp;
          </>
        )}
        {visit.visitDate.toFormat("dd LLLL yyyy")}
      </div>
      <div className={styles.text}>{visit.summaryText}</div>
    </div>
  );
}
