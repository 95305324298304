import { useQueryParams } from "@pomle/react-router-paths";
import { cycle } from "lib/algo";
import { ColorMap, Colormaps } from "lib/webgl/colorizer";
import { useCallback } from "react";
import {
  annotationIdQuery,
  bloodVesselsModeQuery,
  colormapQuery,
  imageTypeQuery,
  propertyQuery,
} from "../lib/tissueQueries";
import {
  BloodVesselsMode,
  BloodVesselsModes,
  ImageType,
  Property,
} from "../lib/types";

export function useQueries() {
  const [bloodVesselsModeParam, setBloodVesselsModeParam] = useQueryParams(
    bloodVesselsModeQuery
  );
  const bloodVesselsMode =
    bloodVesselsModeParam.bvmode.at(0) ?? BloodVesselsMode.inactive;

  const [colormapParam, setColormapParam] = useQueryParams(colormapQuery);
  const colormap = colormapParam.colormap.at(0) ?? ColorMap.Magma;

  const [imageTypeParam, setImageTypeParam] = useQueryParams(imageTypeQuery);
  const imageType = imageTypeParam.type.at(0) ?? ImageType.normal;

  const [propertyParams, setPropertyParam] = useQueryParams(propertyQuery);
  const property = propertyParams.property.at(0) || Property.t1BloodVolume;

  const [selectedAnnoParam, setAnnoIdParam] = useQueryParams(annotationIdQuery);
  const selectedAnnoId = selectedAnnoParam.anno.at(0) ?? undefined;

  // Annotation
  const setSelectedAnno = useCallback(
    (id: string | undefined) => {
      setAnnoIdParam({
        anno: id ? [id] : [],
      });
    },
    [setAnnoIdParam]
  );

  // Blood vessels mode
  const setBloodVesselsMode = useCallback(
    (mode: BloodVesselsMode) => {
      setBloodVesselsModeParam({
        bvmode: [mode],
      });
    },
    [setBloodVesselsModeParam]
  );
  const cycleBloodVesselsMode = useCallback(() => {
    setBloodVesselsMode(cycle(BloodVesselsModes, bloodVesselsMode));
  }, [bloodVesselsMode, setBloodVesselsMode]);

  // Color map
  const setColormap = useCallback(
    (map: ColorMap) => {
      setColormapParam({
        colormap: [map],
      });
    },
    [setColormapParam]
  );
  const cycleColormap = useCallback(() => {
    setColormap(cycle(Colormaps, colormap));
  }, [colormap, setColormap]);

  // Image Type
  const setImageType = useCallback(
    (type: ImageType) => {
      setImageTypeParam({
        type: [type],
      });
    },
    [setImageTypeParam]
  );
  const cycleImageType = useCallback(
    (isPAD = false) => {
      let imageCycle = [
        ImageType.normal,
        ImageType.highContrast,
        ImageType.rgb,
      ];
      if (property === Property.thermal) {
        imageCycle = [ImageType.normal, ImageType.highContrast];
      }
      if (isPAD) {
        imageCycle = [ImageType.normal, ImageType.rgb];
      }
      setImageType(cycle(imageCycle, imageType));
    },
    [imageType, property, setImageType]
  );

  // Property query
  const setProperty = useCallback(
    (prop: Property) => {
      setPropertyParam({
        property: [prop],
      });
      // Thermal don't have RGB, so switch ImageType
      if (prop === Property.thermal && imageType === ImageType.rgb) {
        setImageType(ImageType.normal);
      }
      // Clear selected annotation on property change
      // TODO - Perhaps allow selection to persist?
      setSelectedAnno(undefined);
    },
    [imageType, setImageType, setPropertyParam, setSelectedAnno]
  );

  return {
    bloodVesselsMode,
    colormap,
    cycleBloodVesselsMode,
    cycleColormap,
    cycleImageType,
    imageType,
    setImageType,
    property,
    selectedAnnoId,
    setBloodVesselsMode,
    setColormap,
    setProperty,
    setSelectedAnno,
  };
}
