import { Patient } from "@cur8/rich-entity";
import { useAssessmentNav } from "../../hooks/useAssessmentNav";
import EjectionTime from "./components/EjectionTime";
import FootAsymmetry from "./components/FootAsymmetry";
import HandAsymmetry from "./components/HandAsymmetry";
import PWV from "./components/PWV";
import { PulsewaveType } from "./components/shared/types";
import { CardioSignals } from "./hooks/useCardioSignals";
import styles from "./styles.module.sass";

interface PulseWavePageProps {
  patient: Patient;
  signals: CardioSignals | undefined;
}

export default function PlotSelector({ patient, signals }: PulseWavePageProps) {
  const { pulsewaveType } = useAssessmentNav();
  return (
    <div className={styles.plotComponent}>
      {pulsewaveType === PulsewaveType.lveti && (
        <EjectionTime patient={patient} signals={signals} />
      )}
      {pulsewaveType === PulsewaveType.pulseWaveVelocity && (
        <PWV patientId={patient.patientId} signals={signals} />
      )}
      {pulsewaveType === PulsewaveType.handAsymmetry && (
        <HandAsymmetry signals={signals} />
      )}
      {pulsewaveType === PulsewaveType.footAsymmetry && (
        <FootAsymmetry signals={signals} />
      )}
    </div>
  );
}
