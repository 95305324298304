import { useCallback } from "react";
import PatientName from "render/fragments/patient/PatientName";
import { usePatient } from "render/hooks/api/usePatient";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import PopupDialog from "render/ui/layouts/PopupDialog";
import ActionButton from "render/ui/trigger/ActionButton";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { usePatientBookingToken } from "../../hooks/usePatientBookingToken";
import styles from "./styles.module.sass";

interface Props {
  patientId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export default function CreateBookingTokenPopup({
  patientId,
  onClose,
  onSuccess,
}: Props) {
  const patient = usePatient(patientId);
  const { create } = usePatientBookingToken(patientId);

  const handleSubmit = useCallback(async () => {
    return await create().then(() => {
      onSuccess();
      setTimeout(onClose, 200); // close modal
    });
  }, [create, onSuccess, onClose]);

  const submitHandler = useAsyncHandle(handleSubmit);

  return (
    <PopupDialog>
      <div className={styles.CreateBookingTokenPopup}>
        <div className={styles.content}>
          <header>
            <h2>Booking token will be created</h2>
          </header>
          <section className={styles.body}>
            <p>
              Members need to have an active booking token to be able to book an
              appointment.
            </p>
            <p>
              Click below to create a token for{" "}
              {patient ? <PatientName patient={patient} /> : patientId}
            </p>
          </section>
          <footer className={styles.footer}>
            <HoverTextButton type="button" onClick={onClose}>
              Cancel
            </HoverTextButton>

            <ActionButton disabled={submitHandler.busy} handle={submitHandler}>
              Create booking token
            </ActionButton>
          </footer>
        </div>
      </div>
    </PopupDialog>
  );
}
