import * as Risks from "@cur8/health-risks-calc";
import { highestRisk, Risk } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { Metric } from "lib/metric";
import { useMemo } from "react";
import { BMIScore } from "render/hooks/api/metrics/useBMIScore";
import { useAge } from "render/hooks/patient/useAge";
import MetricStatus from "render/ui/presentation/MetricStatus";
import MetricStatusBadge from "render/ui/presentation/MetricStatusBadge";
import DiabetesIcon from "./assets/diabetes.svg?react";
import { calcGlucoseRisk } from "./risk";

interface DiabetesItemItemProps {
  patient: Patient;

  bloodwork: {
    glucose: Metric<"bloodwork.glucose">[] | undefined;
    hba1c: Metric<"bloodwork.hba1c">[] | undefined;
  };

  body: {
    height: Metric<"body.height">[] | undefined;
    weight: Metric<"body.weight">[] | undefined;
    waist: Metric<"body.waist">[] | undefined;
  };

  bmi: BMIScore | undefined;
}

export default function DiabetesItem({
  patient,
  bloodwork: { glucose, hba1c },
  body: { waist },
  bmi,
}: DiabetesItemItemProps) {
  const age = useAge(patient);
  const sex = patient.sex;

  const risk = useMemo(() => {
    if (!glucose || !hba1c || !bmi || !waist) {
      return;
    }

    const risks = [Risk.Unknown];

    if (glucose.length > 0) {
      const risk = calcGlucoseRisk(glucose[0]);
      if (risk != null) {
        risks.push(risk);
      }
    }

    if (hba1c.length > 0) {
      const risk = Risks.HbA1c.rangesFor({ age }).findRisk(hba1c[0].unit);
      risks.push(risk);
    }

    if (isFinite(bmi.score)) {
      const risk = Risks.BMI.rangesFor({ age }).findRisk({ bmi: bmi.score });
      risks.push(risk);
    }

    if (waist.length > 0) {
      const risk = Risks.Waistline.rangesFor({ sex }).findRisk({
        cm: waist[0].unit.meters * 100,
      });
      risks.push(risk);
    }

    return highestRisk(risks);
  }, [sex, age, glucose, hba1c, bmi, waist]);

  return (
    <MetricStatus
      caption="Diabetes factors"
      summaryContent={risk != null ? <MetricStatusBadge risk={risk} /> : "--"}
    >
      <DiabetesIcon />
    </MetricStatus>
  );
}
