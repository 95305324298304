import { PaginatedResponse } from "@cur8/api-client";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import AssignmentsTable from "render/fragments/assignments/AssignmentsTable";
import { Assignment, fromAPI } from "@cur8/rich-entity";
import HoverTextButton from "render/ui/trigger/HoverTextButton/HoverTextButton";

export default function AssignmentListPage() {
  const api = useAPIClient();
  const [assignments, setAssignments] =
    useState<PaginatedResponse<Assignment>>();

  const requestAssignments = useCallback(
    ({ continuationToken }: { continuationToken?: string }) => {
      const req = api.assignment.queryAssignments({ continuationToken });
      req.result.then(({ items, nextPage }) => {
        setAssignments((v) => {
          return {
            nextPage,
            items: [...(v?.items ?? []), ...items.map(fromAPI.toAssignment)],
          };
        });
      });

      return req;
    },
    [api]
  );

  useEffect(() => {
    const req = requestAssignments({ continuationToken: undefined });

    return req.abandon;
  }, [requestAssignments]);

  return (
    <FramedPage>
      <div>
        <PageHeader caption="Assignments" />
        {assignments?.items && (
          <AssignmentsTable assignments={assignments.items} />
        )}
        {assignments?.nextPage && (
          <HoverTextButton
            onClick={() => {
              requestAssignments({ continuationToken: assignments.nextPage });
            }}
          >
            Load more
          </HoverTextButton>
        )}
      </div>
    </FramedPage>
  );
}
