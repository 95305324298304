import { useEffect, useState } from "react";
import { useRangeContext } from "../../context/RangeContext";
import { CardioSignals } from "../../hooks/useCardioSignals";
import { ECG_COLOR, LEFT_COLOR, RIGHT_COLOR } from "../shared/constants";
import { JSXResult } from "../shared/fragments";
import GraphWithRuler from "../shared/GraphWithRuler";
import { rangeToDistance } from "../shared/utils";

interface HandAsymmetryProps {
  signals: CardioSignals | undefined;
}

export default function HandAsymmetry({ signals }: HandAsymmetryProps) {
  const { selectionRange, setMeasurement, windowRange } = useRangeContext();
  const [resultElement, setResultElement] = useState<JSX.Element | null>(
    JSXResult("", "Make a selection in the plot to measure delay")
  );

  useEffect(() => {
    if (!signals || !selectionRange || selectionRange[0] >= selectionRange[1]) {
      setResultElement(
        JSXResult("", "Make a selection in the plot to measure delay")
      );
      return;
    }
    const distanceMs = rangeToDistance(
      windowRange,
      selectionRange,
      signals.ecg.lengthMs
    );

    setMeasurement(distanceMs);
    setResultElement(JSXResult(`Delay: ${distanceMs} ms`));
  }, [selectionRange, setMeasurement, signals, windowRange]);

  return signals ? (
    <div>
      <GraphWithRuler
        data={[
          {
            label: "Cardiac Rhythm",
            signal: signals.ecg,
            color: ECG_COLOR,
          },
          {
            label: "Left",
            signal: signals.piezo_hand_left,
            color: LEFT_COLOR,
          },
          {
            label: "Right",
            signal: signals.piezo_hand_right,
            color: RIGHT_COLOR,
          },
        ]}
        diffData={[
          {
            label: "Left Acceleration",
            signal: signals.piezo_hand_left_acc,
            color: LEFT_COLOR,
          },
          {
            label: "Right Acceleration",
            signal: signals.piezo_hand_right_acc,
            color: RIGHT_COLOR,
          },
        ]}
      />
      {resultElement}
    </div>
  ) : null;
}
