import { PathLink } from "@pomle/react-router-paths";
import ArrowIcon from "render/ui/symbol/ArrowIcon";
import classes from "./styles.module.sass";

interface BackButtonProps {
  to: string;
  children: React.ReactNode;
}

export default function BackButtonLink({
  children,
  ...props
}: BackButtonProps) {
  return (
    <PathLink className={classes.BackButtonLink} {...props}>
      <ArrowIcon /> {children}
    </PathLink>
  );
}
