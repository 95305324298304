export interface Box {
  x: number;
  y: number;
  width: number;
  height: number;
}

export const extraSmall: Box[] = [
  ...createBlocks({ height: 240, width: 240, x: 1, y: 1, columns: 1, rows: 1 }),
  ...createBlocks({
    height: 160,
    width: 160,
    x: 241,
    y: 1,
    columns: 1,
    rows: 1,
  }),
  ...createBlocks({
    height: 80,
    width: 80,
    x: 241,
    y: 161,
    columns: 2,
    rows: 1,
  }),
];

export const small: Box[] = [
  ...createBlocks({ x: 1, y: 1, height: 160, width: 160, columns: 1, rows: 1 }),
  ...createBlocks({ x: 161, y: 1, height: 80, width: 80, columns: 3, rows: 1 }),
  ...createBlocks({
    x: 161,
    y: 81,
    height: 80,
    width: 80,
    columns: 1,
    rows: 1,
  }),
  ...createBlocks({ x: 1, y: 161, height: 80, width: 80, columns: 3, rows: 1 }),
  ...createBlocks({
    x: 241,
    y: 81,
    height: 160,
    width: 160,
    columns: 1,
    rows: 1,
  }),
];

const medium: Box[] = [
  ...createBlocks({ x: 1, y: 1, height: 80, width: 80, columns: 5, rows: 3 }),
];

const large: Box[] = [
  ...createBlocks({ x: 1, y: 1, height: 80, width: 80, columns: 5, rows: 1 }),
  ...createBlocks({ x: 1, y: 81, height: 80, width: 80, columns: 4, rows: 1 }),
  ...createBlocks({
    x: 321,
    y: 81,
    height: 40,
    width: 40,
    columns: 2,
    rows: 2,
  }),
  ...createBlocks({ x: 1, y: 161, height: 80, width: 80, columns: 3, rows: 1 }),
  ...createBlocks({
    x: 241,
    y: 161,
    height: 40,
    width: 40,
    columns: 4,
    rows: 4,
  }),
];

const larger: Box[] = [
  ...createBlocks({ height: 80, width: 80, x: 1, y: 1, columns: 5, rows: 1 }),
  ...createBlocks({
    height: 80,
    width: 80,
    x: 1,
    y: 81,
    columns: 3,
    rows: 1,
  }),
  ...createBlocks({
    height: 40,
    width: 40,
    x: 241,
    y: 81,
    columns: 6,
    rows: 2,
  }),
  ...createBlocks({
    height: 40,
    width: 40,
    x: 1,
    y: 161,
    columns: 10,
    rows: 2,
  }),
];

const extraLarge: Box[] = [
  ...createBlocks({ height: 40, width: 40, x: 1, y: 1, columns: 10, rows: 6 }),
];

const dense: Box[] = [
  ...createBlocks({ height: 80, width: 80, x: 1, y: 1, columns: 4, rows: 1 }),
  ...createBlocks({ height: 40, width: 40, x: 321, y: 1, columns: 2, rows: 2 }),
  ...createBlocks({ height: 40, width: 40, x: 1, y: 81, columns: 10, rows: 4 }),
];

function createBlocks({
  x,
  y,
  width,
  height,
  rows,
  columns,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  columns: number;
  rows: number;
}) {
  const result: Box[] = [];

  for (let i = 0; i < columns; i++) {
    for (let k = 0; k < rows; k++) {
      result.push({ height, width, x: x + width * i, y: y + height * k });
    }
  }

  return result;
}

export const Coordinates = {
  extraSmall,
  small,
  medium,
  large,
  dense,
  larger,
  extraLarge,
};
