import { APITypesV1 } from "@cur8/api-client";
import {
  Annotation,
  BoundingBoxAnnotation,
  Box,
  ImmutableScan,
  LineAnnotation,
  RangeAnnotation,
  hasBoundingBox,
  hasLine,
  hasRange,
} from "@cur8/rich-entity";
import { APIClient } from "lib/api/client";
import { Line, Point } from "lib/math";
import { TissueURI } from "./TissueURI";
import {
  BloodVesselsMask,
  ImageSize,
  LCJSPoint,
  Property,
  PropertyRange,
  TargetProperty,
  Tick,
  TissueAnnotation,
  TissueAnnotationDataType,
  TissueAnnotationTypes,
} from "./types";

export function compareTimeAnnotations(
  a: TissueAnnotation<RangeAnnotation>,
  b: TissueAnnotation<RangeAnnotation>
): number {
  if (a.annotation.data.range.to < b.annotation.data.range.to) {
    return -1;
  }
  if (a.annotation.data.range.to > b.annotation.data.range.to) {
    return 1;
  }
  return 0;
}

export async function fetchChromophoreTimeseries(
  api: APIClient,
  chromophore: Property,
  rect: Box,
  scan: ImmutableScan,
  bvmask?: BloodVesselsMask
) {
  const transcoder = api.transcode;
  return await transcoder.fetchChromophoreTimeseries(
    {
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
      chromophore,
    },
    rect,
    bvmask?.threshold,
    bvmask?.extract
  ).result;
}

/**
 * Clean, trim and re-factor time series and return as points
 */
export function cleanAndTrimSeries(
  series: number[],
  indexRemap: number[],
  timestamps: number[],
  factor: number | undefined
): LCJSPoint[] {
  const rangeFactor = factor ?? 1;
  const points = [] as LCJSPoint[];
  // Remap series
  for (let idx of indexRemap) {
    // Verify the timestamp exists
    if (timestamps[idx] !== undefined) {
      points.push({
        x: timestamps[idx],
        y: series[idx] * rangeFactor,
      });
    }
  }

  // Trim leading/ending zero-Y
  let start = 0;
  let end = points.length - 1;

  while (start < points.length && points[start].y === 0) {
    start++;
  }
  while (end >= 0 && points[end].y === 0) {
    end--;
  }

  return points.slice(start, end + 1);
}

export function property2TargetProperty(property: Property) {
  if (property === Property.thermal) {
    return TargetProperty.thermal;
  }
  return TargetProperty.nonthermal;
}

export function toTissueAnnotation(
  anno: Annotation,
  count: number = 0
): TissueAnnotation {
  let uri = TissueURI.parse(anno.applicationSpecificTarget);
  if (!uri) {
    // Migrate old annotations, where <applicationSpecificTarget> only contained the label.
    // <applicationSpecificTarget> now contains a TissueURI
    uri = new TissueURI(
      TargetProperty.nonthermal,
      anno.applicationSpecificTarget ?? ""
    );
  }
  return {
    annotation: anno as TissueAnnotationTypes,
    color: getRoIColor(count),
    label: uri.label,
    property: uri.property,
  };
}

export function isTissueAnnotationOfType<T extends TissueAnnotationTypes>(
  annotation: TissueAnnotation,
  type: TissueAnnotationDataType
): annotation is TissueAnnotation<T> {
  switch (type) {
    case TissueAnnotationDataType.Line:
      return hasLine(annotation.annotation);
    case TissueAnnotationDataType.Region:
      return hasBoundingBox(annotation.annotation);
    case TissueAnnotationDataType.Time:
      return hasRange(annotation.annotation);
    default:
      return false;
  }
}
export function filterLines(
  annos: TissueAnnotation[],
  property: Property
): TissueAnnotation<LineAnnotation>[] {
  return filterAnnotations<LineAnnotation>(
    annos,
    property,
    TissueAnnotationDataType.Line
  );
}
export function filterRegions(
  annos: TissueAnnotation[],
  property: Property
): TissueAnnotation<BoundingBoxAnnotation>[] {
  return filterAnnotations<BoundingBoxAnnotation>(
    annos,
    property,
    TissueAnnotationDataType.Region
  );
}
export function filterTimes(
  annos: TissueAnnotation[]
): TissueAnnotation<RangeAnnotation>[] {
  return annos
    .filter((a) => {
      return isTissueAnnotationOfType<RangeAnnotation>(
        a,
        TissueAnnotationDataType.Time
      );
    })
    .map((a) => a as TissueAnnotation<RangeAnnotation>);
}
export function filterAnnotations<T extends TissueAnnotationTypes>(
  annos: TissueAnnotation[],
  property: Property,
  type: TissueAnnotationDataType
): TissueAnnotation<T>[] {
  const targetProp = property2TargetProperty(property);
  return annos
    .filter((a) => {
      if (a.property !== targetProp) {
        return false;
      }
      return isTissueAnnotationOfType<T>(a, type);
    })
    .map((a) => a as TissueAnnotation<T>);
}

export function countDecimals(value: number): number {
  if (Math.floor(value) === value) {
    return 0;
  }
  return value.toString().split(".")[1].length || 0;
}

export function countNumbers(value: number): number {
  if (Math.floor(value) === value) {
    return 0;
  }
  return value.toString().split(".")[0].length || 0;
}

/**
 * Parse ranges in metadata given the version
 */
export function rangeParser(
  range: PropertyRange,
  version: number | undefined
): PropertyRange {
  const ret: PropertyRange = {
    low: range.low,
    high: range.high,
    unit: range.unit,
    displayUnit: range.unit,
    factor: 1,
  };
  if (version) {
    if (version >= 4) {
      switch (range.unit) {
        case "fr":
          ret.displayUnit = "%";
          ret.factor = 100;
          break;
        case "cm":
          ret.displayUnit = "mm";
          ret.factor = 10;
          break;
        case "C":
          ret.displayUnit = "°C";
          ret.factor = 1;
          break;
      }
    } else {
      // version 1-3 has the same error
      switch (range.unit) {
        case "cm":
        case "mm":
          // Incorrect unit => switch to "cm"
          ret.unit = "cm";
          ret.displayUnit = "mm";
          ret.factor = 10;
          break;
        case "%":
          // Incorrect unit, switch to "fr"
          ret.unit = "fr";
          ret.factor = 100;
          break;
      }
    }
  } else {
    // Unversioned => Here be dragons
    switch (range.unit) {
      case "mm":
        /**
         * Special haxx:
         * The image range is off by 10 (compared to range)
         * - divide min/max by 10 here and set factor to 100
         * - Re-label as cm
         */
        ret.unit = "cm";
        ret.displayUnit = "mm";
        ret.low /= 10;
        ret.high /= 10;
        ret.factor = 100;
        break;
      case "%":
        // Convert from % to fr
        ret.unit = "fr";
        ret.displayUnit = "%";
        ret.factor = 100;
        ret.low /= 100;
        ret.high /= 100;
        break;
    }
  }
  return ret;
}

export function calculateTicks(
  min: number,
  max: number,
  tickCount: number,
  scale: number = 1
): Tick[] {
  const ticks = [] as Tick[];
  const span = max - min;
  let step = Math.pow(10, Math.floor(Math.log(span / tickCount) / Math.LN10));
  const err = (tickCount / span) * step;

  let decimals = Math.max(countDecimals(min), countDecimals(max));
  const numbers = Math.max(countNumbers(min), countNumbers(max));
  const range = max - min;
  if (decimals > 2) {
    decimals = 2;
  }
  if (numbers > 1) {
    if (range <= 2) {
      decimals = 1;
    } else {
      decimals = 0;
    }
  }

  // Filter ticks to get closer to the desired count.
  if (err <= 0.15) {
    step *= 10;
  } else if (err <= 0.35) {
    step *= 5;
  } else if (err <= 0.75) {
    step *= 2;
  }

  // Round start and stop values to step interval.
  const tstart = Math.ceil(min / step) * step;
  const tstop = Math.floor(max / step) * step + step * 0.5;

  // Generate tick
  for (let i = tstart; i < tstop; i += step) {
    ticks.push({
      label: i.toFixed(decimals),
      bottom: (i / span - min / span) * scale,
    });
  }
  return ticks;
}

export const RoIColors = [
  "#06b8a6",
  "#fec4bf",
  "#80e8fe",
  "#34a853",
  "#dc493a",
  "#ff9760",
  "#d6b2fe",
  "#b7febe",
  "#f0bc8c",
  "#4392f0",
];
export function getRoIColor(index: number): string {
  let colorIdx = index;
  if (index >= RoIColors.length) {
    colorIdx = index % RoIColors.length;
  }
  return RoIColors[colorIdx];
}
export function getColorName(color: string = "#06b8a6"): string {
  return `color${RoIColors.indexOf(color)}`;
}

/** Max size of a RoI-box */
export const MAX_BOXSIZE = 128;

export function checkRoIMaxSize(a: Point, b: Point): Line {
  const distX = Math.abs(a.x - b.x);
  const distY = Math.abs(a.y - b.y);
  if (distX > MAX_BOXSIZE) {
    b.x = a.x + MAX_BOXSIZE * (b.x > a.x ? 1 : -1);
  }
  if (distY > MAX_BOXSIZE) {
    b.y = a.y + MAX_BOXSIZE * (b.y > a.y ? 1 : -1);
  }
  return new Line(a, b);
}

export function roiWithinBoundry(box: Box, imgSize: ImageSize) {
  if (box.x < 0 || box.y < 0) {
    return false;
  }
  if (box.x + box.w > imgSize.width || box.y + box.h > imgSize.height) {
    return false;
  }
  return true;
}

export function lineWithinBoundry(line: Line, imgSize: ImageSize) {
  return roiWithinBoundry(line.toBox(), imgSize);
}

export function boxTopAnchor(box: Box): Point {
  return new Point(box.x + box.w / 2, box.y + box.h);
}

export function line2DtoLine(line: APITypesV1.Line2D): Line {
  return new Line(new Point(line.a.x, line.a.y), new Point(line.b.x, line.b.y));
}
