import { Length } from "@cur8/measurements";
import { UnitConverter, UnitSystem } from "./UnitConverter";

export function toFeet(height: Length) {
  return {
    feet: height.meters * 3.280839895,
  };
}

export const Converters: Record<
  UnitSystem,
  UnitConverter<"meters" | "feet">
> = {
  metric: new UnitConverter({
    unit: "meters",
  }),
  imperial: new UnitConverter({
    unit: "feet",
  }),
};
