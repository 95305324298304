import { useState } from "react";
import { Model, Settings } from "../../types";
import Portal from "../Portal";
import styles from "./SettingsDialog.module.sass";

interface SettingsProps {
  onClose: (settings: Settings) => void;
  settings: Settings;
}

export default function SettingsDialog(props: SettingsProps) {
  const [settings, setSettings] = useState(props.settings);

  const handleModelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      model: event.target.value as Settings["model"],
    });
  };

  const handleClose = () => {
    props.onClose(settings);
  };

  return (
    <Portal title="Settings" onClose={handleClose}>
      <div className={styles.content}>
        <div>
          <div className={styles.radio}>
            <p>
              <strong>Model</strong>
            </p>
            <label>
              <input
                type="radio"
                value={Model.GPT35Turbo16k}
                checked={settings.model === Model.GPT35Turbo16k}
                onChange={handleModelChange}
              />
              GPT-3.5 Turbo 16k
            </label>
            <label>
              <input
                type="radio"
                value={Model.GPT4}
                checked={settings.model === Model.GPT4}
                onChange={handleModelChange}
              />
              GPT-4
            </label>
            <label>
              <input
                type="radio"
                value={Model.GPT432k}
                checked={settings.model === Model.GPT432k}
                onChange={handleModelChange}
              />
              GPT-4 32k
            </label>
          </div>
        </div>
      </div>
    </Portal>
  );
}
