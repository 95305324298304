import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

export function useDiscountToken(discountCode: string) {
  const { logError } = useReporting();
  const apiClient = useAPIClient();
  const [discountToken, setDiscountToken] =
    useState<APITypesV1.DiscountToken>();

  const fetch = useCallback(async () => {
    return apiClient.billing
      .getDiscountTokenCode(discountCode)
      .result.then((result) => {
        setDiscountToken(result);
      })
      .catch(logError);
  }, [apiClient, logError, discountCode]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return {
    discountToken: useMemo(() => discountToken, [discountToken]),
    fetch,
  };
}
