import { Assessment, AssessmentTypeName, Patient } from "@cur8/rich-entity";
import PatientAge from "render/fragments/patient/PatientAge";
import PatientName from "render/fragments/patient/PatientName";
import PatientPhysicalIdentifier from "render/fragments/patient/PatientPhysicalIdentifier";
import styles from "./styles.module.sass";

interface AssessmentHeaderProps {
  patient: Patient;
  assessment: Assessment;
}

export default function AssessmentHeader({
  patient,
  assessment,
}: AssessmentHeaderProps) {
  let title = "Unsupported Assessment";
  switch (assessment.itemTypeName) {
    case AssessmentTypeName.Pad:
      title = "PAD Assessment";
      break;
    case AssessmentTypeName.PulseWave:
      title = "Pulsewave Assessment";
      break;
  }
  return (
    <div className={styles.AssessmentHeader}>
      <div className={styles.pageTitle}>{title}</div>
      <div className={styles.PatientSummary}>
        <div className={styles.identity}>
          <div className={styles.name}>
            <PatientName patient={patient} />
          </div>
          <div>
            <PatientPhysicalIdentifier patient={patient} />
            {patient.dateOfBirth && (
              <>
                {" "}
                (<PatientAge patient={patient} /> years)
              </>
            )}
          </div>
        </div>

        <div className={styles.date}>Scan: {assessment.scanId}</div>
      </div>
    </div>
  );
}
