import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { silenceAbort } from "lib/error";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { NormalizedSignal, useNormalizedSignal } from "./useNormalizedSignal";

type SignalPair = {
  signal: NormalizedSignal;
  acc: NormalizedSignal;
};

export function useSirad(scan: ImmutableScan | undefined) {
  const api = useAPIClient().scan;

  const [bestChannel, setBestChannel] = useState<number>();
  const [quality, setQuality] = useState<APITypesV1.SiradQualityResultData>();

  const signals: SignalPair[] = [
    {
      signal: useNormalizedSignal(scan, "sirad0_displacement"),
      acc: useNormalizedSignal(scan, "sirad0_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad1_displacement"),
      acc: useNormalizedSignal(scan, "sirad1_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad2_displacement"),
      acc: useNormalizedSignal(scan, "sirad2_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad3_displacement"),
      acc: useNormalizedSignal(scan, "sirad3_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad4_displacement"),
      acc: useNormalizedSignal(scan, "sirad4_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad5_displacement"),
      acc: useNormalizedSignal(scan, "sirad5_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad6_displacement"),
      acc: useNormalizedSignal(scan, "sirad6_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad7_displacement"),
      acc: useNormalizedSignal(scan, "sirad7_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad8_displacement"),
      acc: useNormalizedSignal(scan, "sirad8_acceleration"),
    },
    {
      signal: useNormalizedSignal(scan, "sirad9_displacement"),
      acc: useNormalizedSignal(scan, "sirad9_acceleration"),
    },
  ];

  useEffect(() => {
    if (!scan) {
      return;
    }

    const request = api.fetchScanResult({
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
      resultName: "sirad_signal_quality",
    });

    request.result
      .then((r) => {
        const data = r.data as APITypesV1.SiradQualityResultData;
        if (data.siradQuality && data.siradQuality.length > 0) {
          setBestChannel(data.siradQuality[0].channel);
          setQuality(data);
        }
      })
      .catch(silenceAbort);

    return () => {
      setBestChannel(undefined);
      setQuality(undefined);
      request.abandon();
    };
  }, [api, scan]);

  return { signals, bestChannel, quality };
}
