import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface ValueBoxProps {
  caption: React.ReactNode;
  value: React.ReactNode;
}

export default function ValueBox({ caption, value }: ValueBoxProps) {
  return (
    <div className={styles.ValueBox}>
      <Typography variant="label-m" className={styles.caption}>
        {caption}
      </Typography>
      <div className={styles.value}>{value}</div>
    </div>
  );
}
