const replacements: [string, string][] = [
  ["hälsorese", "hälsoresa"],
  ["grip styrka", "greppstyrka"],
  ["gripstyrka", "greppstyrka"],
];

/**
 * This function post-processes a given text to replace certain words, preserving the case of the first character and an optional "n" at the end of the word.
 * Each pair of words in the replacements array consists of an old value and a new value.
 * The function goes through the text and replaces all instances of the old value with the new value.
 * It uses a regular expression for matching, which is case-insensitive ("i" flag) and global ("g" flag, for replacing all instances, not just the first one).
 * If the word ends with "n", it keeps the "n" in the replacement.
 * Additionally, it checks the case of the first letter of the match and applies the same case to the first letter of the replacement.
 * @param {string} text - The text to be post-processed.
 * @returns {string} - The post-processed text.
 */

export function postProcess(text: string): string {
  for (const [oldValue, newValue] of replacements) {
    const regex = new RegExp(`(${oldValue})n?`, "gi");
    text = text.replace(regex, (match, p1) => {
      let replacedValue =
        p1.toLowerCase() === oldValue.toLowerCase() && match.endsWith("n")
          ? `${newValue}n`
          : newValue;
      return match[0] === match[0].toUpperCase()
        ? replacedValue.charAt(0).toUpperCase() + replacedValue.slice(1)
        : replacedValue;
    });
  }
  return text;
}

const greetings = ["hi", "hello", "hej"];

export function dropGreeting(text: string) {
  const parts = text.split("\n\n");
  if (parts.length > 0) {
    const tLow = parts[0].toLowerCase();
    for (const greeting of greetings) {
      if (tLow.startsWith(greeting)) {
        return parts.slice(1).join("\n\n");
      }
    }
  }
  return text;
}
