import { Annotation, Patient, Recording } from "@cur8/rich-entity";
import { Side } from "lib/api/types";
import { useCallback } from "react";
import { useKeyboardPress } from "render/hooks/useKeyboardPress";
import BoundingBoxAnnotator from "./components/BoundingBoxAnnotator";
import styles from "./styles.module.sass";

interface ScanExplorerProps {
  patient: Patient;
  recording: Recording;
  annotations: Annotation[];
  detectedAnnotations: Annotation[];
  reloadAnnotations: () => void;
  side: Side;
  cameraName: string;
  onExit: () => void;
}

export default function ScanExplorer({
  patient,
  recording,
  annotations,
  detectedAnnotations,
  reloadAnnotations,
  side,
  cameraName,
  onExit,
}: ScanExplorerProps) {
  const handleKeyboard = useCallback(
    (event: KeyboardEvent) => {
      if (event.code === "ArrowDown") {
        onExit();
      }
    },
    [onExit]
  );

  useKeyboardPress(handleKeyboard);

  return (
    <div className={styles.ScanExplorer}>
      <BoundingBoxAnnotator
        patient={patient}
        recording={recording}
        annotations={annotations}
        detectedAnnotations={detectedAnnotations}
        reloadAnnotations={reloadAnnotations}
        side={side}
        cameraName={cameraName}
        onQuit={onExit}
      />
    </div>
  );
}
