import { clamp } from "lib/math";
import { ReactNode, useMemo } from "react";
import Marker from "render/ui/presentation/ChartMarker/Marker";
import { Typography } from "../Typography";
import { concatPercent, normalize } from "./lib/util";
import styles from "./styles.module.sass";
import { MarkerData, RangeData } from "./types";

interface Bounds {
  min: number;
  max: number;
}

interface RangeChartCompactProps {
  ranges: RangeData[];
  value: MarkerData;
  bounds?: Bounds;
  labels: { from: ReactNode; to: ReactNode };
}

/**
 * Compact version of the RangeChart
 * - Shows a single (latest) value
 * - Shows the marker on a single min-max range (explicit or derived from chart range groups)
 * - Highlights the marker with the corresponding risk range variant (e.g. "warning")
 */
export default function RangeChartCompact({
  ranges,
  value: marker,
  bounds,
  labels,
}: RangeChartCompactProps) {
  const sortedRanges = useMemo(
    () => ranges.sort((a, b) => a.to - b.to),
    [ranges]
  );

  // explicit range has priority over the derived one
  const min = useMemo(
    () => bounds?.min ?? Math.min(...sortedRanges.map((range) => range.from)),
    [bounds, sortedRanges]
  );
  const max = useMemo(
    () => bounds?.max ?? Math.max(...sortedRanges.map((range) => range.to)),
    [bounds, sortedRanges]
  );

  const markerVariant = useMemo(() => {
    if (!marker) {
      return "none";
    }

    const range = sortedRanges.find((range, i) => {
      const isFirstRange = i === 0;
      const isLastRange = i === sortedRanges.length - 1;

      if (isFirstRange && marker.value < range.from) {
        return true;
      }
      if (isLastRange && marker.value > range.to) {
        return true;
      }

      return marker.value >= range.from && marker.value <= range.to;
    });

    return range ? range.variant : "none";
  }, [marker, sortedRanges]);

  const shouldClampRight = normalize(marker.value, { min, max }) > 0.95;
  const markerPositionStyle = useMemo(
    () =>
      shouldClampRight
        ? { right: 0 }
        : {
            left: concatPercent(
              normalize(clamp(marker.value, min, max), { min, max }) * 100
            ),
          },
    [marker.value, min, max, shouldClampRight]
  );

  return (
    <div className={styles.RangeChart}>
      <div className={styles.Container}>
        <div className={styles.Range}>
          <div className={styles.Marker} style={markerPositionStyle}>
            <Marker variant="squircle" highlight={markerVariant} />
            <div className={styles.value}>
              <Typography variant="label-m">{marker.label}</Typography>
            </div>
          </div>
        </div>
        <div className={styles.RangeLabels}>
          <div className={styles.LeftLabel}>
            <Typography variant="label-m">{labels.from}</Typography>
          </div>
          <div className={styles.RightLabel}>
            <Typography variant="label-m">{labels.to}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
