function lerp(from: number, to: number, p: number) {
  return (to - from) * p + from;
}

export function toPolyline(values: { x: number; y: number }[]) {
  return values
    .map(({ x, y }) => {
      return [x, y].join(",");
    })
    .join(" ");
}

type Pos = { x: number; y: number };

function abs(pos: Pos) {
  return [pos.x, pos.y].join(" ");
}

function slant(a: Pos, b: Pos, slant: { x?: number; y?: number } = {}) {
  const x = lerp(a.x, b.x, slant.x ?? 0);
  const y = lerp(a.y, b.y, slant.y ?? 0);
  return [x, y].join(" ");
}

export function toCurve(...pos: { 0: Pos; 1: Pos; 2: Pos; 3: Pos } & Pos[]) {
  if (pos.length % 2 !== 0) {
    return "";
  }

  const path: string[] = [
    "M",
    abs(pos[1]),
    "C",
    slant(pos[1], pos[3], pos[0]),
    slant(pos[1], pos[3], pos[2]),
    abs(pos[3]),
  ];

  if (pos.length > 4) {
    path.push("S");

    for (let index = 4; index < pos.length; index += 2) {
      path.push(
        slant(pos[index - 1], pos[index + 1], pos[index]),
        abs(pos[index + 1])
      );
    }
  }

  return path.join(" ");
}
