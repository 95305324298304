import { useCallback } from "react";

function findPointerCoords(event: React.PointerEvent<HTMLDivElement>) {
  const bounds = event.currentTarget.getBoundingClientRect();
  return {
    x: (event.clientX - bounds.left) / bounds.width,
    y: (event.clientY - bounds.top) / bounds.height,
  };
}

interface ScrubAreaProps {
  onScrub: (point: { x: number; y: number }) => void;
  children: React.ReactNode;
}

export default function ScrubArea({ onScrub, children }: ScrubAreaProps) {
  const handleScrub = useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      const coords = findPointerCoords(event);
      onScrub(coords);
    },
    [onScrub]
  );

  return (
    <div style={{ cursor: "crosshair" }} onPointerDown={handleScrub}>
      {children}
    </div>
  );
}
