import { useEffect, useState } from "react";

export function useAudioAmplitude(audio?: Blob) {
  const [signal, setSignal] = useState<number[]>();

  useEffect(() => {
    if (!audio) {
      return;
    }

    audio.arrayBuffer().then((buffer) => {
      const context = new AudioContext();
      context
        .decodeAudioData(buffer)
        .then((result) => {
          return result.getChannelData(0);
        })
        .then((data) => setSignal(Array.from(data)));
    });

    return () => {
      setSignal(undefined);
    };
  }, [audio]);

  return signal;
}
