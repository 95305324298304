import React, { useRef } from "react";
import styles from "./styles.module.sass";

interface BadgedProps {
  content?: React.ReactNode;
  children: React.ReactNode;
}

export default function Badged({ content, children }: BadgedProps) {
  const contentRef = useRef<React.ReactNode>(null);

  if (content) {
    contentRef.current = content;
  }
  return (
    <div className={styles.Badged}>
      <div
        className={styles.badge}
        style={{
          transform: `scale(${content ? 1 : 0})`,
        }}
      >
        {content}
      </div>
      {children}
    </div>
  );
}
