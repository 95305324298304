import PopupDialog from "render/ui/layouts/PopupDialog";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface Props {
  headline: string;
  msg: string;
  onClose: () => void;
}

export default function MessagePopup({ headline, msg, onClose }: Props) {
  return (
    <PopupDialog>
      <div className={styles.MessagePopup}>
        <h2>{headline}</h2>
        <p>{msg}</p>
        <HoverTextButton type="button" onClick={onClose}>
          Close
        </HoverTextButton>
      </div>
    </PopupDialog>
  );
}
