import { SearchResult } from "render/hooks/useSearch";
import Banner from "render/ui/presentation/Banner";

interface ResultsProps<T> {
  result: SearchResult<T>;
  children: (items: T[]) => React.ReactElement;
}

export default function Results<T>({ result, children }: ResultsProps<T>) {
  if (!result.matches) {
    return null;
  }

  if (result.matches.length) {
    return children(result.matches);
  }

  if (result.busy) {
    return null;
  }

  return (
    <Banner>
      <p>
        No results for <q>{result.query}</q>
      </p>
    </Banner>
  );
}
