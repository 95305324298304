import { APIAssessment } from "@cur8/api-client";
import { Assessment, fromAPI } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "../useReporting";

function convertAndRemoveDuplicates(
  assessments: APIAssessment[]
): Assessment[] {
  const unique = new Set<string>();
  const filtered: Assessment[] = [];

  for (const assessment of assessments) {
    if (!unique.has(assessment.itemVersion)) {
      unique.add(assessment.itemVersion);
      filtered.push(fromAPI.toAssessment(assessment));
    }
  }

  return filtered;
}

export function useAssessment(
  patientId: string,
  assessmentId: string,
  versionId: string
) {
  const { handleError } = useReporting();

  const api = useAPIClient().assessment;
  const [versions, setVerions] = useState<Assessment[]>();
  const [selected, setSelected] = useState<Assessment>();

  const fetch = useCallback(() => {
    api
      .listVersions({ patientId, assessmentId })
      .result.then((vs) => {
        const typedAndFiltered = convertAndRemoveDuplicates(vs.items);
        setVerions(typedAndFiltered);
        setSelected(
          typedAndFiltered.find((ass) => ass.itemVersion === versionId)
        );
      })
      .catch(handleError);
  }, [api, assessmentId, handleError, patientId, versionId]);

  useEffect(() => {
    if (!patientId || !assessmentId) {
      return;
    }
    fetch();
  }, [assessmentId, fetch, patientId]);

  return {
    selected,
    versions,
  };
}
