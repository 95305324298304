import { ComponentProps, PropsWithChildren } from "react";
import { Typography } from "../../Typography";
import { type Point } from "../lib/svg";
import { useProjectionGraphContext } from "../ProjectionGraph.context";
import styles from "../styles.module.sass";

interface ProjectionLabelProps extends ComponentProps<"div"> {
  point: Point;
}

export function ProjectionLabel({
  point,
  children,
  style = {},
  className = "",
  ...props
}: PropsWithChildren<ProjectionLabelProps>) {
  const { Xpercent, Ypercent } = useProjectionGraphContext();

  return (
    <div
      className={`${styles.ProjectionLabel} ${className}`}
      style={{
        left: `${Xpercent(point.x)}%`,
        bottom: `${Ypercent(point.y)}%`,
        ...style,
      }}
      {...props}
    >
      <Typography variant="label-m">{children}</Typography>
    </div>
  );
}
