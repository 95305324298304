import { VisitSummary } from "@cur8/rich-entity";
import NoSummary from "./components/NoSummary/NoSummary";
import Summary from "./components/Summary/SummaryProps";
import styles from "./styles.module.sass";

interface PreviousVisitSummaryProps {
  visit: VisitSummary | undefined;
}

export default function PreviousVisitSummary({
  visit,
}: PreviousVisitSummaryProps) {
  return (
    <div className={styles.PreviousVisitSummary}>
      {!visit && <NoSummary />}
      {visit && <Summary visit={visit} />}
    </div>
  );
}
