import { APITypesV1 } from "@cur8/api-client";
import { PathLink, useNav, useQueryState } from "@pomle/react-router-paths";
import { useCallback, useEffect, useState } from "react";
import BackIcon from "render/assets/nav/back.svg?react";
import { useImmutableScan } from "render/hooks/api/useImmutableScan";
import { usePatient } from "render/hooks/api/usePatient";
import { paths } from "render/routes/paths";
import ContextHeader from "render/ui/layouts/ContextHeader";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageFrame from "render/ui/layouts/PageFrame";
import Chart from "../components/Chart";
import ImageViewer from "../components/ImageViewer";
import PatientSummary from "../components/PatientSummary";
import ProcessErrors from "../components/ProcessErrors";
import PropertySelector from "../components/PropertySelector";
import RegionTracker from "../components/RegionTracker";
import TissueAnnotationContext from "../context/TissueAnnotationContext";
import TissueImageContext from "../context/TissueImageContext";
import { useCameraCalibration } from "../hooks/useCameraCalibration";
import { useProcessedMetadata } from "../hooks/useProcessedMetadata";
import { useQueries } from "../hooks/useQueries";
import { indexQuery } from "../lib/tissueQueries";
import {
  PLAYBACK_FRAME_RATE,
  PROPERTIES,
  PropertyLabel,
  PropertyRange,
} from "../lib/types";
import { rangeParser } from "../lib/utils";
import styles from "./styles.module.sass";

interface TissueTrackerPageProps {
  patientId: string;
  scanId: string;
  scanVersion: string;
}

export default function TissueTrackerPage({
  patientId,
  scanId,
  scanVersion,
}: TissueTrackerPageProps) {
  const patientNav = useNav(paths.patient.detail);
  const scan = useImmutableScan(patientId, scanId, scanVersion);

  const patientRequest = usePatient(patientId);

  const { processedMetadata, metadataError } = useProcessedMetadata(scan);

  const [maxIndex, setMaxIndex] = useState<number>(0);
  const [indexRemap, setIndexRemap] = useState<number[]>();
  const [timestamps, setTimestamps] = useState<number[]>([]);
  const [range, setRange] = useState<PropertyRange | undefined>();
  const [isPlaying, setPlayback] = useState<boolean>(false);
  const [visibleProperties, setVisibleProperties] = useState<PropertyLabel[]>(
    Array.from(PROPERTIES)
  );
  const [hasBloodVesselsMask, setHasBloodVesselsMask] =
    useState<boolean>(false);

  const { property, setProperty } = useQueries();

  // Index
  const [indexState, setIndexState] = useQueryState(indexQuery);
  const index = indexState.idx.at(0) ?? 0;
  const onIndexUpdated = useCallback(
    (idx: number) => {
      if (maxIndex > 0 && idx > maxIndex) {
        idx = maxIndex;
      } else if (idx < 0) {
        idx = 0;
      }
      setIndexState({ idx: [idx] });
    },
    [maxIndex, setIndexState]
  );

  const [pixelsPerCm, setPixelsPerCm] = useState<number>();
  const cameraCalibration = useCameraCalibration(scan);
  useEffect(() => {
    if (!cameraCalibration) {
      return;
    }
    setPixelsPerCm(cameraCalibration.camera.pix_per_cm);
  }, [cameraCalibration]);

  useEffect(() => {
    if (!processedMetadata) {
      return;
    }
    const visProps: PropertyLabel[] = [];
    if (processedMetadata.blood_vessels_mask !== undefined) {
      setHasBloodVesselsMask(processedMetadata.blood_vessels_mask);
    }
    /**
     * Show/hide properties based on metadata-ranges
     * Has range => Show property
     */
    PROPERTIES.forEach((label, prop) => {
      if (processedMetadata.ranges[prop]) {
        visProps.push([prop, label]);
      }
    });
    setVisibleProperties(visProps);
  }, [processedMetadata]);

  useEffect(() => {
    if (!processedMetadata) {
      return;
    }
    const r = rangeParser(
      processedMetadata.ranges[property],
      processedMetadata.version
    );
    setRange(r);
  }, [processedMetadata, property]);

  useEffect(() => {
    if (!scan) {
      return;
    }

    const aPrefix = "acq_";
    const acqs = Object.entries(scan.resultStateSummary)
      .filter(
        ([k, v]) =>
          k.startsWith(aPrefix) && v === APITypesV1.ResultState.Complete
      )
      .map(([k, _]) => parseInt(k.slice(aPrefix.length)))
      .sort((a, b) => a - b);
    setIndexRemap(acqs);
    setMaxIndex(acqs.length - 1);

    if (!processedMetadata) {
      return;
    }
    // The old processing pipeline contained only the processed timestamps
    // Now that we do incremental processing we get all timestamps and need
    // to filter out the ones that we don't need.
    const ts =
      (processedMetadata.version ?? 1) > 1
        ? acqs.map((i) => processedMetadata.timestamp_s[i])
        : processedMetadata.timestamp_s;

    setTimestamps(ts);
  }, [scan, processedMetadata]);

  const play = useCallback(() => {
    setPlayback(!isPlaying);
  }, [isPlaying]);

  const playBack = useCallback(() => {
    if (isPlaying) {
      const nextIndex = maxIndex <= index ? 0 : index + 1;
      onIndexUpdated(nextIndex);
    } else {
      setPlayback(false);
    }
  }, [index, isPlaying, maxIndex, onIndexUpdated]);

  useEffect(() => {
    if (!isPlaying) {
      return;
    }
    const timer = setTimeout(playBack, 1000 / PLAYBACK_FRAME_RATE, false);
    return () => {
      clearTimeout(timer);
    };
  }, [isPlaying, playBack]);

  if (scan && metadataError && !processedMetadata) {
    return (
      <FramedPage>
        <h1>{metadataError}</h1>
      </FramedPage>
    );
  }
  if (!patientRequest || !scan || !processedMetadata) {
    return (
      <FramedPage>
        <h1>Fetching data...</h1>
      </FramedPage>
    );
  }

  return (
    <PageFrame>
      <div className={styles.TissuePage}>
        <header>
          <ContextHeader
            context={
              <PathLink
                to={patientNav.to({
                  patientId: patientRequest.patientId,
                })}
              >
                <BackIcon />
              </PathLink>
            }
          >
            {scan && <PatientSummary patient={patientRequest} scan={scan} />}
          </ContextHeader>
        </header>
        <TissueAnnotationContext
          patientId={patientId}
          scanId={scanId}
          scanVersion={scanVersion}
        >
          <TissueImageContext
            scan={scan}
            property={property}
            index={index}
            maxIndex={maxIndex}
            version={processedMetadata?.version || 1}
            hasBloodVesselsMask={hasBloodVesselsMask}
            indexRemap={indexRemap}
          >
            <section className={styles.top}>
              <div className={styles.Sidebox}>
                <PropertySelector
                  onSelectProperty={setProperty}
                  properties={visibleProperties}
                  selected={property}
                />
              </div>
              {scan && range && processedMetadata && (
                <ImageViewer range={range} />
              )}
              <div className={styles.Sidebox} data-toolboxheight="true">
                <RegionTracker pixelsPerCm={pixelsPerCm} />
              </div>
            </section>
            <section className={styles.chart}>
              {range && scan && timestamps && indexRemap && (
                <Chart
                  createdAt={processedMetadata.created_at}
                  index={index}
                  indexRemap={indexRemap}
                  isPlaying={isPlaying}
                  onIndexUpdated={onIndexUpdated}
                  pixelsPerCm={pixelsPerCm}
                  play={play}
                  range={range}
                  scan={scan}
                  timestamps={timestamps}
                  visibleProperties={visibleProperties}
                />
              )}
            </section>
          </TissueImageContext>
        </TissueAnnotationContext>
        <ProcessErrors processErrors={processedMetadata.errors} />
      </div>
    </PageFrame>
  );
}
