export function ExerciseFrequency({ value }: { value: number }) {
  if (value >= 1 && value <= 2) {
    return <>1-2 hours / week</>;
  } else if (value >= 3 && value <= 4) {
    return <>3-4 hours / week</>;
  } else if (value >= 5 && value <= 6) {
    return <>5-6 hours / week</>;
  } else if (value >= 7 && value <= 8) {
    return <>7-8 hours / week</>;
  } else if (value >= 9 && value <= 10) {
    return <>9-10 hours / week</>;
  } else if (value > 10) {
    return <>10+ hours / week</>;
  } else {
    return <>No</>;
  }
}
