import { VisitSummary } from "@cur8/rich-entity";

export function getPreviousSummaryFor(
  visits: VisitSummary[],
  visitId?: string
) {
  if (visitId == null) {
    return visits[0];
  }

  const point = visits.findIndex((v) => v.id === visitId);

  if (point === -1) {
    return undefined;
  }

  return visits[point + 1];
}
