import { DateTime } from "luxon";
import { useMemo } from "react";
import useBookingSchedule from "./useBookingSchedule";
import useCalendarState from "./useCalendarState";

const CALENDAR_START_HOUR = 8;
const CALENDAR_END_HOUR = 22;

// return a of datetimes that have hours like [8.00, 9.00, 10.00]
function workingHours() {
  const numberOfHours = CALENDAR_END_HOUR - CALENDAR_START_HOUR;

  const hoursList = Array(numberOfHours) // to include the last hour
    .fill(null)
    .map((_, i) =>
      DateTime.fromObject({ hour: CALENDAR_START_HOUR + i, minute: 0 })
    );

  return hoursList;
}

export default function useCalendar() {
  const { selectedPeriod, siteId } = useCalendarState();
  const { getSlots } = useBookingSchedule();

  const hours = useMemo(() => workingHours(), []);

  const slots = useMemo(() => {
    return getSlots(siteId, selectedPeriod.start, selectedPeriod.end);
  }, [getSlots, selectedPeriod, siteId]);

  return {
    siteId,
    openingHour: DateTime.fromObject({ hour: CALENDAR_START_HOUR }),
    closingHour: DateTime.fromObject({ hour: CALENDAR_END_HOUR }),
    selectedPeriod,
    slots,
    hours,
  };
}
