import { useEffect, useState } from "react";
//import Checkbox from "render/ui/form/Checkbox";
import styles from "./styles.module.sass";

import { MemberSummary } from "@cur8/api-client/dist/api/cur8/generated-types/v1/Api";
import { useAppInsights } from "render/context/AppInsightsContext";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import Chat from "./components/Chat";

interface ImproveProps {
  patientId: string;
  summary: MemberSummary;
  active: boolean;
  onOK(newSummary: MemberSummary | null): void;
}

export default function Improve({
  patientId,
  summary,
  active,
  onOK,
}: ImproveProps) {
  const [localSummaryText, setLocalSummaryText] = useState<string>(
    summary.summary
  );

  useEffect(() => {
    if (!active && summary.summary !== localSummaryText) {
      // Happens when a different (older) summary was selected
      setLocalSummaryText(summary.summary);
    }
  }, [active, summary.summary, localSummaryText]);

  const appInsights = useAppInsights();

  useEffect(() => {
    appInsights.trackEvent({
      name: "scribe-improve-summary",
      properties: { patientId },
    });
  }, [appInsights, patientId]);

  return (
    <div className={styles.Improve}>
      <div className={styles.columns}>
        <div className={styles.generated}>{localSummaryText}</div>
        <div className={styles.chat}>
          <Chat
            currentSummaryText={localSummaryText}
            onSummaryTextUpdated={setLocalSummaryText}
            active={active}
            onReset={() => setLocalSummaryText(summary.summary)}
          />
        </div>
      </div>

      <footer className={styles.footer}>
        <HoverTextButton type="button" onClick={() => onOK(null)}>
          Cancel
        </HoverTextButton>
        <HoverTextButton
          type="button"
          onClick={() => onOK({ ...summary, summary: localSummaryText })}
        >
          Use this
        </HoverTextButton>
      </footer>
    </div>
  );
}
