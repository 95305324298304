import React from "react";
import styles from "./styles.module.sass";

interface LabeledFigureProps {
  children: React.ReactNode;
  label: React.ReactNode;
}

export default function LabeledFigure({ label, children }: LabeledFigureProps) {
  return (
    <div className={styles.LabeledFigure}>
      {children}
      <label>{label}</label>
    </div>
  );
}
