import { useEffect } from "react";
import { useKeyboardContext } from "render/context/KeyboardContext";

export function useKeyboardPress(handleEvent: (event: KeyboardEvent) => void) {
  const { blocked } = useKeyboardContext();

  useEffect(() => {
    if (blocked) {
      return;
    }

    window.addEventListener("keydown", handleEvent);

    return () => {
      window.removeEventListener("keydown", handleEvent);
    };
  }, [blocked, handleEvent]);
}
