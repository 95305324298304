import styles from "./styles.module.sass";

interface VerticalLineProps {
  position: number | undefined;
}

export default function VerticalLine({ position }: VerticalLineProps) {
  return position ? (
    <div
      className={styles.VerticalLine}
      style={{ left: `${position * 100}%` }}
      data-testid="verticalLine"
    ></div>
  ) : null;
}
