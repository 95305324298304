import { ScanSequence, Side } from "@cur8/api-client";
import { Camera, SIDES } from "lib/api/rigg";
import { ThermalImageURI } from "lib/api/thermal-uri";
import { useMemo } from "react";
import { useScanSequence } from "./useScanSequence";

type PathInfo = {
  side: Side;
  camera: Camera;
  frame: number;
};

function createThermalImageURIs(
  deviceId: string,
  recordingId: string,
  seq: ScanSequence,
  cameras: Camera[]
) {
  const paths: PathInfo[] = [];
  SIDES.forEach((side) => {
    if (seq && Object.keys(seq[side]).includes("flir")) {
      const flir = seq[side].flir;
      if (flir.length > 0) {
        paths.push(
          ...cameras.map((camera) => {
            const flirCamera = "flir_" + camera;
            const frame = flir[0][flirCamera].frame_num;
            return { side: side, camera: camera, frame: frame };
          })
        );
      }
    }
  });

  const findFrame = (side: Side, camera: Camera) => {
    for (let i = 0; i < paths.length; i++) {
      if (paths[i].side === side && paths[i].camera === camera) {
        return paths[i].frame;
      }
    }
    return -1;
  };

  const uris: ThermalImageURI[] = [];
  SIDES.forEach((side) => {
    uris.push(
      ...cameras.map((camera) => {
        return new ThermalImageURI(
          deviceId,
          recordingId,
          side,
          camera,
          findFrame(side, camera).toString()
        );
      })
    );
  });

  return uris;
}

export function useThermalURIs({
  deviceId,
  recordingId,
  cameras,
}: {
  deviceId: string;
  recordingId: string;
  cameras: Camera[];
}) {
  const seq = useScanSequence({
    recordingId: recordingId,
    deviceId: deviceId,
  })?.data;

  const uris = useMemo(() => {
    if (seq) {
      return createThermalImageURIs(deviceId, recordingId, seq, cameras);
    }
  }, [deviceId, recordingId, cameras, seq]);

  return uris;
}
