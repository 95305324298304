import { GripRecord } from "lib/doctor-scribe/types";

export const maleGripData: GripRecord[] = [
  {
    age: {
      from: 0,
      to: 29,
    },
    left: {
      average: 45.98,
      std: 8.3,
    },
    right: {
      average: 48.48,
      std: 8.24,
    },
    diff: {
      average: 4.57,
      std: 3.53,
    },
  },
  {
    age: {
      from: 30,
      to: 34,
    },
    left: {
      average: 46.52,
      std: 8.4,
    },
    right: {
      average: 48.36,
      std: 8.67,
    },
    diff: {
      average: 3.94,
      std: 3.45,
    },
  },
  {
    age: {
      from: 35,
      to: 39,
    },
    left: {
      average: 47.5,
      std: 8,
    },
    right: {
      average: 50.08,
      std: 8.91,
    },
    diff: {
      average: 4.77,
      std: 3.45,
    },
  },
  {
    age: {
      from: 40,
      to: 44,
    },
    left: {
      average: 47.87,
      std: 8,
    },
    right: {
      average: 50.95,
      std: 8.08,
    },
    diff: {
      average: 4.38,
      std: 3.43,
    },
  },
  {
    age: {
      from: 45,
      to: 49,
    },
    left: {
      average: 47.22,
      std: 8.2,
    },
    right: {
      average: 49.52,
      std: 8.87,
    },
    diff: {
      average: 4.59,
      std: 3.81,
    },
  },
  {
    age: {
      from: 50,
      to: 54,
    },
    left: {
      average: 47.17,
      std: 7.6,
    },
    right: {
      average: 50.44,
      std: 8.12,
    },
    diff: {
      average: 4.89,
      std: 4.63,
    },
  },
  {
    age: {
      from: 55,
      to: 59,
    },
    left: {
      average: 44.96,
      std: 7.1,
    },
    right: {
      average: 47.19,
      std: 7.16,
    },
    diff: {
      average: 4.72,
      std: 4.06,
    },
  },
  {
    age: {
      from: 60,
      to: 64,
    },
    left: {
      average: 43.18,
      std: 9,
    },
    right: {
      average: 46.07,
      std: 9.2,
    },
    diff: {
      average: 5.23,
      std: 3.54,
    },
  },
  {
    age: {
      from: 65,
      to: 69,
    },
    left: {
      average: 40.53,
      std: 6.5,
    },
    right: {
      average: 42.65,
      std: 6.76,
    },
    diff: {
      average: 4.68,
      std: 3.06,
    },
  },
  {
    age: {
      from: 70,
      to: 100,
    },
    left: {
      average: 36.27,
      std: 6.2,
    },
    right: {
      average: 38.83,
      std: 6.04,
    },
    diff: {
      average: 4,
      std: 3.69,
    },
  },
];
