import { useNav } from "@pomle/react-router-paths";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useNotifications } from "render/context/NotificationContext";
import { useReporting } from "render/hooks/useReporting";
import { paths } from "render/routes/paths";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import styles from "./styles.module.sass";

interface SendSMSPageProps {
  patientId: string;
}

export default function SendSMSPage({ patientId }: SendSMSPageProps) {
  const { handleError } = useReporting();

  const api = useAPIClient().patient;
  const { emit } = useNotifications();

  const navPatientDetails = useNav(paths.patient.detail);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [messageBody, setMessageBody] = useState("");

  const handleSubmit = () => {
    api
      .sendSignupLink(patientId, {
        preview: false,
      })
      .result.then(() => {
        emit("Sent SMS to patient successfully", "success");
        navPatientDetails.go({ patientId });
      })
      .catch(handleError);
  };

  useEffect(() => {
    api
      .sendSignupLink(patientId, {
        preview: true,
      })
      .result.then((response) => {
        setPhoneNumber(response.phoneNumber || "");
        setMessageBody(response.body || "");
      })
      .catch(handleError);
  }, [api, patientId, handleError]);
  return (
    <FramedPage>
      <div className={styles.SendSMSPage}>
        <h2>Create Member - Onboarding</h2>
        <div className={styles.phoneNumber}>Send SMS - {phoneNumber}</div>
        <div className={styles.messageBody}>{messageBody}</div>
        <div className={styles.actionButtons}>
          <div>
            <HoverTextButton onClick={handleSubmit}>Send SMS</HoverTextButton>
          </div>
          <div
            className={styles.skipButton}
            onClick={navPatientDetails.on({ patientId })}
          >
            <HoverTextButton>Skip</HoverTextButton>
          </div>
        </div>
      </div>
    </FramedPage>
  );
}
