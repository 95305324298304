import { APITypesV1 } from "@cur8/api-client";
import { Annotation } from "@cur8/rich-entity";
import { useMemo } from "react";
import { useAnnotationQuery } from "render/hooks/api/useAnnotationQuery";

export function useAnnotationComments(annotation: Annotation) {
  const { id, uri } = annotation;

  const namespace = useMemo(() => {
    return ["annotations-comments", "annotation", id].join("/");
  }, [id]);

  const options = useMemo((): APITypesV1.AnnotationListByPatientParams => {
    return {
      patientId: uri.patientId,
      targetUri: uri.toString(),
    };
  }, [uri]);

  return useAnnotationQuery(options, namespace);
}
