import { ColorMap } from "lib/webgl/colorizer";
import { useGradientMap } from "render/hooks/api/image/useGradientMap";
import { BloodVesselsMode, TissueImage } from "../../lib/types";
import WaitingForImage from "./WaitingForImage";
import styles from "./styles.module.sass";

interface BloodVesselsImageProps {
  bloodVesselsMode: BloodVesselsMode;
  tissueImage: TissueImage;
}

export default function BloodVesselsImage({
  bloodVesselsMode,
  tissueImage,
}: BloodVesselsImageProps) {
  const colormap =
    bloodVesselsMode === BloodVesselsMode.excluded
      ? ColorMap.None
      : ColorMap.Inferno;
  const gradientImage = useGradientMap(
    colormap,
    tissueImage.image,
    tissueImage.threshold_exclude_blood_vessels
  );

  if (!gradientImage) {
    return <WaitingForImage />;
  }

  return (
    <g className={styles.BloodVesselsImage} data-bvmode={bloodVesselsMode}>
      <rect
        x={0}
        y={0}
        width={gradientImage.naturalWidth}
        height={gradientImage.naturalHeight}
      />
      <image href={gradientImage.src} />
    </g>
  );
}
