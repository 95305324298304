import { ImmutableScan, RangeAnnotation } from "@cur8/rich-entity";
import { Point } from "lib/math";
import { DateTime } from "luxon";
import { useCallback, useEffect, useRef, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useTissueAnnotationContext } from "render/pages/TissuePages/context/TissueAnnotationContext";
import { useChromophoreTimeseriesMulti } from "render/pages/TissuePages/hooks/useChromophoreTimeseriesMulti";
import { useMessagePopup } from "render/pages/TissuePages/hooks/useMessagePopup";
import { useQueries } from "render/pages/TissuePages/hooks/useQueries";
import { TissueURI } from "render/pages/TissuePages/lib/TissueURI";
import {
  exportSummary,
  exportThermalLines,
  exportTimeseries,
} from "render/pages/TissuePages/lib/dataExporter";
import {
  linePoints,
  readPixelsFromImage,
} from "render/pages/TissuePages/lib/line";
import { ChartIcon, DownloadIcon } from "render/ui/symbol/HoverIcon";
import PauseIcon from "render/ui/symbol/HoverIcon/PauseIcon";
import PlayIcon from "render/ui/symbol/HoverIcon/PlayIcon";
import TimeIcon from "render/ui/symbol/HoverIcon/TimeIcon";
import FigureButton from "render/ui/trigger/FigureButton";
import { useTissueImageContext } from "../../context/TissueImageContext";
import {
  Property,
  PropertyLabel,
  PropertyRange,
  RegionTimeseries,
  TimeRange,
  TissueAnnotation,
  TissueAnnotationDataType,
} from "../../lib/types";
import LabelEditor from "../LabelEditor";
import ChartFactory, {
  OnBandCreated,
  OnBandError,
  OnBandUpdated,
} from "./ChartFactory";
import styles from "./styles.module.sass";

interface ChartProps {
  createdAt: DateTime | undefined;
  index: number;
  indexRemap: number[];
  isPlaying: boolean;
  onIndexUpdated: (idx: number) => void;
  pixelsPerCm?: number;
  play: () => void;
  range: PropertyRange;
  scan: ImmutableScan;
  timestamps: number[];
  visibleProperties: PropertyLabel[];
}

interface ToIEditProps {
  annoId?: TissueAnnotation["annotation"]["id"];
  show: boolean;
  type: TissueAnnotationDataType;
  orgLabel: string;
  anchor?: Point;
  start: number;
  end: number;
}

export default function Chart({
  createdAt,
  index,
  indexRemap,
  isPlaying,
  onIndexUpdated,
  pixelsPerCm,
  play,
  range,
  scan,
  timestamps,
  visibleProperties,
}: ChartProps) {
  const api = useAPIClient();
  const { emitDialog } = useMessagePopup();
  const { property } = useQueries();
  const {
    annotations,
    createAndSave,
    filtered,
    newAnno,
    remove,
    replace,
    selected,
  } = useTissueAnnotationContext();
  const { chart: chartImage, bloodVesselsMask } = useTissueImageContext();
  const series = useChromophoreTimeseriesMulti(
    scan,
    filtered.roi,
    indexRemap,
    property,
    timestamps,
    range
  );
  const auditApi = useAPIClient().audit;

  const [thermalLineSeries, setThermalLinesSeries] = useState<
    RegionTimeseries[]
  >([]);
  const [tois, setTois] = useState<TimeRange[]>([]);

  const containerRef = useRef<HTMLDivElement>(null);
  const chartRef = useRef<ChartFactory>();
  const [editData, setEditData] = useState<ToIEditProps>();

  useEffect(() => {
    const tt = [] as TimeRange[];
    filtered.toi.forEach((toi) => {
      tt.push({
        anno: toi,
        from: toi.annotation.data.range.from,
        to: toi.annotation.data.range.to,
      });
    });
    setTois(tt);
  }, [filtered.toi]);

  useEffect(() => {
    if (property !== Property.thermal || !chartImage || !chartImage.image) {
      return;
    }

    const ls = [] as RegionTimeseries[];
    filtered.lines.forEach((lineAnno) => {
      const points = linePoints(lineAnno.annotation.data.line);
      const uarr = readPixelsFromImage(chartImage.image!, points, range);
      ls.push({
        color: lineAnno.color ?? "#fc0",
        id: lineAnno.annotation.id,
        label: lineAnno.label,
        series: uarr,
      });
    });
    setThermalLinesSeries(ls);
  }, [filtered.lines, chartImage, property, range]);

  /* Can we pass the Annotation id into LCJS in some way? */
  const getAnnoFromLabel = useCallback(
    (label: string): TissueAnnotation<RangeAnnotation> => {
      const toi = tois.find((t) => t.anno?.label === label);
      if (toi) {
        return toi.anno;
      }
      emitDialog("Error", "Can't find that Time of Interest");
      throw new Error("No matching ToI found");
    },
    [emitDialog, tois]
  );
  const getAnnoById = useCallback(
    (id: string): TissueAnnotation<RangeAnnotation> => {
      const toi = tois.find((t) => t.anno.annotation.id === id);
      if (toi) {
        return toi.anno;
      }
      emitDialog("Error", "Can't find that Time of Interest");
      throw new Error("No matching ToI found");
    },
    [emitDialog, tois]
  );

  const onToiCancel = useCallback(() => {
    setEditData(undefined);
    chartRef.current?.xAxisDragCancel();
  }, []);

  const onToiCreated = useCallback(
    ({ start, end, anchor }: OnBandCreated): boolean => {
      if (start === end) {
        console.info("Same start stop - ignoring ToI created");
        return false;
      }
      setEditData({
        show: true,
        orgLabel: "",
        type: TissueAnnotationDataType.Time,
        anchor,
        start,
        end,
      });
      return true;
    },
    []
  );

  const onToiUpdated = useCallback(
    ({ name, start, end, anchor }: OnBandUpdated): boolean => {
      const anno = getAnnoFromLabel(name);
      if (!anno) {
        return false;
      }
      const ad = {
        ...anno.annotation,
        data: { range: { from: start, to: end, unit: "index" } },
      };
      const ea = { ...anno, annotation: ad };
      replace(ea)
        .then((res) => {
          if (!res) {
            emitDialog("Error", "Failed to save updated range for ToI");
            console.error("Failed to replace ToI", anno, ea, res);
          }
        })
        .catch((err) => {
          console.warn("onToiSaved failed", err);
        })
        .finally(() => {
          setEditData(undefined);
        });
      return true;
    },
    [emitDialog, getAnnoFromLabel, replace]
  );

  const onToiEdit = useCallback(
    ({ name, start, end, anchor }: OnBandUpdated): boolean => {
      setEditData({
        annoId: getAnnoFromLabel(name).annotation.id,
        show: true,
        orgLabel: name,
        type: TissueAnnotationDataType.Time,
        anchor,
        start,
        end,
      });
      return true;
    },
    [getAnnoFromLabel]
  );

  const onToiError = useCallback(
    ({ message, code }: OnBandError) => {
      emitDialog("Error", message);
    },
    [emitDialog]
  );

  const updateToiAnnotation = useCallback(
    (label: string) => {
      if (!editData || !editData.annoId) {
        return;
      }
      const anno = getAnnoById(editData.annoId);
      const uri = new TissueURI(anno.property, label);
      const ea = { ...anno, label: label };
      ea.annotation.applicationSpecificTarget = uri.toString();
      replace(ea)
        .then((res) => {
          if (!res) {
            emitDialog("Error", "Failed to replace ToI");
            console.error("Failed to replace ToI", anno, ea, res);
          }
        })
        .catch((err) => {
          console.warn("updateToiAnnotation failed", err);
        })
        .finally(() => {
          setEditData(undefined);
        });
    },
    [editData, emitDialog, getAnnoById, replace]
  );

  const createToiAnnotation = useCallback(
    (label: string) => {
      if (!editData) {
        return;
      }
      createAndSave(
        TissueAnnotationDataType.Time,
        {
          range: { from: editData.start, to: editData.end, unit: "index" },
        },
        label
      )
        .then((res) => {
          if (!res) {
            emitDialog("Error", "Failed to create ToI");
            console.error("Failed to create ToI", newAnno, res);
          }
        })
        .catch((err) => {
          console.warn("createToiAnnotation failed", err);
        })
        .finally(() => {
          onToiCancel();
        });
    },
    [createAndSave, editData, emitDialog, newAnno, onToiCancel]
  );

  const onToiSaved = useCallback(
    (label: string) => {
      if (!editData) {
        return;
      }
      if (editData.annoId) {
        updateToiAnnotation(label);
      } else {
        createToiAnnotation(label);
      }
    },
    [createToiAnnotation, editData, updateToiAnnotation]
  );

  const onRemoveToI = useCallback(() => {
    if (!editData || !editData.annoId) {
      return;
    }
    remove(editData.annoId)
      .then((res) => {
        if (!res) {
          emitDialog("Error", "Failed remove ToI");
        }
      })
      .catch((err) => {
        console.warn("onRemove ToI failed", err);
      })
      .finally(() => {
        setEditData(undefined);
      });
  }, [editData, emitDialog, remove]);

  /**
   * Creates the actual chart
   */
  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const title = range.displayUnit ?? range.unit;
    chartRef.current = new ChartFactory(
      containerRef.current,
      title,
      timestamps,
      onIndexUpdated
    );

    return () => {
      chartRef.current?.dispose();
      chartRef.current = undefined;
    };
  }, [indexRemap, onIndexUpdated, range.displayUnit, range.unit, timestamps]);

  /**
   * Move chart marker
   * Needs to depend on range.unit since that triggers a new chart to be created.
   */
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    chartRef.current.moveMarker(index);
  }, [index, range.unit]);

  /**
   * Render all lines
   */
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }
    // Only include Thermal Line Series on Property.thermal
    const thermalLS = property === Property.thermal ? thermalLineSeries : [];
    chartRef.current.setSeries(series, thermalLS, selected);
  }, [property, selected, series, thermalLineSeries]);

  /**
   * Render ToI:s as bands
   */
  useEffect(() => {
    if (!chartRef.current || !timestamps) {
      return;
    }
    chartRef.current.addToIs(
      tois,
      onToiCreated,
      onToiUpdated,
      onToiEdit,
      onToiError
    );
  }, [onToiCreated, onToiUpdated, onToiEdit, onToiError, timestamps, tois]);

  /**
   * Exporters
   */
  const onExportSummary = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();

      auditApi
        .createDataExportTrail({
          patientId: scan.patientId,
          scanId: scan.id,
        })
        .result.then(() => {
          exportSummary(
            annotations,
            api,
            bloodVesselsMask,
            emitDialog,
            pixelsPerCm,
            visibleProperties,
            scan,
            timestamps
          );
        })
        .catch((err: any) => {
          emitDialog("Can't export data", "Not authorized.");
          console.debug(err);
          return false;
        });
    },
    [
      annotations,
      api,
      auditApi,
      bloodVesselsMask,
      emitDialog,
      pixelsPerCm,
      scan,
      timestamps,
      visibleProperties,
    ]
  );

  const onExportTimeseries = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();

      auditApi
        .createDataExportTrail({
          patientId: scan.patientId,
          scanId: scan.id,
        })
        .result.then(() => {
          exportTimeseries(
            annotations,
            api,
            bloodVesselsMask,
            createdAt,
            emitDialog,
            pixelsPerCm,
            property,
            scan,
            timestamps
          );
        })
        .catch((err: any) => {
          emitDialog("Can't export data", "Not authorized.");
          console.debug(err);
          return false;
        });
    },
    [
      annotations,
      api,
      auditApi,
      bloodVesselsMask,
      createdAt,
      emitDialog,
      pixelsPerCm,
      property,
      scan,
      timestamps,
    ]
  );

  const onExportLines = useCallback(
    (ev: React.MouseEvent) => {
      ev.preventDefault();
      if (thermalLineSeries.length < 1) {
        emitDialog(
          "Can't export thermal lines",
          "At least one line is required. Create it in the image above."
        );
        return false;
      }
      auditApi
        .createDataExportTrail({
          patientId: scan.patientId,
          scanId: scan.id,
        })
        .result.then(() => {
          exportThermalLines(
            api,
            emitDialog,
            index,
            thermalLineSeries,
            property,
            scan
          );
        })
        .catch((err: any) => {
          emitDialog("Can't export thermal lines", "Not authorized.");
          console.warn(err);
          return false;
        });
    },
    [api, auditApi, emitDialog, index, property, scan, thermalLineSeries]
  );

  useEffect(() => {
    const keyDownHandler = (ev: KeyboardEvent) => {
      if (ev.ctrlKey || ev.shiftKey || ev.altKey) {
        // Ignore if any modifier key is active
        return;
      }
      switch (ev.key) {
        case "Escape":
          onToiCancel();
          break;
        default:
          return;
      }
    };
    window.addEventListener("keydown", keyDownHandler);
    return () => {
      window.removeEventListener("keydown", keyDownHandler);
    };
  }, [onToiCancel]);

  const showHelp = useCallback(() => {
    emitDialog(
      "Chart tools help",
      "To create a ToI (Time of Interest), at least one RoI is required (Region of Interest). Once you have a RoI, just press [ctrl] and click-n-drag on the X-axis. To edit a ToI, just double click the colored area in the chart."
    );
  }, [emitDialog]);

  return (
    <div className={styles.Chart}>
      <div className={styles.actionButtons}>
        <span>
          Chart
          <br />
          tools
        </span>
        <FigureButton onClick={play} title={isPlaying ? "Pause" : "Play"}>
          {isPlaying ? <PauseIcon color="black" /> : <PlayIcon color="black" />}
        </FigureButton>
        <FigureButton onClick={onExportSummary} title="Export summary">
          <DownloadIcon color="black" />
        </FigureButton>
        <FigureButton onClick={onExportTimeseries} title="Export timeseries">
          <TimeIcon color="black" />
        </FigureButton>
        {property === Property.thermal && (
          <FigureButton
            disabled={thermalLineSeries.length <= 0}
            onClick={onExportLines}
            title="Export Thermal lines"
          >
            <ChartIcon color="black" />
          </FigureButton>
        )}
        <span onClick={showHelp} className={styles.help} title="Chart help">
          [?]
        </span>
      </div>
      <div className={styles.container} ref={containerRef}></div>
      {editData && editData.show && (
        <LabelEditor
          addOrEdit={editData.annoId ? "edit" : "add"}
          anchor={editData.anchor}
          onSave={onToiSaved}
          onCancel={onToiCancel}
          onRemove={onRemoveToI}
          type={editData.type}
          orgLabel={editData.orgLabel}
        />
      )}
    </div>
  );
}
