import { Patient } from "@cur8/rich-entity";
import Cholesterol from "./components/Cholesterol";
import LDLItem from "./components/LDL/LDL";
import styles from "./styles.module.sass";

interface BloodFatsRightSectionProps {
  patient: Patient;
}

export default function BloodFatsRightSection({
  patient,
}: BloodFatsRightSectionProps) {
  return (
    <div className={styles.BloodFatsSection}>
      <div className={styles.items}>
        <Cholesterol patient={patient} />
        <LDLItem patient={patient} />
      </div>
    </div>
  );
}
