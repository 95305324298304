import { fromAPI } from "@cur8/rich-entity";
import { useEffect } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useReporting } from "../useReporting";

export function useImmutableScan(
  patientId: string,
  scanId: string,
  scanVersion: string
) {
  const { handleError } = useReporting();

  const api = useAPIClient().scan;

  const { get, set } = useStore().scan.entries;

  useEffect(() => {
    api
      .fetchScan({ patientId, scanId, scanVersion })
      .result.then(fromAPI.toImmutableScan)
      .then((scan) => {
        set(scanId + scanVersion, scan);
      })
      .catch(handleError);
  }, [set, api, patientId, scanId, scanVersion, handleError]);

  return get(scanId + scanVersion).data ?? undefined;
}
