import { PanoramaImageURI } from "lib/api/uri";
import { silenceAbort } from "lib/error";
import { Homographies } from "lib/types/homographies";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

interface props {
  panoramaURI?: PanoramaImageURI;
}

export function useHomographies({ panoramaURI }: props) {
  const api = useAPIClient();
  const [homographies, setHomographies] = useState<Homographies>();

  useEffect(() => {
    if (!panoramaURI) {
      return;
    }

    const filename = `homographies_${panoramaURI.cameraName}.json`;

    const request = api.recording.fetchAttachment({
      deviceId: panoramaURI.deviceId,
      recordingId: panoramaURI.recordingId,
      attachmentName: filename,
    });

    request.result
      .then((res) => res.json())
      .then((h) => {
        return h["homographies"];
      })
      .then((h) => {
        setHomographies(h);
      })
      .catch(silenceAbort);

    return () => {
      request.abandon();
      setHomographies(undefined);
    };
  }, [api, panoramaURI]);

  return homographies;
}
