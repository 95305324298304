import { Glucose, Risk } from "@cur8/health-risks-calc";
import { Metric } from "lib/metric";

export function calcGlucoseRisk(glucose: Metric<"bloodwork.glucose">) {
  const ranges = Glucose.rangesFor();

  const normals = ranges.entries.filter((range) => {
    return range.risk === Risk.Normal;
  });

  const unit = glucose.unit;
  const risk = ranges.findRisk(unit);
  if (
    risk > Risk.Normal &&
    normals.every((range) => unit["mmol/L"] > range.to)
  ) {
    return risk;
  }

  return undefined;
}
