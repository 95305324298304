import vertexShader from "./plane.vert.glsl?raw";

function compileShader(
  ctx: WebGL2RenderingContext,
  program: WebGLProgram,
  shaderSource: string,
  shaderType: GLenum
) {
  const shader = ctx.createShader(shaderType);
  if (!shader) {
    throw new Error("Could not create shader");
  }
  ctx.shaderSource(shader, shaderSource);
  ctx.compileShader(shader);
  const message = ctx.getShaderInfoLog(shader);

  if (message && message.length > 0) {
    // message may be an error or a warning
    throw message;
  }
  ctx.attachShader(program, shader);
}

function createWebGLProgram(
  ctx: WebGL2RenderingContext,
  vertexShader: string,
  fragmentShader: string
): WebGLProgram {
  const program = ctx.createProgram();
  if (!program) {
    throw new Error("Could not create WebGL Program");
  }
  compileShader(ctx, program, vertexShader, ctx.VERTEX_SHADER);
  compileShader(ctx, program, fragmentShader, ctx.FRAGMENT_SHADER);
  ctx.linkProgram(program);
  ctx.useProgram(program);

  return program;
}

function setupGLLayer(canvas: HTMLCanvasElement, vert: string, frag: string) {
  const gl = canvas.getContext("webgl2");
  if (!gl) {
    throw new Error("Could not create WebGL2 Context");
  }

  const program = createWebGLProgram(gl, vert, frag);

  const positionAttributeLocation = gl.getAttribLocation(program, "a_position");
  const texCoordAttributeLocation = gl.getAttribLocation(program, "a_texCoord");

  const resolutionLocation = gl.getUniformLocation(program, "u_resolution");
  const imageLocation = gl.getUniformLocation(program, "u_image");

  const vao = gl.createVertexArray();

  gl.bindVertexArray(vao);

  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  const size = 2; // 2 components per iteration
  const type = gl.FLOAT; // 32bit floats
  const normalize = false;
  const stride = 0;
  const offset = 0;

  gl.enableVertexAttribArray(positionAttributeLocation);
  gl.vertexAttribPointer(
    positionAttributeLocation,
    size,
    type,
    normalize,
    stride,
    offset
  );

  const texCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, texCoordBuffer);
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([
      0.0, 0.0, 1.0, 0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0, 1.0,
    ]),
    gl.STATIC_DRAW
  );

  gl.enableVertexAttribArray(texCoordAttributeLocation);
  gl.vertexAttribPointer(
    texCoordAttributeLocation,
    size,
    type,
    normalize,
    stride,
    offset
  );

  const texture = gl.createTexture();
  gl.activeTexture(gl.TEXTURE0 + 0);
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // no mips, no filtering, no repeat at the edges
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

  gl.clearColor(0, 0, 0, 0);
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
  gl.useProgram(program);

  gl.bindVertexArray(vao);

  gl.uniform1i(imageLocation, 0);

  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  return {
    gl,
    program,

    setResolution(x: number, y: number) {
      canvas.width = x;
      canvas.height = y;
      gl.viewport(0, 0, x, y);
      gl.uniform2f(resolutionLocation, x, y);

      setRectangle(gl, 0, 0, x, y);
    },

    setAttribute(key: string, value: any) {
      const loc = gl.getUniformLocation(program, key);
      gl.uniform1f(loc, value);
    },

    drawImage(image: HTMLImageElement | HTMLCanvasElement) {
      const mipLevel = 0;
      const internalFormat = gl.RGBA;
      const srcFormat = gl.RGBA;
      const srcType = gl.UNSIGNED_BYTE;

      gl.texImage2D(
        gl.TEXTURE_2D,
        mipLevel,
        internalFormat,
        srcFormat,
        srcType,
        image
      );

      const primitiveType = gl.TRIANGLES;
      const count = 6;

      gl.drawArrays(primitiveType, 0, count);
    },
  };
}

export function setRectangle(
  gl: WebGL2RenderingContext,
  x: number,
  y: number,
  width: number,
  height: number
) {
  const x1 = x;
  const x2 = x + width;
  const y1 = y;
  const y2 = y + height;
  gl.bufferData(
    gl.ARRAY_BUFFER,
    new Float32Array([x1, y1, x2, y1, x1, y2, x1, y2, x2, y1, x2, y2]),
    gl.STATIC_DRAW
  );
}

type WebGLLayer = ReturnType<typeof setupGLLayer>;

export class WebGLImageProcessor {
  layer: WebGLLayer;
  canvas: HTMLCanvasElement;

  // 2D image always have same vertex shader
  constructor(
    fragmentShader: string,
    canvas = document.createElement("canvas")
  ) {
    this.canvas = canvas;
    this.layer = setupGLLayer(canvas, vertexShader, fragmentShader);
  }

  attribute(name: string, value: unknown) {
    this.layer.setAttribute(name, value);
  }

  draw(image: HTMLCanvasElement | HTMLImageElement) {
    this.layer.setResolution(image.width, image.height);
    this.layer.drawImage(image);
  }
}
