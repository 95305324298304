import { Risk } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { isScore2HighRisk } from "lib/scores/score2";
import { useMemo } from "react";
import { useAge } from "render/hooks/patient/useAge";
import { Score2 } from "./useScore2";

export type Score2RiskStatus = {
  status: Risk.Normal | Risk.HighRisk;
};

export function useScore2Status(
  patient: Patient,
  score2: Score2 | undefined
): Score2RiskStatus | undefined {
  const age = useAge(patient);

  const riskStatus = useMemo(() => {
    if (!score2) {
      return;
    }
    /*
      When risk can not be calculated due to missing age or score
      Normal is explicitly returned per choice by Daniel Gorji.
    */
    if (!isFinite(score2.score) || !isFinite(age)) {
      return { status: Risk.Normal };
    }

    return {
      status: isScore2HighRisk(age, score2.score) ? Risk.HighRisk : Risk.Normal,
    };
  }, [age, score2]);

  return riskStatus as Score2RiskStatus;
}
