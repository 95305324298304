import { APITypesV1 } from "@cur8/api-client";
import { Annotation } from "@cur8/rich-entity";
import { URIType } from "@cur8/uri";
import { toList } from "@pomle/react-flat-store";
import { PanoramaImageURI } from "lib/api/uri";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAnnotationCRUD } from "render/hooks/api/useAnnotationCRUD";
import CaptionedBox from "render/ui/layouts/CaptionedBox";
import ArtefactHistoryEntryTitle from "./components/ArtefactHistoryEntryTitle";
import CommentInput from "./components/CommentInput";
import CommentList from "./components/CommentList";
import { useAnnotationComments } from "./hooks/useAnnotationComments";
import styles from "./styles.module.sass";

function makeCaption(annotation: Annotation) {
  const targetURI = annotation.targetURI;
  const appTargetURI = annotation.applicationSpecificTarget;

  if (targetURI.type === URIType.Annotation) {
    return "Attachment";
  }

  if (targetURI.type === URIType.Recording) {
    if (appTargetURI) {
      const panoURI = PanoramaImageURI.parse(appTargetURI);
      if (panoURI) {
        return "Panorama";
      }
    }
    return "Scan";
  }

  return "Unknown";
}

interface AnnotationNotesProps {
  selected: boolean;
  annotation: Annotation;
  onSelect: () => void;
}

export default function AnnotationNotes({
  annotation,
  selected,
  onSelect,
}: AnnotationNotesProps) {
  const { entries, fetch: fetchComments } = useAnnotationComments(annotation);

  const { create: createAnnotation } = useAnnotationCRUD();

  const [userActivatedInput, setUserActivatedInput] = useState<boolean>(false);

  const handleSave = useCallback(
    async (text: string) => {
      await createAnnotation(annotation.patientId, {
        targetURI: annotation.uri,
        applicationSpecificTarget: "annotation:comment",
        acceptState: APITypesV1.AcceptState.Accepted,
        physicalArtefactId: annotation.physicalArtefactId,
        comment: text,
      });

      await fetchComments();

      setUserActivatedInput(false);
    },
    [annotation, fetchComments, createAnnotation]
  );

  const handleCancel = useCallback(() => {
    setUserActivatedInput(false);
  }, []);

  const annotations = useMemo(() => {
    return toList(entries);
  }, [entries]);

  useEffect(() => {
    setUserActivatedInput(false);
  }, [selected]);

  const caption = useMemo(() => {
    return makeCaption(annotation);
  }, [annotation]);

  const isInputActive =
    annotations.length === 0 || (userActivatedInput && selected);
  const canCancel = annotations.length > 0;
  const canActivate = !isInputActive;

  return (
    <div className={styles.AnnotationNotes}>
      <CaptionedBox
        selected={selected}
        onSelect={onSelect}
        caption={
          <div className={styles.header}>
            <ArtefactHistoryEntryTitle
              caption={caption}
              date={annotation.createdAt}
            />

            <div>
              {canActivate && (
                <button
                  className={styles.add}
                  onClick={() => setUserActivatedInput(true)}
                >
                  + Add Note
                </button>
              )}
            </div>
          </div>
        }
      >
        <div className={styles.comments}>
          <CommentList annotations={annotations} />

          {isInputActive && (
            <div className={styles.entry}>
              <CommentInput
                onSave={handleSave}
                onCancel={canCancel ? handleCancel : undefined}
              />
            </div>
          )}
        </div>
      </CaptionedBox>
    </div>
  );
}
