/**
 * Tissue calculations
 */

export type ToISummary = {
  mean: number;
  std: number | null;
  min: number;
  max: number;
  p2: number;
  p25: number;
  p50: number;
  p75: number;
  p98: number;
};

export function sum(arr: number[]): number {
  return arr.reduce((a, b) => a + b, 0);
}

export function mean(arr: number[]): number {
  return sum(arr) / arr.length;
}

export function std(arr: number[]): number | null {
  if (arr.length < 3) {
    // Can't calculate on less than 3 values
    return null;
  }
  const mu = mean(arr);
  const diffArr = arr.map((a) => (a - mu) ** 2);
  return Math.sqrt(sum(diffArr) / (arr.length - 1));
}

export function percentile(arr: number[], q: number): number {
  arr = arr.sort((a, b) => a - b);
  const pos = (arr.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;
  if (arr[base + 1] !== undefined) {
    return arr[base] + rest * (arr[base + 1] - arr[base]);
  } else {
    return arr[base];
  }
}

export function toiSummaryCalc(data: number[]): ToISummary {
  return {
    mean: mean(data),
    std: std(data),
    min: Math.min(...data),
    max: Math.max(...data),
    p2: percentile(data, 0.02),
    p25: percentile(data, 0.25),
    p50: percentile(data, 0.5),
    p75: percentile(data, 0.75),
    p98: percentile(data, 0.98),
  };
}

export function findClosest(target: number, numbers: number[]): number {
  let closest = numbers[0];
  let minDifference = Math.abs(target - closest);

  for (const number of numbers) {
    const difference = Math.abs(target - number);
    if (difference === 0) {
      // Perfect match => done
      return number;
    }
    if (difference < minDifference) {
      closest = number;
      minDifference = difference;
    }
  }

  return closest;
}

export function findClosestIndex(target: number, numbers: number[]): number {
  let closestIdx = 0;
  let minDifference = Math.abs(target - numbers[0]);

  for (let i = 0; i < numbers.length; i++) {
    const difference = Math.abs(target - numbers[i]);
    if (difference === 0) {
      // Perfect match => done
      return i;
    }
    if (difference < minDifference) {
      closestIdx = i;
      minDifference = difference;
    }
  }

  return closestIdx;
}
