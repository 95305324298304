import { Side } from "@cur8/api-client";
import * as THREE from "three";

export const Transform: Record<Side, THREE.Matrix4> = {
  front: new THREE.Matrix4(),

  back: new THREE.Matrix4().compose(
    new THREE.Vector3(0, 0, 0),
    new THREE.Quaternion().setFromAxisAngle(
      new THREE.Vector3(0, 0, 1),
      Math.PI
    ),
    new THREE.Vector3(1, 1, 1)
  ),
};
