import { APITypesV1 } from "@cur8/api-client";
import { PulseWaveAssessment } from "@cur8/rich-entity";
import { useCallback, useEffect, useState } from "react";
import { useAssessmentContext } from "render/pages/AssessmentPage/context/AssessmentContext";
import { useAssessmentNav } from "render/pages/AssessmentPage/hooks/useAssessmentNav";
import { INITIAL_SELECTED_RANGE } from "../../components/shared/constants";
import { PulsewaveType, Range } from "../../components/shared/types";
import { CardioSignals } from "../../hooks/useCardioSignals";

interface RangeProps {
  assessment: PulseWaveAssessment;
  signals: CardioSignals;
}

/**
 * Calculate the actual time range for assessment summary
 */
function rangeToTime(
  windowRange: Range,
  selectionRange: Range,
  signalLengthMs: number
): APITypesV1.Range {
  const length = Math.abs(windowRange[1] - windowRange[0]);
  const from =
    ((windowRange[0] + length * selectionRange[0]) * signalLengthMs) / 1000;
  const to =
    ((windowRange[0] + length * selectionRange[1]) * signalLengthMs) / 1000;
  return { from, to };
}

function timeToRange(
  timeRange: APITypesV1.Range,
  windowRange: Range,
  signalLengthMs: number
): Range {
  const length = Math.abs(windowRange[1] - windowRange[0]);
  const fromIndex = (timeRange.from * 1000) / signalLengthMs - windowRange[0];
  const toIndex = (timeRange.to * 1000) / signalLengthMs - windowRange[0];

  return [fromIndex / length, toIndex / length];
}

export function useRange({ assessment, signals }: RangeProps) {
  const { pulsewaveType } = useAssessmentNav();
  const { setPulseWaveData } = useAssessmentContext();
  const signalLengthMs = signals.ecg.lengthMs;

  const [selectionRange, setSelectionRange] = useState<Range | undefined>();
  const [windowRange, setWindowRange] = useState<Range>(INITIAL_SELECTED_RANGE);

  const assessmentSelection = useCallback(
    (pulsewaveType: PulsewaveType) => {
      if (!signalLengthMs) {
        return;
      }
      let data: APITypesV1.PulseWaveAssessmentData[] | undefined = undefined;
      switch (pulsewaveType) {
        case PulsewaveType.lveti:
          data = assessment.lveti;
          break;
        case PulsewaveType.pulseWaveVelocity:
          data = assessment.pulseWaveVelocity;
          break;
        case PulsewaveType.handAsymmetry:
          data = assessment.handAsymmetry;
          break;
        case PulsewaveType.footAsymmetry:
          data = assessment.footAsymmetry;
          break;
      }
      if (data && data[0]) {
        setSelectionRange(
          timeToRange(data[0].range, windowRange, signalLengthMs)
        );
      } else {
        setSelectionRange(undefined);
      }
    },
    [
      assessment.footAsymmetry,
      assessment.handAsymmetry,
      assessment.lveti,
      assessment.pulseWaveVelocity,
      signalLengthMs,
      windowRange,
    ]
  );

  useEffect(() => {
    if (pulsewaveType) {
      assessmentSelection(pulsewaveType);
    }
  }, [assessmentSelection, pulsewaveType]);

  const setMeasurement = useCallback(
    (value: number) => {
      if (!selectionRange) {
        return;
      }
      setPulseWaveData(pulsewaveType, [
        {
          value,
          range: rangeToTime(windowRange, selectionRange, signalLengthMs),
        },
      ]);
    },
    [
      pulsewaveType,
      selectionRange,
      setPulseWaveData,
      signalLengthMs,
      windowRange,
    ]
  );

  return {
    windowRange,
    selectionRange: selectionRange,
    setMeasurement,
    setSelectionRange: setSelectionRange,
    setWindowRange,
  };
}
