import { shuffle } from "lib/sort";
import * as THREE from "three";

function sortPointBuffer(buffer: THREE.BufferAttribute, anchor: THREE.Vector3) {
  const points: { vec: THREE.Vector4; distance: number }[] = [];
  for (let i = 0; i < buffer.count; i++) {
    const vec = new THREE.Vector4(
      buffer.getX(i),
      buffer.getY(i),
      buffer.getZ(i),
      buffer.getW(i)
    );

    const point = new THREE.Vector3(vec.x, vec.y, vec.z);

    const distance = anchor.distanceToSquared(point);

    points.push({ vec, distance });
  }

  points.sort((a, b) => {
    return b.distance - a.distance;
  });

  for (let i = 0; i < buffer.count; i++) {
    const { x, y, z, w } = points[i].vec;
    buffer.setXYZW(i, x, y, z, w);
  }
}

/*
  Ensure all points always have a corresponding point in each pointcloud
  we transition between, we assume a fixed length when we create the random
  pointcloud, then as pointclouds are loaded, we may need to duplicate positions.

  There is also an option to make a point transparent by setting the 4th component
  of the vector between 0 and 1.

  We do this particularly for the heart pointcloud as it typicall only uses
  1/3 of the amount of points compared to the avatar.
*/
export function normalizePCDBuffers(
  length: number,
  front: THREE.BufferGeometry,
  back: THREE.BufferGeometry,
  heart: THREE.BufferGeometry
) {
  const frontSource = front.getAttribute("position");
  const backSource = back.getAttribute("position");
  const heartSource = heart.getAttribute("position");

  const array = new Float32Array(length * 4);
  const frontBuffer = new THREE.Float32BufferAttribute(array, 4);
  const backBuffer = frontBuffer.clone();

  for (let index = 0; index < length; index++) {
    {
      const sourceIndex = index % frontSource.count;
      const x = frontSource.getX(sourceIndex);
      const y = frontSource.getY(sourceIndex);
      const z = frontSource.getZ(sourceIndex);
      frontBuffer.setXYZW(index, x, y, z, index < frontSource.count ? 1 : 0);
    }

    {
      const sourceIndex = index % backSource.count;
      const x = backSource.getX(sourceIndex);
      const y = backSource.getY(sourceIndex);
      const z = backSource.getZ(sourceIndex);
      backBuffer.setXYZW(index, x, y, z, index < backSource.count ? 1 : 0);
    }
  }

  /*
    Sort front and back from top to bottom to avoid
    legs mophing into the head for example when
    transitioning between front and back.
  */
  const anchor = new THREE.Vector3(0, 0, 2500);
  sortPointBuffer(frontBuffer, anchor);
  sortPointBuffer(backBuffer, anchor);

  const heartBuffer = frontBuffer.clone();
  const indexMapping = Array.from({ length: heartBuffer.count }).map(
    (_, index) => index
  );
  shuffle(indexMapping);

  for (let index = 0; index < length; index++) {
    const sourceIndex = index % heartSource.count;
    const drainIndex = indexMapping[index];
    const x = heartSource.getX(sourceIndex);
    const y = heartSource.getY(sourceIndex);
    const z = heartSource.getZ(sourceIndex);
    const visible = index < heartSource.count;
    heartBuffer.setXYZW(drainIndex, x, y, z, visible ? 1 : 0);
  }

  return { front: frontBuffer, back: backBuffer, heart: heartBuffer };
}
