import { APITypesV1 } from "@cur8/api-client";
import { BoundingBoxAnnotation } from "@cur8/rich-entity";

export function toCreatePhysicalArtefactRequest(
  annotation: BoundingBoxAnnotation
): APITypesV1.CreatePhysicalArtefactFromAnnotationRequest {
  return {
    annotationId: annotation.id,
    tracked: annotation.acceptState === "accepted",
    size: annotation.classification?.size ?? null,
    state: APITypesV1.PhysicalArtefactState.None,
    type: APITypesV1.PhysicalArtefactType.SkinLesion,
    skinLesionClassification: APITypesV1.SkinLesionClassification.Other,
  };
}
