import styles from "./styles.module.sass";

export default function NoSummary() {
  return (
    <div className={styles.NoSummary}>
      <label>Previous Summary</label>
      <div className={styles.text}>No previous summary found</div>
    </div>
  );
}
