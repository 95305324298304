import { useCallback } from "react";
import { PatientBlobURI } from "@cur8/uri";
import { OutputImageSize, SourceImageCrop } from "lib/api/types";
import { useAPIClient } from "render/context/APIContext";
import { useTranscoderImage } from "../useTranscoderImage";

export function usePatientBlobImage(
  uri: PatientBlobURI,
  size?: OutputImageSize,
  crop?: SourceImageCrop
) {
  const transcoder = useAPIClient().transcode;

  const { patientId, path } = uri;

  const fetch = useCallback(() => {
    return transcoder.fetchPatientImage(
      {
        patientId: patientId,
        imageName: path,
      },
      crop,
      size
    );
  }, [transcoder, patientId, path, size, crop]);

  return useTranscoderImage(fetch);
}
