import { clamp, linspace, mapLinear, snap } from "lib/math";
import { ReactNode, useCallback, useMemo } from "react";
import { Typography } from "../Typography";
import styles from "./styles.module.sass";

interface Bounds {
  min: number;
  max: number;
}

type Highlight = "none" | "danger" | "warning" | "normal";

type DominantSide = "left" | "right" | null;

interface BalanceGraphProps {
  value: number;
  bounds: Bounds;
  labels: { from: ReactNode; to: ReactNode };
  steps?: number;
  highlight?: Highlight;
}

export default function BalanceGraph({
  labels,
  value,
  bounds,
  highlight = "none",
  steps = 15,
}: BalanceGraphProps) {
  const normalizedValue = mapLinear(value, bounds.min, bounds.max, -0.5, 0.5);

  const circles = useMemo(() => Array.from({ length: steps }), [steps]);
  const normalizedCirclePositions = useMemo(
    () => linspace(-0.5, 0.5, circles.length),
    [circles.length]
  );

  const dominantSide: DominantSide = useMemo(() => {
    const minDiscreetUnit = 1 / (circles.length - 1);
    const hasDominantSide = Math.abs(normalizedValue) > minDiscreetUnit;

    if (!hasDominantSide) {
      return null;
    }

    return normalizedValue < 0 ? "left" : "right";
  }, [normalizedValue, circles.length]);

  const label = useMemo(
    () => (dominantSide ? `+${Math.round(Math.abs(value))}` : "Even"),
    [dominantSide, value]
  );

  const labelPositionXpercent = useMemo(() => {
    const stepSize = 1 / (circles.length - 1);
    const snappedValue = snap(normalizedValue, stepSize, Math.floor);

    return clamp(snappedValue * 100 + 50, 0, 100);
  }, [normalizedValue, circles.length]);

  const isCircleFilled = useCallback(
    (circleIndex: number) => {
      const normalizedCirclePosition = normalizedCirclePositions[circleIndex];

      let isFilled = false;

      if (!dominantSide) {
        const middleCircleIndex = Math.floor(circles.length / 2);
        isFilled = circleIndex === middleCircleIndex;
      } else {
        isFilled =
          dominantSide === "left"
            ? normalizedCirclePosition <= 0 &&
              normalizedCirclePosition >= normalizedValue
            : normalizedCirclePosition >= 0 &&
              normalizedCirclePosition <= normalizedValue;
      }

      return isFilled;
    },
    [normalizedValue, dominantSide, normalizedCirclePositions, circles.length]
  );

  return (
    <div
      className={styles.BalanceGraph}
      data-highlight={highlight}
      data-dominant-side={dominantSide}
    >
      <div className={styles.bar}>
        <div
          className={styles.value}
          style={{ left: `${labelPositionXpercent}%` }}
        >
          <Typography variant="label-m">{label}</Typography>
        </div>
        <div className={styles.circles}>
          {circles.map((_, index) => (
            <div
              key={index}
              className={styles.circle}
              data-filled={isCircleFilled(index)}
            />
          ))}
        </div>
      </div>

      <div className={styles.labels}>
        <div className={styles.label} data-strong={dominantSide === "left"}>
          <Typography variant="label-m">{labels.from}</Typography>
        </div>
        <div className={styles.label} data-strong={dominantSide === "right"}>
          <Typography variant="label-m">{labels.to}</Typography>
        </div>
      </div>
    </div>
  );
}
