import { Length } from "@cur8/measurements";

interface BodyMetricLengthProps {
  length?: Length;
  precision?: number;
}

export default function BodyMetricLength({
  length,
  precision = 0,
}: BodyMetricLengthProps) {
  return (
    <span>
      {length ? (length.meters * 100).toFixed(precision) : "--"}
      &nbsp;cm
    </span>
  );
}
