export type Plot = {
  signal: number[];
  signal_length_sec: number;
};

export function cutSignal(plot: Plot, duration: number) {
  const len = (plot.signal.length / plot.signal_length_sec) * duration;

  return plot.signal.slice(0, len);
}
