import { BrachialPressure } from "@cur8/measurements";
import { Patient } from "@cur8/rich-entity";
import { BodySide } from "lib/types/body";
import { useABIScore } from "render/hooks/api/metrics/useABIScore";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import { Typography } from "render/ui/presentation/Typography";
import { BloodPressureType } from "../../../ArterialHealthLayout/lib/projection";
import ArterialFlowResultItem from "../ArterialFlowResultItem";
import BloodPressureResultItem from "../BloodPressureResultItem";
import styles from "./styles.module.sass";

interface CirculationSectionProps {
  patient: Patient;
  side: BodySide;
}

const TileForSide: Record<BodySide, string> = {
  left: "Left",
  right: "Right",
};

const MetricTypeForBloodPressureType: Record<
  BloodPressureType,
  keyof BrachialPressure
> = {
  diastolic_blood_pressure: "diastolic",
  systolic_blood_pressure: "systolic",
};

export default function CirculationSection({
  patient,
  side,
}: CirculationSectionProps) {
  const {
    metrics: { cardio },
  } = usePatientData();

  const bloodPressureType: BloodPressureType = "systolic_blood_pressure";

  const bloodPressureValue =
    cardio.brachialPressure[side]?.at(0)?.unit[
      MetricTypeForBloodPressureType[bloodPressureType]
    ].mmHg;

  const prevBloodPressureValue =
    cardio.brachialPressure[side]?.at(1)?.unit[
      MetricTypeForBloodPressureType[bloodPressureType]
    ].mmHg;

  const currentAbiValue = useABIScore(side, cardio);

  const prevBrachialLeft = cardio.brachialPressure["left"]?.at(1)
    ? [cardio.brachialPressure["left"]?.at(1)!]
    : undefined;
  const prevBrachialRight = cardio.brachialPressure["right"]?.at(1)
    ? [cardio.brachialPressure["right"]?.at(1)!]
    : undefined;

  const prevAnkleLeft = cardio.anklePressure["left"]?.at(1)
    ? [cardio.anklePressure["left"]?.at(1)!]
    : undefined;
  const prevAnkleRight = cardio.anklePressure["right"]?.at(1)
    ? [cardio.anklePressure["right"]?.at(1)!]
    : undefined;

  const prevCardio = {
    brachialPressure: {
      left: prevBrachialLeft,
      right: prevBrachialRight,
    },
    anklePressure: {
      left: prevAnkleLeft,
      right: prevAnkleRight,
    },
  };
  const prevAbiValue = useABIScore(side, prevCardio);

  return (
    <div className={styles.CirculationSection}>
      <Typography variant="title-m">{TileForSide[side]}</Typography>

      <hr />

      <div className={styles.ResultContainer}>
        {bloodPressureValue && (
          <BloodPressureResultItem
            patient={patient}
            currentValue={bloodPressureValue}
            previousValue={prevBloodPressureValue}
            bloodPressureType={bloodPressureType}
          />
        )}
        {currentAbiValue && (
          <ArterialFlowResultItem
            currentValue={currentAbiValue?.score}
            previousValue={prevAbiValue?.score}
          />
        )}
      </div>
    </div>
  );
}
