import { useEffect, useState } from "react";

export function useBlobImage(blob?: Blob) {
  const [image, setImage] = useState<HTMLImageElement>();

  useEffect(() => {
    if (!blob) {
      return;
    }

    const url = URL.createObjectURL(blob);
    const image = new Image();
    image.src = url;
    setImage(image);

    return () => {
      setImage(undefined);
      URL.revokeObjectURL(url);
    };
  }, [blob]);

  return image;
}
