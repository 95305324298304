import { useCallback, useEffect, useState } from "react";

export enum PlaybackState {
  Paused = "paused",
  Playing = "playing",
}

export type AudioPlaybackControls = ReturnType<typeof useAudioPlayback>;

export function useAudioPlayback(audio?: HTMLAudioElement) {
  const [state, setState] = useState(PlaybackState.Paused);
  const [duration, setDuration] = useState(audio?.duration ?? NaN);
  const [time, setTime] = useState(0);

  const play = useCallback(() => {
    audio?.play();
  }, [audio]);

  const pause = useCallback(() => {
    audio?.pause();
  }, [audio]);

  const toggle = useCallback(() => {
    if (!audio) {
      return;
    }

    if (audio.paused) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [audio]);

  useEffect(() => {
    if (!audio || state !== "playing") {
      return;
    }

    let id = -1;

    const update = () => {
      id = requestAnimationFrame(update);
      setTime(audio.currentTime);
    };

    update();

    return () => {
      cancelAnimationFrame(id);
    };
  }, [audio, state]);

  useEffect(() => {
    if (!audio) {
      return;
    }

    setDuration(audio.duration);

    const handleMetadata = () => {
      setDuration(audio.duration);
    };

    const handlePlay = () => {
      setState(PlaybackState.Playing);
    };

    const handlePause = () => {
      setState(PlaybackState.Paused);
    };

    const handleTime = () => {
      setTime(audio.currentTime);
    };

    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("timeupdate", handleTime);
    audio.addEventListener("loadedmetadata", handleMetadata);

    return () => {
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("timeupdate", handleTime);
      audio.removeEventListener("loadedmetadata", handleMetadata);

      setState(PlaybackState.Paused);
      setTime(0);
      setDuration(NaN);

      audio.pause();
    };
  }, [audio]);

  const progress = audio ? audio.currentTime / audio.duration : NaN;

  const setProgress = useCallback(
    (progress: number) => {
      if (!audio) {
        return;
      }

      audio.currentTime = audio.duration * progress;
    },
    [audio]
  );

  return {
    play,
    pause,
    toggle,
    state,
    duration,
    time,
    progress,
    setProgress,
  };
}
