import MetricStatus from "render/ui/presentation/MetricStatus";
import ArterialStiffnessIcon from "./assets/arterial-stiffness.svg?react";

export default function ArterialStiffnessItem() {
  return (
    <MetricStatus caption="Arterial stiffness" summaryContent="">
      <ArterialStiffnessIcon />
    </MetricStatus>
  );
}
