import { Box } from "@cur8/rich-entity";
import {
  clampBox,
  fetchLesionImage,
  overscan,
} from "lib/api/resolvers/annotation";
import { silenceAbort } from "lib/error";
import { Lesion, LesionAnnotation } from "lib/lesion";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useBlobImage } from "render/hooks/useBlobImage";
import LesionId from "render/ui/format/LesionId";
import LocatorBox from "./components/LocatorBox";
import styles from "./styles.module.sass";

function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface LesionImageProps {
  active: boolean;
  lesion: Lesion;
  annotation: LesionAnnotation;
}

export default function LesionImage({
  active,
  lesion,
  annotation,
}: LesionImageProps) {
  const [motif, setMotif] = useState<{ blob: Blob; crop: Box }>();
  const [ready, setReady] = useState(false);

  const api = useAPIClient();

  useEffect(() => {
    if (!active || !annotation) {
      return;
    }

    let abandoned = false;

    const engage = async () => {
      await wait(250 + 750 * Math.random());
      if (abandoned) {
        return;
      }

      const crop = overscan(annotation.data.rect, 4);
      const res = { w: Math.min(160, crop.w), h: Math.min(160, crop.h) };
      const request = fetchLesionImage(api, annotation, crop, res);
      request?.result
        .then((image) => {
          if (abandoned) {
            return;
          }

          const { w, h } = image.meta.original.size;

          const bounds = new Box(0, 0, w, h);

          setMotif({ blob: image.blob, crop: clampBox(crop, bounds) });
        })
        .catch(silenceAbort);
    };

    engage();

    return () => {
      abandoned = true;
      setMotif(undefined);
    };
  }, [active, api, annotation]);

  const image = useBlobImage(motif?.blob);

  useEffect(() => {
    if (!image) {
      return;
    }

    image.decode().then(() => {
      setReady(true);
    });

    return () => {
      setReady(false);
    };
  }, [image]);

  return (
    <div className={styles.LesionImage}>
      {image && ready && (
        <img src={image.src} alt="Lesion" data-annotation-id={annotation?.id} />
      )}

      {annotation && motif?.crop && (
        <div className={styles.locator}>
          <LocatorBox annotation={annotation} crop={motif.crop} />
        </div>
      )}

      <div className={styles.name}>
        <LesionId lesion={lesion} />
      </div>
    </div>
  );
}
