import { Children, Fragment } from "react";

interface InterleaveProps {
  sep: React.ReactNode;
  children: React.ReactNode;
}

export default function Interleave({ sep, children }: InterleaveProps) {
  const parts: React.ReactNode[] = [];

  let count = 0;
  for (const child of Children.toArray(children)) {
    if (count++ > 0) {
      parts.push(<Fragment key={count}>{sep}</Fragment>);
    }
    parts.push(child);
  }

  return <>{parts}</>;
}
