import { ImmutableScan, Patient } from "@cur8/rich-entity";
import { URIType } from "@cur8/uri";
import { PathLink, useNav } from "@pomle/react-router-paths";
import PatientAge from "render/fragments/patient/PatientAge";
import PatientName from "render/fragments/patient/PatientName";
import PatientPhysicalIdentifier from "render/fragments/patient/PatientPhysicalIdentifier";
import { useRecording } from "render/hooks/api/useRecording";
import { paths } from "render/routes/paths";
import DayDate from "render/ui/format/DayDate";
import styles from "./styles.module.sass";

interface PatientSummaryProps {
  patient: Patient;
  scan: ImmutableScan;
}

export default function PatientSummary({ patient, scan }: PatientSummaryProps) {
  const patientId = patient.patientId;
  const sourceURI = scan.sourceUris.length > 0 ? scan.sourceUris[0] : undefined;
  const recordingId =
    sourceURI?.type === URIType.Recording ? sourceURI.recordingId : undefined;
  const deviceId =
    sourceURI?.type === URIType.Recording ? sourceURI.deviceId : undefined;
  const nav = {
    patient: useNav(paths.patient.detail),
  };
  const recording = useRecording(deviceId, recordingId);
  return (
    <div className={styles.PatientSummary}>
      <div>
        <div className={styles.identity}>
          <div className={styles.name}>
            <PathLink to={nav.patient.to({ patientId })}>
              <PatientName patient={patient} />
            </PathLink>
          </div>
          <div>
            <PatientPhysicalIdentifier patient={patient} />
            {patient.dateOfBirth && (
              <>
                {" "}
                (<PatientAge patient={patient} /> years)
              </>
            )}
          </div>
        </div>
        <div className={styles.date}>
          This Scan: <DayDate date={scan.timestamp} />
        </div>
      </div>
      {recording && (
        <div className={styles.recdata}>
          <div>
            <i>Rec</i>: {recording.id}
            <br />
            <i>Dev</i>: {recording.deviceId}
            <br />
          </div>
        </div>
      )}
    </div>
  );
}
