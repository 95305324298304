import { VisitSummary } from "@cur8/rich-entity";
import AssessmentAuthor from "render/pages/AtlasPage/components/SkinAssessment/components/AssessmentAuthor";
import MarkdownText from "../MarkdownText";
import styles from "./styles.module.sass";

interface VisitSummaryViewProps {
  visits: VisitSummary[];
}

export default function VisitSummaryView({ visits }: VisitSummaryViewProps) {
  return (
    <div className={styles.VisitSummaryView}>
      <div className={styles.content}>
        {visits.map((visit, index) => {
          return (
            <div className={styles.item} key={index}>
              <div className={styles.date}>
                {visit.visitDate.toLocaleString()}
              </div>
              <div className={styles.summary}>
                <MarkdownText key={index}>
                  {`${visit.summaryText}`}
                </MarkdownText>
              </div>
              {visit.audit.created?.timeStamp && (
                <div className={styles.doctor}>
                  <AssessmentAuthor
                    userUri={visit.audit.created?.userUri}
                    date={visit.audit.created?.timeStamp}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}
