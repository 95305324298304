import { APITypesV1 } from "@cur8/api-client";
import { ImmutableScan } from "@cur8/rich-entity";
import { useAPIClient } from "render/context/APIContext";
import { useMSAL } from "render/context/MSALContext";

export type ProblemReport = {
  category: string;
  feedback: string;
};

/**
 * Save problem report
 *
 */

export function useReportProblem() {
  const api = useAPIClient();
  const { auth } = useMSAL();

  async function saveProblemReportResult(
    scan: ImmutableScan,
    problem: ProblemReport
  ) {
    const reporter = auth?.account?.name;
    const user = auth?.account?.username;

    api.scan.createScanResult({
      patientId: scan.patientId,
      scanId: scan.id,
      scanVersion: scan.version,
      resultName: "internalProblemReport",
      state: APITypesV1.ResultState.Complete,
      internalData: {
        $type: "InternalProblemReport",
        ...problem,
        reporter,
        user,
      },
    });
  }

  return { saveProblemReportResult };
}
