import { Risk } from "@cur8/health-risks-calc";
import { useCallback } from "react";

const RiskMap: Record<Risk, string> = {
  [Risk.Unknown]: "",
  [Risk.Optimal]: "Optimal",
  [Risk.Normal]: "normal",
  [Risk.Risk]: "risk",
  [Risk.HighRisk]: "high-risk",
  [Risk.ImmediateRisk]: "immediate",
};

const RiskNames: Record<Risk, string> = {
  [Risk.Unknown]: "--",
  [Risk.Optimal]: "Optimal",
  [Risk.Normal]: "Normal",
  [Risk.Risk]: "Risk",
  [Risk.HighRisk]: "High Risk",
  [Risk.ImmediateRisk]: "Immediate Risk",
};

type Summary = {
  risk: Risk;
};

interface ResultRowProps<Value> {
  caption: React.ReactNode;
  unit?: React.ReactNode;
  value?: Value;
  summarize?: (value: Value) => Summary;
  format(value: Value): React.ReactNode;
}

export default function ResultRow<Value>({
  caption,
  value,
  summarize,
  format,
  unit: unitName,
}: ResultRowProps<Value>) {
  const handleCopy = useCallback(
    (event: React.PointerEvent<HTMLButtonElement>) => {
      const text = event.currentTarget.textContent;
      if (text) {
        navigator.clipboard.writeText(text);
      }
    },
    []
  );

  const summary = value && summarize ? summarize(value) : null;

  return (
    <tr data-risk={summary ? RiskMap[summary.risk] : ""}>
      <td>{caption}</td>
      <td className="value">
        {value != null ? (
          <button onClick={handleCopy}>{format(value)}</button>
        ) : (
          "--"
        )}
      </td>
      <td className="risk">{summary ? RiskNames[summary.risk] : ""}</td>
      <td>{unitName}</td>
    </tr>
  );
}
