export function SmokingFrequency({ value: numCigarettes }: { value: number }) {
  if (numCigarettes >= 1 && numCigarettes <= 3) {
    return <>1-3 cigarettes / day</>;
  } else if (numCigarettes > 3 && numCigarettes < 10) {
    return <>Less than half a pack / day</>;
  } else if (numCigarettes >= 10 && numCigarettes < 15) {
    return <>Half a pack / day</>;
  } else if (numCigarettes >= 15 && numCigarettes < 20) {
    return <>More than half a pack / day</>;
  } else if (numCigarettes >= 20 && numCigarettes < 30) {
    return <>A pack / day</>;
  } else if (numCigarettes >= 30) {
    return <>More than a pack / day</>;
  } else {
    return <>No</>;
  }
}
