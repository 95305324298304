import { ImmutableScan } from "@cur8/rich-entity";
import { Plot } from "lib/api/cardio";
import { parseAsJSON } from "lib/parse";
import { useImmutableScanBlob } from "render/hooks/api/useImmutableScanBlob";

type PlotSource = {
  signal: number[];
  timestamps: number[];
};

function toPlot(source: PlotSource): Plot {
  const first = source.timestamps.at(0) ?? NaN;
  const last = source.timestamps.at(-1) ?? NaN;

  return {
    signal: source.signal,
    signal_length_sec: last - first,
  };
}

function parse(response: Response) {
  return parseAsJSON<PlotSource>(response).then(toPlot);
}

export function usePlotBlob(
  scan: ImmutableScan | undefined,
  resultName: string
) {
  return useImmutableScanBlob({
    scan,
    resultName,
    path: "data.json",
    parse,
  });
}
