import React from "react";
import styles from "./styles.module.sass";

export default function MenuButton({
  children,
  ...props
}: React.ButtonHTMLAttributes<any>) {
  return (
    <button type="button" className={styles.MenuButton} {...props}>
      <div className={styles.icon}>{children}</div>
      <label>{props.title}</label>
    </button>
  );
}
