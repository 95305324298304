import { BrachialPressure } from "@cur8/measurements";
import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

interface BloodPressureProps {
  brachial: BrachialPressure;
}

export default function BloodPressure({ brachial }: BloodPressureProps) {
  return (
    <span className={styles.BloodPressure}>
      <Typography as="span" variant="numeral-m" className={styles.systolic}>
        {brachial.systolic.mmHg}
      </Typography>
      <Typography as="span" variant="body-m" className={styles.diastolic}>
        &nbsp;/&nbsp;{brachial.diastolic.mmHg}
      </Typography>
    </span>
  );
}
