import { MetricName } from "@cur8/measurements";
import { Metric } from "lib/metric";
import { BodyMetrics } from "lib/types/metrics/body";
import { useCallback } from "react";
import { useMetricAPI } from "render/hooks/api/useMetricAPI";

export function useBodyMetricStore() {
  const { fetchMetrics, storeMetrics } = useMetricAPI();

  const fetchBodyMetrics = useCallback(
    async (patientId: string): Promise<BodyMetrics> => {
      async function fetchMetric<M extends MetricName>(name: M) {
        return (await fetchMetrics(patientId, name, 1)).at(0);
      }

      const [
        biceps,
        calf,
        calf_left,
        calf_right,
        height,
        hip,
        neck,
        waist,
        weight,
        femoral_instep_left,
        femoral_instep_right,
        ssn_carotis_left,
        ssn_femoralis_left,
        ssn_symphysis,
        ssn_piezo_finger_left,
        ssn_piezo_finger_right,
      ] = await Promise.all([
        fetchMetric("body.biceps"),
        fetchMetric("body.calf"),
        fetchMetric("body.calf.left"),
        fetchMetric("body.calf.right"),
        fetchMetric("body.height"),
        fetchMetric("body.hip"),
        fetchMetric("body.neck"),
        fetchMetric("body.waist"),
        fetchMetric("body.weight"),

        fetchMetric("cardio.distance.femoralis-left_instep.left"),
        fetchMetric("cardio.distance.femoralis-left_instep.right"),
        fetchMetric("cardio.distance.ssn_carotis.left"),
        fetchMetric("cardio.distance.ssn_femoralis.left"),
        fetchMetric("cardio.distance.ssn_symphysis"),
        fetchMetric("cardio.distance.ssn_piezo-finger.left"),
        fetchMetric("cardio.distance.ssn_piezo-finger.right"),
      ]);

      return {
        biceps,
        calf,
        calf_left,
        calf_right,
        height,
        hip,
        neck,
        waist,
        weight,
        femoral_instep_left,
        femoral_instep_right,
        ssn_carotis_left,
        ssn_femoralis_left,
        ssn_symphysis,
        ssn_piezo_finger_left,
        ssn_piezo_finger_right,
      };
    },
    [fetchMetrics]
  );

  const storeBodyMetrics = useCallback(
    (patientId: string, metrics: BodyMetrics) => {
      const metricsWithValues = Object.values(metrics).filter((metric) =>
        metric.measurement.dimensions.every(
          (dimension) => !isNaN(dimension.value)
        )
      ) as Metric[];

      return storeMetrics(patientId, metricsWithValues);
    },
    [storeMetrics]
  );

  return { fetchBodyMetrics, storeBodyMetrics };
}
