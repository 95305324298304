import { fromAPI } from "@cur8/rich-entity";
import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSearch } from "render/hooks/useSearch";

export function useSearchPatient(query: string) {
  const patientAPI = useAPIClient().patient;

  const searchPatients = useCallback(
    async (query: string) => {
      const response = await patientAPI.queryPatients({ text: query }).result;
      return response.items.map(fromAPI.toPatientDetails);
    },
    [patientAPI]
  );

  return useSearch({ search: searchPatients, query, delay: 300 });
}
