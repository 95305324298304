import { Annotation } from "@cur8/rich-entity";
import { ViewStack } from "@pomle/react-viewstack";
import { matchesPanorama } from "lib/api/resolvers/annotation";
import { PanoramaImageURI } from "lib/api/uri";
import { useMemo } from "react";
import { filterPanoramaAnnotations } from "../../../ScanExplorer/filterPanoramaAnnotations";
import BulletIcon from "./assets/bullet.svg?react";
import PanoramaImage from "./components/PanoramaImage";
import styles from "./styles.module.sass";

interface ScanItemProps {
  panoramaURI: PanoramaImageURI;
  annotations: Annotation[];
  detectedAnnotations: Annotation[];
}

export default function ScanItem({
  panoramaURI,
  annotations,
  detectedAnnotations,
}: ScanItemProps) {
  const { noteCount, suggestedCount } = useMemo(() => {
    const { primaryAnnotations, nonPrimaryAnnotations } =
      filterPanoramaAnnotations(annotations, panoramaURI.recordingId);
    return {
      noteCount: primaryAnnotations.length,
      suggestedCount: nonPrimaryAnnotations.length,
    };
  }, [annotations, panoramaURI.recordingId]);

  const detections = useMemo(() => {
    return detectedAnnotations.filter((anno) => {
      return matchesPanorama(anno, panoramaURI);
    });
  }, [panoramaURI, detectedAnnotations]);

  return (
    <div className={styles.ScanItem}>
      <ViewStack>
        <div className={styles.meta}>
          <div>
            {noteCount > 0 && (
              <>
                <BulletIcon />
                &ensp;{noteCount} Notes
              </>
            )}
          </div>
          <div>
            {suggestedCount > 0 && (
              <>
                <BulletIcon className={styles.SuggestedIcon} />
                &ensp;{suggestedCount} Suggestions
              </>
            )}
          </div>
          <div>
            {detections && detections.length > 0 && (
              <>
                <BulletIcon className={styles.DetectionsIcon} />
                &ensp;{detections.length} Detections
              </>
            )}
          </div>
        </div>
        <div className={styles.image}>
          <PanoramaImage uri={panoramaURI} />
        </div>
      </ViewStack>
    </div>
  );
}
