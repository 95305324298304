import { Assignment } from "@cur8/rich-entity";
import { useCallback } from "react";
import { usePopupDialog } from "render/context/PopupDialogContext";
import TerminateAssignmentDialog from "render/fragments/assignments/TerminateAssignmentDialog";

export function useAssignmentTerminatePopup() {
  const { create } = usePopupDialog();

  const emitDialog = useCallback(
    (
      assignment: Assignment,
      { onSuccess }: { onSuccess: (assignment: Assignment) => void }
    ) => {
      const { emit, close } = create();
      emit(
        <TerminateAssignmentDialog
          assignment={assignment}
          onClose={close}
          onSuccess={(updatedAssignment) => {
            onSuccess(updatedAssignment);
            close();
          }}
        />
      );
    },
    [create]
  );

  return {
    emitDialog,
  };
}
