import { useCallback } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useSearch } from "render/hooks/useSearch";

export function useQueryDeviceByAlias(query: string) {
  const assignmentAPI = useAPIClient().assignment;

  const searchDevice = useCallback(
    async (alias: string) => {
      const response = await assignmentAPI.queryDevicesBy({
        alias,
        inUse: false,
      }).result;

      return response.items;
    },
    [assignmentAPI]
  );

  return useSearch({ search: searchDevice, query, delay: 300 });
}
