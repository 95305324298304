import { ABI } from "@cur8/formulas";
import { Metric } from "lib/metric";
import { byPressure, bySystolic } from "lib/metric/sort";

export function calculateABI(
  brachialPressure: {
    left: Metric<"cardio.brachial_pressure.left">[];
    right: Metric<"cardio.brachial_pressure.right">[];
  },
  anklePressure:
    | Metric<"cardio.ankle_pressure.left">[]
    | Metric<"cardio.ankle_pressure.right">[]
) {
  const B = brachialPressure;
  const A = anklePressure;

  const latestB = [...B.left.slice(0, 1), ...B.right.slice(0, 1)];
  const latestA = [...A.slice(0, 1)];

  const highestB = latestB.sort(bySystolic("desc")).at(0);
  const highestA = latestA.sort(byPressure("desc")).at(0);

  if (!highestB || !highestA) {
    return;
  }

  return ABI.calculate({
    highestAnklePressure: {
      mmHg: highestA.unit.mmHg,
    },
    highestSystolicBrachialPressure: {
      systolic: {
        mmHg: highestB.unit.systolic.mmHg,
      },
    },
  }).abi;
}
