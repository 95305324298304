export const AgeBins = {
  LESS_THAN_30: "<30",
  BETWEEN_30_AND_34: "30-34",
  BETWEEN_35_AND_39: "35-39",
  BETWEEN_40_AND_44: "40-44",
  BETWEEN_45_AND_49: "45-49",
  BETWEEN_50_AND_54: "50-54",
  BETWEEN_55_AND_59: "55-59",
  BETWEEN_60_AND_67: "60-67",
  MORE_THAN_67: "67+",
} as const;

export type AgeBinsType = (typeof AgeBins)[keyof typeof AgeBins];

export function getFutureAgeGroups(age: number) {
  const allAgeBins: AgeBinsType[] = Object.values(AgeBins);

  const ageBinIndex = allAgeBins.findIndex((bin) => {
    if (bin.startsWith("<")) {
      const binAge = Number(bin.slice(1));
      return age < binAge;
    }

    if (bin.includes("-")) {
      const [startAge, endAge] = bin.split("-").map((ageStr) => Number(ageStr));
      return age >= startAge && age <= endAge;
    }

    if (bin.endsWith("+")) {
      const binAge = Number(bin.slice(1));
      return age > binAge;
    }

    const binAge = Number(bin);
    return age === binAge;
  });

  if (ageBinIndex === -1) {
    throw new Error(`Age bin not found for age ${age}`);
  }

  return allAgeBins.slice(ageBinIndex + 1);
}

export const getAgeBinForAge = (age: number) => {
  if (age < 30) {
    return AgeBins.LESS_THAN_30;
  }

  if (age <= 34) {
    return AgeBins.BETWEEN_30_AND_34;
  }

  if (age <= 39) {
    return AgeBins.BETWEEN_35_AND_39;
  }

  if (age <= 44) {
    return AgeBins.BETWEEN_40_AND_44;
  }

  if (age <= 49) {
    return AgeBins.BETWEEN_45_AND_49;
  }

  if (age <= 54) {
    return AgeBins.BETWEEN_50_AND_54;
  }

  if (age <= 59) {
    return AgeBins.BETWEEN_55_AND_59;
  }

  if (age <= 67) {
    return AgeBins.BETWEEN_60_AND_67;
  }

  return AgeBins.MORE_THAN_67;
};
