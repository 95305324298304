import { PhysicalIdType } from "@cur8/uri";

const HUMAN_READABLE: Record<PhysicalIdType, string> = {
  [PhysicalIdType.Unknown]: "Unknown",
  [PhysicalIdType.Legacy]: "Legacy ID",
  [PhysicalIdType.BankId]: "Swedish Bank ID",
  [PhysicalIdType.USSocialSecurityNo]: "US Social Security Number",
};

export function humanName(type: PhysicalIdType) {
  return HUMAN_READABLE[type];
}
