import { useCallback } from "react";
import { useAsyncHandle } from "render/hooks/useAsyncHandle";
import PopupDialog from "render/ui/layouts/PopupDialog";
import ActionButton from "render/ui/trigger/ActionButton";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { usePatientBookingToken } from "../../hooks/usePatientBookingToken";
import styles from "./styles.module.sass";
import { useInitiateRefundMutation } from "./hooks/useInitiateRefundMutation";

interface Props {
  patientId: string;
  onClose: () => void;
  onSuccess: () => void;
}

export default function RefundActiveBookingTokenPopup({
  patientId,
  onClose,
  onSuccess,
}: Props) {
  const { entries: bookingTokens } = usePatientBookingToken(patientId);
  const initiateRefundMutation = useInitiateRefundMutation();
  const token = bookingTokens?.at(0)?.data;

  const orderId = token?.orderId;
  const actionButtonText = orderId ? "Burn and Refund" : "Burn";

  const handleSubmit = useCallback(async () => {
    const token = bookingTokens?.at(0)?.data;
    if (!token) {
      return;
    }

    return initiateRefundMutation
      .mutate({
        patientId,
        bookingTokenId: token.id,
        eTag: token.eTag!,
      })
      .then(() => {
        onSuccess();
        setTimeout(onClose, 200);
      });
  }, [bookingTokens, onSuccess, onClose, initiateRefundMutation, patientId]);

  const submitHandler = useAsyncHandle(handleSubmit);

  return (
    <PopupDialog>
      <div className={styles.RefundActiveBookingTokenPopup}>
        <div className={styles.content}>
          <header>
            {orderId ? (
              <h2>Initiate refund of active booking token</h2>
            ) : (
              <h2>Burn active booking token</h2>
            )}
          </header>
          <section className={styles.body}>
            <p>
              This will mark the member's token as no longer valid for booking.
            </p>
            <p>
              Associated single use full discount code will be made valid again.
            </p>
            {orderId ? (
              <p>
                It will also immediately initiate the refund process with Stripe
                if the order amount is non-zero.
              </p>
            ) : null}
          </section>
          <footer className={styles.footer}>
            <HoverTextButton type="button" onClick={onClose}>
              Cancel
            </HoverTextButton>

            <ActionButton
              data-variant="danger"
              disabled={submitHandler.busy}
              handle={submitHandler}
            >
              {actionButtonText}
            </ActionButton>
          </footer>
        </div>
      </div>
    </PopupDialog>
  );
}
