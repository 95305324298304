import { classNames } from "@pomle/classnames";
import { IconProps } from ".";
import styles from "./styles.module.sass";

export default function DownloadIcon({ active, color }: IconProps) {
  return (
    <svg
      className={classNames(styles.Common, styles.DownloadIcon)}
      data-active={active ? "yes" : "no"}
      data-color={color}
      fill="none"
      height="40"
      width="40"
      viewBox="0 0 40 40"
    >
      <circle cx="20" cy="20" r="19" />
      <path d="M11.88 28.82q-.78 0-1.36-.58-.59-.59-.59-1.36v-4.64h1.95v4.64h16.85v-4.64h1.94v4.64q0 .77-.58 1.36-.59.58-1.36.58Zm8.42-4.96-6.25-6.25 1.4-1.4 3.88 3.9V8.07h1.94V20.1l3.9-3.88 1.39 1.39Z" />
    </svg>
  );
}
