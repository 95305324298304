import { PatientBlobURI } from "@cur8/uri";
import { OutputImageSize } from "lib/api/types";
import { usePatientBlobImage } from "render/hooks/api/usePatientBlobImage";

interface PatientBlobImageProps {
  uri: PatientBlobURI;
  size?: OutputImageSize;
}

export default function PatientBlobImage({ uri, size }: PatientBlobImageProps) {
  const image = usePatientBlobImage(uri, size);

  if (!image) {
    return null;
  }

  return (
    <img
      src={image.src}
      alt=""
      height={image.naturalHeight}
      width={image.naturalWidth}
    />
  );
}
