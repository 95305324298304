import React, { PropsWithChildren } from "react";
import SquircleArrow from "render/assets/svg/squircle-arrow";
import { Typography } from "../Typography";
import styles from "./styles.module.sass";

interface MetricStatusProps extends PropsWithChildren {
  caption: React.ReactNode;
  summaryContent: React.ReactNode;
  differenceToPreviousValue?: number | undefined;
  unit?: string;
}

export default function MetricStatus({
  children,
  caption,
  summaryContent,
  differenceToPreviousValue,
  unit,
}: MetricStatusProps) {
  const [mode, setMode] = React.useState<"summary" | "change">("summary");
  const handleOnClick = () => {
    if (differenceToPreviousValue !== undefined) {
      setMode((prev) => (prev === "summary" ? "change" : "summary"));
    }
  };

  const arrowDirection =
    differenceToPreviousValue !== undefined && differenceToPreviousValue > 0
      ? "upwards"
      : "downwards";

  return (
    <div className={styles.MetricResultTile}>
      <div className={styles.content}>{children}</div>
      <div className={styles.caption} onClick={handleOnClick}>
        <Typography as="span" variant="label-m">
          {caption}
        </Typography>
      </div>

      <div onClick={handleOnClick}>
        {mode === "summary" && (
          <div className={styles.status}>{summaryContent}</div>
        )}
        {mode === "change" && (
          <div className={styles.valuesDiffContainer}>
            <Typography variant="body-m" className={styles.diff}>
              {differenceToPreviousValue !== undefined &&
                differenceToPreviousValue !== 0 && (
                  <SquircleArrow direction={arrowDirection} />
                )}
              {differenceToPreviousValue !== undefined ? (
                <>
                  {differenceToPreviousValue === 0 && (
                    <Typography as="span" variant="body-m">
                      No change
                    </Typography>
                  )}
                  {differenceToPreviousValue !== 0 && (
                    <Typography as="span" variant="body-m">
                      {Math.abs(differenceToPreviousValue)}
                    </Typography>
                  )}
                </>
              ) : (
                "--"
              )}
            </Typography>
            {unit ? (
              <Typography variant="body-m" className={styles.unit}>
                {unit}
              </Typography>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}
