import { Sex } from "@cur8/rich-entity";
import RadioButtons from "render/ui/form/RadioButtons";

const SEX_TYPES = [
  {
    value: Sex.Male,
    content: "Male",
  },
  {
    value: Sex.Female,
    content: "Female",
  },
];

interface SexRadioInputProps {
  sex: Sex;
  onChange: (sex: Sex) => void;
  required?: boolean;
}

export default function SexRadioInput({
  sex,
  onChange,
  required = false,
}: SexRadioInputProps) {
  return (
    <RadioButtons
      entries={SEX_TYPES}
      value={sex}
      onChange={onChange}
      required={required}
    />
  );
}
