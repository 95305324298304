import styles from "./styles.module.sass";

interface InputProps {
  hint?: React.ReactNode;
  children?: React.ReactNode;
}

export default function Input({ hint, children }: InputProps) {
  return (
    <div className={styles.Input}>
      {children}

      {hint && <div className={styles.hint}>{hint}</div>}
    </div>
  );
}
