import { Annotation } from "@cur8/rich-entity";
import { RecordingURI, URIType } from "@cur8/uri";
import { hasBoundingBox } from "@cur8/rich-entity";

export function filterPanoramaAnnotations(
  annotations: Annotation[],
  recordingId: string
) {
  const primaryAnnotations = annotations
    .filter(hasBoundingBox)
    .filter((entry) => {
      return (
        entry.targetURI.type === URIType.Recording &&
        recordingId ===
          RecordingURI.fromString(entry.targetURI.toString()).recordingId
      );
    });

  const nonPrimaryAnnotations = annotations
    .filter(hasBoundingBox)
    .filter((entry) => {
      return (
        entry.targetURI.type === URIType.Recording &&
        recordingId !==
          RecordingURI.fromString(entry.targetURI.toString()).recordingId
      );
    })
    .filter((entry) => {
      return (
        primaryAnnotations.findIndex(
          (a) => a.physicalArtefactId === entry.physicalArtefactId
        ) === -1
      );
    });

  return {
    primaryAnnotations: primaryAnnotations,
    nonPrimaryAnnotations: nonPrimaryAnnotations,
  };
}
