import { APIFetchError } from "@cur8/api-client";
import { User } from "@cur8/rich-entity";
import { UserURI } from "@cur8/uri";
import { useEffect, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useStore } from "render/context/StoreContext";
import { useReporting } from "../useReporting";

export function useUser(uri?: UserURI) {
  const { handleError } = useReporting();

  const api = useAPIClient().user;

  const userURI = uri?.toString();

  const {
    entries: { get, set },
  } = useStore().user;

  useEffect(() => {
    if (!userURI) {
      return;
    }

    const req = api.getUser(userURI);

    req.result
      .then((data) => {
        const user: User = {
          displayName: data.displayName,
          uri: new UserURI(data.tenantId, data.objectId),
        };
        set(userURI, user);
      })
      .catch((error) => {
        if (error instanceof APIFetchError && error.response.status === 404) {
          return;
        }
        handleError(error);
      });

    return () => {
      req.abandon();
    };
  }, [set, api, handleError, userURI]);

  return useMemo(() => get(userURI ?? ""), [get, userURI]);
}
