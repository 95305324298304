import { Typography } from "render/ui/presentation/Typography";
import styles from "./styles.module.sass";

export function DashboardStyleGuidePage() {
  return (
    <div className={styles.DashboardStyleGuidePage}>
      <h1>Dashboard style guide</h1>

      <hr />

      <div>
        <Typography variant="display-s">Neko Display S</Typography>
      </div>
      <div>
        <Typography variant="title-m">Neko Title M</Typography>
      </div>
      <div>
        <Typography variant="title-s">Neko Title S</Typography>
      </div>
      <div>
        <Typography variant="body-m">Neko Body M</Typography>
      </div>
      <div>
        <Typography variant="body-s">Neko Body S</Typography>
      </div>
      <div>
        <Typography variant="body-xs">Neko Body XS</Typography>
      </div>
      <div>
        <Typography variant="label-m">Neko Label M</Typography>
      </div>
      <div>
        <Typography variant="label-s">Neko Label S</Typography>
      </div>
      <div>
        <Typography variant="eyebrow-m">Neko Eyebrow M</Typography>
      </div>

      <hr />

      <div>
        <Typography variant="label-s">Neko Numeral L</Typography>
        <Typography variant="numeral-l">0123456789</Typography>
      </div>
      <div>
        <Typography variant="label-s">Neko Numeral M</Typography>
        <Typography variant="numeral-m">0123456789</Typography>
      </div>
      <div>
        <Typography variant="label-s">Neko Numeral S</Typography>
        <Typography variant="numeral-s">0123456789</Typography>
      </div>
    </div>
  );
}
