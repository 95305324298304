import { Patient } from "@cur8/rich-entity";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import PatientName from "../PatientName";
import styles from "./styles.module.sass";

interface PatientIdentifierProps {
  patientId: string;
  patient: Patient | undefined;
}

export default function PatientIdentifier({
  patientId,
  patient,
}: PatientIdentifierProps) {
  const nav = useNav(paths.patient.detail);

  return (
    <div className={styles.PatientIdentifier}>
      <div>
        <PathLink to={nav.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "--"}
        </PathLink>
      </div>

      <code className={styles.id}>{patientId}</code>
    </div>
  );
}
