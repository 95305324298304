import { Value } from "@cur8/health-risks-calc";

/**
 * Converts a value from NGSP (National Glycohemoglobin Standardization Programme) units (%) to IFCC (International Federation of Clinical Chemistry) units (mmol/mol).
 *
 * @param ngsp - The value in NGSP units (%).
 * @returns The converted value in IFCC units (mmol/mol).
 *
 * @example
 * const ngspValue = { "%": 6.5 };
 * const ifccValue = NGSPtoIFCC(ngspValue);
 * console.log(ifccValue); // Output: { "mmol/mol": 48.000 }
 */
export const NGSPtoIFCC = (ngsp: Value<"%">): Value<"mmol/mol"> => {
  return { "mmol/mol": +((ngsp["%"] - 2.15) * 10.929).toFixed(3) };
};

/**
 * Converts a value from IFCC (International Federation of Clinical Chemistry) units (mmol/mol) to NGSP (National Glycohemoglobin Standardization Programme) units (%).
 *
 * @param ifcc - The value in IFCC units (mmol/mol).
 * @returns The converted value in NGSP units (%).
 *
 * @example
 * const ifccValue = { "mmol/mol": 48 };
 * const ngspValue = IFCCtoNGSP(ifccValue);
 * console.log(ngspValue); // Output: { "%": 6.500 }
 */
export const IFCCtoNGSP = (ifcc: Value<"mmol/mol">): Value<"%"> => {
  return { "%": +(ifcc["mmol/mol"] / 10.929 + 2.15).toFixed(3) };
};
