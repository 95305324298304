import { createPortal } from "react-dom";
import { useProjectionGraphContext } from "../ProjectionGraph.context";

export function ProjectionSvgPortal({ children }: React.PropsWithChildren) {
  const { svgContainer } = useProjectionGraphContext();

  if (!svgContainer) {
    return null;
  }

  return createPortal(children, svgContainer);
}
