import { useCallback } from "react";
import { CalendarEvent } from "render/pages/SchedulePage/reducer/slotManagementUtils";
import { useConfirmationModal } from "render/ui/layouts/ConfirmationModal";

export function useSlotStateChangeConfirmation() {
  const { emitConfirmationDialog } = useConfirmationModal();

  const emitSlotStateChangeConfirmation = useCallback(
    (
      slots: CalendarEvent[],
      action: "open" | "reserve" | "delete",
      onConfirm: () => Promise<void>
    ) => {
      emitConfirmationDialog({
        title: (
          <h2>
            Confirm slot{" "}
            {action === "open"
              ? "opening"
              : action === "reserve"
              ? "reserving"
              : "deletion"}
          </h2>
        ),
        body: (
          <>
            <p>
              You are about to make the following slots{" "}
              <strong>
                {action === "open"
                  ? "open"
                  : action === "reserve"
                  ? "reserved"
                  : "deleted"}
              </strong>
              :
            </p>

            <ul>
              {slots.map((event) => {
                const interval = event.interval;
                return (
                  <li key={interval.start.toUnixInteger()}>
                    {interval.start.toFormat("dd LLLL yyyy")} at{" "}
                    <strong>{interval.start.toFormat("HH:mm")}</strong> to{" "}
                    <strong>{interval.end.toFormat("HH:mm")}</strong>
                  </li>
                );
              })}
            </ul>

            <p>Do you want to proceed?</p>
          </>
        ),
        confirmButtonLabel: "Yes",
        cancelButtonLabel: "No",
        onConfirm,
      });
    },
    [emitConfirmationDialog]
  );

  return {
    emitSlotStateChangeConfirmation,
  };
}
