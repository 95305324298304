import { APITypesV1 } from "@cur8/api-client";
import { Assignment } from "@cur8/rich-entity";

export function toTerminateAssignmentRequest(
  assignment: Assignment,
  note: string
): APITypesV1.UpdateAssignmentRequest & { eTag: string } {
  return {
    eTag: assignment.eTag ?? "",
    doctorsNote: note,
    action: APITypesV1.AssignmentAction.Terminate,
  };
}
