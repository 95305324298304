type Direction = "asc" | "desc";

export function sortBy<T>(val: (value: T) => number) {
  return function sort(dir: Direction) {
    const invert = dir === "asc" ? 1 : -1;

    return function compare(a: T, b: T) {
      return (val(a) - val(b)) * invert;
    };
  };
}

export function shuffle(array: unknown[]) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}
