import { useCallback, useMemo } from "react";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import useCalendarInteraction from "../../../Calendar/hooks/useCalendarInteraction";
import useCalendarState from "../../../Calendar/hooks/useCalendarState";
import useSlotManagement from "../../../Calendar/hooks/useSlotManagement";
import CrossIcon from "./assets/cross-icon.svg?react";
import { useSlotStateChangeConfirmation } from "./hooks/useSlotStateChangeConfirmation";
import styles from "./styles.module.sass";

interface Props {
  onClose: () => void;
}

export default function EditingSlots({ onClose }: Props) {
  const { resetSlotSelection } = useCalendarInteraction();
  const { bulkSelectedSlots } = useCalendarState();
  const { deleteSlots, openSlots, reserveSlots } = useSlotManagement();

  const { emitSlotStateChangeConfirmation } = useSlotStateChangeConfirmation();

  const anySlotsSelected = useMemo(() => {
    return bulkSelectedSlots.length > 0;
  }, [bulkSelectedSlots]);

  const handleDeleteSelectedSlotsClicked = useCallback(() => {
    emitSlotStateChangeConfirmation(bulkSelectedSlots, "delete", () =>
      deleteSlots(bulkSelectedSlots).then(resetSlotSelection)
    );
  }, [
    bulkSelectedSlots,
    deleteSlots,
    emitSlotStateChangeConfirmation,
    resetSlotSelection,
  ]);

  const handleReserveSelectedSlotsClicked = useCallback(() => {
    emitSlotStateChangeConfirmation(bulkSelectedSlots, "reserve", () =>
      reserveSlots(bulkSelectedSlots).then(resetSlotSelection)
    );
  }, [
    bulkSelectedSlots,
    reserveSlots,
    emitSlotStateChangeConfirmation,
    resetSlotSelection,
  ]);

  const handleOpenSelectedSlotsClicked = useCallback(() => {
    emitSlotStateChangeConfirmation(bulkSelectedSlots, "open", () =>
      openSlots(bulkSelectedSlots).then(resetSlotSelection)
    );
  }, [
    bulkSelectedSlots,
    openSlots,
    resetSlotSelection,
    emitSlotStateChangeConfirmation,
  ]);

  return (
    <div className={styles.EditingSlots}>
      {anySlotsSelected ? (
        <>
          <HoverTextButton onClick={handleOpenSelectedSlotsClicked}>
            Open
          </HoverTextButton>
          <HoverTextButton onClick={handleReserveSelectedSlotsClicked}>
            Reserve
          </HoverTextButton>
          <HoverTextButton onClick={handleDeleteSelectedSlotsClicked}>
            Delete
          </HoverTextButton>
          <HoverTextButton onClick={onClose}>Cancel editing</HoverTextButton>
        </>
      ) : (
        <>
          Select the slots you want to create or change
          <button className={styles.button} onClick={onClose}>
            <CrossIcon />
          </button>
        </>
      )}
    </div>
  );
}
