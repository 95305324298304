import { HDL } from "@cur8/health-risks-calc";
import { Patient } from "@cur8/rich-entity";
import { useMemo } from "react";
import { usePatientData } from "render/pages/DashboardPage/context/PatientDataContext";
import MetricResultHeader, {
  Diff,
  DiffValue,
  MainTitle,
  Subtitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import RangeChart, {
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import { toChartRanges } from "render/ui/presentation/RangeChart/lib/risk";

interface HDLItemProps {
  patient: Patient;
}

const getTitlesForValueChange = (value?: number, previousValue?: number) => {
  if (value === undefined || previousValue === undefined) {
    return {
      title: "HDL",
      subtitle: "",
    };
  }

  if (value === previousValue) {
    return {
      title: "Maintained",
      subtitle: "HDL",
    };
  }

  if (value > previousValue) {
    return {
      title: "Increased",
      subtitle: "HDL",
    };
  }

  return {
    title: "Decreased",
    subtitle: "HDL",
  };
};

export default function HDLItem({ patient }: HDLItemProps) {
  const { metrics } = usePatientData();

  const riskRange = useMemo(() => {
    return HDL.rangesFor({ sex: patient.sex });
  }, [patient]);

  const ranges = useMemo(() => {
    return toChartRanges(riskRange.entries);
  }, [riskRange]);

  const entries = useMemo(() => {
    if (!metrics) {
      return [];
    }

    return metrics.bloodwork.hdl?.map((metric) => {
      const unit = metric.unit;
      return {
        risk: riskRange.findRisk(unit),
        timestamp: metric.measurement.timestampStart,
        value: unit["mmol/L"],
      };
    });
  }, [metrics, riskRange]);

  const latest = entries?.at(0);
  const value = latest?.value;
  const previous = entries?.at(1);
  const previousValue = previous?.value;

  const { title, subtitle } = getTitlesForValueChange(value, previousValue);

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>{title}</MainTitle>
          <Subtitle>{subtitle}</Subtitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={
              previousValue !== undefined && value !== undefined
                ? value - previousValue
                : undefined
            }
            formatValue={(value) => value?.toFixed(2)}
            unit="mmol/L"
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={ranges}
        value={value}
        previousValue={previousValue}
        formatValue={(value) => value?.toFixed(2)}
      >
        {({ ranges, values }) => <RangeChart ranges={ranges} values={values} />}
      </RangeChartDataComposer>
    </div>
  );
}
