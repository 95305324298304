import { Risk } from "@cur8/health-risks-calc";

export const RISK_COLORS: Record<Risk, string> = {
  [Risk.Unknown]: "#0274A2",
  [Risk.Optimal]: "#0274A2",
  [Risk.Normal]: "#0274A2",
  [Risk.Risk]: "#FFAF38",
  [Risk.HighRisk]: "#F96452",
  [Risk.ImmediateRisk]: "#A20025",
};
