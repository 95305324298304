import { Box } from "@cur8/rich-entity";
import { classNames } from "@pomle/classnames";
import { useCallback, useEffect, useState } from "react";
import colors from "render/pages/TissuePages/colors.module.sass";
import { getColorName, getRoIColor } from "render/pages/TissuePages/lib/utils";
import styles from "./styles.module.sass";

interface DisplayLabelProps {
  box: Box;
  isSelected: boolean;
  label: "forearm" | "hypothenar";
  onRoiSelect: (roi: "forearm" | "hypothenar") => void;
  pixelsPerCm: number | undefined;
}

export default function DisplayLabel({
  box,
  isSelected,
  label,
  onRoiSelect,
  pixelsPerCm,
}: DisplayLabelProps) {
  const [x, setX] = useState<number>();
  const [y, setY] = useState<number>();
  const [width, setWidth] = useState<string>();
  const [height, setHeight] = useState<string>();
  const [showRealSize, setShowRealSize] = useState<boolean>(false);

  useEffect(() => {
    setX(box.x);
    setY(box.y);
    if (pixelsPerCm) {
      setWidth((box.w / pixelsPerCm).toFixed(2));
      setHeight((box.h / pixelsPerCm).toFixed(2));
      setShowRealSize(true);
    } else {
      setWidth(box.w.toString());
      setHeight(box.h.toString());
      setShowRealSize(false);
    }
  }, [box, pixelsPerCm]);

  const onSelect = useCallback(
    (ev: React.MouseEvent) => {
      ev.stopPropagation();
      onRoiSelect(label);
    },
    [label, onRoiSelect]
  );

  return (
    <div
      className={styles.DisplayLabel}
      onClick={onSelect}
      data-selected={isSelected}
    >
      <div className={styles.label}>
        <div
          className={classNames(styles.Box, colors.border)}
          data-color={getColorName(getRoIColor(label === "forearm" ? 0 : 1))}
        ></div>
        <div className={styles.txt}>{label}</div>
      </div>
      {isSelected && (
        <div className={styles.infoBox}>
          <div className={styles.data}>
            <label>Position (x, y)</label>
            <div className={styles.item}>
              <div className={styles.value}>{x}</div>
              <div className={styles.unit}>:</div>
              <div className={styles.value}>{y}</div>
            </div>
          </div>
          <div className={styles.data}>
            <label>Size</label>
            <div className={styles.item}>
              <div className={styles.value}>{width}</div>
              <div className={styles.unit}>x</div>
              <div className={styles.value}>{height}</div>
              <div className={styles.unit}>{showRealSize ? "cm" : "px"}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
