import React from "react";
import styles from "./styles.module.sass";

interface SlidingWindowProps {
  activeIndex: number;
  children: React.ReactElement[];
}

export default function SlidingWindow({
  activeIndex,
  children,
}: SlidingWindowProps) {
  const size = children.length;
  return (
    <div className={styles.SlidingWindow}>
      <div
        className={styles.container}
        style={{
          transform: `translateX(${activeIndex * -(100 / size)}%)`,
          width: `${size * 100}%`,
        }}
      >
        {children}
      </div>
    </div>
  );
}
