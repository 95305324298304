import useCalendar from "../../hooks/useCalendar";
import styles from "./styles.module.sass";

export default function Hours() {
  const { hours } = useCalendar();
  return (
    <div className={styles.Hours}>
      {hours.map((hour) => (
        <div key={hour.toUnixInteger()} className={styles.time}>
          {hour.toFormat("HH:mm")}
        </div>
      ))}
    </div>
  );
}
