interface PersonnummerProps {
  nr: string;
}

export default function Personnummer({ nr }: PersonnummerProps) {
  return (
    <>
      {nr.slice(0, 8)}-{nr.slice(8, 12)}
    </>
  );
}
