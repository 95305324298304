import { ImmutableScan } from "@cur8/rich-entity";
import { Plot } from "lib/api/cardio";
import { parseAsJSON } from "lib/parse";
import { useImmutableScanBlob } from "render/hooks/api/useImmutableScanBlob";

function parse(response: Response) {
  return parseAsJSON<Plot>(response);
}

export function useFemoralPlot(scan: ImmutableScan) {
  return useImmutableScanBlob({
    scan,
    resultName: "ui_femoral",
    path: "data.json",
    parse,
  });
}
