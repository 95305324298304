import { createMetric } from "lib/metric";
import { EyePressureMetrics } from "./hooks/useEyePressureMetrics";

export type Entries = {
  eyePressureLeft: string;
  eyePressureRight: string;
  wearsContactLenses?: boolean;
};

export function toEntries(metrics: EyePressureMetrics): Entries {
  return {
    eyePressureRight: metrics.eyePressureRight?.unit.mmHg.toString() ?? "",
    eyePressureLeft: metrics.eyePressureLeft?.unit.mmHg.toString() ?? "",
    wearsContactLenses: metrics.wearsContactLenses?.unit,
  };
}

export function toMetrics(entries: Entries) {
  const metrics: EyePressureMetrics = {};

  {
    const value = parseFloat(entries.eyePressureLeft);
    if (isFinite(value)) {
      metrics.eyePressureLeft = createMetric(
        "risk_assessment.eye_pressure.left",
        {
          mmHg: value,
        }
      );
    }
  }

  {
    const value = parseFloat(entries.eyePressureRight);
    if (isFinite(value)) {
      metrics.eyePressureRight = createMetric(
        "risk_assessment.eye_pressure.right",
        {
          mmHg: value,
        }
      );
    }
  }

  {
    const value = entries.wearsContactLenses;
    if (value != null) {
      metrics.wearsContactLenses = createMetric(
        "risk_assessment.eye_pressure.wears_contact_lenses",
        entries.wearsContactLenses
      );
    }
  }

  return metrics;
}
