import { useEffect, useRef } from "react";

export function onWheel(event: WheelEvent) {
  if (
    event.target instanceof HTMLInputElement &&
    event.target.type === "number"
  ) {
    event.preventDefault();
  }
}

export function useEscapeNumberScroll<T extends HTMLElement>() {
  const ref = useRef<T>(null);

  useEffect(() => {
    const element = ref.current;

    if (!element) {
      return;
    }

    element.addEventListener("wheel", onWheel, { passive: false });

    return () => {
      element.removeEventListener("wheel", onWheel);
    };
  }, []);

  return ref;
}
