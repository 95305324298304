import { useCallback, useMemo } from "react";
import { useAPIClient } from "render/context/APIContext";

export function useRecharge(patientId: string) {
  const apiClient = useAPIClient();

  const recharge = useCallback(async () => {
    return apiClient.billingV2.stripeCharge({ patientId }).result;
  }, [apiClient, patientId]);

  return useMemo(
    () => ({
      recharge,
    }),
    [recharge]
  );
}
