import { Token } from "@arction/eventer";
import {
  AutoCursorModes,
  Axis,
  AxisTickStrategies,
  Band,
  ChartXY,
  Color,
  ColorHEX,
  ConstantLine,
  FontSettings,
  LegendBox,
  LineSeries,
  SolidFill,
  SolidLine,
  Themes,
  UIDraggingModes,
  disableThemeEffects,
  emptyFill,
  emptyLine,
  lightningChart,
} from "@arction/lcjs";
import { Point } from "lib/math";
import {
  findClosest,
  findClosestIndex,
} from "render/pages/TissuePages/lib/calculations";
import {
  RegionTimeseries,
  TimeRange,
} from "render/pages/TissuePages/lib/types";

export type OnBandCreated = {
  start: number;
  end: number;
  anchor: Point;
};

export type OnBandUpdated = {
  name: string;
} & OnBandCreated;

export type OnBandError = {
  message: string;
  code: number;
};

type BandProps = {
  start: number;
  end: number;
  name?: string;
  color?: Color;
  onTop?: boolean;
};
type BandEdit = {
  start: number;
  end: number;
};

type InteractionToken = Map<string, Token | undefined>;

/**
 * Fonts and colors for the chart
 */
const fontRegular = new FontSettings({
  size: 12,
  family: "IBM Plex Mono",
  weight: 400,
});
const fontTitle = new FontSettings({
  size: 14,
  family: "IBM Plex Mono",
  weight: 400,
});
const markerLine = new SolidLine({
  thickness: 3,
  fillStyle: new SolidFill({
    color: ColorHEX("#48d3e6"),
  }),
});
const chartBackground = new SolidFill({ color: ColorHEX("#FBFBFA") });

export default class ChartFactory {
  private bands = [] as Band[];
  private chart: ChartXY;
  private dragBand?: Band;
  private editBand?: BandEdit;
  private interactionToken: InteractionToken = new Map();
  private marker?: ConstantLine;
  private markerDragging: boolean = false;
  private legendBox?: LegendBox;
  private lineSeries = [] as LineSeries[];

  private yAxis: Axis;
  private timeAxis: Axis;
  private lineAxis: Axis;

  private onBandCreated?: ({ start, end, anchor }: OnBandCreated) => boolean;
  private onBandUpdated?: ({
    name,
    start,
    end,
    anchor,
  }: OnBandUpdated) => boolean;
  private onBandEdit?: ({ name, start, end, anchor }: OnBandUpdated) => boolean;
  private onBandError?: ({ message, code }: OnBandError) => void;

  private boundKeyDownHandler: (ev: KeyboardEvent) => void;
  private boundKeyUpHandler: (ev: KeyboardEvent) => void;

  constructor(
    private container: HTMLDivElement,
    title: string,
    private timestamps: number[],
    private onIndexUpdated?: (idx: number) => void
  ) {
    this.chart = lightningChart({
      license:
        "0001-m93bf5db9c3839c69220286a44321c3cf6840b49d28de053cfded0b4b99a96f48-8008fa0a8001-3045022100ab53edfa261b65357349dba1598dbde00d86ab3740d4c4db6dd1610ac0b9a430022029fdf4331302c5f992235acfb4308c03713c5e18beeb6020602fa0f63e6e6879",
      licenseInformation: {
        appTitle: "Operator App",
        company: "HJN Sverige AB",
      },
    })
      .ChartXY({
        container: this.container,
        theme: disableThemeEffects(Themes.light),
      })
      .setAnimationsEnabled(false)
      .setAutoCursorMode(AutoCursorModes.onHover)
      /* Required to make mouse event work */
      .setBackgroundFillStyle(chartBackground)
      .setSeriesBackgroundFillStyle(emptyFill)
      .setSeriesBackgroundStrokeStyle(emptyLine)
      .setSeriesHighlightOnHover(false)
      .setTitleFillStyle(emptyFill);

    this.legendBox = this.chart
      .addLegendBox()
      .setDraggingMode(UIDraggingModes.draggable)
      .setTitle("Legend")
      .setTitleFont(fontRegular)
      .add(this.chart);

    this.yAxis = this.chart
      .getDefaultAxisY()
      .setTitle(title)
      .setTitleRotation(0)
      .setTickStrategy(AxisTickStrategies.Numeric, (tickStrat) =>
        tickStrat.setMajorTickStyle((tickStyle) =>
          tickStyle.setLabelFont(fontRegular)
        )
      )
      .setTitleFont(fontTitle);

    // Bottom X-axis for time series
    this.timeAxis = this.chart
      .getDefaultAxisX()
      .setTickStrategy(AxisTickStrategies.Numeric, (tickStrat) =>
        tickStrat.setMajorTickStyle((tickStyle) =>
          tickStyle.setLabelFont(fontRegular)
        )
      );
    // X-axis for line series
    this.lineAxis = this.chart
      .addAxisX({ opposite: true })
      .setTickStrategy(AxisTickStrategies.Empty)
      .setAxisInteractionZoomByDragging(false)
      .setAnimationsEnabled(false)
      .setMouseInteractions(false);

    this.boundKeyDownHandler = this.keyDownHandler.bind(this);
    this.boundKeyUpHandler = this.keyUpHandler.bind(this);
    window.addEventListener("keydown", this.boundKeyDownHandler);
    window.addEventListener("keyup", this.boundKeyUpHandler);

    // Marker
    this.createMarker();
  }

  // Key handler for drag actions
  private keyDownHandler = (ev: KeyboardEvent) => {
    if (ev.ctrlKey) {
      this.timeAxis.setAxisInteractionZoomByDragging(false);
      const it = this.interactionToken;
      if (!it.has("dragStart")) {
        const dragStart = this.timeAxis.onAxisInteractionAreaMouseDragStart(
          (axis: Axis, ev: MouseEvent) => this.xAxisDragStart(axis, ev)
        );
        it.set("dragStart", dragStart);
      }
      if (!it.has("drag")) {
        const drag = this.timeAxis.onAxisInteractionAreaMouseDrag(
          (axis: Axis, ev: MouseEvent) => this.xAxisDrag(axis, ev)
        );
        it.set("drag", drag);
      }
      if (!it.has("dragStop")) {
        const dragStop = this.timeAxis.onAxisInteractionAreaMouseDragStop(
          (axis: Axis, ev: MouseEvent) => this.xAxisDragStop(axis, ev)
        );
        it.set("dragStop", dragStop);
      }
    }
  };
  private keyUpHandler = (ev: KeyboardEvent) => {
    this.timeAxis.setAxisInteractionZoomByDragging(true);
    const it = this.interactionToken;
    if (it.has("dragStart")) {
      this.timeAxis.offAxisInteractionAreaMouseDragStart(it.get("dragStart")!);
      it.delete("dragStart");
    }
    if (it.has("drag")) {
      this.timeAxis.offAxisInteractionAreaMouseDrag(it.get("drag")!);
      it.delete("drag");
    }
    if (it.has("dragStop")) {
      this.timeAxis.offAxisInteractionAreaMouseDragStop(it.get("dragStop")!);
      it.delete("dragStop");
    }
  };

  /**
   * Set series (all).
   * Clears all previous series
   */
  public setSeries(
    timeSeries: RegionTimeseries[],
    thermalSeries: RegionTimeseries[],
    selectedId?: string
  ) {
    this.lineSeries.forEach((s) => {
      s.dispose();
    });
    this.lineSeries = [];

    // RoI:s
    timeSeries.forEach((rs) => {
      this.addSeries(rs, selectedId, "time");
    });

    // LoI:s
    thermalSeries.forEach((rs) => {
      this.addSeries(rs, selectedId, "line");
    });
  }

  /**
   * Add single series (no clearing)
   */
  public addSeries(
    series: RegionTimeseries,
    selectedId: string | undefined,
    lineOrTime: "line" | "time" = "time"
  ) {
    const xAxis = lineOrTime === "line" ? this.lineAxis : this.timeAxis;
    const ls = this.chart
      .addLineSeries({
        xAxis,
        yAxis: this.yAxis,
      })
      .setDrawOrder({ seriesDrawOrderIndex: 1337 })
      .setEffect(false)
      .setName(series.label)
      .setStrokeStyle(
        new SolidLine({
          thickness: selectedId === series.id ? 4 : 2,
          fillStyle: new SolidFill({
            color: ColorHEX(series.color),
          }),
        })
      );
    ls.add(series.series);
    this.lineSeries.push(ls);
    this.legendBox?.add(ls);
  }

  public addToIs(
    tois: TimeRange[],
    toiCreated: ({ start, end }: OnBandCreated) => boolean,
    toiUpdated: ({ name, start, end }: OnBandUpdated) => boolean,
    toiEdit: ({ name, start, end }: OnBandUpdated) => boolean,
    toiError: ({ message, code }: OnBandError) => void
  ) {
    this.onBandCreated = toiCreated;
    this.onBandUpdated = toiUpdated;
    this.onBandEdit = toiEdit;
    this.onBandError = toiError;

    this.bands.forEach((rs) => {
      rs.dispose();
    });
    this.bands = [];
    tois.forEach((toi) => {
      const b = this.createBand({
        start: this.timestamps[toi.from],
        end: this.timestamps[toi.to],
        name: toi.anno?.label,
        color: toi.anno?.color ? ColorHEX(toi.anno?.color + "66") : undefined,
      });
      this.bands.push(b);
      this.legendBox?.add(b);
    });
  }

  /**
   * Progress marker
   */
  private createMarker() {
    if (this.marker) {
      this.marker.dispose();
    }
    let prevIndex = 0;
    this.marker = this.timeAxis
      .addConstantLine(true) // True == On top
      .setStrokeStyle(markerLine)
      .setValue(this.timestamps[0]);

    this.marker.onMouseDragStart((cl: ConstantLine, ev: MouseEvent) => {
      this.markerDragging = true;
    });
    this.marker.onMouseDrag((cl: ConstantLine, ev: MouseEvent) => {
      const idx = this.markerToIndex(cl);
      if (idx !== prevIndex && this.onIndexUpdated) {
        prevIndex = idx;
        this.onIndexUpdated(idx);
      }
    });
    this.marker.onMouseDragStop((cl: ConstantLine, ev: MouseEvent) => {
      this.markerDragging = false;
      this.moveMarker(this.markerToIndex(cl));
    });
  }

  private markerToIndex(cl: ConstantLine): number {
    const fixed = findClosest(cl.getValue(), this.timestamps);
    return this.timestamps.findIndex((val) => fixed === val);
  }

  public moveMarker(index: number) {
    if (!this.timestamps || !this.marker) {
      return;
    }
    if (this.markerDragging) {
      // Skip move marker since dragging
      return;
    }
    this.marker.setValue(this.timestamps[index]);
  }

  public dispose() {
    window.removeEventListener("keydown", this.boundKeyDownHandler);
    window.removeEventListener("keyup", this.boundKeyUpHandler);
    this.lineSeries.forEach((s) => {
      s.dispose();
    });
    this.bands.forEach((b) => {
      b.dispose();
    });
    this.chart.dispose();
  }

  /**
   * Band drag handlers
   */
  public xAxisDragCancel() {
    if (this.dragBand) {
      this.dragBand.dispose();
      this.dragBand = undefined;
    }
  }

  private xAxisDragStart(axis: Axis, ev: MouseEvent) {
    if (ev.button !== 0) {
      return;
    }
    // Cancel any lingering drag
    this.xAxisDragCancel();

    const nearest = this.chart.solveNearest({
      clientX: ev.clientX,
      clientY: ev.clientY,
    });
    if (nearest && nearest.location) {
      this.dragBand = this.createBand({
        start: nearest.location.x,
        end: nearest.location.x,
        color: ColorHEX("#48d3e6"),
        onTop: true,
      });
    }
  }

  private xAxisDrag(axis: Axis, ev: MouseEvent) {
    if (!this.dragBand) {
      return;
    }
    const nearest = this.chart.solveNearest(ev);
    if (nearest && nearest.location) {
      this.dragBand.setValueEnd(nearest.location.x);
    }
  }

  private xAxisDragStop(axis: Axis, ev: MouseEvent) {
    if (!this.dragBand) {
      this.xAxisDragCancel();
      return;
    }
    if (this.onBandCreated) {
      const res = this.onBandCreated({
        start: findClosestIndex(this.dragBand.getValueStart(), this.timestamps),
        end: findClosestIndex(this.dragBand.getValueEnd(), this.timestamps),
        anchor: new Point(ev.offsetX, 13),
      });
      if (!res) {
        this.xAxisDragCancel();
      }
    }
  }

  private createBand({
    start,
    end,
    name,
    color,
    onTop = false,
  }: BandProps): Band {
    if (!this.timestamps) {
      throw new Error("Can't create band w/o timestamps");
    }
    const band = this.chart
      .getDefaultAxisX()
      .addBand(onTop)
      .setValueStart(start)
      .setValueEnd(end)
      .setName(name ?? "")
      .setFillStyle(new SolidFill({ color }))
      .setStrokeStyle(emptyLine)
      .setHighlight(false)
      .setAnimationHighlight(false)
      .setEffect(false);

    band.onMouseDoubleClick((band: Band, ev: MouseEvent) => {
      if (this.onBandEdit) {
        ev.stopPropagation();
        this.onBandEdit({
          name: band.getName(),
          start: band.getValueStart(),
          end: band.getValueEnd(),
          anchor: new Point(ev.offsetX, 13),
        });
      }
    });

    band.onMouseDragStart((band: Band, ev: MouseEvent) => {
      this.editBand = {
        start: band.getValueStart(),
        end: band.getValueEnd(),
      };
    });

    band.onMouseDragStop((band: Band, ev: MouseEvent) => {
      if (!this.onBandUpdated || !this.onBandError || !this.editBand) {
        return;
      }
      // Snap band to timestamps
      const start = findClosest(band.getValueStart(), this.timestamps!);
      const end = findClosest(band.getValueEnd(), this.timestamps!);
      if (start === end) {
        this.onBandError({
          message: "Start and stop cannot be the same.",
          code: 409,
        });
        // Set band to initial values
        band.setValueStart(this.editBand.start);
        band.setValueEnd(this.editBand.end);
        return;
      }

      band.setValueStart(start);
      band.setValueEnd(end);
      if (start !== this.editBand?.start || end !== this.editBand.end) {
        const idxStart = findClosestIndex(
          band.getValueStart(),
          this.timestamps
        );
        const idxEnd = findClosestIndex(band.getValueEnd(), this.timestamps);
        this.onBandUpdated({
          name: band.getName(),
          start: idxStart > idxEnd ? idxEnd : idxStart,
          end: idxStart > idxEnd ? idxStart : idxEnd,
          anchor: new Point(ev.offsetX, 13),
        });
      } else {
        // Start === End => trigger error
      }
      this.editBand = undefined;
    });

    return band;
  }
}
