import { Metric } from "lib/metric";
import { useCallback } from "react";
import { useMetricAPI } from "render/hooks/api/useMetricAPI";
import { toMetricList } from "lib/metric";

export type GripStrengthMetrics = Partial<{
  gripStrengthLeft: Metric<"body.grip_strength.left">;
  gripStrengthRight: Metric<"body.grip_strength.right">;
}>;

export function useGripStrengthMetrics() {
  const { fetchMetrics, storeMetrics } = useMetricAPI();

  const fetchGripStrengthMetrics = useCallback(
    async (patientId: string): Promise<GripStrengthMetrics> => {
      const [left, right] = await Promise.all([
        fetchMetrics(patientId, "body.grip_strength.left"),
        fetchMetrics(patientId, "body.grip_strength.right"),
      ]);

      return {
        gripStrengthLeft: left.at(0),
        gripStrengthRight: right.at(0),
      };
    },
    [fetchMetrics]
  );

  const updateGripStrengthMetrics = useCallback(
    async (patientId: string, metrics: GripStrengthMetrics) => {
      return storeMetrics(patientId, toMetricList(metrics));
    },

    [storeMetrics]
  );

  return {
    fetchGripStrengthMetrics,
    updateGripStrengthMetrics,
  };
}
