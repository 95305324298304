import { PropsWithChildren } from "react";
import { Typography } from "../Typography";
import SquircleArrow from "./assets/squircle";
import styles from "./styles.module.sass";

export default function MetricResultHeader({ children }: PropsWithChildren) {
  return <div className={styles.Header}>{children}</div>;
}

export function Titles({ children }: PropsWithChildren) {
  return <div className={styles.titles}>{children}</div>;
}

export function MainTitle({ children }: PropsWithChildren) {
  return (
    <Typography as="h3" variant="title-s">
      {children}
    </Typography>
  );
}

export function Subtitle({ children }: PropsWithChildren) {
  return (
    <Typography as="span" variant="title-s" className={styles.subtitle}>
      {children}
    </Typography>
  );
}

export function AuxTitle({ children }: PropsWithChildren) {
  return (
    <Typography as="span" variant="label-m" className={styles.aux}>
      {children}
    </Typography>
  );
}

export function Description({ children }: PropsWithChildren) {
  return (
    <Typography variant="body-m" className={styles.description}>
      {children}
    </Typography>
  );
}

export function Diff({ children }: PropsWithChildren) {
  return <div className={styles.diff}>{children}</div>;
}

export function Unit({ children }: PropsWithChildren) {
  return (
    <Typography variant="body-m" className={styles.unit}>
      {children}
    </Typography>
  );
}

interface DiffValueProps {
  valuesDiff?: number | undefined;
  unit?: string | undefined;
  formatValue?: (value?: number) => string | number | undefined;
}

export function DiffValue({
  valuesDiff,
  unit,
  formatValue = (n?: number) => n,
}: DiffValueProps) {
  return (
    <>
      {valuesDiff !== undefined && (
        <Typography as="span" variant="label-m" className={styles.value}>
          {valuesDiff === 0 ? (
            ""
          ) : valuesDiff > 0 ? (
            <SquircleArrow />
          ) : (
            <SquircleArrow direction="downwards" />
          )}
          {valuesDiff === 0 ? "No change" : formatValue(+Math.abs(valuesDiff))}
        </Typography>
      )}
      {unit && <Unit>{unit}</Unit>}
    </>
  );
}
