import { useNav } from "@pomle/react-router-paths";
import { Bloodwork } from "lib/types/metrics/bloodwork";
import { useCallback, useEffect, useMemo, useState } from "react";
import PatientName from "render/fragments/patient/PatientName";
import { useBloodworkStore } from "render/hooks/api/metrics/useBloodworkStore";
import { usePatient } from "render/hooks/api/usePatient";
import { useEscapeNumberScroll } from "render/hooks/useEscapeNumberScroll";
import { useFormHandle } from "render/hooks/useFormHandle";
import { useReporting } from "render/hooks/useReporting";
import { useUserInput } from "render/hooks/useUserInput";
import { paths } from "render/routes/paths";
import Field from "render/ui/form/Field/Field";
import Fieldset from "render/ui/form/Fieldset";
import Input from "render/ui/form/Input";
import FramedPage from "render/ui/layouts/FramedPage/FramedPage";
import PageHeader from "render/ui/layouts/PageHeader";
import BackButtonLink from "render/ui/trigger/BackButtonLink";
import ButtonSet from "render/ui/trigger/ButtonSet";
import SubmitButton from "render/ui/trigger/SubmitButton";
import { autoComplete } from "./autocomplete";
import { Entries, toBloodwork, toEntries } from "./conversion";
import styles from "./styles.module.sass";

const INITIAL: Entries = {
  cholesterol: "",
  cholesterolHDL: "",
  CRP: "",
  HbA1c: "",
  HDL: "",
  LDL: "",
  nonHDL: "",
  triglycerides: "",
  haemoglobin: "",
  glucose: "",
  wbcTotal: "",
  neutrophils: "",
  basophils: "",
  monocytes: "",
  eosinophils: "",
  lymphocytes: "",
};

interface BloodworkEntryPageProps {
  patientId: string;
}

export default function BloodworkEntryPage({
  patientId,
}: BloodworkEntryPageProps) {
  const { logError } = useReporting();

  const patient = usePatient(patientId);

  const { fetchBloodwork, storeBloodwork } = useBloodworkStore();

  const [latest, setLatest] = useState<Bloodwork>();

  useEffect(() => {
    fetchBloodwork(patientId).then(setLatest);

    return () => {
      setLatest(undefined);
    };
  }, [patientId, fetchBloodwork]);

  // Must use text entries for state to avoid
  // trimming leading zero in decimal (4.08 ex)
  const [entries, setEntries] = useState<Entries>(INITIAL);

  const userInput = useUserInput(entries, setEntries);

  const handleSave = useCallback(() => {
    const bloodwork = toBloodwork(entries);

    return storeBloodwork(patientId, bloodwork)
      .then(() => {
        setEntries(INITIAL);

        setLatest((b) => {
          return { ...b, ...bloodwork };
        });
      })
      .catch(logError);
  }, [patientId, entries, logError, storeBloodwork]);

  const tryAutoFill = useCallback(() => {
    const bloodwork = toBloodwork(entries);
    setEntries(toEntries(autoComplete(bloodwork)));
  }, [entries]);

  const isValid = useMemo(() => {
    return Object.values(entries).some((value) => {
      return !Number.isNaN(parseFloat(value));
    });
  }, [entries]);

  const saveHandle = useFormHandle(handleSave);

  const nav = {
    patient: useNav(paths.patient.detail),
  };

  const handle = useEscapeNumberScroll<HTMLDivElement>();

  return (
    <FramedPage>
      <div className={styles.BloodworkEntryPage} ref={handle}>
        <BackButtonLink to={nav.patient.to({ patientId })}>
          {patient ? <PatientName patient={patient} /> : "Back"}
        </BackButtonLink>

        <PageHeader caption="Bloodwork" />

        <form onSubmit={saveHandle.onSubmit}>
          <div className={styles.bloodwork}>
            <section style={{ gridRow: "span 6" }}>
              <Fieldset legend="Lipid Panel">
                <Field label="Cholesterol">
                  <Input hint="mmol/L">
                    <input
                      type="number"
                      min={2}
                      max={12}
                      step="any"
                      placeholder="2.0 - 12.0"
                      autoComplete="off"
                      {...userInput.cholesterol}
                      onBlur={tryAutoFill}
                    />
                  </Input>

                  {latest?.cholesterol && (
                    <div>
                      Previous <b>{latest?.cholesterol.unit["mmol/L"]}</b>{" "}
                      mmol/L
                    </div>
                  )}
                </Field>

                <Field label="LDL">
                  <Input hint="mmol/L">
                    <input
                      type="number"
                      min={0}
                      max={10}
                      step="any"
                      placeholder="0.0 - 10.0"
                      autoComplete="off"
                      {...userInput.LDL}
                    />
                  </Input>

                  {latest?.LDL && (
                    <div>
                      Previous <b>{latest?.LDL.unit["mmol/L"]}</b> mmol/L
                    </div>
                  )}
                </Field>

                <Field label="HDL">
                  <Input hint="mmol/L">
                    <input
                      type="number"
                      min={0}
                      max={4}
                      step="any"
                      placeholder="0.0 - 4.0"
                      autoComplete="off"
                      {...userInput.HDL}
                      onBlur={tryAutoFill}
                    />
                  </Input>

                  {latest?.HDL && (
                    <div>
                      Previous <b>{latest?.HDL.unit["mmol/L"]}</b> mmol/L
                    </div>
                  )}
                </Field>

                <Field label="Triglycerides">
                  <Input hint="mmol/L">
                    <input
                      type="number"
                      min={0}
                      max={10}
                      step="any"
                      placeholder="0.0 - 10.0"
                      autoComplete="off"
                      {...userInput.triglycerides}
                    />
                  </Input>

                  {latest?.triglycerides && (
                    <div>
                      Previous <b>{latest?.triglycerides.unit["mmol/L"]}</b>{" "}
                      mmol/L
                    </div>
                  )}
                </Field>

                <Field label="non-HDL">
                  <Input hint="mmol/L">
                    <input
                      type="number"
                      min={0}
                      max={10}
                      step="any"
                      placeholder="0.0 - 10.0"
                      autoComplete="off"
                      {...userInput.nonHDL}
                    />
                  </Input>

                  {latest?.nonHDL && (
                    <div>
                      Previous <b>{latest?.nonHDL.unit["mmol/L"]}</b> mmol/L
                    </div>
                  )}
                </Field>

                <Field label="Cholesterol/HDL">
                  <Input hint="Fraction">
                    <input
                      type="number"
                      min={0}
                      max={10}
                      step="any"
                      placeholder="0.0 - 10.0"
                      autoComplete="off"
                      {...userInput.cholesterolHDL}
                    />
                  </Input>

                  {latest?.cholesterolHDL && (
                    <div>
                      Previous <b>{latest?.cholesterolHDL.unit["fraction"]}</b>
                    </div>
                  )}
                </Field>
              </Fieldset>
            </section>

            <section style={{ gridRow: "span 6" }}>
              <Fieldset legend="White Blood Cells">
                <Field label="WBC count (total)">
                  <Input hint="x10⁹/L">
                    <input
                      type="number"
                      min={0}
                      max={20}
                      step="any"
                      placeholder="0.0 - 20.0"
                      autoComplete="off"
                      {...userInput.wbcTotal}
                    />
                  </Input>

                  {latest?.wbcTotal && (
                    <div>
                      Previous <b>{latest?.wbcTotal.unit["x10⁹/L"]}</b> x10⁹/L
                    </div>
                  )}
                </Field>

                <Field label="Neutrophils">
                  <Input hint="x10⁹/L">
                    <input
                      type="number"
                      min={0}
                      max={20}
                      step="any"
                      placeholder="0.0 - 20.0"
                      autoComplete="off"
                      {...userInput.neutrophils}
                    />
                  </Input>

                  {latest?.neutrophils && (
                    <div>
                      Previous <b>{latest?.neutrophils.unit["x10⁹/L"]}</b>{" "}
                      x10⁹/L
                    </div>
                  )}
                </Field>

                <Field label="Lymphocytes">
                  <Input hint="x10⁹/L">
                    <input
                      type="number"
                      min={0}
                      max={10}
                      step="any"
                      placeholder="0.0 - 10.0"
                      autoComplete="off"
                      {...userInput.lymphocytes}
                    />
                  </Input>

                  {latest?.lymphocytes && (
                    <div>
                      Previous <b>{latest?.lymphocytes.unit["x10⁹/L"]}</b>{" "}
                      x10⁹/L
                    </div>
                  )}
                </Field>

                <Field label="Monocytes">
                  <Input hint="x10⁹/L">
                    <input
                      type="number"
                      min={0}
                      max={10}
                      step="any"
                      placeholder="0.0 - 10.0"
                      autoComplete="off"
                      {...userInput.monocytes}
                    />
                  </Input>

                  {latest?.monocytes && (
                    <div>
                      Previous <b>{latest?.monocytes.unit["x10⁹/L"]}</b> x10⁹/L
                    </div>
                  )}
                </Field>

                <Field label="Eosinophils">
                  <Input hint="x10⁹/L">
                    <input
                      type="number"
                      min={0}
                      max={5}
                      step="any"
                      placeholder="0.0 - 5.0"
                      autoComplete="off"
                      {...userInput.eosinophils}
                    />
                  </Input>

                  {latest?.eosinophils && (
                    <div>
                      Previous <b>{latest?.eosinophils.unit["x10⁹/L"]}</b>{" "}
                      x10⁹/L
                    </div>
                  )}
                </Field>

                <Field label="Basophils">
                  <Input hint="x10⁹/L">
                    <input
                      type="number"
                      min={0}
                      max={5}
                      step="any"
                      placeholder="0.0 - 5.0"
                      autoComplete="off"
                      {...userInput.basophils}
                    />
                  </Input>

                  {latest?.basophils && (
                    <div>
                      Previous <b>{latest?.basophils.unit["x10⁹/L"]}</b> x10⁹/L
                    </div>
                  )}
                </Field>
              </Fieldset>
            </section>

            <section>
              <Fieldset legend="HbA1C">
                <Field label="HbA1C">
                  <Input hint="mmol/mol">
                    <input
                      type="number"
                      min={10}
                      max={100}
                      step="any"
                      placeholder="10.0 - 100.0"
                      autoComplete="off"
                      {...userInput.HbA1c}
                    />
                  </Input>

                  {latest?.HbA1c && (
                    <div>
                      Previous <b>{latest?.HbA1c.unit["mmol/mol"]}</b> mmol/mol
                    </div>
                  )}
                </Field>
              </Fieldset>
            </section>

            <section>
              <Fieldset legend="CRP">
                <Field label="CRP">
                  <Input hint="mg/L">
                    <input
                      type="number"
                      min={0}
                      max={100}
                      step="any"
                      placeholder="0.0 - 100.0"
                      autoComplete="off"
                      {...userInput.CRP}
                    />
                  </Input>

                  {latest?.CRP && (
                    <div>
                      Previous <b>{latest?.CRP.unit["mg/L"]}</b> mg/L
                    </div>
                  )}
                </Field>
              </Fieldset>
            </section>

            <section>
              <Fieldset legend="Glucose">
                <Field label="Glucose">
                  <Input hint="mmol/L">
                    <input
                      type="number"
                      min={0}
                      max={20}
                      step="any"
                      placeholder="0.0 - 20.0"
                      autoComplete="off"
                      {...userInput.glucose}
                    />
                  </Input>

                  {latest?.glucose && (
                    <div>
                      Previous <b>{latest?.glucose.unit["mmol/L"]}</b> mmol/L
                    </div>
                  )}
                </Field>
              </Fieldset>
            </section>

            <section>
              <Fieldset legend="Haemoglobin">
                <Field label="Haemoglobin">
                  <Input hint="g/L">
                    <input
                      type="number"
                      min={0}
                      max={500}
                      step="any"
                      placeholder="0.0 - 500.0"
                      autoComplete="off"
                      {...userInput.haemoglobin}
                    />
                  </Input>

                  {latest?.haemoglobin && (
                    <div>
                      Previous <b>{latest?.haemoglobin.unit["g/L"]}</b> g/L
                    </div>
                  )}
                </Field>
              </Fieldset>
            </section>
          </div>

          <section>
            <ButtonSet>
              <SubmitButton disabled={!isValid} handle={saveHandle}>
                Save
              </SubmitButton>
            </ButtonSet>
          </section>
        </form>
      </div>
    </FramedPage>
  );
}
