import { Range } from "./types";

export function rangeToDistance(
  windowRange: Range,
  selectionRange: Range,
  signalLengthMs: number
) {
  const windowLength =
    Math.abs(windowRange[1] - windowRange[0]) * signalLengthMs;
  const delta = Math.abs(selectionRange[1] - selectionRange[0]);
  return Math.round(delta * windowLength);
}
