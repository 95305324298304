import { createMetric } from "lib/metric";
import { GripStrengthMetrics } from "./hooks/useGripStrengthMetrics";

export type Entries = {
  left: string;
  right: string;
};

export function toEntries(metrics: GripStrengthMetrics): Entries {
  return {
    left: metrics.gripStrengthLeft?.unit.kilograms.toString() ?? "",
    right: metrics.gripStrengthRight?.unit.kilograms.toString() ?? "",
  };
}

export function toMetrics(entries: Entries) {
  const metrics: GripStrengthMetrics = {};

  {
    const value = parseFloat(entries.left);
    if (isFinite(value)) {
      metrics.gripStrengthLeft = createMetric("body.grip_strength.left", {
        kilograms: value,
      });
    }
  }

  {
    const value = parseFloat(entries.right);
    if (isFinite(value)) {
      metrics.gripStrengthRight = createMetric("body.grip_strength.right", {
        kilograms: value,
      });
    }
  }

  return metrics;
}
