import { useMemo } from "react";
import { useAssessmentNav } from "render/pages/AssessmentPage/hooks/useAssessmentNav";
import ButtonSet from "render/ui/trigger/ButtonSet";
import HoverTextButton from "render/ui/trigger/HoverTextButton";
import { PulsewaveType } from "../types";
import styles from "./styles.module.sass";

export default function ButtonControl() {
  const { pulsewaveType, setPulsewaveType } = useAssessmentNav();

  const title = useMemo(() => {
    switch (pulsewaveType) {
      case PulsewaveType.lveti:
        return "Ejection Time";
      case PulsewaveType.pulseWaveVelocity:
        return "Pulsewave Velocity";
      case PulsewaveType.footAsymmetry:
        return "Pulse Asymmetry (feet)";
      case PulsewaveType.handAsymmetry:
        return "Pulse Asymmetry (hands)";
    }
  }, [pulsewaveType]);

  return (
    <div className={styles.ButtonControl}>
      <h2>{title}</h2>
      <ButtonSet>
        <HoverTextButton
          active={pulsewaveType === PulsewaveType.lveti}
          onClick={() => {
            setPulsewaveType(PulsewaveType.lveti);
          }}
        >
          Ejection Time
        </HoverTextButton>

        <HoverTextButton
          active={pulsewaveType === PulsewaveType.pulseWaveVelocity}
          onClick={() => {
            setPulsewaveType(PulsewaveType.pulseWaveVelocity);
          }}
        >
          Pulsewave Velocity
        </HoverTextButton>

        <HoverTextButton
          active={pulsewaveType === PulsewaveType.handAsymmetry}
          onClick={() => {
            setPulsewaveType(PulsewaveType.handAsymmetry);
          }}
        >
          Hand Asymmetry
        </HoverTextButton>
        <HoverTextButton
          active={pulsewaveType === PulsewaveType.footAsymmetry}
          onClick={() => {
            setPulsewaveType(PulsewaveType.footAsymmetry);
          }}
        >
          Foot Asymmetry
        </HoverTextButton>
      </ButtonSet>
    </div>
  );
}
