import { APITypesV1 } from "@cur8/api-client";
import { useCallback, useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";
import { useReporting } from "render/hooks/useReporting";

const SUMMARY_CONSENT_DOCUMENT_IDS: string[] = [
  "doctor-scribe-gb",
  "doctor-scribe",
];

const SUGGESTIONS_CONSENT_IDS: string[] = ["doctor-scribe-gb", "doctor-scribe"];

function hasConsent(
  consents: APITypesV1.ConsentSummary[],
  documentNames: string[]
) {
  const set = new Set(documentNames);

  return consents.some((consent) => {
    return set.has(consent.consentId);
  });
}

function isValid(consent: APITypesV1.ConsentSummary) {
  return !consent.expired && !consent.optedOut && !consent.revoked;
}

export function useLLMConsent(patientId: string | undefined) {
  const { handleError } = useReporting();
  const api = useAPIClient().consent;
  const [canUseSummaries, setCanUseSummaries] = useState<boolean>();
  const [canUseSuggestions, setCanUseSuggestions] = useState<boolean>();

  const fetch = useCallback(() => {
    function fetchPage(params: APITypesV1.PatientConsentQueryParams) {
      return api.getListOfConsents({
        ...params,
      }).result;
    }

    async function fetchAll(params: APITypesV1.PatientConsentQueryParams) {
      const buffer: APITypesV1.ConsentSummary[] = [];

      let nextPage: string | undefined;
      do {
        try {
          const res = await fetchPage({
            ...params,
            validityState: APITypesV1.ConsentValidityState.WithinValidityRange,
            continuationToken: nextPage,
          });
          buffer.push(...res.items.filter(isValid));
          nextPage = res.nextPage;
        } catch (err) {
          handleError(err);
          nextPage = undefined;
        }
      } while (nextPage);

      return buffer;
    }

    if (patientId) {
      const options: APITypesV1.PatientConsentQueryParams = {
        patientId,
        validityState: APITypesV1.ConsentValidityState.WithinValidityRange,
      };
      return fetchAll(options).then((consents) => {
        setCanUseSummaries(hasConsent(consents, SUMMARY_CONSENT_DOCUMENT_IDS));
        setCanUseSuggestions(hasConsent(consents, SUGGESTIONS_CONSENT_IDS));
      });
    } else {
      setCanUseSummaries(false);
      return undefined;
    }
  }, [handleError, api, patientId]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { canUseSummaries, canUseSuggestions, fetch };
}
