export type LocaleCode = "en" | "sv";

type LocaleEntry = {
  locale: LocaleCode;
  displayName: string;
};

export const Locales: LocaleEntry[] = [
  {
    locale: "en",
    displayName: "English",
  },
  {
    locale: "sv",
    displayName: "Swedish",
  },
];
