import { Context, useScheduleState } from "./hooks";

interface Props {
  siteId: string;
  children: React.ReactNode;
}

export default function ScheduleContext({ siteId, children }: Props) {
  const value = useScheduleState(siteId);

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
