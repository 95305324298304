import { Patient } from "@cur8/rich-entity";
import { useLiveTime } from "render/hooks/useLiveTime";

interface PatientAgeProps {
  patient: Patient;
}

export default function PatientAge({ patient }: PatientAgeProps) {
  const now = useLiveTime("minute");

  const date = patient.dateOfBirth;

  if (date?.isValid) {
    const years = now.diff(date, "years").years;
    return <>{Math.floor(years)}</>;
  }

  return <>&mdash;</>;
}
