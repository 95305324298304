import { ImmutableScan } from "@cur8/rich-entity";
import { parseAsJSON } from "lib/parse";
import { isRecordingURI } from "lib/uri/guard";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

type IlluminatedRegion = {
  height: number;
  left_x: number;
  top_y: number;
  width: number;
};
type CameraCalibration = {
  camera: {
    pix_per_cm: number;
    pix_per_cm_horizontal: number;
    pix_per_cm_vertical: number;
  };
  created_at: DateTime;
  dmd: {
    pix_per_cm_horizontal: number;
    pix_per_cm_vertical: number;
  };
  illuminated_region: IlluminatedRegion;
  illuminated_regions: Record<number, IlluminatedRegion>;
};

function parse(response: Response) {
  return parseAsJSON<CameraCalibration>(response);
}

export function useCameraCalibration(scan: ImmutableScan | undefined) {
  const api = useAPIClient().recording;

  const [data, setData] = useState<CameraCalibration>();

  useEffect(() => {
    if (!scan) {
      return;
    }
    const recordingURI = scan.sourceUris.filter(isRecordingURI).at(0);
    if (!recordingURI) {
      return;
    }
    const { recordingId, deviceId } = recordingURI;

    api
      .fetchAttachment({
        deviceId: deviceId,
        recordingId: recordingId,
        attachmentName: "camera_calibration.json",
      })
      .result.then(parse)
      .then(setData);
  }, [api, scan]);
  return data;
}
