import { Metric } from "lib/metric";

interface GetEyePressureStatusParams {
  current: {
    left?: Metric<"risk_assessment.eye_pressure.left">;
    right?: Metric<"risk_assessment.eye_pressure.right">;
  };
}
export type EyePressureStatus =
  | "no-data"
  | "unbalanced"
  | "elevated"
  | "low"
  | "normal"
  | "high";

export function getEyePressureStatus({
  current,
}: GetEyePressureStatusParams): EyePressureStatus {
  const currentLeft = current.left?.unit.mmHg;
  const currentRight = current.right?.unit.mmHg;

  if (currentLeft === undefined && currentRight === undefined) {
    return "no-data";
  }

  const differenceBetweenSides = Math.abs(
    (currentLeft ?? 0) - (currentRight ?? 0)
  );

  if (differenceBetweenSides > 4) {
    return "unbalanced";
  }

  if (
    (currentLeft && currentLeft > 28) ||
    (currentRight && currentRight > 28)
  ) {
    return "high";
  }

  if (
    (currentLeft && currentLeft > 24) ||
    (currentRight && currentRight > 24)
  ) {
    return "elevated";
  }

  if ((currentLeft && currentLeft < 8) || (currentRight && currentRight < 8)) {
    return "low";
  }

  return "normal";
}
