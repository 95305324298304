import { useQueryParams } from "@pomle/react-router-paths";
import { useMemo } from "react";
import { query } from "render/routes/querys";
import styles from "./styles.module.sass";
import { useSiteRooms } from "../Calendar/hooks/useSiteRooms";
import { Room } from "@cur8/rich-entity";

function sortByDisplayName(a: Room, b: Room) {
  if (!a.displayName) {
    return 1;
  }
  if (!b.displayName) {
    return -1;
  }

  return a.displayName.localeCompare(b.displayName);
}

export default function RoomDropDown({ siteId }: { siteId: string }) {
  const siteRooms = useSiteRooms(siteId);
  const [queryParams, setQueryParams] = useQueryParams(query.schedule);

  const rooms = useMemo(() => {
    return siteRooms.data?.sort(sortByDisplayName);
  }, [siteRooms.data]);

  const selectedRoom = queryParams.roomId.at(0) ?? rooms?.at(0)?.id;

  return (
    <div className={styles.RoomDropDown}>
      {rooms && (
        <>
          {!rooms.length && <div>[Site has no rooms]</div>}
          {!!rooms.length && (
            <select
              value={selectedRoom}
              onChange={(e) => {
                setQueryParams({ roomId: [e.target.value] });
              }}
            >
              {rooms.map(({ id, displayName }) => (
                <option value={id} key={id}>
                  {displayName ?? id}
                </option>
              ))}
            </select>
          )}
        </>
      )}
    </div>
  );
}
