import { ImmutableScan } from "@cur8/rich-entity";
import { useImmutableScanBlob } from "render/hooks/api/useImmutableScanBlob";

const resultName = "ui_heart_sound";
const path = "heart_sound.wav";

function toBlob(response: Response) {
  return response.blob();
}

export function useHeartSound(scan?: ImmutableScan) {
  return useImmutableScanBlob({ scan, resultName, path, parse: toBlob });
}
