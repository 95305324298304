import { APITypesV1 } from "@cur8/api-client";
import { Visit, fromAPI } from "@cur8/rich-entity";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useAPIClient } from "render/context/APIContext";

function isCanceled(visit: Visit) {
  return visit.status === APITypesV1.VisitStatus.Cancelled;
}

export function usePatientUpcomingVisits(patientId: string) {
  const api = useAPIClient();
  const [visits, setVisits] = useState<AsyncResult<Visit[]>>();

  useEffect(() => {
    const req = api.visit.getPatientVisits(
      { patientId },
      {
        order: APITypesV1.SortOrder.Asc,
        endDateTimeOffsetRange: { start: DateTime.now().toISO() },
      }
    );

    req.result
      .then((response) => {
        return response.items
          .map(fromAPI.toVisit)
          .filter((visit) => !isCanceled(visit));
      })
      .then((data) => {
        setVisits({ data });
      });

    return () => {
      req.abandon();
    };
  }, [api.visit, patientId]);
  return visits;
}
