import { useEffect, useState } from "react";

const CHARS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789......";

function randomText(len: number) {
  let output = "";
  for (let i = 0; i < len; i++) {
    const index = Math.floor(Math.random() * CHARS.length);
    output += CHARS[index];
  }
  return output;
}

interface TextScramblerProps {
  text: string;
  duration?: number;
  framerate?: number;
}

export default function TextScrambler({
  text,
  duration = 1,
  framerate = 1 / 60,
}: TextScramblerProps) {
  const [output, setOutput] = useState("");

  useEffect(() => {
    const next = text;
    const prev = output;

    const start = performance.now();

    const timer = setInterval(() => {
      const time = (performance.now() - start) / 1000;

      if (time >= duration) {
        setOutput(next);
        clearInterval(timer);
        return;
      }

      const progress = Math.min(time / duration, 1);

      const len = Math.max(2, progress * next.length);
      const scramble = randomText(len);
      const offset = Math.max(prev.length - next.length, 0);
      const cut = offset * progress;
      const output = scramble + prev.slice(scramble.length + cut);
      setOutput(output);
    }, framerate * 1000);

    return () => {
      clearInterval(timer);
    };
    /* `output` needed for assigning to prev but we should not look at it for retriggering */
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [text, duration, framerate]);

  return <>{output}</>;
}
