import Sidebar from "./components/Sidebar";
import styles from "./styles.module.sass";

interface PageFrameProps {
  children: React.ReactNode;
}

export default function PageFrame({ children }: PageFrameProps) {
  return (
    <div className={styles.PageFrame}>
      <nav>
        <Sidebar />
      </nav>

      <main className={styles.content}>{children}</main>
    </div>
  );
}
