import { VisitSummary } from "@cur8/rich-entity";
import { PathLink, useNav } from "@pomle/react-router-paths";
import { paths } from "render/routes/paths";
import DayDate from "render/ui/format/DayDate";
import Table from "render/ui/layouts/Table";
import DoctorName from "../PreviousVisitSummary/components/Summary/components/DoctorName/DoctorName";

interface PatientVisitTableProps {
  visits: VisitSummary[];
}

export default function PatientVisitTable({ visits }: PatientVisitTableProps) {
  const nav = {
    edit: useNav(paths.patient.visit.edit),
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>Visit</th>
          <th>Doctor</th>
          <th>Summary</th>
          <th>Follow Up At</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
        {visits.map((visit) => {
          const summaryId = visit.id;
          const patientId = visit.patientId;

          return (
            <tr key={visit.id}>
              <td>
                <DayDate date={visit.visitDate} />
              </td>
              <td>
                {visit.audit.created?.userUri && (
                  <DoctorName uri={visit.audit.created?.userUri} />
                )}
              </td>
              <td>{visit.summaryText}</td>
              <td>
                {visit.followUpDate && <DayDate date={visit.followUpDate} />}
              </td>
              <td>
                <PathLink to={nav.edit.to({ patientId, summaryId })}>
                  Edit
                </PathLink>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
