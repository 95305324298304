import { useCamera } from "./hooks/useCamera";
import { usePodium } from "./hooks/usePodium";
import { useUI } from "./hooks/useUI";
import { Context } from "./context";

interface DashboardContextProps {
  children: React.ReactNode;
}

export default function DashboardContext({ children }: DashboardContextProps) {
  const ui = useUI();
  const camera = useCamera();
  const podium = usePodium();

  return (
    <Context.Provider value={{ ui, camera, podium }}>
      {children}
    </Context.Provider>
  );
}
