import { ABI } from "@cur8/health-risks-calc";
import { useMemo } from "react";
import MetricResultHeader, {
  AuxTitle,
  Diff,
  DiffValue,
  MainTitle,
  Titles,
} from "render/ui/presentation/MetricResultHeader";
import RangeChart, {
  RangeChartDataComposer,
} from "render/ui/presentation/RangeChart";
import {
  capToMaxRanges,
  toChartRanges,
} from "render/ui/presentation/RangeChart/lib/risk";

interface ArterialFlowResultItemProps {
  currentValue: number;
  previousValue?: number;
}

export default function ArterialFlowResultItem({
  currentValue,
  previousValue,
}: ArterialFlowResultItemProps) {
  const riskRanges = useMemo(() => {
    return ABI.rangesFor();
  }, []);

  const rangesForValue = useMemo(() => {
    return capToMaxRanges(toChartRanges(riskRanges.entries), currentValue);
  }, [riskRanges, currentValue]);

  return (
    <div>
      <MetricResultHeader>
        <Titles>
          <MainTitle>Ankle brachial index</MainTitle>
          <AuxTitle>[ABI]</AuxTitle>
        </Titles>
        <Diff>
          <DiffValue
            valuesDiff={previousValue && currentValue - previousValue}
            formatValue={(n) => n?.toFixed(1)}
          />
        </Diff>
      </MetricResultHeader>
      <RangeChartDataComposer
        ranges={rangesForValue}
        value={currentValue}
        previousValue={previousValue}
      >
        {({ ranges, values }) => <RangeChart ranges={ranges} values={values} />}
      </RangeChartDataComposer>
    </div>
  );
}
